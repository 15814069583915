import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { organisationStore } from "../organisation.js";
import { PermissionLevel } from '../models/permissions.js';
import { userStore } from '../user.js';
import { MySelect2 } from './generic.js';
import { camelCaseToSentence } from '../utils.js';
import { projectStatusOptions } from '../models/project.js';
import PropTypes from "prop-types";


export function getProjectOptions(requirePermissionLevel) {
  /**
   * Returns [Project]
   */
  let projects = organisationStore.getSelectableProjects();
  if (requirePermissionLevel != null) {
    projects = userStore.user.getProjectsWithAtLeastPermissionLevel(projects, requirePermissionLevel);
  }
  projects.sort((a, b) => a.getTitle().localeCompare(b.getTitle()));
  return projects;
}



export var ProjectSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    getDisabledProjects: PropTypes.func,
    allowNull: PropTypes.bool,
    nullText: PropTypes.string,
    disabledOptionTooltip: PropTypes.string,

    /**
     * If specified, only projects which the user has at least
     * `permissionLevel` level permissions will be available. If not specified,
     * all projects will be available. In either case, only only projects which
     * both have an id and are not archived will be available.
     */
    requirePermissionLevel: PropTypes.oneOf(PermissionLevel.getItems())
  },

  getDefaultProps: function() {
    return {
      value: null,
      allowNull: false,
      getDisabledProjects: function(projects) { return []; }
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore, userStore], function() {
      return {
        projects: getProjectOptions(this.props.requirePermissionLevel)
      };
    })
  ],

  render: function() {
    let self = this;
    let {value, onChange, getDisabledProjects, allowNull, disabledOptionTooltip, ...props} = this.props;
    let disabledProjects = getDisabledProjects(this.state.projects);
    let options = allowNull ? [null, ...this.state.projects] : this.state.projects;
    let optionDisabledLookup = {};
    options.forEach(function(project) {
      let id, isDisabled;
      if (project == null) {
        id = 0;
        isDisabled = _.some(disabledProjects, p => p == null);
      }
      else {
        id = project.id;
        isDisabled = _.some(disabledProjects, p => p != null && p.id === project.id);
      }
      optionDisabledLookup[id] = isDisabled;
    });

    function isDisabled(project) {
      return optionDisabledLookup[project != null ? project.id : 0];
    };

    return <MySelect2
      className="project-select"
      value={value}
      options={_.sortBy(options, o => {
        return projectStatusOptions.indexOf(o ? o.status : "active").toString()
          + (o != null ? o.getTitle() || "No title" : "No title").toString()
      })}
      onChange={onChange}
      isOptionDisabled={function(project) {
        if (project == null) {
          return _.some(disabledProjects, p => p == null);
        }
        else {
          return _.some(disabledProjects, p => p != null && p.id === project.id);
        }
      }}
      filter={function(project, searchText) {
        return project.getTitle().toLowerCase().match(searchText.toLowerCase());
      }}
      getObjectLabel={function(p) {
       return <div title={isDisabled(p) ? disabledOptionTooltip : null}>
          {p != null ?
            p.getTitle()
          : self.props.nullText != null ?
            self.props.nullText
          : allowNull ?
            "(No project)"
          : "(Please select)"
          }
        </div>;
      }}
      groupBy={p => {
        return camelCaseToSentence((p.status + 'Projects'));
      }}
      {...props}
    />;
  }
});
