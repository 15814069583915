import _ from "underscore";
import moment from "moment";
import {
	StoreBase,
	dispatcher,
	registerActions,
	handleAction,
} from "../coincraftFlux.js";
import { getTimesheetApiUrl } from "../timesheets/utils.js";
import {
	TimesheetStore,
	updateGroups,
	entriesToGroups,
	iterEntries,
} from "../timesheets/flux.js";
import { getEntries } from "../timesheets/ReportQuery.js";
import {
	getMonday,
	sum,
	formatMinutes,
	parseTime,
	imap,
	ifilter,
} from "../utils.js";
import { Report } from "../reports/Report.js";
import { organisationStore } from "../organisation.js";
import apiRequest from "../apiRequest.js";

const actionDefinitions = [
	{ action: "gotEntriesSuccess", args: ["data"] },
	{ action: "gotEntriesFailure", args: ["error"] },
	{ action: "copyMostRecentEntries", args: [] },
	{ action: "copyMostRecentEntriesSuccess", args: ["data"] },
	{ action: "copyMostRecentEntriesFailure", args: ["error"] },
	{ action: "saveTime", args: [] },
	{ action: "saveTimeFromTimer", args: [] },
	{ action: "saveTimeSuccess", args: ["timesheetEntry", "entryId"] },
	{ action: "saveTimeFailure", args: ["timesheetEntry", "error"] },
	{ action: "deleteEntry", args: [] },
	{ action: "deleteEntrySuccess", args: ["timesheetEntry"] },
	{ action: "deleteEntryFailure", args: ["timesheetEntry", "error"] },
	{ action: "previousWeek", args: [] },
	{ action: "nextWeek", args: [] },
	{ action: "selectDate", args: ["date"] },
	{ action: "startAddEntry", args: [] },
	{ action: "selectCostCentre", args: ["costCentre"] },
	{ action: "selectProject", args: ["project"] },
	{ action: "selectProjectPhase", args: ["projectPhase"] },
	{ action: "selectTask", args: ["task"] },
	{ action: "editProjectSearchText", args: ["text"] },
	{ action: "editTaskSearchText", args: ["text"] },
	{ action: "createEntryBack", args: [] },
	{ action: "createEntryCancel", args: [] },
	{ action: "startTimer", args: [] },
	{ action: "pauseTimer", args: [] },
	{ action: "resumeTimer", args: [] },
	{ action: "cancelTimer", args: [] },
	{ action: "timerTick", args: [] },
	{ action: "enterTime", args: [] },
	{ action: "editTime", args: ["text"] },
	{ action: "cancelEnterTime", args: [] },
	{ action: "addNote", args: [] },
	{ action: "editNote", args: ["text"] },
	{ action: "saveNote", args: [] },
	{ action: "cancelNote", args: [] },

	{ action: "viewTimeOptions", args: [] },
	{ action: "setIsBillable", args: ["isBillable"] },
	{ action: "setIsVariation", args: ["isVariation"] },
	{ action: "setIsOvertime", args: ["isOvertime"] },
	{ action: "saveTimeOptions", args: [] },
	{ action: "cancelTimeOptions", args: [] },

	{ action: "clickEntry", args: ["entry", "timesheetEntryPath"] },
];

export let actions = registerActions(
	"timesheetApp",
	actionDefinitions,
	dispatcher
);

class TimesheetAppStore extends StoreBase {
	constructor() {
		super();
		this.costCentreGroups = null;
		this.selectedDate = moment().startOf("day");
		this.selectedEntry = null;
		this.isAddingEntry = false;

		this.selectedCostCentre = null;
		this.selectedProject = null;
		this.selectedProjectPhase = null;
		this.projectSearchText = "";
		this.taskSearchText = "";

		this.timer = new Timer();
		this.isTimerOpen = false;

		this.timesheetEntryMode = "generic";
		this.enteredNote = "";
		this.enteredTime = "";
		// Values for unsaved entry while it's being edited.
		this.selectedEntryIsBillable = null;
		this.selectedEntryIsVariation = null;
		this.selectedEntryIsOvertime = null;

		this.staffTotalLookup = {};

		this.saveError = false;
		this.deleteError = false;

		this.dayTotals = [];

		// Either null or a reference to the timesheetEntry the user tried to click
		// that caused the error.
		this.cantOpenWhileTimerIsRunning = null;

		this.actionDefinitions = actionDefinitions;

		this.timesheetStore = new TimesheetStore();
	}

	handle(action) {
		handleAction(action, this);
	}

	get startOfWeek() {
		return getMonday(this.selectedDate);
	}

	getTodaysEntries() {
		return ifilter(this.iterEntries(), ([_i, entry]) =>
			entry.date.isSame(this.selectedDate)
		);
	}

	getNotTodaysEntries() {
		return ifilter(
			this.iterEntries(),
			([_i, entry]) => !entry.date.isSame(this.selectedDate)
		);
	}

	loadDay() {
		this.getEntries();
	}

	_getEntries({ copyPrevious = false } = {}) {
		return getEntries(
			new Report(
				Report.transformArgs({
					dateRange: {
						id: "custom",
						start: this.selectedDate.format("YYYY-MM-DD"),
						end: this.selectedDate.format("YYYY-MM-DD"),
					},
					filters: [],
				})
			),
			{
				duration: "day",
				copyPrevious: copyPrevious,
				includeWeekDailyTotals: !copyPrevious,
				includeStaffTotals: true,
				user: "me",
			}
		);
	}

	getEntries() {
		this._getEntries().then(
			function (data) {
				actions.gotEntriesSuccess(data);
			},
			function (error) {
				actions.gotEntriesFailure(error);
			}
		);
	}

	getAddEntryStage() {
		if (this.selectedCostCentre == null) {
			return "costCentre";
		} else if (this.selectedProject == null) {
			return "project";
		} else if (this.selectedProjectPhase == null) {
			return "phase";
		} else if (this.selectedProjectPhase.hasNonDefaultTask()) {
			return "task";
		} else {
			// There's not any real reason for this to exist as if we're finished
			// we'll be outside the add entry process so there will be no need to
			// call this function.
			return "finished";
		}
	}

	copyMostRecentEntries() {
		let self = this;
		this._getEntries({ copyPrevious: true }).then(
			function (data) {
				actions.copyMostRecentEntriesSuccess(data);
				self._saveDayTime();
			},
			function (error) {
				actions.copyMostRecentEntriesFailure(error);
			}
		);
	}

	getTotalMinutesForDate(d) {
		if (d.isSame(this.selectedDate)) {
			return sum(
				imap(
					this.iterTodaysEntries(),
					([_i, entry]) => entry.numMinutes
				)
			);
		} else {
			let t = _.find(this.dayTotals, (t) => t.date.isSame(d));
			return t != null ? t.total : 0;
		}
	}

	gotEntriesSuccess({ entries, staffTotals, dayTotals }) {
		this.staffTotalLookup = {};
		this.dayTotals = dayTotals;

		for (let t of staffTotals) {
			this.staffTotalLookup[t.projectPhaseId || -1] = t.totalMinutes;
		}
		this.costCentreGroups = entriesToGroups(
			entries,
			getMonday(this.selectedDate),
			getMonday(this.selectedDate).add(6, "days"),
			this.staffTotalLookup
		);

		this.emitChanged();
	}

	copyMostRecentEntriesSuccess({ entries, staffTotals }) {
		this.staffTotalLookup = {};
		for (let t of staffTotals) {
			this.staffTotalLookup[t.projectPhaseId || -1] = t.totalMinutes;
		}

		if (entries.length > 0) {
			let returnedWeekStartDate = getMonday(entries[0].date);
			let monday = getMonday(this.selectedDate);
			this.costCentreGroups = entriesToGroups(
				[
					...entries.map(function (e) {
						return e.createBlankCopy(
							monday
								.clone()
								.add(
									e.date.diff(returnedWeekStartDate, "days"),
									"days"
								)
						);
					}),
					// ...[...iterEntries(this.costCentreGroups)].map((t) => t[1]),
				],
				monday,
				monday.clone().add(6, "days"),
				this.staffTotalLookup
			);
		}

		this.emitChanged();
	}

	copyMostRecentEntriesFailure(error) {
		//TODO-timesheet_app
		alert("There was a problem getting previous entries");
	}

	gotEntriesFailure(error) {
		//TODO-timesheet_app
		alert("There was a problem loading your timesheets");
	}

	_saveTime(timesheetEntry) {
		apiRequest({
			url: getTimesheetApiUrl() + "/save-one",
			method: "post",
			data: {
				entry: timesheetEntry.serialize(),
			},
		}).then(
			function (data) {
				if (data.status === "ok") {
					actions.saveTimeSuccess(timesheetEntry, data.entryId);
				} else if (
					data.status === "failure" &&
					data.error === "duplicate"
				) {
					actions.saveTimeFailure(timesheetEntry, "duplicate");
				} else {
					actions.saveTimeFailure(timesheetEntry);
				}
			},
			function (error) {
				actions.saveTimeFailure(timesheetEntry);
			}
		);
	}

	saveTime(timeText) {
		// TODO - not the best spot to do this, add to save success
		const entry = this.selectedEntry;
		const currentTotalHours =
			this.staffTotalLookup[entry.projectPhase?.id || -1];
		const newTotalHours =
			currentTotalHours + parseTime(this.enteredTime) - entry.numMinutes;
		this.staffTotalLookup[entry.projectPhase?.id || -1] = newTotalHours;
		this._saveTime(
			this.selectedEntry.set("numMinutes", parseTime(this.enteredTime))
		);
	}

	_saveDayTime() {
		for (let [entryIndex, entry, path] of this.iterTodaysEntries()) {
			this._saveTime(entry);
		}
	}

	saveTimeFromTimer() {
		// TODO - not the best spot to do this, add to save success
		let newNumMinutes = Math.round(
			this.selectedEntry.numMinutes + this.timer.totalSeconds / 60
		);
		if (newNumMinutes !== this.selectedEntry.numMinutes) {
			const entry = this.selectedEntry;
			const currentTotalHours =
				this.staffTotalLookup[entry.projectPhase?.id || -1];
			const newTotalHours =
				currentTotalHours +
				parseTime(this.enteredTime) -
				entry.numMinutes;
			this.staffTotalLookup[entry.projectPhase?.id || -1] = newTotalHours;
			this._saveTime(this.selectedEntry.set("numMinutes", newNumMinutes));
			this.cancelTimer();
			this.emitChanged();
		}
	}

	saveTimeSuccess(timesheetEntry, entryId) {
		timesheetEntry = timesheetEntry.set("id", entryId);
		if (this.selectedEntryPath)
			this.costCentreGroups = this.costCentreGroups.setIn(
				this.selectedEntryPath.searchKeyPath(),
				timesheetEntry
			);

		if (
			this.selectedEntry != null &&
			this.selectedEntry.isSame(timesheetEntry)
		) {
			this.selectedEntry = timesheetEntry;
		}
		this.saveError = false;
		this.cantOpenWhileTimerIsRunning = null;
		this.timesheetEntryMode = "generic";
		this.emitChanged();
	}

	saveTimeFailure(timesheetEntry, error) {
		this.saveError = true;
		this.emitChanged();
	}

	deleteEntry() {
		let timesheetEntry = this.selectedEntry;

		if (timesheetEntry?.id != null) {
			apiRequest({
				method: "post",
				url: getTimesheetApiUrl() + "/delete-one",
				data: {
					id: timesheetEntry.id,
				},
			}).then(
				function (data) {
					actions.deleteEntrySuccess(timesheetEntry);
				},
				function (error) {
					actions.deleteEntryFailure(timesheetEntry, error);
				}
			);
		} else {
			this.deleteEntrySuccess(timesheetEntry);
		}
	}

	deleteEntrySuccess(timesheetEntry) {
		this.selectedEntry = null;
		this.costCentreGroups = this.costCentreGroups.deleteIn(
			this.selectedEntryPath.searchKeyPath()
		);
		this.deleteError = false;
		this.emitChanged();
	}

	deleteEntryFailure(timesheetEntry, error) {
		this.deleteError = true;
		this.emitChanged();
	}

	previousWeek() {
		this.selectDate(this.selectedDate.clone().subtract(7, "days"));
	}

	nextWeek() {
		this.selectDate(this.selectedDate.clone().add(7, "days"));
	}

	selectDate(date) {
		this.selectedDate = date;
		this.loadDay();
	}

	startAddEntry() {
		this.isAddingEntry = true;
		this.projectSearchText = "";
		this.taskSearchText = "";
		this.emitChanged();
	}

	selectCostCentre(costCentre) {
		this.selectedCostCentre = costCentre;
		this.emitChanged();
	}

	selectProject(project) {
		this.selectedProject = project;
		this.emitChanged();
	}

	selectProjectPhase(projectPhase) {
		if (
			!projectPhase.hasNonDefaultTask() ||
			!organisationStore.organisation.settings.useTasks
		) {
			this._addEntry(
				this.selectedProject,
				projectPhase,
				projectPhase.tasks.filter((t) => !t.isDeleted)[0]
			);
		} else {
			this.selectedProjectPhase = projectPhase;
		}
		this.emitChanged();
	}

	selectTask(task) {
		if (task != null && task.uuid === -1) {
			task = null;
		}
		this._addEntry(this.selectedProject, this.selectedProjectPhase, task);
		this.emitChanged();
	}

	_addEntry(project, projectPhase, task) {
		let { costCentreGroups, highlightedRowPath } = updateGroups(
			this.costCentreGroups,
			null,
			null,
			project,
			projectPhase,
			task,
			true, // isBillable
			false, // isVariation
			false, // isOvertime
			false, // isLocked
			false, // Been Invoiced
			this.selectedDate,
			this.selectedDate,
			this.staffTotalLookup
		);

		this.costCentreGroups = costCentreGroups;

		// Remember we're on a daily view so rows only have one entry.
		highlightedRowPath.entryIndex = 0;

		this.selectedEntry = this.costCentreGroups.getIn(
			highlightedRowPath.searchKeyPath()
		);
		this.selectedEntryPath = highlightedRowPath;

		this.selectedCostCentre = null;
		this.selectedProject = null;
		this.selectedProjectPhase = null;
		this.isAddingEntry = false;
		this.timesheetEntryMode = "generic";
	}

	editProjectSearchText(text) {
		this.projectSearchText = text;
		this.emitChanged();
	}

	editTaskSearchText(text) {
		this.taskSearchText = text;
		this.emitChanged();
	}

	getSelectableProjects() {
		if (this.selectedCostCentre == null) {
			return null;
		} else {
			let searchTextLower = this.projectSearchText.toLowerCase();
			return organisationStore
				.getVisibleProjectsByCostCentre(this.selectedCostCentre)
				.filter(function (p) {
					return (
						organisationStore.organisation.settings.timeEntryStatus.includes(
							p.status
						) &&
						p.getTitle().toLowerCase().match(searchTextLower) !=
							null
					);
				});
		}
	}

	getSelectableTasks() {
		if (this.getAddEntryStage() !== "task") {
			return null;
		} else {
			let tasks = this.selectedProjectPhase.getVisibleTasks();
			if (this.taskSearchText !== "") {
				let searchTextLower = this.taskSearchText.toLowerCase();
				return tasks.filter(function (t) {
					return t.name.toLowerCase().match(searchTextLower) != null;
				});
			} else {
				return tasks;
			}
		}
	}

	createEntryBack() {
		if (this.selectedProjectPhase != null) {
			this.selectedProjectPhase = null;
		} else if (this.selectedProject != null) {
			this.selectedProject = null;
		} else if (this.selectedCostCentre != null) {
			this.selectedCostCentre = null;
		} else {
			this.isAddingEntry = false;
		}
		this.emitChanged();
	}

	createEntryCancel() {
		this.selectedCostCentre = null;
		this.selectedProject = null;
		this.isAddingEntry = false;
		this.emitChanged();
	}

	startTimer() {
		this.timer.start();
		this.isTimerOpen = true;
		this.timesheetEntryMode = "timer";
		this.emitChanged();
	}

	pauseTimer() {
		this.timer.pause();
		this.emitChanged();
	}

	resumeTimer() {
		this.timer.resume();
		this.emitChanged();
	}

	cancelTimer() {
		this.timer.stop();
		this.isTimerOpen = false;
		this.timesheetEntryMode = "generic";
		this.cantOpenWhileTimerIsRunning = null;
		this.emitChanged();
	}

	timerTick() {
		this.timer.handleTick();
		this.emitChanged();
	}

	enterTime() {
		this.timesheetEntryMode = "time-input";
		this.enteredTime = formatMinutes(this.selectedEntry.numMinutes);
		this.emitChanged();
	}

	editTime(text) {
		let value = text.replace(/[^0-9.:]+/g, "");
		let specialChars = value.replace(/[^.:]/g, "");
		if (specialChars.length > 1) {
			value = value.slice(0, -1);
		}
		this.enteredTime = value;
		this.emitChanged();
	}

	cancelEnterTime() {
		this.timesheetEntryMode = "generic";
		this.saveError = false;
		this.emitChanged();
	}

	addNote() {
		this.timesheetEntryMode = "note";
		this.enteredNote = this.selectedEntry.notes;
		this.emitChanged();
	}

	editNote(text) {
		this.enteredNote = text;
		this.emitChanged();
	}

	saveNote() {
		this._saveTime(this.selectedEntry.set("notes", this.enteredNote));
		this.emitChanged();
	}

	cancelNote() {
		this.timesheetEntryMode = "generic";
		this.saveError = false;
		this.emitChanged();
	}

	viewTimeOptions() {
		this.timesheetEntryMode = "time-options";
		this.selectedEntryIsBillable = this.selectedEntry.isBillable;
		this.selectedEntryIsVariation = this.selectedEntry.isVariation;
		this.selectedEntryIsOvertime = this.selectedEntry.isOvertime;
		this.emitChanged();
	}

	setIsBillable(isBillable) {
		this.selectedEntryIsBillable = isBillable;
		this.emitChanged();
	}

	setIsVariation(isVariation) {
		this.selectedEntryIsVariation = isVariation;
		this.emitChanged();
	}

	setIsOvertime(isOvertime) {
		this.selectedEntryIsOvertime = isOvertime;
		this.emitChanged();
	}

	saveTimeOptions() {
		this._saveTime(
			this.selectedEntry.merge({
				isBillable: this.selectedEntryIsBillable,
				isVariation: this.selectedEntryIsVariation,
				isOvertime: this.selectedEntryIsOvertime,
			})
		);
	}

	cancelTimeOptions() {
		this.timesheetEntryMode = "generic";
		this.emitChanged();
	}

	clickEntry(entry, path) {
		if (this.selectedEntry == null || !this.selectedEntry.isSame(entry)) {
			if (this.isTimerOpen) {
				this.cantOpenWhileTimerIsRunning = entry;
			} else {
				this.selectedEntry = entry;
				this.selectedEntryPath = path;
				this.timesheetEntryMode = !entry.isLocked
					? "generic"
					: "locked";
			}
			this.emitChanged();
		}
	}

	*iterEntries() {
		yield* iterEntries(this.costCentreGroups);
	}

	*iterTodaysEntries() {
		for (let [entryIndex, entry, path] of this.iterEntries()) {
			if (entry.date.isSame(this.selectedDate)) {
				yield [entryIndex, entry, path];
			}
		}
	}
}

class Timer {
	constructor() {
		this.secondsThisRound = 0;
		this.secondsPreviousRounds = 0;
		this.totalSeconds = 0;
		this.startTimestamp = null;
		this.isRunning = false;
		this.timer = null;

		this.start = this.start.bind(this);
		this.resume = this.resume.bind(this);
		this.pause = this.pause.bind(this);
		this.stop = this.stop.bind(this);
		this.handleTick = this.handleTick.bind(this);
		this.queueTick = this.queueTick.bind(this);
	}

	start() {
		/**
    // Or for testing, sometimes this is useful:
    this.startTimestamp = Date.now() - 30 * 1000;
    this.timerSecondsThisRound = 30;
    */
		if (!this.timer) {
			this.startTimestamp = Date.now();
			this.timerSecondsThisRound = 0;
			this.secondsPreviousRounds = 0;
			this.totalSeconds = 0;
			this.isRunning = true;
			this.timer = setInterval(this.queueTick, 1000);
		}
	}

	resume() {
		if (!this.timer) {
			this.isRunning = true;
			this.startTimestamp = Date.now();
			this.timer = setInterval(this.queueTick, 1000);
		}
	}

	pause() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
			this.handleTick();
			this.isRunning = false;
			this.secondsPreviousRounds = this.totalSeconds;
			this.secondsThisRound = 0;
		}
	}

	stop() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
			this.totalSeconds = 0;
			this.secondsThisRound = 0;
			this.secondsPreviousRounds = 0;
			this.totalSeconds = 0;
			this.isRunning = false;
		}
	}

	queueTick() {
		actions.timerTick();
	}

	handleTick() {
		let s = Math.round((Date.now() - this.startTimestamp) / 1000);
		this.secondsThisRound = s;
		this.totalSeconds = this.secondsPreviousRounds + s;
	}
}

export const store = new TimesheetAppStore();

function pad2(n) {
	return (n < 10 ? "0" : "") + n.toString();
}

export function formatSeconds(seconds) {
	let hours = Math.floor(seconds / 3600);
	let minutes = Math.floor((seconds - hours * 3600) / 60);
	let s = seconds - hours * 3600 - minutes * 60;
	return `${pad2(hours)}:${pad2(minutes)}:${pad2(s)}`;
}
