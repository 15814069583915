import React from 'react';
import CreateReactClass from 'create-react-class';
import { IntercomMessageLink } from '../widgets/IntercomMessageLink.js';
import { ErrorAlert, InfoAlert } from '../widgets/alerts.js';
import { ConfirmableDeleteButton } from '../widgets/ConfirmableDeleteButton.js';
import { TriStateSaveButton } from '../widgets/TriStateSaveButton.js';
import { ContactImportForm } from './ContactImportForm.js';
import classNames from 'classnames';
import { Stage } from './flux.js';
import { dispatcher } from '../coincraftFlux.js';
import { ContactDropdown } from '../widgets/ContactSelector.js';
import PropTypes from 'prop-types';


export var ContactForm = CreateReactClass({
  propTypes: {
    stage: PropTypes.string.isRequired,
    buttons: PropTypes.object.isRequired,
    contact: PropTypes.object,
    saveState: PropTypes.string,
    importContacts: PropTypes.string,
    canSave: PropTypes.bool,
    context: PropTypes.oneOf(['project', 'invoice']).isRequired,

    actions: PropTypes.shape({
      setStage: PropTypes.func,
      save: PropTypes.func,
      back: PropTypes.func
    })
  },

  render: function() {
    return <div className="contact-form">
      {this.renderContent()}
      {this.props.saveState === 'error' ?
        <ErrorAlert>
          There was a problem saving this contact. Try again, and if the problem
          persists, <IntercomMessageLink label="chat with us" />.
        </ErrorAlert>
      : null}
      {this.renderFooter()}
    </div>;
  },

  renderContent: function() {
    switch (this.props.stage) {
      case Stage.selectOperation:
        return <SelectType {...this.props} />;
      case Stage.importContacts:
        return <ContactImportForm {...this.props} />;
      case Stage.selectContact:
        return <div style={{padding: '2em'}}>
          <p>
            <strong>Select contact</strong>
          </p>
          <p>
            You're now ready to select which contact to use for this {this.props.context}.
          </p>
          <div>
            <ContactDropdown
              style={{display: 'inline-block', width: '15em'}}
              value={this.props.accountingSystemContact}
              onChange={(contact) => dispatcher.dispatch({
                type: "contact-form/selectAccountingSystemContact",
                path: this.props.path,
                contact: contact
              })}
            />
          </div>
        </div>;
      case Stage.editContact:
        return <Coincraft {...this.props} />;
    }
  },

  renderFooter: function() {
    return (
      <div
          className="flexbox-container flex-align-items-center"
          style={{borderTop: 'solid 1px #ccc', padding: '1em'}}>
        <div className="flex-0-0-auto">
          {this.props.buttons.has('back') ?
            <button
                className="btn btn-default"
                onClick={() => this.props.actions.back()}>
              <i className="fa fa-arrow-left" />
              Back
            </button>
          : null}

          {this.props.buttons.has('delete') ?
            <ConfirmableDeleteButton
              object={this.props.contact}
              objectTypeName="contact"
              onDelete={() => this.props.actions.deleteContact()}
            />
          : null}
        </div>

        <div className="flex-1-1-auto" />

        {this.props.buttons.has('cancel') || this.props.buttons.has('save') || this.props.buttons.has('import') ?
          <div className="flex-0-0-auto" style={{textAlign: 'right'}}>
            <Buttons>
              {this.props.buttons.has('cancel') ?
                <button
                    className="btn btn-default"
                    onClick={() => this.props.actions.cancel()}>
                  Cancel
                </button>
              : null}

              {this.props.buttons.has('save') ?
                <TriStateSaveButton
                  size="md"
                  isSuccessButton={true}
                  hasFloppyDisk={true}
                  text='Save contact'
                  state={this.props.saveState}
                  onClick={() => this.props.actions.save()}
                  disabled={!this.props.canSave}
                />
              : null}

              {this.props.buttons.has('import') ?
                <TriStateSaveButton
                  size="md"
                  isSuccessButton={true}
                  hasFloppyDisk={true}
                  text='Import'
                  state={this.props.importContacts.state}
                  onClick={() => this.props.actions.importSelectedContacts()}
                  disabled={
                    this.props.selectedRetrievedContacts == null
                    || this.props.selectedRetrievedContacts.size === 0
                  }
                />
              : null}
            </Buttons>
          </div>
        : null}
      </div>
    );
  }
});


var Buttons = CreateReactClass({
  render: function() {
    return <div style={{display: 'inline-block'}}>
      {this.props.children.map(function(child, i) {
        if (child == null) {
          return null;
        }
        return <div key={i} style={{display: 'inline-block', marginLeft: i > 0 ? '1em' : null}}>
          {child}
        </div>;
      })}
    </div>;
  }
});


var SelectType = CreateReactClass({
  propTypes: {
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <div style={{padding: '1em'}}>
      <div style={{padding: '1em 0'}}>
        <div className="flexbox-container flex-align-items-flex-start">
          <button
              className="flex-0-0-auto btn btn-default btn-lg"
              style={{width: '11em'}}
              onClick={() => this.props.actions.setStage(Stage.importContacts)}>
            <div>
              Import contacts
            </div>
            <div style={{fontSize: '0.8em'}}>
              (Recommended)
            </div>
          </button>
          <div className="flex-1-0-auto" style={{padding: '0.5em 0 0 1.5em', fontSize: '0.9em'}}>
            Retrieve contacts from your accounting system and select one for this {this.props.context}.
          </div>
        </div>
      </div>

      <div style={{padding: '1em 0'}}>
        <div className="flexbox-container flex-align-items-flex-start">
          <button
              className="flex-0-0-auto btn btn-default btn-lg coincraft-contact-button"
              style={{width: '11em'}}
              onClick={() => this.props.actions.setStage(Stage.editContact)}>
            Create new contact
          </button>
          <div
              className="flex-1-0-auto"
              style={{
                // For some reason we need some width here even though we want flex to say
                // "use the rest of the width".
                width: '10em',
                padding: '0.5em 0 0 1.5em',
                fontSize: '0.9em'
              }}>
            Create a new contact in Coincraft.
          </div>
        </div>
      </div>
    </div>;
  }
});


var Coincraft = CreateReactClass({
  propTypes: {
    contact: PropTypes.object,
  },

  render: function() {
    return (
      <div>
        {this.props.contact != null && this.props.contact.accountingSystemId != null ?
          <InfoAlert>
            This is a Xero contact and cannot be edited from  If you wish to
            make changes to this contact's details you can do so from Xero.
            {' '}
            <a href={this.props.contact.xeroUrl} target="_blank">
              Open this contact in Xero
            </a>
          </InfoAlert>
        : null}
        <div className="flexbox-container">
          <div className="flex-0-0-auto" style={{padding: '0.5em'}}>
            <div className="form-horizontal">
              {this.renderField("First name", "firstName")}
              {this.renderField("Last name", "lastName")}
              {this.renderField("Organisation name", "contactOrganisationName")}
              {this.renderField("Notes", "notes")}
            </div>

            {this.props.saveError ?
              <ErrorAlert>
                There was a problem saving this contact. Try again, and if the problem
                persists, <IntercomMessageLink label="chat with us" />.
              </ErrorAlert>
            : null}
          </div>
        </div>
      </div>
    );
  },

  renderField: function(heading, fieldProp) {
    const actionNames = {
      firstName: 'setContactFirstName',
      lastName: 'setContactLastName',
      contactOrganisationName: 'setContactOrganisationName',
      notes: 'setContactNotes'
    };

    const fieldAttrs = {
      value: this.props.contact[fieldProp] || '',
      onChange: (event) => this.props.actions[actionNames[fieldProp]](event.target.value),
      disabled: !this.props.contact.isEditable
    };

    return (
      <div className="form-group">
        <div className="col-md-3">
          <label>{heading}:</label>
        </div>
        <div className={classNames("col-md-8", `contact-form__${fieldProp}`)}>
          {fieldProp === 'notes' ?
            <textarea cols={60} rows={4} {...fieldAttrs} />
          : <input type="text" {...fieldAttrs} />
          }
        </div>
      </div>
    );
  },
});
