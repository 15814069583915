import _ from 'underscore';
import moment from 'moment';
import { jsonHttp2 } from '../jsonHttp.js';
import { TimesheetEntry } from '../timesheets/models.js';
import { getTimesheetApiUrl } from './utils.js';
import { organisationStore } from '../organisation.js';
import apiRequest, { chainRequests } from '../apiRequest.js';
import { parseDateRange, CustomDateRange } from '../reports/DateRanges.js';
import Axios from 'axios';


// Safari doesn't create the date for 2015-10-04 correctly and instead creates
// 11pm on 2015-10-03, so we have a special hack to work around that.
const needsFix = (moment("2015-10-04", "YYYY-MM-DD").format("YYYY-MM-DD") === "2015-10-03");

function makeDate(s) {
  if (s === "2015-10-04" && needsFix) {
    return moment(s, "YYYY-MM-DD").add(1, 'hour');
  }
  else {
    return moment(s, "YYYY-MM-DD");
  }
};


export function getEntries(
    report, {
      copyPrevious = false,
      duration,
      includeWeekDailyTotals,
      includeStaffTotals,
      includeMonthTotals,

      // `user`: `null`, 'me', or a user id.
      // If `null`, return entries for any users the current user has permission to see.
      user = null
    } = {}) {


  let hasLoggedAnomaly = false;
  let dateRangeMoment = report.dateRange.getDates(moment());
  if (!dateRangeMoment[0] || !dateRangeMoment[1]) {
    return apiRequest({
		url: "/api/v1/user/timesheet/min-max",
		method: "get",
		params: {
			projects: _.flatten(
				report
					.toJS()
					.filters.filter(
						f =>
							f.columnId === "project" &&
							f.matcher.operation === "any"
					)
					.map(f => f.matcher.value)
			)
		}
	}).then(data => {
		dateRangeMoment = [moment(data.minDate), moment(data.maxDate)];
		return getTimesheets({
			dateRangeMoment,
			report,
			copyPrevious,
			duration,
			includeWeekDailyTotals,
			includeStaffTotals,
			includeMonthTotals,
			user,
			hasLoggedAnomaly
		});
	});
  } else {
    return getTimesheets({
		dateRangeMoment,
		report,
		copyPrevious,
		duration,
		includeWeekDailyTotals,
		includeStaffTotals,
		includeMonthTotals,
		user,
		hasLoggedAnomaly
	});
  }

}

const getTimesheets = ({
	dateRangeMoment,
	report,
	copyPrevious,
	duration,
	includeWeekDailyTotals,
	includeStaffTotals,
	includeMonthTotals,
	user,
	hasLoggedAnomaly
}) => {
	const numDays = moment
		.duration(dateRangeMoment[1] - dateRangeMoment[0])
		.asDays();
	const numRequests = Math.ceil(numDays / 45) || 1;
	const daysPerRequest = Math.ceil(numDays / numRequests);
	return chainRequests(
		_.range(numRequests).map(rn => {
			const startDate = dateRangeMoment[0]
				.clone()
				.add(rn * daysPerRequest, "days");
			const endDate =
				rn + 1 < numRequests
					? dateRangeMoment[0]
							.clone()
							.add(daysPerRequest * (rn + 1) - 1, "days")
					: dateRangeMoment[1];
			const dateRange = new CustomDateRange(
				startDate.clone(),
				endDate.clone()
			);
			return () =>
				apiRequest({
					url: getTimesheetApiUrl(),
					method: "get",
					params: {
						data: {
							report: {
								...report.serialize(),
								dateRange: dateRange.serialize()
							},
							user: user,
							copyPrevious: copyPrevious,
							duration: duration,
							includeWeekDailyTotals: includeWeekDailyTotals,
							includeStaffTotals: includeStaffTotals,
							includeMonthTotals: includeMonthTotals,
							nocache: new Date().getTime()
						}
					}
				});
		})
	).then(dataArray => {
		let data = {
			StaffDailyTotal: [],
			TimesheetEntry: [],
			TimesheetEntryStaffTotal: [],
			monthTotals: []
		};
		dataArray.forEach(d => {
			_.isArray(d.StaffDailyTotal) &&
				data.StaffDailyTotal.push(...d.StaffDailyTotal);
			d.TimesheetEntry && data.TimesheetEntry.push(...d.TimesheetEntry);
			d.TimesheetEntryStaffTotal &&
				data.TimesheetEntryStaffTotal.push(
					...d.TimesheetEntryStaffTotal
				);
			d.monthTotals && data.monthTotals.push(...d.monthTotals);
		});
		if (data.StaffDailyTotal.length == 0) {
			data.StaffDailyTotal = "blank";
		}
		const entries = data.TimesheetEntry.map(function(te) {
			const projectPhase = organisationStore.getProjectPhaseById(
				te.projectPhaseId
			);

			// There is one edge case where this can happen: if the user has entered
			// a timesheet for a phase for which all of their permissions have
			// subsequently been removed. Then we will have the timesheet entry data
			// but not the phase data. We've decided to wontfix this for the time
			// being. Note that a null `projectPhase` is normal if `projectPhaseId ==
			// null`; that just means no phase.
			if (te.projectPhaseId != null && projectPhase == null) {
				if (!hasLoggedAnomaly) {
					console.log(
						"Timesheet entry with missing phase data; ignoring"
					);
					hasLoggedAnomaly = true;
				}
				return null;
			}

			const task =
				projectPhase && te.taskUuid != null
					? projectPhase.getTaskByUuid(te.taskUuid)
					: null;
			const staffMember = organisationStore.getStaffMemberById(
				te.staffMemberId
			);
			return new TimesheetEntry({
				id: te.id,
				uuid: te.uuid,
				date: makeDate(te.date),
				numMinutes: te.numMinutes,
				businessCategory: organisationStore.getCostCentreById(
					te.businessCategoryId
				),
				staffMember: staffMember,
				staffRole: staffMember.role,
				project: organisationStore.getProjectById(te.projectId),
				projectPhase: projectPhase,
				task: task,
				isBillable: te.isBillable,
				isVariation: te.isVariation,
				isOvertime: te.isOvertime,
				isLocked: te.isLocked,
				beenInvoiced: te.beenInvoiced,
				user: organisationStore.getStaffMemberById(te.staffMemberId),
				notes: te.notes,
				pay: te.pay,
				cost: te.cost,
				chargeOut: te.chargeOut,
				chargeOutRate: te.chargeOutRate
			});
		}).filter(te => te != null);

		const staffTotals = data.TimesheetEntryStaffTotal;
		const monthTotals = data.monthTotals;

		const dayTotals = _.isArray(data.StaffDailyTotal)
			? data.StaffDailyTotal.map(function(t) {
					return {
						date: moment(t.date),
						total: t.total
					};
			  })
			: data.StaffDailyTotal === "blank"
			? "blank"
			: null;

		return {
			entries: entries,
			staffTotals: staffTotals,
			monthTotals: monthTotals,
			dayTotals: dayTotals
		};
	});
};
