import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { organisationStore } from '../organisation.js';
import { TaskSelector, BasicMySelect2, FilterTextBox } from '../widgets.js';
import { arrayToObject, trueKeys, formatNumber2, pluralise } from '../utils.js';
import { getMatchingEntries, getStaffDataLookup } from './flux.js';
import classNames from 'classnames';

import PropTypes from "prop-types";


export var TimesheetLineItemForm = CreateReactClass({
  propTypes: {
    invoice: PropTypes.object.isRequired,
    timesheetEntries: PropTypes.array.isRequired,
    phaseId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  },

  mixins: [LinkedStateMixin],

  getInitialState: function() {
    const initialTask = 'all';
    const isBillable = true;
    const isVariation = null;
    const staffHoursLookup = this.getStaffHoursLookup(
      initialTask,
      {
        isBillable: isBillable,
        isVariation: isVariation
      }
    );

    return {
      staffHoursLookup: staffHoursLookup,
      selectedStaffMembers: arrayToObject(
        organisationStore.staffMembers,
        sm => sm.id,
        sm => !!staffHoursLookup[sm.id]
      ),
      combinedType: 'staff',
      task: initialTask,
      isBillable: isBillable,
      isVariation: isVariation,
      filterText: ''
    };
  },

  render: function() {
    let self = this;

    const phase = organisationStore.getProjectPhaseById(this.props.phaseId);
    const filterUpper = this.state.filterText.toUpperCase();
    const staff = organisationStore.staffMembers.filter(function(sm) {
      return ((!sm.isArchived || self.state.staffHoursLookup[sm.id] > 0)
        && sm.getFullName().toUpperCase().indexOf(filterUpper) !== -1
      );
    });

    return (
      <div className="invoice-phase__add-line-item">
        <div>
          <div className="flexbox-container flex-align-items-center flex-space-around" style={{padding: '1em', borderBottom: 'solid 1px #ccc'}}>
            <div className="inline-flexbox-container flex-align-items-center">
              <span className="flex-0-0-auto" style={{marginRight: '1em'}}>
                Task:
              </span>
              <TaskSelector
                className="task-selector flex-0-0-auto"
                style={{ maxWidth: '100%' }}
                projectPhase={phase}
                value={this.state.task}
                onChange={this.handleTaskChange}
                includeAllTasksOption={true}
                style={{width: '12em'}}
              />
            </div>
            <div className="inline-flexbox-container flex-align-items-center">
              <span className="flex-0-0-auto" style={{marginRight: '1em'}}>
                Billable:
              </span>
              <BasicMySelect2
                value={this.state.isBillable}
                onChange={this.handleIsBillableChange}
                options={[
                  {label: 'any', value: null},
                  {label: 'yes', value: true},
                  {label: 'no', value: false}
                ]}
                style={{width: '5em'}}
              />
            </div>
            <div className="inline-flexbox-container flex-align-items-center">
              <span className="flex-0-0-auto" style={{marginRight: '1em'}}>
                Variation:
              </span>
              <BasicMySelect2
                value={this.state.isVariation}
                onChange={this.handleIsVariationChange}
                options={[
                  {label: 'any', value: null},
                  {label: 'yes', value: true},
                  {label: 'no', value: false}
                ]}
                style={{width: '5em'}}
              />
            </div>
          </div>

          <div style={{padding: '1em'}}>
            <FilterTextBox
              value={this.state.filterText}
              onChange={(text) => this.setState({filterText: text})}
              placeholder="Filter Staff"
            />
          </div>

          <div style={{maxHeight: '20em', overflowY: 'auto', margin: 0, borderTop: 'solid 1px #ddd', borderBottom: 'solid 1px #ccc'}}>
            {staff.length > 0 ?
              <div>
                {staff.map(function(sm) {
                  let numHours = self.state.staffHoursLookup[sm.id] || 0;
                  return (
                    <label
                        key={sm.getFullName()}
                        style={{borderBottom: 'solid 1px #eee'}}
                        className={classNames(
                          "column-selector__column",
                          "flexbox_container",
                          "flex-align-items-center",
                          "column-select-list-item", {
                            selected: self.state.selectedStaffMembers[sm.id] === true
                          }
                        )}>
                      <input
                        type="checkbox"
                        className="flex-0-0-auto"
                        checked={self.state.selectedStaffMembers[sm.id] === true}
                        onChange={(e) => self.handleStaffMemberCheckboxChange(sm, e.target.checked)}
                      />
                      <span style={{marginLeft: '1em'}} className="flex-0-0-auto">
                        {sm.getFullName()}
                      </span>
                      <span style={{float: 'right'}}>({formatNumber2(numHours)} hours)</span>
                    </label>
                  );
                })}
              </div>
            :
              <div style={{padding: '1em 3em', fontStyle: 'italic'}}>No Staff Match That Filter</div>
            }
          </div>
        </div>

        <div style={{padding: '1em'}} className="flexbox-container flex-align-items-center flex-space-between">
          <a
              href="javascript:void(0)"
              onClick={() => this.props.onCancel()}>
            Cancel
          </a>
          <div className="flexbox-container flex-align-items-center">
            <BasicMySelect2
              className="hourly-line-item-form__combine-type-dropdown"
              value={this.state.combinedType}
              onChange={(v) => this.setState({combinedType: v})}
              options={[
                {label: 'Add a single line item', value: "combined"},
                {label: 'Add a line item per staff member', value: "staff"},
                {label: 'Add a line item per timesheet entry', value: "timesheetEntry"},
              ]}
            />
            <button
                className="btn btn-primary hourly-line-item-form__submit-button"
                style={{marginLeft: '1em'}}
                onClick={this.handleSubmitButtonClick}>
              Add {pluralise(this.getNumTimesheetEntriesToAdd(this.state), 'line item')}
            </button>
          </div>
        </div>
      </div>
    );
  },

  getNumTimesheetEntriesToAdd: function(newState) {
    if (newState.combinedType === 'combined') {
      return 1;
    }
    else if (newState.combinedType === 'staff') {
      return trueKeys(newState.selectedStaffMembers).length;
    }
    else {
      return getMatchingEntries(
        this.props.timesheetEntries,
        organisationStore.getProjectPhaseById(this.props.phaseId),
        newState.task,
        {
          isBillable: newState.isBillable,
          isVariation: newState.isVariation,
          staffMemberIds: trueKeys(newState.selectedStaffMembers).map(k => parseInt(k))
        }
      ).length;
    }
  },

  handleStaffMemberCheckboxChange: function(sm, checked) {
    this.state.selectedStaffMembers[sm.id] = checked;
    this.setState({selectedStaffMembers: this.state.selectedStaffMembers});
  },

  updateStaffHoursLookup: function(newState) {
    return {
      ...newState,
      staffHoursLookup: this.getStaffHoursLookup(
        newState.task || this.state.task,
        {
          isBillable: this.state.isBillable,
          isVariation: this.state.isVariation,
          ...((newState.isBillable !== undefined) ? {isBillable: newState.isBillable} : null),
          ...((newState.isVariation !== undefined) ? {isVariation: newState.isVariation} : null)
        }
      )
    };
  },

  handleTaskChange: function(task) {
    this.setState(this.updateStaffHoursLookup({task: task}));
  },

  handleIsBillableChange: function(isBillable) {
    this.setState(this.updateStaffHoursLookup({isBillable: isBillable}));
  },

  handleIsVariationChange: function(isVariation) {
    this.setState(this.updateStaffHoursLookup({isVariation: isVariation}));
  },

  handleSubmitButtonClick: function() {
    this.props.onSubmit({
      combinedType: this.state.combinedType,
      staffMemberIds: trueKeys(this.state.selectedStaffMembers).map(k => parseInt(k)),
      task: this.state.task,
      isBillable: this.state.isBillable,
      isVariation: this.state.isVariation
    });
  },

  getStaffHoursLookup: function(task, {isBillable = null, isVariation = null} = {}) {
    const phase = organisationStore.getProjectPhaseById(this.props.phaseId);
    const staffDataLookup = getStaffDataLookup(
      this.props.timesheetEntries,
      phase,
      task,
      {
        isBillable: isBillable,
        isVariation: isVariation
      }
    );
    return _.mapObject(staffDataLookup, v => v.numHours);
  }
});
