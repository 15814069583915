import _ from 'underscore';
import { sum, enumerate, formatNumber0, compareMultiple, formatCurrencyWithCents } from '../utils.js';
import { organisationStore } from "../organisation.js";
import { processString } from '../invoices/templateProcessor.js';
import { getStaffDataLookup, getMatchingEntries } from '../invoices/flux.js';
import { makeRecordClass, ListOf, DateType, UUID, ListType, DateTimeType, MapType } from './record.js';
import Immutable from 'immutable';
import { dateConverter } from './dateconverter.js';


class InvoicePhase extends makeRecordClass({
	phaseId: null,

	phaseName: null,
	projectName: null,
	phaseCode: null,
	projectCode: null,
	phaseFee: null,
	phasePreviousPercent: null,
	phasePercent: null,
	phasePreviousBilled: null,
	phaseCurrentBilled: null,
	phaseToDateBilled: null,

	phaseTotalExTax: null,
	phaseTotalTax: null,
	phaseTotalIncTax: null,

	expensesTotal: {},
	expensesPreviousBilled: {},
	expensesCurrentBilled: {},

	lineItems: ListOf(() => LineItem)
}) {
	_getLineItemPath(lineItemUuid) {
		for (let [lineItemIndex, lineItem] of enumerate(this.lineItems)) {
			if (lineItem.uuid === lineItemUuid) {
				return ["lineItems", lineItemIndex];
			}
		}
	}

	_getDescriptionTags() {
		return {
			phase: () => this.phaseName,
			project: () => this.projectName,
			phasecode: () => this.phaseCode,
			projectcode: () => this.projectCode,
			phasefee: () => `$${formatCurrencyWithCents(this.phaseFee)}`,
			phaseprogress: () => formatNumber0(this.phasePercent),
			remainingprogress: () => formatNumber0(100 - this.phasePercent),
			previousprogress: () => formatNumber0(this.phasePreviousPercent),
			currentbilled: () =>
				`$${formatCurrencyWithCents(this.phaseCurrentBilled)}`,
			previousbilled: () =>
				`$${formatCurrencyWithCents(this.phasePreviousBilled)}`,
			todatebilled: () =>
				`$${formatCurrencyWithCents(this.phaseToDateBilled)}`,
			remainingbilled: () =>
				`$${formatCurrencyWithCents(
					this.phaseFee - this.phaseToDateBilled
				)}`,
			hours: li => li.unitQuantity,
			staff: function(li) {
				return li.staffIds
					.map(id =>
						organisationStore.getStaffMemberById(id).getFullName()
					)
					.join(", ");
			},
			expensePrevious: li => {
				return `$${formatCurrencyWithCents(
					this.expensesPreviousBilled.get(li.expenseUuid)
				)}`},
			expenseCurrent: li =>
				`$${formatCurrencyWithCents(
					this.expensesCurrentBilled.get(li.expenseUuid)
				)}`,
			expenseToDate: li =>
				`$${formatCurrencyWithCents(
					this.expensesPreviousBilled.get(li.expenseUuid) + this.expensesCurrentBilled.get(li.expenseUuid)
				)}`,
			expenseRemaining: li =>
				`$${formatCurrencyWithCents(
					(this.expensesTotal.get(li.expenseUuid) - (this.expensesPreviousBilled.get(li.expenseUuid) + this.expensesCurrentBilled.get(li.expenseUuid)))*100
				)}`,
			expenseProgress: li =>
				`${formatNumber0(
					((this.expensesPreviousBilled.get(li.expenseUuid) + this.expensesCurrentBilled.get(li.expenseUuid)) / this.expensesTotal.get(li.expenseUuid))*100
				)}`,
			expensePreviousProgress: li =>
				`${formatNumber0(
					((this.expensesPreviousBilled.get(li.expenseUuid)) / this.expensesTotal.get(li.expenseUuid))*100
				)}`,
			expenseRemainingProgress: li =>
				`${formatNumber0(
					((this.expensesTotal.get(li.expenseUuid) - (this.expensesPreviousBilled.get(li.expenseUuid) + this.expensesCurrentBilled.get(li.expenseUuid))) / this.expensesTotal.get(li.expenseUuid))*100
				)}`
		};
	}

	get phase() {
		return (
			organisationStore.getProjectPhaseById(this.phaseId) ||
			organisationStore.getProjectPhaseById(-1)
		);
	}

	getAddedTimesheets() {
		return _.flatten(this.lineItems.toJS().map(li => li.timesheetIds));
	}

	updateLineItemDescriptionSource(lineItemUuid, value) {
		let self = this;
		return this.updateIn(this._getLineItemPath(lineItemUuid), function(li) {
			return li
				.set("descriptionSource", value)
				.updateDescription(self._getDescriptionTags());
		});
	}

	addLineItem(lineItem) {
		return this.update("lineItems", function(lineItems) {
			return lineItems.push(lineItem);
		}).updateTotals();
	}

	addLineItems(lineItems) {
		return this.update("lineItems", function(lis) {
			return lis.concat(lineItems);
		}).updateTotals();
	}

	updateTotals() {
		const totalAgreed = this.getPhaseTotal({
			billingType: "agreedFee",
			amount: true,
			tax: false
		});
		const thisPercentage = (totalAgreed / this.phaseFee) * 100;
		const percentage = this.phasePreviousPercent + thisPercentage;

		let expensesCurrentBilled = {};

		this.phase.expenses.forEach(e => {
			expensesCurrentBilled[e.uuid] = sum(
				this.lineItems
					.filter(li => li.expenseUuid === e.uuid)
					.map(li => li.lineTotalExTax)
			);
		});

		return this.merge({
			phasePercent: percentage,
			phaseCurrentBilled: totalAgreed,
			phaseToDateBilled: this.phasePreviousBilled + totalAgreed,
			phaseTotalExTax: sum(this.lineItems.map(li => li.lineTotalExTax)),
			phaseTotalTax: sum(this.lineItems.map(li => li.lineTotalTax)),
			phaseTotalIncTax: sum(this.lineItems.map(li => li.lineTotalIncTax)),
			expensesCurrentBilled: expensesCurrentBilled
		}).updateDescriptions();
	}

	getPhaseTotal({
		billingType = null,
		amount = true,
		tax = false,
		projectExpenses = true
	}) {
		const lineItems = this.lineItems.filter(
			li =>
				(billingType ? li.billingType === billingType : true) &&
				(!projectExpenses ? !li.expenseUuid : true)
		);
		let values = [];
		if (amount && tax) {
			values = lineItems.map(li => li.lineTotalIncTax);
		} else if (amount && !tax) {
			values = lineItems.map(li => li.lineTotalExTax);
		} else if (!amount && tax) {
			values = lineItems.map(li => li.lineTotalTax);
		}
		return sum(values);
	}

	updateDescriptions() {
		const tags = this._getDescriptionTags();
		return this.update("lineItems", function(lineItems) {
			return lineItems.map(li => li.updateDescription(tags));
		});
	}

	deleteLineItem(uuid) {
		return this.update("lineItems", lis =>
			lis.filterNot(li => li.uuid === uuid)
		).updateTotals();
	}

	setTaxRatePercent(taxRatePercent) {
		return this.update("lineItems", lineItems =>
			lineItems.map(li => li.setTaxRatePercent(taxRatePercent))
		).updateTotals();
	}
}


class LineItem extends makeRecordClass({
  uuid: UUID,
  billingType: null, // agreedFee, variation, reimbursement, note
  lineItemType: null,

  description: null,
  descriptionSource: null,

  unitQuantity: null,
  phasePercent: null,

  unitCost: null,
  isTaxed: null,

  lineTotalExTax: null,
  lineTotalTax: null,
  lineTotalIncTax: null,

  staffIds: ListType,
  timesheetIds: ListType,
  expenseUuid: null,
}) {
  setTaxRatePercent(taxRatePercent) {
    const tax = this.isTaxed ? (this.lineTotalExTax * taxRatePercent / 100) : 0;
    return this.merge({
      lineTotalTax: tax,
      lineTotalIncTax: this.lineTotalExTax + tax
    });
  }

  updateDescription(tags) {
    const description = processString(tags, this.descriptionSource, this);
    return (description !== this.description) ? this.set('description', description) : this;
  }
}


export const Invoice = class extends makeRecordClass({
			id: null,
			projectId: null,
			contactId: null,
			createdAt: DateTimeType,
			issuedOn: DateType,
			startDate: DateType,
			endDate: DateType,
			dueDate: DateType,
			description: null,
			accountingSettings: MapType,
			phases: ListOf(() => InvoicePhase),
			taxRatePercent: null,
			totalExTax: null,
			totalTax: null,
			totalIncTax: null,
			accountingSystemId: null,
			accountingSystemInvoiceId: null
		}) {
			static getClassName() {
				return "Invoice";
			}

			get path() {
				if (this.id == null) {
					throw new Error("Invoice with no id has no path");
				} else {
					return `/dashboard/invoices/${this.id}`;
				}
			}

			get project() {
				return organisationStore.getProjectById(this.projectId);
			}

			get contact() {
				return this.contactId != null
					? organisationStore.getContactById(this.contactId)
					: null;
			}

			getAddedTimesheets() {
				return _.flatten(
					this.phases.map(ph => ph.getAddedTimesheets()).toJS()
				);
			}

			getSortedPhases() {
				return this.phases.sort(
					compareMultiple(
						(a, b) =>
							(a.phase.startDate != null ? 0 : 1) -
							(b.phase.startDate != null ? 0 : 1),
						(a, b) => a.phase.startDate - b.phase.startDate,
						(a, b) =>
							a.phase.getTitle().localeCompare(b.phase.getTitle())
					)
				);
			}

			getPhase(phaseId) {
				const matchingPhases = this.phases
					.toJS()
					.filter(ph => ph.phaseId === phaseId);
				return matchingPhases.length ? matchingPhases[0] : undefined;
			}

			matchesSearch(searchStr) {
				if (searchStr == null || searchStr === "") {
					return true;
				}
				return (
					this.description
						.toLowerCase()
						.indexOf(searchStr.toLowerCase()) >= 0
				);
			}

			ensureDefaultAccountingSystemSettingsExist({
				overwrite = false
			} = {}) {
				let accountingSystemIdentifier =
					organisationStore.organisation.accountingSystem;
				if (
					overwrite ||
					this.accountingSettings[accountingSystemIdentifier] == null
				) {
					// Set up the default settings if this is a new invoice, or the user has changed
					// the accounting system since creating the invoice and data for the new accounting
					// system doesn't yet exist.
					this.accountingSettings[
						accountingSystemIdentifier
					] = organisationStore.getCurrentAccountingSystem().settings;
				}
			}

			_getPhasePathFromPhaseId(phaseId) {
				for (let [phaseIndex, phase] of enumerate(this.phases)) {
					if (phase.phaseId === phaseId) {
						return ["phases", phaseIndex];
					}
				}
			}

			_getPhasePath(lineItemUuid) {
				for (let [phaseIndex, phase] of enumerate(this.phases)) {
					if (
						phase.lineItems.find(li => li.uuid === lineItemUuid) !=
						null
					) {
						return ["phases", phaseIndex];
					}
				}
			}

			_getLineItemPath(lineItemUuid) {
				let phaseIndex, lineItemIndex;

				outerloop: for (let [i, phase] of enumerate(this.phases)) {
					for (let [j, lineItem] of enumerate(phase.lineItems)) {
						if (lineItem.uuid === lineItemUuid) {
							phaseIndex = i;
							lineItemIndex = j;
							break outerloop;
						}
					}
				}
				return ["phases", phaseIndex, "lineItems", lineItemIndex];
			}

			setLineItemField(lineItemUuid, field, value) {
				if (
					!_.include(
						[
							"descriptionSource",
							"billingType",
							"phasePercent",
							"unitQuantity",
							"unitCost",
							"isTaxed"
						],
						field
					)
				) {
					throw new Error("Invalid field");
				}
				if (field === "descriptionSource") {
					return this.updateIn(this._getPhasePath(lineItemUuid), p =>
						p.updateLineItemDescriptionSource(lineItemUuid, value)
					);
				} else {
					return this.updateIn(this._getPhasePath(lineItemUuid), p =>
						this.updateLineItemField(p, lineItemUuid, field, value)
					).updateTotals();
				}
			}

			addNewLineItem(phaseId, lineItemType) {
				let self = this;
				if (
					!_.include(
						["progress", "fixed", "expense", "note"],
						lineItemType
					)
				) {
					throw new Error("Not implemented");
				}

				return this.updateIn(
					this._getPhasePathFromPhaseId(phaseId),
					function(invoicePhase) {
						let lineItem;
						if (lineItemType === "progress") {
							lineItem = new LineItem({
								billingType: "agreedFee",
								lineItemType: "progress",
								descriptionSource: `[phase] ([phaseprogress]% completion)`,
								phasePercent: 0,
								unitCost: 0,
								isTaxed: true
							});
						} else if (
							lineItemType === "fixed" ||
							lineItemType === "expense"
						) {
							lineItem = new LineItem({
								billingType:
									lineItemType === "fixed"
										? "agreedFee"
										: "reimbursement",
								lineItemType: lineItemType,
								descriptionSource: "",
								unitCost: 0,
								unitQuantity: 0,
								isTaxed: true
							});
						} else if (lineItemType === "note") {
							lineItem = new LineItem({
								billingType: "note",
								lineItemType: lineItemType,
								descriptionSource: "",
								unitCost: 0,
								unitQuantity: 0,
								isTaxed: false
							});
						} else {
							throw new Error("Internal error");
						}

						return invoicePhase
							.addLineItem(
								self.updateLineItemTotal(invoicePhase, lineItem)
							)
							.updateDescriptions();
					}
				).updateTotals();
			}

			updateLineItemField(invoicePhase, lineItemUuid, field, value) {
				let self = this;
				return invoicePhase
					.updateIn(
						invoicePhase._getLineItemPath(lineItemUuid),
						function(li) {
							return self.updateLineItemTotal(
								invoicePhase,
								li.set(field, value)
							);
						}
					)
					.updateTotals();
			}

			updateLineItemTotal(invoicePhase, lineItem) {
				let exTax;
				if (lineItem.lineItemType === "progress") {
					exTax =
						(invoicePhase.phaseFee * lineItem.phasePercent) / 100;
				} else {
					exTax = lineItem.unitCost * lineItem.unitQuantity;
				}

				const tax = lineItem.isTaxed
					? (exTax * this.taxRatePercent) / 100
					: 0;
				const incTax = exTax + tax;
				return lineItem.merge({
					lineTotalExTax: exTax,
					lineTotalTax: tax,
					lineTotalIncTax: incTax
				});
			}

			addNewTimesheetItem({
				phaseId,
				combineType,
				staffMemberIds,
				task,
				isBillable,
				isVariation,
				timesheetEntries
			}) {
				let self = this;

				const queryArgs = [
					timesheetEntries,
					organisationStore.getProjectPhaseById(phaseId),
					task,
					{
						isBillable: isBillable,
						isVariation: isVariation,
						staffMemberIds: staffMemberIds
					}
				];

				function makeLineItem(invoicePhase, args) {
					return self.updateLineItemTotal(
						invoicePhase,
						new LineItem({
							billingType: "agreedFee",
							lineItemType: "timesheets",
							isTaxed: true,
							descriptionSource: "[phase] - [staff]",
							...args
						})
					);
				}

				return this.updateIn(
					this._getPhasePathFromPhaseId(phaseId),
					function(invoicePhase) {
						switch (combineType) {
							case "combined": {
								let totalHours = 0,
									totalChargeOut = 0,
									staffIds = [],
									combinedTimeEntryIds = [];
								const staffDataLookup = getStaffDataLookup(
									...queryArgs
								);
								_.each(staffDataLookup, function({
									staffId,
									chargeOutRate,
									numHours,
									timeEntryIds
								}) {
									staffIds.push(staffId);
									totalHours += numHours;
									totalChargeOut += chargeOutRate * numHours;
									combinedTimeEntryIds = [
										...combinedTimeEntryIds,
										...timeEntryIds
									];
								});
								const chargeOutRate =
									totalHours > 0
										? totalChargeOut / totalHours
										: 0;
								return invoicePhase.addLineItem(
									makeLineItem(invoicePhase, {
										staffIds: Immutable.List(staffIds),
										unitQuantity: totalHours,
										unitCost: chargeOutRate,
										timesheetIds: Immutable.List(
											combinedTimeEntryIds
										)
									})
								);
							}
							case "staff": {
								let lineItems = [];
								const staffDataLookup = getStaffDataLookup(
									...queryArgs
								);
								_.each(staffDataLookup, function({
									staffId,
									staffName,
									numHours,
									chargeOutRate,
									timeEntryIds
								}) {
									lineItems.push(
										makeLineItem(invoicePhase, {
											staffIds: Immutable.List([staffId]),
											unitQuantity: numHours,
											unitCost: chargeOutRate,
											timesheetIds: Immutable.List(
												timeEntryIds
											)
										})
									);
								});
								return invoicePhase.addLineItems(lineItems);
							}
							case "timesheetEntry": {
								const matchingEntries = _.sortBy(
									getMatchingEntries(...queryArgs),
									te => dateConverter.momentToInt(te.date)
								);
								return invoicePhase.addLineItems(
									matchingEntries.map(function(te) {
										let descriptionSource = `${te.date.format(
											"DD/MM/YYYY"
										)}: [phase] - [staff]`;
										if (
											te.notes != null &&
											te.notes !== ""
										) {
											descriptionSource +=
												"\n" + te.notes;
										}
										return makeLineItem(invoicePhase, {
											billingType: te.isVariation
												? "variation"
												: "agreedFee",
											staffIds: Immutable.List([
												te.staffMember.id
											]),
											unitQuantity: te.numMinutes / 60,
											unitCost: te.chargeOutRate,
											descriptionSource: descriptionSource,
											timesheetIds: Immutable.List([
												te.id
											])
										});
									})
								);
							}
							default:
								throw new Error("Unrecognised combineType");
						}
					}
				).updateTotals();
			}

			deleteLineItem(phaseId, lineItemUuid) {
				return this.updateIn(
					this._getPhasePathFromPhaseId(phaseId),
					function(invoicePhase) {
						return invoicePhase.deleteLineItem(lineItemUuid);
					}
				).updateTotals();
			}

			setIssuedOn(issuedOn) {
				return this.merge({
					issuedOn: issuedOn,
					dueDate: issuedOn
						.clone()
						.add(
							organisationStore.numDaysBetweenIssueDateAndDueDate,
							"days"
						)
				});
			}

			setAccountingSettings(
				accountingSystemId,
				accountingSettings,
				defaultTaxRate
			) {
				return this.setIn(
					["accountingSettings", accountingSystemId],
					accountingSettings
				).setTaxRatePercent(defaultTaxRate);
			}

			setTaxRatePercent(taxRatePercent) {
				return this.set("taxRatePercent", taxRatePercent).update(
					"phases",
					phases =>
						phases.map(p => p.setTaxRatePercent(taxRatePercent))
				);
			}

			updateTotals() {
				return this.merge({
					totalExTax: sum(this.phases.map(li => li.phaseTotalExTax)),
					totalTax: sum(this.phases.map(li => li.phaseTotalTax)),
					totalIncTax: sum(this.phases.map(li => li.phaseTotalIncTax))
				});
			}

			setDates(startDate, endDate) {
				return this.merge({
					startDate: startDate,
					endDate: endDate
				});
			}

			getSelectedExpenses() {
				const expenseUuids = this.phases
					.find(ip => ip.phaseId === -1)
					.lineItems.filter(li => li.expenseUuid != null)
					.map(li => li.expenseUuid);
				return this.project.expenses.filter(e =>
					expenseUuids.contains(e.uuid)
				);
			}

			syncExpenses(expensesToAdd, expensesToRemove) {
				let inv = this;

				for (let e of expensesToAdd) {
					const phasePath = inv._getPhasePathFromPhaseId(e.phase?.id || -1);
					inv = inv.updateIn(phasePath, function(invoicePhase) {
						const lineItem = new LineItem({
							billingType: "reimbursement",
							lineItemType: "expense",
							description: e.name,
							descriptionSource: e.name,

							// Expense unit cost / quantity may be strings but the invoice requires numbers.
							unitCost: parseFloat(e.remainingCost),
							unitQuantity: 1,
							isTaxed: false,
							expenseUuid: e.uuid
						});

						return invoicePhase
							.addLineItem(
								inv.updateLineItemTotal(invoicePhase, lineItem)
							)
							.updateDescriptions();
					});
				}

				for (let e of expensesToRemove) {
					const phasePath = inv._getPhasePathFromPhaseId(
						e.phase?.id || -1
					);
					inv.updateIn(phasePath, function(invoicePhase) {
						return invoicePhase.update("lineItems", lineItems =>
							lineItems.filter(function(li) {
								return li.expenseUuid !== e.uuid;
							})
						);
					});
				}
				return inv.updateTotals()
			}

			validate() {
				const validators = {
					description: () =>
						this.description != null && this.description !== "",
					nonzero: () => this.totalExTax > 0
				};
				return validate(validators);
			}
		};


function validate(validators) {
  let isValid = true, errors = {};
  _.each(validators, function(func, key) {
    if (!func()) {
      isValid = false;
      errors[key] = true;
    }
  });
  return {isValid, errors};
}
