import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { organisationStore } from "../organisation.js";
import { timesheetActions, EntriesState } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { StaffMemberSelector, LoadingSpinner, ErrorAlert, IntercomMessageLink, DateValue } from '../widgets.js';
import { TimesheetNav, PhaseBlock } from './widgets.js';
import { SaveStatus } from './utils.js';
import { getMonday, groupBy } from '../utils.js';
import { EditEntryModal } from './EditEntryModal.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { EditEntryNoteModal } from './EditEntryNoteModal.js';
import { TimesheetReportPageWrapper } from './TimesheetReportsPage.js';
import { requiresPermission, permissions } from '../models/permissions.js';
import { getOnboardingData } from '../organisationStore.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import { userStore } from '../user/flux.js';
import { wrapUserStore } from '../user/utils.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { BlockTabs } from '../widgets/tabs.js';
import { rootStore } from '../RootStore.js';
import PropTypes from "prop-types";

export { TimesheetReportPageWrapper };


/**
  router
  -> TimesheetsPage
    -> TimesheetsPageInner
      -> TimesheetComponent
        -> TimesheetWidget(mode='weekly' | 'daily')
          -> PhaseBlock*
            -> TaskRow*
              -> WeeklyTimeEntry*
*/


function getDateFromParam(dateParam) {
  if (dateParam === 'current') {
    return moment();
  }
  else {
    return moment(dateParam, "YYYY-MM-DD");
  }
}


export var TimesheetsPage = requiresPermission(
  permissions.noRestrictions,
  {
    staffSelectorPermission: permissions.otherStaffTimesheets
  },
  CreateReactClass({
    mixins: [
      makeMultipleStoreMixin([rootStore], function() {
        return {
          store: rootStore.stores['my-timesheets']
        };
      }),
    ],

    componentWillMount: function() {
      rootStore.loadTimesheets(getDateFromParam(this.props.routeParams.date));
    },

    componentWillReceiveProps: function(nextProps) {
      if (nextProps.routeParams.date !== this.props.routeParams.date) {
        rootStore.loadTimesheets(getDateFromParam(nextProps.routeParams.date));
      }
    },

    render: function() {
      if (this.state.store == null) {
        return null;
      }
      /**
       * We have `TimesheetsPageInner` in between `TimesheetsPage` and
       * `TimesheetComponent` just so `TimesheetsPage` can create the `store`
       * prop and then `TimesheetsPageInner` can assume the store is already
       * there and we don't have to worry about whether the component's
       * `componentWillMount` is called before or after the mixin setup.
       */
      return <TimesheetsPageInner
        user={this.props.user}
        store={this.state.store}
        showStaffSelector={this.props.staffSelectorPermission}
      />;
    }
  })
);



var TimesheetsPageInner = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([rootStore, organisationStore, userStore], function() {
      let store = this.props.store;
      return {
        onboardingData: getOnboardingData(organisationStore, permissions, userStore),
        headingText: store.getHeadingText(),
        autosave: store.autosave,
        saveStatus: store.getSaveStatus(),
        mode: store.mode,
        disableNavigation: store.isNavigationDisabled,
        currentUser: store.user,
        hasProjects: organisationStore.projects != null && organisationStore.projects.length > 0,
      };
    })
  ],

  componentDidMount() {
    window.scrollTo(0, 0)
  },

  render: function() {
    if (!this.state.onboardingData.hasProjects) {
      return <IncompleteSetupPage
        heading="Timesheets"
        onboardingData={this.state.onboardingData}
      />;
    }
    else {
      return <CoincraftPage
        header={<div>
          <div>
            <div
                className="timesheet__heading heading"
                style={{display: 'inline-block', marginRight: '1em', marginTop: '0.1em'}}>
              {this.state.headingText}
            </div>
          </div>
          <div style={{color: 'white'}}>
            {'Try our mobile timesheet app at '}
            <a
                href="https://m.coincraft.co"
                target="_blank"
                style={{color: '#ffc700'}}>
              m.coincraft.co
            </a>
          </div>
          <div className="inline-flexbox-container flex-align-items-center">
            <button
                className="btn btn-default add-timesheet-entry-button"
                style={{margin: '1em'}}
                onClick={this.handleAddTimeEntryButtonClick}
                disabled={!this.state.hasProjects || this.state.disableNavigation}>
              + Add time entry
            </button>
            {this.props.showStaffSelector ?
              <StaffMemberSelector
                className="timesheet__staff-selector"
                value={this.state.currentUser}
                allowNull={false}
                onChange={this.handleStaffMemberChange}
                selectProps={{
                  readOnly: this.state.disableNavigation
                }}
                dropLeft={false}
              />
            : null}
            <DateValue
              value={this.props.store.date}
              isEditable={true}
              onChange={(date) => this.props.store.setDate(date)}
              style={{width: '10em', marginLeft: '1em'}}
            />
          </div>
        </div>}
        body={
          <div className="timesheet-page" style={{maxWidth: '85em'}}>
            <TimesheetComponent
              store={this.props.store}
            />
          </div>
        }
        saveBar={
          <SaveStatus
            autosave={this.state.autosave}
            status={this.state.saveStatus}
          />
        }
        tabs={
          <BlockTabs
            value={this.state.mode}
            onChange={this.handleTabChange}
            tabs={[
              {
                label: 'Daily',
                value: 'daily',
                props: {className: classNames("timesheet__daily-button", {active: this.state.mode === 'daily'})},
                disabled: this.state.disableNavigation
              },
              {
                label: 'Weekly',
                value: 'weekly',
                props: {className: classNames("timesheet__weekly-button", {active: this.state.mode === 'weekly'})},
                disabled: this.state.disableNavigation
              }
            ]}
          />
        }
      />;
    }
  },

  handleTabChange: function(tabName) {
    if (tabName === 'daily' && this.state.mode !== 'daily') {
      timesheetActions.switchToDailyView();
    }
    else if (tabName === 'weekly' && this.state.mode !== 'weekly') {
      timesheetActions.switchToWeeklyView();
    }
  },

  handleStaffMemberChange: function(staffMember) {
    timesheetActions.setStaffMember(staffMember);
  },

  handleAddTimeEntryButtonClick: function() {
    timesheetActions.addRow();
  }
});


export var TimesheetsPageWrapper = wrapUserStore(TimesheetsPage);


export var TimesheetComponent = CreateReactClass({
  propTypes: {
    store: PropTypes.object.isRequired,
    user: PropTypes.object,
  },

  mixins: [
    makeMultipleStoreMixin([rootStore, userStore, organisationStore], function() {
      let store = this.props.store;

      return {
        entriesState: store.entriesState,
        modals: store.modals,
        mode: store.mode,
        ready: store.ready,
        canCreateProject: permissions.canCreateProject.ok(userStore.user),
        hasProjects: organisationStore.projects != null && organisationStore.projects.length > 0,
      };
    })
  ],

  render: function() {
    if (this.state.entriesState === EntriesState.error) {
      return <div>
        <ErrorAlert>
          {'There was a problem retrieving your timesheet entries. If the problem persists, please '}
          <IntercomMessageLink label="get in touch" />.
        </ErrorAlert>
      </div>;
    }

    if (!this.state.ready) {
      return null;
    }

    return <div>
      {this.state.modals.map(function(modal, i) {
        if (modal.type === 'editEntry') {
          return <EditEntryModal
            key={i}
            modal={modal}
            row={modal.row}
            timesheetEntryPath={modal.timesheetEntryPath}
            project={modal.project}
            projectPhase={modal.projectPhase}
            onSubmit={function (modal, row, timesheetEntryPath, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced) {
              timesheetActions.submitEditEntry(modal, row, timesheetEntryPath, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced);
            }}
            onClose={function() {
              timesheetActions.closeModal(modal);
            }}
          />;
        }
        else if (modal.type === 'editEntryNote') {
          return <EditEntryNoteModal
            key={i}
            modal={modal}
            entry={modal.entry}
            timesheetEntryPath={modal.timesheetEntryPath}
          />;
        }
      })}

      <div className="new-dashboard__panel new-dashboard__panel__inner timesheet" style={{padding: 0}}>
        <TimesheetWidget
          store={this.props.store}
          mode={this.state.mode}
        />
      </div>
    </div>;
  }
});


var TimesheetWidget = CreateReactClass({
  propTypes: {
    store: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['weekly', 'daily']).isRequired
  },

  mixins: [
    PureRenderMixin,
    makeMultipleStoreMixin([rootStore, userStore], function() {
      const store = this.props.store;

      return {
        entriesState: store.entriesState,
        budgetState: store.budgetState,

        costCentreGroups: store.costCentreGroups,
        state: store.state,

        startDate: store.startDate,
        endDate: store.endDate,
        dailyDate: store.date,

        hasSavedAtLeastOnce: store.hasSavedAtLeastOnce,
        user: store.getUser(),
        disableNavigation: store.isNavigationDisabled,
        dayTotals: store.dayTotals,
        hasEntries: store.hasEntries(),
        highlightedRowPath: store.highlightedRowPath,

        hasTimesheetEntries: userStore.user.hasTimesheetEntries
      };
    })
  ],

  render: function() {
    let self = this;

    let date = (this.props.mode === 'weekly') ? this.state.startDate : this.state.dailyDate;
    if (date == null) {
      return <div>Loading...</div>;
    }
    let monday = getMonday(date);

    return (
      <div className="timesheet-widget">
        <TimesheetNav
          mode={this.props.mode}
          isDisabled={this.state.disableNavigation}
          dayTotals={this.state.dayTotals}
          monday={monday}
          selectedDate={this.state.dailyDate}
        />

        {this.state.entriesState === EntriesState.loading ?
          <div style={{padding: '4em', fontSize: '1.2em'}}>
            Loading Timesheet Entries <LoadingSpinner style={{marginLeft: '0.2em'}} />
          </div>
        :
          <div>
            <div>
              {this.state.hasEntries ?
                <div>
                  {this.state.costCentreGroups.map(function(costCentreGroup, costCentreGroupIndex) {
                    let costCentre = costCentreGroup.get('costCentre');
                    let projectPhaseGroups = costCentreGroup.get('projectPhaseGroups');

                    // Don't render anything if we can't find any rows in any phase blocks.
                    if (!projectPhaseGroups.find(pg => pg.get('rows').find(r => !r.get('isDeleted')) != null)) {
                      return null;
                    }

                    // The data structure doesn't group by project, just by cost
                    // centre and then phase, but we want to group by project.
                    let projectGroups = groupBy(
                      projectPhaseGroups,
                      pg => pg.get('project'),
                      project => project != null ? project.id : 0
                    );

                    return <div key={costCentreGroupIndex}>
                      <div
                          style={{
                            fontSize: '1.7em',
                            padding:'0.5em 0 0.5em 0',
                            textAlign: 'left',
                            borderBottom: 'solid 1px #aaa',
                            marginBottom: '0.8em'
                          }}>
                        {costCentre != null ? costCentre.name : "(No cost centre)"}
                      </div>

                      {projectGroups.map(function({grouper: project, items: phaseGroups}, projectGroupIndex) {
                        return (
                          <div
                              key={projectGroupIndex}
                              className="project-block">
                            <div
                                className="project-title"
                                style={{fontSize: '1.4em', margin: '1.2em 0 0.2em 0.6em', fontWeight: 600}}>
                              {project != null ? project.getTitle() : "(No project)"}
                            </div>

                            {phaseGroups.map(function(projectPhaseGroup, i) {
                              let project = projectPhaseGroup.get('project');
                              let projectPhase = projectPhaseGroup.get('projectPhase');
                              let rows = projectPhaseGroup.get('rows');

                              // Since `phaseGroups` is a sublist of
                              // `projectPhaseGroups`, the index in `phaseGroups`
                              // isn't the right `projectPhaseIndex`, so be extra
                              // careful to get the index in `projectPhaseGroups`
                              // because that's what we use to identify the object
                              // to update.
                              let projectPhaseGroupIndex = projectPhaseGroups.findIndex(pg => pg === projectPhaseGroup);

                              return <PhaseBlock
                                key={i}
                                staffMember={self.state.user}
                                projectPhaseGroup={projectPhaseGroup}
                                project={project}
                                phase={projectPhase}
                                costCentreGroupIndex={costCentreGroupIndex}
                                projectPhaseGroupIndex={projectPhaseGroupIndex}
                                highlightedRowPath={self.state.highlightedRowPath}
                                rows={rows}
                                mode={self.props.mode}
                                dailyDate={self.state.dailyDate}
                                budgetState={self.state.budgetState}
                              />;
                            })}
                          </div>
                        );
                      })}
                    </div>;
                  })}
                </div>
              :
                <div>
                  <div>
                    <div style={{fontSize: '2em', padding:'2em 0 1em 0', textAlign: 'left'}}>
                      No Time Entries
                    </div>
                    <div style={{textAlign: 'left', fontSize: '1.3em', lineHeight: '2em'}}>
                      There are no time entries for {this.props.mode === 'weekly' ? 'this week' : 'today'}.
                      <br />
                      Please add an entry by clicking the button below.
                    </div>
                  </div>
                </div>
              }
            </div>

            <button
                className="btn btn-lg btn-default add-timesheet-entry-button"
                onClick={this.handleAddEntryButtonClick}>
              + Add time entry
            </button>

            {!this.state.hasEntries && this.state.hasTimesheetEntries ?
              <button
                  className="btn btn-lg btn-primary timesheet-widget__copy-previous-button"
                  onClick={this.handleCopyPreviousClick}
                  style={{marginLeft: '1em'}}>
                Copy previous
              </button>
            : null}
          </div>
        }
      </div>
    );
  },

  handleAddEntryButtonClick: function() {
    timesheetActions.addRow();
  },

  handleCopyPreviousClick: function() {
    timesheetActions.copyPrevious();
  }
});
