import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import d3 from 'd3';
import { linkMouseOverToState, FpsCounter, makeAreaPath, middleOfThree,
  transformEventToSvgCoords, abbreviateNumber, LinearScale, stableSort,
} from './utils.js';
import { dateConverter } from './models.js';
import { organisationStore } from "./organisation.js";
import elementResizeDetectorMaker from "element-resize-detector";
import classNames from 'classnames';
import PropTypes from 'prop-types';

const COUNT_FPS = false;

function isPercentage(val) {
  return _.isString(val) && val.match('%');
}


export var CashFlowChart = CreateReactClass({
  propTypes: {
    renderer: PropTypes.object.isRequired,

    // percent-string: use this rather than height to make an aspect ratio.
    paddingBottom: PropTypes.string,

    showLegend: PropTypes.bool,

    // If unspecified or null, show scrollbar if and only if there is too much
    // data to fit into the viewport.
    showScrollbar: PropTypes.bool,

    overrideMinY: PropTypes.number,
    overrideMaxY: PropTypes.number,

    firstSelectedMonth: PropTypes.number,
    lastSelectedMonth: PropTypes.number,

    enableHoverTooltips: PropTypes.bool,

    yValueType: PropTypes.oneOf(['dollars', 'hours']),

    showXAxis: PropTypes.bool,

    // Currently data is always at least 12 months. This component is only used
    // with data taken from the milestones store.
    data: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.number.isRequired, // DateConverter number
      income: PropTypes.number.isRequired,
      spend: PropTypes.number.isRequired,
      prospectiveIncome: PropTypes.number,
      prospectiveSpend: PropTypes.number,
    })).isRequired,

    // `min` and `max` are optional: if not specified, they are the values
    // of the first and last data points respectively.
    min: PropTypes.number,  // DateConverter number
    max: PropTypes.number,  // DateConverter number

    manageOwnScrolling: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      width: 700,
      height: 400,

      paddingBottom: '50%',

      showLegend: true,
      incomeAxisName: "Income",
      spendAxisName: "Spend",

      enableHoverTooltips: true,

      showXAxis: true,

      min: null,
      max: null,

      yValueType: 'dollars',

      padding: {
        top: 80,
        left: 70,
        bottom: 70,
        right: 20
      },

      offsetWidth: 700,
      manageOwnScrolling: true
    };
  },

  getXScale: function() {
    return this.props.xScale;
  },

  getInitialState: function() {
    return {
      incomeOnTopHighlighted: false,
      spendOnTopHighlighted: false,
      key: 0,
      showTooltip: false,
      hoveredItem: null,

      offsetWidth: 700,
      offsetHeight: 350,
    };
  },

  componentDidMount: function() {
    var self = this;

    this.disableTooltip = false;
    this.reenableTooltipTimer = null;
    this.isPanning = false;

    var erd = elementResizeDetectorMaker();
    erd.listenTo(ReactDOM.findDOMNode(this.refs.container), this.handleResize);
    this.handleResize(ReactDOM.findDOMNode(this.refs.container));

    this.scroller = new SmoothScroller(function(pos) {
      let viewportScrollLeft = self.getXScale().yToX(pos);
      if (viewportScrollLeft < self.props.min) {
        viewportScrollLeft = self.props.min;
      }
      else if (viewportScrollLeft + 365 > self.props.max) {
        viewportScrollLeft = self.props.max - 366;
      }

      let actualPos = self.props.xScale.xToY(viewportScrollLeft);

      if (self.props.onScroll != null) {
        self.props.onScroll(viewportScrollLeft);
      }
      else {
        throw new Error("nope");
      }
      return actualPos;
    });

    this.fpsCounter = new FpsCounter(function(fps) {
      console.log("Graph fps: ", fps);
    });
  },


  componentWillReceiveProps: function(newProps) {
    if (newProps.viewportScrollLeft !== this.props.viewportScrollLeft
        // Don't do anything if the scroller is operational -- it's taking care of things itself.
        && this.scroller.ticker == null) {
      this.setScrollerOffset(newProps.viewportScrollLeft);
    }
  },


  setScrollerOffset(viewportScrollLeft) {
    // `this.scroller.offset` is a pixel value.
    this.scroller.offset = (viewportScrollLeft - this.props.min)
                           / (this.props.max - this.props.min)
                           * (this.props.offsetWidth * (100 - parseInt(this.props.padding.left)) / 100);
  },


  componentDidUpdate: function() {
    if (this.isScrollerOffsetSetup == null && this.props.offsetWidth != null) {
      this.setScrollerOffset(this.props.viewportScrollLeft);
      this.isScrollerOffsetSetup = true;
    }
  },


  handleResize: function(element) {
    this.setState({
      offsetWidth: element.offsetWidth,
      offsetHeight: element.offsetHeight
    });
  },

  getDateFromXCoord: function(x) {
    // Returns the date as an integer offset as per `dateConverter`.
    var ix = Math.round(this.xScale.yToX(x));
    return middleOfThree(0, ix, dateConverter.populatedToOffset);
  },

  getDataItemFromXCoord: function(x) {
    var realX = x - this.getYAxisOffsetWidth() + this.getXScale().xToY(this.props.viewportScrollLeft);
    var ix = _.sortedIndex(this.props.data, {date: this.getDateFromXCoord(realX)}, a => a.date);
    if (ix > 0) { ix--; }
    return this.props.data[ix];
  },

  fullDataWidth: function() {
    return this.props.xScale.xToY(this.props.max) - this.props.xScale.xToY(this.props.min);
  },

  viewportWidth: function() {
    if (isPercentage(this.props.width) && !isPercentage(this.props.padding.left)) {
      throw new Error("We don't know how to do this");
    }
    return 100 - parseInt(this.props.padding.left) - parseInt(this.props.padding.right) + '%';
  },

  viewportHeight: function() {
    if (isPercentage(this.props.width) && !isPercentage(this.props.padding.left)) {
      throw new Error("We don't know how to do this");
    }
    return 100 - parseInt(this.props.padding.top) - parseInt(this.props.padding.bottom) + '%';
  },

  viewportMaxScroll: function() {
    //TODO-new_date_converter
    return (this.fullDataWidth() - this.getInnerOffsetWidth()) - 1;
  },

  formatYAxisLabel: function(y) {
    if (this.props.yValueType === 'dollars') {
      return organisationStore.organisation.currencyFormatter.symbol + abbreviateNumber(y);
    }
    else {
      return y + "h";
    }
  },

  getYAxisOffsetWidth: function() {
    return parseInt(this.props.padding.left) / 100 * this.state.offsetWidth;
  },

  getInnerOffsetWidth: function() {
    return this.state.offsetWidth * (100 - parseInt(this.props.padding.left) - parseInt(this.props.padding.right)) / 100;
  },

  getInnerOffsetHeight: function() {
    return this.state.offsetHeight * (100 - parseInt(this.props.padding.top) - parseInt(this.props.padding.bottom)) / 100;
  },

  render: function() {
    var self = this;

    var data = this.props.data;

    var xScale = this.xScale = this.getXScale();

    if (COUNT_FPS) {
      this.fpsCounter.frame();
    }

    // If we have a horizontal line at the very top of the graph it looks wrong,
    // so multiply the max by 1.2 to give it a buffer.
    const yAdjustmentFactor = 1.2;

    var maxY = (this.props.overrideMaxY != null) ?
      this.props.overrideMaxY :
      _.max(data.map(v => self.props.renderer.maxY(v))) * yAdjustmentFactor;

    var minY = (this.props.overrideMinY != null) ?
      this.props.overrideMinY :
      Math.min(_.min(data.map(v => self.props.renderer.minY(v))), 0);

    var yScale = this.yScale = d3.scale.linear()
      .domain([minY, maxY])
      .range([this.getInnerOffsetHeight(), 0]);

    var yTicks = yScale.ticks([6]).filter(tick => tick !== 0);

    return (
      <div
          className={classNames(
            'cash-flow-graph', {
              'cash-flow-graph--income-on-top-highlighted': self.state.incomeOnTopHighlighted,
              'cash-flow-graph--spend-on-top-highlighted': self.state.spendOnTopHighlighted,
            }
          )}
          ref="container"
          style={{
            width: '100%',
            paddingBottom: this.props.paddingBottom,
            position: 'relative',
            'WebkitTouchCallout': 'none',
            'userSelect': 'none',
          }}
          onMouseDown={evt => self.onMouseDown(evt)}
          onMouseUp={evt => self.onMouseUp(evt)}
          onMouseMove={evt => self.onMouseMove(evt, data)}
          onMouseLeave={evt => self.onMouseLeave(evt)}>

        {/*
          The tooltip is HTML, not SVG, so we don't put it in with the main content.
          We could put it in a <foreignObject> but that does not seem to be supported by any
          version of IE: https://msdn.microsoft.com/en-us/library/hh834675(v=vs.85).aspx
        */}
        {this.state.showTooltip ? this.tooltip() : null}

        {this.props.showLegend ?
          <div style={{height: 60}}>
            <div style={{float: 'right', width: 440}}>
              <Legend
                incomeAxisName={this.props.incomeAxisName}
                spendAxisName={this.props.spendAxisName}
               />
            </div>
          </div>
        : null}

        <div style={{position: 'absolute', width: '100%', height: '100%'}}>
          <svg style={{width: '100%', height: '100%'}} ref="svg">
            <defs>
              <clipPath className="cash-flow-graph__clip-path" id="clippath">
                {/*
                  Clip the graph content strictly to the graph area. A bunch of magic
                  numbers here that happen to make it work ok.
                */}
                <rect
                  x={this.props.padding.left}
                  y={this.props.padding.top}
                  width={this.viewportWidth()}
                  height={this.viewportHeight()}
                />

                <rect
                  x={this.props.padding.left}
                  y={this.getInnerOffsetHeight()}
                  width={this.getInnerOffsetWidth()}
                  height={this.props.padding.bottom}
                />
              </clipPath>
            </defs>

            {/* y axis */}
            <g className="cash-flow-graph__y-axis">
              {yTicks.map(function(tick, i) {
                var y = yScale(tick);
                return (
                  <g className="tick" key={i}>
                    <text
                        dy=".32em"
                        x={parseInt(self.props.padding.left) - 1 + '%'}
                        y={y}
                        style={{textAnchor: 'end', fontSize: '1em'}}>
                      {self.formatYAxisLabel(tick)}
                    </text>
                  </g>
                );
              })}
            </g>

            <g clipPath="url(#clippath)" onClick={self.handleClick}>
              {yTicks.map(function(tick, i) {
                var y = yScale(tick);
                return (
                  <line
                    key={i}
                    className="grid-line"
                    x1={self.props.padding.left}
                    x2={"100%"}
                    y1={y}
                    y2={y}
                  />
                );
              })}

              <g
                  transform={`translate(
                    ${this.getYAxisOffsetWidth() - xScale.xToY(this.props.viewportScrollLeft)},
                    ${parseInt(this.props.padding.top) / 100 * this.state.offsetHeight}
                  )`}>

                <g className="cash-flow-graph__main-content" ref="mainContent" style={{cursor: 'pointer'}}>
                  {self.props.renderer.getArea(
                    self.props.data,
                    xScale,
                    yScale,
                    self.props.firstSelectedMonth,
                    self.props.lastSelectedMonth,
                    self.state.hoveredItem,
                    self.getInnerOffsetHeight()
                  )}
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  },

  innerHeight: function() {
    return this.props.height - this.props.padding.top - this.props.padding.left;
  },

  tooltip: function() {
    return (
      <div
          ref="tooltip"
          className="cash-flow-graph-tooltip"
          style={{
            left: this.xScale.xToY(this.state.hoveredItem.date + 15)
                  + this.props.padding.left
                  - 75, // minus half width
            top: 20,

            // Make sure this isn't behind the checkboxes or form data from
            // other widgets.
            zIndex: 100
          }}>

        {this.props.renderTooltipContent(this.state.hoveredItem)}
      </div>
    );
  },

  hideTooltip: function() {
    this.setState({showTooltip: false});
  },

  legend: function() {
    var legendLineSize = 11;

    var p = function(x) {
      return legendLineSize * x;
    };
    // A little triangle shape to denote the meaning of different coloured areas
    // in the legend.
    var trianglePath = `M 0 ${p(0.7)}   L ${p(0.4)} 0    L ${p(1.0)} ${p(0.4)}    z`;

    return (
      <div className="cash-flow-graph__legend row">
        <div className="col col-xs-4">
          <div>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <line x1={0} y1={legendLineSize} x2={legendLineSize} y2={0} className="cash-flow-graph__legend__income-line" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.incomeAxisName}
            </span>
          </div>
          <div>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <line x1={0} y1={legendLineSize} x2={legendLineSize} y2={0} className="cash-flow-graph__legend__spend-line" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.spendAxisName}
            </span>
          </div>
        </div>
        <div className="col col-xs-8">
          <div {...linkMouseOverToState(this, 'incomeOnTopHighlighted')}>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <path d={trianglePath} className="cash-flow-graph__legend__income-bigger-shape" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.incomeAxisName} &gt; {this.props.spendAxisName}
            </span>
          </div>
          <div {...linkMouseOverToState(this, 'spendOnTopHighlighted')}>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <path d={trianglePath} className="cash-flow-graph__legend__spend-bigger-shape" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.spendAxisName} &gt; {this.props.incomeAxisName}
            </span>
          </div>
        </div>
      </div>
    );
  },

  handleClick: function(e) {
    if (this.props.onClick != null) {
      let position = transformEventToSvgCoords(e, ReactDOM.findDOMNode(this.refs.svg));
      let selectedItem = this.getDataItemFromXCoord(position[0]);
      this.props.onClick(selectedItem.date);
    }
  },

  onMouseDown: function(e) {
    var position = transformEventToSvgCoords(e, ReactDOM.findDOMNode(this.refs.svg));
    this.scroller.tap(position[0]);
    this.isPanning = true;
  },

  onMouseUp: function(e) {
    if (this.isPanning) {
      var position = transformEventToSvgCoords(e, ReactDOM.findDOMNode(this.refs.svg));
      this.scroller.release(position[0]);
      this.isPanning = false;
      window.$("body").removeClass("disable-text-selection");
    }
  },

  onMouseMove: function(e, data) {
    var self = this;
    var position = transformEventToSvgCoords(e, ReactDOM.findDOMNode(this.refs.svg));

    /*
    if (this.state.showTooltip) {
      // If we have a visible tooltip, don't capture events if the mouse is
      // over the tooltip container.
      var rect = ReactDOM.findDOMNode(this.refs.tooltip).getBoundingClientRect();
      if (rect.left <= e.clientX && e.clientX <= rect.right &&
          rect.top <= e.clientY && e.clientY <= rect.bottom) {
        return;
      }
    }
    */

    if (this.isPanning) {
      window.$("body").addClass("disable-text-selection");
      this.scroller.drag(position[0]);

      this.setState({showTooltip: false});

      // It's annoying to have the tooltip appearing while you're trying to
      // pan, so when the user pans, disable the tooltip for one second.
      this.disableTooltip = true;
      if (this.reenableTooltipTimer != null) {
        clearTimeout(this.reenableTooltipTimer);
      }
      this.reenableTooltipTimer = setTimeout(function() {
        self.disableTooltip = false;
      }, 1000);

      e.preventDefault();
    }
    else {
      if (this.props.onClick != null) {
        // Don't show hovered item feedback if there's no click handler.
        var hoveredItem = this.getDataItemFromXCoord(position[0]);
        var newState = {hoveredItem: hoveredItem};
        if (this.props.enableHoverTooltips && !this.disableTooltip) {
          newState.showTooltip = true;
        }
        this.setState(newState);
      }
    }
  },

  onMouseLeave: function(e, data) {
    window.$("body").removeClass("disable-text-selection");

    this.setState({
      mouseDown: false,
      hoveredItem: null,
      showTooltip: false
    });
  }
});


export var SizeAwareContainer = CreateReactClass({
  getInitialState: function() {
    return {
      offsetWidth: null,
      offsetHeight: null
    };
  },

  componentDidMount: function() {
    var erd = elementResizeDetectorMaker();
    var el = ReactDOM.findDOMNode(this);
    erd.listenTo(el, this.handleResize);
    this.handleResize(el);
  },

  handleResize: function(element) {
    this.setState({
      offsetWidth: element.offsetWidth,
      offsetHeight: element.offsetHeight
    });
  },

  render: function() {
    let self = this;
    return <div style={{width: '100%', height: '100%'}} ref="container">
      {this.state.offsetWidth != null && this.state.offsetHeight != null ?
        React.Children.map(this.props.children, function(child) {
          return React.cloneElement(child, {
            offsetWidth: self.state.offsetWidth,
            offsetHeight: self.state.offsetHeight
          });
        })
      : null}
    </div>;
  }
});


export var ScrollBar = CreateReactClass({
  propTypes: {
    viewportWidth: PropTypes.number,
    scrollX: PropTypes.number.isRequired, // int-date
    min: PropTypes.number.isRequired, // int-date
    max: PropTypes.number.isRequired  // int-date
  },

  fullDataWidth: function() {
    return this.props.xScale.xToY(this.props.max) - this.props.xScale.xToY(this.props.min);
  },

  getPixelScale: function() {
    return new LinearScale({
      minX: this.props.min,
      maxX: this.props.max,
      minY: 0,
      maxY: this.props.viewportWidth
    });
  },

  viewportWidth: function() {
    return parseInt(this.props.viewportWidth);
  },

  sliderLeft: function() {
    // The width of the part of the slider that moves.
    return this.props.xScale.xToY(this.props.scrollX) / this.fullDataWidth() * this.viewportWidth();
  },

  sliderWidth: function() {
    // The width of the part of the slider that moves.
    return parseInt(this.viewportWidth()) / this.fullDataWidth() * this.viewportWidth();
  },

  sliderMaxX: function() {
    //TODO-new_date_converter
    // I don't quite understand why the -1 is necessary.
    return this.getPixelScale().yToX(this.viewportWidth() - this.sliderWidth()) - 1;
  },

  constrainScrollX: function(scrollX) {
    return middleOfThree(this.props.min, scrollX, this.sliderMaxX());
  },

  render: function() {
    if (this.props.viewportWidth == null) {
      // Don't render if we're not ready yet.
      return null;
    }

    let viewportWidth = this.viewportWidth();
    let sliderWidth = this.sliderWidth();

    if (viewportWidth !== viewportWidth || sliderWidth !== sliderWidth) {
      // Don't try to render rects if the widths are NaN.
      return null;
    }

    return <g ref="g">
      <rect
        x={0}
        y={0}
        rx={5}
        ry={5}
        width={viewportWidth}
        height={20}
        fill="#c4c8c4"
      />
      <rect
        x={this.sliderLeft()}
        y={0}
        rx={5}
        ry={5}
        height={20}
        width={sliderWidth}
        fill="white"
        stroke="#888888"
        strokeWidth={1}
        onMouseDown={this.sliderScrollStart}
        onMouseUp={this.sliderScrollUp}
      />
    </g>;
  },

  getSvg: function() {
    return window.$(ReactDOM.findDOMNode(this.refs.g)).parents("svg")[0];
  },

  sliderScrollStart: function(e) {
    var self = this;
    var position = transformEventToSvgCoords(e, this.getSvg());

    this.sliderMouseDown = true;
    this.sliderMouseX = position[0];

    var f = function(e) {
      self.sliderScrollMove(e);
    };
    var $body = window.$("body");

    // If the user moves the mouse quickly, it will easily be able to move
    // outside the slider area, but we don't want that to stop the scroll
    // events from happening.  So attach the mousemove event to the `body`
    // element, making sure to clear it on mouseup.
    $body.on("mouseup", function() {
      $body.off("mousemove", f);
    });
    $body.on("mousemove", f);

    e.preventDefault();
    e.stopPropagation();
  },

  sliderScrollMove: function(e) {
    var self = this;

    if (this.sliderMouseDown) {
      var position = transformEventToSvgCoords(e, this.getSvg());

      // Pixels from left of svg.
      // HELP we might even need to care about padding here. Or maybe not as the scale might
      // already do that.
      //
      // Ok we are now deciding that the xscale does that.
      var x = position[0];

      var pixelScale = this.getPixelScale();

      var origSliderLeftPixels = pixelScale.xToY(this.props.scrollX);
      var newSliderLeftPixels = origSliderLeftPixels + (x - this.sliderMouseX);
      var newViewportScrollLeftLogical = pixelScale.yToX(newSliderLeftPixels);

      // Value in between `this.props.min` and `this.props.max`.
      var viewportScrollLeftLogical = self.constrainScrollX(newViewportScrollLeftLogical);

      if (this.props.onScroll != null) {
        this.props.onScroll(viewportScrollLeftLogical);
      }

      this.sliderMouseX = x;

      this.disableTooltip = true;
      if (this.reenableTooltipTimer != null) {
        clearTimeout(this.reenableTooltipTimer);
      }
      this.reenableTooltipTimer = setTimeout(function() {
        self.disableTooltip = false;
      }, 1000);
    }
    e.preventDefault();
    e.stopPropagation();
  },

  sliderScrollEnd: function(e) {
    this.sliderMouseDown = false;
    e.preventDefault();
    e.stopPropagation();
  },
});



export var Legend = CreateReactClass({
  propTypes: {
    incomeAxisName: PropTypes.string.isRequired,
    spendAxisName: PropTypes.string.isRequired,
  },


  render: function() {
    var legendLineSize = 11;

    var p = function(x) {
      return legendLineSize * x;
    };
    // A little triangle shape to denote the meaning of different coloured areas
    // in the legend.
    var trianglePath = `M 0 ${p(0.7)}   L ${p(0.4)} 0    L ${p(1.0)} ${p(0.4)}    z`;

    //TODO-invoice_data_in_cashflow: hook up events to actual graph.
    return (
      <div className="cash-flow-graph__legend row">
        <div className="col col-xs-4">
          <div>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <line x1={0} y1={legendLineSize} x2={legendLineSize} y2={0} className="cash-flow-graph__legend__income-line" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.incomeAxisName}
            </span>
          </div>
          <div>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <line x1={0} y1={legendLineSize} x2={legendLineSize} y2={0} className="cash-flow-graph__legend__spend-line" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.spendAxisName}
            </span>
          </div>
        </div>
        <div className="col col-xs-8">
          <div {...linkMouseOverToState(this, 'incomeOnTopHighlighted')}>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <path d={trianglePath} className="cash-flow-graph__legend__income-bigger-shape" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.incomeAxisName} &gt; {this.props.spendAxisName}
            </span>
          </div>
          <div {...linkMouseOverToState(this, 'spendOnTopHighlighted')}>
            <svg style={{width: legendLineSize, height: legendLineSize}}>
              <path d={trianglePath} className="cash-flow-graph__legend__spend-bigger-shape" />
            </svg>
            <span style={{marginLeft: 6}}>
              {this.props.spendAxisName} &gt; {this.props.incomeAxisName}
            </span>
          </div>
        </div>
      </div>
    );
  }
});





export const SuperimposedDataRenderer = class {
  constructor({includeLines = true, includeCircles = true} = {}) {
    this.includeLines = includeLines;
    this.includeCircles = includeCircles;
  }

  maxY(monthData) {
    return Math.max(monthData.spend, monthData.income);
  }

  minY(monthData) {
    return Math.min(monthData.spend, monthData.income);
  }

  getArea(data, xScale, yScale, firstSelectedMonth, lastSelectedMonth, hoveredItem, innerHeight) {
    // Note: This curently only makes sense for monthly data.  We want the
    // points for each month to appear in the middle of that month.  Ie., if
    // the graph starts on October 2015, we want the left-most point on the
    // graph to be October 1, but the point that represents the data for
    // October should be half way between the point for October 1 and the point
    // for November 1. So we shuffle the data a little bit adding 0.5 to each
    // x value.

    const halfMonth = 15;
    const hasProspectiveIncome = data[0].prospectiveIncome !== undefined
    const hasProspectiveSpend = data[0].prospectiveSpend !== undefined
    let last = _.last(data);
    let points = [
      _.clone(data[0]),
      ...data.map(function(d) {
        return {
          ...d,
          originalX: d.date,
          date: d.date + halfMonth
        };
      }),
      {
        ..._.clone(last),
        date: last.date + 30,
      }
    ];

    var withIntersects = this.withIntersections(points);
    var withIntersectsProspectiveIncome = this.withIntersectionsProspectiveIncome(points);
    var withIntersectsProspectiveSpend = this.withIntersectionsProspectiveSpend(points);

    var incomeLineFunc = function() {
      return d3.svg.line()
        .x(function(v) { return xScale.xToY(v.date); })
        .y(function(v) { return yScale(v.income); });
    };
    var spendLineFunc = function() {
      return d3.svg.line()
        .x(function(v) { return xScale.xToY(v.date); })
        .y(function(v) { return yScale(v.spend); });
    };

    var prospectiveIncomeLineFunc = function () {
      return d3.svg.line()
        .x(function (v) { return xScale.xToY(v.date); })
        .y(function (v) { return yScale(v.income-(v.prospectiveIncome || 0)); });
    };

    var prospectiveSpendLineFunc = function () {
      return d3.svg.line()
        .x(function (v) { return xScale.xToY(v.date); })
        .y(function (v) { return yScale(v.spend - (v.prospectiveSpend || 0)); });
    };

    var startIndex = 0;

    return <g>
      {hoveredItem != null ?
        <rect
          x={xScale.xToY(hoveredItem.date)}
          y={0}
          width={xScale.xToY(30) - xScale.xToY(0)}
          height={innerHeight}
          fill="#fcfcfc"
          style={{opacity: 0.4}}
          // style for lines at sides of rectangle - problem is when y-axis scales
          // , strokeDasharray: "0px, " + (xScale.xToY(30) - xScale.xToY(0)) +"px, 250px, " + (xScale.xToY(30) - xScale.xToY(0)) +"px, 250px", stroke: "#cccccc"
        />
      : null}

      {firstSelectedMonth != null ?
        <rect
          x={xScale.xToY(firstSelectedMonth)}
          y={0}
          width={xScale.xToY(lastSelectedMonth + 30) - xScale.xToY(firstSelectedMonth)}
          height={innerHeight}
          fill="#fafafa"
          style={{opacity: 0.85}}
        />
      : null}

      {withIntersects.map(function(v, i) {
        if (v.isIntersection || i == withIntersects.length - 1) {
          var slice = withIntersects.slice(startIndex, i + 1);

          var topPath = makeAreaPath(slice,
            function(m) { return xScale.xToY(m.date); },
            function(m) { return yScale(Math.min(m.income, m.spend)); },
            function(m) { return yScale(Math.max(m.income, m.spend)); }
          );

          var firstDifferentPoint = _.find(slice, v => v.income !== v.spend);
          var className =
            (firstDifferentPoint == null || firstDifferentPoint.income > firstDifferentPoint.spend) ?
            'cash-flow-graph__income-on-top-area' :
            'cash-flow-graph__spend-on-top-area';

          startIndex = i;

          return <path d={topPath} className={className} key={i} />;
        }
      })}

      <path d={
        makeAreaPath(withIntersects,
          function(m) { return xScale.xToY(m.date); },
          function(m) { return yScale(0); },
          function(m) { return yScale(Math.min(m.income, m.spend)); }
        )}
        className="cash-flow-graph__bottom-area" />

      {hasProspectiveIncome ?
        <path d={
          makeAreaPath(withIntersectsProspectiveIncome,
            function (m) { return xScale.xToY(m.date); },
            function (m) { return yScale(m.income - m.prospectiveIncome); },
            function (m) { return yScale(m.income); }
          )}
          className="cash-flow-graph__prospective-bottom-area"
          style={{ fill: 'white', opacity: 0.25 }} />
      : null}

      {hasProspectiveSpend ?
        <path d={
          makeAreaPath(withIntersectsProspectiveSpend,
            function (m) { return xScale.xToY(m.date); },
            function (m) { return yScale(m.spend - m.prospectiveSpend); },
            function (m) { return yScale(m.spend); }
          )}
          className="cash-flow-graph__prospective-bottom-area"
          style={{ fill: 'white', opacity: 0.25 }} />
        : null}



      {hasProspectiveSpend && this.includeLines ?
        <path
          d={prospectiveSpendLineFunc()(points)}
          className="cash-flow-graph__spend-line"
          style={{ strokeWidth: '1px', opacity: 0.5 }}
        />
        : null}

      {this.includeLines ?
        <path
          d={spendLineFunc()(points)}
          className="cash-flow-graph__spend-line"
          style={{strokeWidth: '0.2em'}}
        />
      : null}


      {this.includeLines ?
        <path
          d={incomeLineFunc()(points)}
          className="cash-flow-graph__income-line"
          style={{strokeWidth: '0.2em'}}
        />
      : null}


      {hasProspectiveIncome && this.includeLines ?
        <path
          d={prospectiveIncomeLineFunc()(points)}
          className="cash-flow-graph__income-line"
          style={{ strokeWidth: '1px', opacity: 0.5 }}
        />
        : null}

      {this.includeCircles ?
        points.map(function(v, i) {
          if (i === 0 || i === points.length - 1) {
            return null;
          }

          let isSelected = firstSelectedMonth != null
            && v.originalX >= firstSelectedMonth
            && v.originalX <= lastSelectedMonth;
          let r = (isSelected ? 0.75 : 0.5);

          return <g key={i}>
            {hasProspectiveSpend ? 
              <circle
                cx={xScale.xToY(v.date)}
                cy={yScale(v.spend - v.prospectiveSpend)}
                r={(r-0.25) + 'em'}
                className="cash-flow-graph__spend-circle"
                style={{ strokeWidth: 0 }}
              />
            : null}
            <circle
              cx={xScale.xToY(v.date)}
              cy={yScale(v.spend)}
              r={r + 'em'}
              className={`cash-flow-graph__spend-circle--${v.income >= v.spend ? 'income' : 'spend'}-on-top`}
            />
            {hasProspectiveIncome ? 
              <circle
                cx={xScale.xToY(v.date)}
                cy={yScale(v.income - v.prospectiveIncome)}
                r={(r-0.25) + 'em'}
                className="cash-flow-graph__income-circle"
                style={{strokeWidth: 0}}
              />
            : null}
            <circle
              cx={xScale.xToY(v.date)}
              cy={yScale(v.income)}
              r={r + 'em'}
              className="cash-flow-graph__income-circle"
            />
          </g>;
        })
      : null}

      {hoveredItem != null ?
        <rect
          x={xScale.xToY(hoveredItem.date)}
          y={0}
          width={xScale.xToY(30) - xScale.xToY(0)}
          height={innerHeight}
          fill="grey"
          style={{opacity: 0.1}}
        />
      : null}

      {firstSelectedMonth != null ?
        <rect
          x={xScale.xToY(firstSelectedMonth)}
          y={0}
          width={xScale.xToY(lastSelectedMonth + 30) - xScale.xToY(firstSelectedMonth)}
          height={innerHeight}
          fill="grey"
          style={{opacity: 0.1}}
        />
      : null}
    </g>;
  }

  withIntersections(data) {
    // List. TODO clean this up.
    var toAppend = [];
    data.forEach(function(_, i) {
      if (i == 0) { return; }
      if ((data[i].income > data[i].spend) === (data[i - 1].income > data[i - 1].spend)) { return; }

      var m1 = (data[i].income - data[i - 1].income) / (data[i].date - data[i - 1].date);
      var m2 = (data[i].spend - data[i - 1].spend) / (data[i].date - data[i - 1].date);
      var c1 = data[i].income - m1 * data[i].date;
      var c2 = data[i].spend - m2 * data[i].date;

      var intersectX = (c2 - c1) / (m1 - m2);
      toAppend.push({
        ...data[i],
        ...{
          x: intersectX,
          date: intersectX,
          income: m1 * intersectX + c1,

          // The equation for `spend` is actually `m1 * interectX + c2`, but
          // with proper real numbers this is identical to the expressions for
          // `income` (that's the whole point of what we were looking for. So
          // we use the exact same value so the caller can use `===` or `>` or
          // `<` without having to worry about floating point rounding issues.
          spend: m1 * intersectX + c1,
          isIntersection: true
        }
      });
    });

    var dataWithIntersects = data.concat(toAppend);
    return stableSort(dataWithIntersects, (a, b) => a.date - b.date);
  }

  withIntersectionsProspectiveIncome(data) {
    // List. TODO clean this up.
    var toAppend = [];
    data.forEach(function (_, i) {
      if (i == 0) { return; }
      if ((data[i].prospectiveIncome > data[i].income) === (data[i - 1].prospectiveIncome > data[i - 1].income)) { return; }

      var m1 = (data[i].prospectiveIncome - data[i - 1].prospectiveIncome) / (data[i].date - data[i - 1].date);
      var m2 = (data[i].income - data[i - 1].income) / (data[i].date - data[i - 1].date);
      var c1 = data[i].prospectiveIncome - m1 * data[i].date;
      var c2 = data[i].income - m2 * data[i].date;

      var intersectX = (c2 - c1) / (m1 - m2);
      toAppend.push({
        ...data[i],
        ...{
          x: intersectX,
          date: intersectX,
          prospectiveIncome: m1 * intersectX + c1,

          // The equation for `income` is actually `m1 * interectX + c2`, but
          // with proper real numbers this is identical to the expressions for
          // `prospectiveIncome` (that's the whole point of what we were looking for. So
          // we use the exact same value so the caller can use `===` or `>` or
          // `<` without having to worry about floating point rounding issues.
          income: m1 * intersectX + c1,
          isIntersection: true
        }
      });
    });

    var dataWithIntersects = data.concat(toAppend);
    return stableSort(dataWithIntersects, (a, b) => a.date - b.date);
  }

  withIntersectionsProspectiveSpend(data) {
    // List. TODO clean this up.
    var toAppend = [];
    data.forEach(function (_, i) {
      if (i == 0) { return; }
      if ((data[i].prospectiveSpend > data[i].income) === (data[i - 1].prospectiveSpend > data[i - 1].income)) { return; }

      var m1 = (data[i].prospectiveSpend - data[i - 1].prospectiveSpend) / (data[i].date - data[i - 1].date);
      var m2 = (data[i].income - data[i - 1].income) / (data[i].date - data[i - 1].date);
      var c1 = data[i].prospectiveSpend - m1 * data[i].date;
      var c2 = data[i].income - m2 * data[i].date;

      var intersectX = (c2 - c1) / (m1 - m2);
      toAppend.push({
        ...data[i],
        ...{
          x: intersectX,
          date: intersectX,
          prospectiveSpend: m1 * intersectX + c1,

          // The equation for `income` is actually `m1 * interectX + c2`, but
          // with proper real numbers this is identical to the expressions for
          // `prospectiveSpend` (that's the whole point of what we were looking for. So
          // we use the exact same value so the caller can use `===` or `>` or
          // `<` without having to worry about floating point rounding issues.
          income: m1 * intersectX + c1,
          isIntersection: true
        }
      });
    });

    var dataWithIntersects = data.concat(toAppend);
    return stableSort(dataWithIntersects, (a, b) => a.date - b.date);
  }
}

class SmoothScroller {
  // Adapted from http://ariya.github.io/kinetic/2/

  constructor(scrollCallback) {
    this.pressed = false;
    this.reference = null;
    this.ticker = null;
    this.velocity = 0;
    this.amplitude = 0;
    this.frame = 0;
    this.offset = 0;
    this.target = null;
    this.timeConstant = 325; // ms
    this.scrollCallback = scrollCallback;

    this._clearAutoScroll = false;
    this._isAutoScrolling = false;
  }

  tap(pos) {
    var self = this;
    this.pressed = true;
    this.reference = pos;

    this.velocity = this.amplitude = 0;
    this.frame = this.offset;
    this.timestamp = Date.now();
    clearInterval(this.ticker);
    this.ticker = setInterval(function() { self.track(); }, 100);
  }

  track() {
    var now = Date.now();
    var elapsed = now - this.timestamp;
    this.timestamp = now;
    var delta = this.offset - this.frame;
    this.frame = this.offset;
    var v = 1000 * delta / (1 + elapsed);
    this.velocity = 0.8 * v + 0.2 * this.velocity;
  }

  drag(pos) {
    if (this.pressed) {
      var delta = this.reference - pos;
      if (delta > 2 || delta < -2) {
        this.reference = pos;
        this.scroll(this.offset + delta);
      }
    }
  }

  release() {
    var self = this;
    this.pressed = false;

    clearInterval(this.ticker);
    this.ticker = null;
    if (this.velocity > 10 || this.velocity < -10) {
      this.amplitude = 0.8 * this.velocity;
      this.target = Math.round(this.offset + this.amplitude);
      this.timestamp = Date.now();
      this._isAutoScrolling = true;
      requestAnimationFrame(function() { self.autoScroll(); });
    }
  }

  autoScroll() {
    var self = this;

    if (this._clearAutoScroll) {
      this._clearAutoScroll = false;
      this._isAutoScrolling = false;
      return;
    }

    if (this.amplitude) {
      var elapsed = Date.now() - this.timestamp;
      var delta = -this.amplitude * Math.exp(-elapsed / this.timeConstant);
      if (delta > 0.5 || delta < -0.5) {
        this.scroll(this.target + delta);
        requestAnimationFrame(function() { self.autoScroll(); });
      } else {
        this.scroll(this.target);
        this._isAutoScrolling = false;
      }
    }
    else {
      this._isAutoScrolling = false;
    }
  }

  scroll(pos) {
    this.offset = this.scrollCallback(pos);
  }

  clearAutoScroll() {
    // Have a way to cancel the autoscroll if eg. the user drags a physical scrollbar
    // while the autoscroll from a previous pan is still operating.
    if (this._isAutoScrolling) {
      this._clearAutoScroll = true;
    }
  }
}


export var Timeline = CreateReactClass({
  propTypes: {
    startDate: PropTypes.number.isRequired,
    endDate: PropTypes.number.isRequired,
    fontSize: PropTypes.any,
    xScale: PropTypes.object.isRequired
  },

  render: function() {
    var self = this;
    var format = "MMM";

    var data = this.props.data;

    var ticks = [];
    var xScale = this.props.xScale;
    var viewportScrollLeft = xScale.xToY(this.props.startDate);

    data.forEach(function(d, i) {
      var month = dateConverter.intToMoment(d.date);
      var currentX = xScale.xToY(d.date);
      var nextX = xScale.xToY(d.date + 30); // Vaguely
      var labelX = -viewportScrollLeft + (currentX + nextX) / 2;

      ticks.push(
        <line
          key={i * 2}
          style={{vectorEffect: 'non-scaling-stroke'}}
          className="axis-tick-line"
          x1={-viewportScrollLeft + currentX}
          y1={0}
          x2={-viewportScrollLeft + currentX}
          y2={9}
        />
      );

      ticks.push(
        <text
            key={i * 2 + 1}
            x={labelX}
            y={10}
            style={{textAnchor: 'middle', fontSize: self.props.fontSize}}>
          <tspan x={labelX} dy="0.71em">
            {month.format(format)}
          </tspan>
          {i === 0 || month.month() === 0 ?
            <tspan x={labelX} dy="1.2em">
              {month.year()}
            </tspan>
          : null}
        </text>
      );
    });

    return <g>
      {ticks}
    </g>;
  }
});
