import React from 'react';
import PropTypes from "prop-types";
import CreateReactClass from "create-react-class";


export const SaveCancelPanel = CreateReactClass({
			propTypes: {
				onSave: PropTypes.func.isRequired,
				onCancel: PropTypes.func.isRequired
			},

			render: function() {
				return (
					<div className="project-options">
						<div
							className="entry-time-input__save-button pr-opt"
							onClick={this.props.onSave}
						>
							<span className="pr-opt-icon notes">
								<i className="fa fa-download" />
							</span>
							<span className="pr-opt-label">Save</span>
						</div>
						<div className="pr-opt" onClick={this.props.onCancel}>
							<span className="pr-opt-icon delete">
								<i className="fa fa-times" />
							</span>
							<span className="pr-opt-label">Cancel</span>
						</div>
					</div>
				);
			}
		});

