import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { formatMinutes, imap, sum } from "../utils.js";
import { makeMultipleStoreMixin, dispatcher } from "../coincraftFlux.js";
import { organisationStore } from "../organisation.js";
import {
	authenticationStore,
	actions as authenticationActions,
} from "../authenticationService.js";
import { Checkbox } from "./Checkbox.js";
import { SaveCancelPanel } from "./SaveCancelPanel.js";
import { store, actions, formatSeconds } from "./flux.js";
import { Project, ProjectPhase, BusinessCategory, Task } from "../models.js";
import { userStore, actions as userActions } from "../user.js";
import PropTypes from "prop-types";
import CreateReactClass from "create-react-class";

var TimeApp = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([store], function () {
			return {
				selectedDate: store.selectedDate,
				hasEntries: store.costCentreGroups != null,
				isAddingEntry: store.isAddingEntry,
			};
		}),
	],

	render: function () {
		if (!this.state.hasEntries) {
			return <div>Loading...</div>;
		} else {
			return (
				<div>
					{this.state.isAddingEntry ? (
						<AddEntryPage />
					) : (
						<TimeEntryPage />
					)}
				</div>
			);
		}
	},
});

var TimeEntryPage = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([store], function () {
			let startOfWeek = store.startOfWeek;
			return {
				startOfWeek: startOfWeek,
				selectedDate: store.selectedDate,
				dayTotals: [0, 1, 2, 3, 4, 5, 6].map(function (i) {
					return store.getTotalMinutesForDate(
						startOfWeek.clone().add(i, "days")
					);
				}),
				hasEntries: store.getTodaysEntries().length > 0,
			};
		}),
	],

	render: function () {
		let self = this;

		let today = moment().startOf("day");

		return (
			<div>
				<div className="top-nav">
					<div
						className="nav-btn left"
						onClick={this.handlePreviousWeekButtonClick}
					>
						<i className="fa fa-chevron-left" />
					</div>
					<div className="nav-title">
						<span className="date-title">
							<span style={{ fontSize: "0.9em" }}>
								Week Starting
							</span>
							<br />
							<span style={{ fontWeight: 600 }}>
								{this.state.startOfWeek.format(
									"ddd, D MMM YYYY"
								)}
							</span>
						</span>
					</div>
					<div
						className="nav-btn right"
						onClick={this.handleNextWeekButtonClick}
					>
						<i className="fa fa-chevron-right" />
					</div>
				</div>
				<div className="weekday">
					{[0, 1, 2, 3, 4, 5, 6].map(function (i) {
						let date = self.state.startOfWeek
							.clone()
							.add(i, "days");

						return (
							<div
								key={i}
								className="d-sel"
								onClick={function () {
									self.handleDateClick(date);
								}}
							>
								<div
									className={classNames("day", {
										selected:
											self.state.selectedDate.isSame(
												date
											),
										today: today.isSame(date),
									})}
								>
									<div className="day-circle">
										<span className="mini-day">
											{date.format("ddd")}
										</span>
										<span className="mini-date">
											{" "}
											{date.format("DD")}
										</span>
									</div>
								</div>
								<div className="day-hours">
									{formatMinutes(self.state.dayTotals[i])}
								</div>
							</div>
						);
					})}
				</div>
				<TimesheetList />
				{!this.state.hasEntries ? (
					<div
						className="project-btn"
						onClick={this.handleCopyPreviousButtonClick}
					>
						+ Copy Previous Entries
					</div>
				) : null}
				<div style={{ borderBottom: "solid 1px #ccc" }}>
					<div
						className="add-entry-button project-btn"
						onClick={this.handleAddProjectButtonClick}
					>
						+ Add Entry
					</div>
				</div>
				<div style={{ textAlign: "center" }}>
					<img
						className="logo"
						src={process.env.PUBLIC_URL + "/coincraft-coin.svg"}
					/>
				</div>
				<div>
					<div
						className="add-entry-button project-btn"
						onClick={this.handleLogoutButtonClick}
					>
						Logout
					</div>
				</div>
			</div>
		);
	},

	handlePreviousWeekButtonClick() {
		actions.previousWeek();
	},

	handleNextWeekButtonClick() {
		actions.nextWeek();
	},

	handleDateClick: function (date) {
		actions.selectDate(date);
	},

	handleAddProjectButtonClick: function () {
		actions.startAddEntry();
	},

	handleCopyPreviousButtonClick: function () {
		actions.copyMostRecentEntries();
	},

	handleLogoutButtonClick: () => userActions.logout(false),
});

var TimesheetList = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([store], function () {
			return {
				todaysEntries: store.iterTodaysEntries(),
			};
		}),
	],

	render: function () {
		return (
			<div className="timesheet-list">
				{Array.from(
					imap(
						this.state.todaysEntries,
						function ([_i, entry, path], i) {
							return (
								<TimesheetItem
									key={i}
									timesheetEntry={entry}
									path={path}
								/>
							);
						}
					)
				)}
			</div>
		);
	},
});

var TimerDisplay = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([store], function () {
			return {
				timerTotalSeconds: store.timer.totalSeconds,
			};
		}),
	],

	render: function () {
		return <span>{formatSeconds(this.state.timerTotalSeconds)}</span>;
	},
});

// Note that this is copied from widgets/CurrencyIcon.js in order to get around a difficult
// circular import that was breaking stuff in production.
var CurrencyIcon = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([organisationStore], function () {
			return {
				currencyFormatter:
					organisationStore.organisation.currencyFormatter,
			};
		}),
	],

	render: function () {
		const { className, ...props } = this.props;
		return (
			<i
				className={classNames(
					"fa",
					this.state.currencyFormatter.fontAwesomeClass,
					className
				)}
				{...props}
			/>
		);
	},
});

var TimesheetItem = CreateReactClass({
	propTypes: {
		timesheetEntry: PropTypes.object.isRequired,
		path: PropTypes.object.isRequired,
	},

	mixins: [
		makeMultipleStoreMixin([store], function () {
			return {
				isTimerOpen: store.isTimerOpen,
				isTimerRunning: store.timer.isRunning,
				timesheetEntryMode: store.timesheetEntryMode,
				enteredTime: store.enteredTime,
				enteredNote: store.enteredNote,
				isBillable: store.selectedEntryIsBillable,
				isVariation: store.selectedEntryIsVariation,
				isOvertime: store.selectedEntryIsOvertime,

				staffTotalLookup: store.staffTotalLookup,

				expanded: this.props.timesheetEntry.isSame(store.selectedEntry),
				cantOpenWhileTimerRunning: this.props.timesheetEntry.isSame(
					store.cantOpenWhileTimerIsRunning
				),

				saveError: store.saveError,
				deleteError: store.deleteError,
			};
		}),
	],

	render: function () {
		let dropDown;
		let entry = this.props.timesheetEntry;
		let phase = entry.projectPhase;
		let hoursToDate =
			phase && phase.getStaffMemberHoursBudget(userStore.user)
				? (phase?.staffMinutes[userStore.user.id] || 0) / 60
				: (phase && sum(Object.values(phase?.staffMinutes))) / 60 || 0;
		let budgetHours =
			phase && phase.getStaffMemberHoursBudget(userStore.user)
				? phase.getStaffMemberHoursBudget(userStore.user)
				: (phase && phase.manualHoursBudget) || 0;
		let progressPercent = budgetHours
			? Math.round((hoursToDate / budgetHours) * 10000) / 100
			: 0;
		let progressBarWidth = Math.min(100, progressPercent);
		let progressBarColor = progressPercent > 100 ? "red" : "gold";

		switch (this.state.timesheetEntryMode) {
			case "timer":
				dropDown = (
					<div className="project-dropdown two-btn">
						<div className="project-timer">
							<TimerDisplay />
						</div>
						<div className="project-options">
							<div
								className="pr-opt"
								onClick={this.handleTimerSaveButtonClick}
							>
								<span className="pr-opt-icon notes">
									<i className="fa fa-download"> </i>
								</span>
								<span className="pr-opt-label">Save</span>
							</div>
							{this.state.isTimerRunning ? (
								<div
									className="pr-opt"
									onClick={this.handleTimerPauseButtonClick}
								>
									<span className="pr-opt-icon timer">
										<i className="fa fa-pause"> </i>
									</span>
									<span className="pr-opt-label">Pause</span>
								</div>
							) : (
								<div
									className="pr-opt"
									onClick={this.handleTimerResumeButtonClick}
								>
									<span className="pr-opt-icon timer">
										<i className="fa fa-play"> </i>
									</span>
									<span className="pr-opt-label">Start</span>
								</div>
							)}
							<div
								className="pr-opt"
								onClick={this.timerCancelClick}
							>
								<span className="pr-opt-icon delete">
									<i className="fa fa-times"> </i>
								</span>
								<span className="pr-opt-label">Cancel</span>
							</div>
						</div>
					</div>
				);
				break;

			case "time-input":
				dropDown = (
					<div className="project-dropdown two-btn">
						<div className="project-input">
							<input
								className="entry-time-input__input"
								type="text"
								maxLength={5}
								value={this.state.enteredTime}
								onChange={this.handleTimeInputChange}
							/>
						</div>
						<SaveCancelPanel
							onSave={this.handleTimeInputSaveButtonClick}
							onCancel={this.handleTimeInputCancelClick}
						/>
					</div>
				);
				break;

			case "note":
				dropDown = (
					<div className="project-dropdown two-btn">
						<div className="project-note">
							<textarea
								rows="3"
								placeholder="Text goes here..."
								onChange={this.handleNoteInputChange}
							>
								{this.state.enteredNote}
							</textarea>
						</div>
						<SaveCancelPanel
							onSave={this.handleSaveNoteButtonClick}
							onCancel={this.handleCancelNoteButtonClick}
						/>
					</div>
				);
				break;

			case "time-options":
				dropDown = (
					<div className="project-dropdown two-btn">
						<div className="time-entry-switches">
							{organisationStore.organisation.settings.timeEntryFlags.includes(
								"billable"
							) && (
								<Checkbox
									value={this.state.isBillable}
									label="Billable"
									onChange={(isBillable) =>
										actions.setIsBillable(isBillable)
									}
								/>
							)}
							{organisationStore.organisation.settings.timeEntryFlags.includes(
								"variation"
							) && (
								<Checkbox
									value={this.state.isVariation}
									label="Variation"
									onChange={(isVariation) =>
										actions.setIsVariation(isVariation)
									}
								/>
							)}
							{organisationStore.organisation.settings.timeEntryFlags.includes(
								"overtime"
							) && (
								<Checkbox
									value={this.state.isOvertime}
									label="Overtime"
									onChange={(isOvertime) =>
										actions.setIsOvertime(isOvertime)
									}
								/>
							)}
						</div>
						<SaveCancelPanel
							onSave={() => actions.saveTimeOptions()}
							onCancel={() => actions.cancelTimeOptions()}
						/>
					</div>
				);
				break;

			case "locked":
				dropDown = (
					<div className="project-dropdown two-btn">
						<div className="project-locked">
							<i
								className="fa fa-lock"
								style={{ marginRight: "1em" }}
							/>
							<span>Time Entry Locked</span>
						</div>
					</div>
				);
				break;

			default:
				dropDown = (
					<div className="project-dropdown">
						<div className="project-options">
							<div
								className="pr-opt"
								onClick={this.handleStartTimerButtonClick}
							>
								<span className="pr-opt-icon timer">
									<i className="fa fa-play"> </i>
								</span>
								<span className="pr-opt-label">
									Start Timer
								</span>
							</div>
							<div
								className="enter-time-button pr-opt"
								onClick={this.handleEnterTimeButtonClick}
							>
								<span className="pr-opt-icon input">
									<i className="fa fa-clock-o"> </i>
								</span>
								<span className="pr-opt-label">Enter Time</span>
							</div>
							<div
								className="pr-opt"
								onClick={this.handleAddNoteButtonClick}
							>
								<span className="pr-opt-icon notes">
									<i className="fa fa-sticky-note"> </i>
								</span>
								<span className="pr-opt-label">Add Note</span>
							</div>
							{organisationStore.organisation.settings
								.timeEntryFlags.length > 0 && (
								<div
									className="pr-opt"
									onClick={this.handleTimeOptionsButtonClick}
								>
									<span className="pr-opt-icon time-options">
										<i className="fa fa-code-fork"> </i>
									</span>
									<span className="pr-opt-label">
										Time Options
									</span>
								</div>
							)}
							<div className="pr-opt" onClick={this.deleteClick}>
								<span className="pr-opt-icon delete">
									<i className="fa fa-times"> </i>
								</span>
								<span className="pr-opt-label">
									Delete Entry
								</span>
							</div>
						</div>
					</div>
				);
		}

		return (
			<div
				className="timesheet-entry project"
				style={{ position: "relative" }}
			>
				<div
					className="project-sel"
					onClick={this.handleClick}
					style={{ position: "relative", paddingBottom: "0.5em" }}
				>
					<div className="project-text">
						<span className="cc-name">
							{entry.project != null &&
							entry.project.costCentre != null
								? entry.project.costCentre.name
								: "(No cost centre)"}
						</span>
						<span className="pr-name">
							{entry.project != null
								? entry.project.getTitle()
								: "(No project)"}
						</span>
						<span className="ph-name">
							{!entry.isBillable ? (
								<span
									className="fa-stack"
									style={{
										fontSize: "0.7em",
										marginRight: "0.4em",
										display: "inline-block",
									}}
								>
									<CurrencyIcon className="fa-stack-1x" />
									<i className="fa fa-ban fa-stack-2x" />
								</span>
							) : null}
							{entry.isVariation ? (
								<i
									className="fa fa-code-fork"
									style={{
										fontSize: "1.3em",
										position: "relative",
										top: "0.15em",
									}}
								/>
							) : null}
							<span
								style={{
									marginLeft: "0.5em",
									display: "inline-block",
								}}
							>
								{entry.projectPhase != null
									? entry.projectPhase.getTitle()
									: "(No phase)"}
								{entry.task != null &&
								entry.projectPhase.hasNonDefaultTask()
									? ": " + entry.task.name
									: null}
								{budgetHours
									? ` - ${Math.round(progressPercent)}%`
									: null}
							</span>
						</span>
					</div>
					<div className="project-time">
						{formatMinutes(this.props.timesheetEntry.numMinutes)}
					</div>
					<div
						style={{
							height: "3px",
							position: "absolute",
							bottom: "0.5em",
							left: "1em",
							right: "1em",
						}}
					>
						<div
							style={{
								height: "3px",
								backgroundColor: "#ccc",
								width: "100%",
								position: "absolute",
								left: 0,
							}}
						/>
						<div
							style={{
								height: "3px",
								backgroundColor: `${progressBarColor}`,
								width: `${progressBarWidth}%`,
								position: "absolute",
								left: 0,
							}}
						/>
					</div>
				</div>
				{this.state.expanded ? dropDown : null}
				{this.state.saveError ? (
					<ErrorMessage>
						{/* TODO-timesheet_app */}
						There was a problem saving this entry.
					</ErrorMessage>
				) : null}
				{this.state.deleteError ? (
					<ErrorMessage>
						{/* TODO-timesheet_app */}
						There was a problem deleting this entry.
					</ErrorMessage>
				) : null}
				{this.state.cantOpenWhileTimerRunning ? (
					<InfoMessage>
						{/* TODO-timesheet_app */}
						You currently have a timer running; please save or
						cancel it before opening another entry.
					</InfoMessage>
				) : null}
			</div>
		);
	},

	handleClick: function () {
		actions.clickEntry(this.props.timesheetEntry, this.props.path);
	},

	handleStartTimerButtonClick: function (e) {
		actions.startTimer();
	},

	handleTimerResumeButtonClick: function (e) {
		actions.resumeTimer();
	},

	handleTimerPauseButtonClick: function (e) {
		actions.pauseTimer();
	},

	timerCancelClick: function (e) {
		actions.cancelTimer();
	},

	handleTimerSaveButtonClick: function (e) {
		actions.saveTimeFromTimer();
	},

	handleEnterTimeButtonClick: function (e) {
		actions.enterTime();
	},

	handleTimeInputCancelClick: function () {
		actions.cancelEnterTime();
	},

	handleTimeInputSaveButtonClick: function () {
		actions.saveTime();
	},

	handleTimeInputChange: function (event) {
		actions.editTime(event.target.value);
	},

	handleAddNoteButtonClick: function () {
		actions.addNote();
	},

	handleCancelNoteButtonClick: function () {
		actions.cancelNote();
	},

	handleSaveNoteButtonClick: function () {
		actions.saveNote();
	},

	handleNoteInputChange: function (event) {
		actions.editNote(event.target.value);
	},

	handleTimeOptionsButtonClick: function () {
		actions.viewTimeOptions();
	},

	deleteClick: function (e) {
		actions.deleteEntry();
	},
});

var AddEntryPage = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([organisationStore, store], function () {
			return {
				costCentres: organisationStore.getVisibleCostCentres(),
				projects: store.getSelectableProjects(),
				phases:
					store.selectedProject != null
						? store.selectedProject
								.getVisiblePhases()
								.filter((p) => {
									return organisationStore.organisation.settings.timeEntryStatus.includes(
										p.status
									);
								})
						: null,
				tasks: store.getSelectableTasks(),
				selectedCostCentre: store.selectedCostCentre,
				projectSearchText: store.projectSearchText,
				taskSearchText: store.taskSearchText,
				selectedProject: store.selectedProject,
				selectedProjectPhase: store.selectedProjectPhase,
				stage: store.getAddEntryStage(),
				todaysEntries: store.getTodaysEntries(),
			};
		}),
	],

	render: function () {
		let self = this;

		let selectText, items;
		let hasSearch = false,
			searchValue,
			searchCallback;

		if (this.state.stage === "costCentre") {
			selectText = "Cost-Centre";
			items = this.state.costCentres;
		} else if (this.state.stage === "project") {
			hasSearch = true;
			searchValue = this.state.projectSearchText;
			searchCallback = this.handleProjectSearchTextInputChange;
			selectText = "Project";
			items = this.state.projects;
		} else if (this.state.stage === "phase") {
			selectText = "Phase";
			items = [...this.state.phases];
			organisationStore.organisation.settings.allowNoPhase &&
				items.push(
					new ProjectPhase({
						id: -1,
						name: "(No phase)",
						project: this.state.selectedProject,
					})
				);
		} else if (this.state.stage === "task") {
			hasSearch = true;
			searchValue = this.state.taskSearchText;
			searchCallback = this.handleTaskSearchTextInputChange;
			selectText = "Task";
			items = this.state.tasks;
		}

		return (
			<div>
				<div
					className="top-nav"
					style={{ borderBottom: "1px solid #ccc" }}
				>
					<div className="nav-btn right" />
					<div className="nav-title">Select {selectText} Below</div>
					<div
						className="nav-btn right"
						onClick={this.handleCloseButtonClick}
					>
						<i className="fa fa-times" />
					</div>
				</div>
				<div style={{ marginTop: "-4.8em" }}>
					{hasSearch ? (
						<div
							className="project-input"
							style={{
								padding: "1rem",
								backgroundColor: "#f2f2f2",
							}}
						>
							<input
								type="text"
								value={searchValue}
								onChange={searchCallback}
								placeholder="Search..."
								style={{
									fontSize: "1.5em",
									letterSpacing: "0.1em",
									fontStyle: "italic",
								}}
							/>
						</div>
					) : null}
					{items
						.sort((a, b) =>
							(a.getTitle?.() || a.name).localeCompare(
								b.getTitle?.() || b.name
							)
						)
						.map(function (item, i) {
							return (
								<div
									key={i}
									className="project"
									onClick={function () {
										self.handleItemClick(item);
									}}
								>
									<div className="project-sel">
										<div className="project-text">
											<span className="add-entry-form__item">
												{item.getTitle?.() || item.name}
											</span>
										</div>
									</div>
								</div>
							);
						})}

					<div style={{ borderBottom: "solid 1px #ccc" }}>
						<div
							className="project-btn"
							onClick={self.handleBackButtonClick}
						>
							{"<< Back"}
						</div>
					</div>
				</div>
			</div>
		);
	},

	handleItemClick: function (item) {
		if (item.constructor === BusinessCategory) {
			actions.selectCostCentre(item);
		} else if (item.constructor === Project) {
			actions.selectProject(item);
		} else if (item.constructor === ProjectPhase) {
			actions.selectProjectPhase(item);
		} else if (item.constructor === Task) {
			actions.selectTask(item);
		}
	},

	handleProjectSearchTextInputChange: function (event) {
		actions.editProjectSearchText(event.target.value);
	},

	handleTaskSearchTextInputChange: function (event) {
		actions.editTaskSearchText(event.target.value);
	},

	handleBackButtonClick: function () {
		actions.createEntryBack();
	},

	handleCloseButtonClick: function () {
		actions.createEntryCancel();
	},
});

var ErrorMessage = CreateReactClass({
	render: function () {
		return (
			<div
				style={{
					color: "#a94442",
					backgroundColor: "#f2dede",
					border: "solid 1px #ebccd1",
					borderRadius: 4,
					padding: "1.5em",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				{this.props.children}
			</div>
		);
	},
});

var InfoMessage = CreateReactClass({
	render: function () {
		return (
			<div
				style={{
					color: "#31708f",
					backgroundColor: "#d9edf7",
					border: "solid 1px #bce8f1",
					borderRadius: 4,
					padding: "1.5em",
					textAlign: "center",
				}}
			>
				{this.props.children}
			</div>
		);
	},
});

var LoginPage = CreateReactClass({
	getInitialState: function () {
		return {
			email: "",
			password: "",
		};
	},

	render: function () {
		return (
			<div>
				<div style={{ textAlign: "center" }}>
					<img
						className="logo"
						src={process.env.PUBLIC_URL + "/coincraft-coin.svg"}
					/>
				</div>
				<div style={{ textAlign: "center" }} className="login">
					<form>
						<input
							type="email"
							placeholder="Email"
							value={this.state.email}
							onChange={this.handleEmailChange}
						/>

						<input
							type="password"
							placeholder="Password"
							value={this.state.password}
							onChange={this.handlePasswordChange}
						/>

						<button
							className="sign-in-button project-btn"
							onClick={this.handleSignInButtonClick}
						>
							Sign In
						</button>
					</form>
				</div>
			</div>
		);
	},

	handleEmailChange: function (event) {
		this.setState({ email: event.target.value });
	},

	handlePasswordChange: function (event) {
		this.setState({ password: event.target.value });
	},

	handleSignInButtonClick: function (event) {
		event.preventDefault();
		let redirect = false;
		userActions.login(this.state.email, this.state.password, redirect);
	},
});

export var TimesheetAppComponent = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([userStore], function () {
			return {
				isReady: userStore.isReady,
				isLoggedIn: userStore.isLoggedIn(),
			};
		}),
	],

	render: function () {
		if (!this.state.isReady) {
			return <div>Loading...</div>;
		} else if (!this.state.isLoggedIn) {
			return <LoginPage />;
		} else {
			return <TimeApp />;
		}
	},
});

export class App {
	initialize() {}
}
