import moment from 'moment';
import _ from 'underscore';
import { dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import { Enum } from '../enum.js';
import { TimesheetEntry } from './models.js';
import { jsonHttp } from '../jsonHttp';
import Immutable from 'immutable';
import {
  getMonday, isNumber, groupBy, sum, enumerate, compareMultiple,
  imap, iterDateRange
} from '../utils.js';
import { getTimesheetApiUrl, SaveTimer } from './utils.js';
import { userStore } from '../user/flux.js';
import { getEntries } from './ReportQuery.js';
import { Report } from '../reports/Report.js';
import { router } from '../router.js';
import { dateConverter } from '../models.js';
import { jsonHttp2 } from '../jsonHttp.js';
import { organisationStore } from '../organisation.js';
import apiRequest, { chainRequests } from '../apiRequest.js';
import Axios from 'axios';


const actionDefinitions = [
  // Weekly
  { action: 'loadWeek', args: ['startDate'] },
  // Daily
  { action: 'loadDay', args: ['date'] },

  { action: 'today', args: [] },

  { action: 'nextWeek', args: [] },
  { action: 'previousWeek', args: [] },

  { action: 'setStaffMember', args: ['staffMember'] },

  { action: 'gotEntryList', args: ['data'] },
  { action: 'gotEntryListFailed', args: [] },
  { action: 'gotBudgets', args: ['data'] },
  {
    action: 'addRow',
    args: [
      'project',  // optional
      'projectPhase' // optional
    ]
  },
  { action: 'editRow', args: ['row', 'timesheetEntryPath'] },
  {
    action: 'submitEditEntry',
    args: [
      'modal',
      'row',
      'timesheetEntryPath',
      'project',
      'projectPhase',
      'task',
      'isBillable',
      'isVariation',
      'isOvertime',
      'isLocked',
      'beenInvoiced'
    ]
  },
  { action: 'changeEntryProject', args: ['entry', 'project'] },
  { action: 'changeEntryPhase', args: ['entry', 'phase'] },
  { action: 'changeEntryTask', args: ['entry', 'task'] },
  { action: 'changeEntryTime', args: ['costCentreGroupIndex', 'projectPhaseGroupIndex', 'rowIndex', 'entryIndex', 'numMinutes'] },

  // Daily
  { action: 'changeEntryNotes', args: ['costCentreGroupIndex', 'projectPhaseGroupIndex', 'rowIndex', 'entryIndex', 'notes'] },

  // Weekly
  { action: 'editEntryNote', args: ['entry', 'timesheetEntryPath'] },
  { action: 'saveEntryNote', args: ['modal', 'entry', 'timesheetEntryPath', 'note'] },

  { action: 'deleteRow', args: ['costCentreGroupIndex', 'projectPhaseGroupIndex', 'rowIndex'] },
  { action: 'lockRow', args: ['costCentreGroupIndex', 'projectPhaseGroupIndex', 'rowIndex'] },
  { action: 'unlockRow', args: ['costCentreGroupIndex', 'projectPhaseGroupIndex', 'rowIndex'] },
  { action: 'copyPrevious', args: [] },
  { action: 'save', args: [] },
  { action: 'saveSuccess', args: ['data'] },
  { action: 'saveFailed', args: [] },
  { action: 'saveFailedDuplicate', args: [] },
  { action: 'saveFailedMissingTask', args: [] },
  { action: 'switchToDailyView', args: [] },
  { action: 'switchToWeeklyView', args: [] },

  { action: 'closeModal', args: ['modal'] },
];

export let timesheetActions = registerActions("timesheet", actionDefinitions, dispatcher, "my-timesheets");

export const EntriesState = Enum(['notReady', 'loading', 'loaded', 'error']);
export const BudgetState = Enum(['notReady', 'loading', 'loaded', 'error']);


export const TimesheetStore = class {
  constructor({ user, autosave = false } = {}) {
    this.mode = "weekly";

    this.user = user || userStore.getUser();

    this.autosave = autosave;

    this.modals = [];

    this.events = [];
    this.state = "clean";
    this.entriesState = EntriesState.notReady;
    this.budgetState = BudgetState.notReady;
    this.ready = false;

    this.hasSavedAtLeastOnce = false;

    this.startDate = getMonday(moment());
    this.endDate = this.startDate.clone().add(6, 'days');
    this.date = moment().startOf('day');
    this.ready = false;

    this.staffTotalLookup = null;

    this.copyingPrevious = false;
    this.highlightedRow = null;

    this.saveTimer = new SaveTimer(function () {
      timesheetActions.save();
    });

    this.actionDefinitions = actionDefinitions;
  }

  handle(action) {
    handleAction(action, this);
  }

  getUser() {
    return this.user;
  }

  _setDirty() {
    this.state = "dirty";

    if (this.autosave) {
      if (this.isValid()) {
        this.saveTimer.start();
      }
      else {
        this.saveTimer.clear();
      }
    }
  }

  _appendOperation(operation) {
    this.events.push(operation);
  }

  getHeadingText() {
    if (this.mode === 'weekly') {
      const monday = getMonday(this.startDate);
      const sunday = monday.clone().add(6, 'days');
      return `Timesheets: ${monday.format('DD MMMM, YYYY')} - ${sunday.format('DD MMMM, YYYY')}`;
    }
    else if (this.mode === 'daily') {
      return `Timesheets: ${this.date.format('DD MMMM, YYYY')}`;
    }
  }

  changeEntryTime(costCentreGroupIndex, projectPhaseGroupIndex, rowIndex, entryIndex, numMinutes) {
    this.costCentreGroups = this.costCentreGroups.setIn([
      costCentreGroupIndex,
      'projectPhaseGroups', projectPhaseGroupIndex,
      'rows', rowIndex,
      'entries', entryIndex,
      'numMinutes'
    ], numMinutes);
    this._setDirty();
    this._updateDayTotals();
    this._appendOperation("changeEntryTime");
  }

  editEntryNote(entry, timesheetEntryPath) {
    this.modals.push({
      type: 'editEntryNote',
      entry: entry,
      timesheetEntryPath: timesheetEntryPath
    });
  }

  saveEntryNote(modal, entry, timesheetEntryPath, note) {
    this.costCentreGroups = this.costCentreGroups.setIn([
      timesheetEntryPath.costCentreGroupIndex,
      'projectPhaseGroups', timesheetEntryPath.projectPhaseGroupIndex,
      'rows', timesheetEntryPath.rowIndex,
      'entries', timesheetEntryPath.entryIndex,
      'notes'
    ], note);
    this.closeModal(modal);
    this._setDirty();
    this._appendOperation("editNote");
  }

  get isNavigationDisabled() {
    return (_.include(['dirty', 'saving', 'error', 'saveFailed'], this.getSaveStatus())
      || this.entriesState === EntriesState.loading
    );
  }

  getSaveStatus() {
    var isValid = this.isValid();
    if (!isValid) {
      return "error";
    }
    if (this.state === 'dirty' && isValid) {
      return "saving";
    }
    else if (this.state === 'clean' && this.hasSavedAtLeastOnce) {
      return "saved";
    }
    else if (this.state === 'saveFailed') {
      return "saveFailed";
    }
    else if (this.state === 'missingTask') {
      return "missingTask";
    }
    else if (this.state === 'clean') {
      return null;
    }
  }

  getUserParam() {
    let user = this.getUser();
    return user != null ? user.id : 'me';
  }

  getReport(params) {
    return new Report(Report.transformArgs({
      dateRange: {
        id: 'custom',
        start: params.startDate.format("YYYY-MM-DD"),
        end: params.endDate.format("YYYY-MM-DD"),
      },
      filters: []
    }));
  }

  getEntries(params) {
    this.entriesState = EntriesState.loading;

    return getEntries(
      this.getReport(params),
      {
        copyPrevious: params.copyPrevious,
        duration: params.duration,
        includeWeekDailyTotals: params.includeWeekDailyTotals,
        includeStaffTotals: true,
        user: this.user.id
      }
    );
  }

  getBudgets() {
    this.budgetState = BudgetState.loading;
    const projectIds = _.uniq(this.costCentreGroups.toJS().reduce((projectIdsArray, ccg) => {
      projectIdsArray.push(...ccg.projectPhaseGroups.filter(ppg => ppg.project).map(ppg => ppg.project.id))
      return projectIdsArray
    }, []))
    if (projectIds.length === 0) return new Promise(() => ({data:[]}))
    return apiRequest({
      url: `/api/v1/user/timesheet/timesheet-budgets`,
      method: "get",
      params: { data:{
        projects: projectIds,
        nocache: new Date().getTime()
      }}
    });
  }

  save() {
    let self = this;

    this._appendOperation("save");

    // Use `.flatten(3).toArray()` rather than the simpler `.toJS()` because that makes
    // the `TimesheetEntry` objects lose their prototypes.
    let entries = (
      this.costCentreGroups.map(function (costCentreGroup) {
        return costCentreGroup.get('projectPhaseGroups').map(function (projectPhaseGroup) {
          return projectPhaseGroup.get('rows').filter(r => !r.get('isDeleted')).map(function (row) {
            return row.get('entries').filter(e => !e.isDeleted);
          });
        });
      })
        .flatten(3)
        .toArray()
    );

    let deletedEntryUuids;
    if (this.mode === 'weekly') {
      deletedEntryUuids = this.costCentreGroups.map(function (costCentreGroup) {
        return costCentreGroup.get('projectPhaseGroups').map(function (projectPhaseGroup) {
          let deletedRows = projectPhaseGroup.get('rows').filter(r => r.get('isDeleted'));
          return deletedRows.map(r => r.get('entries').map(e => e.uuid));
        });
      }).flatten().toJS();
    }
    else {
      deletedEntryUuids = [];
      for (let costCentreGroup of self.costCentreGroups) {
        for (let projectPhaseGroup of costCentreGroup.get('projectPhaseGroups')) {
          for (let row of projectPhaseGroup.get('rows')) {
            for (let [, entry] of enumerate(row.get('entries'))) {
              if (entry.isDeleted) {
                deletedEntryUuids.push(entry.uuid);
              }
            }
          }
        }
      }
    }

    const startDate = (this.mode === 'weekly' ? this.startDate : this.date);
    const endDate = (this.mode === 'weekly' ? this.endDate : this.date);

    saveTimeEntries({
      startDate: startDate,
      endDate: endDate,
      entries: entries,
      deletedEntryUuids: deletedEntryUuids,
      userId: this.getUserParam()
    }).then(function (data) {
      timesheetActions.saveSuccess(data);
    }, function (error) {
      if (error === 'duplicate') {
        timesheetActions.saveFailedDuplicate();
      } else if (error === 'missingTask') {
        timesheetActions.saveFailedMissingTask();
      }
      else {
        timesheetActions.saveFailed();
      }
    });
  }

  getIsDirty() {
    return this.state === 'dirty';
  }

  loadWeek(date) {
    /**
     * `startDate`: moment
     */
    var startDate = getMonday(date)
    var endDate = startDate.clone().add(6, 'days');
    if (!this.copyingPrevious) {
      this.startDate = startDate;
      this.date = date;
      this.endDate = endDate;
      this.hasSavedAtLeastOnce = false;
    }

    let params = {
      user: this.getUserParam(),
      startDate: startDate,
      endDate: endDate
    };

    let self = this;

    this.getEntries(params).then(function (data) {
      timesheetActions.gotEntryList(data);
      self.getBudgets().then(data => {
        timesheetActions.gotBudgets(data);
      })
    }, function (e) {
      timesheetActions.gotEntryListFailed(e);
    });
  }

  loadDay(date) {
    let self = this;
    date = date.clone().startOf('day');

    if (!this.copyingPrevious) {
      this.date = date;
      this.hasSavedAtLeastOnce = false;
      this.startDate = null;
      this.endDate = null;
    }

    let params = {
      user: this.getUserParam(),
      startDate: this.date,
      endDate: this.date,
      includeWeekDailyTotals: true
    };

    this.getEntries(params).then(function (data) {
      timesheetActions.gotEntryList(data);
      self.getBudgets().then(data => {
        timesheetActions.gotBudgets(data);
      })
    }, function (e) {
      timesheetActions.gotEntryListFailed(e);
    });
  }

  today() {
    if (this.mode === 'weekly') {
      this.loadWeek(getMonday(moment()));
    }
    else {
      this.loadDay(moment().startOf('day'));
    }
  }

  setDate(date) {
    this.loadWeek(date);
    // replacing history currently will switch from daily to weekly
    // router.history.replace('/dashboard/timesheet/' + date.format('YYYY-MM-DD'));
  }

  getDate() {
    if (this.mode === 'weekly') {
      return this.startDate;
    }
    else {
      return this.date;
    }
  }

  setStaffMember(staffMember) {
    this.user = staffMember;
    if (this.mode === 'weekly') {
      this.loadWeek(this.startDate);
    }
    else {
      this.loadDay(this.date);
    }
  }

  gotEntryList({ entries, staffTotals, dayTotals }) {
    entries = entries.filter(te => te.project)
    // Argh I quite dislike this.
    let self = this;

    this.staffTotalLookup = {};
    for (let t of staffTotals) {
      this.staffTotalLookup[t.projectPhaseId] = t.totalMinutes;
    }

    this.initialDayTotals = dayTotals;

    if (this.copyingPrevious) {
      if (entries.length > 0) {
        // If we are copying entries from a previous week, set their hours to
        // zero and disassociate them from the objects being copied.
        let returnedWeekStartDate = getMonday(entries[0].date);
        this.costCentreGroups = entriesToGroups(
          entries.map(function (e) {
            if (self.mode === 'weekly') {
              return e.createBlankCopy(self.startDate.clone().add(e.date.diff(returnedWeekStartDate, 'days'), 'days'));
            }
            else {
              return e.createBlankCopy(self.date);
            }
          }),
          this.mode === 'weekly' ? this.startDate : this.date,
          this.mode === 'weekly' ? this.endDate : this.date,
          this.staffTotalLookup
        );
      }
      else {
        this.costCentreGroups = Immutable.List([]);
      }
    }
    else {
      this.costCentreGroups = entriesToGroups(entries,
        this.mode === 'weekly' ? this.startDate : this.date,
        this.mode === 'weekly' ? this.endDate : this.date,
        this.staffTotalLookup
      );
    }

    this._updateDayTotals();
    this.copyingPrevious = false;
    this.entriesState = EntriesState.loaded;
    this.ready = true;
  }

  gotEntryListFailed() {
    this.entriesState = EntriesState.error;
  }

  gotBudgets({data}) {

    const currentStaffMember = this.user;
    const currentStafRole = currentStaffMember ? currentStaffMember.role : undefined

    const monthTotalLookup = data.reduce((lookup, mt) => {
      const monthIndex = mt.month
      lookup[mt.projectId] = lookup[mt.projectId] || {}
      lookup[mt.projectId][mt.phaseId] = lookup[mt.projectId][mt.phaseId] || {}
      lookup[mt.projectId][mt.phaseId]['organisationTotal'] = lookup[mt.projectId][mt.phaseId]['organisationTotal'] || 0
      lookup[mt.projectId][mt.phaseId]['staffRoleTotal'] = lookup[mt.projectId][mt.phaseId]['staffRoleTotal'] || 0
      lookup[mt.projectId][mt.phaseId]['staffMemberTotal'] = lookup[mt.projectId][mt.phaseId]['staffMemberTotal'] || 0
      lookup[mt.projectId][mt.phaseId][monthIndex] = lookup[mt.projectId][mt.phaseId][monthIndex] || {}
      lookup[mt.projectId][mt.phaseId][monthIndex]['organisation'] = lookup[mt.projectId][mt.phaseId][monthIndex]['organisation'] || 0
      lookup[mt.projectId][mt.phaseId][monthIndex]['staffRole'] = lookup[mt.projectId][mt.phaseId][monthIndex]['staffRole'] || 0
      lookup[mt.projectId][mt.phaseId][monthIndex]['staffMember'] = lookup[mt.projectId][mt.phaseId][monthIndex]['staffMember'] || 0
      const mtStaffMember = organisationStore.getStaffMemberById(mt.staffMemberId);
      const mtStaffRole = mtStaffMember ? mtStaffMember.role : undefined;
      lookup[mt.projectId][mt.phaseId]['organisationTotal'] += mt.hours * 60
      lookup[mt.projectId][mt.phaseId][monthIndex]['organisation'] += mt.hours * 60
      if ((!currentStafRole && !mtStaffRole) || (currentStafRole && mtStaffRole && currentStafRole.id === mtStaffRole.id)) {
        lookup[mt.projectId][mt.phaseId]['staffRoleTotal'] += mt.hours * 60
        lookup[mt.projectId][mt.phaseId][monthIndex]['staffRole'] += mt.hours * 60
      }
      if (currentStaffMember && mtStaffMember && currentStaffMember.id === mtStaffMember.id) {
        lookup[mt.projectId][mt.phaseId]['staffMemberTotal'] += mt.hours * 60
        lookup[mt.projectId][mt.phaseId][monthIndex]['staffMember'] += mt.hours * 60
      }
      return lookup
    }, {})

    this.costCentreGroups.toJS().forEach((ccg, cci) => {
      ccg.projectPhaseGroups.forEach((ppg, ppi) => {
        if (ppg.projectPhase != null) {
          const hoursInView = sum(ppg.rows.map(r => sum(r.entries.map(e => e.numMinutes))));
          const firstEntry = ppg.rows[0].entries[0]
          const phaseTotals = firstEntry && monthTotalLookup && monthTotalLookup[ppg.project.id] ? monthTotalLookup[ppg.project.id][ppg.projectPhase.id] || {} : {};
          const monthTotals = phaseTotals[dateConverter.momentToMonthIndex(firstEntry.date)] || {};
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'staffTotalNotInView'
          ], (phaseTotals['staffMemberTotal'] || 0) - hoursInView)
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'roleTotalNotInView'
          ], (phaseTotals['staffRoleTotal'] || 0) - hoursInView)
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'organisationTotalNotInView'
          ], (phaseTotals['organisationTotal'] || 0) - hoursInView)
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'staffMonthTotalNotInView'
          ], (monthTotals['staffMember'] || 0) - hoursInView)
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'roleMonthTotalNotInView'
          ], (monthTotals['staffRole'] || 0) - hoursInView)
          this.costCentreGroups = this.costCentreGroups.setIn([
            cci,
            'projectPhaseGroups', ppi,
            'organisationMonthTotalNotInView'
          ], (monthTotals['organisation'] || 0) - hoursInView)
        }
      })
    })
    this.budgetState = BudgetState.loaded;
  }

  changeEntryNotes(costCentreGroupIndex, projectPhaseGroupIndex, rowIndex, entryIndex, notes) {
    this.costCentreGroups = this.costCentreGroups.setIn([
      costCentreGroupIndex,
      'projectPhaseGroups', projectPhaseGroupIndex,
      'rows', rowIndex,
      'entries', entryIndex,
      'notes'
    ], notes);

    this._setDirty();

    // Don't make a ridiculous number of "changeNotes" entries every time the
    // user types a character into a notes field.
    if (this.events[this.events.length - 1] !== "changeNotes") {
      this._appendOperation("changeNotes");
    }
  }

  saveFailed() {
    this.state = "saveFailed";
  }

  switchToWeeklyView() {
    this.mode = 'weekly';
    this.loadWeek(getMonday(this.date));
  }

  nextWeek() {
    if (this.mode === 'weekly') {
      this.loadWeek(this.startDate.clone().add(1, 'week'));
    }
    else {
      this.loadDay(this.date.clone().add(1, 'week'));
    }
  }

  previousWeek() {
    if (this.mode === 'weekly') {
      this.loadWeek(this.startDate.clone().subtract(1, 'week'));
    }
    else {
      this.loadDay(this.date.clone().subtract(1, 'week'));
    }
  }

  copyMostRecentNonblank() {
    let self = this;
    this.copyingPrevious = true;

    let startDate, endDate;
    if (this.mode === 'weekly') {
      startDate = this.startDate;
      endDate = this.endDate;
    }
    else {
      startDate = getMonday(this.date);
      endDate = startDate.clone().add(6, 'days');
    }

    let params = {
      user: this.getUserParam(),
      startDate: startDate,
      endDate: endDate,
      copyPrevious: true,
      duration: this.mode === 'weekly' ? 'week' : 'day',
      includeWeekDailyTotals: false
    };

    this.getEntries(params).then(function (data) {
      timesheetActions.gotEntryList(data);
      self.getBudgets().then(data => {
        timesheetActions.gotBudgets(data);
      })
    }, function (e) {
      timesheetActions.gotEntryListFailed(e);
    });
  }


  addRow(project = null, projectPhase = null) {
    this.modals.push({
      type: 'editEntry',
      project: project,
      projectPhase: projectPhase
    });
  }

  editRow(row, timesheetEntryPath) {
    this.modals.push({
      type: 'editEntry',
      row: row,
      timesheetEntryPath: timesheetEntryPath
    });
  }

  submitEditEntry(modal, row, timesheetEntryPath, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced) {
    let { costCentreGroups, highlightedRowPath } = updateGroups(
      this.costCentreGroups,
      row,
      timesheetEntryPath,
      project,
      projectPhase,
      task,
      isBillable,
      isVariation,
      isOvertime,
      isLocked,
      beenInvoiced,
      this.mode === 'weekly' ? this.startDate : this.date,
      this.mode === 'weekly' ? this.endDate : this.date,
      this.staffTotalLookup
    );

    this.costCentreGroups = costCentreGroups;
    this.highlightedRowPath = highlightedRowPath;

    this._setDirty();
    this._appendOperation("addRow");
    this.closeModal(modal);
    this.getBudgets().then(data => {
      timesheetActions.gotBudgets(data);
    })
  }

  deleteRow(costCentreGroupIndex, projectPhaseGroupIndex, rowIndex) {
    let msg = (this.mode === 'weekly') ?
      'Are you sure you want to delete these entries?'
      : 'Are you sure you want to delete this entry?';

    if (window.confirm(msg)) {
      if (this.mode === 'weekly') {
        this.costCentreGroups = this.costCentreGroups.updateIn([
          costCentreGroupIndex,
          'projectPhaseGroups',
          projectPhaseGroupIndex,
          'rows',
          rowIndex
        ], function (row) {
          return (row
            .set('isDeleted', true)
            .update('entries', entries => entries.map(e => e.set('isDeleted', true)))
          );
        });
      }
      else {
        this.costCentreGroups = this.costCentreGroups.setIn([
          costCentreGroupIndex,
          'projectPhaseGroups', projectPhaseGroupIndex,
          'rows', rowIndex,
          'entries', 0,
          'isDeleted'
        ], true);
      }

      this._setDirty();
      this._updateDayTotals();
      this._appendOperation("deleteRow");
    }
  }

  lockRow(costCentreGroupIndex, projectPhaseGroupIndex, rowIndex) {
    if (this.mode === 'weekly') {
      this.costCentreGroups = this.costCentreGroups.updateIn([
        costCentreGroupIndex,
        'projectPhaseGroups',
        projectPhaseGroupIndex,
        'rows',
        rowIndex
      ], function (row) {
        return (row
          .set('isLocked', true)
          .update('entries', entries => entries.map(e => e.set('isLocked', true)))
        );
      });
    }
    else {
      this.costCentreGroups = this.costCentreGroups.setIn([
        costCentreGroupIndex,
        'projectPhaseGroups', projectPhaseGroupIndex,
        'rows', rowIndex,
        'entries', 0,
        'isLocked'
      ], true);
    }

    this._setDirty();
    this._appendOperation("isLocked");
  }

  unlockRow(costCentreGroupIndex, projectPhaseGroupIndex, rowIndex) {
    if (this.mode === 'weekly') {
      this.costCentreGroups = this.costCentreGroups.updateIn([
        costCentreGroupIndex,
        'projectPhaseGroups',
        projectPhaseGroupIndex,
        'rows',
        rowIndex
      ], function (row) {
        return (row
          .set('isLocked', false)
          .update('entries', entries => entries.map(e => e.set('isLocked', false)))
        );
      });
    }
    else {
      this.costCentreGroups = this.costCentreGroups.setIn([
        costCentreGroupIndex,
        'projectPhaseGroups', projectPhaseGroupIndex,
        'rows', rowIndex,
        'entries', 0,
        'isLocked'
      ], true);
    }

    this._setDirty();
    this._appendOperation("isLocked");
  }

  copyPrevious() {
    this.copyMostRecentNonblank();
  }

  saveFailedDuplicate() {
  }

  saveFailedMissingTask() {
    this.state = "missingTask";
  }

  saveSuccess({ uuidToIdLookup, objects }) {
    this.hasSavedAtLeastOnce = true;

    if (this.events[this.events.length - 1] === "save") {
      this.state = "clean";
    }
  }

  isValid() {
    for (let [_i, entry] of this.iterEntries()) {
      if (!isNumber(entry.get('numMinutes'))) {
        return false;
      }
    }
    return true;
  }

  switchToDailyView() {
    this.mode = 'daily';
    let monday = getMonday(this.startDate);
    if (monday.isSame(getMonday(moment()))) {
      this.loadDay(moment().startOf('day'));
    }
    else {
      this.loadDay(monday);
    }
  }

  * iterEntries() {
    yield* iterEntries(this.costCentreGroups);
  }

  _updateDayTotals() {
    if (this.mode === 'weekly') {
      let dayTotals = [0, 0, 0, 0, 0, 0, 0];
      for (let [i, entry] of this.iterEntries()) {
        if (isNumber(dayTotals[i]) && isNumber(entry.numMinutes)) {
          dayTotals[i] += entry.numMinutes;
        }
        else {
          dayTotals[i] = null;
        }
      }
      this.dayTotals = dayTotals;
    }
    else {
      if (this.initialDayTotals == null) {
        return ['loading', 'loading', 'loading', 'loading', 'loading', 'loading', 'loading'];
      }

      let dayTotals;
      let monday = getMonday(this.date);

      if (this.initialDayTotals !== 'blank') {
        dayTotals = this.initialDayTotals.map(t => t.total);
      }
      else {
        // If we did copy previous then the totals we got from the request
        // won't line up with the current week, and there won't be any entries
        // for the current week.
        dayTotals = [0, 0, 0, 0, 0, 0, 0];
      }
      let columnIndex = this.date.diff(monday, 'days');
      dayTotals[columnIndex] = 0;
      for (let [_, entry] of this.iterEntries()) {
        if (isNumber(dayTotals[columnIndex]) && isNumber(entry.numMinutes)) {
          dayTotals[columnIndex] += entry.numMinutes;
        }
        else {
          dayTotals[columnIndex] = null;
        }
      }
      this.dayTotals = dayTotals;
    }
  }

  hasEntries() {
    for (let [_i, _entry] of this.iterEntries()) {
      return true; // Found an entry!
    }
    return false;
  }

  closeModal(modal) {
    this.modals = _.without(this.modals, modal);
  }
}


export function bulkUpdateEntries({ report, timesheetEntries, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced }) {
  const timesheetEntryUuids = [...new Set(timesheetEntries.map(e => e.uuid))];
  const chunkedTimesheetEntryUuids = _.chunk(timesheetEntryUuids, 50);
  return new Promise(function (resolve, reject) {
    chainRequests(
		chunkedTimesheetEntryUuids.map(tUuids => {
			return () => apiRequest({
				url: `/api/v1/user/timesheet/bulk-update`,
				method: "post",
				data: {
					report: report.serialize(),
					timesheetEntryUuids: tUuids,
					newSettings: {
						projectId:
							project === -1
								? -1
								: project == null
								? null
								: project.id,
						projectPhaseId:
							projectPhase === -1
								? -1
								: projectPhase == null
								? null
								: projectPhase.id,
						taskUuid:
							task === -1 ? -1 : task != null ? task.uuid : null,
						isBillable: isBillable == null ? null : isBillable,
						isVariation: isVariation == null ? null : isVariation,
						isOvertime: isOvertime == null ? null : isOvertime,
						isLocked: isLocked == null ? null : isLocked,
						beenInvoiced: beenInvoiced == null ? null : beenInvoiced
					}
				}
			});
		})
	).then(
		dataArray => {
			if (dataArray.every(d => d.status === "ok")) {
				resolve(dataArray);
			} else {
				reject(null);
			}
		},
		data => reject(null)
	);
  });
}


export function saveTimeEntries({
  startDate,  // moment
  endDate,    // moment
  entries,    // array of TimesheetEntry objects
  deletedEntryUuids,
  userId      // int or 'me'
}) {

  return new Promise(function (resolve, reject) {
    apiRequest({
      url: getTimesheetApiUrl(),
      method: "post",
      data: {
        user: userId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        entries: entries.map(e => e.serialize()),
        deleteEntryUuids: deletedEntryUuids,
        isFixed: true
      },
      success: data => {
        if (data.status === 'ok') {
          resolve(data);
        }
        else if (data.status === 'failure' && data.error === 'duplicate') {
          reject('duplicate');
        }
        else if (data.status === 'failure' && data.error === 'missingTask') {
          reject('missingTask');
        }
        else {
          reject(null);
        }
      },
      error: data => reject(null)
    })
  });
}



export function areSame(ob1, ob2) {
  /**
   * Assumes we already know they're the same type or null.
   */

  function getId(ob) {
    if (ob == null) {
      return null;
    }
    else if (ob.id != null) {
      return ob.id;
    }
    else if (ob.get != null) {
      return ob.get('id');
    }
    else {
      return null;
    }
  }

  return getId(ob1) === getId(ob2);
}


/**
 * Sort by:
 * 1.  Entries with projects at the top, entries with no projects at the bottom; then
 * 2.  By project name; then
 * 3.  Entries with phases at the top, entries with no phases at the bottom; then
 * 4.  By phase name; then
 * 5.  Entries with tasks at the top, entries with no tasks at the bottom; then
 * 6.  By task name; then
 * 7.  Billable tasks at the top; then
 * 8.  Non-variation tasks at the top; then
 * 9.  By date; then
 * 10. By id.
 */
const entryComparator = compareMultiple(
  function (a, b) {
    function hasProject(e) {
      return e.project != null ? 0 : 1;
    }
    return hasProject(a) - hasProject(b);
  },
  function (a, b) {
    function getCostCentreName(e) {
      return e.project != null ? e.project.costCentre.name : "(No cost centre)";
    }
    return getCostCentreName(a).localeCompare(getCostCentreName(b));
  },
  function (a, b) {
    function getProjectName(e) {
      return e.project != null ? e.project.getTitle() : "(No project)";
    }
    return getProjectName(a).localeCompare(getProjectName(b));
  },
  function (a, b) {
    function hasPhase(e) {
      return e.projectPhase != null ? 0 : 1;
    }
    return hasPhase(a) - hasPhase(b);
  },
  function (a, b) {
    function getPhaseName(e) {
      return e.projectPhase != null ? e.projectPhase.getTitle() : "(No phase)";
    }
    return getPhaseName(a).localeCompare(getPhaseName(b));
  },
  function (a, b) {
    function hasTask(e) {
      return e.task != null ? 0 : 1;
    }
    return hasTask(a) - hasTask(b);
  },
  function (a, b) {
    function getTaskName(e) {
      return e.task != null ? e.task.get('name') : "(No task)";
    }
    return getTaskName(a).localeCompare(getTaskName(b));
  },
  function (a, b) {
    return (a.isBillable ? 0 : 1) - (b.isBillable ? 0 : 1);
  },
  function (a, b) {
    return (a.isVariation ? 1 : 0) - (b.isVariation ? 1 : 0);
  },
  function (a, b) {
    return (a.isOvertime ? 1 : 0) - (b.isOvertime ? 1 : 0);
  },
  function (a, b) {
    return (a.isLocked ? 1 : 0) - (b.isLocked ? 1 : 0);
  },
  function (a, b) {
    return (a.beenInvoiced ? 1 : 0) - (b.beenInvoiced ? 1 : 0);
  },
  function (a, b) {
    function getDate(e) {
      return +e.date.toDate();
    }
    return getDate(a) - getDate(b);
  },
  function (a, b) {
    function getId(e) {
      return e.id;
    }
    return getId(a) - getId(b);
  }
);


export function normaliseEntryRows(entries, startDate, endDate) {
  /**
   * Takes a list of entries, returns a list of lists of entries.
   * Each list of entries has exactly one entry for each date from startDate to endDate inclusive.
   */
  const numColumnsInRow = endDate.diff(startDate, 'days') + 1;

  let rows = [makeBlankRow(numColumnsInRow)];

  for (let entry of entries) {
    let columnIndex = entry.date.diff(startDate, 'days');

    let foundEmptySlot = false;
    for (let r of rows) {
      if (r[columnIndex] == null) {
        foundEmptySlot = true;
        r[columnIndex] = entry;
        break;
      }
    }

    if (!foundEmptySlot) {
      let row = makeBlankRow(numColumnsInRow);
      row[columnIndex] = entry;
      rows.push(row);
    }
  }

  for (let row of rows) {
    for (let [columnIndex, entry] of enumerate(row)) {
      if (entry == null) {
        row[columnIndex] = new TimesheetEntry({
          numMinutes: 0,
          date: startDate.clone().add(columnIndex, 'days'),
          businessCategory: entries[0].businessCategory,
          project: entries[0].project,
          projectPhase: entries[0].projectPhase,
          task: entries[0].task,
          isBillable: entries[0].isBillable,
          isVariation: entries[0].isVariation,
          isOvertime: entries[0].isOvertime,
          isLocked: entries[0].isLocked,
          beenInvoiced: entries[0].beenInvoiced,
          staffMember: entries[0].staffMember
        });
      }
    }
  }

  return rows;
}



export function entriesToGroups(entries, startDate, endDate, staffTotalLookup) {
  /**
   * `staffTotalLookup`: {<project phase id>: <total minutes>}
   */

  entries.sort(entryComparator);

  const costCentreGroups = groupBy(
    entries,
    function itemToGrouper(entry) {
      return entry.project != null ? entry.project.costCentre : null;
    },
    function grouperToKey(cs) {
      return cs != null ? cs.id : 0;
    }
  ).map(function ({ grouper: costCentre, items: entries }) {
    const phaseGroups = groupBy(
      entries,
      function itemToGrouper(entry) {
        return [entry.project, entry.projectPhase];
      },
      function grouperToKey([project, phase]) {
        return (project != null ? project.id : 0) + "-" + (phase != null ? phase.id : 0);
      }
    );

    return {
      costCentre: costCentre,
      projectPhaseGroups: phaseGroups.map(function ({ grouper: [project, projectPhase], items: entries }) {
        let taskGroups = groupBy(
          entries,
          function itemToGrouper(e) {
            return [e.task, e.isBillable, e.isVariation];
          },
          function grouperToKey([task, isBillable, isVariation]) {
            return (task != null ? task.uuid : 0) + "-" + (isBillable ? 1 : 0) + "-" + (isVariation ? 1 : 0);
          }
        );

        let r = [];
        for (let { items: entries } of taskGroups) {
          if (startDate != null && endDate != null) {
            r = r.concat(normaliseEntryRows(entries, startDate, endDate));
          }
          else {
            r = r.concat([entries]);
          }
        }

        let projectPhaseGroup = {
          project: project,
          projectPhase: projectPhase,

          rows: r.map(function (row) {
            return {
              isDeleted: row[0].isDeleted,
              project: row[0].project,
              projectPhase: row[0].projectPhase,
              task: row[0].task,
              isBillable: row[0].isBillable,
              isVariation: row[0].isVariation,
              isOvertime: row[0].isOvertime,
              isLocked: row[0].isLocked,
              beenInvoiced: row[0].beenInvoiced,
              entries: row
            };
          })
        };
        projectPhaseGroup.staffTotalNotInView = 0;
        projectPhaseGroup.organisationTotalNotInView = 0;
        projectPhaseGroup.staffMonthTotalNotInView = 0;
        projectPhaseGroup.roleMonthTotalNotInView = 0;
        projectPhaseGroup.organisationMonthTotalNotInView = 0;
        return projectPhaseGroup;
      })
    };
  });

  return Immutable.fromJS(costCentreGroups);
}


function makeBlankRow(numColumnsInRow) {
  let r = [];
  for (let i = 0; i < numColumnsInRow; i++) {
    r.push(null);
  }
  return r;
}


export function updateGroups(
  costCentreGroups,
  row,
  timesheetEntryPath,
  project,
  projectPhase,
  task,
  isBillable,
  isVariation,
  isOvertime,
  isLocked,
  beenInvoiced,
  startDate,
  endDate,
  staffTotalLookup) {

  // We don't know where in the nested cost centre-phase-row structure our
  // entries will end up, so we tag our entries with `isNew = true` so we
  // can find them after the grouping finishes.  First we start by setting
  // them all to false so we don't get mixed up with previously-added
  // entries.

  costCentreGroups = costCentreGroups.map(function (csg) {
    return csg.update('projectPhaseGroups', function (ppgs) {
      return ppgs.map(ppg => ppg.update('rows', function (rows) {
        return rows.map(row => row.update('entries', function (entries) {
          return entries.map(e => e.set('isNew', false));
        }));
      }));
    });
  });

  if (row != null) {
    costCentreGroups = costCentreGroups.updateIn([
      timesheetEntryPath.costCentreGroupIndex,
      'projectPhaseGroups',
      timesheetEntryPath.projectPhaseGroupIndex,
      'rows',
      timesheetEntryPath.rowIndex,
      'entries'
    ], function (entries) {
      return updateTimeEntries(
        entries,
        project,
        projectPhase,
        task,
        isBillable,
        isVariation,
        isOvertime,
        isLocked,
        beenInvoiced
      );
    });
  }

  let entries = costCentreGroups.map(function (csg) {
    return csg.get('projectPhaseGroups').map(function (ppg) {
      return ppg.get('rows').map(function (row) {
        return row.get('entries');
      });
    });
  }).flatten(3); // Flatten for 3 groups to avoid flattening the entries into lists of properties.

  if (row == null) {
    entries = entries.concat(Array.from(imap(iterDateRange(startDate, endDate), function (d) {
      return new TimesheetEntry({
        numMinutes: 0,
        date: d,
        project: project,
        projectPhase: projectPhase,
        task: task,
        isBillable: isBillable,
        isVariation: isVariation,
        isOvertime: isOvertime,
        isLocked: isLocked,
        beenInvoiced: beenInvoiced,
        isNew: true
      });
    })));
  }

  costCentreGroups = entriesToGroups(entries, startDate, endDate, staffTotalLookup);

  let costCentreGroupIndex, projectPhaseGroupIndex, rowIndex;
  out: for (let [i, ccg] of enumerate(costCentreGroups)) {
    for (let [j, ppg] of enumerate(ccg.get('projectPhaseGroups'))) {
      for (let [k, row] of enumerate(ppg.get('rows'))) {
        if (row.get('entries').get(0).isNew) {
          costCentreGroupIndex = i;
          projectPhaseGroupIndex = j;
          rowIndex = k;
          break out;
        }
      }
    }
  }

  return {
    costCentreGroups: costCentreGroups,
    highlightedRowPath: new Path({ costCentreGroupIndex, projectPhaseGroupIndex, rowIndex })
  };
}

export function updateTimeEntries(timeEntries, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced) {
  return timeEntries.map(e => e.merge({
    project: project,
    projectPhase: projectPhase,
    task: task,
    isBillable: isBillable,
    isVariation: isVariation,
    isOvertime: isOvertime,
    isLocked: isLocked,
    beenInvoiced: beenInvoiced,
    isNew: true
  }));
}


export function* iterRows(costCentreGroups) {
  if (costCentreGroups != null) {
    for (let [costCentreGroupIndex, costCentreGroup] of enumerate(costCentreGroups)) {
      for (let [projectPhaseGroupIndex, projectPhaseGroup] of enumerate(costCentreGroup.get('projectPhaseGroups'))) {
        for (let [rowIndex, row] of enumerate(projectPhaseGroup.get('rows'))) {
          if (!row.get('isDeleted')) {
            let path = new Path({
              costCentreGroupIndex: costCentreGroupIndex,
              projectPhaseGroupIndex: projectPhaseGroupIndex,
              rowIndex: rowIndex,
            });
            yield [rowIndex, row, path];
          }
        }
      }
    }
  }
}

export function* iterEntries(costCentreGroups) {
  for (let [, row, path] of iterRows(costCentreGroups)) {
    for (let [entryIndex, entry] of enumerate(row.get('entries'))) {
      if (!entry.isDeleted) {
        yield [entryIndex, entry, new Path({ ...path, entryIndex: entryIndex })];
      }
    }
  }
}


class Path {
  constructor({ costCentreGroupIndex, projectPhaseGroupIndex, rowIndex, entryIndex }) {
    this.costCentreGroupIndex = costCentreGroupIndex;
    this.projectPhaseGroupIndex = projectPhaseGroupIndex;
    this.rowIndex = rowIndex;
    this.entryIndex = entryIndex;
  }

  searchKeyPath() {
    return [
      this.costCentreGroupIndex,
      'projectPhaseGroups',
      this.projectPhaseGroupIndex,
      'rows',
      this.rowIndex,
      'entries',
      this.entryIndex
    ];
  }
}
