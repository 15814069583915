import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import PropTypes from "prop-types";


export var Modal2 = CreateReactClass({
  propTypes: {
    modal: PropTypes.object,
    closeOnClickOutside: PropTypes.bool,
    onClosed: PropTypes.func,
    header: PropTypes.string,
    width: PropTypes.any
  },

  render: function() {
    return <Modal
        closeOnClickOutside={this.props.closeOnClickOutside}
        onClosed={() => this.props.onCancel()}>
      <ModalContent header={this.props.header} width={this.props.width} bodyStyles={{padding:0}}>
        {React.cloneElement(this.props.children, {
          onCancel: this.props.onCancel
        })}
      </ModalContent>
    </Modal>;
  }
});


export var ModalContent = CreateReactClass({
  propTypes: {
    // Passed through to inner div. Height is just the height of the content so
    // no need to worry about that. You can have whatever height you want -- even
    // if it's higher than the browser window itself.
    width: PropTypes.any,

    className: PropTypes.any,

    includeFooter: PropTypes.bool,

    // This, or
    acceptButton: PropTypes.node,

    // Some of these.
    acceptButtonText: PropTypes.string,
    acceptButtonProps: PropTypes.object,

    showCloseButton: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      showCloseButton: false,
      includeFooter: false,

      acceptButtonText: "Submit",
      acceptButtonProps: {},

      headerStyles: {},
      bodyStyles: {},
      footerStyles: {},
    };
  },

  render: function() {
    return (
      <div
          className={this.props.className}
          style={{
            width: this.props.width,
            margin: "5em auto",
            padding: 0,
            flexDirection: 'column',
            color: '#444',
            backgroundColor: 'white',
          }}>
        <div>
          {this.props.header != null ?
            <div className="flex-0-0-auto">
              <div
                  className="modal-header"
                  style={{
                    position: 'relative',
                    height: 53,
                    padding: 10,
                    ...this.props.headerStyles
                  }}>
                {this.props.showCloseButton ?
                  <div>
                    <h3 style={{float: 'left', height: 33, marginTop: 0}}>{this.props.header}</h3>
                    <div style={{float: 'right', width: 100, height: 33, textAlign: 'right'}}>
                      <button
                          className="btn btn-default"
                          onClick={this.handleCloseButtonClick}>
                        <i style={{fontSize: 20, margin: 0}} className="fa fa-close" />
                      </button>
                    </div>
                  </div>
                :
                  <h3 style={{marginTop: 0}}>{this.props.header}</h3>
                }
              </div>
              <div style={{clear: 'both'}} />
            </div>
          : null}
          <div
              className="modal-body flex-1-1-auto"
              style={{
                position: 'relative',
                ...this.props.bodyStyles
              }}>
            {this.props.children}
          </div>
          {this.props.includeFooter ?
            <ModalFooter
              acceptButton={this.props.acceptButton}
              acceptButtonProps={this.props.acceptButtonProps}
              acceptButtonText={this.props.acceptButtonText}
              footerStyles={this.props.footerStyles}
              onSubmit={this.handleAcceptButtonClick}
              onCancel={this.handleCloseButtonClick}
            />
          : null}
        </div>
      </div>
    );
  },

  handleCloseButtonClick: function() {
    this.props.onClose();
  },

  handleAcceptButtonClick: function() {
    this.props.onAccept();
  }
});


export var Modal = CreateReactClass({
  propTypes: {
    closeOnClickOutside: PropTypes.bool,
    onClosed: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      closeOnClickOutside: false,
      onClosed: function() { },
    };
  },

  render: function() {
    return (
      <div
          className={this.props.className}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1050,
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            overflowY: 'scroll'
          }}
          onClick={this.handleOutsideClick}>
        <div onClick={this.handleClick}>
          {this.props.children}
        </div>
      </div>
    );
  },

  handleClick: function(event) {
    event.stopPropagation();
  },

  handleOutsideClick: function() {
    if (this.props.closeOnClickOutside) {
      this.props.onClosed();
    }
  }
});


export var ModalFooter = CreateReactClass({
  propTypes: {
    // This, or
    acceptButton: PropTypes.node,

    // Some of these.
    acceptButtonClassName: PropTypes.any,
    acceptButtonProps: PropTypes.object,
    acceptButtonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,

    footerStyles: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
  },

  getDefaultProps: function() {
    return {
      acceptButtonProps: {}
    };
  },

  render: function() {
    let acceptButton;
    if (this.props.acceptButton != null) {
      acceptButton = this.props.acceptButton;
    }
    else {
      const {className: acceptButtonClassName, ...acceptButtonProps} = this.props.acceptButtonProps;
      acceptButton = <button
          className={classNames("btn", "btn-success", acceptButtonClassName)}
          onClick={() => this.props.onSubmit()}
          {...acceptButtonProps}>
        {this.props.acceptButtonText}
      </button>;
    }

    return <div
        className="modal-footer"
        style={{
          padding: '1em',
          textAlign: 'right',
          borderTop: 'solid 1px #ccc',
          ...this.props.footerStyles
        }}>

      {/*TODO-invoice_impovements-styling cancel button doesnt work*/}
      <a
          href="#"
          onClick={this.props.onCancel}
          style={{paddingRight: '1em'}}>
        Cancel
      </a>

      {acceptButton}
    </div>;
  }
});
