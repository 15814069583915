import _ from "underscore";
import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from 'moment';
import Immutable from 'immutable';
import { Spreadsheet, Cell } from './Spreadsheet.js';
import { organisationStore } from '../organisation.js';
import { dateConverter } from '../models.js';
import { sum, LinearScale, caseInsensitiveContains, camelCaseToSentence } from '../utils.js';
import { DeleteButton, BasicMySelect2, SaveBar, SaveButton, MySelect2, Dropdown3, Dropdown3ListItem, PageLoadingSpinner, AlertBar } from '../widgets.js';
import { CoincraftPage, CoincraftPageHeader } from '../CoincraftPage.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { CashFlowChart, SuperimposedDataRenderer } from "../cashFlowChart.js";
import { userStore } from '../user/flux.js';
import { scheduleStore, actions } from './ScheduleStore.js';
import { HistoryMenu } from './historyMenu/HistoryMenu.js';
import classNames from 'classnames';
import { MultiCostCentreSelect, MultiStaffMemberSelect,
  MultiProjectSelect, ProjectStatusSelect, MultiStaffRoleSelect } from '../widgets/coincraft.js';
import { ReportNameModal } from '../reports/ReportNameModal.js';
import { Modal, ModalContent } from '../modal.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import { permissions, PermissionLevel } from '../models/permissions.js';
import { getOnboardingData } from "../organisationStore.js";
import { Multiselect } from 'react-widgets';
import { getGradient } from './rows/gradient'

import elementResizeDetectorMaker from "element-resize-detector";
import { projectStatusOptions } from '../models/project.js';
import { FilterTextBox } from "../widgets/generic.js";
import PropTypes from "prop-types";
const erd = elementResizeDetectorMaker();


export var NewSchedulePage = CreateReactClass({

  propTypes: {
    spreadsheetType: PropTypes.oneOf(['revenue', 'allocation']).isRequired
  },

  mixins: [
    makeMultipleStoreMixin([scheduleStore, organisationStore, userStore], function() {
      if (!scheduleStore.isReady) {
        return {isReady: false};
      }

      return {
        isReady: scheduleStore.isReady,
        currentMonthIndex: scheduleStore.currentMonthIndex,
        startMonth: scheduleStore.startMonth,
        endMonth: scheduleStore.endMonth,
        dateColumns: scheduleStore.dateColumns,

        totalRows: scheduleStore.totalSpreadsheetRows,
        projectRows: scheduleStore.projectSpreadsheetRows,
        hoursRows: scheduleStore.hoursRows,
        graphData: scheduleStore.graphData,

        dataType: scheduleStore.dataType,
        groups: scheduleStore.groups,
        totalType: scheduleStore.totalType,
        percentType: scheduleStore.percentType,
        isDirty: scheduleStore.isDirty,
        saveState: scheduleStore.projectSaveState,
        filters: scheduleStore.filters,
        showFilters: scheduleStore.showFilters,
        showSidebar: scheduleStore.showSidebar,
        selectedCell: scheduleStore.selectedCell,
        columns: scheduleStore.getColumns(),
        selectedRow: scheduleStore.selectedRow,
        selectedMonthIndex: scheduleStore.selectedMonthIndex,
        loadingData: scheduleStore.loadingData,

        addRowStaff: scheduleStore.addRowStaff,
        addRowPhases: scheduleStore.addRowPhases,

        selectedReport: scheduleStore.selectedReport,
        defaultReport: scheduleStore.defaultReport,
        reports: scheduleStore.reports,
        reportName: scheduleStore.reportName,
        modal: scheduleStore.modal,

        user: userStore.user,
        organisationStore: organisationStore,

        onboardingData: getOnboardingData(organisationStore, permissions, userStore),
      };
    })
  ],

  componentWillMount: function() {
    scheduleStore.initialize();
  },

  shiftDate: function(direction) {
    if (direction === 'right') {
      actions.moveRight();
    } else if (direction === 'left') {
      actions.moveLeft();
    }
  },

  componentDidUpdate: function() {
    const { setupResizeListener, isReady } = this.state
    if (!setupResizeListener && isReady) {
      this.setupResizeHandler();
    }
  },

  setupResizeHandler() {
    let el = this.refs.body;
    if (el) {
      erd.listenTo(el, this.handleResize);
      this.handleResize(el);
      this.setState({
        setupResizeListener: true
      });
    }
  },

  handleResize: function(element) {
    this.setState({
      hasSize: true,
      offsetWidth: element.offsetWidth,
      contentOffsetWidth: element.offsetWidth * 72 / 100,
      offsetHeight: element.offsetHeight
    });
  },

  render: function() {
    if (this.state.onboardingData && !this.state.onboardingData.hasHours) {
      return <IncompleteSetupPage
        heading={'Resource Schedule'}
        onboardingData={this.state.onboardingData}
        type="resource"
      />;
    }
    let self = this;
    return <CoincraftPage
      header={this.renderHeader()}
      body={this.renderBody()}
      sidebar={
        <div>
          {this.state.showSidebar && this.state.selectedCell ?
            <div
                className="context-menu"
                style={{minWidth: '25em'}}>
              <HistoryMenu project={this.state.selectedRow.project} user={this.state.user}/>
            </div>
          : null}
        </div>
      }
      saveBar={this.state.saveState === 'failed' ?
        <SaveBar
          isDirty={this.state.isDirty}
          saveState={this.state.saveState}
          onSaveClick={() => actions.saveAll()}
          unsavedChangesMessage={"There was an issue saving your forecast. Please contact us if the problem persists."}
          buttonText={"Try Again"}
          color={'#dc2d11'}
        />
      : this.state.isDirty || this.state.saveState != null ?
        <SaveBar
          isDirty={this.state.isDirty}
          saveState={this.state.saveState}
          onSaveClick={() => actions.saveAll()}
          unsavedChangesMessage={"Save changes to your forecast? "}
        />
      : this.state.loadingData ? 
            <AlertBar closeButton={false} >
              <i className="fa fa-spinner fa-spin" />
              <span>Loading Data...</span>
            </AlertBar>
      : null}
    />;
  },

  renderBody: function() {
    if (!this.state.isReady) {
      return <div ref="body">
        <PageLoadingSpinner
          text={"Loading Resource Schedule"}
        />
      </div>
    } else {
      return <div ref="body">
        {this.renderModals()}
        <CashFlowChartContainer
          contentOffsetWidth={this.state.contentOffsetWidth}
          graphData={this.state.graphData}
          startMonth={this.state.graphData[0].monthIndex}
          yAxisWidth={'28%'}
          contentWidth={'72%'}
          yValueType={'hours'}
        />
        <Spreadsheet
          columns={this.state.columns}
          rows={this.state.totalRows}
          cellComponent={RevenueCell}
        />
        <Spreadsheet
          columns={this.state.columns}
          rows={this.state.projectRows}
          cellComponent={RevenueCell}
          onCommitCell={function (cell, inputText) {
            return new Promise(function (resolve, reject) {
              actions.commitInputText(cell, inputText);
              resolve();
            });
          }}
          onRollbackCell={function (cell) {
            return new Promise(function (resolve, reject) {
              actions.rollbackInputText(cell);
              resolve();
            });
          }}
          onSetInputText={function (cell, inputText) {
            return new Promise(function (resolve, reject) {
              actions.setInputText(cell, inputText);
              resolve();
            });
          }}
          edgeKeyPress={this.shiftDate}
          selectCell={cell => actions.selectCell(cell)}
        />
      </div>
    }
  },

  renderHeader: function() {
    const subHeading = this.state.dateColumns ? `${_.first(this.state.dateColumns).value} - ${_.last(this.state.dateColumns).value}` : ""
    const reports = this.state.reports || []
    return <div>
      <CoincraftPageHeader
        heading={"Resource Schedule"}
        subHeading={subHeading}
        filter={false/*this.props.hasFilter*/ ?
          <FilterTextBox
            style={{width: '19em'}}
            value={this.props.filterText}
            onChange={this.handleFilterTextChange}
            placeholder={this.props.filterInputPlaceholder}
          />
        : null}
        rightButtons={<div style={{display: 'inline-block'}}>
          {this.props.extraRightButtons}
          <button className="export-button page-header__button" onClick={() => actions.exportSpreadsheet()}>
            <i className="fa fa-table" />
            Export
          </button>
          <button className="print-button page-header__button" onClick={() => window.print()}>
            <i className="fa fa-print" />
            Print
          </button>
        </div>}
        extraContent={<div className="report-selector">
          {'Reports: '}
          <BasicMySelect2
            style={{marginLeft: '0.5em'}}
            value={this.state.selectedReport}
            onChange={(reportUuid) => actions.changeSelectedReport(reportUuid)}
            options={reports.map(r => ({label: `${r.name}${this.state.defaultReport === r.uuid ? ' (default)' : ''}`, value: r.uuid}))}
            placeholder={"Select Report..."}
            filter={function(report, searchText) {
              return caseInsensitiveContains(report.label, searchText);
            }}
          />
          {this.renderReportButtons()}
        </div>}
      />
    {this.state.showFilters ? this.renderReportFilters() : null}
    </div>
  },

  renderModals: function() {
    if (this.state.modal === "saveReport") {
      return <ReportNameModal
        modal={'ReportNameModal'}
        header="Save Report As..."
        initialName={this.state.reportName}
        onSave={(reportName) => actions.saveReport(reportName)}
        actions={actions}
      />;
    } else if (this.state.modal === "rename") {
      return <ReportNameModal
        modal={'RenameReportModal'}
        header="Rename Report"
        initialName={this.state.reportName}
        onSave={(reportName) => actions.saveReport(reportName)}
        actions={actions}
      />;
    } else if (this.state.modal === "delete") {
      return <Modal
          closeOnClickOutside={true}
          onClosed={() => actions.closeModal()}>
        <ModalContent header="Delete report" width='35em'>
          <p style={{padding: '2em'}}>
            Are you sure you want to delete this report?
          </p>
          <div style={{padding: '1em', textAlign: 'right', borderTop: '1px solid #ccc'}}>
            <a
                href="javascript:void(0)"
                onClick={() => actions.closeModal()}>
              Cancel
            </a>
            <DeleteButton
              text="Delete report"
              onClick={() => actions.deleteReport()}
              style={{marginLeft: '2em'}}
            />
          </div>
        </ModalContent>
      </Modal>;
    } else if (this.state.modal === "addStaffRow") {
      const multiSelText = item => `${item.project.getTitle()}: ${item.phase.getTitle()}`
      return <Modal
        closeOnClickOutside={true}
        onClosed={() => actions.closeModal()}>
        <ModalContent header="Add Staff Row" width='35em'>
          <div style={{ padding: '2em' }}><p style={{ marginBottom: '2em' }}>
            Please select the projects / phase below that you wish to assign to staff member / role.
          </p>
          <Multiselect
            value={this.state.addRowProjects}
            data={_.sortBy(organisationStore.projects.reduce((phases, project) => {
              let phs = ((project.getVisiblePhases().map(phase => ({
                id: phase.uuid,
                project: phase.project,
                phase: phase
              }))))
              phases.push(...phs)
              return phases
            }, []), o => {
              return projectStatusOptions.indexOf(o.project ? o.project.status : "active").toString() 
                + (o.project != null ? o.project.getTitle() || "No title" : "No title").toString()
                + (o.phase != null ? o.phase.getTitle() || "" : "").toString()
            })}
            groupBy={o => ((o.project ? camelCaseToSentence((o.project.status + 'Projects')): undefined) || "No Status")}
            valueField='id'
            textField={multiSelText}
            onChange={options => actions.updateAddRowProjects(options)}
            filter={function (item, searchText) {
              return multiSelText(item).toLowerCase().match(searchText.toLowerCase());
            }}
          /></div>
          <div style={{ padding: '1em', textAlign: 'right', borderTop: '1px solid #ccc' }}>
            <a
              href="javascript:void(0)"
              onClick={() => actions.closeModal()}>
              Cancel
            </a>
            <SaveButton
              text="Add Rows"
              onClick={() => actions.addProjectsToStaff()}
              style={{ marginLeft: '2em' }}
            />
          </div>
        </ModalContent>
      </Modal>;
    } else if (this.state.modal === "addProjectRow") {
      const multiSelText = item => item.staff ? item.staff.getFullName() : item.role.name
      return <Modal
        closeOnClickOutside={true}
        onClosed={() => actions.closeModal()}>
        <ModalContent header="Add Project Row" width='35em'>
          <div style={{ padding: '2em' }}><p style={{ marginBottom: '2em' }}>
            Please select the roles / staff below that you wish to assign to Project / Phase.
          </p>
          <Multiselect
            value={this.state.addRowStaff}
            data={[
              ...organisationStore.staffMembers.reduce((staff, sm) => {
                staff.push({
                  id: sm.uuid,
                  staff: sm,
                  role: sm.role,
                })
                return staff
              }, []),
              ...organisationStore.staffRoles.reduce((roles, r) => {
                roles.push({
                  id: r.uuid,
                  staff: null,
                  role: r,
                })
                return roles
              }, [])
            ]}
            valueField='id'
            textField={multiSelText}
            onChange={options => actions.updateAddRowStaff(options)}
            filter={function (item, searchText) {
              return multiSelText(item).toLowerCase().match(searchText.toLowerCase());
            }}
          /></div>
          <div style={{ padding: '1em', textAlign: 'right', borderTop: '1px solid #ccc' }}>
            <a
              href="javascript:void(0)"
              onClick={() => actions.closeModal()}>
              Cancel
            </a>
            <SaveButton
              text="Add Rows"
              onClick={() => actions.addStaffToProjects()}
              style={{ marginLeft: '2em' }}
            />
          </div>
        </ModalContent>
      </Modal>;
    }
  },

  renderReportButtons: function() {
    return <div className="flex-1-1-auto flexbox-container">
      {this.state.selectedReport ?
        <button onClick={() => actions.changeSelectedReport(null)} style={{padding: '0.25em 0.5em'}}>
          x
        </button>
      :null}
      <button onClick={() => actions.toggleFilters()}>
        {!this.state.showFilters ? "Show Filters" : "Hide Filters"}
      </button>
      {this.state.showFilters ?
        <div style={{marginTop: '0.25em'}}>
          {this.state.selectedReport ?
            <ReportActionsDropdown/>
          : null}
          <ReportSaveDropdown/>
        </div>
      : null}
    </div>;
  },

  renderReportFilters: function() {
    return <div className="report-selector" style={{width: '100%', marginTop: '1em', display: 'block', padding: '1em 0', borderTop: 'solid 1px #666'}}>
      <div style={{ padding: '1em 0.5em' }}>
        Groups:
          <Multiselect
            value={this.state.groups}
            data={[
              { id: "role", label: "Staff Role" },
              { id: "staff", label: "Staff Member" },
              { id: "project", label: "Project" },
              { id: "phase", label: "Project Phase" },
              { id: "status", label: "Project Status" },
            ]}
            valueField='id'
            textField='label'
            style={{width: "50em"}}
            onChange={(groups) => actions.setGroups(groups.map(g => g.id))}
            onBlur={() => actions.updateSpreadsheet()}
            filter={function (item, searchText) {
              return String(item).toLowerCase().match(searchText.toLowerCase());
            }}
          />
      </div>
      <div style={{padding: '1em 0.5em'}}>
        {'Hours Data: '}
        <BasicMySelect2
          style={{ margin: '0 1.5em 0 0.5em', maxWidth: "15em" }}
          value={this.state.dataType}
          onChange={(dataType) => actions.setDataType(dataType)}
          options={[
            {label: 'Actuals + Projected', value: 'actualsProjected'},
            {label: 'Actuals', value: 'actuals'},
            {label: 'Projected', value: 'projected'}
          ]}
        />
        {'Totals: '}
        <BasicMySelect2
          style={{ margin: '0 1.5em 0 0.5em' }}
          value={this.state.totalType}
          onChange={(totalType) => actions.setTotalType(totalType)}
          options={[
            { label: 'Budget Use', value: 'budgetUse' },
            { label: 'Remaining Budget', value: 'remainingBudget' },
            { label: 'Average Utilisation', value: 'averageUtilisation' },
          ]}
        />
        {'Percent: '}
        <BasicMySelect2
          style={{ margin: '0 1.5em 0 0.5em' }}
          value={this.state.percentType}
          onChange={(percentType) => actions.setPercentType(percentType)}
          options={[
            { label: 'Utilisation', value: 'utilisation' },
            { label: 'Budget Use', value: 'budgetUse' },
          ]}
        />
      </div>
      <div className="flexbox-container" style={{width: '100%', fontSize: '0.95em'}}>
        <div style={{width: '20%', padding: '0.5em'}}>
          Cost Centres:
          <MultiCostCentreSelect
            value={this.state.filters.costCentres}
            onChange={(costCentres) => actions.setFilteredCostCenters(costCentres)}
            onBlur={() => actions.updateSpreadsheet()}
          />
        </div>
        <div style={{width: '20%', padding: '0.5em'}}>
          Project Status:
          <ProjectStatusSelect
            value={this.state.filters.projectStatus}
            onChange={(projectStatuses) => actions.setFilteredProjectStatus(projectStatuses.map(ps => ps.id))}
            onBlur={() => actions.updateSpreadsheet()}
          />
        </div>
        <div style={{width: '20%', padding: '0.5em'}}>
          Projects:
          <MultiProjectSelect
            value={this.state.filters.projects}
            onChange={(projects) => actions.setFilteredProjects(projects)}
            onBlur={() => actions.updateSpreadsheet()}
          />
        </div>
        <div style={{ width: '20%', padding: '0.5em' }}>
          Roles:
          <MultiStaffRoleSelect
            value={this.state.filters.roles}
            onChange={(roles) => actions.setFilteredRoles(roles)}
            onBlur={() => actions.updateSpreadsheet()}
          />
        </div>
        <div style={{width: '20%', padding: '0.5em'}}>
          Staff:
          <MultiStaffMemberSelect
            value={this.state.filters.staff}
            onChange={(staff) => actions.setFilteredStaff(staff)}
            onBlur={() => actions.updateSpreadsheet()}
          />
        </div>
      </div>
    </div>
  }

});


class RevenueCell extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (
      this.props.isEditing != nextProps.isEditing
      || this.props.isSelected != nextProps.isSelected
      || this.props.cell.inputText != nextProps.cell.inputText
      || this.props.cell.value != nextProps.cell.value
      || this.props.column.monthIndex != nextProps.column.monthIndex
      || this.props.cell.visible != nextProps.cell.visible
      || this.props.cell.expanded != nextProps.cell.expanded
      || this.props.cell.expandable != nextProps.cell.expandable
      || this.props.cell.inRange != nextProps.cell.inRange
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment()));
    const isRowHeader = this.props.cell.isRowHeader;
    const cellType = this.props.cell.cellType;
    const cell = this.props.cell;
    const isColumnHeader = this.props.cell.isColumnHeader;
    const value = this.props.cell.value;
    const numValue = this.props.cell.numValue;
    const isEditable = !((this.props.cell.isEditable === false) || isRowHeader || isColumnHeader);

    let displayValue;
    if (this.props.isEditing) {
      displayValue = this.props.cell.inputText;
    }
    else {
      if (value != null && value.toFixed != null) {
        displayValue = value.toFixed(0);
      }
      else if(cellType === 'title') {
        displayValue = <span>
          {cell.addProjectRowButton ?
            <i
              className={`fa fa-plus`}
              onClick={() => actions.clickAddProjectRowButton(cell.uuid)} />
            : null}
          {cell.addStaffRowButton ?
            <i
              className={`fa fa-plus`}
              onClick={() => actions.clickAddStaffRowButton(cell.uuid)} />
            : null}
          {cell.hideable ?
            <i
              className={`fa fa-eye${!cell.visible ? '-slash' : ''}`}
              onClick={() => actions.toggleItemVisibility(cell.uuid)} />
            : null}
          {cell.expandable ? 
            <i
              className={`fa fa-chevron${!cell.expanded ? '-right' : '-down'}`}
              onClick={() => actions.toggleItemExpand(cell.uuid)} />
          : null }
            {/*<span>{p.likelihood}</span>*/}
          <span>{value}</span>
        </span>
      }
      else {
        displayValue = value;
      }
    }
    return <Cell
      ref="input"
      {...this.props}
      value={displayValue}
      cellProps={{
        className: classNames({
          editable: isEditable,
          rowheader: isRowHeader,
          columnheader: isColumnHeader,
          pasttime: this.props.column.monthIndex && this.props.column.monthIndex < currentMonthIndex,
          currenttime: this.props.column.monthIndex && this.props.column.monthIndex === currentMonthIndex,
          futuretime: this.props.column.monthIndex && this.props.column.monthIndex > currentMonthIndex,
          negative: this.props.cell.error,
          // neutral: numValue === 0,
          notvisible: this.props.cell.visible === false,
          project: this.props.cell.isProject,
          scheduleinrange: this.props.cell.inRange,
        }),
        style: {
          ...(cell.percent > 0 ? getGradient(cell.percent / 100) : {}),
          ...{ mixBlendMode: this.props.isSelected ? 'normal' : 'multiply' },
        }
      }}
    />;
  }

  get input() {
    return this.refs.input.input;
  }
}

var CashFlowChartContainer = CreateReactClass({
  propTypes: {
    contentOffsetWidth: PropTypes.number.isRequired,
    graphData: PropTypes.array.isRequired,
    startMonth: PropTypes.number.isRequired,
    yAxisWidth: PropTypes.string.isRequired, // percent-string
    contentWidth: PropTypes.string.isRequired, // percent-string
    yValueType: PropTypes.oneOf(['dollars', 'hours']).isRequired
  },

  // shouldComponentUpdate: function(nextProps) {
  //   //TODO needs to actually be aware of cell values for when visibilty changes
  //   return (this.props.contentOffsetWidth !== nextProps.contentOffsetWidth
  //           || this.props.startMonth !== nextProps.startMonth);
  // },

  render: function() {
    const data = this.props.graphData;
    const minX = data[0].date;
    const maxX = _.last(data).date + 31; //TODO-next_scheduler_fiddling

    const xScale = new LinearScale({
      minX: data[0].date,
      maxX: data[0].date + 365,
      minY: 0,
      maxY: this.props.contentOffsetWidth
    });

    return <CashFlowChart
      data={this.props.graphData}
      paddingBottom="20%"
      showLegend={false}
      renderer={new SuperimposedDataRenderer()}
      enableHoverTooltips={false}
      min={minX}
      max={maxX}
      viewportScrollLeft={dateConverter.monthIndexToOffset(this.props.startMonth)}
      manageOwnScrolling={false}
      showXAxis={false}
      showScrollbar={false}
      onScroll={this.handleCashFlowGraphScroll}
      xScale={xScale}
      yValueType={this.props.yValueType}
      padding={{
        top: '0%',
        left: this.props.yAxisWidth,
        bottom: '0%',
        right: '0%'
      }}
    />;
  }
});

var ReportActionsDropdown = CreateReactClass({
  propTypes: {
    report: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <Dropdown3
        isExpanded={this.props.isExpanded}
        onToggle={this.handleToggle}
        toggleElement={
          <button
              className="primary"
              style={{margin: '0.25em'}}>
            Actions
            <i className="rw-i rw-i-caret-down" style={{marginLeft: '0.5em'}} />
          </button>
        }
        contentStyle={{width: '18em', color: '#444', borderRadius: '0.5em'}}>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => actions.setDefaultReport()}>
        Set As Default
      </Dropdown3ListItem>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => actions.clickRenameReportButton()}>
        Rename
      </Dropdown3ListItem>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => actions.clickDeleteReportButton()}>
        Delete
      </Dropdown3ListItem>
    </Dropdown3>;
  },

  handleToggle: function(isExpanded) {
    this.props.actions.setActionsMenuExpanded(isExpanded);
  }
});

var ReportSaveDropdown = CreateReactClass({
  propTypes: {
    report: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <Dropdown3
        className="report-save-dropdown"
        isExpanded={this.props.isExpanded}
        onToggle={this.handleToggle}
        toggleElement={
          <button
              className="primary"
              style={{margin: '0.25em'}}>
            Save
            <i className="rw-i rw-i-caret-down" style={{marginLeft: '0.5em'}} />
          </button>
        }
        contentStyle={{width: '14em', color: '#444', borderRadius: '0.5em'}}>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          className="report-save-dropdown__save-option"
          onClick={() => actions.clickSaveReportButton()}>
        Save
      </Dropdown3ListItem>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => actions.clickSaveAsReportButton()}>
        Save As...
      </Dropdown3ListItem>
    </Dropdown3>;
  },

  handleToggle: function(isExpanded) {
    this.props.actions.setSaveMenuExpanded(isExpanded);
  }
});
