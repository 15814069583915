import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Project, ProjectPhase } from '../models.js';
import { LeaveHook } from "../utils.js";
import { projectStore } from './flux.js';
import { organisationStore } from '../organisation.js';
import { wrapUserStore } from '../user/utils.js';
import { ProjectForm } from './ProjectForm.js';
import { CoincraftPage } from '../CoincraftPage.js';
import PropTypes from "prop-types";


export var _ProjectPage = CreateReactClass({
  propTypes: {
    user: PropTypes.object.isRequired
  },

  mixins: [
    LeaveHook
  ],

  componentWillMount: function() {
    this._loadProjectId(this.props.params.projectId);
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.params.projectId !== this.props.params.projectId) {
      this._loadProjectId(nextProps.params.projectId);
    }
  },

  _loadProjectId: function(projectId) {
    let project;
    if (projectId === 'new') {
      project = new Project({
        phases: [new ProjectPhase()]
      });
      project.phases.forEach(pp => {
        pp.createDefaultTask();
      })
    }
    else {
      project = organisationStore.getProjectById(parseInt(projectId));
    }
    projectStore.loadProject(project);
    this.setState({project: project});
  },

  render: function() {
    return <ProjectForm
      user={this.props.user}
      project={this.state.project}
      template={CoincraftPage}
      showRevenueHint={this.props.location.query.showRevenueHint === 'true'}
    />;
  },

  routerWillLeave: function(nextLocation) {
    if (projectStore.isDirty) {
      return "We haven't saved your changes to this project yet. " +
        "If you leave the project page without saving, you'll lose your changes.";
    }
  }
});


export const ProjectPage = wrapUserStore(_ProjectPage);
