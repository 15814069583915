import { generateUUID } from "../utils.js";
import Immutable from "immutable";
import { dateConverter } from "../models.js";
import { organisationStore } from "../organisation.js";

export const TimesheetEntry = class extends Immutable.Record({
	id: null,
	uuid: null,
	rowIndex: 0,
	selected: false,
	businessCategory: null,
	project: null,
	projectPhase: null,
	task: null,
	isBillable: true,
	isVariation: false,
	isOvertime: false,
	isLocked: false,
	beenInvoiced: false,
	staffMember: null,
	staffRole: null,
	date: null,
	numMinutes: 0,
	notes: "",
	pay: 0,
	cost: 0,
	chargeOut: 0,
	chargeOutRate: 0,
	isDeleted: false,
	isNew: false
}) {
	constructor(options = {}) {
		super({
			...options,
			uuid: options.uuid || generateUUID(),
			isLocked:
				(options.projectPhase &&
					!organisationStore.organisation.settings.timeEntryStatus.includes(
						options.projectPhase?.status
					)) ||
				options.isLocked
		});
	}

	serialize() {
		return {
			id: this.id,
			uuid: this.uuid,
			rowIndex: this.rowIndex,
			projectId: this.project != null ? this.project.id : null,
			projectPhaseId:
				this.projectPhase != null ? this.projectPhase.id : null,
			taskUuid: this.task != null ? this.task.uuid : null,
			isBillable: this.isBillable,
			isVariation: this.isVariation,
			isOvertime: this.isOvertime,
			isLocked: this.isLocked,
			beenInvoiced: this.beenInvoiced,
			totalMinutes: this.numMinutes,
			date: this.date.format("YYYY-MM-DD"),
			notes: this.notes
		};
	}

	clone() {
		return new TimesheetEntry({
			id: this.id,
			uuid: this.uuid,
			rowIndex: this.rowIndex,
			project: this.project,
			projectPhase: this.projectPhase,
			task: this.task,
			isBillable: this.isBillable,
			isVariation: this.isVariation,
			isOvertime: this.isOvertime,
			isLocked: this.isLocked,
			beenInvoiced: this.beenInvoiced,
			staffMember: this.staffMember,
			staffRole: this.staffRole,
			date: this.date.clone(),
			numMinutes: this.numMinutes,
			notes: this.notes,
			isDeleted: this.isDeleted,
			isNew: this.isNew
		});
	}

	createBlankCopy(date) {
		return new TimesheetEntry({
			id: null,
			numMinutes: 0,
			notes: "",
			project: this.project,
			projectPhase: this.projectPhase,
			task: this.task,
			isBillable: this.isBillable,
			isVariation: this.isVariation,
			isOvertime: false,
			isLocked: false,
			beenInvoiced: false,
			rowIndex: this.rowIndex,
			date: date
		});
	}

	get monthIndex() {
		return dateConverter.momentToMonthIndex(this.date);
	}

	isSame(other) {
		return (
			other != null &&
			other.constructor === TimesheetEntry &&
			((this.id != null && other.id === this.id) ||
				(this.uuid != null && other.uuid === this.uuid))
		);
	}
};
