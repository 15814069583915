import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from "./modal.js";
import { SubscriptionForm } from './SubscriptionForm.js';
import { actions as authenticationActions } from './authenticationService.js';
import { store as pricingStore, actions as pricingActions} from './pricing.js';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import classNames from 'classnames';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { permissions } from './models/permissions.js';
import { organisationStore, actions as organisationActions, newOrganisationActions } from "./organisation.js";
import { invoicesActions } from './invoices/flux.js';
import { wrapUserStore } from './user/utils.js';
import { router } from './router.js';
import { userStore } from './user';
import PropTypes from 'prop-types';


const menuStructure = [
	{
		item: "forecasts",
		label: "Forecasts",
		children: ["revenueForecast", "resourceSchedule"]
	},
	{
		item: "projects",
		label: "Projects",
		children: ["projects", "projectPlanner", "createProject"]
	},
	{
		item: "staff",
		label: "Staff",
		children: ["staff", "staffRoles", "createStaff"]
	},
	{
		item: "invoices",
		label: "Invoices",
		children: [
			"invoices",
			"invoiceTemplates",
			"invoiceSettings",
			"createInvoice"
		]
	},
	{
		item: "timesheets",
		label: "Timesheets",
		children: ["myTimesheets", "timesheetReports"]
	},
	{
		item: "organisation",
		label: "Organisation",
		children: ["overheadExpenses", "costCentres", "holidays", "contacts", "settings"]
	}
];


export const menuItems = {
  revenueForecast: {
    label: "Revenue Forecast",
    path: "/dashboard/revenue-forecast"
  },
  resourceSchedule: {
    label: "Resource Schedule",
    path: "/dashboard/resource-schedule"
  },
  projects: {
    label: "Project List",
    path: "/dashboard/projects"
  },
  projectPlanner: {
    label: "Project Planning",
    path: "/dashboard/milestones",
  },
  createProject: {
    label: "+ Create Project",
    path: "/dashboard/project/new"
  },
  staff: {
    label: "Staff List",
    path: "/dashboard/staff"
  },
  staffRoles: {
    label: "Staff Roles",
    path: "/dashboard/staff-roles"
  },
  createStaff: {
    label: "+ Create Staff",
    onClick: () => newOrganisationActions.newStaffMember()
  },
  invoices: {
    label: "Invoice List",
    path: "/dashboard/invoices"
  },
  invoiceTemplates: {
    label: "Invoice Templates",
    onClick: () => invoicesActions.initiateEditTemplates()
  },
  invoiceSettings: {
    label: "Invoice Settings",
    onClick: () => organisationActions.openAccountingSystemSettingsPopup()
  },
  createInvoice: {
    label: "+ Create Invoice",
    onClick: () => invoicesActions.initiateCreateNewInvoice()
  },
  myTimesheets: {
    label: "My Timesheets",
    path: "/dashboard/timesheet/current"
  },
  timesheetReports: {
    label: "Timesheet Reports",
    path: "/dashboard/timesheet"
  },
  overheadExpenses: {
    label: "Overhead Expenses",
    path: "/dashboard/expenses"
  },
  costCentres: {
    label: "Cost Centres",
    path: "/dashboard/cost-centres"
  },
  contacts: {
    label: "Contacts",
    path: "/dashboard/contacts"
  },
  settings: {
    label: "Settings",
    path: "/dashboard/settings"
  },
  holidays: {
    label: "Holidays",
    path: "/dashboard/org-hols"
  }
};




function dateToString(m) {
  const n = m.clone().startOf('day').diff(moment().startOf('day'), 'days');
  if (n === 0) {
    return "Today";
  }
  else if (n === 1) {
    return "Tomorrow";
  }
  else {
    return `in ${n} Days`;
  }
}



var SubscribeNowBanner = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore, pricingStore], function() {
      if (!pricingStore.isReady) {
        pricingStore.initialize();
        return {
          isReady: false
        };
      }
      else {
        return {
          isReady: true,
          trialEndsOn: pricingStore.trialEndsOn
        };
      }
    })
  ],

  render: function() {
    if (!this.state.isReady) {
      return null;
    } else if (!organisationStore.organisation.hasTrialExpired()) {
      return <div className="trial-bar">
        Trial Ends {dateToString(this.state.trialEndsOn)}
        <div
          className="subscribe-btn"
          onClick={() => router.history.push("/billing")}>
          Click Here to Subscribe!
        </div>
      </div>;
    } else {
      return <div className="trial-bar">
        Your trial has expired. If you wish to continue using Coincraft please subscribe below.
      </div>;
    }
  }
});

var CreditCardBanner = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore], function () {
      return {
        cardExpDate: organisationStore.organisation.cardExpDate,
        hasCreditCardExpired: organisationStore.organisation.hasCreditCardExpired(),
        willCreditCardExpireInLessThanAMonth: organisationStore.organisation.willCreditCardExpireInLessThanAMonth(),
      };
    })
  ],

  render: function () {
    if (this.state.willCreditCardExpireInLessThanAMonth) {
      return <div className="trial-bar">
        Your Card Expires {dateToString(this.state.cardExpDate)}
        <div
          className="subscribe-btn"
          onClick={() => router.history.push("/billing")}>
          Click Here to Update!
        </div>
      </div>;
    } else if (this.state.hasCreditCardExpired) {
      return <div className="trial-bar">
        Your Card has Expired
        <div
          className="subscribe-btn"
          onClick={() => router.history.push("/billing")}>
          Click Here to Update!
        </div>
      </div>;
    }
  }
});

var UnpaidInvoiceBanner = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore], function () {
      return {
        earliestUnpaidInvoiceDate: organisationStore.organisation.getEarliestUnpaidInvoiceDate(),
        hasOverdueInvoices: organisationStore.organisation.hasOverdueInvoices(),
        hasUnpaidInvoices: organisationStore.organisation.hasUnpaidInvoices(),
      };
    })
  ],

  render: function () {
    if (this.state.hasOverdueInvoices) {
      return <div className="trial-bar">
        You currently have overdue invoices. If you wish to continue using Coincraft please pay below.
      </div>;
    } else if (this.state.hasUnpaidInvoices) {
      return <div className="trial-bar">
        You currently have unpaid invoices due {dateToString(this.state.earliestUnpaidInvoiceDate)}
        <div
          className="subscribe-btn"
          onClick={() => router.history.push("/billing")}>
          Pay Here!
        </div>
      </div>;
    }
  }
});




export var CoincraftHeader = wrapUserStore(CreateReactClass({
  render: function() {
    return <_CoincraftHeader
      user={this.props.user}
      currentPath={window.location.pathname}
    />;
  }
}));


export var _CoincraftHeader = CreateReactClass({
  propTypes: {
    user: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([pricingStore, organisationStore], function() {
      return {
        isSubscribeFormOpen: pricingStore.isOpen,
        hasSubscription: organisationStore.hasSubscription,
        organisation: organisationStore.organisation,
      };
    }),
    PureRenderMixin
  ],

  componentWillMount: function() {
    window.$("body").on("mouseover", this.handleBodyMouseOver);
  },

  componentWillUnmount: function() {
    window.$("body").off("mouseover", this.handleBodyMouseOver);
  },

  canSeeProjectPlanning: function() {
    let safeIds = [6, 299, 629, 50];
    return safeIds.includes(organisationStore.organisation.id);
  },

  render: function() {
    let self = this;
    let organisation = this.state.organisation;

    return (
      <header className="coincraft-header">
        {userStore.isAdmin() && !this.state.hasSubscription ?
          <SubscribeNowBanner />
        : userStore.isAdmin() &&  organisation.useStripe && (this.state.organisation.hasOverdueInvoices() || this.state.organisation.hasUnpaidInvoices()) ?
          <UnpaidInvoiceBanner />
        : userStore.isAdmin() && organisation.useStripe && (this.state.organisation.hasCreditCardExpired() || this.state.organisation.willCreditCardExpireInLessThanAMonth()) ?
          <CreditCardBanner />
        : null}

        <div className="header-bar">
          <div className="navigation">
            {menuStructure.map(function(menuItem, i) {
              const {item, label, children} = menuItem;
              const childrenWithPermission = children.filter(function(c) {
                const permission = permissions[c];
                if (permission == null || permission.ok == null) {
                  throw new Error(`Missing permission: ${c}`);
                }
                return permission.ok(self.props.user);
              });
              if (childrenWithPermission.length > 0 && !self.state.organisation.isAccountLocked()) {
                const highlightedChildId = _.find(childrenWithPermission, c => matchesPath(menuItems[c].path, self.props.currentPath));
                return (
                  <div
                      key={i}
                      className="nav-item nav-item--top-level"
                      style={{display: 'inline-block'}}
                      onMouseOver={() => self.handleTopLevelItemMouseOver(menuItem)}
                      data-menu-item-id={item}>
                    <div className={classNames("nav-button", {highlighted: highlightedChildId != null})}>
                      {label}
                    </div>
                    <div
                        className="nav-dropdown"
                        style={item === self.state.expandedItem ? {display: 'inline-block'} : null}>
                      {childrenWithPermission.map(function(childId) {
                        const menuItem = menuItems[childId];
                        if(childId != 'projectPlanner' || self.canSeeProjectPlanning()) {
                          return (
                            <div
                                key={childId}
                                className={classNames("nav-dropdown-item", {highlighted: childId === highlightedChildId})}
                                data-menu-item-id={childId}
                                onClick={() => self.handleMenuItemClick(menuItem)}>
                              {menuItem.label}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              }
              else {
                return null;
              }
            })}
          </div>
          {this.state.isSubscribeFormOpen ?
            <Modal onClosed={this.handleSubscriptionFormClosed}>
              <ModalContent
                  width='60em'
                  header="Subscribe to Coincraft"
                  showCloseButton={true}
                  height="80%"
                  onClose={this.handleSubscriptionFormClosed}>
                <SubscriptionForm />
              </ModalContent>
            </Modal>
          : null}
          <div className="account-info">
            <div className="user-info flexbox-container flex-align-items-center">
              <div className="user-name" style={{marginRight: '1em'}}>
                {this.props.user.getFullName()}
              </div>
              {permissions['billing'].ok(self.props.user) && organisation.useStripe ?
                <div className="billing-btn" style={{marginRight: '1em'}}>
                  <a href="/billing">
                    Billing
                  </a>
                </div>
              : null}
              <div className="signout-btn">
                <a onClick={this.logout} href="javascript:void(0)">
                  Sign Out &nbsp;<i className="fa fa-sign-out"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  },

  handleTopLevelItemMouseOver: function(menuItem) {
    if (this.state.expandedItem !== menuItem.item) {
      this.setState({expandedItem: menuItem.item});
    }
  },

  handleMenuItemClick: function(link) {
    if (link.onClick != null) {
      link.onClick();
    }
    else if (link.path != null) {
      router.history.push(link.path);
    }
    this.setState({expandedItem: null});
  },

  handleBodyMouseOver: function() {
    if (this.state.expandedItem != null) {
      this.setState({expandedItem: null});
    }
  },

  logout: function() {
    authenticationActions.logout();
  },

  handleOpenSubscribeFormClick: function() {
    pricingActions.open();
  },

  handleSubscriptionFormClosed: function() {
    pricingActions.close();
  }
});


function matchesPath(itemPath, currentPath) {
  /**
   * This is kinda awkward because some of our paths are kinda awkward.
   *
   * Current path /dashboard should only match /dashboard
   * Current path /dashboard/staff should match /dashboard/staff but not /dashboard
   * Current path /dashboard/staff-allocation should not match /dashboard/staff
   * Current path /dashboard/staff/report/ac5831ab-b065-43ae-b4b4-9b8f0d0ee6d8 should match /dashboard/staff
   * Current path /dashboard/timesheet/current should not match /dashboard/timesheet
   */

  if (itemPath == null || itemPath === '') {
    return false;
  }
  else if (itemPath === '/dashboard' && currentPath === '/dashboard/') {
    return true;
  }
  else if (itemPath === '/dashboard' || currentPath === '/dashboard/timesheet/current') {
    return itemPath === currentPath;
  }
  else {
    return itemPath === currentPath || currentPath.startsWith(itemPath + '/');
  }
}
