import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { VerticalCheckboxList } from '../widgets/VerticalCheckboxList.js';
import { StoreBase } from '../coincraftFlux.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


class ColumnSelectorStore extends StoreBase {
  constructor(columns, selectedColumnIds) {
    super();
    this.columns = columns;
    this.selectedColumnIds = selectedColumnIds;
    this.selectedOrderingColumnId = null;
  }

  setSelectedColumns(selectedColumnIds, {emitChanged = true} = {}) {
    this.selectedColumnIds = selectedColumnIds;
    if (emitChanged) {
      this.emitChanged();
    }
  }

  setColumnSelected(columnId, isSelected) {
    this.setSelectedColumns(setColumnSelection(this.selectedColumnIds, columnId, isSelected), {emitChanged: false});
    if (isSelected) {
      this.selectedOrderingColumnId = columnId;
    }
    else if (this.selectedOrderingColumnId === columnId) {
      this.selectedOrderingColumnId = null;
    }
    this.emitChanged();
  }

  selectOrderingColumn(columnId) {
    this.selectedOrderingColumnId = columnId;
    this.emitChanged();
  }

  _moveColumnBy(columnId, addend) {
    const columnIndex = _.findIndex(this.selectedColumnIds, c => c === columnId);
    const newSelectedColumns = _.clone(this.selectedColumnIds);
    const temp = newSelectedColumns[columnIndex + addend];
    newSelectedColumns[columnIndex + addend] = newSelectedColumns[columnIndex];
    newSelectedColumns[columnIndex] = temp;
    this.setSelectedColumns(newSelectedColumns);
  }

  moveColumnUp() {
    if (this.selectedOrderingColumnId != null) {
      this._moveColumnBy(this.selectedOrderingColumnId, -1);
    }
  }

  moveColumnDown() {
    if (this.selectedOrderingColumnId != null) {
      this._moveColumnBy(this.selectedOrderingColumnId, +1);
    }
  }
}


export function setColumnSelection(selectedColumnIds, columnId, isSelected) {
  if (isSelected) {
    return [...selectedColumnIds, columnId];
  }
  else {
    return selectedColumnIds.filter(id => id !== columnId);
  }
}


function ownStoreWrapper(component, createStore, getStateFromStore) {
  return CreateReactClass({
    getInitialState: function() {
      this.store = createStore.apply(this);
      this.store.addChangeListener(this._update);
      return this._getStateFromStore();
    },

    componentWillUnmount: function() {
      this.store.removeChangeListener(this._update);
    },

    _update: function() {
      this.setState(this._getStateFromStore());
    },

    _getStateFromStore: function() {
      return getStateFromStore.apply(this, [this.store]);
    },

    render: function() {
      return React.createElement(component,
        {
          ...this.props,
          ...this.state
        }
      );
    }
  });
};


export const ColumnSelector = ownStoreWrapper(
  CreateReactClass({
    propTypes: {
      modal: PropTypes.object.isRequired,
      initialSelectedColumns: PropTypes.array,
      columns: PropTypes.array,
      selectedColumnIds: PropTypes.array.isRequired,
      columnOptions: PropTypes.array.isRequired,
      isColumnVisible: PropTypes.func.isRequired,
      myActions: PropTypes.shape({
        setColumnSelected: PropTypes.func.isRequired,
        moveColumnUp: PropTypes.func.isRequired,
        moveColumnDown: PropTypes.func.isRequired,
      }).isRequired,
      actions: PropTypes.shape({
        closeModal: PropTypes.func.isRequired,
        saveColumnSelection: PropTypes.func.isRequired
      })
    },

    getInitialState: function() {
      return {
        filterText: ''
      };
    },

    render: function() {
      let self = this;
      const selectedColumnIndex = this.props.selectedOrderingColumnId != null ?
        _.findIndex(this.props.selectedColumnIds, c => c === this.props.selectedOrderingColumnId)
      : null;

      return <Modal>
        <ModalContent
            header="Select columns"
            width='60em'
            bodyStyles={{padding: 0, color: '#444'}}
            headerStyles={{borderBottom: 'solid 1px #ccc', color: '#444'}}
            className="column-selector">
          <div style={{borderBottom: 'solid 1px #ccc'}}>
            <div style={{display: 'inline-block', width: '50%', verticalAlign: 'top', borderRight: 'solid 1px #ccc'}}>
              <div
                  style={{
                    height: '2.6em',
                    padding: '0.5em',
                    borderBottom: 'solid 1px #ddd',
                    textAlign: 'center'
                  }}>
                Select columns to display in table.
              </div>

              <VerticalCheckboxList
                options={this.props.columnOptions.filter(co => self.props.isColumnVisible(co.value))}
                selectedOptionValues={this.props.selectedColumnIds}
                onCheckboxChange={(columnId, isChecked) => self.props.myActions.setColumnSelected(columnId, isChecked)}
                filterPlaceholder="Find a column"
              />
            </div>

            <div style={{display: 'inline-block', width: '50%', verticalAlign: 'top'}}>
              <div
                  style={{
                    height: '2.6em',
                    padding: '0.5em',
                    borderBottom: 'solid 1px #ddd',
                    textAlign: 'center'
                  }}>
                Set order of displayed columns.
              </div>
              <div style={{height: '3.9em', borderBottom: 'solid 1px #ddd'}}>
                <button
                    className="btn btn-default"
                    style={{width: '42.5%', margin: '2% 2.5% 2% 5%'}}
                    disabled={selectedColumnIndex == null || selectedColumnIndex === 0}
                    onClick={function() {
                      self.props.myActions.moveColumnUp();
                    }}>
                  <i className="fa fa-chevron-up" />
                </button>
                <button
                    className="btn btn-default"
                    style={{width: '42.5%', margin: '2% 5% 2% 2.5%'}}
                    disabled={selectedColumnIndex == null || selectedColumnIndex >= self.props.selectedColumnIds.length - 1}
                    onClick={function() {
                      self.props.myActions.moveColumnDown();
                    }}>
                  <i className="fa fa-chevron-down" />
                </button>
              </div>
              <div style={{height: '40em', overflowY: 'auto', margin: '0 1em'}}>
                {self.props.selectedColumnIds.map(function(columnId, i) {
                  const column = _.find(self.props.columns, c => c.identifier === columnId);
                  return (
                    <div
                        key={columnId}
                        className={classNames("column-select-list-item", {selected: columnId === self.props.selectedOrderingColumnId})}
                        style={{borderBottom: '1px solid #eaeaea', margin: 0, paddingBottom: '0.7em'}}
                        onClick={() => self.props.myActions.selectOrderingColumn(columnId)}>
                      {column.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div style={{textAlign: 'right', padding: '1em'}}>
            <button
                className="btn btn-default"
                onClick={self.handleCancelButtonClick}>
              Cancel
            </button>
            <button
                className="apply-and-close btn btn-default"
                onClick={self.handleSaveButtonClick}
                style={{marginLeft: '1em'}}>
              Apply &amp; close
            </button>
          </div>
        </ModalContent>
      </Modal>;
    },

    handleCancelButtonClick: function() {
      this.props.actions.closeModal(this.props.modal);
    },

    handleSaveButtonClick: function() {
      this.props.actions.saveColumnSelection(this.props.selectedColumnIds, this.props.modal);
    }
  }),
  function createStore() {
    return new ColumnSelectorStore(this.props.columns, this.props.initialSelectedColumns);
  },
  function getStateFromStore(store) {
    return {
      selectedColumnIds: store.selectedColumnIds,
      selectedOrderingColumnId: store.selectedOrderingColumnId,
      columnOptions: this.props.columns.map(function(c) {
        return {
          label: c.headerText != null ? c.headerText : c.header,
          value: c.identifier,
          disabled: c.isMandatory
        };
      }),
      myActions: store
    };
  }
);
