import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin, dispatcher } from '../coincraftFlux.js';
import { rootStore } from '../RootStore.js';
import { ContactForm } from './ContactForm.js';
import PropTypes from 'prop-types';


export var ContactFormContainer = CreateReactClass({
  propTypes: {
    path: PropTypes.string.isRequired,
    context: PropTypes.oneOf(['project', 'invoice']).isRequired
  },

  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      const store = rootStore.getStore(this.props.path);
      return {
        stage: store.stage,
        buttons: store.buttons,
        contact: store.contact,
        accountingSystemContact: store.accountingSystemContact,
        saveState: store.saveState,
        saveError: store.saveError,
        importContacts: store.importContacts,
        retrieveContacts: store._retrieveContacts,
        retrievedContacts: store.retrievedContacts,
        selectedRetrievedContacts: store.selectedRetrievedContacts,
        canSave: store.canSave(),
        actions: {
          setStage: (type) => dispatcher.dispatch({
            type: "contact/setStage",
            path: this.props.path,
            contactType: type
          }),
          save: () => dispatcher.dispatch({
            type: "contact/save",
            path: this.props.path,
          }),
          cancel: () => dispatcher.dispatch({
            type: "contact/cancel",
            path: this.props.path,
          }),
          back: () => dispatcher.dispatch({
            type: "contact/back",
            path: this.props.path,
          }),
          setContactFirstName: (value) => dispatcher.dispatch({
            type: "contact/setContactFirstName",
            path: this.props.path,
            firstName: value
          }),
          setContactLastName: (value) => dispatcher.dispatch({
            type: "contact/setContactLastName",
            path: this.props.path,
            lastName: value
          }),
          setContactOrganisationName: (value) => dispatcher.dispatch({
            type: "contact/setContactOrganisationName",
            path: this.props.path,
            organisationName: value
          }),
          setContactNotes: (value) => dispatcher.dispatch({
            type: "contact/setContactNotes",
            path: this.props.path,
            notes: value
          }),
          retrieveContacts: () => dispatcher.dispatch({
            type: "contact/retrieveContacts",
            path: this.props.path,
          }),
          selectContactForImport: (contact, value) => dispatcher.dispatch({
            type: "contact/selectContactForImport",
            path: this.props.path,
            contact: contact,
            value: value
          }),
          importSelectedContacts: () => dispatcher.dispatch({
            type: "contact/importSelectedContacts",
            path: this.props.path,
          }),
          deleteContact: () => dispatcher.dispatch({
            type: "contact/deleteContact",
            path: this.props.path,
          }),
        }
      };
    })
  ],

  render: function() {
    return <ContactForm {...this.props} {...this.state} />;
  }
});

