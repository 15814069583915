import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { Modal, ModalContent } from '../modal.js';
import { DeleteButton, MySelect2, FilterTextBox, Dropdown3, Dropdown3ListItem, SaveBar } from '../widgets.js';
import { CoincraftPageHeader } from '../CoincraftPage.js';
import { caseInsensitiveContains } from '../utils.js';
import { FilterList } from '../widgets/filterwidget.js';
import { DateRangeSelector } from './DateRangeSelector.js';
import { ReportNameModal } from './ReportNameModal.js';
import { ColumnSelector } from './ColumnSelector.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { requiresPermission, permissions } from '../models/permissions.js';
import { SlideUpDown } from '../widgets/SlideUpDown.js';
import { Multiselect } from 'react-widgets';
import PropTypes from "prop-types";
import apiRequest from '../apiRequest.js';
const FileDownload = require("js-file-download");


export var ReportStoreSaveBar = requiresPermission(
  permissions.noRestrictions,
  {
    canSave: permissions.canSaveReports
  },
  CreateReactClass({
    render: function() {
      if (this.props.canSave && (this.props.reportStore.isDirty || this.props.reportStore.saveState != null)) {
        return <SaveBar
          unsavedChangesMessage="Your report has unsaved changes."
          isDirty={this.props.reportStore.isDirty}
          saveState={this.props.reportStore.saveState}
          onSaveClick={() => this.props.reportStore.actions.clickSaveReportButton()}
        />;
      }
      else {
        return null;
      }
    }
  })
);


export var ReportStoreWidget = requiresPermission(
  permissions.noRestrictions,
  {
    canSave: permissions.canSaveReports
  },
  CreateReactClass({
    propTypes: {
      includesDateRange: PropTypes.bool,
      groupingOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })),
      items: PropTypes.array.isRequired,
      reports: PropTypes.array.isRequired,
      reportStore: PropTypes.object.isRequired,
      columnPresentation: PropTypes.object,
      getCsvExportUrl: PropTypes.func,
      hasFilter: PropTypes.bool,
      filterInputPlaceholder: PropTypes.string,

      // Is the Refresh option in the dropdown menu enabled?
      canRefresh: PropTypes.bool,

      // Display a 'refresh table' button when the filters are dirty
      shouldShowRefreshTableButton: PropTypes.bool,

      heading: PropTypes.string.isRequired,
      buttons: PropTypes.array
    },

    getDefaultProps: function() {
      return {
        includesDateRange: true,
        hasFilter: true,
        canRefresh: false,
        shouldShowRefreshTableButton: false,
        buttons: []
      };
    },

    render: function() {
      let self = this;

      return <ReportWidget
        includesDateRange={this.props.includesDateRange}
        groupingOptions={this.props.groupingOptions}
        items={this.props.items}
        reports={this.props.reports}
        columnPresentation={this.props.columnPresentation}
        hasFilter={this.props.hasFilter}
        filterInputPlaceholder={this.props.filterInputPlaceholder}
        canRefresh={this.props.canRefresh}
        shouldShowRefreshTableButton={this.props.shouldShowRefreshTableButton}
        canSave={this.props.canSave}

        modals={this.props.reportStore.modals}
        report={this.props.reportStore.report}
        isExpanded={this.props.reportStore.isExpanded}
        isMenuExpanded={this.props.reportStore.isMenuExpanded}
        isActionsMenuExpanded={this.props.reportStore.isActionsMenuExpanded}
        isSaveMenuExpanded={this.props.reportStore.isSaveMenuExpanded}
        isDirty={this.props.reportStore.isDirty}
        saveState={this.props.reportStore.save.state}
        dateRange={this.props.reportStore.report.dateRange}
        columns={this.props.reportStore.columns}
        isColumnVisible={this.props.reportStore.isColumnVisible}
        filters={this.props.reportStore.report.filters}
        filterText={this.props.reportStore.filterText}
        selectedColumns={this.props.reportStore.report.columns}
        actions={this.props.reportStore.actions}
        getCsvExportUrl={this.props.getCsvExportUrl}
        getCsvString={function(items, columnPresentation, exportChildData = false) {
          return self.props.reportStore.getCsvString(
            self.props.reportStore.getMatchingItems(items),
            columnPresentation,
            exportChildData
          );
        }}
        heading={this.props.heading}
        subHeading={this.props.subHeading}
        buttons={this.props.buttons}
        extraRightButtons={this.props.extraRightButtons}
      />;
    }
  })
);



var ReportWidget = CreateReactClass({
  propTypes: {
    includesDateRange: PropTypes.bool,
    hasFilter: PropTypes.bool,
    filterInputPlaceholder: PropTypes.string,
    groupingOptions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    canRefresh: PropTypes.bool,
    shouldShowRefreshTableButton: PropTypes.bool,

    modals: PropTypes.array.isRequired,
    report: PropTypes.object.isRequired,
    columnPresentation: PropTypes.object,
    reports: PropTypes.array.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isMenuExpanded: PropTypes.bool.isRequired,
    isActionsMenuExpanded: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    dateRange: PropTypes.object.isRequired,
    saveState: PropTypes.string,
    columns: PropTypes.array.isRequired,
    isColumnVisible: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired, // Immutable.List
    filterText: PropTypes.string.isRequired,
    selectedColumns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,

    // If `getCsvExportUrl` is provided, it's called with no arguments to get a
    // full path to a URL that should return a CSV for the provided report.
    // Otherwise we use `getCsvString` which generates the content from the
    // client data.
    getCsvExportUrl: PropTypes.func,
    getCsvString: PropTypes.func,

    canSave: PropTypes.bool.isRequired,

    actions: PropTypes.shape({
      selectDateRange: PropTypes.func.isRequired,
      createDuplicateReportSubmit: PropTypes.func.isRequired,
      saveReport: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
      deleteCurrentReportSubmit: PropTypes.func.isRequired,
      toggleExpanded: PropTypes.func.isRequired,
      clickSaveReportButton: PropTypes.func.isRequired,
      refresh: PropTypes.func.isRequired,
      refreshTable: PropTypes.func
    }).isRequired,

    heading: PropTypes.string.isRequired,
    buttons: PropTypes.array,
    extraRightButtons: PropTypes.array
  },

  getDefaultProps: function() {
    return {
      includesDateRange: true,
      hasFilter: true,
      canRefresh: false,
      buttons: []
    };
  },

  render: function() {
    return <div className="report-widget">
      {this.renderModals()}
      {this.renderHeader()}
      {this.renderFilters()}
    </div>;
  },

  renderModals: function() {
    let self = this;

    function modalContent(modal) {
      switch (modal.type) {
        case 'columnSelection':
          return self.renderColumnSelectionModal(modal);
        case 'saveReport':
          return self.renderSaveReportModal(modal);
        case 'duplicateReport':
          return self.renderDuplicateReportModal(modal);
        case 'renameReport':
          return self.renderRenameReportModal(modal);
        case 'deleteReport':
          return self.renderDeleteReportModal(modal);
        case 'export':
          return self.renderExportModal(modal);
      }
    }

    return this.props.modals.map(function(modal, i) {
      return <div key={i}>
        {modalContent(modal)}
      </div>;
    });
  },

  renderHeader: function() {
    return <CoincraftPageHeader
      heading={this.props.heading}
      subHeading={this.props.subHeading}
      leftButtons={this.props.buttons}
      filter={this.props.hasFilter ?
        <FilterTextBox
          style={{width: '19em'}}
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
          placeholder={this.props.filterInputPlaceholder}
        />
      : null}
      rightButtons={<div style={{display: 'inline-block'}}>
        {this.props.extraRightButtons}
        <button className="export-button page-header__button" onClick={this.handleExportButtonClick}>
          <i className="fa fa-table" />
          Export
        </button>
        <button className="print-button page-header__button" onClick={() => window.print()}>
          <i className="fa fa-print" />
          Print
        </button>
      </div>}
      extraContent={<div className="report-selector">
        {'Report: '}
        <MySelect2
          className="flex-0-0-auto"
          style={{ marginLeft: '1em', maxWidth: "15em"}}
          value={this.props.report}
          options={this.props.reports}
          filter={function(report, searchText) {
            return caseInsensitiveContains(report.name, searchText);
          }}
          getObjectLabel={function(report) {
            return <div>{report.name}</div>;
          }}
          onChange={(report) => this.props.actions.selectReport(report)}
        />
        {this.renderReportButtons()}
      </div>}
    />;
  },

  renderReportButtons: function() {
    return <div>
      <button
          className="customise-report-button"
          onClick={() => this.props.actions.toggleExpanded()}>
        {this.props.isExpanded ? 'Hide ' : 'Edit '}
        <i
          className={classNames("fa", this.props.isExpanded ? 'fa-angle-double-up' : 'fa-angle-double-down')}
          style={{margin: 0}}
        />
      </button>
      {this.props.isExpanded && this.props.canSave ?
        <span>
          <ReportSaveDropdown
            report={this.props.report}
            isExpanded={this.props.isSaveMenuExpanded}
            actions={this.props.actions}
            saveState={this.props.saveState}
          />
          {this.props.report.id != null ?
            <ReportActionsDropdown
              report={this.props.report}
              isExpanded={this.props.isActionsMenuExpanded}
              actions={this.props.actions}
            />
          : null}
        </span>
      : null}
    </div>;
  },

  renderDuplicateReportModal: function(modal) {
    return <ReportNameModal
      modal={modal}
      header="Create copy"
      initialName={'Copy of ' + this.props.report.name}
      saveButtonText="Duplicate report"
      instructions="Please enter a name for your new report."
      onSave={(reportName) => this.props.actions.createDuplicateReportSubmit(reportName, modal)}
      actions={this.props.actions}
    />;
  },

  renderSaveReportModal: function(modal) {
    return <ReportNameModal
      modal={modal}
      header="Save report"
      initialName={this.props.report.name}
      instructions="You can save the settings for this report so you can run it again later."
      onSave={(reportName) => this.props.actions.saveReport(reportName, modal)}
      actions={this.props.actions}
    />;
  },

  renderRenameReportModal: function(modal) {
    return <ReportNameModal
      modal={modal}
      header="Rename report"
      initialName={this.props.report.name}
      saveButtonText="Rename report"
      instructions="Enter a new name for this report."
      onSave={(reportName) => this.props.actions.saveReport(reportName, modal)}
      actions={this.props.actions}
    />;
  },

  renderDeleteReportModal: function(modal) {
    return <Modal
        closeOnClickOutside={true}
        onClosed={() => this.props.actions.closeModal(modal)}>
      <ModalContent header="Delete report" width='35em'>
        <p style={{padding: '2em'}}>
          Are you sure you want to delete this report?
        </p>
        <div  style={{padding: '1em', textAlign: 'right', borderTop: '1px solid #ccc'}}>
          <a
              href="javascript:void(0)"
              onClick={() => this.props.actions.closeModal(modal)}>
            Cancel
          </a>
          <DeleteButton
            text="Delete report"
            onClick={() => this.props.actions.deleteCurrentReportSubmit(modal)}
            style={{marginLeft: '2em'}}
          />
        </div>
      </ModalContent>
    </Modal>;
  },

  renderColumnSelectionModal: function(modal) {
    return <ColumnSelector
      modal={modal}
      columns={this.props.columns.filter(c => c.canShow)}
      initialSelectedColumns={this.props.selectedColumns}
      isColumnVisible={this.props.isColumnVisible}
      actions={this.props.actions}
    />;
  },

  renderFilters: function(modal) {
    return (
      <SlideUpDown isVisible={this.props.isExpanded}>
        <div className="dont-print">
          <div style={{backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '1em', margin: '1em 0'}}>
            <div>
              <div style={{borderBottom: 'solid 1px #ccc', padding: '1em'}}>
                <div>
                  <div>
                    {this.props.includesDateRange ?
                      <div className="inline-flexbox-container flex-align-items-center report-widget__use-data-from">
                        <label className="flex-0-0-auto middle-item-m-1">
                          Use data from:
                        </label>
                        <DateRangeSelector
                          className="flex-0-0-auto middle-item-m-1"
                          style={{marginLeft: '0.5em', marginRight: '0.5em'}}
                          value={this.props.dateRange}
                          onChange={(dateRange) => this.props.actions.selectDateRange(dateRange)}
                        />
                      </div>
                    : null}
                    <button
                        className="select-columns-button btn btn-default flex-0-0-auto"
                        onClick={() => this.props.actions.clickSelectColumns()}>
                      Select columns to display...
                    </button>
                  </div>
                </div>
                {this.props.groupingOptions != null ?
                  <div className="flexbox-container flex-align-items-center" style={{marginTop: '1em'}}>
                    <label className="flex-0-0-auto middle-item-m-1">
                      Group by:
                    </label>
                    <Multiselect
                      value={this.props.report.groupBy}
                      data={this.props.groupingOptions}
                      valueField='id'
                      textField='label'
                      style={{ width: "50em" }}
                      onChange={(groups) => {if(groups.length > 0) this.props.actions.selectGroupBy(groups.map(g => g.id))}}
                      filter={function (item, searchText) {
                        return item.label.toLowerCase().match(searchText.toLowerCase());
                      }}
                    />
                  </div>
                : null}
              </div>

              <FilterList
                columns={this.props.columns}
                filters={this.props.filters}
                selectedColumns={this.props.selectedColumns}
                actions={this.props.actions}
                shouldShowRefreshTableButton={this.props.shouldShowRefreshTableButton && this.props.isDirty}
              />
            </div>
          </div>
        </div>
      </SlideUpDown>
    );
  },

  renderExportModal: function(modal) {
    let downloadLink;
    let childDownloadLink;

    if (this.props.getCsvExportUrl) {
      downloadLink = (
			<a
				className="btn btn-primary export-download-button"
				style={{ textDecoration: "none" }}
				onClick={() =>
					this.props.getCsvExportUrl().then(data =>
						FileDownload(data, "CoincraftTimesheetReport.csv")
					)
				}
				href={"#"}
			>
				Download
			</a>
		);
    }
    else {
      const csv = this.props.getCsvString(this.props.items, this.props.columnPresentation);
      const childCSV = this.props.heading === "Projects" ? this.props.getCsvString(this.props.items, this.props.columnPresentation, true) : null;
      if (navigator.msSaveBlob) {
        // IE10+
        downloadLink = (
          <a
              className="btn btn-primary export-download-button"
              style={{textDecoration: 'none'}}
              href="javascript:void(0)"
              onClick={function() {
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                let filename = "CoincraftReport.csv";
                navigator.msSaveBlob(blob, filename);
              }}>
            {this.props.heading === "Projects" ? "Download Project Report" : "Download"}
          </a>
        );
      }
      else {
        // Proper browsers.
        downloadLink = (
          <a
              className="btn btn-primary export-download-button"
              style={{textDecoration: 'none', marginRight: '1em'}}
              href={`data:attachment/csv,${encodeURIComponent(csv)}`}
              target="_blank"
              download="CoincraftReport.csv">
            {this.props.heading === "Projects" ? "Download Project Report" : "Download"}
          </a>
        );
      }
      if (childCSV) {
        if (navigator.msSaveBlob) {
          // IE10+
          childDownloadLink = (
            <a
                className="btn btn-primary export-download-button"
                style={{textDecoration: 'none'}}
                href="javascript:void(0)"
                onClick={function() {
                  let blob = new Blob([childCSV], { type: 'text/csv;charset=utf-8;' });
                  let filename = "CoincraftReport.csv";
                  navigator.msSaveBlob(blob, filename);
                }}>
              {this.props.heading === "Projects" ? "Download Phase Report" : "Download"}
            </a>
          );
        }
        else {
          // Proper browsers.
          childDownloadLink = (
            <a
                className="btn btn-primary export-download-button"
                style={{textDecoration: 'none'}}
                href={`data:attachment/csv,${encodeURIComponent(childCSV)}`}
                target="_blank"
                download="CoincraftReport.csv">
              {this.props.heading === "Projects" ? "Download Phase Report" : "Download"}
            </a>
          );
        }
      }
    }

    return <Modal
        closeOnClickOutside={true}
        onClosed={() => this.props.actions.closeModal(modal)}>
      <ModalContent header="Export" width="50em">
        <div style={{padding: '2em'}}>
          <p>
            You can export this table to a file that can be opened in a
            spreadsheet program like Microsoft Excel or imported into other
            services.
          </p>
          <div>
            {downloadLink}
            {this.props.heading === "Projects" ? childDownloadLink : null}
          </div>
          <div style={{marginTop: '2em'}}>
            <a
                href="javascript:void(0)"
                className="export__close-button"
                onClick={() => this.props.actions.closeModal(modal)}>
              Close
            </a>
          </div>
        </div>
      </ModalContent>
    </Modal>;
  },

  handleFilterTextChange: function(text) {
    this.props.actions.setFilterText(text);
  },

  handleExportButtonClick: function() {
    this.props.actions.openExportModal();
  }
});



var ReportActionsDropdown = CreateReactClass({
  propTypes: {
    report: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  mixins: [
    PureRenderMixin
  ],

  render: function() {
    return <Dropdown3
        isExpanded={this.props.isExpanded}
        onToggle={this.handleToggle}
        toggleElement={
          <button
              className="primary"
              style={{margin: '0.25em'}}>
            Actions
            <i className="rw-i rw-i-caret-down" style={{marginLeft: '0.5em'}} />
          </button>
        }
        contentStyle={{width: '18em', color: '#444', borderRadius: '0.5em'}}>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => this.props.actions.renameCurrentReport()}>
        Rename
      </Dropdown3ListItem>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => this.props.actions.deleteCurrentReport()}
          disabled={this.props.report.isDefault}>
        Delete
      </Dropdown3ListItem>
    </Dropdown3>;
  },

  handleToggle: function(isExpanded) {
    this.props.actions.setActionsMenuExpanded(isExpanded);
  }
});

var ReportSaveDropdown = CreateReactClass({
  propTypes: {
    report: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  mixins: [
    PureRenderMixin
  ],

  render: function() {
    return <Dropdown3
        className="report-save-dropdown"
        isExpanded={this.props.isExpanded}
        onToggle={this.handleToggle}
        toggleElement={
          <button
              className="primary"
              style={{margin: '0.25em'}}>
            Save
            <i className="rw-i rw-i-caret-down" style={{marginLeft: '0.5em'}} />
          </button>
        }
        contentStyle={{width: '14em', color: '#444', borderRadius: '0.5em'}}>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          className="report-save-dropdown__save-option"
          onClick={() => this.props.actions.clickSaveReportButton()}>
        Save
      </Dropdown3ListItem>
      <Dropdown3ListItem
          style={{padding: '0.5em 1em', height: '2.5em'}}
          onClick={() => this.props.actions.duplicateCurrentReport()}>
        Save As...
      </Dropdown3ListItem>
    </Dropdown3>;
  },

  handleToggle: function(isExpanded) {
    this.props.actions.setSaveMenuExpanded(isExpanded);
  }
});
