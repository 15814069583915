import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { userStore } from '../user.js';
import { billingPageStore, actions } from './BillingPageStore';
import { organisationStore } from '../organisation.js';
import { rootStore } from '../RootStore.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { Link } from 'react-router';
import { Table, Column } from '../table.js';
import { formatCurrencyWithCents, formatPercentage0, formatNumber2, sum } from "../utils.js";
import { Modal2 } from '../modal.js';
import { PermissionsEditor } from '../staff/PermissionsEditor.js';
import { BasicMySelect2, Checkbox } from '../widgets/generic.js';
import moment from 'moment';


export var BillingPage = CreateReactClass({

  mixins: [
    makeMultipleStoreMixin([userStore, rootStore], function () {
      return {
        isAdmin: userStore.isAdmin(),
        staffWithFees: billingPageStore.staffWithFees,
        staffWithoutFees: billingPageStore.staffWithoutFees,
        organisation: billingPageStore.organisation,
        initialPeriod: billingPageStore.initialPeriod,
        modal: billingPageStore.modal,
        staffMember: billingPageStore.staffMember,
        receiptEmail: billingPageStore.receiptEmail,
      };
    })
  ],

  componentWillMount: function () {
    setTimeout(function () {
      actions.loadPage();
    }, 0);
  },

  componentWillReceiveProps: function () {
    setTimeout(function () {
      actions.loadPage();
    }, 0);
  },

  render: function() {
    if (!this.state.staffWithFees) {
      return null;
    }
    
    const { staffWithFees, staffWithoutFees, organisation } = this.state
    const staffBilling = sum(this.state.staffWithFees.map(s => s.billingAmount))
    const adjustments = sum(organisation.unpaidInvoices.filter(inv => inv.stripe_invoice_id == "proration" && inv.amount < 50).map(i => i.amount))
    const billingAmount = staffBilling + adjustments
    const discount = billingAmount * (organisation.coupon.percent_discount/100)
    const tax = (billingAmount - discount) * (organisation.taxPercent / 100)
    const credit = organisation.credit / 100
    const total = billingAmount - discount + tax - credit

    const yearlyPeriod = organisation.subscriptionPeriod === "yearly"
    const yearlyInitialPeriod = this.state.initialPeriod === "yearly"
    const hasSubscription = organisation.hasSubscription

    const unpaidInvoices = organisation.unpaidInvoices.filter(inv => inv.stripe_invoice_id != "proration" || inv.amount >= 50)

    return <CoincraftPage
      header={
        <div>
          <div className="flexbox-container flex-align-items-center">
            <div className="heading" style={{ lineHeight: '1em' }}>
              Billing
            </div>
            <button 
              style={{ margin: '0 1em' }} 
              disabled={yearlyPeriod} 
              className="btn btn-default" 
              onClick={() => actions.setSubscriptionPeriod("yearly")}
            >
              {`Yearly (${organisation.yearlyCoupon.percent_discount}% discount)${hasSubscription ? yearlyInitialPeriod ? ` (current)` : ` (preview)` : ``}`}
            </button>
            <button 
              style={{ marginRight: '1em' }} 
              disabled={!yearlyPeriod} 
              className="btn btn-default" 
              onClick={() => actions.setSubscriptionPeriod("monthly")}
            >
              {`Monthly${hasSubscription ? !yearlyInitialPeriod ? ` (current)` : ` (preview)` : ``}`}
            </button>
            {!hasSubscription ? 
              <button
                className="btn btn-primary"
                onClick={() => actions.subscribe()}
              >
                Subscribe!
            </button>
            : null}
            {false ? //hasSubscription ?
              <button
                className="btn btn-primary"
                onClick={() => actions.openSubscriptionModal()}
              >
                Edit...
            </button>
              : null}
          </div>
          <div style={{marginTop: '1em', fontSize: '1.5em', fontWeight: 300}}>
            Next Payment: ${formatCurrencyWithCents(total)}{`${(organisation.nextPaymentDate ? ` - ${organisation.nextPaymentDate.format('DD MMM YYYY')}` : '')}`}
          </div>
        </div>
      }
      body={
        <div>
          {this.state.modal == "email" ? 
            <Modal2
              header={"Edit Receipt Email"}
              closeOnClickOutside={true}
              width={"45em"}
              onCancel={() => actions.closeModal()}>
              {<div>
                <div style={{ padding: '2em', textAlign: 'center'}}>
                  <input 
                    type="text" 
                    value={this.state.receiptEmail} 
                    onChange={(e) => actions.updateEmail(e.target.value)} 
                    style={{width: '80%', textAlign: 'center'}} 
                  />
                </div>
                <div style={{ padding: '1em', textAlign: 'right', borderTop: 'solid 1px #ccc' }}>
                  <button onClick={() => actions.closeModal()} style={{ marginRight: "1em" }} className="btn btn-default">Cancel</button>
                  <button onClick={() => actions.saveEmail()} className="btn btn-success">Save</button>
                </div>
              </div>}
            </Modal2>
          : this.state.modal == "staff" ?
            <Modal2
              header={`${this.state.staffMember.getFullName()}'s Permissions`}
              closeOnClickOutside={true}
              width={"45em"}
              onCancel={() => actions.closeModal()}>
                {<div>
                  <div className="flexbox-container flex-align-items-center" style={{borderBottom: 'solid 1px #ccc', padding: '1em'}}>
                    <div className="flexbox-container flex-align-items-center" style={{marginRight: '3em'}}>
                      <span style={{marginRight: '1em'}}>Status:</span>
                      <BasicMySelect2
                        options={[
                          { label: "Active", value: false },
                          { label: "Archived", value: true }
                        ]}
                        value={this.state.staffMember.isArchived}
                        onChange={(isArchived) => actions.setStaffFieldValue('isArchived', isArchived)}
                      />
                    </div>
                    <div>
                      <Checkbox className="staff-member-component__has-login-input"
                        value={this.state.staffMember.hasLogin && !this.state.staffMember.isArchived}
                        disabled={this.state.staffMember.isArchived}
                        style={{ opacity: this.state.staffMember.isArchived ? 0.5 : 1}}
                        label="Provide Staff Member with Login?"
                        onChange={(hasLogin) => actions.setStaffFieldValue('hasLogin', hasLogin)} />
                    </div>
                  </div>
                  <PermissionsEditor
                    permissions={this.state.staffMember.permissions}
                    isEditable={true}
                    actions={{
                      addPermission: actions.addPermission.bind(actions),
                      setOverallLevel: actions.setOverallLevel.bind(actions),
                      setFinancialsVisibility: actions.setFinancialsVisibility.bind(actions),
                      setPermissionItem: actions.setPermissionItem.bind(actions),
                      setPermissionLevel: actions.setPermissionLevel.bind(actions),
                      deletePermission: actions.deletePermission.bind(actions)
                    }}
                  />
                  <div className="flexbox-container flex-align-items-center" style={{ borderTop: 'solid 1px #ccc', padding: '1em' }}>
                    <div style={{width: '50%'}}>
                      {this.state.staffMember.billingStatus} - ${formatCurrencyWithCents(this.state.staffMember.billingAmount)}
                    </div>
                    <div style={{width: '50%', textAlign: 'right'}}>
                      <button onClick={() => actions.closeModal()} style={{ marginRight: "1em" }} className="btn btn-default">Cancel</button>
                      <button onClick={() => actions.staffSave()} className="btn btn-success">Save</button>
                    </div>
                  </div>
                </div>}
            </Modal2>
          : this.state.modal == "subscription" ?
            <Modal2
              header={"Edit Subscription"}
              closeOnClickOutside={true}
              width={"45em"}
              onCancel={() => actions.closeModal()}>
                {<div>Edit Subscription</div>}
            </Modal2>
          : null}
          <div style={{ maxWidth: '60em' }}>
            {unpaidInvoices.length > 0 ?
              <div className="coincraft-table">
                <div className="flexbox-container coincraft-table-row header" style={{ backgroundColor: '#f25a2a' }}>
                  <div className="coincraft-table-cell" style={{ width: '100%', color: '#444' }}>Unpaid Invoices</div>
                </div>
                {unpaidInvoices.map(invoice => {
                  let proration = invoice.stripe_invoice_id == "proration";
                  let amount = invoice.amount * (proration ? (1 + organisation.taxPercent / 100) : 1)
                  return <div className="flexbox-container coincraft-table-row">
                    <div className="coincraft-table-cell" style={{ width: '25%' }}>{invoice.name}</div>
                    <div className="coincraft-table-cell" style={{ width: '30%' }}>{moment(invoice.date).format('DD MMM YYYY')}</div>
                    <div className="coincraft-table-cell" style={{ width: '30%', textAlign: 'right' }}>${formatCurrencyWithCents(amount)}</div>
                    <div className="coincraft-table-cell text-link" onClick={() => actions.payInvoice(invoice.stripe_invoice_id, invoice.stripe_subscription_id)} style={{ width: '15%', textAlign: 'right', paddingRight: '1em' }}>Pay Invoice</div>
                  </div>
                })}
              </div>
              : null}
            {organisation.cardLast4 ?
              <div className="coincraft-table" style={{ marginTop: unpaidInvoices.length > 0 ? '4em' : '0' }}>
                <div className="flexbox-container coincraft-table-row header">
                  <div className="coincraft-table-cell" style={{ width: '100%' }}>Payment Details</div>
                </div>
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Organisation</div>
                  <div className="coincraft-table-cell" style={{ width: '80%' }}>{organisation.name}</div>
                </div>
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Card</div>
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>{organisation.cardBrand ? organisation.cardBrand : 'N/A'}</div>
                  <div className="coincraft-table-cell" style={{ width: '25%' }}>...{organisation.cardLast4 ? organisation.cardLast4 : 'N/A'}</div>
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>{organisation.cardExpDate ? organisation.cardExpDate.format('MM-YYYY') : 'N/A'}</div>
                  <div className="coincraft-table-cell text-link" onClick={() => actions.updateCard()} style={{ width: '15%', textAlign: 'right', paddingRight: '1em' }}>edit...</div>
                </div>
                {<div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Receipt Email</div>
                  <div className="coincraft-table-cell" style={{ width: '65%' }}>{organisation.receiptEmail}</div>
                  <div className="coincraft-table-cell text-link" onClick={() => actions.openEmailModal()} style={{ width: '15%', textAlign: 'right', paddingRight: '1em' }}>edit...</div>
                </div>}
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Credit</div>
                  <div className="coincraft-table-cell" style={{ width: '80%' }}>${formatCurrencyWithCents(organisation.credit / 100)}</div>
                </div>
              </div>
            : null}

            <div className="coincraft-table" style={{ marginTop: unpaidInvoices.length > 0 || organisation.cardLast4 ? '4em' : '0' }}>
              <div className="flexbox-container coincraft-table-row header">
                <div className="coincraft-table-cell" style={{ width: '100%' }}>Next Payment</div>
              </div>
              {organisation.nextPaymentDate ?
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Date</div>
                  <div className="coincraft-table-cell" style={{ width: '80%', paddingRight: '1em' }}>{organisation.nextPaymentDate ? organisation.nextPaymentDate.format('DD MMM YYYY') : 'N/A'}</div>
                </div>
                : null}
              <div className="flexbox-container coincraft-table-row">
                <div className="coincraft-table-cell" style={{ width: '20%' }}>Amount Due</div>
                <div className="coincraft-table-cell" style={{ width: '80%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(staffBilling)}</div>
              </div>
              {adjustments ?
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Staff Adjustments</div>
                  <div className="coincraft-table-cell" style={{ width: '80%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(adjustments)}</div>
                </div>
                : null}
              {organisation.coupon.percent_discount ?
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Discount</div>
                  <div className="coincraft-table-cell" style={{ width: '40%', paddingRight: '1em' }}>{organisation.coupon.percent_discount}%</div>
                  <div className="coincraft-table-cell" style={{ width: '40%', textAlign: 'right', paddingRight: '1em' }}>-${formatCurrencyWithCents(discount)}</div>
                </div>
                : null}
              <div className="flexbox-container coincraft-table-row">
                <div className="coincraft-table-cell" style={{ width: '20%' }}>Tax</div>
                <div className="coincraft-table-cell" style={{ width: '40%', paddingRight: '1em' }}>{organisation.taxPercent}%</div>
                <div className="coincraft-table-cell" style={{ width: '40%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(tax)}</div>
              </div>
              {organisation.credit ?
                <div className="flexbox-container coincraft-table-row">
                  <div className="coincraft-table-cell" style={{ width: '20%' }}>Credit</div>
                  <div className="coincraft-table-cell" style={{ width: '80%', textAlign: 'right', paddingRight: '1em' }}>-${formatCurrencyWithCents(credit)}</div>
                </div>
                : null}
              <div className="flexbox-container coincraft-table-row" style={{ fontWeight: 'bold' }}>
                <div className="coincraft-table-cell" style={{ width: '20%' }}>Total</div>
                <div className="coincraft-table-cell" style={{ width: '80%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(total)}</div>
              </div>
            </div>
            <div className="coincraft-table" style={{ marginTop: '4em' }}>
              <div className="flexbox-container coincraft-table-row header">
                <div className="coincraft-table-cell" style={{ width: '100%' }}>Staff Breakdown</div>
              </div>
              {staffWithFees.map(function (s) {
                return <div className="flexbox-container coincraft-table-row" key={s.id}>
                  <div className="coincraft-table-cell" style={{ width: '30%' }}>{s.getFullName()}</div>
                  <div className="coincraft-table-cell" style={{ width: '55%', textAlign: 'right' }}>
                    <span style={{ marginRight: '1em' }}>{s.billingStatus}</span>
                    <span className="text-link" onClick={() => actions.openStaffPermissionsModal(s)} style={{ marginRight: '1em' }}>edit...</span>
                  </div>
                  <div className="coincraft-table-cell" style={{ width: '15%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(s.billingAmount)}</div>
                </div>
              })}
              {staffWithoutFees.map(function (s) {
                return <div className="flexbox-container coincraft-table-row" key={s.id} style={{ opacity: 0.75 }}>
                  <div className="coincraft-table-cell" style={{ width: '30%' }}>{s.getFullName()}</div>
                  <div className="coincraft-table-cell" style={{ width: '55%', textAlign: 'right' }}>
                    <span style={{ marginRight: '1em' }}>{s.billingStatus}</span>
                    <span className="text-link" onClick={() => actions.openStaffPermissionsModal(s)} style={{ marginRight: '1em' }}>edit...</span>
                  </div>
                  <div className="coincraft-table-cell" style={{ width: '15%', textAlign: 'right', paddingRight: '1em' }}>${formatCurrencyWithCents(s.billingAmount)}</div>
                </div>
              })}
            </div>
          </div>
        </div>
      }
    />
  }
});


