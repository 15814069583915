import { NoAccountingSystem } from './accounting/none.js';
import { XeroAccountingSystem } from './accounting/xero.js';
import { QuickBooksAccountingSystem } from './accounting/quickbooks.js';
import { MyobEssentialsAccountingSystem } from './accounting/myobEssentials.js';
import { MyobAccountRightAccountingSystem } from './accounting/myobAccountRight.js';

export const accountingSystemTypeLookup = {
  none: NoAccountingSystem,
  xero: XeroAccountingSystem,
  'myob-essentials': MyobEssentialsAccountingSystem,
  'myob-accountright': MyobAccountRightAccountingSystem,
  quickbooks: QuickBooksAccountingSystem
};

export const accountingSystems = [
  NoAccountingSystem,
  XeroAccountingSystem,
  QuickBooksAccountingSystem,
  MyobEssentialsAccountingSystem,
];


