import _ from 'underscore';
import { sum, generateUUID, compareMultiple } from "../../utils.js";
import { TimesheetItem } from '../../models/timesheetitem.js';
import { organisationStore } from '../../organisation.js';
import { dateConverter } from '../../models/dateconverter.js';

const displayTypes = {
    0: "sub-total",
    1: "sub-total",
    2: "",
    3: "child",
    4: "childchild",
    5: "childchildchild"
}

const expandable = {
    0: false,
    1: false,
    2: true,
    3: true,
    4: true,
    5: true
}

const hideable = {
    0: false,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true
}

const defaultExpanded = {
    0: false,
    1: true,
    2: false,
    3: false,
    4: false,
    5: false
}

export const TimesheetRow = class {
    constructor(spreadsheetStore, rowData) {
        this.uuid = rowData.uuid;
        this.timesheetItems = [];
        this.parentId = rowData.parentId;
        this.childrenIds = new Set(rowData.childrenIds);
        this.visible = true; //rowData.visible;
        this.level = rowData.level;
        this.title = rowData.title || "";
        this.spreadsheetStore = spreadsheetStore;
        this.groupType = rowData.groupType;
        this.rowType = rowData.rowType;
        this.editable = rowData.editable === false ? false : true; 
        this.project = organisationStore.getProjectById(rowData.projectId);
        this.staffMember = organisationStore.getStaffMemberById(rowData.staffId);
        this.staffRole = organisationStore.getStaffRoleById(rowData.roleId);
        this.phase = organisationStore.getProjectPhaseById(rowData.phaseId);

        // cached vals
        this._projectedValCache = rowData.projectedHours || {};
        this._actualValCache = rowData.actualHours || {};
        this._combinedValCache = rowData.combinedHours || {};
        this._projectedHoursToDateCache = rowData.projectedHoursToDate || {};
        this._actualHoursToDateCache = rowData.actualHoursToDate || {};
        this._combinedHoursToDateCache = rowData.combinedHoursToDate || {};
        this._actualMinMonthIndex = rowData.actualMinMonthIndex;
        this._actualMaxMonthIndex = rowData.actualMaxMonthIndex;
        this._projectedMinMonthIndex = rowData.projectedMinMonthIndex;
        this._projectedMaxMonthIndex = rowData.projectedMaxMonthIndex;
        this._combinedMinMonthIndex = rowData.combinedMinMonthIndex;
        this._combinedMaxMonthIndex = rowData.combinedMaxMonthIndex;
        this._budget = undefined;
        this._availableHoursInMonth = {};
        this._startMonthIndex = undefined;
        this._endMonthIndex = undefined;
    }

    get addStaffRowButton() {
        if (!(this.rowType === "role" || this.rowType === "staff")) return false
        const thisIndex = this.spreadsheetStore.groups.indexOf(this.rowType)
        const childIndex = Math.min(this.spreadsheetStore.groups.indexOf("project"), this.spreadsheetStore.groups.indexOf("phase"))
        return thisIndex < childIndex
    }

    get addProjectRowButton() {
        if (!(this.rowType === "project" || this.rowType === "phase")) return false
        const thisIndex = this.spreadsheetStore.groups.indexOf(this.rowType)
        const childIndex = Math.min(this.spreadsheetStore.groups.indexOf("role"), this.spreadsheetStore.groups.indexOf("staff"))
        return thisIndex < childIndex
    }

    get rowDisplayType() {
        return displayTypes[this.level]
    }

    get expandable() {
        return expandable[this.level]
    }

    get hideable() {
        return hideable[this.level]
    }

    get likelihood() {
        const likelihoodStatus = (status) => ['prospective', 'onHold'].includes(status)
        if (this.phase && likelihoodStatus(this.phase.status)) return this.phase.likelihood 
        if (this.project && likelihoodStatus(this.project.status)) return this.project.likelihood
        return 100;
    }

    get startMonthIndex() {
        if (this._startMonthIndex) return this._startMonthIndex
        this._startMonthIndex = this.phase && this.phase.id > 0 ? this.phase.startMonthIndex : this.project ? this.project.startMonthIndex : undefined;
        return this._startMonthIndex;
    }

    get endMonthIndex() {
        if (this._endMonthIndex) return this._endMonthIndex
        this._endMonthIndex = this.phase && this.phase.id > 0 ? this.phase.endMonthIndex : this.project ? this.project.endMonthIndex : undefined;
        return this._endMonthIndex;
    }

    get expanded() {
        return defaultExpanded[this.level] || this.spreadsheetStore.expandedItems.includes(this.uuid)
    }

    get minMonthIndex() {
        return this._combinedMinMonthIndex;
    }

    get maxMonthIndex() {
        return this._combinedMaxMonthIndex;
    }

    get parent() {
        return this.spreadsheetStore.hoursRows.get(this.parentId) // rename in future
    }

    get children() {
        return [...this.childrenIds]
            .map(cId => this.spreadsheetStore.hoursRows.get(cId))
            .sort(compareMultiple(
                (a, b) => (a.rowType === "phase" && a.phase && a.phase.startDate ? a.phase.startDate : Infinity) - (b.rowType === "phase" && b.phase && b.phase.startDate ? b.phase.startDate : Infinity),
                (a, b) => a.title.localeCompare(b.title)
            ));
        
    }

    get allChildren() {
        return [this, ...this.children.forEach(c => c.allChildren)]
    }

    get visibleChildren() {
        // if you turn off entire row but van see children
        // you want to the totals to add up correctl and not be 0
        return this.children.filter(c => this.visible ? c.visible : true)
    }

    get editableChildren() {
        // if you turn off entire row but van see children
        // you want to the totals to add up correctl and not be 0
        return this.children.filter(c => (this.visible ? c.visible : true) && c.editable && (c.isLeaf ?
            (
                c.project 
                && c.phase 
                && c.project.id > 0 
                && c.phase.id > 0 
                && (c.staffMember || c.staffRole)
            )
            : c.editableChildren.length > 0))
    }

    get ancestors() {
        if (!this.parent) return []
        let ancestors = this.parent.ancestors
        ancestors.push(this.parent)
        return ancestors
    }

    get selected() {
        return this.spreadsheetStore.selectedRowId === this.uuid
    }

    get isEntireRole() {
        return (this.rowType == "role" 
            && !this.ancestors.map(a => a.rowType).includes("project")
            && !this.ancestors.map(a => a.rowType).includes("phase"))
    }

    get isEntireStaffMember() {
        return (this.rowType == "staff"
            && !this.ancestors.map(a => a.rowType).includes("project")
            && !this.ancestors.map(a => a.rowType).includes("phase"))
    }

    get displayBudget() {
        let groups = this.ancestors.map(a => a.rowType)
        return (
            this.rowType === "project"
            || groups.includes("project")
        )
    }

    get isLeaf() {
        return this.childrenIds.size === 0
    }

    get isArchived() {
        return ((this.project ? this.project.status === "archived" : false)
            || (this.staffMember ? this.staffMember.isArchived : false))
            && (this.children.length > 0 ? _.every(this.children, child => child.isArchived) : true)
    }

    get isDisplayed() {
        return (!this.isArchived || this.hoursInDisplayedMonths) 
            && (this.children.length > 0 ? _.some(this.children, child => child.isDisplayed) : true)
    }

    get hoursInDisplayedMonths() {
        return sum(this.spreadsheetStore.dateColumns.map(dc => {
            return this.getDisplayedHoursMonthIndex(dc.monthIndex)
        }))
    }

    toggleVisibility() {
        this.visible = !this.visible
        this.children.forEach(child => child.visible = this.visible)
        _.range(this.minMonthIndex, this.maxMonthIndex + 1).forEach( mi => {
            let multiplyer = this.visible ? 1 : -1;
            this.parent.addActualHours(this.getActualHoursMonthIndex(mi)*multiplyer, mi)
            this.parent.addProjectedHours(this.getProjectedHoursMonthIndex(mi) * multiplyer, mi)
            this.parent.addCombinedHours(this.getCombinedHoursMonthIndex(mi) * multiplyer, mi)
        })
        if (this.isEntireRole || this.isEntireStaffMember) {
            this._availableHoursInMonth = {}
            this.ancestors.forEach(a => a._availableHoursInMonth = {})
        }
    }

    addTimesheetItem(tsItem) {
        this.timesheetItems.push(tsItem)
        const actual = tsItem.type === "timesheet"
        const { hours, monthIndex } = tsItem
        this.addHours(hours, monthIndex, actual ? "actual" : "projected")
    }

    addHours(hours, monthIndex, type) {
        if (type === "actual") {
            this.addActualHours(hours, monthIndex)
        } else if (type === "projected") {
            this.addProjectedHours(hours, monthIndex)
        }
    }

    addActualHours(hours, monthIndex) {
        if (hours == undefined) return true
        const currentMonthIndex = this.spreadsheetStore.currentMonthIndex
        this._actualValCache[monthIndex] = this._actualValCache[monthIndex] || 0
        this._actualValCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._actualMinMonthIndex = Math.min(monthIndex, this._actualMinMonthIndex) || monthIndex;
        this._actualMaxMonthIndex = Math.max(monthIndex, this._actualMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._actualHoursToDateCache[mi] != undefined) {
                this._actualHoursToDateCache[mi] = this.getActualHoursToDateMonthIndex(mi)
                this._actualHoursToDateCache[mi] += hours
            } else {
                this._actualHoursToDateCache[mi] = this.getActualHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addActualHours(hours, monthIndex)
        if (this.childrenIds.size === 0) {
            if (
				monthIndex <= currentMonthIndex &&
				(this._actualValCache[monthIndex] || 0) >=
					(this._projectedValCache[monthIndex] || 0)
			) {
				const changeInHours =
					(this._actualValCache[monthIndex] || 0) -
					(this._combinedValCache[monthIndex] || 0);
				this.addCombinedHours(changeInHours, monthIndex);
			} else {
				const changeInHours =
					(this._projectedValCache[monthIndex] || 0) -
					(this._combinedValCache[monthIndex] || 0);
				this.addCombinedHours(changeInHours, monthIndex);
			}
        }
    }

    addProjectedHours(hours, monthIndex) {
        if (hours == undefined) return true
        const currentMonthIndex = this.spreadsheetStore.currentMonthIndex
        this._projectedValCache[monthIndex] = this._projectedValCache[monthIndex] || 0
        this._projectedValCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._projectedMinMonthIndex = Math.min(monthIndex, this._projectedMinMonthIndex) || monthIndex;
        this._projectedMaxMonthIndex = Math.max(monthIndex, this._projectedMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._projectedHoursToDateCache[mi] != undefined) {
                this._projectedHoursToDateCache[mi] = this.getProjectedHoursToDateMonthIndex(mi)
                this._projectedHoursToDateCache[mi] += hours
            } else {
                this._projectedHoursToDateCache[mi] = this.getProjectedHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addProjectedHours(hours, monthIndex)
        
        if (this.childrenIds.size === 0) {
            if ((monthIndex >= currentMonthIndex) &&
                ((this._projectedValCache[monthIndex] || 0) >= (this._actualValCache[monthIndex] || 0))
            ) {
                const changeInHours = (this._projectedValCache[monthIndex] || 0) - (this._combinedValCache[monthIndex] || 0)
                this.addCombinedHours(changeInHours, monthIndex)
            } else {
                const changeInHours =
					(this._actualValCache[monthIndex] || 0) -
					(this._combinedValCache[monthIndex] || 0);
				this.addCombinedHours(changeInHours, monthIndex);
            }
        }
    }

    addCombinedHours(hours, monthIndex) {
        if (hours == undefined) return true
        this._combinedValCache[monthIndex] = this._combinedValCache[monthIndex] || 0
        this._combinedValCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._combinedMinMonthIndex = Math.min(monthIndex, this._combinedMinMonthIndex) || monthIndex;
        this._combinedMaxMonthIndex = Math.max(monthIndex, this._combinedMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._combinedHoursToDateCache[mi] != undefined) {
                this._combinedHoursToDateCache[mi] = this.getCombinedHoursToDateMonthIndex(mi)
                this._combinedHoursToDateCache[mi] += hours
            } else {
                this._combinedHoursToDateCache[mi] = this.getCombinedHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addCombinedHours(hours, monthIndex)
    }


    getTimesheetItemsMonthIndex(monthIndex) {
        this.timesheetItems.filter(tsi => tsi.monthIndex === monthIndex)
    }

    getProjectedHoursMonthIndex(monthIndex) {
        return this._projectedValCache[monthIndex] || 0
    }

    getActualHoursMonthIndex(monthIndex) {
        return this._actualValCache[monthIndex] || 0
    }

    getCombinedHoursMonthIndex(monthIndex) {
        return this._combinedValCache[monthIndex] || 0
    }

    getDisplayedHoursMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedHoursMonthIndex(monthIndex);
            case "actuals":
                return this.getActualHoursMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedHoursMonthIndex(monthIndex)// * (monthIndex > currentMonthIndex ? this.likelihood / 100 : 1);
        }
    }

    getProspectiveHours(monthIndex) {
        const isPhaseRow = this.rowType === "phase" && this.phase
        const prospectivePhase = isPhaseRow && ['prospective', 'onHold'].includes(this.phase.status)
        if (prospectivePhase) return this.getDisplayedHoursMonthIndex(monthIndex)
        if (!isPhaseRow && !this.isLeaf) return sum(this.children.map(c => c.getProspectiveHours(monthIndex)))
        return 0
    }

    getProspectiveHoursMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProspectiveHours(monthIndex);
            case "actuals":
                return 0
            default: // actualsProjected
                if (monthIndex < currentMonthIndex) return 0
                return this.getProspectiveHours(monthIndex)// * (monthIndex > currentMonthIndex ? this.likelihood / 100 : 1);
        }
    }

    getTotalAvailableHoursInMonth(monthIndex) {
        if (this._availableHoursInMonth[monthIndex]) return this._availableHoursInMonth[monthIndex]
        let groups = this.ancestors.map(a => a.rowType)
        groups.push(this.rowType)
        const staffGroup = groups.includes("staff")
        const roleGroup = groups.includes("role")
        if (staffGroup) {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getStaffAvailabilityInMonth(this.staffMember || this.staffRole, monthIndex);
        } else if (roleGroup) {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getRoleAvailabilityInMonth(this.staffRole, monthIndex);
        } else {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getAllStaffAvailabilityInMonth(monthIndex);
        }
        return this._availableHoursInMonth[monthIndex]
    }

    getProjectedPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getProjectedHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getActualPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getActualHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getDisplayedPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getDisplayedHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getProjectedHoursToDateMonthIndex(monthIndex) {
        if (!this._projectedMinMonthIndex || monthIndex < this._projectedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._projectedMaxMonthIndex) {
            return this.getProjectedHoursToDateMonthIndex(this._projectedMaxMonthIndex)
        } else {
            return this._projectedHoursToDateCache[monthIndex] || this.getProjectedHoursToDateMonthIndex(monthIndex-1)
        }
    }

    getActualHoursToDateMonthIndex(monthIndex) {
        if (!this._actualMinMonthIndex || monthIndex < this._actualMinMonthIndex) {
            return 0
        } else if(monthIndex > this._actualMaxMonthIndex) {
            return this.getActualHoursToDateMonthIndex(this._actualMaxMonthIndex)
        } else {
            return this._actualHoursToDateCache[monthIndex] || this.getActualHoursToDateMonthIndex(monthIndex-1)
        }
        
    }

    getCombinedHoursToDateMonthIndex(monthIndex) {
        if (!this._combinedMinMonthIndex || monthIndex < this._combinedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._combinedMaxMonthIndex) {
            return this.getCombinedHoursToDateMonthIndex(this._combinedMaxMonthIndex)
        } else {
            return this._combinedHoursToDateCache[monthIndex] || this.getCombinedHoursToDateMonthIndex(monthIndex-1)
        }

    }

    getDisplayedHoursToDateMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedHoursToDateMonthIndex(monthIndex);
            case "actuals":
                return this.getActualHoursToDateMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedHoursToDateMonthIndex(monthIndex);
        }
    }


    get monthIndexArray() {
        return _.range(
            Math.min(this.minMonthIndex, this.spreadsheetStore.selectedMonthIndex), 
            Math.max(this.maxMonthIndex, this.spreadsheetStore.selectedMonthIndex) + 1
        )
    }

    get totalProjectedHours() {
        return this.getDisplayedHoursToDateMonthIndex(this.maxMonthIndex)
    }

    get totalBudgetUse() {
        if (!this.budget) return 0
        return (this.totalProjectedHours / this.budget) * 100
    }

    get budget() {
        if (this._budget !== undefined) return this._budget
        this._budget = this.calcBudget()
        return this._budget
    }

    calcBudget() {
        let groups = this.ancestors.map(a => a.rowType)
        groups.push(this.rowType)
        const projectGroup = groups.includes("project") && this.project
        const phaseGroup = groups.includes("phase") && this.phase
        const staffGroup = groups.includes("staff") && this.staffMember
        const roleGroup = groups.includes("role") && this.staffRole
        if (projectGroup && phaseGroup && staffGroup) {
            if (this.staffMember) return this.phase.getStaffMemberHoursBudget(this.staffMember) * (this.likelihood / 100)
            return this.phase.getStaffRoleHoursBudget(this.staffRole) * (this.likelihood / 100)
        } else if (projectGroup && phaseGroup && roleGroup && !staffGroup) {
            return this.phase.getTotalStaffRoleHoursBudget(this.staffRole) * (this.likelihood / 100)
        } else if (projectGroup && staffGroup && !phaseGroup && !roleGroup) {
            if (this.staffMember) return this.project.getStaffMemberHoursBudget(this.staffMember) * (this.likelihood / 100)
            return this.project.getStaffRoleHoursBudget(this.staffRole) * (this.likelihood / 100)
        } else if (projectGroup && phaseGroup && !roleGroup && !staffGroup) {
            return this.phase ? this.phase.manualHoursBudget * (this.likelihood / 100) : 0
        } else if (projectGroup && !phaseGroup && !roleGroup && !staffGroup) {
            return this.project ? this.project.manualHoursBudget * (this.likelihood / 100) : 0
        } else {
            return sum(this.visibleChildren.map(child => child.budget))
        }
    }

    getProjectedBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getProjectedHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    getActualBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getActualHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    getDisplayedBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getDisplayedHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    updateLikelihood(newLikelihood) {
        if (['phase', 'role', 'staff'].includes(this.rowType)){
            this.parent.updateLikelihood(newLikelihood)
        } else if (this.rowType === "project") {
            const oldLikelihood = this.project.likelihood
            const likelihoodRatio = newLikelihood / oldLikelihood
            this.clearBudgets()
            this.monthIndexArray.forEach(mi => {
                this.setHoursMonthIndex(mi, this.getProjectedHoursMonthIndex(mi) * likelihoodRatio)
            })
        }
    }

    clearBudgets() {
        this._budget = undefined;
        this.ancestors.forEach( a => a._budget = undefined )
        this.children.forEach( c => c.clearBudgets() )
    }

    setHoursMonthIndex(monthIndex, newHours) {
        const oldProjectedHours = this.getProjectedHoursMonthIndex(monthIndex)
        const oldDisplayedHours = this.getDisplayedHoursMonthIndex(monthIndex)
        if (!this.editable || newHours === oldProjectedHours) return true
        if (this.editableChildren.length > 0) {
            const hoursRatio = oldDisplayedHours ? newHours / oldDisplayedHours : newHours / this.editableChildren.length
            this.editableChildren.forEach(childRow => {
                const oldChildHours = childRow.getDisplayedHoursMonthIndex(monthIndex)
                childRow.setHoursMonthIndex(monthIndex, oldDisplayedHours ? oldChildHours * hoursRatio : hoursRatio)
            })
        } else {
            const hours = (newHours - oldProjectedHours)// / (this.likelihood / 100);
            this.addHours(hours, monthIndex, "projected");
            const startDate = dateConverter.monthIndexToOffset(monthIndex);
            const endDate = dateConverter.endOfMonthOffset(startDate);
            newHours = newHours / (this.likelihood / 100);
            if (this.staffMember && this.phase) {
                this.phase.setStaffMemberHours(this.staffMember, startDate, endDate, newHours);
                this.spreadsheetStore.dirtyProjects.push(this.project)
            } else if (this.staffRole && this.phase){
                this.phase.setStaffRoleHours(this.staffRole, startDate, endDate, newHours);
                this.spreadsheetStore.dirtyProjects.push(this.project)
            }
        }
    }

    setBudgetUseMonthIndex(monthIndex, budgetPercent) {
        const budget = this.budget
        const hoursLastMonthToDate = this.getDisplayedHoursToDateMonthIndex(monthIndex-1)
        const hours = budget * (budgetPercent / 100) - hoursLastMonthToDate
        this.setHoursMonthIndex(monthIndex, hours);
    }

    setUtilisationMonthIndex(monthIndex, percentUtilisation) {
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        const hours = availabileHours * (percentUtilisation / 100)
        this.setHoursMonthIndex(monthIndex, hours);
    }

    get averageDisplayedHours() {
        const totalHours = sum(this.spreadsheetStore.dateColumns.map(dc => this.getDisplayedHoursMonthIndex(dc.monthIndex)))
        return totalHours / 12
    }

    get averageAvailableHours() {
        const totalHours = sum(this.spreadsheetStore.dateColumns.map(dc => this.getTotalAvailableHoursInMonth(dc.monthIndex)))
        return totalHours / 12
    }

    get averageUtilisation() {
        if (!this.averageAvailableHours) return 0
        return (this.averageDisplayedHours / this.averageAvailableHours) * 100 
    }

    addChildProjects(projectPhaseArray) {
        if (this.rowType == "role") {
            this.children.forEach(c => c.addChildProjects(projectPhaseArray))
        } else if (this.rowType == "staff") {
            projectPhaseArray.forEach(pph => {
                const projectRow = this.addBlankChildRow(getBlankRowData({
                    uuid: this.uuid + itemUuids["project"](pph.project),
                    title: itemTitles["project"](pph.project),
                    level: this.level + 1,
                    groupType: this.groupType,
                    rowType: "project",
                    projectId: pph.project ? pph.project.id : null,
                    phaseId: null,
                    staffId: this.staffMember ? this.staffMember.id : null,
                    roleId: this.staffRole ? this.staffRole.id : null,
                    parentId: this.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                projectRow.addBlankChildRow(getBlankRowData({
                    uuid: projectRow.uuid + itemUuids["phase"](pph.phase),
                    title: itemTitles["phase"](pph.phase),
                    level: projectRow.level + 1,
                    groupType: this.groupType,
                    rowType: "phase",
                    projectId: pph.project ? pph.project.id : null,
                    phaseId: pph.phase ? pph.phase.id : null,
                    staffId: this.staffMember ? this.staffMember.id : null,
                    roleId: this.staffRole ? this.staffRole.id : null,
                    parentId: projectRow.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                this.spreadsheetStore.dirtyProjects.push(pph.project);
                if (this.staff) {
                    pph.phase.addBlankStaffAllocations(this.staff)
                } else if (this.role) {
                    pph.phase.addBlankRoleAllocations(this.role)
                }
            })
        }
    }

    addChildStaff(roleStaffArray) {
        if (this.rowType == "project") {
            this.children.forEach(c => c.addChildStaff(roleStaffArray))
        } else if (this.rowType == "phase") {
            roleStaffArray.forEach(rs => {
                const roleRow = this.addBlankChildRow(getBlankRowData({
                    uuid: this.uuid + itemUuids["role"](rs.role),
                    title: itemTitles["role"](rs.role),
                    level: this.level + 1,
                    groupType: this.groupType,
                    rowType: "role",
                    projectId: this.project ? this.project.id : null,
                    phaseId: this.phase ? this.phase.id : null,
                    staffId: null,
                    roleId: rs.role ? rs.role.id : null,
                    parentId: this.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                roleRow.addBlankChildRow(getBlankRowData({
                    uuid: roleRow.uuid + itemUuids["staff"](rs.staff),
                    title: itemTitles["staff"](rs.staff),
                    level: roleRow.level + 1,
                    groupType: this.groupType,
                    rowType: "staff",
                    projectId: this.project ? this.project.id : null,
                    phaseId: this.phase ? this.phase.id : null,
                    staffId: rs.staff ? rs.staff.id : null,
                    roleId: rs.role ? rs.role.id : null,
                    parentId: roleRow.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                this.spreadsheetStore.dirtyProjects.push(this.project);
                if (rs.staff) {
                    this.phase.addBlankStaffAllocations(rs.staff)
                } else if (rs.role) {
                    this.phase.addBlankRoleAllocations(rs.role)
                }
            })
        }
    }

    addBlankChildRow(data) {
        const store = this.spreadsheetStore
        if (!this.childrenIds.has(data.uuid)) {
            this.childrenIds.add(data.uuid)
            const newRow = new TimesheetRow(store, data)
            store.hoursRows = store.hoursRows.set(data.uuid, newRow)
            return newRow
        } else {
            return store.hoursRows.get(data.uuid)
        }
    }
}

const itemUuids = {
    "role": item => item ? item.id : "--no-role",
    "staff": item => item ? item.id : "--no-staff",
    "project": item => item ? item.id : "--no-project",
    "phase": item => item ? item.id : "--no-phase",
    "status": item => item ? item.status : "--no-status",
}

const itemTitles = {
    "total": item => "Total",
    "role": item => item ? item.name : "No Role",
    "staff": item => item ? item.getFullName() : item.role ? `Generic ${item.role.name}` : "Generic",
    "project": item => item ? item.getTitle() : "No Project",
    "phase": item => item ? item.getTitle() : "No Phase",
    "status": item => item ? item.status : "No Status",
}

const getBlankRowData = ({
    uuid,
    title,
    level,
    groupType,
    rowType,
    projectId,
    phaseId,
    staffId,
    roleId,
    parentId,
    childrenIds,
    currentMonthIndex
}) => ({
    'uuid': uuid,
    'title': title,
    'level': level,
    'groupType': groupType,
    'rowType': rowType,
    'projectId': projectId,
    'phaseId': phaseId,
    'staffId': staffId,
    'roleId': roleId,
    'parentId': parentId,
    'childrenIds': childrenIds,
    'projectedHours': {},
    'actualHours': {},
    'combinedHours': {},
    'projectedHoursToDate': {},
    'actualHoursToDate': {},
    'combinedHoursToDate': {},
    'actualMinMonthIndex': currentMonthIndex,
    'actualMaxMonthIndex': currentMonthIndex,
    'projectedMinMonthIndex': currentMonthIndex,
    'projectedMaxMonthIndex': currentMonthIndex,
    'combinedMinMonthIndex': currentMonthIndex,
    'combinedMaxMonthIndex': currentMonthIndex,
})