import { generateUUID, sum } from '../utils.js';
import { DataObject } from './dataobject.js';
import { organisationStore } from '../organisation.js';
import _ from 'underscore';
import { ProjectExpenseAllocation } from './projectexpenseallocation.js';


export const ProjectExpense = class extends DataObject {
			/**
			 * ProjectExpense objects aren't real objects in the database,
			 * they are just dicts in the `Project.expenses` list.
			 */

			defaults() {
				return {
					uuid: generateUUID(),
					name: "",
					projectId: -1,
					phaseId: -1,
					phaseUuid: "",
					startDate: null,
					endDate: null,
					cost: 0,
					isInvoiceable: true,
					allocations: []
				};
			}

			static fieldTypes() {
				return {
					uuid: "string",
					name: "string",
					projectId: "number",
					phaseId: "number",
					phaseUuid: "string",
					startDate: "date",
					endDate: "date",
					cost: "number",
					isInvoiceable: "bool",
					allocations: [ProjectExpenseAllocation]
				};
			}

			static fieldsForSerialize() {
				return [
					"uuid",
					"name",
					"projectId",
					"phaseId",
					"phaseUuid",
					"startDate",
					"endDate",
					"cost",
					"isInvoiceable",
					"allocations"
				];
			}

			totalCost() {
				return this.cost;
			}
			get allocated_expenses() {
				if (!this.allocations.length) {
					return [
						new ProjectExpenseAllocation({
							date: this.startDate,
							amount: this.cost,
							projectId: this.projectId,
							phaseId: this.phaseId,
							expenseUuid: this.uuid
						})
					];
				}
				return this.allocations;
			}
			set allocated_expenses(allos) {
				this.allocations = allos;
			}
			get project() {
				return (
					organisationStore.getProjectById(this.projectId) ||
					this._project
				);
			}
			set project(project) {
				this.projectId = project.id;
				this._project = project;
			}

			get phase() {
				if (!this.project) return null;
				return (
					_.find(
						this.project.getVisiblePhases(),
						p => p.uuid === this.phaseUuid
					) || organisationStore.getProjectPhaseById(this.phaseId)
				);
			}
			set phase(phase) {
				this.phaseId = phase.id;
				this.phaseUuid = phase.uuid;
			}

			get billedToDate() {
				return sum(
					this.project.changeLog
						.toJS()
						.filter(
							e =>
								e.invoice && e.expense &&
								e.expense.uuid === this.uuid
						)
						.map(e => e.revenue)
				);
			}

			get remainingCost() {
				return this.cost - this.billedToDate;
			}
		};


