import _ from "underscore";
import { StoreBase, addAction, dispatcher } from "../coincraftFlux.js";
import { myHttp } from "../myHttp.js";
import { jsonHttp } from "../jsonHttp.js";
import { StaffMember } from "../models.js";
import { router } from "../router.js";
import { organisationStore } from "../organisation.js";
import axios from "axios";
import apiRequest from "../apiRequest.js";
import { TrackJS } from "trackjs";

class UserStore extends StoreBase {
	constructor() {
		super();
		this.user = null;

		// Have we checked to see if the user is logged in or are we still waiting for that?
		this.isReady = false;

		this.error = null;
		this.isLoggingIn = false;
		this.isRegistering = false;
		this.destinationUrl = null;
	}

	isAccountingSystemAuthenticated(accountingSystem) {
		return (
			this.user != null &&
			this.user.isAccountingSystemAuthenticated(accountingSystem)
		);
	}

	xeroDisconnectSuccess() {
		if (this.user != null && this.user.xeroCredentials != null) {
			delete this.user.xeroCredentials;
			this.emitChanged();
		}
	}

	accountingSystemAuthenticateSuccess(xeroCredentials) {
		this.user.xeroCredentials = xeroCredentials;
		this.emitChanged();
	}

	saveUserDetails(user) {
		apiRequest({
			url: `/api/v1/user/details`,
			method: "post",
			data: {
				firstName: user.firstName,
				lastName: user.lastName,
				organisationName: user.organisationName,
				country: user.country,
				couponCode: user.couponCode,
			},
			success: (data) => actions.saveUserDetailsSuccess(data),
			error: (data) => actions.saveUserDetailsFailure(),
		});
	}

	saveUserDetailsSuccess(data) {
		this.user = this.user
			.set("id", data.userData.id)
			.setDetails(data.userData);
		this.user.intercomUpdate();
		this.user.elevioUpdate();
		this.gotoUserHomePage();
	}

	saveUserDetailsFailure() {}

	isAdmin() {
		return this.user != null && this.user.permissions.isAdmin;
	}

	initializeAuth(redirect = true) {
		let self = this;
		// Be extra careful to nocache this because otherwise IE will think the user
		// isn't logged in when they actually are.
		return apiRequest({
			path: "/user/status",
			method: "get",
			data: { noCache: new Date().getTime() },
			success: (data) => {
				self.isReady = true;
				if (data.userData != null) {
					actions.loginSuccess(data, redirect);
				} else {
					if (redirect) {
						router.history.replace({ pathname: "/login" });
					} else {
						self.emitChanged();
					}
				}
			},
		});
	}

	login(email, password, redirect = true) {
		this.isLoggingIn = true;
		this.error = null;
		this.emitChanged();
		axios
			.post(process.env.REACT_APP_NODE_SERVER_URL + "/flags/has-flag", {
				email: email,
				flag: "has-migrated",
			})
			.then((response) => {
				if (response.data) {
					window.location.replace(
						process.env.REACT_APP_NEW_CLIENT_URL
					);
				} else {
					return apiRequest({
						path: "/user/login",
						method: "post",
						data: {
							email: email,
							password: password,
						},
						success: (data) => {
							actions.loginSuccess(data, redirect);
						},
						error: (data) => actions.loginFailure(),
					});
				}
			});
	}

	initializeOrganisationSuccess() {
		this.user.permissions = organisationStore.getStaffMemberById(
			this.user.id
		).permissions;
		this.emitChanged();
	}

	gotoUserHomePage() {
		router.history.replace({
			pathname: this.user.getHomePage(this.destinationUrl),
		});
	}

	_loginSuccess(data) {
		this.isReady = true;
		this.isLoggingIn = false;
		this.isRegistering = false;
		this.error = null;
		this.user = StaffMember.fromJson(data.userData);
		this.user.intercomBoot();
		this.user.posthogBoot();
		TrackJS.configure({
			userId: this.user.email,
		});
	}

	loginSuccess(data, redirect = true) {
		this._loginSuccess(data);
		if (redirect) {
			this.gotoUserHomePage();
		}
		this.emitChanged();
	}

	register(email, password) {
		this.isRegistering = true;
		apiRequest({
			url: `/user/register`,
			method: "post",
			data: {
				email: email,
				password: password,
			},
			success: (data) => actions.registerSuccess(data),
			error: (data) => actions.registerFailure(data),
		});
		this.emitChanged();
	}

	logout(redirect = true) {
		return apiRequest({
			url: `/user/logout`,
			method: "post",
		}).then((data) => actions.logoutSuccess(redirect));
	}

	logoutSuccess(redirect) {
		if (window.Intercom != null) {
			window.Intercom("shutdown");
		}
		this.user.elevioLogout();
		this.user = null;
		if (redirect) router.history.replace({ pathname: "/login" });
		this.emitChanged();
	}

	registerSuccess(data) {
		// `registerSuccess` is the same as loginSuccess (currently).
		this.isRegistering = false;
		this.loginSuccess(data);
	}

	registerFailure(error) {
		this.isLoggingIn = false;
		this.isRegistering = false;
		this.error = error;
		this.emitChanged();
	}

	loginFailure() {
		this.isLoggingIn = false;
		this.isRegistering = false;
		this.error = "login_failed";
		this.emitChanged();
	}

	getUser() {
		return this.user;
	}

	isLoggedIn() {
		return this.user != null;
	}

	includeOrganisationExpenses() {
		return this.user != null && this.user.isAdmin();
	}

	setDestinationUrl(url) {
		this.destinationUrl = url;
	}
}

export var userStore = new UserStore();

userStore.dispatchToken = dispatcher.register(function (action) {
	switch (action.type) {
		case "INITIALIZE_AUTH":
			userStore.initializeAuth(action.redirect);
			break;
		case "REQUIRE_ADMIN":
			userStore.requireAdmin();
			break;
		case "LOGIN":
			userStore.login(action.email, action.password, action.redirect);
			break;
		case "LOGIN_SUCCESS":
			dispatcher.waitFor([organisationStore.dispatchToken]);
			userStore.loginSuccess(action.data, action.redirect);
			break;
		case "LOGIN_FAILURE":
			userStore.loginFailure(action.user);
			break;
		case "REGISTER":
			userStore.register(action.email, action.password);
			break;
		case "REGISTER_SUCCESS": //?
			dispatcher.waitFor([organisationStore.dispatchToken]);
			userStore.registerSuccess(action.data);
			break;
		case "REGISTER_FAILURE": //?
			userStore.registerFailure(action.error);
			break;
		case "LOGOUT": //?
			userStore.logout();
			break;
		case "LOGOUT_SUCCESS": //?
			userStore.logoutSuccess();
			break;
		case "ACCOUNTING_SYSTEM_AUTHENTICATE_SUCCESS":
			userStore.accountingSystemAuthenticateSuccess(
				action.accountingSystem,
				action.xeroCredentials
			);
			break;
		case "XERO_DISCONNECT_SUCCESS":
			userStore.xeroDisconnectSuccess();
			break;
		case "SAVE_USER_DETAILS":
			userStore.saveUserDetails(action.user);
			break;
		case "SAVE_USER_DETAILS_SUCCESS":
			userStore.saveUserDetailsSuccess(action.data);
			break;
		case "SAVE_USER_DETAILS_FAILURE":
			userStore.saveUserDetailsFailure();
			break;
		case "SET_DESTINATION_URL":
			userStore.setDestinationUrl(action.url);
			break;
	}
});

export var actions = {};
addAction(actions, "initializeAuth", ["redirect"]);
addAction(actions, "requireAdmin", []);
addAction(actions, "login", ["email", "password", "redirect"]);
addAction(actions, "loginSuccess", ["data", "redirect"]);
addAction(actions, "loginFailure", []);
addAction(actions, "register", ["email", "password"]);
addAction(actions, "registerSuccess", ["data"]);
addAction(actions, "registerFailure", ["error"]);
addAction(actions, "logout", []);
addAction(actions, "logoutSuccess", []);
addAction(actions, "saveUserDetails", ["user"]);
addAction(actions, "saveUserDetailsSuccess", ["data"]);
addAction(actions, "saveUserDetailsFailure", []);
addAction(actions, "setDestinationUrl", ["url"]);
