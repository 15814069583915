import _ from 'underscore';
import { sum } from '../utils.js';
import { organisationStore } from '../organisation.js';


export const Allocation = class {
  constructor(dict = {}) {
    delete dict[undefined]
    this.dict = dict;
  }

  static fromHoursAndStaff(totalHours, staffMembers) {
    // `totalHours` hours divided evenly among `staffMembers`. If `staffMembers` is empty,
    // create `totalHours` generic hours.

    let d = {};

    if (staffMembers.length > 0) {
      let numHoursPerStaffMember = totalHours / staffMembers.length;
      staffMembers.forEach(function(s) {
        if(s.id !== undefined) {
          d[s.id] = numHoursPerStaffMember;
        }
      });
    }
    else {
      d[organisationStore.genericStaffMember.id] = totalHours;
    }

    return new Allocation(d);
  }

  clone() {
    return new Allocation(_.clone(this.dict));
  }

  toDict() {
    return _.clone(this.dict);
  }

  serialize() {
    return this.dict;
  }

  addAllocation(alloc) {
    /** Destructive. */
    let self = this;
    _.each(alloc.dict, function(hours, staffId) {
      if(staffId !== undefined) {
        if (self.dict[staffId] == null) {
          self.dict[staffId] = 0;
        }
        self.dict[staffId] += hours;
      }
    });
  }

  addStaffHours(staffMember, hours) {
    //turns out this is sometimes an id not a staff object, ugh..
    const staffId = Number.isInteger(staffMember) ? staffMember : staffMember.id;
    if(staffMember.id !== undefined) {
      if (this.dict[staffMember.id] == null) {
        this.dict[staffMember.id] = 0;
      }
      this.dict[staffMember.id] += hours;
    }
  }

  mapHours(func) {
    let dict = _.mapObject(this.dict, func);
    _.each(dict, function(v, k) {
      if (v == null) {
        delete dict[k];
      }
    });
    return new Allocation(dict);
  }

  hasGeneric() {
    return this.dict[organisationStore.genericStaffMember.id] != null;
  }

  getStaffMembers() {
    let staffMembers = [];
    _.each(this.dict, function(_, staffId) {
      if (staffId !== organisationStore.genericStaffMember.id.toString()) {
        staffMembers.push(organisationStore.getStaffMemberById(parseInt(staffId)));
      }
    });
    return staffMembers;
  }

  getStaffMemberAllocation(staffMember) {
    /**
     * Returns `null` if there is no allocation (distinct from zero allocation).
     */
    return this.dict[staffMember.id] || null;
  }

  hasStaffMemberAllocation(staffMember) {
    return this.dict[staffMember.id] != null;
  }

  removeStaffMember(staffMember) {
    delete this.dict[staffMember.id];
  }

  setStaffMemberAllocation(staffMember, hours) {
    if (staffMember.id !== undefined) {
      this.dict[staffMember.id] = hours;
    }
  }

  setStaffMembers(staffMembers) {
    let self = this;
    let hours;
    if (this.dict[organisationStore.genericStaffMember.id] != null) {
      hours = this.dict[organisationStore.genericStaffMember.id] / staffMembers.length;
    }
    else {
      hours = 0;
    }

    staffMembers.forEach(function(staffMember) {
      if (staffMember.id && self.dict[staffMember.id] == null) {
        self.dict[staffMember.id] = hours;
      }
    });

    _.each(this.dict, function(hours, staffId) {
      let intStaffId = parseInt(staffId);
      if (_.find(staffMembers, sm => sm.id === intStaffId) == null) {
        delete self.dict[staffId];
      }
    });
  }

  getNumAllocatedStaff() {
    return Object.keys(this.dict).filter(k => k !== organisationStore.genericStaffMember.id.toString()).length;
  }

  hasSpecificStaffAllocation() {
    return _.find(Object.keys(this.dict), k => k !== organisationStore.genericStaffMember.id.toString()) != null;
  }

  getTotalAllocatedHours() {
    return sum(_.map(this.dict, (hours, staffId) => hours));
  }
}


export function getStaffMembers(allocations) {
  let staffMembers = [];
  let staffMemberIds = new Set();
  for (let ra of allocations) {
    if (!staffMemberIds.has(ra.staffMember.id)) {
      staffMembers.push(ra.staffMember);
      staffMemberIds.add(ra.staffMember.id);
    }
  }
  return staffMembers;
}


export function getStaffMemberHours(allocations) {
  let staffMemberAllocations = {};
  let allocationsList = [];
  for (let ra of allocations) {
    let allocation = staffMemberAllocations[ra.staffMember.id];
    if (allocation == null) {
      allocation = {
        staffMember: ra.staffMember,
        hours: 0
      };
      staffMemberAllocations[ra.staffMember.id] = allocation;
      allocationsList.push(allocation);
    }
    allocation.hours += ra.hours;
  }
  return allocationsList;
}
