import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { MyTable } from "../myTable.js";
import { ProjectExpense } from '../models.js';
import { TextValue, DateValue, BooleanValue, SmallDeleteButton, CurrencySymbol, NumberInputContainer } from '../widgets.js';
import { formatNumber2 } from "../utils.js";
import { labelStyle, indentStyle } from './styles.js';
import { ProjectPhaseSelector } from '../widgets/ProjectPhaseSelector.js';
import PropTypes from "prop-types";


const elevioIds = {
  expenses: "89333",
};

export var ProjectExpenses = CreateReactClass({
  propTypes: {
    expenses: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
  },

  componentWillMount: function() {
    let self = this;
    // single line empty expense looks better than no expenses
    if (self.props.expenses.length === 0) {
      let newExpense = new ProjectExpense();
      newExpense.projectId = self.props.project.id;
      self.props.expenses.push(newExpense);
    }
  },

  render: function() {
    var self = this;

    return (
      <div>
        <div className="flexbox-container flex-space-between flex-align-items-flex-end">
          <div style={{display: 'inline-block'}}>
            <h4 style={{fontSize: '1.8em', margin: '1.5em 0 0.5em 0.5em', display: 'inline-block'}}>
              Project Expenses
            </h4>
            <i className="fa fa-question-circle" data-elevio-inline={elevioIds.expenses} style={{marginLeft: '1em'}}/>
          </div>
        </div>
        <div className="coincraft-table">
          <div className="flexbox-container coincraft-table-row header">
            <div className="coincraft-table-cell" style={{ width: '24%' }}>Name</div>
            <div className="coincraft-table-cell" style={{ width: '20%' }}>Phase</div>
            <div className="coincraft-table-cell" style={{width:'15%'}}>Start Date</div>
            <div className="coincraft-table-cell" style={{width:'15%'}}>End Date</div>
            <div className="coincraft-table-cell" style={{width:'16%'}}>Cost</div>
            <div className="coincraft-table-cell" style={{width:'6%'}}>Billable</div>
            <div className="coincraft-table-cell" style={{width:'4%'}}></div>
          </div>

          {this.props.expenses.map(function(expense) { //filter out deleted items
            return <div className="flexbox-container coincraft-table-row" key={expense.uuid}>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'24%'}}>
                <TextValue
                  isEditable={self.props.isEditable}
                  className="coincraft-table-cell__input"
                  value={expense.name}
                  onChange={function(event) {
                    self.doChange(self.cloneExpensesWithUpdate(expense, 'name', event.target.value));
                  }}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--dropdown" style={{ width: '20%' }}>
                <ProjectPhaseSelector
                  project={self.props.project}
                  phase={expense.phase}
                  allowNull={true}
                  placeholder="(No Phase)"
                  onChange={(phase) => {
                    self.doChange(self.cloneExpensesWithUpdate(expense, 'phaseId', phase ? phase.id : null))
                  }}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--date"  style={{width:'15%'}}>
                <DateValue
                  value={expense.startDate}
                  isEditable={self.props.isEditable}
                  onChange={function(newDate) {
                      self.doChange(self.cloneExpensesWithUpdate(expense, 'startDate', newDate));
                  }}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--date"  style={{width:'15%'}}>
                <DateValue
                  value={expense.endDate}
                  isEditable={self.props.isEditable}
                  onChange={function(newDate) {
                      self.doChange(self.cloneExpensesWithUpdate(expense, 'endDate', newDate));
                  }}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'16%'}}>
                <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                    <NumberInputContainer
                      className="coincraft-table-cell__input"
                      value={parseFloat(expense.cost)}
                      isEditable={self.props.isEditable}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={function(value) {
                        self.doChange(self.cloneExpensesWithUpdate(expense, 'cost', value));
                      }}
                    />
                </div>
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'6%'}}>
                <BooleanValue
                  value={expense.isInvoiceable}
                  isEditable={self.props.isEditable}
                  onChange={function(isChecked) {
                    self.doChange(self.cloneExpensesWithUpdate(expense, 'isInvoiceable', isChecked));
                  }}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'4%'}}>
                {self.props.isEditable ?
                  <SmallDeleteButton onClick={function() {
                    self.doChange(_.without(self.props.expenses, expense));
                  }} />
                : null}
              </div>
            </div>;
          })}
        </div>
        <button
            className="btn btn-default"
            style={{margin: '0.5em'}}
            onClick={function() {
              let newExpense = new ProjectExpense();
              newExpense.projectId = self.props.project.id;
              self.props.expenses.push(newExpense);
              self.doChange(self.props.expenses);
            }}>
        + Add Expense
        </button>
      </div>
    );
  },

  cloneExpenses: function() {
    return this.props.expenses.map(e => e.copy());
  },

  cloneExpensesWithUpdate: function(expense, prop, newValue) {
    var newExpenses = this.cloneExpenses();
    var newExpense = _.find(newExpenses, e => e.uuid === expense.uuid);
    newExpense[prop] = newValue;
    return newExpenses;
  },

  handleTableChange: function(expenses) {
    this.doChange(expenses);
  },

  doChange: function(expenses) {
    this.props.onChange(expenses);
  }
});
