import moment from 'moment';
import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import Immutable from 'immutable';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { parsesToFloat, caseInsensitiveContains, caseInsensitiveStartsWith,
  wrapAsReactNode } from '../utils.js';
import { DateValue, BasicMySelect2, MySelect2, SmallDeleteButton, Checkbox } from './generic.js';
import { MultiCostCentreSelect, MultiContactSelect, MultiStaffMemberSelect, MultiStaffRoleSelect,
  MultiProjectSelect, ProjectStatusSelect } from './coincraft.js';
import { organisationStore } from '../organisation.js';
import { parseDateRange, AllTime } from '../reports/DateRanges.js';
import { DateRangeSelector } from '../reports/DateRangeSelector.js';
import { dateConverter } from '../models/dateconverter.js';
import PropTypes from "prop-types";


function doSimpleMatch(operation, filterValue, itemValue) {
  if (operation === 'eq') {
    return itemValue === filterValue;
  }
  else if (operation === 'lt') {
    return itemValue < filterValue;
  }
  else if (operation === 'gt') {
    return itemValue > filterValue;
  }
  else {
    throw new Error("Was not a simple operation");
  }
}


class DateMatcher extends Immutable.Record({
  type: 'moment',
  value: null,
  operation: 'occurred',
  customRange: null
}) {
  constructor(options = {}) {
    super({
      ...options,
      value: _.isString(options.value) ? moment(options.value, "YYYY-MM-DD") : options.value,
      customRange: (options.customRange != null) ? parseDateRange(options.customRange) : AllTime
    });
  }

  matches(itemValue) {
    if (_.include(['eq', 'lt', 'gt'], this.operation)) {
      if (this.value != null) {
        return itemValue != null && doSimpleMatch(this.operation, dateConverter.momentToInt(this.value), itemValue);
      }
      else {
        return true;
      }
    }
    else if (_.include(['occurred', 'did_not_occur'], this.operation)) {
      const occurred = itemValue != null && this.customRange.containsDate(itemValue);
      if (this.operation === 'occurred') {
        return occurred;
      }
      else {
        return !occurred;
      }
    }
  }

  toJS() {
    return {
      type: this.type,
      value: this.value != null ? this.value.format("YYYY-MM-DD") : null,
      operation: this.operation,
      customRange: this.customRange
    };
  }
}


class IntDateMatcher extends DateMatcher {
  constructor(options = {}) {
    super({
      ...options,
      type: 'intDate'
    });
  }
}


class NumberMatcher extends Immutable.Record({
  type: 'number',
  value: '',
  operation: 'eq',
}) {
  matches(itemValue) {
    if (parsesToFloat(this.value)) {
      return doSimpleMatch(this.operation, parseFloat(this.value), itemValue);
    }
    else {
      return true;
    }
  }
}


class RationalMatcher extends Immutable.Record({
  type: 'rational',
  value: '',
  operation: 'eq',
}) {
  matches(itemValue) {
    if (parsesToFloat(this.value)) {
      return doSimpleMatch(
        this.operation,
        parseFloat(this.value) / 100,
        itemValue.numerator / itemValue.denominator
      );
    }
    else {
      return true;
    }
  }
}


class StringMatcher extends Immutable.Record({
  type: 'string',
  value: '',
  operation: 'eq',
}) {
  matches(itemValue) {
    if (this.value != null && this.value !== '') {
      if (this.operation === 'eq') {
        return this.value === itemValue;
      }
      else if (this.operation === 'contains') {
        return itemValue != null && caseInsensitiveContains(itemValue, this.value);
      }
      else if (this.operation === 'starts_with') {
        return itemValue != null && caseInsensitiveStartsWith(itemValue, this.value);
      }
    }
    else {
      return true;
    }
  }
}

class TaskMatcher extends Immutable.Record({
  type: 'task',
  value: '',
  operation: 'eq',
}) {
  matches(itemValue) {
    if (this.value != null && this.value !== '') {
      if (itemValue == null) {
        return false;
      }
      if (this.operation === 'eq') {
        return this.value === itemValue.name;
      }
      else if (this.operation === 'contains') {
        return caseInsensitiveContains(itemValue.name, this.value);
      }
      else if (this.operation === 'starts_with') {
        return caseInsensitiveStartsWith(itemValue.name, this.value);
      }
    }
    else {
      return true;
    }
  }
}


class ProjectPhaseMatcher extends Immutable.Record({
  type: 'projectPhase',
  value: '',
  operation: 'eq',
}) {
  matches(itemValue) {
    if (this.value != null && this.value !== '') {
      if (itemValue == null) {
        return false;
      }
      if (this.operation === 'eq') {
        return this.value === itemValue.getTitle();
      }
      else if (this.operation === 'contains') {
        return caseInsensitiveContains(itemValue.getTitle(), this.value);
      }
      else if (this.operation === 'starts_with') {
        return caseInsensitiveStartsWith(itemValue.getTitle(), this.value);
      }
    }
    else {
      return true;
    }
  }
}




class BooleanMatcher extends Immutable.Record({
  type: 'bool',
  operation: 'is_true',
}) {
  matches(itemValue) {
    if (this.operation === 'is_true') {
      return itemValue;
    }
    else if (this.operation === 'is_false') {
      return !itemValue;
    }
    else {
      return true;
    }
  }
}



function listMatch(filterValue, operation, itemValue, keyFunc = (a => a)) {
  if (filterValue.length > 0) {
    let includes = (itemValue != null && _.find(filterValue, cs => keyFunc(cs) === keyFunc(itemValue)) != null);
    if (operation === 'any') {
      return includes;
    }
    else {
      return !includes;
    }
  }
  else {
    return true;
  }
}


class SingleListMatcher extends Immutable.Record({
  /**
   * `itemValue` is a scalar, `value` is a list.
   */
  type: 'simpleList',
  value: [],
  operation: 'any', // 'any' | 'not_any'
  keyFunc: a => a.id
}) {
  matches(itemValue) {
    return listMatch(this.value, this.operation, itemValue, this.keyFunc);
  }

  toJS() {
    return {
      type: this.type,
      value: this.value.map(c => this.keyFunc(c)),
      operation: this.operation
    };
  }
}


class MultiListMatcher extends Immutable.Record({
  type: 'multiList',
  value: [],
  operation: 'any', // 'any' | 'all' | 'not_any' | 'not_all'
}) {
  matches(itemValue) {
    if (!_.isArray(itemValue)) {
      itemValue = [itemValue];
    }

    if (this.value.length > 0) {
      let value;

      if (this.operation === 'any' || this.operation === 'not_any') {
        if (itemValue instanceof Immutable.Set) {
          value = itemValue.some(itemOb => _.find(this.value, valueOb => valueOb.id === itemOb.id));
        }
        else {
          value = (_.find(itemValue, sm => _.find(this.value, sm1 => sm1.id === sm.id) != null)) != null;
        }
      }
      else if (this.operation === 'all' || this.operation === 'not_all') {
        if (itemValue instanceof Immutable.Set) {
          value = itemValue.map(ob => ob.id).isSuperset(Immutable.Set(this.value.map(ob => ob.id)));
        }
        else {
          value = _.find(this.value, ob => _.find(itemValue, ob1 => ob1.id === ob.id) == null) == null;
        }
      }

      if (this.operation === 'not_any' || this.operation === 'not_all') {
        return !value;
      }
      else {
        return value;
      }
    }
    else {
      return true;
    }
  }

  toJS() {
    return {
      type: this.type,
      value: this.value.map(c => c.id),
      operation: this.operation
    };
  }
}


var FilterBlock = CreateReactClass({
  propTypes: {
    style: PropTypes.object
  },

  render: function() {
    return (
      <div
          style={{
            display: 'inline-block',
            verticalAlign: 'top',
            marginRight: '0.5em',
            ...this.props.style
          }}>
        {this.props.children}
      </div>
    );
  }
});


var FilterTextBlock = CreateReactClass({
  propTypes: {
    style: PropTypes.object
  },

  render: function() {
    return (
      <FilterBlock
          style={{
            // Line up with the MySelect2
            border: 'solid 1px transparent',
              ...this.props.style
          }}>
        {this.props.children}
      </FilterBlock>
    );
  }
});


export var FilterList = CreateReactClass({
  propTypes: {
    columns: PropTypes.array.isRequired,
    filters: PropTypes.instanceOf(Immutable.List),
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    actions: PropTypes.object.isRequired,
    shouldShowRefreshTableButton: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      shouldShowRefreshTableButton: false
    };
  },

  mixins: [
    PureRenderMixin
  ],

  render: function() {
    let self = this;

    return <div style={{padding: '1em 1.5em'}}>
      {this.props.filters.map(function(filter, filterIndex) {
        let options = self.props.columns.filter(c => c.canFilter);
        const columnId = filter.get('columnId');
        let column, WidgetClass, matcher;
        if (columnId == null) {
          options = [null, ...options];
          // Must be `null`, not `undefined` as the drop down list turns `undefined` into
          // an empty string.
          column = null;
        }
        else {
          column = _.find(self.props.columns, c => c.identifier === columnId);
          WidgetClass = (column != null ? fieldTypeToWidgetLookup[column.type] : null);
          matcher = filter.get('matcher');
        }

        return (
          <div
              key={filterIndex}
              className="filter-row"
              style={{padding: '0.5em 0', borderBottom: 'solid 1px #ccc', display: "flex", alignItems: 'center'}}>

            <FilterBlock style={{marginTop: '0.3em', flexGrow: 0}}>
              <MySelect2
                className="filter-row__column-select"
                value={column}
                options={options}
                onChange={function(column) { self.handleSelectChange(filterIndex, column.identifier); }}
                getObjectLabel={function(c) {
                  return <span>
                    {c != null ? c.name : '-- Select a column --'}
                  </span>;
                }}
                filter={function(dataItem, searchTerm) {
                  return dataItem != null && caseInsensitiveContains(dataItem.name, searchTerm);
                }}
                style={{width: '17em'}}
              />
            </FilterBlock>

            {column != null ?
              <div style={{display: 'inline-block', flexGrow: 1}}>
                <FilterBlock  style={{marginTop: '0.3em'}}>
                  {WidgetClass != null ?
                    React.createElement(WidgetClass, {
                      matcher: matcher,
                      filterIndex: filterIndex,
                      actions: self.props.actions,
                      readOnly: filter.get('readOnly')
                    })
                  :
                    <span>Unknown type</span>
                  }
                </FilterBlock>
                <FilterBlock style={{marginTop: '0.2em', float: 'right'}}>
                  {column.canShow ?
                    <Checkbox
                      className="filter-row__show-column-checkbox"
                      label="Show"
                      labelPosition='left'
                      value={_.find(self.props.selectedColumns, c => c === column.identifier) != null}
                      onChange={(isSelected) => self.props.actions.setColumnSelected(column.identifier, isSelected)}
                      disabled={column.isMandatory}
                    />
                  : null}
                </FilterBlock>
              </div>
            : null}

            <FilterBlock style={{flexGrow: 0}}>
              <SmallDeleteButton onClick={() => self.handleDeleteButtonClick(filterIndex)} />
            </FilterBlock>
          </div>
        );
      })}

      <div>
        <button
            className="btn btn-default add-filter-button"
            onClick={this.handleAddFilterButtonClick}
            style={{margin: '0.5em', paddingLeft: '2em', paddingRight: '2em'}}>
          + Add filter
        </button>

        {this.props.shouldShowRefreshTableButton ?
          <button
              className="btn btn-primary refresh-table-button"
              style={{marginLeft: '2em', paddingLeft: '1.3em', paddingRight: '1.3em'}}
              onClick={this.handleRefreshButtonClick}>
            <i className="fa fa-fw fa-refresh" style={{marginLeft: 0}} />
            Refresh table
          </button>
        : null}
      </div>
    </div>;
  },

  handleSelectChange: function(filterIndex, columnId) {
    this.props.actions.selectColumnById(filterIndex, columnId);
  },

  handleAddFilterButtonClick: function() {
    this.props.actions.addFilter();
  },

  handleDeleteButtonClick: function(filterIndex) {
    this.props.actions.deleteFilter(filterIndex);
  },

  handleRefreshButtonClick: function() {
    this.props.actions.refreshTable();
  }
});


var NumberWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    afterInput: PropTypes.node
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          value={this.props.matcher.operation}
          options={[
            {label: 'is equal to', value: "eq"},
            {label: 'is greater than', value: "gt"},
            {label: 'is less than', value: "lt"}
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>

      <FilterTextBlock>
        <input
          type="text"
          value={this.props.matcher.get('value', '')}
          onChange={this.handleInputChange}

          // Line up with the MySelect2
          className="rw-input"
          style={{width: '6em'}}
        />
        {wrapAsReactNode(this.props.afterInput)}
      </FilterTextBlock>
    </div>;
  },

  handleInputChange: function(event) {
    this.props.actions.setFilterValue(this.props.filterIndex, event.target.value);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});


var PercentageWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  },

  render: function() {
    return <NumberWidget afterInput="%" {...this.props} />;
  }
});


var StringWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          value={this.props.matcher.operation}
          options={[
            {label: 'is equal to', value: "eq"},
            {label: 'contains', value: "contains"},
            {label: 'starts with', value: "starts_with"},
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>

      <FilterTextBlock>
        <input
          type="text"
          value={this.props.matcher.get('value', '')}
          onChange={this.handleInputChange}
          className="rw-input"
        />
      </FilterTextBlock>
    </div>;
  },

  handleInputChange: function(event) {
    this.props.actions.setFilterValue(this.props.filterIndex, event.target.value);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});


var BooleanWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          className="filter-row__operation-select"
          value={this.props.matcher.operation}
          options={[
            {label: 'yes', value: "is_true"},
            {label: 'no', value: "is_false"},
            {label: "doesn't matter", value: "any"},
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>
    </div>;
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});



var CostCentresWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          className="filter-row__operation-select"
          value={this.props.matcher.operation}
          options={[
            {label: 'includes any of', value: "any"},
            {label: 'does not include any of', value: "not_any"},
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>

      <FilterTextBlock>
        <MultiCostCentreSelect
          value={this.props.matcher.value}
          onChange={this.handleCostCentresChange}
          style={{width: '20em'}}
        />
      </FilterTextBlock>
    </div>;
  },

  handleCostCentresChange: function(costCentres) {
    this.props.actions.setFilterValue(this.props.filterIndex, costCentres);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});

var ProjectStatusWidget = CreateReactClass({
	propTypes: {
		matcher: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired
	},

	render: function() {
    if (this.props.readOnly) {
      let operation;
      if (this.props.matcher.operation === "any") {
        if (this.props.matcher.value.length > 1) {
          operation = "is any of";
        } else {
          operation = "is";
        }
      } else {
        if (this.props.matcher.value.length > 1) {
          operation = "is not any of";
        } else {
          operation = "is not";
        }
      }

      /**
        * We've been a bit lazy here and we rely on the `selectComponent` having
        * a `textField` method which we use to turn the value into text.
        */
      return (
        <div>
          {operation}{" "}
          {this.props.matcher.value
            .map(v =>
              this.props.selectComponent.prototype.textField(
                v
              )
            )
            .join(", ")}
        </div>
      );
    }
		return (
			<div>
				<FilterBlock>
					<BasicMySelect2
						className="filter-row__operation-select"
						value={this.props.matcher.operation}
						options={[
							{ label: "is any of", value: "any" },
							{ label: "is not any of", value: "not_any" }
						]}
						onChange={this.handleOperationChange}
						style={{ width: "11em" }}
					/>
				</FilterBlock>

				<FilterTextBlock>
					<ProjectStatusSelect
						value={this.props.matcher.value}
						onChange={this.handleStatusChange}
						style={{ width: "20em" }}
					/>
				</FilterTextBlock>
			</div>
		);
	},

	handleStatusChange: function(projectStatuses) {
		this.props.actions.setFilterValue(
			this.props.filterIndex,
			projectStatuses.map(ps => ps.id)
		);
	},

	handleOperationChange: function(operation) {
		this.props.actions.setFilterOperation(
			this.props.filterIndex,
			operation
		);
	}
});


var SingleListWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    filterIndex: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    selectComponent: PropTypes.any.isRequired
  },

  render: function() {
    if (this.props.readOnly) {
      let operation;
      if (this.props.matcher.operation === 'any') {
        if (this.props.matcher.value.length > 1) {
          operation = "is any of";
        }
        else {
          operation = "is";
        }
      }
      else {
        if (this.props.matcher.value.length > 1) {
          operation = "is not any of";
        }
        else {
          operation = "is not";
        }
      }

      /**
       * We've been a bit lazy here and we rely on the `selectComponent` having
       * a `textField` method which we use to turn the value into text.
       */
      return <div>
        {operation} {this.props.matcher.value.map(v => this.props.selectComponent.prototype.textField(v)).join(", ")}
      </div>;
    }
    else {
      return <div>
        <FilterBlock>
          <BasicMySelect2
            className="filter-row__operation-select"
            value={this.props.matcher.operation}
            options={[
              {label: 'is any of', value: "any"},
              {label: 'is not any of', value: "not_any"},
            ]}
            onChange={this.handleOperationChange}
            style={{width: '11em'}}
          />
        </FilterBlock>

        <FilterTextBlock>
          {React.createElement(
            this.props.selectComponent,
            {
              value: this.props.matcher.value,
              onChange: this.handleMultiSelectChange,
              style: {width: '20em'}
            }
          )}
        </FilterTextBlock>
      </div>;
    }
  },

  handleMultiSelectChange: function(contacts) {
    this.props.actions.setFilterValue(this.props.filterIndex, contacts);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});



var MultiListWidget = CreateReactClass({
  propTypes: {
    filterIndex: PropTypes.number.isRequired,
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    selectComponent: PropTypes.any.isRequired
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          className="filter-row__operation-select"
          value={this.props.matcher.operation}
          options={[
            {label: 'includes any of', value: "any"},
            {label: 'includes all of', value: "all"},
            {label: 'does not include any of', value: "not_any"},
            {label: 'does not include all of', value: "not_all"},
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>

      <FilterTextBlock>
        {React.createElement(
          this.props.selectComponent,
          {
            value: this.props.matcher.value,
            onChange: this.handleMultiSelectChange,
            style: {width: '20em'}
          }
        )}
      </FilterTextBlock>
    </div>;
  },

  handleMultiSelectChange: function(options) {
    this.props.actions.setFilterValue(this.props.filterIndex, options);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  }
});


function makeListWidgetComponent(type, selectComponentFunc) {
  /**
   * `type`: 'single' | 'multi'
   */
  return CreateReactClass({
    propTypes: {
      filterIndex: PropTypes.number.isRequired,
      matcher: PropTypes.object.isRequired,
      actions: PropTypes.object.isRequired
    },

    render: function() {
      return React.createElement(type === 'multi' ? MultiListWidget : SingleListWidget, {
        filterIndex: this.props.filterIndex,
        matcher: this.props.matcher,
        actions: this.props.actions,
        selectComponent: selectComponentFunc(),
        readOnly: this.props.readOnly
      });
    }
  });
}


var DateWidget = CreateReactClass({
  propTypes: {
    matcher: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  },

  render: function() {
    return <div>
      <FilterBlock>
        <BasicMySelect2
          className="filter-row__operation-select"
          value={this.props.matcher.operation}
          options={[
            {label: 'is equal to', value: "eq"},
            {label: 'is after', value: "gt"},
            {label: 'is before', value: "lt"},
            {label: 'occurred', value: 'occurred'},
            {label: 'did not occur', value: 'did_not_occur'}
          ]}
          onChange={this.handleOperationChange}
          style={{width: '11em'}}
        />
      </FilterBlock>

      <FilterBlock>
        {_.include(['eq', 'gt', 'lt'], this.props.matcher.get('operation')) ?
          <DateValue
            style={{width: '10em'}}
            value={this.props.matcher.get('value', null)}
            onChange={this.handleDatePickerChange}
          />
        :
          <DateRangeSelector
            value={this.props.matcher.get('customRange')}
            onChange={this.handleCustomRangeChange}
            showAllTimeOption={false}
          />
        }
      </FilterBlock>
    </div>;
  },

  handleDatePickerChange: function(date) {
    this.props.actions.setFilterValue(this.props.filterIndex, date);
  },

  handleOperationChange: function(operation) {
    this.props.actions.setFilterOperation(this.props.filterIndex, operation);
  },

  handleCustomRangeChange: function(dateRangeId) {
    this.props.actions.setFilterCustomRange(this.props.filterIndex, dateRangeId);
  }
});

function makeSingleListMatcherClass(typename, getObjectFromId, extraOptions) {
  return function(options = {}) {
    return new SingleListMatcher({
      ...options,
      type: typename,
      value: options.value != null ?
        options.value.map(getObjectFromId).filter(p => p != null)
      : [],
      ...extraOptions
    });
  };
}

function makeMultiListMatcherClass(typename, getObjectFromId) {
  return function(options = {}) {
    return new MultiListMatcher({
      ...options,
      type: typename,
      value: options.value != null ?
        options.value.map(getObjectFromId).filter(p => p != null)
      : []
    });
  };
}

export const fieldTypeToMatcherTypeLookup = {
  number: options => new NumberMatcher(options),
  rational: options => new RationalMatcher(options),
  string: options => new StringMatcher(options),
  bool: options => new BooleanMatcher(options),
  moment: options => new DateMatcher(options),
  intDate: options => new IntDateMatcher(options),
  costCentre: makeSingleListMatcherClass('costCentre', id => organisationStore.getCostCentreById(id)),
  contact: makeSingleListMatcherClass('contact', id => organisationStore.getContactById(id)),
  staffMember: makeSingleListMatcherClass('staffMember', id => organisationStore.getStaffMemberById(id)),
  staffMembers: makeMultiListMatcherClass('staffMembers', id => organisationStore.getStaffMemberById(id)),
  project: makeSingleListMatcherClass('project', id => organisationStore.getProjectById(id)),
  projects: makeMultiListMatcherClass('projects', id => organisationStore.getProjectById(id)),
  staffRole: makeSingleListMatcherClass('staffRole', id => organisationStore.getStaffRoleById(id)),
  staffRoles: makeMultiListMatcherClass('staffRoles', id => organisationStore.getStaffRoleById(id)),
  task: options => new TaskMatcher(options),
  projectPhase: options => new ProjectPhaseMatcher(options),
  projectStatus: makeSingleListMatcherClass(
    'projectStatus',
    // Project states are just plain strings so we don't need any shenanigans to
    // convert them to and from ids.
    s => s,
    {keyFunc: (a => a)}
  )
};

const fieldTypeToWidgetLookup = {
	number: NumberWidget,
	rational: PercentageWidget,
	string: StringWidget,
	bool: BooleanWidget,
	moment: DateWidget,
	intDate: DateWidget,
	costCentre: CostCentresWidget,
	contact: makeListWidgetComponent("single", () => MultiContactSelect),
	staffMember: makeListWidgetComponent(
		"single",
		() => MultiStaffMemberSelect
	),
	staffMembers: makeListWidgetComponent(
		"multi",
		() => MultiStaffMemberSelect
	),
	project: makeListWidgetComponent("single", () => MultiProjectSelect),
	projects: makeListWidgetComponent("multi", () => MultiProjectSelect),
	staffRole: makeListWidgetComponent("single", () => MultiStaffRoleSelect),
	staffRoles: makeListWidgetComponent("multi", () => MultiStaffRoleSelect),
	task: StringWidget,
	projectPhase: StringWidget,
	projectStatus: ProjectStatusWidget
};
