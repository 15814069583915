import axios from "axios";
import _ from "underscore";
import { userStore } from "./user";

const apiRequest = ({
	path,
	url,
	method = "get",
	data = {},
	params = {},
	cancelToken,
	success,
	error,
}) => {
	if (userStore.user) {
		axios
			.post(process.env.REACT_APP_NODE_SERVER_URL + "/flags/has-flag", {
				email: userStore.user.email,
				organisationId: userStore.user.organisationId,
				flag: "has-migrated",
			})
			.then((response) => {
				if (response.data) {
					window.location.replace(
						process.env.REACT_APP_NEW_CLIENT_URL
					);
				}
			});
	}
	path = path || url;
	const makeRequest = () =>
		axios(`${process.env.REACT_APP_SERVER_URL}${path}`, {
			method: method,
			data: data,
			params: params,
			withCredentials: true,
			cancelToken: cancelToken,
		})
			.then((response) => {
				success && success(response.data);
				return response.data;
			})
			.catch((errorResponse) => {
				console.error(errorResponse);
				error && error(errorResponse);
				return errorResponse;
			});
	return makeRequest();
};

export default apiRequest;

export const chainRequests = async (
	requestFunctions,
	parrallelRequests = 4
) => {
	let responseArray = [];
	const requester = async () => {
		let responses = [];
		const executeRequest = async () => {
			if (requestFunctions.length > 0) {
				const req = requestFunctions[0];
				requestFunctions.shift();
				const response = await req();
				responses.push(response);
				await executeRequest();
			}
		};
		await executeRequest();
		return responses;
	};
	await Promise.all(_.range(parrallelRequests).map((rn) => requester())).then(
		(responses) => {
			responseArray.push(..._.flatten(responses));
		}
	);
	return responseArray;
};
