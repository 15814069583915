
import moment from "moment";
import _ from "underscore";
import { StoreBase, dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import { AjaxOperation } from '../AjaxOperation.js';
import { organisationStore } from '../organisation.js';
import { router } from '../router.js';
import { sum } from "../utils.js";
import { PermissionItem, PermissionObject, FinancialsVisibility } from '../models/permissions.js';
import { AjaxOperation2 } from '../AjaxOperation.js';
import { PayRate } from '../models/staffmember.js';
import { userStore } from "../user/flux.js";
import Immutable from "immutable";


class BillingPageStore {
  constructor() {
    this.path = "billing-page";
    this.actionDefinitions = billingActionDefinitions;
    this.saveWithEntriesOperation = new AjaxOperation(this);
    this.cardUpdateInProgress = false;
    this.initialPeriod = "yearly";
    this.modal = null;
    this.staffMember = null;
    this.receiptEmail = null
    this.staffSaveOperation = new AjaxOperation2(this.path + '/staffSave');
    this.stores = {
      'staffSave': this.staffSaveOperation
    };
  }

  loadPage() {
    if (organisationStore.staffMembers) {
      this.staffWithFees = _.sortBy(organisationStore.staffMembers.filter(s => s.billingAmount > 0), s => s.getFullName());
      this.staffWithoutFees = _.sortBy(organisationStore.staffMembers.filter(s => s.billingAmount === 0), s => s.getFullName());
      this.organisation = organisationStore.organisation
      this.initialPeriod = organisationStore.organisation.subscriptionPeriod
      this.receiptEmail = this.organisation.receiptEmail
    }
  }

  handle(action) {
    if (action.path === 'billing-page/staffSave') {
      this.staffSaveOperation.handle(action);
      if (action.type === "ajax/success") {
        this.staffSaveSuccess(action.data);
      }
    }
    else {
      handleAction(action, this);
    }
  }

  updateCard() {
    let tokenFired = false;
    let self= this;

    let handler = window.StripeCheckout.configure({
		key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
		panelLabel: "Update",
		token: function(token) {
			try {
				tokenFired = true;
				self.cardUpdateInProgress = true;
				self.updateCardState = "processing";
				organisationStore
					.updateCard({
						stripeToken: token.id
					})
					.then(
						function(data) {
							actions.cardSuccess();
						},
						function(error) {
							console.error(error);
							actions.cardFailure(error);
						}
					);
			} catch (e) {
				console.error(e);
				actions.cardFailure();
			}
		},
		closed: function() {
			if (!tokenFired) {
				actions.cardCancel();
			}
		}
	});

    self.updateCardState = 'waitingForStripe';

    handler.open({
      name: 'Update Card',
      description: 'Update your payment details.',
      email: this.organisation.receiptEmail,
      label: 'Update'
    });
  }

  cardSuccess() {
    this.updateCardState = 'success';
    this.cardUpdateInProgress = false;
  }

  cardFailure() {
    this.updateCardState = 'error';
    this.cardUpdateInProgress = false;
  }

  cardCancel() {
    this.updateCardState = null;
    this.cardUpdateInProgress = false;
  }

  subscribe() {
    let self = this;
    
    const { staffWithFees, organisation } = this
    const billingAmount = sum(staffWithFees.map(s => s.billingAmount))
    const discount = billingAmount * (organisation.coupon.percent_discount / 100)
    const tax = (billingAmount - discount) * (organisation.taxPercent / 100)
    const credit = organisation.credit / 100
    const total = billingAmount - discount + tax - credit

    let tokenFired = false;

    let handler = window.StripeCheckout.configure({
		key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
		token: function(token) {
			try {
				tokenFired = true;
				self.subscriptionInProgress = true;
				self.subscriptionState = "processing";
				organisationStore
					.addCustomer({
						stripeToken: token.id,
						period: organisation.subscriptionPeriod
					})
					.then(
						function(data) {
							actions.subscriptionSuccess();
						},
						function(error) {
							console.error(error);
							actions.subscriptionFailure(error);
						}
					);
			} catch (e) {
				console.error(e);
				actions.subscriptionFailure();
			}
		},
		closed: function() {
			if (!tokenFired) {
				actions.subscriptionCancel();
			}
		}
	});

    self.subscriptionState = 'waitingForStripe';

    handler.open({
      name: 'Coincraft Subscription',
      description: "Please enter your payment details.",
      amount: total * 100,
      email: userStore.getUser().email
    });
  }

  subscriptionSuccess() {
    this.subscriptionState = 'success';
    this.subscriptionInProgress = false;
  }

  subscriptionFailure() {
    this.subscriptionState = 'error';
    this.subscriptionInProgress = false;
  }

  subscriptionCancel() {
    this.subscriptionState = null;
    this.subscriptionInProgress = false;
  }

  setSubscriptionPeriod(period) {
    this.organisation.subscriptionPeriod = period;
  }

  payInvoice(stripeInvoiceId, stripeSubscriptionId) {
    organisationStore.payInvoice({
      stripeInvoiceId: stripeInvoiceId,
      stripeSubscriptionId: stripeSubscriptionId,
    }).then(function (data) {
      actions.payInvoiceSuccess();
    }, function (error) {
      console.error(error);
      actions.payInvoiceFailure(error);
    });
  }

  payInvoiceSuccess(){

  }

  payInvoiceFailure() {

  }

  openEmailModal() {
    this.modal = "email";
  }
  openSubscriptionModal() {
    this.modal = "subscription";
  }
  openStaffPermissionsModal(staffMember) {
    this.modal = "staff";
    this.staffMember = staffMember;
  }
  closeModal() {
    this.modal = null;
    this.staffMember = null;
    this.receiptEmail = this.organisation.receiptEmail;
  }


  addPermission() {
    this.staffMember = this.staffMember.updateIn(['permissions', 'items'], addPermission);
    this._update();
  }

  setFinancialsVisibility(financialsVisibility) {
    this.staffMember = this.staffMember.setIn(['permissions', 'financialsVisibility'], financialsVisibility);
  }

  setOverallLevel(level) {
    if (this.staffMember.permissions.items.some(item => item.item !== 'everything')) {
      if (!window.confirm("This will reset this staff member's permissions. Are you sure you want to proceed?")) {
        return false;
      }
    }
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => setOverallLevel(items, level)
    );
    this._update();
  }

  setPermissionItem(rowIndex, item) {
    this.staffMember = this.staffMember.setIn(['permissions', 'items', rowIndex, 'object'], item);
    this._update();
  }

  setPermissionLevel(rowIndex, level) {
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => setPermissionLevel(items, rowIndex, level)
    );
    this._update();
  }

  deletePermission(rowIndex) {
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => deletePermission(items, rowIndex)
    );
  }

  _update() {
    if (this.staffMember.permissions.isAdmin) {
      this.staffMember = this.staffMember.setIn(['permissions', 'financialsVisibility'], FinancialsVisibility.all);
    }
  }

  setStaffFieldValue(fieldName, value) {
    this.staffMember = this.staffMember.set(fieldName, value);
    if (fieldName === "isArchived") {
      this.adjustRates();
    }
  }

  adjustRates() {
    let staff = this.staffMember;
    if (staff.isArchived && staff.weeklyAvailability !== 0) {
      this.staffMember = staff.update('payRates', function (payRates) {
        if (payRates.size && payRates.last().get('date').isSame(moment().startOf('day'))) {
          payRates = payRates.delete(payRates.size - 1)
        }
        if (payRates.size) {
          return payRates.push(payRates.last().merge({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        } else {
          return payRates.push(new PayRate({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        }
      });
    } else if (!staff.isArchived && staff.weeklyAvailability === 0) {
      this.staffMember = staff.update('payRates', function (payRates) {
        if (payRates.size && payRates.last().get('date').isSame(moment().startOf('day'))) {
          payRates = payRates.delete(payRates.size - 1)
        }
        if (payRates.size) {
          return payRates.push(payRates.last().merge({
            date: moment().startOf('day'),
            weeklyAvailability: staff.payRates.filter(p => p.get('weeklyAvailability') != 0).last()?.get('weeklyAvailability') || 0,
          }));
        } else {
          return payRates.push(new PayRate({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        }
      });
    }
  }

  staffSave() {
    let staffMember = this.staffMember;
    this.submitted = true;
    this.staffSaveOperation.execute(
		organisationStore._save(
			`/organisation/current/staff-member/${staffMember.id || ""}`,
			"staffMember",
			staffMember.serialize()
		)
	);
  }

  staffSaveSuccess(data) {
    let staffMemberData = data.objects.StaffMember[0]
    let organisationData = data.objects.Organisation
    if (staffMemberData.uuid === this.staffMember.uuid) {
      organisationStore._addObjects({ StaffMember: [staffMemberData] });
    }
    organisationStore.organisation.credit = organisationData.credit;
    organisationStore.organisation.unpaidInvoices = organisationData.unpaidInvoices;
    organisationStore.emitChanged();
    
    this.loadPage()
    this.closeModal()
  }

  staffSaveFailure(error) {
    this.saveError = true;
  }

  updateEmail(text) {
    this.receiptEmail = text;
  }

  saveEmail() {
    organisationStore.updateReceiptEmail({
      email: this.receiptEmail,
    }).then(function (data) {
      actions.saveEmailSuccess();
    }, function (error) {
      actions.saveEmailFailure(error);
    });
  }

  saveEmailSuccess() {
    this.organisation.receiptEmail = this.receiptEmail;
    this.closeModal();
  }

  saveEmailFailure(error) {
    console.error(error);
  }

}

const billingActionDefinitions = [
  { action: 'loadPage', args: [] },
  { action: 'updateCard', args: [] },
  { action: 'cardSuccess', args: [] },
  { action: 'cardFailure', args: [] },
  { action: 'cardCancel', args: [] },
  { action: 'subscribe', args: [] },
  { action: 'subscriptionSuccess', args: [] },
  { action: 'subscriptionFailure', args: [] },
  { action: 'subscriptionCancel', args: [] },
  { action: 'setSubscriptionPeriod', args: ["period"] },
  { action: 'payInvoice', args: ["stripeInvoiceId", "stripeSubscriptionId"] },
  { action: 'payInvoiceSuccess', args: [] },
  { action: 'payInvoiceFailure', args: [] },
  { action: 'openEmailModal', args: [] },
  { action: 'openSubscriptionModal', args: [] },
  { action: 'openStaffPermissionsModal', args: ["staffMember"] },
  { action: 'closeModal', args: [] },
  { action: 'addPermission', args: [] },
  { action: 'setFinancialsVisibility', args: ['value'] },
  { action: 'setOverallLevel', args: ['level'] },
  { action: 'setPermissionItem', args: ['rowIndex', 'item'] },
  { action: 'setPermissionLevel', args: ['rowIndex', 'level'] },
  { action: 'deletePermission', args: ['rowIndex'] },
  { action: 'setStaffFieldValue', args: ['fieldName', 'value'] },
  { action: 'staffSave', args: [] },
  { action: 'staffSaveSuccess', args: ['data'] },
  { action: 'staffSaveFailure', args: ['error'] },
  { action: 'updateEmail', args: ['text'] },
  { action: 'saveEmail', args: [] },
  { action: 'saveEmailSuccess', args: [] },
  { action: 'saveEmailFailure', args: ['error'] },
];

export const actions = registerActions("billing-page", billingActionDefinitions, dispatcher);

export var billingPageStore = new BillingPageStore();






function addPermission(permissions) {
  return permissions.push(new PermissionItem({
    object: new PermissionObject({ item: 'everything' }),
    level: 'view'
  }));
}

function setOverallLevel(_permissions, level) {
  return Immutable.List([
    new PermissionItem({
      object: new PermissionObject({ item: 'everything' }),
      level: level
    })
  ]);
}

function setPermissionLevel(permissions, rowIndex, level) {
  return permissions.setIn([rowIndex, 'level'], level);
}

function deletePermission(permissions, rowIndex) {
  return permissions.remove(rowIndex);
}

const permissionLevels = [
  {
    value: 'admin',
    label: 'Admin',
    description: "This staff member has full access to all Coincraft functionality for your organisation."
  },
  {
    value: 'viewer',
    label: 'Organisation-wide read-only access',
    description: "This staff member can view all your organisation's project data " +
      "and timesheet reports, including financials, but cannot edit any data except their " +
      "own timesheets."
  },
  {
    value: 'project',
    label: 'Project-specific permissions',
    description: "You can assign this staff member to be able to view or edit specific projects " +
      "and/or cost centres."
  },
  {
    value: 'timesheet',
    label: "Timesheet user",
    description: "This staff member can only view and enter their own timesheets."
  }
];
