import moment from 'moment';
import _ from 'underscore';
import { DataObject } from './dataobject.js';
import { formatCurrency, formatCurrencyWithCents, abbreviateNumber } from '../utils.js';
import { dateConverter } from './dateconverter.js';


export const Organisation = class extends DataObject {
			constructor(options) {
				let accounting = require("../invoices/accounting.js");
				super({
					...options,
					accountingSystemSettings: _.mapObject(
						options.accountingSystemSettings,
						function(v, k) {
							return new accounting.accountingSystemTypeLookup[k](
								v || {}
							);
						}
					)
				});
			}

			static getClassName() {
				return "Organisation";
			}

			defaults() {
				return {
					id: null,
					name: "",
					defaultRevenueForecastReport: null,
					defaultResourceScheduleReport: null,
					startedTrialOn: null,

					hasSubscription: false,
					useStripe: true,
					trialEndsOn: null,
					credit: null,
					receiptEmail: null,
					cardBrand: null,
					cardLast4: null,
					cardExpDate: null,
					monthlyStandardPlan: null,
					monthlyAdminPlan: null,
					yearlyStandardPlan: null,
					yearlyAdminPlan: null,
					monthlyCoupon: null,
					yearlyCoupon: null,
					subscriptionPeriod: "yearly",
					taxPercent: 0,
					nextPaymentDate: null,
					unpaidInvoices: [],

					accountingSystem: "none",
					accountingSystemSettings: {},
					accountingSystemCredentials: {},
					settings: {},
					country: null
				};
			}

			static fieldTypes() {
				return {
					id: "int",
					name: "string",
					defaultRevenueForecastReport: "string",
					defaultResourceScheduleReport: "string",

					hasSubscription: "bool",
					useStripe: "bool",
					startedTrialOn: "date",
					trialEndsOn: "date",
					credit: "number",
					receiptEmail: "string",
					cardBrand: "string",
					cardLast4: "int",
					cardExpDate: "date",
					monthlyStandardPlan: "dict",
					monthlyAdminPlan: "dict",
					yearlyStandardPlan: "dict",
					yearlyAdminPlan: "dict",
					monthlyCoupon: "dict",
					yearlyCoupon: "dict",
					subscriptionPeriod: "string",
					taxPercent: "number",
					nextPaymentDate: "date",
					unpaidInvoices: "array",

					accountingSystem: "string",
					accountingSystemSettings: "dict",
					accountingSystemCredentials: "dict",
					settings: "dict",
					country: "string"
				};
			}

			get adminPlan() {
				if (this.subscriptionPeriod == "yearly") {
					return this.yearlyAdminPlan;
				} else {
					return this.monthlyAdminPlan;
				}
			}

			get standardPlan() {
				if (this.subscriptionPeriod == "yearly") {
					return this.yearlyStandardPlan;
				} else {
					return this.monthlyStandardPlan;
				}
			}

			get coupon() {
				if (this.subscriptionPeriod == "yearly") {
					return this.yearlyCoupon
						? this.yearlyCoupon
						: { percent_discount: 0 };
				} else {
					return this.monthlyCoupon
						? this.monthlyCoupon
						: { percent_discount: 0 };
				}
			}

			getDiscountCutoffDate() {
				if (this.discountCutoffDate != null) {
					return this.discountCutoffDate;
				} else if (this.startedTrialOn != null) {
					return this.startedTrialOn.clone().add(7, "days");
				} else {
					throw new Error(
						"Organisation without subscription and without trial"
					);
				}
			}

			getTrialEndDate() {
				if (this.trialEndsOn != null) {
					return this.trialEndsOn;
				} else if (this.startedTrialOn != null) {
					return this.startedTrialOn.clone().add(14, "days");
				} else {
					throw new Error(
						"Organisation without subscription and without trial"
					);
				}
			}

			hasTrialExpired() {
				if (this.hasSubscription) {
					return false;
				}
				return this.getTrialEndDate().isBefore(moment().startOf("day"));
			}

			hasCreditCardExpired() {
				if (!this.cardExpDate) {
					return false;
				}
				return this.cardExpDate.isBefore(moment().startOf("day"));
			}

			willCreditCardExpireInLessThanAMonth() {
				if (!this.cardExpDate) {
					return false;
				}
				return (
					this.cardExpDate.isBefore(
						moment()
							.startOf("day")
							.add(1, "months")
					) && !this.hasCreditCardExpired()
				);
			}

			get invoicesDue() {
				return this.unpaidInvoices.filter(
					inv =>
						(inv.stripe_invoice_id != "proration" ||
							inv.amount >= 50) &&
						inv.date
				);
			}

			getEarliestUnpaidInvoiceDate() {
				if (!this.invoicesDue.length > 0) {
					return null;
				} else {
					return dateConverter.intToMoment(
						_.min(
							this.invoicesDue.map(inv =>
								dateConverter.stringToInt(inv.date)
							)
						)
					);
				}
			}

			hasUnpaidInvoices() {
				return this.invoicesDue.length > 0;
			}

			hasOverdueInvoices() {
				if (!this.getEarliestUnpaidInvoiceDate()) {
					return false;
				}
				return this.getEarliestUnpaidInvoiceDate().isBefore(
					moment().startOf("day")
				);
			}

			isAccountLocked() {
				//TODO - add unpaid invoices
				return this.hasTrialExpired() || this.hasOverdueInvoices();
			}

			getAccountingSystemByIdentifier(identifier) {
				return this.accountingSystemSettings[identifier];
			}

			get currencyFormatter() {
				//TODO-currencies
				if (this.country === "gb") {
					return poundFormatter;
				} else {
					return dollarFormatter;
				}
			}

			get currencySymbol() {
				return this.currencyFormatter.symbol;
			}
		};


class CurrencyFormatter {
  constructor(symbol, fontAwesomeClass) {
    this.symbol = symbol;
    this.fontAwesomeClass = fontAwesomeClass;
  }

  format(amount, {ifNull = ""} = {}) {
    if (amount !== null) {
      return (amount < 0 ? "-" : "") + this.symbol + formatCurrency(Math.abs(amount));
    } else {
      return ifNull;
    }
  }

  formatWithCents(amount) {
    return (amount < 0 ? "-" : "") + this.symbol + formatCurrencyWithCents(Math.abs(amount));
  }

  formatAbbreviated(amount) {
    return (amount < 0 ? "-" : "") + this.symbol + abbreviateNumber(Math.abs(amount));
  }
}


const dollarFormatter = new CurrencyFormatter('$', 'fa-usd');
const poundFormatter = new CurrencyFormatter('£', 'fa-gbp');
