import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { VerticalAlign } from '../utils.js';

import PropTypes from "prop-types";

export var TriStateSaveButton = CreateReactClass({
  propTypes: {
    state: PropTypes.oneOf([
      null,
      'saving', 'executing', // Equivalent
      'saved', 'success', // Equivalent
      'failed', 'error' // Equivalent
    ]),

    // Small, medium, or large. Large buttons are green and have a floppy disk
    // icon.
    size: PropTypes.oneOf(['sm', 'md', 'lg']),

    hasFloppyDisk: PropTypes.bool,
    isSuccessButton: PropTypes.bool,

    text: PropTypes.string,

    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      text: 'Save',
      size: 'md',
      disabled: false
    };
  },

  render: function() {
    let {
      state,
      className,
      style,
      size,
      text,
      hasFloppyDisk,
      disabled: _disabled,
      isSuccessButton: _isSuccessButton,
      ...props
    } = this.props;

    const buttonSizeClass = 'btn-' + size;
    const isSuccessButton = (_isSuccessButton != null ? _isSuccessButton : (size === 'lg'));
    const buttonDefaultClass = isSuccessButton ?  'btn-success' : 'btn-default';
    const disabled = (state === 'saving' || state === 'executing' || _disabled);

    text = (hasFloppyDisk != null ? hasFloppyDisk : (size === 'lg')) ?
      <span>
        <i className="fa fa-floppy-o"></i>
        {this.props.text}
      </span>
    : this.props.text;

    return <div style={{position: 'relative', display: 'inline-block', ...style}}>
      <button
          className={classNames(
            "btn",
            "save-button",
            (state === 'saved' || state === 'success') ? 'btn-success' : buttonDefaultClass,
            buttonSizeClass,
            className
          )}
          disabled={disabled}
          style={{textAlign: 'center', position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}
          {...props}>
        {(state == null || state === 'failed' || state === 'error') ? text : null}
      </button>
      {/**
        The button's size is determined by the text inside it, but we hide the
        text when we're in 'saving' and 'saved' states and when we do that we
        don't want the button to change size.  So we have this hidden item
        (which is itself a button to make sure we have the same padding etc. as
        the actual button, which always takes up the right amount of space, which
        gives the containing div its size, then the actual button uses
        `position: absolute` to cover the container's space.
      */}
      <button style={{visibility: 'hidden'}} className={classNames('btn', buttonSizeClass)}>
        {text}
      </button>
      {state != null && state !== 'failed' && state !== 'error' ?
        <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              textAlign: 'center'
            }}>
          <VerticalAlign align="middle">
            <i
              className={classNames("fa", (state === 'saving' || state === 'executing') ? 'fa-spinner fa-spin' : 'fa-check')}
              style={{
                margin: 0,
                color: (state === 'saved' || state === 'success' || isSuccessButton) ? 'white' : null
              }}
            />
          </VerticalAlign>
        </div>
      : null}
    </div>;
  }
});


