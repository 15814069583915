import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CreateReactClass from "create-react-class";

export const Checkbox = CreateReactClass({
			propTypes: {
				value: PropTypes.bool.isRequired,
				label: PropTypes.any,
				onChange: PropTypes.func.isRequired
			},

			render: function() {
				return (
					<div
						className="time-entry-switch"
						onClick={this.handleClick}
					>
						<i
							className={classNames(
								"fa fa-fw",
								this.props.value
									? "fa-check-square"
									: "fa-square-o"
							)}
						/>
						{this.props.label}
					</div>
				);
			},

			handleClick: function() {
				this.props.onChange(!this.props.value);
			}
		});

