import { enumerate } from '../utils.js';
import { getProjectPhases } from '../widgets/ProjectPhaseSelector.js';


export const UNCHANGED = null;
export const NONE = -1;


export const TimesheetEntryStore = class {
  constructor({
        projects,
        entries = null,      // batch edit
        row = null,          // edit entry
        projectPhase = null, // optional if new entry
        organisationStore
      }) {

    this.organisationStore = organisationStore;
    this.row = row;
    this.projects = projects;
    this.entries = entries;

    this.project = null;
    this.projectPhase = null;
    this.task = null;
    this.isBillable = null;
    this.isVariation = null;
    this.isOvertime = null;
    this.isLocked = null;
    this.beenInvoiced = null;

    this.projectOptions = projects
		.filter(p => 
			this.organisationStore.organisation.settings.timeEntryStatus.includes(
				p.status
			)
		)
		.map(function(project) {
			return {
				label: project.getTitle(),
				value: project
			};
		});

    if (this.isBatchEdit) {
      this.project = UNCHANGED;
      this.projectPhase = UNCHANGED;
      this.task = UNCHANGED;
      this.isBillable = UNCHANGED;
      this.isVariation = UNCHANGED;
      this.isOvertime = UNCHANGED;
      this.isLocked = UNCHANGED;
      this.beenInvoiced = UNCHANGED;

      this.firstProject = entries[0].project;
      this.multipleProjects = false;
      this.firstPhase = entries[0].projectPhase;
      this.multiplePhases = false;
      this.firstTask = entries[0].task;

      this.multipleTasks = false;

      for (let [i, entry] of enumerate(entries)) {
        if (i > 0) {
          if (entry.project !== this.firstProject) {
            this.multipleProjects = true;
            this.multiplePhases = true;
            this.multipleTasks = true;
            break;
          }
          if (entry.projectPhase !== this.firstPhase) {
            this.multiplePhases = true;
            this.multipleTasks = true;
          }
          if (entry.task !== this.firstTask) {
            this.multipleTasks = true;
          }
        }
      }

      if (this.multipleProjects) {
        this.projectOptions = [
          {label: "Multiple projects (leave unchanged)", value: null},
          ...this.projectOptions
        ];
      }
      else {
        this.projectOptions = [
          {label: `${this.firstProject ? this.firstProject.getTitle() : 'No Project'} (leave unchanged)`, value: null},
        ...this.projectOptions.filter(o => o.value == null || (o.value && this.firstProject ? o.value.id !== this.firstProject.id : true))
        ];
      }
    }
    else if (this.isEditEntry) {
      this.project = row.get('project');
      this.projectPhase = row.get('projectPhase');
      this.task = row.get('task');
      this.isBillable = row.get('isBillable');
      this.isVariation = row.get('isVariation');
      this.isOvertime = row.get('isOvertime');
      this.isLocked = row.get('isLocked');
      this.beenInvoiced = row.get('beenInvoiced');
    }
    else if (this.isNewEntry) {
      if (projectPhase != null) {
        const task = (projectPhase != null) ? projectPhase.defaultTask : null;
        this.project = projectPhase.project;
        this.projectPhase = projectPhase;
        this.task = task;
        this.isBillable = task != null ? task.isBillable : true;
        this.isVariation = task != null ? task.isVariation : false;
        this.isOvertime = false;
        this.isLocked = false;
        this.beenInvoiced = false;
      }
      else {
        this.project = null;
        this.projectPhase = null;
        this.task = null;
        this.isBillable = true;
        this.isVariation = false;
        this.isOvertime = false;
        this.isLocked = false;
        this.beenInvoiced = false;
      }
    }
  }

  get isBatchEdit() {
    return this.entries != null;
  }

  get isEditEntry() {
    return this.row != null;
  }

  get isNewEntry() {
    return this.entries == null && this.row == null;
  }

  selectProject(project) {
    this.project = project;
    if (project === UNCHANGED) {
      this.projectPhase = UNCHANGED;
      this.task = UNCHANGED;
    }
    else {
      this.projectPhase = NONE;
      this.task = NONE;
      if (!this.isBatchEdit) {
        this.isBillable = project.costCentre.isBillable;
      }
    }
  }

  selectPhase(phase) {
    this.projectPhase = phase;
    if (phase === UNCHANGED) {
      this.task = UNCHANGED;
      this.isBillable = UNCHANGED;
      this.isVariation = UNCHANGED;
    }
    else if (phase === NONE) {
      this.task = NONE;
    }
    else {
      this.task = phase.defaultTask;
      if (!this.isBatchEdit) {
        this.isBillable = phase.defaultTask.isBillable;
        this.isVariation = phase.defaultTask.isVariation;
      }
    }
  }

  selectTask(task) {
    if (task === UNCHANGED) {
      this.task = UNCHANGED;
      this.isBillable = UNCHANGED;
      this.isVariation = UNCHANGED;
    }
    else {
      this.task = task;
      this.isBillable = task.isBillable;
      this.isVariation = task.isVariation;
    }
  }

  setIsBillable(isBillable) {
    this.isBillable = isBillable;
  }

  setIsVariation(isVariation) {
    this.isVariation = isVariation;
  }

  setIsOvertime(isOvertime) {
    this.isOvertime = isOvertime;
  }

  setIsLocked(isLocked) {
    this.isLocked = isLocked;
  }

  setBeenInvoiced(beenInvoiced) {
    this.beenInvoiced = beenInvoiced;
  }

  getPhaseOptions() {
    if (this.project == null && this.firstProject == null) {
      return [];
    }
    else {
      let options;
      if (this.multipleProjects && this.project === UNCHANGED) {
        options = [];
      }
      else {
        options = getProjectPhases({
			project: this.project || this.firstProject,
			allowNull: this.organisationStore.organisation.settings
				.allowNoPhase,
			organisationStore: this.organisationStore,
			filters: [
				p =>
					this.organisationStore.organisation.settings.timeEntryStatus.includes(
						p.status
					)
			]
		});
        for (let option of options) {
          if (option.value == null) {
            option.value = -1;
          }
        }
      }

      if (this.isBatchEdit && this.multiplePhases && this.project === UNCHANGED) {
        options = [
          {
            label: "Multiple phases",
            value: 'multiple'
          },
          ...options
        ];
      }

      if (this.isBatchEdit && this.project == null) {
        const initialValue = (this.multiplePhases) ? 'multiple'
          : (this.firstPhase == null) ? -1
          : this.firstPhase;

        for (let o of options) {
          if (o.value === initialValue) {
            o.value = UNCHANGED;
            o.label += " (leave unchanged)";
          }
        }
      }

      return options;
    }
  }

  getTaskOptions() {
    let options = [];

    if ((this.projectPhase == null && this.firstPhase == null) || this.projectPhase === -1) {
      options = [
        {
          label: "(No task)",
          value: null
        }
      ];
    }
    else {
      if (this.projectPhase != null || !this.multiplePhases) {
        options = (this.projectPhase || this.firstPhase).getVisibleTasks().map(function(task) {
          return {
            label: task.name,
            value: task
          };
        }).toArray();
      }

      if (this.projectPhase === UNCHANGED && this.multipleTasks) {
        options = [
          {
            label: "Multiple tasks",
            value: 'multiple'
          },
          ...options
        ];
      }
    }

    if (this.isBatchEdit) {
      const initialValue = (this.multipleTasks) ? 'multiple' : this.firstTask;

      for (let o of options) {
        if (this.projectPhase == null && o.value === initialValue) {
          o.value = UNCHANGED;
          o.label += " (leave unchanged)";
        }
        else if (o.value == null) {
          o.value = -1;
        }
      }
    }

    return options;
  }

  getState() {
    return {
      project: this.project,
      projectPhase: this.projectPhase,
      task: this.task,
      isBillable: this.isBillable,
      isVariation: this.isVariation,
      isOvertime: this.isOvertime,
      isLocked: this.isLocked,
      beenInvoiced: this.beenInvoiced,

      projectOptions: this.projectOptions,
      phaseOptions: this.getPhaseOptions(),

      taskOptions: this.getTaskOptions()
    };
  }
}
