import React from 'react';
import CreateReactClass from 'create-react-class';
import { TweenMax, Cubic } from 'gsap/TweenMax';
import TransitionGroup from 'react-addons-transition-group';
import { findDOMNode } from 'react-dom';

import PropTypes from "prop-types";

export var SlideUpDown = CreateReactClass({
  getInitialState: function() {
    return {
      overflow: this.props.isVisible ? null : 'hidden'
    };
  },

  render: function() {
    return <div style={{overflow: this.state.overflow}}>
      <TransitionGroup>
        {this.props.isVisible ?
          <SlideUpDownContent
              onEnterFinished={() => this.setState({overflow: 'visible'})}
              onEnterStarted={() => this.setState({overflow: 'hidden'})}
              onLeaveStart={() => this.setState({overflow: 'hidden'})}>
            {this.props.children}
          </SlideUpDownContent>
        : null}
      </TransitionGroup>
    </div>;
  }
});


export var SlideUpDownContent = CreateReactClass({
  propTypes: {
    onEnterStarted: PropTypes.func,
    onEnterFinished: PropTypes.func,
    onLeaveStarted: PropTypes.func,
    onLeaveFinished: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      onEnterStarted: () => null,
      onEnterFinished: () => null,
      onLeaveStarted: () => null,
      onLeaveFinished: () => null
    };
  },

  componentWillEnter: function(callback) {
    let self = this;
    this.props.onEnterStarted();

    /**
     * For some reason using marginTop for enter works better but using height for leave works better.
     */
    TweenMax.fromTo(
      findDOMNode(this),
      0.22,
      {
        marginTop: '-100%'
      },
      {
        marginTop: 0,
        ease: Cubic.easeOut,
        onComplete: function() {
          callback();
          self.props.onEnterFinished();
        }
      }
    );
  },

  componentWillLeave: function(callback) {
    let self = this;
    this.props.onLeaveStart();
    TweenMax.fromTo(
      findDOMNode(this),
      0.22,
      {height: 'auto'},
      {
        height: 0,
        ease: Cubic.easeOut,
        onComplete: function() {
          callback();
          self.props.onLeaveFinished();
        }
      }
    );
  },

  render: function() {
    return <div>
      {this.props.children}
    </div>;
  }
});



