import _ from 'underscore';
import moment from 'moment';
import { organisationStore } from "../organisation.js";
import { StaffRole, Rate } from '../models/staffRole.js';
import { PermissionItem, PermissionObject, FinancialsVisibility } from '../models/permissions.js';
import { isNotBlank, isEmail, isNumericOrBlank } from '../utils.js';
import { StoreBase, dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import { jsonHttp } from '../jsonHttp.js';
import { ReportStore, applyTimesheetCacheRequirement, isDataChangedAction } from '../reports/flux.js';
import { Column } from '../table.js';
import { router } from '../router.js';
import { Report } from '../reports/Report.js';
import { AjaxOperation2 } from '../AjaxOperation.js';
import Immutable from 'immutable';


const staffRoleActionDefinitions = [
  {action: 'loadPage', args: ['staffRoleId']},

  {action: 'selectTab', args: ['tabName']},

  {action: 'addRate', args: []},
  {action: 'setRateField', args: ['uuid', 'field', 'value']},
  {action: 'deleteRate', args: ['uuid']},

  {action: 'save', args: []},
  {action: 'setFieldValue', args: ['fieldName', 'value']},
  {action: 'changeRoleName', args: ['name']},

];

export const actions = registerActions("staff-role-page", staffRoleActionDefinitions, dispatcher);


class StaffRoleStore {
  constructor() {
    this.path = "staff-role-page";

    this.staffRole = null;
    this.submitted = false;
    this.errors = {};
    this.isValid = null;
    this.isDirty = false;

    this.saveOperation = new AjaxOperation2(this.path + '/save');

    this.stores = {
      'save': this.saveOperation
    };

    this.selectedTabName = 'details';
    this.saveError = null;

    this.modals = [];

    this.actionDefinitions = staffRoleActionDefinitions;
  }

  handle(action) {
    if (action.path === this.path + '/save') {
      this.saveOperation.handle(action);
      if (action.type === "ajax/success") {
        console.log(action.data);
        this.saveSuccess(action.data.staffRole);
      }
    }
    else {
      handleAction(action, this);
    }
  }

  _setStaffRole(staffRole) {
    this.staffRole = staffRole
  }

  loadPage(staffRoleId) {
    if (staffRoleId === 'new') {
      this._setStaffRole(new StaffRole({ avgPayRate: false, avgOvertimeRate: false, avgCostRate: false, avgChargeOutRate: false}));
    }
    else {
      this._setStaffRole(organisationStore.getStaffRoleById(parseInt(staffRoleId)));
    }
    this.isValid = true;
    this.saveError = null;
    this.selectedTabName = 'details';
  }

  getIsDirty() {
    return this.isDirty;
  };

  save() {
    let self = this;

    this.submitted = true;

    if (this.isValid) {
      this.saveOperation.execute(
			organisationStore._save(
				`/organisation/current/staff-roles/${this.staffRole.id || ""}`,
				"staffRole",
				{ ...this.staffRole.serialize() }
			)
		);
    }

  }

  changeRoleName(name) {
    this.staffRole = this.staffRole.set("name", name);
    this.isDirty = true;
  }

  setFieldValue(fieldName, value) {
    this.staffRole = this.staffRole.set(fieldName, value);
    this.isDirty = true;
    this._validate();
  }

  saveSuccess(staffRoleData) {
    this.isDirty = false;
    this.submitted = false;
    this.saveError = false;
    organisationStore._addObjects({StaffRole: [staffRoleData]});
    this._setStaffRole(organisationStore.getStaffRoleById(staffRoleData.id))
  }

  saveFailure(error) {
    if (error === 'email_already_exists') {
      this.saveError = 'email_already_exists';
    }
    else {
      this.saveError = true;
    }
  }


  selectTab(tabName) {
    this.selectedTabName = tabName;
  }

  addRate() {
    this.staffRole = this.staffRole.update('rates', function(rates) {
      let lastRate = rates.last();
      return rates.push(new Rate({
        date: lastRate ? lastRate.date : moment("2000-01-01"),
        payRate: lastRate ? lastRate.payRate : 0,
        overtimeRate: lastRate ? lastRate.overtimeRate : 0,
        costRate: lastRate ? lastRate.costRate : 0,
        chargeOutRate: lastRate ? lastRate.chargeOutRate : 0,
        roleRate: true
      }));
    });
    this.isDirty = true;
  }

  setRateField(uuid, field, value) {
    const val = _.isString(value) ? parseFloat(value) : value;
    const rateIndex = this.staffRole.rates.findIndex(rr => rr.uuid === uuid);
    this.staffRole = this.staffRole.setIn(['rates', rateIndex, field], val);
    this.isDirty = true;
  }

  deleteRate(uuid) {
    const rateIndex = this.staffRole.rates.findIndex(rr => rr.uuid === uuid);
    this.staffRole = this.staffRole.deleteIn(['rates', rateIndex]);
    this.isDirty = true;
  }

  _validate() {
    // let self = this;
    //
    // const validators = {
    //   firstName: isNotBlank,
    //   lastName: isNotBlank,
    //   email: isEmail,
    //   payRate: isNumericOrBlank,
    //   chargeOutRate: isNumericOrBlank,
    //   weeklyAvailability: isNumericOrBlank,
    //   minutesPerWeek: isNumericOrBlank
    // };
    //
    // this.errors = {};
    // this.isValid = true;
    // _.each(validators, function(func, prop) {
    //   var isFieldValid = func(self.staffRole[prop]);
    //   if (!isFieldValid) {
    //     self.errors[prop] = true;
    //     self.isValid = false;
    //   }
    // });
  }
}


export var staffRoleStore = new StaffRoleStore();
