import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from "../organisation.js";
import { authenticationStore } from '../authenticationService.js';
import { EditItemControls, RadioButton, DateValue, NumberInputContainer, SmallDeleteButton,
  CurrencyValue, HoursInput, TriStateSaveButton, IntercomMessageLink, BasicMySelect2,
  ErrorAlert, SuccessAlert, CostCentreSelector, SaveBar, ConfirmableDeleteButton, Checkbox } from '../widgets.js';
import { ErrorPopover, isEmail } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { Table, Column } from '../table.js';
import { FormSection, FormRow, FormLabel } from '../forms.js';
import { BlockTabs } from '../widgets/tabs.js';
import { staffStore, actions } from './flux.js';
import { PermissionsEditor } from './PermissionsEditor.js';
import { Modal, ModalContent } from '../modal.js';
import { rootStore } from '../RootStore.js';
import { wrapUserStore } from '../user/utils.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { TimesheetStore } from '../timesheets/flux.js';
import PropTypes from "prop-types";


const labelWidth = '10em';
const elevioIds = {
  payRate: "89327",
  costRate: "89328",
  chargeOutRate: "89329"
};


export var StaffMemberForm = wrapUserStore(requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: permissions.canEditStaff
  },
  CreateReactClass({
    propTypes: {
      staffMember: PropTypes.object.isRequired,
      onDismiss: PropTypes.func
    },

    mixins: [
      makeMultipleStoreMixin([rootStore, organisationStore], function() {
        if (this.state != null && organisationStore.hasDeletedObject(this.state.staffMember)) {
          this.props.onDismiss();
        }

        return {
          staffMember: staffStore.staffMember,
          saveState: staffStore.saveOperation.state,
          timesheetSaveState: staffStore.timesheetSaveState,
          submitted: staffStore.submitted,
          errors: staffStore.errors,
          payRate: staffStore.payRate,
          overtimeRate: staffStore.overtimeRate,
          costRate: staffStore.costRate,
          chargeOutRate: staffStore.chargeOutRate,
          selectedTabName: staffStore.selectedTabName,
          showTimesheetsTab: staffStore.staffMember.id != null,
          timesheetStore: staffStore.timesheetStore,
          saveError: staffStore.saveError,
          modals: staffStore.modals,
          resendInviteState: staffStore.resendInviteState,
          staffRoles: organisationStore.staffRoles,
          isDirty: staffStore.isDirty,
        };
      }),
      LinkedStateMixin
    ],

    getDefaultProps: function() {
      return {
        onDismiss: function() { }
      };
    },

    _errorFor: function(fieldName, message) {
      if (this.state.submitted && this.state.errors[fieldName]) {
        return <ErrorPopover orientation="right" message={message} />;
      }
      else {
        return null;
      }
    },

    render: function() {
      return React.createElement(this.props.template, this.getElements());
    },

    getElements: function() {
      let self = this;
      return {
        className: 'staff-member-component',
        heading: this.state.staffMember.id != null ? this.state.staffMember.getFullName() : 'New Staff Member',
        rightButtons: <div>
          {self.props.isEditable && self.state.staffMember.id && !self.state.staffMember.isAdmin() && self.state.staffMember.id !== authenticationStore.getUser().id ?
            <ConfirmableDeleteButton
              object={this.state.staffMember}
              objectTypeName={"Staff Member"}
              text={"Delete"}
              popupBelow={true}
            />
          : null}
        </div>,
        tabs: <BlockTabs
          value={this.state.selectedTabName}
          onChange={this.handleTabChange}
          tabs={[
            {
              label: "Details",
              value: "details",
              props: {className: "staff-member-component__details-tab"}
            },
            this.state.staffMember.payRates.size > 0 ?
              {
                label: "Rates",
                value: "rates",
                props: {className: "staff-member-component__rates-tab"}
              }
            : null,
            this.state.staffMember.hasLogin ? 
              {
                label: "Permissions",
                value: "permissions",
                props: {className: "staff-member-component__permissions-tab"}
              }
            : null
          ]}
        />,
        body: <div>
          {this.state.modals.map(function(modal, i) {
            return <Modal key={i}>
              {modal.type === 'resendInvite' ?
                <ModalContent header="Resend invite" width="40em">
                  <p>
                    {`This will send ${self.state.staffMember.getFullName()} an email to `}
                    <strong>{self.state.staffMember.email}</strong> with a link that will
                    let them access their CoinCraft account.
                  </p>

                  {self.state.resendInviteState === 'error' ?
                    <ErrorAlert>
                      There was a problem resending the invite. You can try again,
                      or if the problem persists, <IntercomMessageLink label="chat with us" />.
                    </ErrorAlert>
                  : self.state.resendInviteState === 'sent' ?
                    <SuccessAlert>
                      Invite sent successfully.
                      <button
                          onClick={() => actions.resendInviteClose(modal)}
                          className="btn btn-default"
                          style={{marginLeft: '1em'}}>
                        Close
                      </button>
                    </SuccessAlert>
                  :
                    <div>
                      <TriStateSaveButton
                        onClick={self.handleResendInviteConfirmButtonClick}
                        saveText="Send invite"
                        // Not actually a save button but hey it fits.
                        state={self.state.resendInviteState === 'sending' ? 'saving' : null}
                      />
                      <a
                          href="javascript:void(0)"
                          onClick={() => actions.resendInviteClose(modal)}
                          style={{marginLeft: '1em'}}>
                        Cancel
                      </a>
                    </div>
                  }
                </ModalContent>
              : null}
            </Modal>;
          })}

          {this.state.selectedTabName === 'details' ?
            this.renderDetailsTab()
          : this.state.selectedTabName === 'rates' ?
            this.renderRatesTab()
          : this.state.selectedTabName === 'permissions' ?
            this.renderPermissionsTab()
          : null}

          {/* TODO-friday_fixes_1 saveError vs nonempty errors */}
          {this.state.saveError ?
            <ErrorAlert>
              {this.state.saveError === 'email_already_exists' ?
                <p>
                  {`We couldn't save this staff member because a user with the same
                  email address already exists in Coincraft.`}
                </p>
              :
                <p>
                  There was a problem saving this staff member. You can try again,
                  or if the problem persists, <IntercomMessageLink label="chat with us" />.
                </p>
              }
            </ErrorAlert>
          : null}

          {this.state.submitted && !_.isEmpty(this.state.errors) ?
            <ErrorAlert>
              Sorry, there was a problem saving this staff member. Please try again.
            </ErrorAlert>
          : null}
        </div>,
        saveBar: this.props.isEditable ?
            <SaveBar
              unsavedChangesMessage="Your staff member has unsaved changes."
              isDirty={self.state.isDirty}
              saveState={self.state.saveState}
              onSaveClick={() => actions.save(self.state.staffMember)}
            />
          : null,
      };
    },

    renderDetailsTab: function() {
      let self = this;

      const emailError = this._errorFor('email', "Please enter the staff member's email address");

      return (
        <div>
          <div className="form-horizontal">
            <h4 style={{marginTop: 15, marginBottom: 20}}>
              Staff member details
            </h4>
            <FormSection>
              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  First name:
                </FormLabel>
                <input className="staff-member-component__first-name-input"
                  type="text"
                  value={this.state.staffMember.firstName || ''}
                  disabled={!this.props.isEditable}
                  onChange={this.handleFieldChange.bind(this, 'firstName')} />
                {this._errorFor('firstName', "Please enter the staff member's first name")}
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Last name:
                </FormLabel>
                <input className="staff-member-component__last-name-input"
                  type="text"
                  value={this.state.staffMember.lastName || ''}
                  disabled={!this.props.isEditable}
                  onChange={this.handleFieldChange.bind(this, 'lastName')} />
                {this._errorFor('lastName', "Please enter the staff member's last name")}
              </FormRow>

              <FormRow>
                <FormLabel style={{ width: labelWidth }}>
                </FormLabel>
                <Checkbox className="staff-member-component__has-login-input"
                  value={this.state.staffMember.hasLogin}
                  label="Provide Staff Member with Login?"
                  disabled={!this.props.isEditable}
                  onChange={this.handleFieldChangeValue.bind(this, 'hasLogin')} />
              </FormRow>

              <FormRow style={{ opacity: this.state.staffMember.hasLogin ? 1 :0.5}}>
                <FormLabel style={{width: labelWidth}}>
                  Email:
                </FormLabel>
                <input className="staff-member-component__email-input"
                  type="email"
                  value={this.state.staffMember.email || ''}
                  disabled={!this.props.isEditable || !this.state.staffMember.hasLogin}
                  onChange={this.handleFieldChange.bind(this, 'email')} />
                {emailError != null ?
                  emailError
                  : this.state.staffMember.id != null && this.state.staffMember.hasLogin && isEmail(this.state.staffMember.email) ?
                  <button
                      className="btn btn-sm btn-default"
                      style={{marginLeft: '1em'}}
                      onClick={this.handleResendInviteButtonClick}>
                    Resend invite...
                  </button>
                : null}
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Staff type:
                </FormLabel>
                <RadioButton
                  className="staff-member-component__employee-radio-button"
                  value={this.state.staffMember.staffType === 'employee'}
                  onSelected={() => actions.setFieldValue('staffType', 'employee')}
                  disabled={!this.props.isEditable}
                  label="Employee"
                  identifier="employee"
                />
                {' '}
                <RadioButton
                  className="staff-member-component__contractor-radio-button"
                  value={this.state.staffMember.staffType === 'contractor'}
                  onSelected={() => actions.setFieldValue('staffType', 'contractor')}
                  disabled={!this.props.isEditable}
                  label="Contractor"
                  identifier="contractor"
                />
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Staff Role:
                </FormLabel>
                <BasicMySelect2
                  options={[
                    {label: "(No Role)", value: null},
                    ...self.state.staffRoles.map(role => {
                      return {label: role.name, value: role.id}
                    })
                  ]}
                  isEditable={this.props.isEditable}
                  value={self.state.staffMember.roleId || null}
                  onChange={(roleId) => actions.setFieldValue('roleId', roleId)}
                />
              </FormRow>

              {this.renderPayRateField({
                inputClassName: 'staff-member-component__pay-rate',
                field: 'payRate',
                label: 'Pay rate:',
                description: 'pay rate'
              })}

              {this.renderPayRateField({
                inputClassName: 'staff-member-component__overtime-rate',
                field: 'overtimeRate',
                label: 'Overtime rate:',
                description: 'overtime rate'
              })}

              {this.renderPayRateField({
                inputClassName: 'staff-member-component__cost-rate',
                field: 'costRate',
                label: 'Cost rate:',
                description: 'cost rate'
              })}

              {this.renderPayRateField({
                inputClassName: 'staff-member-component__charge-out-rate',
                field: 'chargeOutRate',
                label: 'Charge-out rate:',
                description: 'charge-out rate'
              })}

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Weekly availability:
                </FormLabel>
                <HoursInput
                  className={'staff-member-component__weekly-availability-input'}
                  style={{width: '6em'}}
                  value={this.state.staffMember.weeklyAvailability}
                  onChange={this.handleFieldChangeValue.bind(this, 'weeklyAvailability')}
                  isEditable={this.props.isEditable && this.state.staffMember.payRates.size === 0}
                />
                {' hours per week '}
                {this._errorFor('weeklyAvailability', `Please enter the staff member's weekly availability`)}

                {this.state.staffMember.payRates.size > 0 ?
                  <span>
                    {' '}
                    <button
                        onClick={this.handleEditPayRateButtonClick}
                        className="btn btn-default btn-sm">
                      Edit...
                    </button>
                  </span>
                : null}
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Permission level:
                </FormLabel>
                {self.state.staffMember.permissions.getLabel()}
                {' '}
                <button onClick={self.handleEditPermissionsButtonClick} className="btn btn-default btn-sm">
                  {this.props.isEditable ? 'Edit' : 'View'}
                  {' permissions...'}
                </button>
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Status:
                </FormLabel>
                <BasicMySelect2
                  options={[
                    {label: "Active", value: false},
                    {label: "Archived", value: true}
                  ]}
                  isEditable={this.props.isEditable}
                  value={self.state.staffMember.isArchived}
                  onChange={(isArchived) => actions.setFieldValue('isArchived', isArchived)}
                />
              </FormRow>

              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Cost Centre:
                </FormLabel>
                <CostCentreSelector
                  value={self.state.staffMember.costCentre}
                  onChange={(costCentre) => actions.setFieldValue('costCentreId', costCentre.id)}
                />
              </FormRow>
            </FormSection>
          </div>
        </div>
      );
    },

    renderPayRateField({inputClassName, field, label, description}) {
      return (
        <FormRow>
          <FormLabel style={{width: labelWidth}}>
            {label}
          </FormLabel>

          <CurrencyValue
            className={inputClassName}
            style={{width: '6em'}}
            value={this.state[field]}
            onChange={this.handleFieldChange.bind(this, field)}
            isEditable={this.props.isEditable && this.state.staffMember.payRates.size === 0}
            nullReadOnlyValue="-"
          />
          {' per hour '}
          <i className="fa fa-question-circle" data-elevio-inline={elevioIds[field]} />
          {this._errorFor(field, `Please enter the staff member's ${description}`)}

          {this.state.staffMember.payRates.size > 0 ?
            <span>
              {' '}
              <button
                  onClick={this.handleEditPayRateButtonClick}
                  className="btn btn-default btn-sm">
                Edit...
              </button>
            </span>
          : null}
        </FormRow>
      );
    },

    renderRatesTab: function() {
      let self = this;
      return (
        <div className="pay-rates-table">
          <h4 style={{marginTop: 15, marginBottom: 20}}>
            Rates
          </h4>

          <Table
            //use index-1 due to settings row
            rows={[{settings:true},...this.state.staffMember.getCombinedRates()]}
            tableProps={{
              style: {
                maxWidth: '120rem'
              }
            }}
            columns={[
              new Column({
                header: "From date",
                width: '24%',
                content: function(pr, i) {
                  if (pr.settings) {
                    return <div></div>
                  } else {
                    return <DateValue
                      value={pr.date}
                      isEditable={self.props.isEditable && !pr.roleRate}
                      onChange={(date) => actions.setPayRateField(pr.uuid, 'date', date)}
                    />;
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Pay rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.payRate} />
                </span>,
                width: '19.2%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const roleExists = self.state.staffMember.role != undefined && self.state.staffMember.role != null;
                    const circularReference = roleExists && self.state.staffMember.role.avgPayRate;
                    const roleLabel = `Inherit From Role ${!roleExists ? " (Staff Has No Role)" : circularReference ? " (Circular Reference)" : ""}`;
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: roleLabel, value: true, disabled: (!roleExists || circularReference)}
                      ]}
                      isEditable={this.props.isEditable && self.state.staffMember.role && !self.state.staffMember.role.avgPayRate}
                      value={self.state.staffMember.inheritPayRate}
                      onChange={(inheritPayRate) => actions.setFieldValue('inheritPayRate', inheritPayRate)}
                    />
                  } else {
                    if (self.state.staffMember.inheritPayRate && pr.staffRate || (!self.state.staffMember.inheritPayRate && pr.roleRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.payRate}
                        isEditable={self.props.isEditable && !pr.roleRate && !self.state.staffMember.inheritPayRate}
                        onChange={(payRate) => actions.setPayRateField(pr.uuid, 'payRate', payRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Overtime rate '}
                  <i className="fa fa-question-circle" />
                </span>,
                width: '19.2%',
                content: function (pr, i) {
                  if (pr.settings) {
                    const roleExists = self.state.staffMember.role != undefined && self.state.staffMember.role != null;
                    const circularReference = roleExists && self.state.staffMember.role.avgOvertimeRate;
                    const roleLabel = `Inherit From Role ${!roleExists ? " (Staff Has No Role)" : circularReference ? " (Circular Reference)" : ""}`;
                    return <BasicMySelect2
                      options={[
                        { label: "Manual Entry", value: false },
                        { label: roleLabel, value: true, disabled: (!roleExists || circularReference) }
                      ]}
                      isEditable={this.props.isEditable && self.state.staffMember.role && !self.state.staffMember.role.avgOvertimeRate}
                      value={self.state.staffMember.inheritOvertimeRate}
                      onChange={(inheritOvertimeRate) => actions.setFieldValue('inheritOvertimeRate', inheritOvertimeRate)}
                    />
                  } else {
                    if (self.state.staffMember.inheritOvertimeRate && pr.staffRate || (!self.state.staffMember.inheritOvertimeRate && pr.roleRate)) {
                      return <div style={{ textAlign: 'right' }} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.overtimeRate}
                        isEditable={self.props.isEditable && !pr.roleRate && !self.state.staffMember.inheritOvertimeRate}
                        onChange={(overtimeRate) => actions.setPayRateField(pr.uuid, 'overtimeRate', overtimeRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Cost rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.costRate} />
                </span>,
                width: '19.2%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const roleExists = self.state.staffMember.role != undefined && self.state.staffMember.role != null;
                    const circularReference = roleExists && self.state.staffMember.role.avgCostRate;
                    const roleLabel = `Inherit From Role ${!roleExists ? " (Staff Has No Role)" : circularReference ? " (Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: roleLabel, value: true, disabled: (!roleExists || circularReference)}
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffMember.inheritCostRate}
                      onChange={(inheritCostRate) => actions.setFieldValue('inheritCostRate', inheritCostRate)}
                    />
                  } else {
                    if (self.state.staffMember.inheritCostRate && pr.staffRate || (!self.state.staffMember.inheritCostRate && pr.roleRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.costRate}
                        isEditable={self.props.isEditable && !pr.roleRate && !self.state.staffMember.inheritCostRate}
                        onChange={(costRate) => actions.setPayRateField(pr.uuid, 'costRate', costRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Charge-out rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.chargeOutRate} />
                </span>,
                width: '19.2%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const roleExists = self.state.staffMember.role != undefined && self.state.staffMember.role != null;
                    const circularReference = roleExists && self.state.staffMember.role.avgChargeOutRate;
                    const roleLabel = `Inherit From Role ${!roleExists ? " (Staff Has No Role)" : circularReference ? " (Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: roleLabel, value: true, disabled: (!roleExists || circularReference)}
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffMember.inheritChargeOutRate}
                      onChange={(inheritChargeOutRate) => actions.setFieldValue('inheritChargeOutRate', inheritChargeOutRate)}
                    />
                  } else {
                    if ((self.state.staffMember.inheritChargeOutRate && pr.staffRate) || (!self.state.staffMember.inheritChargeOutRate && pr.roleRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.chargeOutRate}
                        isEditable={self.props.isEditable && !pr.roleRate && !self.state.staffMember.inheritChargeOutRate}
                        onChange={(chargeOutRate) => actions.setPayRateField(pr.uuid, 'chargeOutRate', chargeOutRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Weekly Availability '}
                </span>,
                width: '19.2%',
                content: function(pr, i) {
                  if (pr.settings) {
                    return <div></div>
                  } else {
                    return <HoursInput
                      value={pr.weeklyAvailability}
                      onChange={(weeklyAvailability) => actions.setPayRateField(pr.uuid, 'weeklyAvailability', weeklyAvailability)}
                      isEditable={self.props.isEditable && !pr.roleRate}
                    />;
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: null,
                width: '5%',
                getCellProps: function() {
                  return {
                    style: {
                      padding: '0.6em 0.1em',
                      verticalAlign: 'middle'
                    }
                  };
                },
                content: function(pr, i) {
                  if (self.props.isEditable && !pr.roleRate && !pr.settings) {
                    return <SmallDeleteButton onClick={() => actions.deletePayRate(pr.uuid)} />;
                  }
                  else {
                    return null;
                  }
                },
                // type: null means "not sortable"
                type: null
              }),
            ]}
          />

          {this.props.isEditable ?
            <div style={{marginTop: '1em'}}>
              <button
                  className="btn btn-default add-pay-rate-button"
                  onClick={() => actions.addPayRate()}>
                + Add Rates
              </button>
            </div>
          : null}
        </div>
      );
    },

    renderPermissionsTab() {
      return (
        <div>
          <div className="form-horizontal">
            <PermissionsEditor
              permissions={this.state.staffMember.permissions}
              isEditable={this.props.isEditable}
              actions={{
                addPermission: actions.addPermission.bind(actions),
                setOverallLevel: actions.setOverallLevel.bind(actions),
                setFinancialsVisibility: actions.setFinancialsVisibility.bind(actions),
                setPermissionItem: actions.setPermissionItem.bind(actions),
                setPermissionLevel: actions.setPermissionLevel.bind(actions),
                deletePermission: actions.deletePermission.bind(actions)
              }}
            />
          </div>
        </div>
      );
    },

    handleTabChange: function(tabName) {
      actions.selectTab(tabName);
    },

    handleEditPayRateButtonClick: function() {
      actions.editPayRates();
    },

    handleEditPermissionsButtonClick: function() {
      actions.editPermissions();
    },

    handleFieldChangeValue: function(fieldName, value) {
      actions.setFieldValue(fieldName, value);
    },

    handleFieldChange: function(fieldName, event) {
      actions.setFieldValue(fieldName, event.target.value);
    },

    handleSaveButtonClick: function() {
      actions.save(this.state.staffMember);
    },

    handleResendInviteButtonClick: function() {
      actions.resendInvite();
    },

    handleResendInviteConfirmButtonClick: function() {
      actions.resendInviteConfirm();
    },

    handleResendInviteCloseButtonClick: function(modal) {
      actions.resendInviteClose(modal);
    }
  })
));


var TimesheetControlsContainer = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([TimesheetStore], function() {
      return {
        isSaveButtonDisabled: this.props.timesheetStore.getSaveStatus() === 'error'
      };
    })
  ],

  render: function() {
    return <EditItemControls
      objectTypeName={"time sheets"}
      isSaveButtonDisabled={this.state.isSaveButtonDisabled}
      isSaving={this.props.isSaving}
      object={this.props.object}
      showCancelButton={false}
      onSave={this.props.onSave}
      canDelete={this.props.canDelete}
    />;
  }
});
