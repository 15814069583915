import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from './modal.js';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import { caseInsensitiveContains } from './utils.js';
import { FilterTextBox } from './widgets/FilterTextBox.js';
import { router } from './router.js';
import { Table, Column } from './table.js';
import { organisationStore } from './organisation.js';
import { ContactForm } from './contacts/ContactForm.js';
import { ContactImportForm } from './contacts/ContactImportForm.js';
import { requiresPermission, permissions } from './models/permissions.js';
import { contactsPageStore, contactsPageActions } from './contacts/flux.js';
import { ContactFormContainer } from './contacts/ContactFormContainer.js';
import { CoincraftPage, CoincraftPageHeader } from './CoincraftPage.js';
import { accountingSystemTypeLookup } from './invoices/accounting.js';
import { rootStore } from './RootStore.js';
import { wrapUserStore } from './user/utils.js';
import PropTypes from 'prop-types';

export { ContactForm } from "./contacts/ContactForm.js";
export { contactsPageStore, contactsPageActions } from "./contacts/flux.js";
export { ContactFormContainer } from "./contacts/ContactFormContainer.js";


export var ContactsPage = requiresPermission(
  permissions.noRestrictions,
  {
    editPermission: permissions.editContacts
  },
  CreateReactClass({
    propTypes: {
      user: PropTypes.object.isRequired
    },

    mixins: [
      makeMultipleStoreMixin([rootStore, organisationStore], function() {
        return {
          contacts: organisationStore.contacts.filter(c => c.isCoincraftContact),
          contactFormStore: contactsPageStore.contactFormStore,
          isReady: organisationStore.isReady
        };
      })
    ],

    getInitialState: function() {
      return {
        filterText: ''
      };
    },

    render: function() {
      let self = this;
      if (!this.state.isReady) {
        return null;
      }

      const contacts = this.state.contacts.filter(function(contact) {
        if (self.state.filterText !== '') {
          let t = self.state.filterText;
          return caseInsensitiveContains(contact.firstName + ' ' + contact.lastName, t)
            || caseInsensitiveContains(contact.contactOrganisationName, t);
        }
        return true;
      });

      return <CoincraftPage
        className="contacts-page"
        header={<CoincraftPageHeader
          heading="Contacts"
          leftButtons={this.props.editPermission ?
            [
              <button
                  key={1}
                  className="page-header__button--primary page-header__button"
                  onClick={() => contactsPageActions.newContact()}>
                + New contact
              </button>,
              <button
                  key={2}
                  className="page-header__button--primary page-header__button"
                  onClick={() => contactsPageActions.openImportContacts()}>
                + Import from Accounting System
              </button>
            ]
          : null}
          filter={<FilterTextBox
            style={{width: '18em'}}
            value={this.state.filterText}
            onChange={text => this.setState({filterText: text})}
            placeholder="Filter by name or organisation"
          />}
        />}
        body={
          <div style={{ height: '100%', minHeight: '30em'}}>
            {this.state.contactFormStore.isOpen ?
              <Modal>
                <ModalContent
                    header={
                      this.state.contactFormStore.contact == null ? 'Importing Contacts'
                      : this.state.contactFormStore.contact.id == null ? "New contact" 
                      : "Edit contact"
                    }
                    width="60%">
                  <ContactFormContainer
                    path="contacts-page/contact-form"
                    onClose={this.handleContactFormClose}
                  />
                </ModalContent>
              </Modal>
            : null}

            <Table
              scroll={true}
              rows={contacts}
              tableProps={{
                className: "data-table",
                style: {
                  width: '95%',
                  maxWidth: '70em',
                  textAlign: 'left',
                  display: 'inline-block',
                  height: '100%'
                }
              }}
              rowProps={function(contact, i) {
                return {
                  key: contact.id,
                  style: self.props.editPermission ? {cursor: 'pointer'} : null,
                  onClick: self.props.editPermission ? (() => self.handleContactRowClick(contact)) : null
                };
              }}
              columns={[
                new Column({
                  identifier: 'lastName',
                  header: "Last name",
                  width: '15%',
                  content: contact => contact.lastName,
                  type: 'string',
                }),
                new Column({
                  header: "First name",
                  width: '15%',
                  content: contact => contact.firstName,
                  type: 'string',
                }),
                new Column({
                  header: "Organisation name",
                  width: '30%',
                  content: contact => contact.contactOrganisationName,
                  type: 'string',
                }),
                new Column({
                  header: "Notes",
                  width: '30%',
                  content: contact => contact.notes,
                  type: 'string',
                }),
                new Column({
                  header: "Source",
                  width: '10%',
                  type: 'string',
                  data: contact => contact.accountingSystemId,
                  content: function(contact, i, stack, data) {
                    if (data == null) {
                      return "Coincraft";
                    }
                    else {
                      return new (accountingSystemTypeLookup[data])().name;
                    }
                  },
                  props: {style: {textAlign: 'center'}}
                }),
              ]}
              initialSort={{
                columnIdentifier: 'lastName',
                direction: 'asc',
                isAlreadySorted: false
              }}
            />
          </div>
        }
      />;
    },

    handleContactRowClick: function(contact) {
      contactsPageActions.editContact(contact);
    },

    editContact: function(contact) {
      if (this.props.editPermission) {
        router.history.replace({pathname: `/dashboard/contacts/${contact.id}`});
      }
    },

    handleContactFormClose: function() {
      contactsPageStore.closePopup();
    }
  })
);

export var ContactsPageWrapper = wrapUserStore(ContactsPage);


