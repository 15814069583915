

import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { Modal, ModalContent } from '../modal.js';
import { TriStateSaveButton } from '../widgets.js';
import { BasicMySelect2, DateValue } from '../widgets/generic.js';
import { NumberInputContainer } from '../widgets/NumberInputContainer.js';
import { projectStore, actions } from './flux.js';



export var ConfirmSyncModal = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([projectStore], function () {
      return {
        project: projectStore.project,
        modifiedFee: projectStore.modifiedFee,
        modifiedBudget: projectStore.modifiedBudget,
        updateForecastSelection: projectStore.updateForecastSelection,
        updateBudgetValue: projectStore.updateBudgetValue,
        updateForecastStartDate: projectStore.updateForecastStartDate,
        updateForecastEndDate: projectStore.updateForecastEndDate,
        updateForecastCustomStartDate: projectStore.updateForecastCustomStartDate,
        updateForecastCustomEndDate: projectStore.updateForecastCustomEndDate,
        saveState: projectStore.saveState,
      };
    }),
  ],

  render: function() {
    const {project, modifiedFee, modifiedBudget} = this.state;
    let bothModified = modifiedFee && modifiedBudget;

    return <Modal className="sync-modal">
      <ModalContent header="Saving project" width='48em' bodyStyles={{padding: 0}}>
        {bothModified ?
          <div style={{padding: "2em"}}>
            Would you like to adjust your Project's <b>Revenue Forecast</b> &amp; <b>Resource Schedule</b> based on your new settings?
          </div>
          : modifiedFee ?
          <div style={{padding: "2em"}}>
              Would you like to adjust your Project's <b>Revenue Forecast</b> based on your new settings?
          </div>
          :
          <div style={{padding: "2em"}}>
            Would you like to adjust your Project's <b>Resource Schedule</b> based on your new settings?
          </div>
        }
        <div style={{ padding: "0 2em 2em 2em" }} className="flexbox-container flex-align-items-center">
          <span style={{display: "inline-block"}}>Adjust: </span>
          <BasicMySelect2
            style={{ margin: "0 1em" }}
            value={this.state.updateForecastSelection}
            onChange={(v) => actions.changeUpdateForecastSelection(v)}
            options={[
              ...bothModified ? [{ label: "Revenue Forecast & Resource Schedule", value: 'revenueResource' }] : [],
              ...modifiedFee ? [{ label: "Revenue Forecast", value: 'revenue' }] : [],
              ...modifiedBudget ? [{ label: "Resource Schedule", value: 'resource' }] : [],
              { label: "Nothing", value: 'nothing' },
            ]}
          />
        </div>
        <div style={{ padding: "2em", borderTop: '1px solid #eee' }} className="flexbox-container flex-align-items-center">
          <span style={{ display: 'inline-block' }}>Distribute</span>
          <div style={{ margin: "0 1em", width: '12em' }}>
            <BasicMySelect2
              style={{ width: '100%' }}
              value={this.state.updateBudgetValue}
              onChange={(v) => actions.changeUpdateBudgetValue(v)}
              options={[
                { label: "Total Budget", value: 'totalBudget' },
                { label: "Remaining Budget", value: 'remainingBudget' },
                // { label: "Custom Amount", value: 'custom' },
              ]}
            />
            {/* <NumberInputContainer
              style={{ width: '100%' }}
              className="custome_distribution_amount"
              value={0}
              onChange={(num) => null}
            /> */}
          </div>
          <span style={{display: 'inline-block'}}>between</span>
          <div style={{ margin: "0 1em", width: '9em' }}>
            <BasicMySelect2
            style={{ width: '100%' }}
              value={this.state.updateForecastStartDate}
              onChange={(d) => actions.changeUpdateForecastStartDate(d)}
              options={[
                { label: "Start Date", value: 'startDate' },
                { label: "Now", value: 'now' },
                { label: "End Date", value: 'endDate' },
                // { label: "Custom Date", value: 'custom' },
              ]}
            />
            {this.state.updateForecastStartDate === "custom" ? 
              <DateValue
                style={{ width: '100%' }}
                value={this.state.updateForecastCustomStartDate}
                onChange={(d) => actions.changeUpdateForecastCustomStartDate(d)}
              />
            : null}
          </div>
          <span style={{ display: 'inline-block' }}>>></span>
          <div style={{ margin: "0 1em", width: '9em' }}>
            <BasicMySelect2
              style={{ width: '100%' }}
              value={this.state.updateForecastEndDate}
              onChange={(d) => actions.changeUpdateForecastEndDate(d)}
              options={[
                { label: "Start Date", value: 'startDate' },
                { label: "End Date", value: 'endDate' },
                // { label: "Custom Date", value: 'custom' },
              ]}
            />
            {this.state.updateForecastEndDate === "custom" ?
              <DateValue
                style={{ width: '100%' }}
                value={this.state.updateForecastCustomEndDate}
                onChange={(d) => actions.changeUpdateForecastCustomEndDate(d)}
              />
              : null}
          </div>
        </div>
        <div style={{borderTop: '1px solid #ccc', textAlign: 'right', padding: '1em'}}>
          <a
              href="javascript:void(0)"
              onClick={this.props.onCancel}>
            Cancel
          </a>

          <TriStateSaveButton
            text="Save"
            className="btn btn-default sync-modal__save-button"
            onClick={() => this.props.onConfirm({
              updateMilestones: this.state.updateMilestones, updateAllocations: this.state.updateAllocations})}
            state={this.state.saveState}
            style={{marginLeft: '1em'}}
          />
        </div>
      </ModalContent>
    </Modal>;
  }
});
