import React from 'react';
import CreateReactClass from 'create-react-class';
import { invoicesActions } from "./flux.js";
import {
	descriptionTemplatesStore,
	actions
} from "./descriptionTemplatesStore.js";
import { BasicMySelect2 } from '../widgets/generic.js';


export var TemplateEditor = CreateReactClass({
  getInitialState: function() {
    return {
      isFocused: false,
      template: null
    };
  },

  render: function() {
    let { sourceValue, renderedValue, ...props } = this.props;

    return (
		<div style={{ position: "relative" }}>
			<div
				className="flexbox-container"
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					fontSize: "0.75em",
					fontWeight: "bold"
				}}
			>
				<BasicMySelect2
					value={this.state.template}
					onChange={template => {
						this.setState({ template: template });
						this.props.onChange({
							target: { value: template.description }
						});
					}}
					options={descriptionTemplatesStore.templates.map(t => ({
						label: `${t.name}`,
						value: t
					}))}
					placeholder={"Template..."}
				/>
				<button
					className="btn btn-default"
					style={{ padding: "0.15em 0.4em" }}
					onClick={e => {
						actions.newTemplate();
						actions.editTemplate();
						actions.changeTemplateDescription(sourceValue);
						invoicesActions.initiateEditTemplates();
					}}
				>
					<i className="fa fa-floppy-o" style={{ margin: 0 }}></i>
				</button>
			</div>
			<textarea
				rows={1}
				style={{ width: "100%", padding: "1em" }}
				value={this.state.isFocused ? sourceValue : renderedValue}
				onChange={this.props.onChange}
				onFocus={this.handleInputFocus}
				onBlur={this.handleInputBlur}
				{...props}
			/>
		</div>
	);
  },

  handleInputFocus: function(e) {
    this.setState({isFocused: true});
  },

  handleInputBlur: function() {
    this.setState({isFocused: false});
  }
});



