import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from "../organisation.js";
import { authenticationStore } from '../authenticationService.js';
import { EditItemControls, RadioButton, DateValue, NumberInputContainer, SmallDeleteButton,
  CurrencyValue, HoursInput, TriStateSaveButton, IntercomMessageLink, BasicMySelect2,
  ErrorAlert, SuccessAlert, CostCentreSelector, SaveBar, ConfirmableDeleteButton } from '../widgets.js';
import { ErrorPopover } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { Table, Column } from '../table.js';
import { FormSection, FormRow, FormLabel } from '../forms.js';
import { BlockTabs } from '../widgets/tabs.js';
import { staffRoleStore, actions } from './staffRoleStore.js';
import { Modal, ModalContent } from '../modal.js';
import { rootStore } from '../RootStore.js';
import { wrapUserStore } from '../user/utils.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import LinkedStateMixin from 'react-addons-linked-state-mixin'; 
import PropTypes from "prop-types";


const labelWidth = '10em';
const elevioIds = {
  payRate: "89327",
  costRate: "89328",
  chargeOutRate: "89329"
};


export var StaffRoleForm = wrapUserStore(requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: permissions.canEditStaff
  },
  CreateReactClass({
    propTypes: {
      staffRole: PropTypes.object.isRequired,
      onDismiss: PropTypes.func
    },

    mixins: [
      makeMultipleStoreMixin([rootStore, organisationStore], function() {
        if (this.state != null && organisationStore.hasDeletedObject(this.state.staffRole)) {
          this.props.onDismiss();
        }

        return {
          staffRole: staffRoleStore.staffRole,
          saveState: staffRoleStore.saveOperation.state,
          isDirty: staffRoleStore.isDirty,
          errors: staffRoleStore.errors,
          selectedTabName: staffRoleStore.selectedTabName,
          saveError: staffRoleStore.saveError,
          modals: staffRoleStore.modals,
        };
      }),
      LinkedStateMixin
    ],

    getDefaultProps: function() {
      return {
        onDismiss: function() { }
      };
    },

    _errorFor: function(fieldName, message) {
      if (this.state.submitted && this.state.errors[fieldName]) {
        return <ErrorPopover orientation="right" message={message} />;
      }
      else {
        return null;
      }
    },

    render: function() {
      return React.createElement(this.props.template, this.getElements());
    },

    getElements: function() {
      let self = this;
      return {
        className: 'staff-role-component',
        heading: this.state.staffRole.name ? this.state.staffRole.name : "New Staff Role",
        rightButtons: <div>
          {this.state.staffRole.id ?
            <ConfirmableDeleteButton
              object={this.state.staffRole}
              objectTypeName={"Staff Role"}
              text={"Delete"}
              popupBelow={true}
            />
          : null}
        </div>,
        tabs: <BlockTabs
          value={this.state.selectedTabName}
          onChange={this.handleTabChange}
          tabs={[
            {
              label: "Details",
              value: "details",
              props: {className: "staff-role-component__details-tab"}
            },
            // {
            //   label: "Staff",
            //   value: "staff",
            //   props: {className: "staff-role-component__staff-tab"}
            // }
          ]}
        />,
        body: <div>
          {this.state.modals.map(function(modal, i) {
            return <Modal key={i}>
              {modal.type === 'resendInvite' ?
                <ModalContent header="Resend invite" width="40em">
                  <p>
                    {`This will send ${self.state.staffMember.getFullName()} an email to `}
                    <strong>{self.state.staffMember.email}</strong> with a link that will
                    let them access their CoinCraft account.
                  </p>

                  {self.state.resendInviteState === 'error' ?
                    <ErrorAlert>
                      There was a problem resending the invite. You can try again,
                      or if the problem persists, <IntercomMessageLink label="chat with us" />.
                    </ErrorAlert>
                  : self.state.resendInviteState === 'sent' ?
                    <SuccessAlert>
                      Invite sent successfully.
                      <button
                          onClick={() => actions.resendInviteClose(modal)}
                          className="btn btn-default"
                          style={{marginLeft: '1em'}}>
                        Close
                      </button>
                    </SuccessAlert>
                  :
                    <div>
                      <TriStateSaveButton
                        onClick={self.handleResendInviteConfirmButtonClick}
                        saveText="Send invite"
                        // Not actually a save button but hey it fits.
                        state={self.state.resendInviteState === 'sending' ? 'saving' : null}
                      />
                      <a
                          href="javascript:void(0)"
                          onClick={() => actions.resendInviteClose(modal)}
                          style={{marginLeft: '1em'}}>
                        Cancel
                      </a>
                    </div>
                  }
                </ModalContent>
              : null}
            </Modal>;
          })}

          {this.state.selectedTabName === 'details' ?
            this.renderDetailsTab()
          : this.state.selectedTabName === 'staff' ?
            this.renderStaffTab()
          : null}

          {/* TODO-friday_fixes_1 saveError vs nonempty errors */}
          {this.state.saveError ?
            <ErrorAlert>
              {this.state.saveError === 'email_already_exists' ?
                <p>
                  {`We couldn't save this staff member because a user with the same
                  email address already exists in Coincraft.`}
                </p>
              :
                <p>
                  There was a problem saving this staff member. You can try again,
                  or if the problem persists, <IntercomMessageLink label="chat with us" />.
                </p>
              }
            </ErrorAlert>
          : null}

          {this.state.submitted && !_.isEmpty(this.state.errors) ?
            <ErrorAlert>
              Sorry, there was a problem saving this staff member. Please try again.
            </ErrorAlert>
          : null}
        </div>,
        saveBar:
          <SaveBar
            unsavedChangesMessage="Your role has unsaved changes."
            isDirty={this.state.isDirty}
            saveState={this.state.saveState}
            onSaveClick={() => actions.save()}
          />
      };
    },

    renderDetailsTab: function() {
      let self = this;

      return (
        <div>
          <div className="form-horizontal">
            <h4 style={{marginTop: 15, marginBottom: 20}}>
              Staff Role Details
            </h4>
            <FormSection>
              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Name:
                </FormLabel>
                <input className="staff-role-component__name-input"
                  type="text"
                  value={this.state.staffRole.name || ''}
                  disabled={!this.props.isEditable}
                  onChange={e => actions.changeRoleName(e.target.value)} />
                {this._errorFor('name', "Please enter the staff role's name")}
              </FormRow>

              {this.renderRatesTable()}

            </FormSection>
          </div>
        </div>
      );
    },

    renderRatesTable: function() {
      let self = this;

      return (
        <div className="pay-rates-table">
          <h4 style={{marginTop: 15, marginBottom: 20}}>
            Rates
          </h4>

          <Table
            rows={[{settings:true},...this.state.staffRole.getCombinedRates()]}
            tableProps={{
              style: {
                maxWidth: '100rem'
              }
            }}
            columns={[
              new Column({
                header: "From date",
                width: '23%',
                content: function(pr, i) {
                  if (pr.settings) {
                    return <div></div>
                  } else {
                    return <DateValue
                      value={pr.date}
                      isEditable={self.props.isEditable && !pr.staffRate}
                      onChange={(date) => actions.setRateField(pr.uuid, 'date', date)}
                    />;
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Pay rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.payRate} />
                </span>,
                width: '18%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const circularReference = self.state.staffRole.staff.some(sm => sm.inheritPayRate);
                    const staffLabel = `Average From Staff ${circularReference ? "(Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: staffLabel, value: true, disabled: circularReference}
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffRole.avgPayRate}
                      onChange={(avgPayRate) => actions.setFieldValue('avgPayRate', avgPayRate)}
                    />
                  } else {
                    if (self.state.staffRole.avgPayRate && pr.roleRate || (!self.state.staffRole.avgPayRate && pr.staffRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.payRate}
                        isEditable={self.props.isEditable && !pr.staffRate && !self.state.staffRole.avgPayRate}
                        onChange={(payRate) => actions.setRateField(pr.uuid, 'payRate', payRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Overtime rate '}
                  <i className="fa fa-question-circle" />
                </span>,
                width: '18%',
                content: function (pr, i) {
                  if (pr.settings) {
                    const circularReference = self.state.staffRole.staff.some(sm => sm.inheritOvertimeRate);
                    const staffLabel = `Average From Staff ${circularReference ? "(Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        { label: "Manual Entry", value: false },
                        { label: staffLabel, value: true, disabled: circularReference }
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffRole.avgOvertimeRate}
                      onChange={(avgOvertimeRate) => actions.setFieldValue('avgOvertimeRate', avgOvertimeRate)}
                    />
                  } else {
                    if (self.state.staffRole.avgOvertimeRate && pr.roleRate || (!self.state.staffRole.avgOvertimeRate && pr.staffRate)) {
                      return <div style={{ textAlign: 'right' }} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.overtimeRate}
                        isEditable={self.props.isEditable && !pr.staffRate && !self.state.staffRole.avgOvertimeRate}
                        onChange={(overtimeRate) => actions.setRateField(pr.uuid, 'overtimeRate', overtimeRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Cost rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.costRate} />
                </span>,
                width: '18%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const circularReference = self.state.staffRole.staff.some(sm => sm.inheritCostRate);
                    const staffLabel = `Average From Staff ${circularReference ? "(Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: staffLabel, value: true, disabled: circularReference}
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffRole.avgCostRate}
                      onChange={(avgCostRate) => actions.setFieldValue('avgCostRate', avgCostRate)}
                    />
                  } else {
                    if (self.state.staffRole.avgCostRate && pr.roleRate || (!self.state.staffRole.avgCostRate && pr.staffRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.costRate}
                        isEditable={self.props.isEditable && !pr.staffRate && !self.state.staffRole.avgCostRate}
                        onChange={(costRate) => actions.setRateField(pr.uuid, 'costRate', costRate)}
                      />;
                    }
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Charge-out rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.chargeOutRate} />
                </span>,
                width: '18%',
                content: function(pr, i) {
                  if (pr.settings) {
                    const circularReference = self.state.staffRole.staff.some(sm => sm.inheritChargeOutRate);
                    const staffLabel = `Average From Staff ${circularReference ? "(Circular Reference)" : ""}`
                    return <BasicMySelect2
                      options={[
                        {label: "Manual Entry", value: false},
                        {label: staffLabel, value: true, disabled: circularReference}
                      ]}
                      isEditable={this.props.isEditable}
                      value={self.state.staffRole.avgChargeOutRate}
                      onChange={(avgChargeOutRate) => actions.setFieldValue('avgChargeOutRate', avgChargeOutRate)}
                    />
                  } else {
                    if (self.state.staffRole.avgChargeOutRate && pr.roleRate || (!self.state.staffRole.avgChargeOutRate && pr.staffRate)) {
                      return <div style={{textAlign: 'right'}} >-</div>
                    } else {
                      return <NumberInputContainer
                        value={pr.chargeOutRate}
                        isEditable={self.props.isEditable && !pr.staffRate && !self.state.staffRole.avgChargeOutRate}
                        onChange={(chargeOutRate) => actions.setRateField(pr.uuid, 'chargeOutRate', chargeOutRate)}
                      />;
                    };
                  }
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: null,
                width: '5%',
                getCellProps: function() {
                  return {
                    style: {
                      padding: '0.6em 0.1em',
                      verticalAlign: 'middle'
                    }
                  };
                },
                content: function(pr, i) {
                  if (self.props.isEditable && !pr.staffRate && !pr.settings) {
                    return <SmallDeleteButton onClick={() => actions.deleteRate(pr.uuid)} />;
                  }
                  else {
                    return null;
                  }
                },
                // type: null means "not sortable"
                type: null
              }),
            ]}
          />

          <div style={{marginTop: '1em'}}>
            <button
                className="btn btn-default add-pay-rate-button"
                onClick={() => actions.addRate()}>
              + Add Rates
            </button>
          </div>
        </div>
      );
    },

    renderStaffTab: function() {
      let self = this;

      return <div>{this.state.staffRole.staff.map(sm => {
        return <div>{sm.getFullName()}</div>
      })}</div>;

    },

    handleTabChange: function(tabName) {
      actions.selectTab(tabName);
    },

    handleEditPayRateButtonClick: function() {
      actions.editPayRates();
    },

    handleEditPermissionsButtonClick: function() {
      actions.editPermissions();
    },

    handleFieldChangeValue: function(fieldName, value) {
      actions.setFieldValue(fieldName, value);
    },

    handleFieldChange: function(fieldName, event) {
      actions.setFieldValue(fieldName, event.target.value);
    },

    handleSaveButtonClick: function() {
      actions.save(this.state.staffMember);
    },

    handleResendInviteButtonClick: function() {
      actions.resendInvite();
    },

    handleResendInviteConfirmButtonClick: function() {
      actions.resendInviteConfirm();
    },

    handleResendInviteCloseButtonClick: function(modal) {
      actions.resendInviteClose(modal);
    }
  })
));
