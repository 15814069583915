import moment from "moment";
import _ from "underscore";
import { dateConverter } from "./dateconverter.js";
import { isNumber } from "../utils.js";
import { organisationStore } from "../organisation.js";
import {
	makeRecordClass,
	BooleanType,
	NumberType,
	StringType,
	DateType,
	DateTimeType,
	MapType,
	ListOf,
	UUID,
} from "./record.js";
import {
	PermissionLevel,
	PermissionItem,
	UserLevel,
	PermissionObject,
	FinancialsVisibility,
	PermissionsType,
} from "./permissions.js";
import Immutable from "immutable";
import xspans from "xspans";
import { userStore } from "../user/flux.js";
import axios from "axios";
// import LogRocket from "logrocket";
export {
	PermissionLevel,
	PermissionItem,
	UserLevel,
	PermissionObject,
	FinancialsVisibility,
	PermissionsType,
} from "./permissions.js";

window.setUserPermissions = function (level, financialsVisibility) {
	if (level === "limitedAdmin") {
		userStore.user = userStore.user.setIn(
			["permissions", "items", 0, "level"],
			"admin"
		);
		userStore.user = userStore.user.setIn(
			["permissions", "items", 0, "object"],
			new PermissionObject({
				projectId: organisationStore.projects[0].id,
			})
		);
	} else {
		userStore.user = userStore.user.setIn(
			["permissions", "items", 0, "level"],
			level
		);
		userStore.user = userStore.user.setIn(
			["permissions", "items", 0, "object"],
			new PermissionObject({ item: "everything" })
		);
	}
	userStore.user = userStore.user.setIn(
		["permissions", "financialsVisibility"],
		financialsVisibility
	);
	userStore.emitChanged();
};

export const PayRate = makeRecordClass({
	date: DateType,
	payRate: NumberType({ defaultValue: 0 }),
	overtimeRate: NumberType({ defaultValue: 0 }),
	costRate: NumberType({ defaultValue: 0 }),
	chargeOutRate: NumberType({ defaultValue: 0 }),
	weeklyAvailability: NumberType({ defaultValue: 0 }),
	staffRate: BooleanType({ defaultValue: true }),
	uuid: UUID,
});

export const StaffMember = class extends makeRecordClass({
	id: null,
	firstName: StringType,
	lastName: StringType,
	staffType: StringType({ defaultValue: "employee" }),
	organisationName: StringType,
	organisationId: NumberType,
	couponCode: StringType,
	email: StringType,
	country: StringType,
	hasSetDetails: {
		...BooleanType({ defaultValue: false }),
		serialize: null,
	},
	minutesPerWeek: NumberType({ defaultValue: 0 }),
	permissions: PermissionsType,
	isArchived: BooleanType({ defaultValue: false }),
	hasLogin: BooleanType({ defaultValue: true }),
	accountingSystemCredentials: {
		...MapType,
		serialize: null,
	},
	hasTimesheetEntries: {
		...BooleanType({ defaultValue: false }),
		serialize: null,
	},
	signedUpAt: {
		...DateTimeType,
		serialize: null,
	},
	payRates: ListOf(() => PayRate),
	elevioUserHash: StringType,
	uuid: UUID,
	roleId: NumberType({ defaultValue: null }),
	costCentreId: null,
	inheritPayRate: BooleanType,
	inheritOvertimeRate: BooleanType,
	inheritCostRate: BooleanType,
	inheritChargeOutRate: BooleanType,
	billingLevelOverride: StringType,
	monthlyHours: MapType({ defaultValue: {} }),
	confirmedAt: StringType,
}) {
	constructor(...args) {
		super(...args);
		this._cache = {};
	}

	getDefaultCostCentre() {
		let costCentres = organisationStore.costCentres;
		if (costCentres.length > 0) {
			let projectCostCentres = costCentres.filter(
				(cc) => cc.name === "Staff"
			);
			if (projectCostCentres.length > 0) {
				return projectCostCentres[0].id;
			} else {
				return costCentres[0].id;
			}
		} else {
			return null;
		}
	}

	getCombinedRates() {
		return [
			...this.payRates,
			...(this.fetchRoleRates() ? this.role.rates : []),
		];
	}

	fetchRoleRates() {
		return (
			this.roleId &&
			(this.inheritPayRate ||
				this.inheritOvertimeRate ||
				this.inheritCostRate ||
				this.inheritChargeOutRate)
		);
	}

	get role() {
		return organisationStore.getStaffRoleById(this.roleId);
	}

	get billingAmount() {
		if (this.isArchived || !this.hasLogin) {
			return 0;
		}
		if (this.billingLevelOverride) {
			if (this.billingLevelOverride == "none") {
				return 0;
			} else if (this.billingLevelOverride == "admin") {
				return organisationStore.organisation.adminPlan.amount / 100;
			} else if (this.billingLevelOverride == "standard") {
				return organisationStore.organisation.standardPlan.amount / 100;
			}
		}
		if (this.permissions.isAdmin) {
			return organisationStore.organisation.adminPlan.amount / 100;
		} else {
			return organisationStore.organisation.standardPlan.amount / 100;
		}
	}

	get billingStatus() {
		if (this.isArchived) {
			return "Archived";
		} else if (!this.hasLogin) {
			return "No Login";
		} else if (this.permissions.isAdmin) {
			return "Admin";
		} else {
			return "Standard";
		}
	}

	refreshRates() {
		if (!this.payRates.isEmpty()) {
			const now = moment();
			let i = 0,
				l = this.payRates.size;
			while (i < l) {
				if (this.payRates.get(i).date.isAfter(now)) {
					break;
				}
				i++;
			}
			if (!this._cache) this._cache = {};
			const currentPr = this.payRates.get(i > 0 ? i - 1 : 0);
			this._cache.payRate = [currentPr.payRate, this.payRates];
			this._cache.overtimeRate = [currentPr.overtimeRate, this.payRates];
			this._cache.costRate = [currentPr.costRate, this.payRates];
			this._cache.chargeOutRate = [
				currentPr.chargeOutRate,
				this.payRates,
			];
			this._cache.weeklyAvailability = [
				currentPr.weeklyAvailability,
				this.payRates,
			];
		}
		return this;
	}

	_getRate(field) {
		if (!this._cache) this._cache = {};
		let c = this._cache[field];
		if (c == null || this.payRates !== c[1]) {
			this.refreshRates();
			c = this._cache[field];
			if (c != null) {
				return c[0];
			} else {
				//TODO-project_architect is null ok?
				return null;
			}
		} else {
			return c[0];
		}
	}

	get payRate() {
		if (this.inheritPayRate) {
			return this.role.payRate;
		} else {
			return this._getRate("payRate");
		}
	}

	get overtimeRate() {
		if (this.inheritOvertimeRate) {
			return this.role.overtimeRate;
		} else {
			return this._getRate("overtimeRate");
		}
	}

	get costRate() {
		if (this.inheritCostRate) {
			return this.role.costRate;
		} else {
			return this._getRate("costRate");
		}
	}

	get chargeOutRate() {
		if (this.inheritChargeOutRate) {
			return this.role.chargeOutRate;
		} else {
			return this._getRate("chargeOutRate");
		}
	}

	get weeklyAvailability() {
		return this._getRate("weeklyAvailability");
	}

	static getClassName() {
		return "StaffMember";
	}

	apiTypeName() {
		return "staff-member";
	}

	getFullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	get costCentre() {
		let ccId = this.costCentreId || this.getDefaultCostCentre();
		return organisationStore.getCostCentreById(ccId);
	}

	set costCentre(costCentre) {
		this.costCentreId = costCentre != null ? costCentre.id : null;
	}

	matchesSearch(searchStr) {
		if (searchStr == null || searchStr === "") {
			return true;
		}
		const searchLower = searchStr.toLowerCase();
		const fLower = this.firstName.toLowerCase();
		const lLower = this.lastName.toLowerCase();
		return (
			(fLower + " " + lLower).indexOf(searchLower) >= 0 ||
			(lLower + ", " + fLower).indexOf(searchLower) >= 0
		);
	}

	getIntercomData() {
		return {
			name: this.firstName + " " + this.lastName,
			email: this.email,
			created_at: moment(this.signedUpAt).unix(),
			app_id: process.env.REACT_APP_INTERCOM_APP_ID,
			organisation_name: this.organisationName,
			user_id: this.id,
			first_name: this.firstName,
			user_level: this.permissions.getUserLevel(),
			financials_visibility: this.permissions.financialsVisibility,
			company: {
				name: this.organisationName,
				id: this.organisationId,
			},
		};
	}

	getLogRocketData() {
		return {
			name: this.firstName + " " + this.lastName,
			email: this.email,
			created_at: moment(this.signedUpAt).unix(),
			organisation_name: this.organisationName,
			user_id: this.id,
			user_level: this.permissions.getUserLevel(),
			financials_visibility: this.permissions.financialsVisibility,
			company: this.organisationName,
		};
	}

	intercomBoot() {
		if (window.Intercom != null) {
			window.intercomSettings = this.getIntercomData();
			window.Intercom("boot", window.intercomSettings);
		}
	}

	posthogBoot() {
		axios
			.post(process.env.REACT_APP_NODE_SERVER_URL + "/flags/has-flag", {
				email: userStore.user.email,
				organisationId: userStore.user.organisationId,
				flag: "has-migrated",
			})
			.then((response) => {
				if (response.data) {
					window.location.replace(
						process.env.REACT_APP_NEW_CLIENT_URL
					);
				}
			});
	}

	featurebaseBoot() {
		window.Featurebase(
			"identify",
			{
				// Each 'identify' call should include an "organization" property,
				// which is your Featurebase board's name before the ".featurebase.app".
				organization: this.organisationName,

				// Required. Replace with your customers data.
				email: this.email,
				name: this.getFullName(),
				id: this.getFullName(),
			},
			(err) => {
				// Callback function. Called when identify completed.
				if (err) {
					// console.error(err);
				} else {
					// console.log("Data sent successfully!");
				}
			}
		);
	}

	// logRocketBoot() {
	// 	LogRocket.identify(this.id, this.getIntercomData());
	// }

	intercomUpdate() {
		if (window.Intercom != null) {
			window.intercomSettings = this.getIntercomData();
			window.Intercom("update", window.intercomSettings);
		}
	}

	elevioUpdate() {
		if (window._elev != null && window._elev.changeUser != null) {
			window._elev.changeUser({
				first_name: this.firstName,
				last_name: this.lastName,
				email: this.email,
				user_hash: this.elevioUserHash,
				groups: this.permissions.getUserLevel(),
			});
		}
	}

	elevioLogout() {
		if (window._elev != null && window._elev.changeUser != null) {
			window._elev.logoutUser();
		}
	}

	isAdmin() {
		return this.permissions.isAdmin;
	}

	getProjectsWithAtLeastPermissionLevel(projects, level) {
		return this.permissions.getProjectsWithAtLeastPermissionLevel(
			projects,
			level
		);
	}

	/**
	 * These properties are so the milestones store and related components can
	 * continue using the interface they were using when they had their own
	 * independent staff objects, even though now they're using the same objects
	 * from the organisation store.
	 */
	get weeklyHoursAvailable() {
		return isNumber(this.weeklyAvailability)
			? this.weeklyAvailability / 60
			: 0;
	}

	get dailyHoursAvailable() {
		return isNumber(this.weeklyAvailability)
			? this.weeklyAvailability / 60 / 5
			: 0;
	}

	getDailyHoursAvailableInRange(startDateInt, endDateInt) {
		return isNumber(
			this.getWeeklyAvailabilityInRange(startDateInt, endDateInt)
		)
			? this.getWeeklyAvailabilityInRange(startDateInt, endDateInt) /
					60 /
					5
			: 0;
	}

	getWeeklyHoursAvailableInRange(startDateInt, endDateInt) {
		return isNumber(
			this.getWeeklyAvailabilityInRange(startDateInt, endDateInt)
		)
			? this.getWeeklyAvailabilityInRange(startDateInt, endDateInt) / 60
			: 0;
	}

	inheritRateType(rateType) {
		let string = `inherit${rateType[0].toUpperCase()}${rateType.slice(1)}`;
		return this[string];
	}

	getRateInRange(rateType, startDateInt, endDateInt) {
		if (this.inheritRateType(rateType)) {
			return this.role.getRateInRange(rateType, startDateInt, endDateInt);
		} else {
			let rates = [];
			if (startDateInt && endDateInt) {
				rates = this.payRates
					.toJS()
					.filter(
						(pr) =>
							pr[rateType] !== null &&
							pr[rateType] !== undefined &&
							dateConverter.momentToInt(pr.date) <= endDateInt
					)
					.sort((a, b) => a.date.diff(b.date));
				if (rates.length === 0 && this.payRates.toJS().length > 0) {
					rates = [new PayRate().toJS()];
				}
			} else {
				if (this.payRates.toJS().length > 0) {
					rates = [_.last(this.payRates.toJS())];
				}
			}
			if (rates.length === 1) {
				return rates[0][rateType];
			} else {
				let rateInRange = 0;
				let totalDuration = endDateInt - startDateInt;
				let intersectingRates = [];
				rates.forEach((a, i) => {
					let rateStartDate = dateConverter.momentToInt(a.date);
					let rateEndDate = rates[i + 1]
						? dateConverter.momentToInt(rates[i + 1].date)
						: rateStartDate + 100000;
					let intersectingDates = xspans.and(
						[rateStartDate, rateEndDate],
						[startDateInt, endDateInt]
					).data;
					if (intersectingDates.length === 2) {
						intersectingRates.push({
							startDate: intersectingDates[0],
							endDate: intersectingDates[1],
							duration:
								intersectingDates[1] - intersectingDates[0],
							rate: a[rateType],
						});
					}
				});
				intersectingRates.forEach(
					(a) =>
						(rateInRange += a.rate * (a.duration / totalDuration))
				);
				return rateInRange;
			}
		}
	}

	getRateInMonth(rateType, monthIndex) {
		let monthMoment = dateConverter.monthIndexToMoment(monthIndex);
		let startDate = dateConverter.momentToInt(monthMoment);
		let endDate = dateConverter.momentToInt(monthMoment.endOf("month"));
		return this.getRateInRange(rateType, startDate, endDate);
	}

	getRateAtDate(rateType, dateInt) {
		if (this.inheritRateType(rateType)) {
			return this.role.getRateAtDate(rateType, dateInt);
		} else {
			let rates = this.payRates
				.toJS()
				.filter(
					(pr) =>
						pr[rateType] !== null &&
						pr[rateType] !== undefined &&
						dateConverter.momentToInt(pr.date) <= dateInt
				)
				.sort((a, b) => a.date.diff(b.date));
			if (rates.length > 0) {
				return _.last(rates)[rateType];
			} else {
				return 0;
			}
		}
	}

	getWeeklyAvailabilityInRange(startDateInt, endDateInt) {
		return this.getRateInRange(
			"weeklyAvailability",
			startDateInt,
			endDateInt
		);
	}

	getTotalPayInRange(startDateInt, endDateInt, staffMember) {
		if (this.inheritRateType("payRate")) {
			return this.role.getTotalPayInRange(startDateInt, endDateInt, this);
		} else {
			let payRates = this.payRates
				.toJS()
				.filter((pr) => pr.payRate != null)
				.sort((a, b) => a.date.diff(b.date));
			if (payRates.length === 1) {
				return (
					payRates[0].payRate *
					this.getNumHoursAvailableInRange(startDateInt, endDateInt)
				);
			} else {
				let payInRange = 0;
				let totalDuration = endDateInt - startDateInt;
				let intersectingPayRates = [];
				payRates.forEach((a, i) => {
					let payRateStartDate = dateConverter.momentToInt(a.date);
					let payRateEndDate = payRates[i + 1]
						? dateConverter.momentToInt(payRates[i + 1].date)
						: payRateStartDate + 100000;
					let intersectingDates = xspans.and(
						[payRateStartDate, payRateEndDate],
						[startDateInt, endDateInt]
					).data;
					if (intersectingDates.length === 2) {
						intersectingPayRates.push({
							startDate: intersectingDates[0],
							endDate: intersectingDates[1],
							duration:
								intersectingDates[1] - intersectingDates[0],
							availability: this.getNumHoursAvailableInRange(
								intersectingDates[0],
								intersectingDates[1]
							),
							payRate: a.payRate,
						});
					}
				});
				intersectingPayRates.forEach(
					(a) => (payInRange += a.payRate * a.availability)
				);
				return payInRange;
			}
		}
	}

	get name() {
		return `${this.firstName} ${this.lastName}`;
	}

	getNumHoursAvailableInMonth(monthIndex, holidaysArray = []) {
		let monthMoment = dateConverter.monthIndexToMoment(monthIndex);
		let startDate = dateConverter.momentToInt(monthMoment);
		let endDate = dateConverter.momentToInt(monthMoment.endOf("month"));
		return this.getNumHoursAvailableInRange(
			startDate,
			endDate,
			holidaysArray
		);
	}

	getNumHoursAvailableInRange(startDate, endDate, holidaysArray = []) {
		if (
			startDate == null ||
			startDate === -Infinity ||
			endDate == null ||
			endDate === Infinity
		) {
			return Infinity;
		}
		if (moment.isMoment(startDate)) {
			startDate = dateConverter.momentToInt(startDate);
		}
		if (moment.isMoment(endDate)) {
			endDate = dateConverter.momentToInt(endDate);
		}
		const numWeekdays = dateConverter.numWeekdaysInRangeWithoutHolidays(
			[startDate, endDate],
			holidaysArray
		);
		const weeklyAvailabilityInRange = this.getWeeklyAvailabilityInRange(
			startDate,
			endDate
		);
		const minutesPerWeek = isNumber(weeklyAvailabilityInRange)
			? weeklyAvailabilityInRange
			: 0;
		return (numWeekdays * minutesPerWeek) / 60 / 5;
	}

	getAllocatedProjectsInRange(startDate, endDate) {
		let self = this;
		return Immutable.Set().withMutations(function (projects) {
			for (let p of organisationStore.projects) {
				for (let pp of p.phases) {
					for (let ra of pp.allocations) {
						if (ra.staffMember.id === self.id) {
							projects.add(p);
						}
					}
				}
			}
		});
	}

	getNumHoursAllocatedInRange(startDate, endDate) {
		let hours = 0;
		if (moment.isMoment(startDate)) {
			startDate = dateConverter.momentToInt(startDate);
		}
		if (moment.isMoment(endDate)) {
			endDate = dateConverter.momentToInt(endDate);
		}
		for (let p of organisationStore.projects) {
			for (let pp of p.phases) {
				for (let ra of pp.allocations) {
					hours += ra.getHoursForStaffMemberInDateRange(
						this,
						startDate,
						endDate
					);
				}
			}
		}
		return hours;
	}

	getHomePage(url) {
		if (!this.hasSetDetails) {
			return "/user/details";
		}

		const userType = this.permissions.getUserLevel();
		switch (userType) {
			case UserLevel.admin:
			case UserLevel.limitedAdmin:
				return url || "/dashboard/revenue-forecast";
			case UserLevel.projectManager:
			case UserLevel.viewer:
				if (
					FinancialsVisibility.isAtLeast(
						this.permissions.financialsVisibility,
						FinancialsVisibility.allExceptPay
					)
				) {
					return url || "/dashboard/revenue-forecast";
				} else {
					return "/dashboard/projects";
				}
			case UserLevel.timesheet:
				return "/timesheet/current";
			default:
				throw new Error("Account disabled");
		}
	}

	setDetails(details) {
		/**
		 * Details: dict as returned by the /user/details view.
		 */
		return this.merge({
			hasSetDetails: details.hasSetDetails,
			firstName: details.firstName,
			lastName: details.lastName,
			organisationName: details.organisationName,
			country: details.country,
			couponCode: details.couponCode,
		});
	}

	isAccountingSystemAuthenticated(accountingSystem) {
		let credentials = this.accountingSystemCredentials[accountingSystem];
		return (
			credentials != null &&
			credentials.verified &&
			moment(credentials.oauth_expires_at).unix() > moment().unix()
		);
	}
};
