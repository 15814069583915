import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from "moment";
import { reportsPageWrapper } from '../reports/reportsPageWrapper.js';
import { getTimesheetApiUrl } from './utils.js';
import { makeMultipleStoreMixin, dispatcher } from '../coincraftFlux.js';
import { formatNumber2 } from '../utils.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { Checkbox, PageLoadingSpinner } from '../widgets.js';
import { organisationStore } from '../organisation.js';
import { ReportStoreWidget, ReportStoreSaveBar } from '../reports/ReportWidget.js';
import { timesheetReportStore, timesheetReportActions, groupingOptions } from './TimesheetReportStore.js';
import { userStore } from '../user/flux.js';
import { rootStore } from '../RootStore.js';
import { Table } from '../table.js';
import Immutable from 'immutable';
import { EditEntryModal } from './EditEntryModal.js';
import { dateConverter } from '../models/dateconverter.js';
import apiRequest from '../apiRequest.js';



export var TimesheetReportsPage = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore, rootStore, userStore], function() {
      const groupers = timesheetReportStore.reportStore.report.groupBy || [];
      const entries = timesheetReportStore.getMatchingEntries();
      const expandedGroups = timesheetReportStore.reportStore.expandedGroups;
      const sortBy = timesheetReportStore.reportStore.sortBy;
      const report = timesheetReportStore.reportStore.report;
      const groups = timesheetReportStore.groupRows({
        items: entries,
        groupers: groupers,
        expandedGroups: expandedGroups,
        sortBy: sortBy,
        report: report
      });

      return {
        modals: timesheetReportStore.modals,
        entries: entries,
        expandedGroups: expandedGroups,
        selectedItems: timesheetReportStore.reportStore.selectedItems,
        sortBy: sortBy,
        reports: organisationStore.reports.filter(r => r.reportType === 'timesheet'),
        report: report,
        selectedVisibleColumns: timesheetReportStore.selectedVisibleColumns,
        isDirty: timesheetReportStore.reportStore.isDirty,
        state: timesheetReportStore.state,
        currencyFormatter: organisationStore.organisation.currencyFormatter,
        user: userStore.user,
        groups: groups,
        isAllChecked: timesheetReportStore.isAllChecked(groups),
        isAllIndeterminate: timesheetReportStore.isAllIndeterminate(groups),
      };
    })
  ],

  render: function() {
    let self = this;
    const [start, end] = timesheetReportStore.reportStore.report.dateRange.getDates(moment());
    const subHeading = start != null && end != null ?
      start.isSame(end) ?
        `${start.format("DD/MM/YYYY")}`
      :
        `${start.format("DD/MM/YYYY")} to ${end.format("DD/MM/YYYY")}`
      : null;
    return <CoincraftPage
      className="timesheet-reports"
      header={
        <ReportStoreWidget
          heading="Timesheet Reports"
          subHeading={subHeading}
          user={this.state.user}
          items={this.state.entries}
          reports={this.state.reports}
          reportStore={timesheetReportStore.reportStore}
          groupingOptions={groupingOptions}
          getCsvExportUrl={() => apiRequest({ 
            url: getTimesheetApiUrl() , 
            method: "get", 
            params: {data: {
              report: timesheetReportStore.reportStore.report.serialize(),
              format: 'csv'
            }}
          })}
          hasFilter={false}
          extraRightButtons={
              [
                <button
                  key={0}
                  disabled={this.state.selectedItems.length === 0}
                  className="batch-edit-button page-header__button--primary"
                  onClick={() => timesheetReportActions.editSelectedEntries()}
                >
                  Edit Selected Time Entries
                </button>
              ]
          }
        />
      }
      body={
        <div style={{ height: '100%'}}>
          {this.state.modals.map(function(modal, i) {
            if (modal.type === 'editSelectedEntries') {
              return <EditEntryModal
                key={i}
                entries={modal.entries}
                modal={modal}
                onSubmit={function(modal, entries, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced) {
                  timesheetReportActions.batchEditEntries(modal, entries, project, projectPhase, task, isBillable, isVariation, isOvertime, isLocked, beenInvoiced);
                }}
                onClose={function() {
                  timesheetReportActions.closeModal(modal);
                }}
              />;
            }
            else {
              return null;
            }
          })}
          {this.state.state === 'loaded' && !this.state.isDirty ?
            <Table
              scroll={true}
              rows={this.state.groups}
              columns={
                // The `<Table>` currently needs to have all the grouped columns passed in
                // in order to be able to correctly draw the header row.
                Immutable.OrderedSet(this.state.selectedVisibleColumns.map(c => c.identifier))
                  .union(Immutable.Set(timesheetReportStore.reportStore.report.groupBy))
                  .map(c => timesheetReportStore.reportStore.getColumnById(c))
                  .toJS()
              }
              transformColumnsAfterGrouping={function(columns) {
                return [
                  timesheetReportStore.checkboxColumn.copy().update(
                    {
                      header: <Checkbox
                        value={self.state.isAllChecked}
                        indeterminate={self.state.isAllIndeterminate}
                        onChange={() => timesheetReportActions.toggleAllChecked(self.state.groups)}
                        className="dont-print"
                        onClick={function(event) {
                          event.stopPropagation();
                        }}
                      />
                    },
                    {useDefaults: false}
                  ),
                  ...columns
                ];
              }}
              groupBy={timesheetReportStore.reportStore.report.groupBy}
              getRowChildren={row => row.expanded ? row.children || [] : []}
              columnPresentation={{
                numMinutes: {
                  content: (item, i, stack, data) => formatNumber2(data, {ifNull: ''})
                },
                labourExpense: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                cost: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                chargeOut: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                chargeOutRate: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                hoursBudget: {
                  content: (item, i, stack, data) => formatNumber2(data, {ifNull: ''})
                },
                budget: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                fee: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                revenue: {
                  content: (item, i, stack, data) => this.state.currencyFormatter.format(data, {ifNull: ''})
                },
                monthIndex: {
                  content: (item, i, stack, data) => data != null ? dateConverter.monthIndexToMoment(data).format("MMM YY") : ''
                },
                isBillable: {
                  content: (item, i, stack, data) => data === true ? 'Yes' : data === false ? 'No' : ''
                },
                isVariation: {
                  content: (item, i, stack, data) => data === true ? 'Yes' : data === false ? 'No' : ''
                },
                isOvertime: {
                  content: (item, i, stack, data) => data === true ? 'Yes' : data === false ? 'No' : ''
                },
                isLocked: {
                  content: (item, i, stack, data) => data === true ? 'Yes' : data === false ? 'No' : ''
                },
                beenInvoiced: {
                  content: (item, i, stack, data) => data === true ? 'Yes' : data === false ? 'No' : ''
                },
                task: {
                  content: function(item, i, stack, task) {
                    const groupBy = timesheetReportStore.reportStore.report.groupBy;
                    if (task != null) {
                      return task.name;
                    }
                    else if (stack.length >= groupBy.length || groupBy[stack.length] === 'task') {
                      return '(No task)';
                    }
                    else {
                      return null;
                    }
                  }
                },
              }}
              rowProps={function(item, i, stack) {
                return {
                  onClick: function(event) {
                    dispatcher.dispatch({
                      type: "report/toggleGroupExpanded",
                      path: "timesheet-reports-page/reports",
                      stack: stack,
                      item: item
                    });
                  }
                };
              }}
              shouldHandleSorting={false}
              sortedBy={this.state.sortBy}
              initialSort={{columnIdentifier: 'grouper', direction: 'asc'}}
              onSort={function({columnIdentifier, direction}) {
                dispatcher.dispatch({
                  type: "report/setSortBy",
                  path: "timesheet-reports-page/reports",
                  columnIdentifier: columnIdentifier,
                  direction: direction
                });
              }}
            />
          : this.state.state === 'loading' ?
            <div style={{marginTop: '4em'}}>
              <PageLoadingSpinner text="Loading..." />
            </div>
          : null}

          {this.state.isDirty ?
            <div style={{textAlign: 'center', marginTop: '2.5em'}}>
              <button
                  className="btn btn-default btn-lg refresh-table-button"
                  onClick={() => timesheetReportStore.reportStore.actions.refreshTable()}>
                <i className="fa fa-fw fa-refresh" style={{marginLeft: 0}} />
                Refresh table
              </button>
            </div>
          : null}
        </div>
      }
      saveBar={
        <ReportStoreSaveBar
          reportStore={timesheetReportStore.reportStore}
          user={this.state.user}
        />
      }
    />;
  },
});


export var TimesheetReportPageWrapper = reportsPageWrapper(timesheetReportStore, TimesheetReportsPage);
