import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import { jsonHttp } from './jsonHttp.js';
import { router } from './router.js';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import { userStore, actions as userActions } from './user/flux.js';
import classNames from 'classnames';
import { ErrorAlert, SuccessAlert } from './widgets/alerts.js';
import { Checkbox } from './widgets/generic.js';
import apiRequest from './apiRequest.js';


export var LoginComponent = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([userStore], function() {
      return {
        userAlreadyExists: userStore.error === 'user_already_exists',
        isLoggingIn: userStore.isLoggingIn,
        isRegistering: userStore.isRegistering,
        loginFailed: userStore.error != null,
        registrationFailed: userStore.error != null,
      };
    })
  ],

  getInitialState: function() {
    return {
      mode: 'login',
      email: '',
      password: '',
      registerFormSubmitted: false,
      acceptedToc: false
    };
  },

  switchToRegister: function(event) {
    router.history.replace({pathname: '/register'});
    event.preventDefault();
  },

  switchToLogin: function(event) {
    router.history.replace({pathname: '/login'});
    event.preventDefault();
  },

  forgottenPassword: function() {
    router.history.replace({pathname: '/user/forgotten-password'});
  },

  componentWillMount: function() {
    // If we come to a URL like https://app.coincraft.co/#/register?email=amagee@gmail.com
    // Pre-fill the email box with amagee@gmail.com.
    if (window.location.search.match(/email=([^&]*)/)) {
      let email = RegExp.$1;
      this.setState({email: email});
    }
  },

  componentDidMount: function() {
    if (document.activeElement === document.body) {
      this.refs.email.focus();
    }
  },

  render: function() {
    let self = this;

    return (
		<div
			className="flexbox-container flex-align-items-center flex-justify-content-center"
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				textAlign: "center"
			}}
		>
			<section
				className={classNames("login-widget", {
					"login-widget--login-mode":
						this.props.route.name === "login",
					"login-widget--register-mode":
						this.props.route.name === "register"
				})}
			>
				<header className="text-align-center">
					<div
						style={{ width: "10em", height: "10em" }}
						className="logo2 s75"
					>
						<img src={process.env.PUBLIC_URL + "/coincraft_logo_130.png"} />
					</div>
					<h1 style={{ fontSize: "5em" }}>coincraft</h1>
				</header>
				{this.props.route.name === "login" ? (
					<div className="body">
						<form className="no-margin" noValidate>
							<fieldset>
								<div className="form-group">
									<div className="input-group input-group-lg">
										<input
											ref="email"
											value={this.state.email}
											onChange={this.handleEmailChange}
											type="email"
											className="form-control input-lg login-widget__email-input"
											placeholder="Your Email"
										/>
									</div>
								</div>

								<div className="form-group">
									<div className="input-group input-group-lg">
										<input
											type="password"
											value={this.state.password}
											onChange={this.handlePasswordChange}
											className="form-control input-lg login-widget__password-input"
											placeholder="Your Password"
										/>
									</div>
								</div>

								{self.state.loginFailed ? (
									<ErrorAlert>
										{`Sorry, we couldn't log you in with those details. Please try again.`}
									</ErrorAlert>
								) : null}
							</fieldset>
							<div className="form-actions">
								<button
									type="submit"
									onClick={self.login}
									className="btn btn-block btn-lg btn-primary"
									disabled={self.state.isLoggingIn}
								>
									<i
										className={classNames(
											"fa fa-fw",
											self.state.isLoggingIn
												? "fa-spinner fa-spin"
												: "fa-user"
										)}
									/>{" "}
									<small>Sign In</small>
								</button>
								{this.renderCreateAccountButton(
									this.switchToRegister,
									"login-widget__to-register"
								)}
								<div className="forgot">
									<a
										className="forgot"
										href="javascript:void(0)"
										onClick={self.forgottenPassword}
									>
										Forgotten Your Password?
									</a>
								</div>
							</div>
						</form>
					</div>
				) : (
					<div className="body">
						<h4
							style={{
								margin: "0em -2em 1em",
								fontSize: "1.2em"
							}}
						>
							Please enter your email address and select a
							password
						</h4>
						<form
							ref="registerForm"
							name="registerForm"
							className="no-margin"
							noValidate
						>
							<fieldset>
								<div className="form-group">
									<div className="input-group input-group-lg">
										<input
											ref="email"
											name="email"
											type="email"
											required={true}
											value={this.state.email}
											onChange={this.handleEmailChange}
											className="form-control input-lg login-widget__email-input"
											placeholder="Your Email"
										/>
									</div>
									{self.state.registerFormSubmitted &&
									!self.state.emailValid ? (
										<div className="popover error-message-popover bottom">
											<div className="arrow"></div>
											<div className="popover-content">
												Please enter your e-mail
												address.
											</div>
										</div>
									) : null}
								</div>
								<div className="form-group">
									<div className="input-group input-group-lg">
										<input
											type="password"
											name="password"
											value={this.state.password}
											onChange={this.handlePasswordChange}
											required={true}
											className="form-control input-lg login-widget__password-input"
											placeholder="Your Password"
										/>
									</div>
									{self.state.registerFormSubmitted &&
									!self.state.passwordValid ? (
										<div className="popover error-message-popover bottom">
											<div className="arrow"></div>
											<div className="popover-content">
												Please enter a password for your
												account.
											</div>
										</div>
									) : null}

									{self.state.userAlreadyExists ? (
										<ErrorAlert>
											A user already exists with the email
											address you entered. Have you{" "}
											<a
												className="forgot"
												href="javascript:void(0)"
												onClick={self.forgottenPassword}
											>
												forgotten your password
											</a>
											?
										</ErrorAlert>
									) : null}
								</div>
								<div
									className="control-group"
									style={{ textAlign: "left" }}
								>
									<div className="controls form-group">
										<Checkbox
											className="login-widget__toc-checkbox"
											label={
												<span>
													{
														"I agree to the Coincraft "
													}
													<a
														href="http://www.coincraft.co/terms-of-service"
														target="_blank"
													>
														Terms of Service
													</a>
												</span>
											}
											value={self.state.acceptedToc}
											onChange={
												self.handleAcceptedTocChange
											}
										/>
									</div>
									{self.state.registerFormSubmitted &&
									!self.state.acceptedTocValid ? (
										<div className="popover error-message-popover bottom">
											<div
												className="arrow"
												style={{ left: "10%" }}
											></div>{" "}
											{/* Align with the check box -->*/}
											<div className="popover-content">
												Sorry, you need to accept our
												Terms of Service before we can
												create your account.
											</div>
										</div>
									) : null}
								</div>
							</fieldset>
							<div className="form-actions">
								{this.renderCreateAccountButton(
									this.login,
									"login-widget__create-account-button"
								)}
								<div className="forgot">
									<a
										className="forgot"
										onClick={self.switchToLogin}
										href="javascript:void(0)"
									>
										Log Into Your Existing Account
									</a>
								</div>
							</div>
						</form>
					</div>
				)}
			</section>
		</div>
	);
  },

  renderCreateAccountButton: function(onClick, className) {
    return <button
        onClick={onClick}
        className={classNames("btn btn-block btn-lg btn-info", className)}
        disabled={this.state.isRegistering}>
      <i className={classNames("fa fa-fw", this.state.isRegistering ? "fa-spinner fa-spin" : "fa-plus")} />
      {' '}
      <small>
        {this.state.isRegistering ? "Creating Account..." : "Create a New Account"}
      </small>
    </button>;
  },

  handleEmailChange: function(event) {
    this.setState({
      loginFailed: false,
      email: event.target.value,
      emailValid: event.target.checkValidity()
    });
  },

  handlePasswordChange: function(event) {
    this.setState({
      loginFailed: false,
      password: event.target.value,
      passwordValid: event.target.checkValidity()
    });
  },

  handleAcceptedTocChange: function(acceptedToc) {
    this.setState({
      loginFailed: false,
      acceptedToc: acceptedToc,
      acceptedTocValid: acceptedToc === true
    });
  },

  login: function(event) {
    var self = this;

    const isLogin = (this.props.route.name === 'login');
    const form = isLogin ? this.refs.loginForm : this.refs.registerForm;

    if (this.props.route.name === 'register') {
      self.setState({registerFormSubmitted: true});
      if (!ReactDOM.findDOMNode(form).checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    if (isLogin) {
      userActions.login(this.state.email, this.state.password);
    }
    else {
      if (!this.state.acceptedToc) {
        this.setState({acceptedTocValid: false});
      }
      else {
        userActions.register(this.state.email, this.state.password);
      }
    }

    event.preventDefault();
    event.stopPropagation();
  },

  showError: function() {
    if (this.props.route.name === 'login') {
      this.setState({loginFailed: true});
    }
    else {
      this.setState({registrationFailed: true});
    }
  }
});


export var ForgottenPasswordComponent = CreateReactClass({
  getInitialState: function() {
    return {
      email: '',
      state: 'not_submitted'
    };
  },

  render: function() {
    return (
		<div
			className="flexbox-container flex-align-items-center flex-justify-content-center"
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				textAlign: "center"
			}}
		>
			<section
				className={classNames("login-widget", {
					"login-widget--login-mode":
						this.props.route.name === "login",
					"login-widget--register-mode":
						this.props.route.name === "register"
				})}
			>
				<header className="text-align-center">
					<div
						style={{ width: "10em", height: "10em" }}
						className="logo2 s75"
					>
						<img
							src={
								process.env.PUBLIC_URL +
								"/coincraft_logo_130.png"
							}
						/>
					</div>
					<h1 style={{ fontSize: "5em" }}>coincraft</h1>
				</header>
				{this.state.state === "success" ? (
					<SuccessAlert className="body">
						An email has been sent to your address with a link to
						reset your password.
					</SuccessAlert>
				) : (
					<div className="body">
						<h4
							style={{
								margin: "0em -2em 1em",
								fontSize: "1.2em"
							}}
						>
							Set a New Password
						</h4>
						<p>
							Please enter your email address and we will email
							you instructions on how to set a new password.
						</p>
						<form
							name="forgottenPasswordForm"
							className="no-margin"
						>
							<fieldset>
								<div className="form-group no-margin">
									<div className="input-group input-group-lg">
										<span className="input-group-addon">
											<i className="eicon-user"></i>
										</span>
										<input
											value={this.state.email}
											onChange={this.handleEmailChange}
											type="email"
											className="form-control input-lg"
											placeholder="Your Email"
										/>
									</div>
								</div>
							</fieldset>
							{this.state.state === "error" ? (
								<ErrorAlert style={{ marginTop: 15 }}>
									Sorry, we couldn't find an email with this
									e-mail address. Please try again.
								</ErrorAlert>
							) : null}
							<div className="form-actions">
								<button
									onClick={this.sendEmail}
									className="btn btn-block btn-lg btn-primary"
								>
									<small>
										Email New Password Instructions
									</small>
								</button>
								<div className="forgot">
									<a
										className="forgot"
										href="javascript:void(0)"
										onClick={this.toLogin}
									>
										Sign Into Your Account
									</a>
								</div>
							</div>
						</form>
					</div>
				)}
			</section>
		</div>
	);
  },

  handleEmailChange: function(event) {
    this.setState({
      email: event.target.value,
      state: 'not_submitted'
    });
  },

  toLogin: function() {
    router.history.replace({pathname: '/login'});
  },

  sendEmail: function(event) {
    var self = this;
    try {
      return apiRequest({
        path: `/user/send-reset-password-email`,
        method: "post",
        data: { email: this.state.email },
        success: data => {
          if (data.status === "ok") {
            self.setState({ state: "success" });
          } else {
            self.setState({ state: "error" });
          }
        }
      });
    }
    finally {
      event.preventDefault();
    }
  }
});
