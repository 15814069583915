export const phaseRevenueDateMap = {
    startDate: phase => phase.getStartDate(),
    endDate: phase => phase.getEndDate(),
    now: phase => phase.remainingRevenueStartDate
}

export const phaseStaffDateMap = {
    startDate: (phase, staffMember) => phase.getStartDate(),
    endDate: (phase, staffMember) => phase.getEndDate(),
    now: (phase, staffMember) => phase.getRemainingStaffHoursStartDate(staffMember)
}

export const phaseRoleDateMap = {
    startDate: (phase, staffRole) => phase.getStartDate(),
    endDate: (phase, staffRole) => phase.getEndDate(),
    now: (phase, staffRole) => phase.getRemainingRoleHoursStartDate(staffRole)
}