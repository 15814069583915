import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { WarningAlert } from '../widgets.js';
import { PermissionItemSelector } from '../widgets/PermissionItemSelector.js';
import { BasicMySelect2, SmallDeleteButton } from '../widgets/generic.js';
import { PermissionLevel, FinancialsVisibility } from '../models/permissions.js';
import { UserLevel } from '../models/staffmember.js';
import PropTypes from "prop-types";
import { Permissions } from "../models/permissions.js";


const elevioIds = {
  permissionLevel: "89330",
  financialPermissions: "89331",
  projectPermissions: "89332"
};

const sectionStyle ={padding: '1em', borderBottom: '1px solid #e9e9e9'};
const selectedPermissionStyles = {backgroundColor: '#eee', marginRight: '1em', opacity: 1};


export var PermissionsEditor = CreateReactClass({
  propTypes: {
    permissions: PropTypes.instanceOf(Permissions).isRequired,
    actions: PropTypes.shape({
      addPermission: PropTypes.func.isRequired, // function()
      setOverallLevel: PropTypes.func.isRequired, // function(PermissionLevel)
      setPermissionItem: PropTypes.func.isRequired, // function(rowIndex:int, item:(Project|BusinessCategory))
      setPermissionLevel: PropTypes.func.isRequired, // function(rowIndex:int, permission:PermissionLevel)
      deletePermission: PropTypes.func.isRequired, // function(rowIndex:int)
    }).isRequired,
    isEditable: PropTypes.bool.isRequired
  },

  render: function() {
    let self = this;

    const permissions = this.props.permissions;
    const userLevel = permissions.getUserLevel();

    return <div>
      <div style={sectionStyle}>
        <h4>
          {'Permission Level '}
          <i
            className="fa fa-question-circle"
            data-elevio-inline={elevioIds.permissionLevel}
            style={{fontSize: '0.8em'}}/>
        </h4>
        <button
            className="btn btn-sm btn-default"
            style={userLevel === UserLevel.admin ? selectedPermissionStyles : {marginRight: '1em'}}
            onClick={() => this.props.actions.setOverallLevel(PermissionLevel.admin)}
            disabled={!this.props.isEditable}>
          Master Admin
        </button>
        <button
            className="btn btn-sm btn-default"
            style={userLevel === UserLevel.limitedAdmin ? selectedPermissionStyles : {marginRight: '1em', opacity: 1}}
            disabled={true}>
          Limited Admin
        </button>
        <button
            className="btn btn-sm btn-default"
            style={userLevel === UserLevel.projectManager ? selectedPermissionStyles : {marginRight: '1em', opacity: 1}}
            onClick={() => this.props.actions.setOverallLevel(PermissionLevel.projectManager)}>
          Project Manager
        </button>
        <button
            className="btn btn-sm btn-default"
            style={userLevel === UserLevel.viewer ? selectedPermissionStyles : {marginRight: '1em'}}
            onClick={() => this.props.actions.setOverallLevel(PermissionLevel.view)}
            disabled={!this.props.isEditable}>
          Viewer
        </button>
        <button
            className="btn btn-sm btn-default"
            style={userLevel === UserLevel.timesheet ? selectedPermissionStyles : {marginRight: '1em'}}
            onClick={() => this.props.actions.setOverallLevel(PermissionLevel.timesheet)}
            disabled={!this.props.isEditable}>
          Timesheet
        </button>
      </div>
      <div style={sectionStyle}>
        <h4>
          {'Financials '}
          <i
            className="fa fa-question-circle"
            data-elevio-inline={elevioIds.financialPermissions}
            style={{fontSize: '0.8em'}}
          />
        </h4>
        <div>
          <BasicMySelect2
            options={[
              {
                value: FinancialsVisibility.all,
                label: 'Can see all financial data'
              },
              {
                value: FinancialsVisibility.allExceptPay,
                label: 'Can see all financial data except pay data'
              },
              {
                value: FinancialsVisibility.onlyExpenses,
                label: 'Can only see staff cost and project expenses'
              },
              {
                value: FinancialsVisibility.none,
                label: 'All financial data hidden'
              }
            ]}
            value={this.props.permissions.financialsVisibility}
            onChange={(value) => this.props.actions.setFinancialsVisibility(value)}
            disabled={!this.props.isEditable || this.props.permissions.isAdmin}
          />
        <div><i>For all projects with Admin, Project Manager, or View permissions</i></div>
        </div>
      </div>
      <div style={{...sectionStyle, borderBottom: 'none'}}>
        <div>
          <h4>
            {'Project Permissions '}
            <i
              className="fa fa-question-circle"
              data-elevio-inline={elevioIds.projectPermissions}
              style={{fontSize: '0.8em'}}/>
          </h4>
          <div className="permission-rows" style={{margin: '1em', borderTop: '1px solid #eee', maxWidth: '48em'}}>
            {this.props.permissions.items.map(function(permission, i) {
              return <div
                  key={i}
                  className="permission-row flexbox-container flex-align-items-center flex-space-between"
                  style={{height: '3.5em', borderBottom: '1px solid #eee'}}>
                {/**
                  * lineHeight: 0 makes the inline-block dropdowns not bork up the vertical
                  * centering.
                  */}
                <div className="flex-1-1-auto" style={{lineHeight: 0}}>
                  <div style={{display: 'inline-block', lineHeight: '20px', marginRight: '2em', width: '58%', maxWidth: '17em'}}>
                    <PermissionItemSelector
                      className="item-dropdown"
                      value={permission.object}
                      isEditable={self.props.isEditable}
                      onChange={function(item) {
                        self.props.actions.setPermissionItem(i, item);
                      }}
                    />
                  </div>
                  <BasicMySelect2
                    className="level-dropdown"
                    style={{lineHeight: '20px', width: '42%', maxWidth: '12em'}}
                    options={PermissionLevel.getOptions()}
                    value={permission.level}
                    isEditable={self.props.isEditable}
                    onChange={function(level) {
                      self.props.actions.setPermissionLevel(i, level);
                    }}
                  />
                </div>
                <div className="flex-0-0-auto" style={{textAlign: 'right'}}>
                  {self.props.isEditable ?
                    <SmallDeleteButton
                      className="delete-button"
                      onClick={function() { self.props.actions.deletePermission(i); }}
                    />
                  : null}
                </div>
              </div>;
            })}
          </div>
        </div>

        {this.props.isEditable ?
          <div>
            <button
                className="btn btn-default add-permission-button"
                onClick={() => this.props.actions.addPermission()}>
              + Add permission
            </button>
          </div>
        : null}

        {this.props.isEditable && userLevel == null ?
          <WarningAlert style={{marginTop: '1em'}}>
            Without any permissions, this user will not be able to access
            any CoinCraft functionality.
          </WarningAlert>
        : null}
      </div>
    </div>;
  }
});
