import _ from 'underscore';
import Immutable from 'immutable';
import { generateUUID } from '../utils.js';
import { fieldTypeToMatcherTypeLookup } from '../widgets/filterwidget.js';
import { parseDateRange, AllTime } from './DateRanges.js';
import { dateConverter } from '../models.js';
export { ReportType } from "./ReportType.js";


export const Report = class extends Immutable.Record({
			id: null,
			uuid: null,
			reportType: null,
			createdAt: null,
			name: "",
			dateRange: null,
			columns: null,
			groupBy: [],
			filters: null,
			isDefault: false
		}) {
			constructor(options = {}) {
				super({
					...options,
					...(options.filters == null
						? { filters: Immutable.List([]) }
						: {}),
					...(options.dateRange == null
						? { dateRange: AllTime }
						: {}),
					uuid: options.uuid || generateUUID()
				});
			}

			addFilter() {
				return this.set(
					"filters",
					this.filters.push(
						Immutable.Map({
							columnId: null,
							matcher: null
						})
					)
				);
			}

			setFilterColumn(filterIndex, column, matcher) {
				return this.set(
					"filters",
					this.filters.set(
						filterIndex,
						Immutable.Map({
							columnId: column.identifier,
							matcher: matcher
						})
					)
				);
			}

			deleteFilter(filterIndex) {
				return this.set("filters", this.filters.remove(filterIndex));
			}

			setFilterValue(filterIndex, value) {
				return this.setIn(
					["filters", filterIndex, "matcher", "value"],
					value
				);
			}

			setFilterOperation(filterIndex, operation) {
				return this.set(
					"filters",
					this.filters.setIn(
						[filterIndex, "matcher", "operation"],
						operation
					)
				);
			}

			setFilterCustomRange(filterIndex, customRange) {
				return this.set(
					"filters",
					this.filters.setIn(
						[filterIndex, "matcher", "customRange"],
						customRange
					)
				);
			}

			setDateRange(dateRange) {
				return this.set("dateRange", dateRange);
			}

			setGroupBy(groupBy) {
				return this.set("groupBy", groupBy);
			}

			getPath() {
				let segment;
				if (this.reportType === "project") {
					segment = "projects";
				} else if (this.reportType === "staffMember") {
					segment = "staff";
				} else if (this.reportType === "invoice") {
					segment = "invoices";
				} else if (this.reportType === "timesheet") {
					segment = "timesheet";
				} else {
					throw new Error("Unrecognised report type");
				}
				return `/dashboard/${segment}/report/${this.uuid}`;
			}

			getItemMatcher(columns) {
				const activeFilters = this.filters.filter(
					f => f.get("columnId") != null
				);

				const filters = activeFilters.map(function(filter) {
					const columnId = filter.get("columnId");
					const column = _.find(
						columns,
						c => c.identifier === columnId
					);
					const sharedData = column.sharedData();
					const matcher = filter.get("matcher");

					return {
						filter: filter,
						matcher: matcher,
						getItemValue: function(item) {
							let val = column.data(item, sharedData);
							if (column.type === "moment") {
								return val != null
									? dateConverter.momentToInt(val)
									: null;
							} else {
								return val;
							}
						}
					};
				});

				return function(item) {
					for (let { getItemValue, matcher } of filters) {
						if (!matcher.matches(getItemValue(item))) {
							return false;
						}
					}
					return true;
				};
			}

			fixValues(fixedValues) {
				let matchedColumns = [];
				let report = this.update("filters", function(filters) {
					return filters.map(function(filter) {
						const columnId = filter.get("columnId");
						const matcher = fixedValues[columnId];
						if (matcher != null) {
							matchedColumns.push(columnId);
							return filter.merge({
								matcher: matcher(),
								readOnly: true
							});
						} else {
							return filter;
						}
					});
				});

				_.each(fixedValues, function(matcherFunc, columnId) {
					if (!_.include(matchedColumns, columnId)) {
						report = report.update("filters", function(filters) {
							return filters.push(
								Immutable.Map({
									columnId: columnId,
									matcher: matcherFunc(),
									readOnly: true
								})
							);
						});
					}
				});

				return report;
			}

			static getClassName() {
				return "Report";
			}

			static transformArgs(objectData, organisation) {
				return {
					...objectData,
					dateRange: parseDateRange(objectData.dateRange),
					filters: Immutable.fromJS(
						(objectData.filters || [])
							.map(data => parseFilter(data))
							.filter(f => f != null)
					)
				};
			}

			static fromJson(objectData, organisation) {
				return new Report(this.transformArgs(objectData));
			}

			serialize() {
				return {
					reportType: this.reportType,
					dateRange: this.dateRange.serialize(),
					name: this.name,
					columns: this.columns,
					filters: this.filters.toJS(),
					groupBy: this.groupBy,
					isDefault: this.isDefault
				};
			}
		};


export function parseFilter(filterData) {
  if (filterData.columnId == null || filterData.matcher == null) {
    // Defensive: don't deserialise not-really-created filters.
    return null;
  }
  return {
    columnId: filterData.columnId,
    matcher: fieldTypeToMatcherTypeLookup[filterData.matcher.type](filterData.matcher)
  };
}

