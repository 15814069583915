import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { CoincraftHeader } from './CoincraftHeader.js';
import PropTypes from 'prop-types';



export var CoincraftPage = CreateReactClass({
  propTypes: {
    // `header` is an already-rendered header component, `heading` is a string which
    // `CoincraftPage` will turn into a heading.
    header: PropTypes.object,

    heading: PropTypes.object,
    extraContent: PropTypes.object,
    saveBar: PropTypes.node,
    errorBar: PropTypes.node,
    rightButtons: PropTypes.node,

    body: PropTypes.object,
    sidebar: PropTypes.object,
    tabs: PropTypes.object
  },

  render: function() {
    const { className, header, heading, saveBar, errorBar, body, sidebar, tabs, rightButtons, extraContent, ...props} = this.props;
    return (
      <div className="coincraft-page-layout">
        <div className="coincraft-page-layout__header">
          <CoincraftHeader />
          {errorBar != null ?
            <div className="flex-0-0-auto">
              {errorBar}
            </div>
          : saveBar != null ?
            <div className="flex-0-0-auto">
              {saveBar}
            </div>
          : null}
        </div>
        <div
            className={classNames("coincraft-page-layout__content", className)}
            {...props}>
          <div className="coincraft-page-layout__page">
            <div className="coincraft-page-layout__page-header">
              {header}
              {heading != null ?
                <CoincraftPageHeader
                  heading={heading}
                  rightButtons={rightButtons}
                  extraContent={extraContent}
                />
              : null}
              {tabs != null ?
                <div className="page-layout__tabs">
                  {tabs}
                </div>
              : null}
            </div>
            <div className="coincraft-page-layout__page-body">
              {body}
            </div>
          </div>
          {sidebar != null ?
            <div className="coincraft-page-layout__sidebar">
              <div className="coincraft-page-layout__sidebar-content">
                {sidebar}
              </div>
            </div>
          : null}
        </div>
      </div>
    );
  }
});


export var CoincraftPageHeader = CreateReactClass({
  propTypes: {
    heading: PropTypes.node,
    leftButtons: PropTypes.array,
    filter: PropTypes.node,
    rightButtons: PropTypes.node,
    extraContent: PropTypes.node,
  },

  render: function() {
    return <div>
      <div className="flexbox-container flex-space-between">
        <div className="flex-1-1-auto flexbox-container flex-align-items-center">
          <div>
            <div className="heading">
              {this.props.heading}
            </div>
            <div>
              {this.props.subHeading}
            </div>
          </div>
          {this.props.leftButtons != null && this.props.leftButtons.length > 0 ?
            this.props.leftButtons
          : null}
        </div>
        <div className="buttons flex-0-0-auto">
          {this.props.filter != null ?
            <div className="search-field">
              {this.props.filter}
            </div>
          : null}
          {this.props.rightButtons}
        </div>
      </div>

      {this.props.extraContent}
    </div>;
  }
});
