import moment from "moment";
import _ from "underscore";
import {
	generateUUID,
	sum,
	isNumber,
	isDecimal,
	rangeIntersection,
	compareMoments,
	LinearScale,
	throwError,
} from "../utils.js";
import { getStaffExpensesInRange } from "../models.js";
import { organisationStore } from "../organisation.js";
import { DataObject } from "./dataobject.js";
import {
	Allocation,
	getStaffMembers,
	getStaffMemberHours,
} from "./allocation.js";
import { RangeAllocation } from "./rangeallocation.js";
import { Milestone, getDefaultMilestoneDates } from "./milestone.js";
import { dateConverter } from "./dateconverter.js";
import { InterpolationThingy } from "./interpolationthingy.js";
import { makeCashFlowItemsFromMilestones } from "./cashflowitem.js";
import { AllTime } from "../reports/DateRanges.js";
import { Task } from "./task.js";
import Immutable from "immutable";
import { Enum } from "../enum.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";

export const ProjectPhaseStatus = Enum([
	"prospective",
	"active",
	"onHold",
	"archived",
]);

export const ProjectPhase = class extends DataObject {
	constructor(options) {
		super(options);

		if (options == null) {
			options = {};
		}
		this.project = options.project;

		if (this.uuid == null) {
			this.uuid = generateUUID();
		}

		this.errors = [];
	}

	static getClassName() {
		return "ProjectPhase";
	}

	setFee(fee, updateMilestones = false) {
		if (this.fee === fee) return;
		this.fee = fee;
		this.updateStaffHoursBudgetsFromPhaseFee();
		if (updateMilestones) {
			this.scaleMilestonesToPhaseFee();
		}
	}

	getProject() {
		return this.project;
	}

	/**
	 * <Feed> interface.
	 */
	getFee() {
		return this.fee;
	}

	setDates(startDate, endDate) {
		/**
		 * `startDate`: number
		 * `endDate`: number
		 */
		this.startDate = startDate;
		this.endDate = endDate;
		this.project.updateDatesFromPhases();
	}

	setStartDate(startDate) {
		/**
		 * `startDate`: number
		 */
		this.setDates(startDate, this.endDate);
	}

	moveBy(diff) {
		this.adjustMilestones(this.startDate + diff, this.endDate + diff);

		this.allocations.forEach(function (rangeAllocation) {
			rangeAllocation.startDate += diff;
			rangeAllocation.endDate += diff;
		});

		this.project.startDate = Math.min(
			...this.project.getVisiblePhases().map((p) => p.startDate)
		);
		this.project.endDate = Math.max(
			...this.project.getVisiblePhases().map((p) => p.endDate)
		);
	}

	setEndDate(endDate) {
		/**
		 * `endDate`: number
		 */
		this.setDates(this.startDate, endDate);
	}

	setHours(hours) {
		if (this.manualHoursBudget === hours) return;
		this.manualHoursBudget = hours;
		this.updateStaffHoursBudgetsFromPhaseHoursBudget();
	}

	get likelihood() {
		if (
			this.percentLikelihood !== undefined &&
			this.percentLikelihood !== null &&
			(this.status === "prospective" || this.status === "onHold")
		) {
			return this.percentLikelihood;
		} else {
			return 100;
		}
	}

	set likelihood(val) {
		this.percentLikelihood = val;
	}

	setTotalAllocatedHours(hours) {
		let currentTotal = this.getTotalAllocatedHours();
		let ratio = hours / currentTotal;
		this.allocations.forEach((a) => (a.hours = a.hours * ratio));
	}

	setStaffMembers(staffMembers) {
		/**
		 * "Manual hours budgets vs. allocations"
		 * https://docs.google.com/document/d/1ukDNu1gpvLvjyvIVFCvEmzdaYY-6An4SIMFAnhqPOvM/edit
		 */
		let self = this;
		let newStaffMembers = Immutable.Set(staffMembers);
		let currentStaffMembers = Immutable.Set(
			this.allocation.getStaffMembers()
		);
		let addedStaffMembers = newStaffMembers.subtract(currentStaffMembers);
		let removedStaffMembers = currentStaffMembers.subtract(newStaffMembers);
		let removedStaffMemberIds = removedStaffMembers.map((sm) => sm.id);

		let hoursToDistribute = 0;
		if (staffMembers.length > 0) {
			let genericAllocation = _.find(
				this.allocations,
				(ra) =>
					ra.staffMember.id ===
					organisationStore.genericStaffMember.id
			);
			if (genericAllocation != null) {
				hoursToDistribute = genericAllocation.hours;
				removedStaffMemberIds = removedStaffMemberIds.add(
					organisationStore.genericStaffMember.id
				);
			} else {
				hoursToDistribute = this.manualHoursBudget;
			}
		}

		this.allocations = this.allocations.filter(
			(ra) => !removedStaffMemberIds.has(ra.staffMember.id)
		);

		for (let s of addedStaffMembers) {
			const hours = hoursToDistribute / staffMembers.length;
			self.allocations.push(
				new RangeAllocation({
					startDate: self.startDate,
					endDate: self.endDate,
					staffMember: s,
					staffRole: s.role,
					hours: hours,
				})
			);
		}
	}

	getStaffMemberAllocation(staffMember) {
		return sum(
			this.allocations
				.filter((ra) => ra.staffMember.id === staffMember.id)
				.map((ra) => ra.hours)
		);
	}

	addMilestone() {
		this.milestones.push(
			new Milestone({
				endDate: this.endDate,
				percent: Math.max(...this.milestones.map((m) => m.percent)),
				phase: this,
				allocation: this.allocation.mapHours((h) => 0),
			})
		);
	}

	createMilestones() {
		if (this.startDate && this.endDate && this.fee) {
			if (
				(this.project.milestoneType === "monthly" ||
					!this.project.milestoneType) &&
				this.endDate - this.startDate > 0
			) {
				this.milestones = dateConverter
					.splitValueIntoMonths(
						this.fee,
						this.getStartDate(),
						this.getEndDate()
					)
					.map((i) => {
						return new Milestone({
							phase: this,
							endDate: i.end,
							offsetDays: i.end - this.startDate,
							revenue: i.value,
							percent: (i.cumulativeValue / this.fee) * 100,
						});
					});
			} else {
				this.milestones = [
					new Milestone({
						phase: this,
						endDate: this.endDate,
						offsetDays: this.endDate - this.startDate,
						revenue: this.fee,
						percent: 100,
					}),
				];
			}
			this.setupMilestones();
		}
	}

	setupMilestones() {
		//this sets up calculated milstone values and links to neighbouring milestones
		if (
			this.milestones.length > 0 &&
			(_.min(this.milestones.map((m) => m.offsetDays)) !== 0 ||
				this.milestones.length === 1) &&
			this.startDate &&
			this.endDate
		) {
			this.milestones.unshift(
				new Milestone({
					phase: this,
					endDate: this.startDate,
					revenue: 0,
					percent: 0,
					offsetDays: 0,
					milestoneIndex: 0,
				})
			);
		}

		this.milestones.sort((a, b) => a.endDate - b.endDate);
		// add milstone to start to maintain accurate measurment of starteDate when changed
		// think of smarter way to do this

		this.milestones.forEach((m, i) => {
			m.phase = this;
			m.milestoneIndex = i;

			if (m.endDate && m.offsetDays == null) {
				m.offsetDays = m.endDate - this.startDate;
			}
			if (m.offsetDays != null && !m.endDate) {
				m.endDate = Math.round(this.startDate + m.offsetDays);
			}
			if (m.revenue != null && m.percent == null) {
				m.percent = m.totalRevenueToDate() / this.fee;
			}
			if (m.percent != null && m.revenue == null) {
				if (i !== 0) {
					m.revenue =
						(m.percent / 100) * this.fee -
						(this.milestones[i - 1].percent / 100) * this.fee;
				} else {
					m.revenue = (m.percent / 100) * this.fee;
				}
			}
			if (i !== 0) {
				m.prevMilestone = this.milestones[i - 1];
			}
			if (i !== this.milestones.length - 1) {
				m.nextMilestone = this.milestones[i + 1];
			}
		});

		this.milestones.sort((a, b) => a.endDate - b.endDate);
	}

	scaleMilestonesToPhaseDates() {
		// this.setupMilestones();
		// if (this.milestones.length > 0) {
		//   if ((this.project.milestoneType === 'monthly' || !this.project.milestoneType) && (this.endDate - this.startDate) > 0) {
		//     let milestoneDuration = _.max(this.milestones.map(m => m.endDate)) - _.min(this.milestones.map(m => m.endDate));
		//     let phaseDuration = this.endDate - this.startDate;
		//     let durationRatio = phaseDuration / milestoneDuration;
		//     this.milestones.forEach(m => m.setOffsetDays(m.offsetDays*durationRatio));
		//
		//     let mValPrev = 0;
		//     let newMilestones = (
		//       dateConverter.splitValueIntoMonths(
		//         this.fee,
		//         this.getStartDate(),
		//         this.getEndDate()
		//       ).map(i => {
		//         let valAtDate = this.getMilestoneValueAtDate(i.end);
		//         let mVal = valAtDate - mValPrev;
		//         mValPrev = valAtDate;
		//         return new Milestone({
		//           phase: this,
		//           endDate: i.end,
		//           offsetDays: i.end - this.startDate,
		//           revenue: mVal,
		//           percent: (valAtDate / this.fee) * 100
		//         })
		//       })
		//     );
		//     this.milestones = newMilestones;
		//     this.setupMilestones();
		//   } else {
		//     _.max(this.milestones, m => m.endDate).endDate = this.endDate;
		//   }
		// } else {
		this.createMilestones();
		// }
	}

	scaleMilestonesToPhaseFee() {
		let totalMilestoneRevenue = sum(this.milestones.map((m) => m.revenue));
		let feeRatio = this.fee / totalMilestoneRevenue;
		this.milestones.forEach((m) => (m.revenue = m.revenue * feeRatio));
	}

	getMilestoneValueAtDate(date) {
		let closestMilestoneAfter = this.milestones.filter(
			(m) => m.endDate >= date
		)[0];
		if (closestMilestoneAfter.endDate === date) {
			return closestMilestoneAfter.totalRevenueToDate();
		}
		let prevMilestone = closestMilestoneAfter.prevMilestone;
		let daysAfterPrevMilestone = date;
		if (prevMilestone) {
			daysAfterPrevMilestone = date - prevMilestone.endDate;
		}
		let percentOfMilestoneRevenue =
			daysAfterPrevMilestone /
			closestMilestoneAfter.distanceToPrevMilestone();
		let valueAtDate =
			closestMilestoneAfter.revenue * percentOfMilestoneRevenue;
		if (prevMilestone) {
			valueAtDate = valueAtDate + prevMilestone.totalRevenueToDate();
		}
		return valueAtDate;
	}

	removeMilestone(milestone) {
		/**
     * Desired behaviour:

       Initial state:
          Oct  - 50%  - $5000
          Nov  - 70%  - $2000  <-- delete this
          Dec  - 90%  - $2000
          Jan  - 100% - $1000

       Final state:
          Oct  - 50%  - $5000
          Dec  - 70%  - $2000  <-- 90 - diff
          Jan  - 80%  - $1000  <-- 100 - diff

       Note that the revenue values for all other milestones remain unchanged.
     */

		const milestoneIndex = _.findIndex(
			this.milestones,
			(m) => m === milestone
		);
		const previousPercent =
			milestoneIndex > 0
				? this.milestones[milestoneIndex - 1].percent
				: 0;
		const initialMilestoneDiff = milestone.percent - previousPercent;

		for (let i = milestoneIndex + 1; i < this.milestones.length; i++) {
			this.milestones[i].percent =
				this.milestones[i].percent - initialMilestoneDiff;
		}

		if (this.milestones.length === 1) {
			throw new Error("Can't delete last milestone");
		}
		this.milestones = _.without(this.milestones, milestone);
	}

	adjustMilestones(startDate, endDate, fee = null) {
		if (startDate != null) {
			this.startDate = startDate;
		}
		if (endDate != null) {
			this.endDate = endDate;
		}
		if (fee != null) {
			this.fee = fee;
		}
		if (this.areMilestonesOutOfSync()) {
			if (
				(this.project.milestoneType === "manual" &&
					this.milestones.length !== 2) ||
				(this.project.milestoneType === "monthly" &&
					this.milestones.length <= 2 &&
					this.getEndDate()
						.clone()
						.diff(this.getStartDate(), "months", true) > 1)
			) {
				this.createMilestones();
			}
			this.scaleMilestonesToPhaseDates();
			this.scaleMilestonesToPhaseFee();
		}
	}

	syncMilestones(remainingFee, startDate, endDate) {
		this.milestones = this.milestones.filter(
			(m) => m.endDate < dateConverter.startOfCurrentMonthInt
		);
		this.splitValueIntoMonths(remainingFee, startDate, endDate, (mi, val) =>
			this.setTotalMilestoneRevenueInMonth(val, mi)
		);
	}

	splitValueIntoMonths(value, startMoment, endMoment, monthValueFunction) {
		const monthsPerMonthIndex = dateConverter.momentMonthsPerMonthIndex(
			startMoment,
			endMoment
		);
		const valuePerMonth = this.getValuePerMonthFromLookup(
			value,
			monthsPerMonthIndex
		);
		_.keys(monthsPerMonthIndex).forEach((mi) => {
			monthValueFunction(mi, valuePerMonth * monthsPerMonthIndex[mi]);
		});
	}

	getValuePerMonthFromMoment(value, startMoment, endMoment) {
		return this.getValuePerMonthFromLookup(
			value,
			dateConverter.momentMonthsPerMonthIndex(startMoment, endMoment)
		);
	}

	getValuePerMonthFromLookup(value, monthIndexLookup) {
		const numMonths = sum(_.values(monthIndexLookup));
		return value / numMonths;
	}

	get hasDates() {
		return this.startDate && this.endDate;
	}

	get isCurrent() {
		return (
			this.hasDates &&
			this.startDate <= dateConverter.todayInt &&
			this.endDate >= dateConverter.todayInt
		);
	}

	get numMonths() {
		return (
			Math.round(
				dateConverter.diffMomentMonths(
					this.getStartDate(),
					this.getEndDate().add(1, "day")
				) * 10
			) / 10
		);
	}

	get numRemainingMonths() {
		return (
			Math.round(
				dateConverter.diffMomentMonths(
					dateConverter.maxMoment(
						dateConverter.startOfCurrentMonth,
						this.getStartDate()
					),
					this.getEndDate().add(1, "day")
				) * 10
			) / 10
		);
	}

	get remainingRevenueStartDate() {
		const currentMonthRevenue = this.getRevenueInCurrentMonth();
		if (currentMonthRevenue === 0) {
			return dateConverter.maxMoment(
				dateConverter.startOfCurrentMonth,
				this.getStartDate()
			);
		} else {
			const remainingRevenue = this.fee - this.getRevenueInPast();
			const monthsPerMonthIndex = dateConverter.momentMonthsPerMonthIndex(
				this.getStartDate(),
				this.getEndDate()
			);
			const revenuePerMonth = this.getValuePerMonthFromLookup(
				remainingRevenue,
				monthsPerMonthIndex
			);
			const projectedRevenueForCurrentMonth =
				monthsPerMonthIndex[dateConverter.currentMonthIndex] *
				revenuePerMonth;
			if (currentMonthRevenue < projectedRevenueForCurrentMonth) {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth,
					this.getStartDate()
				);
			} else {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth.add(1, "month"),
					this.getStartDate()
				);
			}
		}
	}

	get remainingRevenue() {
		return Math.max(
			this.fee -
				this.getRevenueInDateRange(
					{
						start: null,
						end: this.remainingRevenueStartDate
							.clone()
							.subtract(1, "day"),
					},
					"agreedFee"
				),
			0
		);
	}

	getRevenueInPast() {
		return this.getRevenueInDateRange(
			{ start: null, end: moment() },
			"agreedFee"
		);
	}

	getRevenueBeforeCurrentMonth() {
		return this.getRevenueInDateRange(
			{ start: null, end: moment().startOf("month") },
			"agreedFee"
		);
	}

	getRevenueInCurrentMonth() {
		return this.getRevenueInDateRange(
			{ start: moment().startOf("month"), end: moment().endOf("month") },
			"agreedFee"
		);
	}

	updateMilestonePercentsBasedOnRevenue() {
		let cumulativeRevenue = 0;
		this.milestones
			.filter((m) => !(m.endDate == this.startDate && m.revenue == 0))
			.forEach((m) => {
				cumulativeRevenue += m.revenue;
				m.percent = (cumulativeRevenue / this.fee) * 100;
			});
	}

	updateMilestoneRevenuesBasedOnPercent() {
		let prevCumulativeRevenue = 0;
		this.milestones
			.filter((m) => !(m.endDate == this.startDate && m.revenue == 0))
			.forEach((m) => {
				let cumulativeRevenue = (m.percent / 100) * this.fee;
				m.revenue = cumulativeRevenue - prevCumulativeRevenue;
				prevCumulativeRevenue = cumulativeRevenue;
			});
	}

	updateFromMilestones() {
		/**
		 * Set `allocation`, `hours`, `staffExpenses` and `staffChargeOut`.
		 */
		let allocation = new Allocation();
		this.allocations
			.filter((ra) => ra.staffMember)
			.forEach(function (rangeAllocation) {
				allocation.addStaffHours(
					rangeAllocation.staffMember,
					rangeAllocation.hours
				);
			});
		this.allocation = allocation;
		this.hours = allocation.getTotalAllocatedHours();

		this.updateFromAllocations();
	}

	updateFromAllocations() {
		const { expenses, chargeOut } = this.getStaffExpensesFromAllocations();
		this.staffExpenses = expenses;
		this.staffChargeOut = chargeOut;
	}

	getStaffMembers() {
		return getStaffMembers(this.iterAllocations());
	}

	getStaffMemberAllocations() {
		return getStaffMemberHours(this.iterAllocations());
	}

	*iterAllocations() {
		for (let p of this.allocations) {
			yield p;
		}
	}

	getStaffExpensesFromAllocations(staffMember = null, payField = "costRate") {
		/**
		 * `payField`: 'payRate` | 'costRate'
		 */
		let staffExpenses = 0;
		let staffChargeOut = 0;
		for (let ra of this.allocations) {
			if (
				staffMember == null ||
				(ra.staffMember != null && ra.staffMember.id === staffMember.id)
			) {
				let numWeekdays = dateConverter.numWeekdaysBetween(
					ra.startDate,
					ra.endDate
				);
				if (numWeekdays > 0) {
					const { expenses, chargeOut } = getStaffExpensesInRange(
						ra.staffMember,
						ra.hours / numWeekdays,
						[ra.startDate, ra.endDate],
						payField
					);
					staffExpenses += expenses;
					staffChargeOut += chargeOut;
				}
			}
		}
		return {
			expenses: staffExpenses,
			chargeOut: staffChargeOut,
		};
	}

	setStaffAllocation(staffMember, hours) {
		const currentTotalHours =
			this.allocation.getStaffMemberAllocation(staffMember) || 0;
		const matchingAllocations = this.allocations.filter(
			(ra) => ra.staffMember && ra.staffMember.id === staffMember.id
		);
		const numMatchingAllocations = matchingAllocations.length;
		for (let rangeAllocation of matchingAllocations) {
			if (currentTotalHours > 0) {
				rangeAllocation.hours =
					hours * (rangeAllocation.hours / currentTotalHours);
			} else {
				rangeAllocation.hours = hours / numMatchingAllocations;
			}
		}
		this.updateFromAllocations();
	}

	setRoleAllocation(staffRole, hours) {
		const currentTotalHours =
			sum(
				this.allocations
					.filter(
						(a) => !a.staffMember && a.staffRole.id === staffRole.id
					)
					.map((a) => a.hours)
			) || 0;
		const matchingAllocations = this.allocations.filter(
			(a) => !a.staffMember && a.staffRole.id === staffRole.id
		);
		const numMatchingAllocations = matchingAllocations.length;
		for (let rangeAllocation of matchingAllocations) {
			if (currentTotalHours > 0) {
				rangeAllocation.hours =
					hours * (rangeAllocation.hours / currentTotalHours);
			} else {
				rangeAllocation.hours = hours / numMatchingAllocations;
			}
		}
		this.updateFromAllocations();
	}

	setMilestoneEndDate(milestone, endDate) {
		// `endDate`: int-date
		milestone.endDate = endDate;
		this.endDate = Math.max(...this.milestones.map((m) => m.endDate));
	}

	setMilestonePercent(milestone, percent) {
		milestone.setPercent(percent);
	}

	setRangeAllocationStartDate(rangeAllocation, startDate) {
		let staffMemberAllocations = this.allocations.filter(
			(ra) => ra.staffMember.id === rangeAllocation.staffMember.id
		);
		staffMemberAllocations.sort((a, b) => a.startDate - b.startDate);

		let allocationIndex = _.findIndex(
			staffMemberAllocations,
			(ra) => ra === rangeAllocation
		);

		if (
			allocationIndex > 0 &&
			staffMemberAllocations[allocationIndex - 1].endDate >= startDate
		) {
			let diff = startDate - rangeAllocation.startDate;
			for (let i = allocationIndex - 1; i >= 0; i--) {
				staffMemberAllocations[i].startDate += diff;
				staffMemberAllocations[i].endDate += diff;
			}
		}
		rangeAllocation.startDate = startDate;
		this.updateFromAllocations();
	}

	setRangeAllocationEndDate(rangeAllocation, endDate) {
		let staffMemberAllocations = this.allocations.filter(
			(ra) => ra.staffMember.id === rangeAllocation.staffMember.id
		);
		staffMemberAllocations.sort((a, b) => a.startDate - b.startDate);

		let allocationIndex = _.findIndex(
			staffMemberAllocations,
			(ra) => ra === rangeAllocation
		);

		if (
			allocationIndex < staffMemberAllocations.length - 1 &&
			staffMemberAllocations[allocationIndex + 1].startDate <= endDate
		) {
			let diff = endDate - rangeAllocation.endDate;
			for (
				let i = allocationIndex + 1;
				i < staffMemberAllocations.length;
				i++
			) {
				staffMemberAllocations[i].startDate += diff;
				staffMemberAllocations[i].endDate += diff;
			}
		}
		rangeAllocation.endDate = endDate;
		this.updateFromAllocations();
	}

	getAllocationRange(staffMember = null) {
		let startDate = null,
			endDate = null;
		for (let rangeAllocation of this.allocations) {
			if (
				staffMember == null ||
				rangeAllocation.staffMember.id === staffMember.id
			) {
				if (
					startDate == null ||
					rangeAllocation.startDate < startDate
				) {
					startDate = rangeAllocation.startDate;
				}
				if (endDate == null || rangeAllocation.endDate > endDate) {
					endDate = rangeAllocation.endDate;
				}
			}
		}

		return [startDate, endDate];
	}

	setAllocationDates(startDate = null, endDate = null, staffMember = null) {
		/**
		 * If `startDate == null`, keep start date as is.
		 * If `endDate == null`, keep end date as is.
		 * If `staffMember == null`, apply to all allocations.
		 */

		let [initialStartDate, initialEndDate] =
			this.getAllocationRange(staffMember);

		if (startDate == null) {
			startDate = initialStartDate;
		}
		if (endDate == null) {
			endDate = initialEndDate;
		}

		let isReversed = startDate > endDate;
		if (isReversed) {
			[startDate, endDate] = [endDate, startDate];
		}

		let factor =
			(endDate - startDate) / (initialEndDate - initialStartDate);

		for (let rangeAllocation of this.allocations) {
			if (
				staffMember == null ||
				rangeAllocation.staffMember.id === staffMember.id
			) {
				if (isNumber(factor)) {
					rangeAllocation.startDate =
						startDate +
						(rangeAllocation.startDate - initialStartDate) * factor;
					rangeAllocation.endDate =
						startDate +
						(rangeAllocation.endDate - initialStartDate) * factor;
				} else {
					rangeAllocation.startDate = startDate;
					rangeAllocation.endDate = endDate;
				}
			}
		}

		this.updateFromAllocations();
		return isReversed;
	}

	mergeAllocations() {
		let mergedAllocations = {};
		this.allocations.forEach((a) => {
			const id = `${a.staffMember?.id}-${a.staffRole?.id}-${a.startDate}-${a.endDate}`;
			const mergedA =
				mergedAllocations[id] ||
				new RangeAllocation({
					startDate: a.startDate,
					endDate: a.endDate,
					staffMember: a.staffMember,
					staffRole: a.staffRole,
					hours: 0,
				});
			mergedA.hours += a.hours;
			mergedAllocations[id] = mergedA;
		});
		this.allocations = _.values(mergedAllocations);
	}

	setStaffMemberHours(staffMember = null, startDate, endDate, hours) {
		if (
			dateConverter.getMonthIndex(startDate) !==
			dateConverter.getMonthIndex(endDate)
		) {
			throw new Error(
				"Only supporting setting hours for a single month currently"
			);
		}

		let phase = this;
		let initialHours = 0;
		phase.allocations
			.filter((a) => a.staffMember)
			.forEach(function (rangeAllocation) {
				initialHours +=
					rangeAllocation.getHoursForStaffMemberInDateRange(
						staffMember,
						startDate,
						endDate
					);
			});

		if (initialHours > 0) {
			let factor = hours / initialHours;
			phase.allocations = _.flatten(
				phase.allocations
					.filter((a) => a.staffMember)
					.map(function (rangeAllocation) {
						if (staffMember != null) {
							return rangeAllocation.scaleStaffMemberAndDateRange(
								staffMember,
								startDate,
								endDate,
								factor
							);
						} else {
							return rangeAllocation.scaleSectionByFactor(
								startDate,
								endDate,
								factor
							);
						}
					})
			);
		} else {
			/**
			 * If no existing hours to scale, divide evenly among the working days of the
			 * allocations that intersect with the required range.
			 */

			let totalIntersectingWeekdays = 0;

			let intersections = [];
			for (let rangeAllocation of phase.allocations.filter(
				(a) => a.staffMember
			)) {
				if (
					staffMember == null ||
					rangeAllocation.staffMember.id === staffMember.id
				) {
					let intersection = rangeIntersection(
						[rangeAllocation.startDate, rangeAllocation.endDate],
						[startDate, endDate]
					);
					let numIntersectingWeekdays =
						intersection != null
							? dateConverter.numWeekdaysBetween(
									intersection[0],
									intersection[1]
							  )
							: 0;
					intersections.push(numIntersectingWeekdays);
					totalIntersectingWeekdays += numIntersectingWeekdays;
				} else {
					// Just so the arrays line up when we loop again.
					intersections.push(null);
				}
			}

			if (totalIntersectingWeekdays > 0) {
				phase.allocations = _.flatten(
					phase.allocations
						.filter((a) => a.staffMember)
						.map(function (rangeAllocation, i) {
							let numIntersectingWeekdays = intersections[i];
							if (
								(staffMember == null ||
									rangeAllocation.staffMember.id ===
										staffMember.id) &&
								numIntersectingWeekdays > 0
							) {
								return rangeAllocation.setStaffMemberAndDateHours(
									staffMember,
									startDate,
									endDate,
									(hours * numIntersectingWeekdays) /
										totalIntersectingWeekdays
								);
							} else {
								return rangeAllocation;
							}
						})
				);
			} else {
				phase.allocations.push(
					new RangeAllocation({
						startDate: startDate,
						endDate: endDate,
						staffMember:
							staffMember || organisationStore.genericStaffMember,
						staffRole: staffMember ? staffMember.role : null,
						hours: hours,
					})
				);
			}
		}
		this.updateFromAllocations();
	}

	setStaffRoleHours(staffRole = null, startDate, endDate, hours) {
		if (
			dateConverter.getMonthIndex(startDate) !==
			dateConverter.getMonthIndex(endDate)
		) {
			throw new Error(
				"Only supporting setting hours for a single month currently"
			);
		}

		let phase = this;
		let initialHours = 0;
		phase.allocations
			.filter((a) => a.staffRole)
			.forEach(function (rangeAllocation) {
				initialHours += rangeAllocation.getHoursForStaffRoleInDateRange(
					staffRole,
					startDate,
					endDate
				);
			});

		if (initialHours > 0) {
			let factor = hours / initialHours;
			phase.allocations = _.flatten(
				phase.allocations
					.filter((a) => a.staffRole)
					.map(function (rangeAllocation) {
						if (staffRole != null) {
							return rangeAllocation.scaleStaffRoleAndDateRange(
								staffRole,
								startDate,
								endDate,
								factor
							);
						} else {
							return rangeAllocation.scaleSectionByFactor(
								startDate,
								endDate,
								factor
							);
						}
					})
			);
		} else {
			/**
			 * If no existing hours to scale, divide evenly among the working days of the
			 * allocations that intersect with the required range.
			 */

			let totalIntersectingWeekdays = 0;

			let intersections = [];
			for (let rangeAllocation of phase.allocations.filter(
				(a) => a.staffRole
			)) {
				if (
					staffRole == null ||
					rangeAllocation.staffRole.id === staffRole.id
				) {
					let intersection = rangeIntersection(
						[rangeAllocation.startDate, rangeAllocation.endDate],
						[startDate, endDate]
					);
					let numIntersectingWeekdays =
						intersection != null
							? dateConverter.numWeekdaysBetween(
									intersection[0],
									intersection[1]
							  )
							: 0;
					intersections.push(numIntersectingWeekdays);
					totalIntersectingWeekdays += numIntersectingWeekdays;
				} else {
					// Just so the arrays line up when we loop again.
					intersections.push(null);
				}
			}

			if (totalIntersectingWeekdays > 0) {
				phase.allocations = _.flatten(
					phase.allocations
						.filter((a) => a.staffRole)
						.map(function (rangeAllocation, i) {
							let numIntersectingWeekdays = intersections[i];
							if (
								(staffRole == null ||
									rangeAllocation.staffRole.id ===
										staffRole.id) &&
								numIntersectingWeekdays > 0
							) {
								return rangeAllocation.setStaffRoleAndDateHours(
									staffRole,
									startDate,
									endDate,
									(hours * numIntersectingWeekdays) /
										totalIntersectingWeekdays
								);
							} else {
								return rangeAllocation;
							}
						})
				);
			} else {
				phase.allocations.push(
					new RangeAllocation({
						startDate: startDate,
						endDate: endDate,
						staffRole: staffRole,
						hours: hours,
					})
				);
			}
		}
		this.updateFromAllocations();
	}

	printAllocations() {
		/* eslint-disable no-console */
		this.allocations.forEach(function (ra) {
			console.log(
				"RA",
				dateConverter.intToString(ra.startDate),
				dateConverter.intToString(ra.endDate),
				ra.staffMember.email,
				ra.hours
			);
		});
		console.log(
			"milestones",
			this.milestones.map((m) => m.percent)
		);
		/* eslint-enable no-console */
	}

	getProfitPercent() {
		return ((this.fee - this.staffExpenses) / this.fee) * 100;
	}

	getProfit() {
		return this.fee - this.staffExpenses;
	}

	getCashFlowItems(startDate, endDate, billingType = "any") {
		let self = this;
		return this.project.changeLog
			.filter(function (cli) {
				return cli.revenue > 0 &&
					cli.phase != null &&
					cli.phase.id === self.id &&
					cli.date != null &&
					(startDate == null || !cli.date.isBefore(startDate)) &&
					(endDate == null || !cli.date.isAfter(endDate)) &&
					billingType === "any"
					? true
					: cli.billingType === billingType;
			})
			.map((cli) => cli.toCashFlowItem());
	}

	getActuals() {
		const now = moment();
		/**
		 * Returns: Array<CashFlowItem>.
		 *
		 * Returns this phase's change log items for dates up to the end of the current month
		 * and its milestones for dates starting from the start of the current month.
		 */
		const startOfMonth = now.clone().startOf("month");
		const endOfMonth = now.clone().endOf("month");

		return [
			...this.getCashFlowItems(null, endOfMonth),
			...makeCashFlowItemsFromMilestones(
				this.getVisibleMilestones().filter((m) => {
					return m.endDate && m.getEndDate().isAfter(startOfMonth);
				})
			),
		];
	}

	getTotalProjectedRevenue() {
		return Math.round(sum(this.getActuals().map((cfi) => cfi.fee)));
	}

	getActualsInRange(startDateInt, endDateInt) {
		const now = moment();
		/**
		 * Returns: Array<CashFlowItem>.
		 *
		 * Returns this phase's change log items for dates up to the end of the current month
		 * and its milestones for dates starting from the start of the current month.
		 */
		const startOfMonth = now.clone().startOf("month");
		const endOfMonth = now.clone().endOf("month");
		const startMoment = dateConverter.intToMoment(startDateInt);
		const endMoment = dateConverter.intToMoment(endDateInt);

		return [
			...this.getCashFlowItems(startMoment, endMoment),
			...makeCashFlowItemsFromMilestones(
				this.getVisibleMilestones().filter((m) => {
					return (
						m.endDate && m.getEndDate().isSameOrAfter(startOfMonth)
					);
				})
			),
		];
	}

	getMilestonesInRange(startDateInt, endDateInt) {
		return this.getVisibleMilestones().filter((m) => {
			return (
				m.endDate &&
				m.endDate >= startDateInt &&
				m.endDate <= endDateInt
			);
		});
	}

	getMilestonesInMonth(monthIndex) {
		const startMoment = dateConverter.monthIndexToMoment(monthIndex);
		const startDateInt = dateConverter.momentToInt(startMoment);
		const endDateInt = dateConverter.momentToInt(
			startMoment.clone().endOf("month")
		);
		return this.getMilestonesInRange(startDateInt, endDateInt);
	}

	removeMilestonesInMonth(monthIndex) {
		let milestones = this.getMilestonesInMonth(monthIndex);
		milestones.forEach((m) => {
			this.removeMilestone(m);
		});
	}

	setTotalMilestoneRevenueInMonth(totalRevenue, monthIndex) {
		let milestones = this.getMilestonesInMonth(monthIndex);
		if (milestones.length === 0) {
			const startMoment = dateConverter.monthIndexToMoment(monthIndex);
			const endDateInt = dateConverter.momentToInt(
				startMoment.clone().endOf("month")
			);
			let newMilestone = new Milestone({
				endDate: endDateInt,
				revenue: totalRevenue,
				phase: this,
				allocation: this.allocation.mapHours((h) => 0),
			});
			this.milestones.push(newMilestone);
		} else {
			const milestoneTotal = sum(milestones.map((m) => m.revenue));
			const ratio =
				totalRevenue /
				(milestoneTotal
					? sum(milestones.map((m) => m.revenue))
					: milestones.length);
			milestones.forEach((m) => {
				m.setRevenue(milestoneTotal ? m.revenue * ratio : ratio);
			});
		}
		this.updateMilestonePercentsBasedOnRevenue();
		this.setupMilestones();
	}

	setMilestones(milestones) {
		this.milestones = milestones;
		this.setupMilestones();
		this.totalRevenue = sum(this.milestones.map((m) => m.revenue));
	}

	defaults() {
		return {
			id: null,
			uuid: generateUUID(),
			name: "",
			jobCode: null,
			isDeleted: false,
			status: ProjectPhaseStatus.active,
			percentLikelihood: 100,

			budget: null,
			fee: 0,
			totalRevenue: null,

			staffMinutesLoggedToDate: null,
			currentStaffMinutesLoggedToDate: null,
			unbilledChargeOut: null,

			staffMinutes: {},
			currentStaffMinutes: {},

			numTimesheetMinutesLogged: 0,

			allocation: new Allocation(),
			allocations: [],

			tasks: Immutable.List([]),

			startDate: null,
			endDate: null,

			hours: 0,
			offsetDays: null,
			numDays: null,
			milestones: [],

			projectId: null,

			manualBudget: null,
			manualHoursBudget: null,
			staffMemberBudgetedHours: [],
			staffRoleBudgetedHours: [],

			isRevenueSpreadsheetModified: false,

			durationUnit: "weeks",
			hoursBudgetLinked: false,
			expenseBudgetLinked: false,
			feeLinked: false,
		};
	}

	static fieldTypes() {
		return {
			id: "int",
			uuid: "string",
			projectId: "int",
			name: "string",
			status: "string",
			percentLikelihood: "number",
			jobCode: "string",
			isDeleted: "bool",
			startDate: "date",
			endDate: "date",
			allocation: "dict",
			allocations: "array",
			tasks: "array", // Actually an Immutable.List
			numDays: "number",
			budget: "number",
			staffMinutesLoggedToDate: "dict",
			currentStaffMinutesLoggedToDate: "dict",
			staffMinutes: "array",
			currentStaffMinutes: "array",
			unbilledChargeOut: "number",
			fee: "number",
			totalRevenue: "number",
			milestones: ["Milestone"],
			manualBudget: "number",
			manualHoursBudget: "number",

			// [{staffMember: <StaffMember>, hours: number, phase: this}]
			staffMemberBudgetedHours: "list",
			// [{staffRole: <StaffRole>, hours: number, phase: this}],
			staffRoleBudgetedHours: "list",
			isRevenueSpreadsheetModified: "bool",

			durationUnit: "string",
			hoursBudgetLinked: "bool",
			expenseBudgetLinked: "bool",
			feeLinked: "bool",
		};
	}

	static transformArgs(objectData, organisation) {
		let args = super.transformArgs(objectData, organisation);
		args.tasks = Immutable.List(
			args.tasks != null ? args.tasks.map((t) => new Task(t)) : []
		);
		return args;
	}

	static fieldsForSerialize() {
		return [
			"id",
			"uuid",
			"projectId",
			"name",
			"status",
			"percentLikelihood",
			"jobCode",
			"startDate",
			"endDate",
			"budget",
			"fee",
			"totalRevenue",
			"isDeleted",
			"hours",
			"offsetDays",
			"milestones",
			"manualBudget",
			"manualHoursBudget",
			"tasks",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"isRevenueSpreadsheetModified",
			"durationUnit",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
			"feeLinked",
		];
	}

	copy({ cloneIdentity = true } = {}) {
		var phase = new ProjectPhase({
			id: cloneIdentity ? this.id : null,
			uuid: cloneIdentity ? this.uuid : null,
			name: _.clone(this.name),
			status: this.status,
			percentLikelihood: this.percentLikelihood,
			jobCode: this.jobCode,
			isDeleted: this.isDeleted,
			budget: this.budget,
			fee: this.fee,
			totalRevenue: this.totalRevenue,
			staffCostsToDate: this.staffCostsToDate,
			staffMinutesLoggedToDate: this.staffMinutesLoggedToDate,
			unbilledChargeOut: this.unbilledChargeOut,
			startDate: this.startDate,
			endDate: this.endDate,
			hours: this.hours,
			offsetDays: this.offsetDays,
			numDays: this.numDays,
			allocation: this.allocation.clone(),
			allocations: this.allocations.map((ra) =>
				ra.copy({ cloneIdentity: cloneIdentity })
			),
			manualBudget: this.manualBudget,
			manualHoursBudget: this.manualHoursBudget,
			staffMemberBudgetedHours: _.clone(this.staffMemberBudgetedHours),
			staffRoleBudgetedHours: _.clone(this.staffRoleBudgetedHours),
			tasks: this.getVisibleTasks().map((t) => t.copy()),
			isRevenueSpreadsheetModified: this.isRevenueSpreadsheetModified,
			durationUnit: this.durationUnit,
			hoursBudgetLinked: this.hoursBudgetLinked,
			expenseBudgetLinked: this.expenseBudgetLinked,
			feeLinked: this.feeLinked,
		});

		phase.staffExpenses = this.staffExpenses;
		phase.milestones = this.milestones.map((m) =>
			m.copy({ cloneIdentity: cloneIdentity })
		);
		phase.milestones.forEach(function (m) {
			m.phase = phase;
		});
		return phase;
	}

	clone() {
		return this.copy({ cloneIdentity: false });
	}

	serialize() {
		if (isNumber(this.startDate) || isNumber(this.endDate)) {
			var p = this.copy();
			if (p.startDate != null) {
				p.startDate = p.getStartDate();
			}
			if (p.endDate != null) {
				p.endDate = p.getEndDate();
			}
			return p.serialize();
		} else {
			return super.serialize();
		}
	}

	isValid() {
		this.validate();
		return this.errors.length === 0;
	}

	validate() {
		this.validateName();
		this.validateDates();
	}

	validateName() {
		let isValid = !(this.name == "" || this.name == null);
		if (!isValid) {
			this.errors.push("Please enter a phase name");
		} else {
			this.errors = _.without(this.errors, "Please enter a phase name");
		}
	}

	validateDates() {
		let isValid =
			(this.startDate == null && this.endDate == null) ||
			(this.startDate != null &&
				this.endDate != null &&
				this.startDate <= this.endDate);
		if (!isValid) {
			this.errors.push(
				"Please make sure phase start dates occur after end dates"
			);
		} else {
			this.errors = _.without(
				this.errors,
				"Please make sure phase start dates occur after end dates"
			);
		}
	}

	getTitle() {
		if (this.jobCode != null && this.jobCode !== "") {
			return `${this.jobCode}: ${this.name}`;
		} else if (this.name != null && this.name !== "") {
			return this.name;
		} else {
			return "(Unnamed phase)";
		}
	}

	hasIncome() {
		return (
			this.startDate != null &&
			this.endDate != null &&
			isNumber(this.fee) &&
			this.fee !== 0
		);
	}

	hasHours() {
		return (
			(this.allocations.length > 0 &&
				_.some(this.allocations, (a) => a.hours > 0)) ||
			this.staffMinutesLoggedToDate > 0
		);
	}

	get hasTimesheets() {
		return sum(_.values(this.staffMinutes));
	}

	getStartDate() {
		// We need to be careful not to call `Project.getStartDate` from
		// `ProjectPhase.getStartDate` since `Project.getStartDate` calls
		// `ProjectPhase.getStartDate`.
		if (this.startDate == null) {
			return null;
		}
		if (!isNumber(this.startDate)) {
			throw new Error("startDate should have been a number");
		}
		return dateConverter.intToMoment(this.startDate);
	}

	getEndDate() {
		// We need to be careful not to call `Project.getEndDate` from
		// `ProjectPhase.getEndDate` since `Project.getEndDate` calls
		// `ProjectPhase.getEndDate`.

		if (this.endDate == null) {
			return null;
		}
		if (!isNumber(this.endDate)) {
			throw new Error("endDate should have been a number");
		}
		return dateConverter.intToMoment(this.endDate);
	}

	getDuration() {
		if (this.startDate == null || this.endDate == null) {
			return null;
		} else if (this.durationUnit === "months") {
			return this.getEndDate()
				.clone()
				.add(1, "day")
				.diff(this.getStartDate(), "months", true);
		} else if (this.durationUnit === "weeks") {
			return (this.endDate - this.startDate + 1) / 7;
		} else {
			return this.endDate - this.startDate + 1;
		}
	}

	getStaffCost(store) {
		return sum(this.milestones.map((m) => m.getStaffCost(store)));
	}

	getStaffMinutesLoggedToDate() {
		return this.staffMinutesLoggedToDate;
	}

	getBudget() {
		return this.manualBudget || 0;
	}

	getTotalInvoicedToDate() {
		let matchesPhase =
			this.id == null
				? (p) => p == null
				: (p) => p != null && p.id === this.id;
		return this.project.changeLog
			.filter((cli) => matchesPhase(cli.phase))
			.map((cli) => cli.revenue || 0)
			.reduce((a, b) => a + b, 0);
	}

	getInterpolationThingy() {
		return new InterpolationThingy([
			{ x: this.startDate, y: 0 },
			...this.milestones.map((m) => ({ x: m.endDate, y: m.percent })),
		]);
	}

	percentageAt(d) {
		return this.getInterpolationThingy().interpolate(d);
	}

	getStaffAllocation(staffMember) {
		/**
		 * Returns `null` if the staff member is not explicitly allocated any hours.
		 */
		//TODO-schedule_generic_hours
		return this.allocation.dict[staffMember.id] || null;
	}

	getTotalAllocation() {
		return this.allocation.getTotalAllocatedHours();
	}

	getAndResetHasJustAddedTask() {
		let r = this.hasJustAddedTask;
		this.hasJustAddedTask = false;
		return r;
	}

	syncAllocationDates() {
		/**
		 * Scale the allocation dates so they start at the phase start date and end
		 * at the phase end date.
		 */
		this.setAllocationDates(this.startDate, this.endDate, null);
	}

	getCombinedBudgetedHours() {
		const self = this;
		return [
			...this.staffRoleBudgetedHours.map((srbh) => {
				return {
					item: srbh.staffRole,
					hours: srbh.hours,
					label: srbh.staffRole.name,
					phase: self,
				};
			}),
			...this.staffMemberBudgetedHours.map((smbh) => {
				return {
					item: smbh.staffMember,
					hours: smbh.hours,
					label: smbh.staffMember.getFullName(),
					phase: self,
				};
			}),
		];
	}

	createAllocationsFromStaffBudgets() {
		if (this.areAllocationsOutOfSync()) {
			this.allocations = [];
			if (this.startDate && this.endDate) {
				this.staffMemberBudgetedHours.forEach((smbh) => {
					this.allocations.push(
						new RangeAllocation({
							startDate: dateConverter.momentToInt(
								this.getRemainingStaffHoursStartDate(
									smbh.staffMember
								)
							),
							endDate: this.endDate,
							staffMember: smbh.staffMember,
							staffRole: smbh.staffMember.role,
							hours: this.getRemainingStaffHours(
								smbh.staffMember
							),
						})
					);
				});
				this.staffRoleBudgetedHours.forEach((smbh) => {
					this.allocations.push(
						new RangeAllocation({
							startDate: dateConverter.momentToInt(
								this.getRemainingRoleHoursStartDate(
									smbh.staffRole
								)
							),
							endDate: this.endDate,
							staffRole: smbh.staffRole,
							hours:
								this.getRemainingRoleHours(smbh.staffRole) || 0,
						})
					);
				});
			}
		}
	}

	syncStaffMemberAllocations(staffMember, hours, startDate, endDate) {
		let newAllocations = [];
		newAllocations.push(
			new RangeAllocation({
				startDate: dateConverter.momentToInt(startDate),
				endDate: dateConverter.momentToInt(endDate),
				staffMember: staffMember,
				staffRole: staffMember.role,
				hours: hours || 0,
				phase: this,
			})
		);
		//remove allocations for this staff member then push new ones
		this.allocations = this.allocations.filter(
			(a) => !a.staffMember || !(a.staffMember.id === staffMember.id)
		);
		this.allocations.push(...newAllocations);
	}

	syncStaffRoleAllocations(staffRole, hours, startDate, endDate) {
		let newAllocations = [];
		newAllocations.push(
			new RangeAllocation({
				startDate: dateConverter.momentToInt(startDate),
				endDate: dateConverter.momentToInt(endDate),
				staffRole: staffRole,
				hours: hours || 0,
				phase: this,
			})
		);
		//remove allocations for this staff role then push new ones
		this.allocations = this.allocations.filter(
			(a) =>
				!(
					!a.staffMember &&
					a.staffRole &&
					a.staffRole.id === staffRole.id
				)
		);
		this.allocations.push(...newAllocations);
	}

	getRemainingStaffHours(staffMember) {
		const startDate = this.getRemainingStaffHoursStartDate(staffMember);
		const addCurrentMonth =
			dateConverter.momentToMonthIndex(startDate) ===
			dateConverter.currentMonthIndex;
		const remainingHours =
			this.getBudgetedHoursForStaffMember(staffMember) -
			this.getStaffRecordedHours(staffMember) +
			(addCurrentMonth
				? this.getStaffRecordedHoursCurrentMonth(staffMember)
				: 0);
		return Math.max(remainingHours, 0);
	}

	getRemainingRoleHours(staffRole) {
		const startDate = this.getRemainingRoleHoursStartDate(staffRole);
		const addCurrentMonth =
			dateConverter.momentToMonthIndex(startDate) ===
			dateConverter.currentMonthIndex;
		const remainingHours =
			this.getBudgetedHoursForStaffRole(staffRole) -
			this.getRoleRecordedHours(staffRole) +
			(addCurrentMonth
				? this.getRoleRecordedHoursCurrentMonth(staffRole)
				: 0);
		return Math.max(remainingHours, 0);
	}

	getRemainingStaffHoursStartDate(staffMember) {
		const currentMonthHours =
			this.getStaffRecordedHoursCurrentMonth(staffMember);
		if (currentMonthHours === 0) {
			return dateConverter.maxMoment(
				dateConverter.startOfCurrentMonth,
				this.getStartDate()
			);
		} else {
			const remainingHours =
				this.getBudgetedHoursForStaffMember(staffMember) -
				this.getStaffRecordedHours(staffMember);
			const monthsPerMonthIndex = dateConverter.momentMonthsPerMonthIndex(
				this.getStartDate(),
				this.getEndDate()
			);
			const hoursPerMonth = this.getValuePerMonthFromLookup(
				remainingHours,
				monthsPerMonthIndex
			);
			const projectedHoursForCurrentMonth =
				monthsPerMonthIndex[dateConverter.currentMonthIndex] *
				hoursPerMonth;
			if (currentMonthHours < projectedHoursForCurrentMonth) {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth,
					this.getStartDate()
				);
			} else {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth.add(1, "month"),
					this.getStartDate()
				);
			}
		}
	}

	getRemainingRoleHoursStartDate(staffRole) {
		const currentMonthHours =
			this.getRoleRecordedHoursCurrentMonth(staffRole);
		if (currentMonthHours === 0) {
			return dateConverter.maxMoment(
				dateConverter.startOfCurrentMonth,
				this.getStartDate()
			);
		} else {
			const remainingHours =
				this.getBudgetedHoursForStaffRole(staffRole) -
				this.getRoleRecordedHours(staffRole);
			const monthsPerMonthIndex = dateConverter.momentMonthsPerMonthIndex(
				this.getStartDate(),
				this.getEndDate()
			);
			const hoursPerMonth = this.getValuePerMonthFromLookup(
				remainingHours,
				monthsPerMonthIndex
			);
			const projectedHoursForCurrentMonth =
				monthsPerMonthIndex[dateConverter.currentMonthIndex] *
				hoursPerMonth;
			if (currentMonthHours < projectedHoursForCurrentMonth) {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth,
					this.getStartDate()
				);
			} else {
				return dateConverter.maxMoment(
					dateConverter.startOfCurrentMonth.add(1, "month"),
					this.getStartDate()
				);
			}
		}
	}

	getStaffRecordedHours(staffMember) {
		return (this.staffMinutes[staffMember.id] || 0) / 60;
	}

	getStaffRecordedHoursCurrentMonth(staffMember) {
		return (this.currentStaffMinutes[staffMember.id] || 0) / 60;
	}

	getRoleRecordedHours(staffRole) {
		const staffIdsWithRole = staffRole.staffIds;
		const staffIdsWithBudgets = this.staffMemberBudgetedHours.map(
			(sbh) => sbh.staffMember.id
		);
		const staffIdsWithoutBudgets = _.difference(
			staffIdsWithRole,
			staffIdsWithBudgets
		);
		return (
			sum(
				staffIdsWithoutBudgets.map((sId) => this.staffMinutes[sId] || 0)
			) / 60
		);
	}

	getRoleRecordedHoursCurrentMonth(staffRole) {
		const staffIdsWithRole = staffRole.staffIds;
		const staffIdsWithBudgets = this.staffMemberBudgetedHours.map(
			(sbh) => sbh.staffMember.id
		);
		const staffIdsWithoutBudgets = _.difference(
			staffIdsWithRole,
			staffIdsWithBudgets
		);
		return (
			sum(
				staffIdsWithoutBudgets.map(
					(sId) => this.currentStaffMinutes[sId] || 0
				)
			) / 60
		);
	}

	addBlankStaffAllocations(staffMember) {
		this.allocations.push(
			new RangeAllocation({
				startDate:
					this.startDate || dateConverter.momentToInt(moment()),
				endDate: this.endDate || dateConverter.momentToInt(moment()),
				staffMember: staffMember,
				staffRole: staffMember.role,
				hours: 0,
			})
		);
	}

	addBlankRoleAllocations(staffRole) {
		this.allocations.push(
			new RangeAllocation({
				startDate:
					this.startDate || dateConverter.momentToInt(moment()),
				endDate: this.endDate || dateConverter.momentToInt(moment()),
				staffRole: staffRole,
				hours: 0,
			})
		);
	}

	areAllocationsOutOfSync() {
		if (!this.startDate || !this.endDate) return false;
		return true;
		const dateRange = [moment().startOf("month"), null];
		return (
			_.every(
				this.staffRoleBudgetedHours,
				(srb) =>
					this.getRoleAllocatedHoursInDateRange(
						srb.staffRole,
						dateRange
					) === 0 && srb.hours > 0
			) ||
			_.every(
				this.staffMemberBudgetedHours,
				(smb) =>
					this.getStaffAllocatedHoursInDateRange(
						smb.staffMember,
						dateRange
					) === 0 && smb.hours > 0
			)
		);
	}

	areMilestonesOutOfSync() {
		if (!this.startDate || !this.endDate) return false;
		return true;
		const milestones = this.milestones.filter(
			(m) =>
				m.endDate > dateConverter.momentToInt(moment().startOf("month"))
		);
		let projectedRevenue = 0;
		milestones.forEach((m) => {
			projectedRevenue += m.revenue;
		});
		return projectedRevenue === 0;
	}

	getTaskByUuid(uuid) {
		if (uuid != null) {
			let task = this.tasks.find((t) => t.uuid === uuid);
			if (task != null) {
				return task;
			} else {
				// Defensive: we shouldn't allow deleting of tasks that have time
				// against them, but if that somehow happens anyway, do this
				// rather than borking up pretending there was never a task
				// assigned (which would then bork the table up because we could
				// have multiple entries for the same phase+date).
				return new Task({ uuid: uuid, name: "(Deleted task)" });
			}
		} else {
			return null;
		}
	}

	getVisibleTasks() {
		return this.tasks.filter((t) => !t.get("isDeleted"));
	}

	getChangeLogItemsInDateRange(dateRange) {
		let self = this;
		let clis = this.project.changeLog.filter(function (cli) {
			return (
				(cli.phase != null && cli.phase.id === self.id) ||
				// Defensive: would be nicer to standardise on whether this.id should
				// be -1 or null for no-phase phase.
				(cli.phase == null && (self.id == null || self.id === -1))
			);
		});

		if (dateRange != null) {
			return clis.filter(function (c) {
				return !(
					(dateRange.start != null &&
						c.date != null &&
						c.date.isBefore(dateRange.start)) ||
					(dateRange.end != null &&
						c.date != null &&
						c.date.isAfter(dateRange.end))
				);
			});
		} else {
			return clis;
		}
	}

	getRevenueInDateRange(dateRange, ...billingTypes) {
		return this.getChangeLogItemsInDateRange(dateRange)
			.filter((c) =>
				billingTypes.length
					? billingTypes.includes(c.billingType)
					: true
			)
			.map((c) => c.revenue || 0)
			.reduce((a, b) => a + b, 0);
	}

	getExpensesSpentInDateRange(dateRange) {
		return this.getChangeLogItemsInDateRange(dateRange)
			.map((c) => c.expenses || 0)
			.reduce((a, b) => a + b, 0);
	}

	get expenses() {
		return this.project.expenses.filter((e) => e.phaseId === this.id);
	}

	getCompletionDate() {
		let self = this;
		let cli = this.project.changeLog.find(function (c) {
			return (
				c.phase != null && c.phase.id === self.id && c.progress == 100
			);
		});
		return cli != null ? cli.date : null;
	}

	getMostRecentRevenueDate() {
		let self = this;
		let invoices = this.project.changeLog.filter(function (c) {
			return c.phase != null && c.phase.id === self.id && c.revenue > 0;
		});
		if (invoices.isEmpty()) {
			return null;
		}
		return invoices.max(function (cli1, cli2) {
			return compareMoments(cli1.date, cli2.date);
		}).date;
	}

	getAllocatedStaffMembersInDateRangeSet(dateRange) {
		/**
		 * `dateRange`: [start:int|null, end:int|null]
		 * Returns an `Immutable.Set`. (Used by `Project.getAllocatedStaffMembersInDateRange`).
		 */
		let staffMembers = new Immutable.Set();

		for (let ra of this.allocations) {
			if (
				rangeIntersection([ra.startDate, ra.endDate], dateRange) != null
			) {
				staffMembers = staffMembers.add(ra.staffMember);
			}
		}
		for (let smbh of this.staffMemberBudgetedHours) {
			staffMembers = staffMembers.add(smbh.staffMember);
		}

		return staffMembers;
	}

	getAllocationsInDateRangeSet(dateRange) {
		/**
		 * `dateRange`: [start:int|null, end:int|null]
		 */
		let allocations = new Immutable.Set();

		for (let ra of this.allocations) {
			if (
				rangeIntersection([ra.startDate, ra.endDate], dateRange) != null
			) {
				allocations = allocations.add(ra);
			}
		}

		return allocations;
	}

	getStaffAllocationsInDateRangeSet(staffMember, dateRange) {
		/**
		 * `dateRange`: [start:int|null, end:int|null]
		 */
		return this.getAllocationsInDateRangeSet(dateRange).filter((a) => {
			const paramId = staffMember ? staffMember.id : undefined;
			const alloId = a.staffMember ? a.staffMember.id : undefined;
			return paramId === alloId;
		});
	}

	getRoleAllocationsInDateRangeSet(staffRole, dateRange) {
		/**
		 * `dateRange`: [start:int|null, end:int|null]
		 */
		return this.getAllocationsInDateRangeSet(dateRange).filter((a) => {
			const paramId = staffRole ? staffRole.id : undefined;
			const alloId = a.staffRole
				? a.staffRole.id
				: a.staffMember && a.staffMember.role
				? a.staffMember.role.id
				: undefined;
			return paramId === alloId;
		});
	}

	getStaffAllocatedHoursInDateRange(staffMember, dateRange) {
		let hours = 0;
		this.getStaffAllocationsInDateRangeSet(staffMember, dateRange).forEach(
			(sa) => {
				hours += sa.getHoursForStaffMemberInDateRange(
					staffMember,
					...dateRange
				);
			}
		);
		return hours;
	}

	getRoleAllocatedHoursInDateRange(staffRole, dateRange) {
		let hours = 0;
		this.getRoleAllocationsInDateRangeSet(staffRole, dateRange).forEach(
			(sa) => {
				hours += sa.getHoursForStaffRoleInDateRange(
					staffRole,
					...dateRange
				);
			}
		);
		return hours;
	}

	getAllocationsInMonthIndexSet(monthIndex) {
		const startMoment = dateConverter.monthIndexToMoment(monthIndex);
		const startDateInt = dateConverter.momentToInt(startMoment);
		const endDateInt = dateConverter.momentToInt(
			startMoment.clone().endOf("month")
		);
		const dateRange = [startDateInt, endDateInt];
		return this.getAllocationsInDateRangeSet(dateRange);
	}

	getStaffAllocationsInMonthIndexSet(staffMember, monthIndex) {
		const startMoment = dateConverter.monthIndexToMoment(monthIndex);
		const startDateInt = dateConverter.momentToInt(startMoment);
		const endDateInt = dateConverter.momentToInt(
			startMoment.clone().endOf("month")
		);
		const dateRange = [startDateInt, endDateInt];
		return this.getStaffAllocationsInDateRangeSet(staffMember, dateRange);
	}

	getRoleAllocationsInMonthIndexSet(staffRole, monthIndex) {
		const startMoment = dateConverter.monthIndexToMoment(monthIndex);
		const startDateInt = dateConverter.momentToInt(startMoment);
		const endDateInt = dateConverter.momentToInt(
			startMoment.clone().endOf("month")
		);
		const dateRange = [startDateInt, endDateInt];
		return this.getRoleAllocationsInDateRangeSet(staffRole, dateRange);
	}

	getAllocatedHoursInMonthIndex(monthIndex) {
		const allocations = this.getAllocationsInMonthIndexSet(monthIndex);
		let hours = 0;
		for (let ra of allocations) {
			hours += ra.getTotalHoursInMonthIndex(monthIndex);
		}
		return hours;
	}

	getStaffAllocatedHoursInMonthIndex(staffMember, monthIndex) {
		const allocations = this.getStaffAllocationsInMonthIndexSet(
			staffMember,
			monthIndex
		);
		let hours = 0;
		for (let ra of allocations) {
			hours += ra.getTotalHoursInMonthIndex(monthIndex);
		}
		return hours;
	}

	getRoleAllocatedHoursInMonthIndex(staffRole, monthIndex) {
		const allocations = this.getRoleAllocationsInMonthIndexSet(
			staffRole,
			monthIndex
		);
		let hours = 0;
		for (let ra of allocations) {
			hours += ra.getTotalHoursInMonthIndex(monthIndex);
		}
		return hours;
	}

	getAllocatedStaffMembersInDateRange(dateRange) {
		/**
		 * Returns a list of `StaffMember`s.
		 */

		return this.getAllocatedStaffMembersInDateRangeSet(dateRange).toJS();
	}

	getAllocatedHours() {
		// Deprecated in favour of `getTotalAllocatedHours`.
		return this.getAllocatedHoursInDateRange(AllTime);
	}

	getTotalAllocatedHours() {
		return this.getAllocatedHoursInDateRange(AllTime);
	}

	getAllocatedHoursInDateRange(dateRange) {
		let hours = 0;
		dateRange = _.values(dateRange).map((date) =>
			moment.isMoment(date) ? dateConverter.momentToInt(date) : date
		);
		for (let ra of this.allocations) {
			hours += ra.getTotalHoursInDateRange(...dateRange);
		}
		return hours;
	}

	getAllocatedRateInDateRange(dateRange, rateType) {
		let rateTotal = 0;
		dateRange = _.values(dateRange).map((date) =>
			moment.isMoment(date) ? dateConverter.momentToInt(date) : date
		);
		for (let ra of this.allocations) {
			rateTotal +=
				ra.getTotalHoursInDateRange(...dateRange) *
				this.project.getRateInRange(
					ra.staffMember || ra.staffRole,
					this,
					rateType,
					...dateRange
				);
		}
		return rateTotal;
	}

	getProgressAtEndOfDateRange(dateRange) {
		let cli = this.getChangeLogItemsInDateRange(dateRange)
			.filter((c) => c.phase == null && c.progress != null)
			.maxBy((c) => c.progress);
		return cli != null ? cli.progress : 0;
	}

	getBudgetedHoursForStaffMember(staffMember) {
		let item = _.find(this.staffMemberBudgetedHours, (b) =>
			staffMember && b.staffMember
				? b.staffMember.id === staffMember.id
				: false
		);
		return item != null ? item.hours : 0;
	}

	getBudgetedHoursObjectForStaffMember(staffMember) {
		let item = _.find(this.staffMemberBudgetedHours, (b) =>
			staffMember && b.staffMember
				? b.staffMember.id === staffMember.id
				: false
		);
		return item;
	}

	getBudgetedHoursForStaffRole(staffRole) {
		let item = _.find(
			this.staffRoleBudgetedHours,
			(b) => b.staffRole.id === staffRole.id
		);
		return item != null ? item.hours : 0;
	}

	getBudgetedHoursObjectForStaffRole(staffRole) {
		let item = _.find(
			this.staffRoleBudgetedHours,
			(b) => b.staffRole.id === staffRole.id
		);
		return item;
	}

	setBudgetedHoursForStaffMember(staffMember, hours = 0) {
		let item = _.find(
			this.staffMemberBudgetedHours,
			(b) => b.staffMember.id === staffMember.id
		);
		if (item != null) {
			item.hours = hours;
		} else {
			this.staffMemberBudgetedHours.push({
				staffMember: staffMember,
				hours: hours,
				phase: this,
			});
		}
		this.updateHoursBudgetFromStaffBudgets();
		this.updateExpenseBudgetFromStaffBudgets();
		this.updateFeeFromStaffBudgets();
	}

	setBudgetedHoursForStaffRole(staffRole, hours = 0) {
		let item = _.find(
			this.staffRoleBudgetedHours,
			(b) => b.staffRole.id === staffRole.id
		);
		if (item != null) {
			item.hours = hours;
		} else {
			this.staffRoleBudgetedHours.push({
				staffRole: staffRole,
				hours: hours,
				phase: this,
			});
		}
		this.updateHoursBudgetFromStaffBudgets();
		this.updateExpenseBudgetFromStaffBudgets();
		this.updateFeeFromStaffBudgets();
	}

	changeStaffHoursBudgetStaffMember(prevStaff, newStaff) {
		let item = _.find(
			this.staffMemberBudgetedHours,
			(b) => b.staffMember.id === prevStaff.id
		);
		item.staffMember = newStaff;
	}

	changeStaffHoursBudgetStaffRole(prevRole, newRole) {
		let item = _.find(
			this.staffRoleBudgetedHours,
			(b) => b.staffRole.id === prevRole.id
		);
		item.staffRole = newRole;
	}

	deleteBudgetedHoursForStaffMember(staffMember) {
		let item = _.find(
			this.staffMemberBudgetedHours,
			(b) => b.staffMember.id === staffMember.id
		);
		if (item != null) {
			this.staffMemberBudgetedHours = _.without(
				this.staffMemberBudgetedHours,
				item
			);
		}
		this.updateExpenseBudgetFromStaffBudgets();
		this.updateHoursBudgetFromStaffBudgets();
		this.updateFeeFromStaffBudgets();
		if (this.getCombinedBudgetedHours().length === 0) {
			this.hoursBudgetLinked = false;
			this.expenseBudgetLinked = false;
			this.feeLinked = false;
		}
	}

	deleteBudgetedHoursForStaffRole(staffRole) {
		let item = _.find(
			this.staffRoleBudgetedHours,
			(b) => b.staffRole.id === staffRole.id
		);
		if (item != null) {
			this.staffRoleBudgetedHours = _.without(
				this.staffRoleBudgetedHours,
				item
			);
		}
		this.updateExpenseBudgetFromStaffBudgets();
		this.updateHoursBudgetFromStaffBudgets();
		this.updateFeeFromStaffBudgets();
		if (this.getCombinedBudgetedHours().length === 0) {
			this.hoursBudgetLinked = false;
			this.expenseBudgetLinked = false;
			this.feeLinked = false;
		}
	}

	updateHoursBudgetFromStaffBudgets() {
		if (this.hoursBudgetLinked) {
			this.manualHoursBudget = this.getTotalHoursBudgetFromStaffBudgets();
		}
	}

	updateExpenseBudgetFromStaffBudgets() {
		if (this.expenseBudgetLinked) {
			this.manualBudget = this.getTotalExpenseFromStaffBudgets();
		}
	}

	updateFeeFromStaffBudgets() {
		if (this.feeLinked) {
			this.fee = this.getTotalChargeOutFromStaffBudgets();
		}
	}

	getTotalHoursBudgetFromStaffBudgets() {
		return sum(this.getCombinedBudgetedHours().map((b) => b.hours));
	}

	getTotalExpenseFromStaffBudgets() {
		return sum(
			this.getCombinedBudgetedHours().map(
				(b) =>
					b.hours *
					this.project.getRateForPhase(b.item, this, "costRate")
			)
		);
	}

	getTotalChargeOutFromStaffBudgets() {
		return sum(
			this.getCombinedBudgetedHours().map(
				(b) =>
					b.hours *
					this.project.getRateForPhase(b.item, this, "chargeOutRate")
			)
		);
	}

	setExpenseBudget(newExpenseBudget) {
		if (this.manualBudget === newExpenseBudget) return;
		this.manualBudget = newExpenseBudget;
		this.updateStaffHoursBudgetsFromPhaseExpenseBudget();
	}

	updateStaffHoursBudgetsFromPhaseHoursBudget() {
		if (
			this.hoursBudgetLinked &&
			!isNaN(this.getTotalHoursBudgetFromStaffBudgets())
		) {
			let ratio =
				this.manualHoursBudget /
				this.getTotalHoursBudgetFromStaffBudgets();
			this.staffMemberBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.staffRoleBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.updateExpenseBudgetFromStaffBudgets();
			this.updateFeeFromStaffBudgets();
		}
	}

	updateStaffHoursBudgetsFromPhaseExpenseBudget() {
		if (
			this.expenseBudgetLinked &&
			!isNaN(this.getTotalExpenseFromStaffBudgets())
		) {
			let ratio =
				this.manualBudget / this.getTotalExpenseFromStaffBudgets();
			this.staffMemberBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.staffRoleBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.updateHoursBudgetFromStaffBudgets();
			this.updateFeeFromStaffBudgets();
		}
	}

	updateStaffHoursBudgetsFromPhaseFee() {
		if (
			this.fee &&
			this.feeLinked &&
			!isNaN(this.getTotalChargeOutFromStaffBudgets())
		) {
			let ratio = this.fee / this.getTotalChargeOutFromStaffBudgets();
			this.staffMemberBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.staffRoleBudgetedHours.forEach((sbh) => {
				sbh.hours = sbh.hours * ratio;
			});
			this.updateExpenseBudgetFromStaffBudgets();
			this.updateHoursBudgetFromStaffBudgets();
		}
	}

	updateHoursBudgetFromStaffAllocations() {
		let staffMemberBudgetedHours = [];
		for (let allocation of this.allocations) {
			let staffMemberBudget = _.find(
				staffMemberBudgetedHours,
				(smb) => smb.staffMember.id === allocation.staffMember.id
			);
			if (staffMemberBudget != null) {
				staffMemberBudget.hours += allocation.hours;
			} else {
				staffMemberBudgetedHours.push({
					staffMember: allocation.staffMember,
					hours: allocation.hours,
					phase: this,
				});
			}
		}
		this.staffMemberBudgetedHours = staffMemberBudgetedHours;
		this.updateHoursBudgetFromStaffBudgets();
	}

	populateHoursBudgetFromStaffAllocations() {
		/**
		 * It's (currently) possible for there to be staff members in
		 * `this.allocations` that are not in `this.staffMemberBudgetedHours`. This method
		 * makes sure every staff member in `this.allocations` is in `this.staffMemberBudgetedHours`.
		 */
		for (let allocation of this.allocations) {
			if (
				_.find(
					this.staffMemberBudgetedHours,
					(smb) => smb.staffMember.id === allocation.staffMember.id
				) == null
			) {
				this.staffMemberBudgetedHours.push({
					staffMember: allocation.staffMember,
					hours: 0,
					phase: this,
				});
			}
		}
	}

	getStaffMemberHoursBudget(staffMember) {
		let staffHoursBudget = _.find(
			this.staffMemberBudgetedHours,
			(smb) => smb.staffMember.id === staffMember.id
		);
		let hours = staffHoursBudget !== undefined ? staffHoursBudget.hours : 0;
		if (staffMember.id === -1) {
			hours = this.manualHoursBudget;
		}
		return hours;
	}

	getStaffRoleHoursBudget(staffRole) {
		if (!staffRole) return 0;
		let staffRoleHoursBudget = _.find(
			this.staffRoleBudgetedHours,
			(smb) => smb.staffRole.id === staffRole.id
		);
		let hours =
			staffRoleHoursBudget !== undefined ? staffRoleHoursBudget.hours : 0;
		if (staffRole.id === -1) {
			hours = this.manualHoursBudget;
		}
		return hours;
	}

	getTotalStaffRoleHoursBudget(staffRole) {
		let budgets = [];
		if (!staffRole) {
			budgets = this.staffMemberBudgetedHours.filter(
				(smb) => !smb.staffMember.role
			);
		} else {
			budgets = this.staffMemberBudgetedHours.filter(
				(smb) =>
					smb.staffMember.role &&
					smb.staffMember.role.uuid === staffRole.uuid
			);
			budgets.push(
				...this.staffRoleBudgetedHours.filter(
					(smb) => smb.staffRole.uuid === staffRole.uuid
				)
			);
		}
		return sum(budgets.map((b) => b.hours));
	}

	get costCentre() {
		return this.project.costCentre;
	}

	get startMonthIndex() {
		let startDate = this.getStartDate();
		if (startDate) return dateConverter.momentToMonthIndex(startDate);
		else {
			return null;
		}
	}

	get endMonthIndex() {
		let endDate = this.getEndDate();
		if (endDate) return dateConverter.momentToMonthIndex(endDate);
		else {
			return null;
		}
	}

	get latestEventDate() {
		let eventHistory = this.project.eventHistory.filter(
			(e) => e.phaseId == this.id
		);
		if (eventHistory.length > 0) {
			return _.max(eventHistory.map((e) => e.dateInt)) || null;
		} else {
			return null;
		}
	}

	getVisibleMilestones() {
		return this.milestones.filter(
			(m) => !(m.endDate == this.startDate && m.revenue == 0)
		);
	}

	*iterMilestones() {
		let lastPercent = 0;
		for (let m of this.milestones) {
			yield [m, m.percent - lastPercent];
			lastPercent = m.percent;
		}
	}

	createDefaultTask() {
		if (this.tasks.isEmpty()) {
			this.tasks = this.tasks.push(
				new Task({
					name: "(No task)",
					isBillable: this.project.costCentre.isBillable,
					isDefault: true,
				})
			);
			if (organisationStore.organisation.id === 289) {
				this.tasks = this.tasks.push(
					new Task({
						name: "Additional services",
						isBillable: true,
						isVariation: true,
					})
				);
				this.tasks = this.tasks.push(
					new Task({
						name: "Non-billable",
						isBillable: false,
						isVariation: false,
					})
				);
			}
		}
	}

	get defaultTask() {
		const task = this.tasks.find((t) => t.isDefault);
		if (task == null) {
			throw new Error("Phase had no default task");
		}
		return task;
	}

	hasNonDefaultTask() {
		return (
			this.tasks.filter((t) => !t.isDeleted).find((t) => !t.isDefault) !=
			null
		);
	}

	getTaskBillabilityLookup() {
		/**
		 * Returns Immutable.Map([uuid, [isBillable::bool, isVariation::bool]])
		 */
		let lookup = {};
		for (let t of this.tasks) {
			lookup[t.uuid] = [t.isBillable, t.isVariation];
		}
		return Immutable.fromJS(lookup);
	}

	get isRealPhase() {
		// As opposed to NoPhasePhase.
		return true;
	}

	getMonthsPerMonthIndex() {
		return dateConverter.momentMonthsPerMonthIndex(
			this.getStartDate(),
			this.getEndDate()
		);
	}

	getBudgetedHoursPerMonth() {
		return dateConverter.splitValueIntoMonths(
			this.manualHoursBudget || 0,
			this.getStartDate(),
			this.getEndDate()
		);
	}

	getBudgetedExpensePerMonth() {
		return dateConverter.splitValueIntoMonths(
			this.getBudget(),
			this.getStartDate(),
			this.getEndDate()
		);
	}

	getBudgetedHoursInMonthIndex(monthIndex) {
		const hoursBudgetPerMonthIndex = this.getBudgetedHoursPerMonth();
		return (
			hoursBudgetPerMonthIndex[monthIndex - this.startMonthIndex]
				?.value || 0
		);
	}

	getBudgetedExpenseInMonthIndex(monthIndex) {
		const expenseBudgetPerMonthIndex = this.getBudgetedExpensePerMonth();
		return (
			expenseBudgetPerMonthIndex[monthIndex - this.startMonthIndex]
				?.value || 0
		);
	}
};

export function getMilestonesForNewPhase(startDate, endDate, fee) {
	/**
	 * `startDate`, `endDate`: int-date
	 */

	let milestoneDates = getDefaultMilestoneDates(
		dateConverter.intToMoment(startDate).startOf("day"),
		dateConverter.intToMoment(endDate).startOf("day"),
		{ gracePeriodDays: 1 }
	).map((d) => dateConverter.momentToInt(d));

	const totalWeekdays = dateConverter.numWeekdaysBetween(startDate, endDate);
	let lastEndDate = startDate - 1;
	return milestoneDates.map(function (d) {
		const m = new Milestone({
			percent:
				(dateConverter.numWeekdaysBetween(startDate, d) /
					totalWeekdays) *
				100,
			revenue:
				(dateConverter.numWeekdaysBetween(lastEndDate + 1, d) /
					totalWeekdays) *
				fee,
		});
		lastEndDate = d;
		return m;
	});
}

export function updateMilestoneRevenue(milestones, endDate, newRevenue) {
	let newMilestones = milestones.map((m) => _.clone(m));
	_.find(newMilestones, (m) => m.endDate === endDate).revenue = newRevenue;

	const totalRevenue = sum(newMilestones.map((m) => m.revenue));
	let cumulativeRevenue = 0;
	for (let m of newMilestones) {
		cumulativeRevenue += m.revenue;
		m.percent = (cumulativeRevenue / totalRevenue) * 100;
	}

	return newMilestones;
}
