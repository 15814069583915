import React from 'react';
import CreateReactClass from 'create-react-class';
import { dispatcher, handleAction } from '../coincraftFlux.js';
import { Task } from '../models.js';
import { ProjectPhaseTasks } from './ProjectPhaseTasks.js';
import PropTypes from "prop-types";


const phaseTaskActionDefinitions = [
  {action: 'addTask', args: []},
  {action: 'deleteTask', args: ['taskUuid']},
  {action: 'setTaskText', args: ['taskUuid', 'text']},
  {action: 'setTaskIsBillable', args: ['taskUuid', 'isBillable']},
  {action: 'setTaskIsVariation', args: ['taskUuid', 'isVariation']},
];


function prependArg(definitions, arg) {
  return definitions.map(function({action, args}) {
    return {action: action, args: [arg, ...args]};
  });
}

// Project task actions are the same as phase task actions but they have an extra
// `phaseIndex` arg at the beginning.
const projectTaskActionDefinitions = prependArg(phaseTaskActionDefinitions, 'phaseIndex');


export const ProjectTaskStore = class {
  constructor({project}) {
    this.project = project;
    this.actionDefinitions = projectTaskActionDefinitions;
  }

  loadProject(project) {
    this.project = project.copy();
    this.taskBillabilityLookup = project.getTaskBillabilityLookup();
  }

  handle(action) {
    new PhaseTaskStore(this.project.getVisiblePhases()[action.phaseIndex]).handle(action);
  }
}


export const PhaseTaskStore = class {
			constructor(phase) {
				this.phase = phase;
				this.actionDefinitions = phaseTaskActionDefinitions;
			}

			loadPhase(phase) {
				this.phase = phase.copy();
				this.taskBillabilityLookup = phase.getTaskBillabilityLookup();
			}

			handle(action) {
				handleAction(action, this);
			}

			addTask() {
				this.phase.tasks = this.phase.tasks.push(new Task());
				this.phase.hasJustAddedTask = true;
			}

			deleteTask(taskUuid) {
				if (
					this.phase.tasks
						.find(task => task.get("uuid") === taskUuid)
						.get("isDefault")
				) {
					// Defensive: it shouldn't be possible for a user to get here.
					throw new Error("Can't delete default task");
				}
				this.updateTaskByUuid(taskUuid, "isDeleted", true);
			}

			setTaskText(taskUuid, text) {
				this.updateTaskByUuid(taskUuid, "name", text);
			}

			setTaskIsBillable(taskUuid, isBillable) {
				this.updateTaskByUuid(taskUuid, "isBillable", isBillable);
			}

			setTaskIsVariation(taskUuid, isVariation) {
				this.updateTaskByUuid(taskUuid, "isVariation", isVariation);
			}

			updateTaskByUuid(taskUuid, feild, value) {
				this.phase.tasks = this.phase.tasks.update(
					this.phase.tasks.findIndex(function(task) {
						return task.get("uuid") === taskUuid;
					}),
					function(task) {
						return task.set(feild, value);
					}
				);
			}
		};


export var ProjectTasks = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
  },

  render: function() {
    let self = this;

    return <div>
      {this.props.project.getVisiblePhases().map(function(phase, phaseIndex) {
        return <div key={phaseIndex}>
          <h3 style={{borderBottom: 'solid 1px #ccc'}}>
            {phase.getTitle()}
          </h3>
          <ProjectPhaseTasks
            phase={phase}
            isEditable={self.props.isEditable}
            actions={{
              addTask: () => dispatcher.dispatch({
                type: "tasks/addTask",
                path: self.props.path,
                phaseIndex: phaseIndex
              }),
              deleteTask: (taskUuid) => dispatcher.dispatch({
                type: "tasks/deleteTask",
                path: self.props.path,
                phaseIndex: phaseIndex,
                taskUuid: taskUuid
              }),
              setTaskText: (taskUuid, text) => dispatcher.dispatch({
                type: "tasks/setTaskText",
                path: self.props.path,
                phaseIndex: phaseIndex,
                taskUuid: taskUuid,
                text: text
              }),
              setTaskIsBillable: (taskUuid, isBillable) => dispatcher.dispatch({
                type: "tasks/setTaskIsBillable",
                path: self.props.path,
                phaseIndex: phaseIndex,
                taskUuid: taskUuid,
                isBillable: isBillable
              }),
              setTaskIsVariation: (taskUuid, isVariation) => dispatcher.dispatch({
                type: "tasks/setTaskIsVariation",
                path: self.props.path,
                phaseIndex: phaseIndex,
                taskUuid: taskUuid,
                isVariation: isVariation
              }),
            }}
          />
        </div>;
      })}
    </div>;
  }
});
