import moment from 'moment';
import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { isNumber, wrapAsReactNode, camelCaseToSentence } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { organisationStore } from "../organisation.js";
import { store as milestonesStore } from '../milestones/flux.js';
import { userStore } from '../user.js';
import { MySelect2, RefreshButton, SelectValue } from './generic.js';
import { Multiselect } from 'react-widgets';
import { dateConverter } from '../models.js';
import { ProjectStatus, projectStatusOptions } from '../models/project.js';
import { ReportType } from '../reports/ReportType.js';
import classNames from 'classnames';
import PropTypes from "prop-types";

// This seems to be required for `DateTimePicker`.
import Globalize from 'globalize';
import globalizeLocalizer from 'react-widgets/lib/localizers/globalize';
import { ModalContent, Modal } from '../modal.js';
import { ContactDropdown } from './ContactSelector.js';
globalizeLocalizer(Globalize);


export var StateAwareRefreshButton = CreateReactClass({
  /**
   * A refresh button that is disabled if the milestones store is dirty.
   * (Use if clicking the button would clobber the milestones data).
   */
  mixins: [
    makeMultipleStoreMixin([organisationStore, milestonesStore], function() {
      return {
        isRefreshing: organisationStore.isRefreshing,
        isMilestonesDirty: milestonesStore.projectState.getDirtyProjects().length > 0,
      };
    })
  ],

  propTypes: {
    onClick: PropTypes.func.isRequired,
    iconOnly: PropTypes.bool,
    smallButton: PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      iconOnly: false,
      smallButton: true
    };
  },

  render: function() {
    return <RefreshButton
      disabled={this.state.isMilestonesDirty}
      isRefreshing={this.state.isRefreshing}
      onClick={this.props.onClick}
      style={{pointerEvents: 'auto'}}
      iconOnly={this.props.iconOnly}
      smallButton={this.props.smallButton}
      title={this.state.isMilestonesDirty ? "Please save your changes in the scheduler before refreshing" : null}
    />;
  }
});


export var TaskSelector = CreateReactClass({
  propTypes: {
    value: function(props, propName, componentName) {
      let val = props[propName];
      if (val != null && val !== 'all' && val.constructor.getClassName() !== 'Task') {
        throw new Error("value must be 'all', `null`, or a `Task`");
      }
    },
    projectPhase: PropTypes.object,
    onChange: PropTypes.func.isRequired,

    includeAllTasksOption: PropTypes.bool,

    nullText: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      includeAllTasksOption: false
    };
  },

  render: function() {
    let self = this;
    let {includeAllTasksOption, projectPhase, value, className, ...props} = this.props;

    let options = [
      ...(includeAllTasksOption ? ['all'] : []),
      ...projectPhase.getVisibleTasks()
    ];

    return <MySelect2
      className={classNames("task-select", className)}
      value={value}
      options={options}
      getObjectLabel={function(t) {
        return <div>
          {t === 'all' ?
            '(All tasks)'
          : t != null ?
            t.name
          : self.props.nullText != null ?
            self.props.nullText
          :
            '(No task)'
          }
        </div>;
      }}
      onChange={this.handleChange}
      {...props}
    />;
  },

  handleChange: function(task) {
    this.props.onChange(task);
  }
});



export var ContactSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func
  },

  getInitialState: function() {
    return {
      isPopupOpen: false
    };
  },

  render: function() {
    let ContactFormContainer = require("../contacts.js").ContactFormContainer;

    return (
      <div
        style={{display: 'inline-block', height: 32, textAlign: 'left'}}
        className={this.props.className}>
        {this.state.isPopupOpen ?
          <Modal className="contact-modal">
            <ModalContent header="New contact" width="60%" onClosed={this.handlePopupClose}>

              <ContactFormContainer onClose={this.handlePopupClose} />
            </ModalContent>
          </Modal>
        : null}
        <div className="flexbox-container flex-align-items-stretch">
          <div className="flex-0-0-auto" style={{zIndex: 2}}>
            <button
                className="btn btn-sm btn-default contact-selector__add-contact-button"
                onClick={this.handleNewContactButtonClick}
                style={{
                  borderRadius: 0,
                  marginRight: '-0.2em',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  height: '100%'
                }}>
              <i className="fa fa-plus" style={{margin: 0}}/>
            </button>
          </div>
          <div className="flex-0-0-auto">
            <ContactDropdown
              value={this.props.value}
              onChange={this.handleSelectChange}
            />
          </div>
        </div>
      </div>
    );
  },

  handleSelectChange: function(contact) {
    this.props.onChange(contact);
  },

  handleNewContactButtonClick: function() {
    this.setState({isPopupOpen: true});
  },

  handlePopupClose: function(contact) {
    let self = this;
    this.setState({isPopupOpen: false});
    if (contact != null) {
      // `setTimeout` because this is a dispatched event but `props.onChange`
      // may also dispatch an event so we would otherwise get
      // can't-dispatch-in-the-middle-of-a-dispatch.
      setTimeout(function() {
        self.props.onChange(contact);
      }, 0);
    }
  }
});



var ReportSelector = CreateReactClass({
  propTypes: {
    reportType: PropTypes.string.isRequired,
    reportUuid: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allowNull: PropTypes.bool,
    nullOptionText: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      allowNull: false
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      const reports = organisationStore.reports.filter(r => r.reportType === this.props.reportType);
      return {
        options: this.props.allowNull ? [null, ...reports] : reports
      };
    })
  ],

  render: function() {
    let self = this;
    return <MySelect2
      className="flex-0-0-auto"
      style={{marginLeft: '0.5em'}}
      value={this.props.reportUuid}
      options={this.state.options}
      getObjectLabel={function(report) {
        if (report == null) {
          return <div>{self.props.nullOptionText}</div>;
        }
        else {
          return <div>{report.name}</div>;
        }
      }}
      getObjectValue={r => r != null ? r.uuid : null}
      onChange={this.props.onChange}
    />;
  }
});


function makeReportSelectorComponent(reportType) {
  return CreateReactClass({
    propTypes: {
      reportUuid: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      allowNull: PropTypes.bool,
      nullOptionText: PropTypes.string
    },

    getDefaultProps: function() {
      return {
        allowNull: false
      };
    },

    render: function() {
      return <ReportSelector
        reportType={reportType}
        reportUuid={this.props.reportUuid}
        onChange={this.props.onChange}
        allowNull={this.props.allowNull}
        nullOptionText={this.props.nullOptionText}
      />;
    }
  });
};

export const ProjectReportSelector = makeReportSelectorComponent(ReportType.project);
export const StaffMemberReportSelector = makeReportSelectorComponent(ReportType.staffMember);



export var CostCentreSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isEditable: true
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore, userStore], function() {
      return {
        costCentres: organisationStore.getEditableCostCentres(userStore.user)
      };
    })
  ],

  render: function() {
    return <SelectValue
      className="md-input"
      isEditable={this.props.isEditable}
      value={this.props.value}
      onChange={this.props.onChange}
      options={this.state.costCentres}
      getObjectLabel={function(c) {
        if (c == null) {
          return <span>Please select...</span>;
        }
        else {
          return <span>{c.name}</span>;
        }
      }}
    />;
  }
});


export var StaffMemberSelector = CreateReactClass({
  propTypes: {
    className: PropTypes.any,
    value: PropTypes.object,
    onChange: PropTypes.func,
    allowNull: PropTypes.bool,
    nullText: PropTypes.string,
    dropLeft: PropTypes.bool,
    disabledStaff: PropTypes.array
  },

  getDefaultProps: function() {
    return {
      allowNull: true,
      nullText: "(All staff)",
      dropLeft: false,
      disabledStaff: []
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        staffMembers: organisationStore.getVisibleStaff()
      };
    })
  ],

  render: function() {
    let { value, className, allowNull, nullText, selectProps, ...props } = this.props;
    let options = allowNull ? [null, ...this.state.staffMembers] : this.state.staffMembers;

    return <MySelect2
      value={value}
      options={options}
      isOptionDisabled={(staff) => {
        let staffId = staff != null ? staff.id : -1;
        if (staffId == value.id) {
          return false;
        } else {
          return this.props.disabledStaff.map(s => s.id).includes(staffId);
        }
      }}
      filter={function(staffMember, searchText) {
        if (staffMember == null) {
          return false;
        }
        var lower = searchText.toLowerCase();
        return (staffMember.firstName || '').toLowerCase().match(lower) ||
               (staffMember.lastName || '').toLowerCase().match(lower);
      }}
      getObjectLabel={function(sm) {
        if (sm != null) {
          return <span>{sm.lastName + ', ' + sm.firstName}</span>;
        }
        else {
          return <span>{nullText}</span>;
        }
      }}
      selectProps={{
        // This is how we make a dropdown open to the left.
        className: className,
        ...selectProps
      }}
      {...props}
    />;
  }
});

export var StaffMemberRoleSelector = CreateReactClass({
  propTypes: {
    className: PropTypes.any,
    value: PropTypes.object,
    onChange: PropTypes.func,
    allowNull: PropTypes.bool,
    nullText: PropTypes.string,
    dropLeft: PropTypes.bool,
    disabledStaff: PropTypes.array,
    disabledRoles: PropTypes.array
  },

  getDefaultProps: function() {
    return {
      allowNull: true,
      nullText: "(All staff)",
      dropLeft: false,
      disabledStaff: [],
      disabledRoles: []
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        staffMembers: organisationStore.getVisibleStaff(),
        staffRoles: organisationStore.staffRoles,
      };
    })
  ],

  render: function() {
    let { value, className, allowNull, nullText, selectProps, ...props } = this.props;
    let options = allowNull ? [null, ...this.state.staffRoles, ...this.state.staffMembers] : [...this.state.staffRoles, ...this.state.staffMembers];

    return <MySelect2
      value={value}
      options={options}
      isOptionDisabled={(item) => {
        let itemId = item != null ? item.id : -1;
        let valueId = item != null ? value.id : -1;
        let valueClassName = value != null ? value.constructor.getClassName() : "null";
        let itemClassName = item != null ? item.constructor.getClassName() : "null";
        if (itemId === valueId && itemClassName === valueClassName) {
          return false;
        } else {
          if (itemClassName === "StaffMember") {
            return this.props.disabledStaff.map(s => s.id).includes(itemId);
          } else {
            return this.props.disabledRoles.map(r => r.id).includes(itemId);
          }
        }
      }}
      filter={function(item, searchText) {
        let itemClassName = item != null ? item.constructor.getClassName() : "null";
        if (item == null) {
          return false;
        }
        var lower = searchText.toLowerCase();
        if (itemClassName === "StaffMember") {
          return (item.firstName || '').toLowerCase().match(lower) ||
                 (item.lastName || '').toLowerCase().match(lower);
        } else {
          return (item.name || '').toLowerCase().match(lower);
        }

      }}
      getObjectLabel={function(item) {
        let itemClassName = item != null ? item.constructor.getClassName() : "null";
        if (item != null) {
          if (itemClassName === "StaffMember") {
            return <span>{item.lastName + ', ' + item.firstName}</span>;
          } else {
            return <span style={{fontWeight: 'bold'}}>{item.name}</span>;
          }
        }
        else {
          return <span>{nullText}</span>;
        }
      }}
      selectProps={{
        // This is how we make a dropdown open to the left.
        className: className,
        ...selectProps
      }}
      {...props}
    />;
  }
});


export var LocalisedShortDate = CreateReactClass({
  propTypes: {
    date: PropTypes.any, // 'moment` or int-date
    ifNull: PropTypes.node
  },

  mixins: [
    makeMultipleStoreMixin([userStore], function() {
      let user = userStore.getUser();
      return {
        country: user != null ? user.country : null
      };
    })
  ],

  render: function() {
    if (this.props.date == null) {
      return wrapAsReactNode(this.props.ifNull);
    }
    else {
      let date;
      if (moment.isMoment(this.props.date)) {
        date = this.props.date;
      }
      else if (isNumber(this.props.date)) {
        date = dateConverter.intToMoment(this.props.date);
      }
      else {
        throw new Error("Should have been date or int");
      }

      let format = (this.state.country === 'us' ? "MM/DD/YYYY" : "DD/MM/YYYY");
      return <span>{date.format(format)}</span>;
    }
  }
});


function makeMultiSelectComponent({getOptions, textField, onChangeIdField, groupBy}) {
  return CreateReactClass({
    propTypes: {
      value: PropTypes.array,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func
    },

    mixins: [
      makeMultipleStoreMixin([organisationStore], function() {
        return {
          options: getOptions()
        };
      })
    ],

    render: function() {
      return (
			<Multiselect
				value={this.props.value}
				data={this.state.options}
				valueField="id"
				textField={textField}
				onChange={this.props.onChange || this.handleChange}
				onBlur={this.props.onBlur}
				style={this.props.style}
				filter={function(item, searchText) {
					return textField(item)
						.toLowerCase()
						.match(searchText.toLowerCase());
				}}
				groupBy={groupBy}
			/>
		);
    },

    handleChange: function(options) {
      if (onChangeIdField != null) {
        this.props.onChange(options.map(o => o.id));
      }
      else {
        this.props.onChange(options);
      }
    },

    textField: textField
  });
}


export var MultiCostCentreSelect = makeMultiSelectComponent({
  getOptions: () => organisationStore.objects.BusinessCategory.list,
  textField: cs => cs.name
});

export var MultiContactSelect = makeMultiSelectComponent({
  getOptions: () => organisationStore.objects.Contact.list,
  textField: c => c.display()
});

export var MultiStaffMemberSelect = makeMultiSelectComponent({
  getOptions: () => organisationStore.objects.StaffMember.list,
  textField: sm => `${sm.firstName} ${sm.lastName}`,
  groupBy: sm => sm.isArchived ? 'Archived Staff' : 'Active Staff'
});

export var MultiStaffRoleSelect = makeMultiSelectComponent({
  getOptions: () => organisationStore.objects.StaffRole.list,
  textField: sr => sr.name,
});

export var MultiProjectSelect = makeMultiSelectComponent({
  getOptions: () =>_.sortBy(organisationStore.objects.Project.list, o => projectStatusOptions.indexOf(o ? o.status : "active").toString() + (o != null ? o.getTitle() || "No title" : "No title").toString()),
  textField: p => p.getTitle(),
  groupBy: p => {
    return camelCaseToSentence((p.status + 'Projects'));
  }
});

export var MultiExpenseSelect = makeMultiSelectComponent({
  getOptions: () => {
    let expenses = organisationStore.objects.Expense.list;
    let uniqueExpenses = [];
    expenses.forEach(e => {
      if(!uniqueExpenses.map(e => e.name).includes(e.name)) {
        uniqueExpenses.push(e)
      }
    })
    return uniqueExpenses;
  },
  textField: e => e.name,
});

export var ProjectStatusSelect = makeMultiSelectComponent({
  getOptions: () => ProjectStatus.getMultiSelectOptions(),
  textField: s => s.label,
  onChangeIdField: o => o.id
});
