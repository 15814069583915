import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { router } from '../router.js';
import { Modal2 } from '../modal.js';
import { reportsPageWrapper } from '../reports/reportsPageWrapper.js';
import { organisationStore, actions as organisationActions } from '../organisation.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { ReportStoreWidget, ReportStoreSaveBar } from '../reports/ReportWidget.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { invoicesStore, invoicesActions } from './flux.js';
import { permissions } from '../models/permissions.js';
import { getOnboardingData } from '../organisationStore.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import { ErrorModal } from './ErrorModal.js';
import { Table } from '../table.js';
import { rootStore } from '../RootStore.js';
import { userStore } from '../user.js';


export var InvoicesPage = CreateReactClass({
  /**
   * Wrapper to make sure we've reset the store (eg. modals) before loading the
   * page if we've visited it before.
   */
  getInitialState: function() {
    return {
      isReady: false
    };
  },

  componentWillMount: function() {
    let self = this;
    setTimeout(function() {
      invoicesActions.loadPage();
      self.setState({isReady: true});
    }, 0);
  },

  render: function() {
    if (!this.state.isReady) {
      return null;
    }
    return <InvoicesPageContent />;
  }
});


export var InvoicesPageContent = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore, invoicesStore, userStore], function() {
      const reportStore = invoicesStore.reportStore;

      if (!invoicesStore.isReady) {
        return {isReady: false};
      }
      return {
        isReady: true,
        modals: invoicesStore.modals,
        onboardingData: getOnboardingData(organisationStore, permissions, userStore),
        // Defensive: don't try to display invoices for deleted projects.
        invoices: invoicesStore.getMatchingInvoices().filter(inv => inv.project != null),
        columns: invoicesStore.columns,
        selectedColumns: reportStore.report.columns.map(c => reportStore.getColumnById(c)),
        reports: organisationStore.reports.filter(r => r.reportType === 'invoice'),
        reportStore: reportStore,
        isDirty: rootStore.isDirty,
        currencyFormatter: organisationStore.organisation.currencyFormatter,
        user: userStore.user
      };
    })
  ],

  render: function() {
    if (!this.state.isReady) {
      return null;
    }
    else if (!this.state.onboardingData.hasProjects) {
      return <IncompleteSetupPage
        heading="Invoices"
        onboardingData={this.state.onboardingData}
      />;
    }
    else {
      const columnPresentation = {
        description: {
          contentText: (item, i, stack, description) => description
        },
        project: {
          content: (item, i, stack, project) => project.getTitle()
        },
        amountExTax: {
          contentText: (item, i, stack, amount) => this.state.currencyFormatter.format(amount)
        },
        amountIncTax: {
          contentText: (item, i, stack, amount) => this.state.currencyFormatter.format(amount)
        },
        amountTax: {
          contentText: (item, i, stack, amount) => this.state.currencyFormatter.format(amount)
        },
        contact: {
          content: (item, i, stack, contact) => contact != null ? contact.display() : '-'
        }
      };

      return <CoincraftPage
        header={
          <ReportStoreWidget
            heading="Invoices"
            user={this.state.user}
            includesDateRange={false}
            items={this.state.invoices}
            reports={this.state.reports}
            reportStore={this.state.reportStore}
            canRefresh={!this.state.isDirty}
            columnPresentation={columnPresentation}
            filterInputPlaceholder="Filter by invoice number"
            buttons={[
              <button
                  key={1}
                  className="page-header__button--primary new-invoice-button"
                  onClick={() => invoicesActions.initiateCreateNewInvoice()}>
                + New Invoice
              </button>,
              permissions.canEditAccountingSystemSettings.ok(this.state.user) ?
                <button
                    key={2}
                    className="page-header__button--primary edit-accounting-system-settings-button"
                    onClick={() => organisationActions.openAccountingSystemSettingsPopup()}>
                  <i className="fa fa-gear" />
                  Settings
                </button>
              : null
            ]}
          />
        }
        saveBar={
          <ReportStoreSaveBar
            reportStore={this.state.reportStore}
            user={this.state.user}
          />
        }
        body={
          <div style={{ height: '100%', minHeight: '30em'}}>
            {this.state.modals.map(function(modal, i) {
              let closeOnClickOutside, width, header, content, modal2;

              switch (modal.type) {
                case 'error':
                  modal2 = <ErrorModal
                    modal={modal}
                    onDismiss={() => invoicesActions.dismissError(modal.operation)}
                    onCancel={() => invoicesActions.cancelModal(modal.type)}
                  />;
                  break;
                default:
                  throw new Error(`Unrecognised modal type ${modal.type}`);
              }

              return <div key={i}>
                {modal2 != null ?
                  modal2
                :
                  <Modal2
                    key={i}
                    header={header}
                    closeOnClickOutside={closeOnClickOutside}
                    width={width}
                    onCancel={() => invoicesActions.cancelModal(modal.type)}>
                  {content}
                </Modal2>}
              </div>;
            })}


            <Table
              scroll={true}
              rows={this.state.invoices}
              tableProps={{
                className: "data-table",
                style: {
                  width: '95%',
                  maxWidth: '70em',
                  textAlign: 'left',
                  display: 'inline-block',
                  height: '100%'
                }
              }}
              rowProps={function(row, i) {
                return {
                  key: row.id,
                  style: {
                    cursor: 'pointer'
                  },
                  onClick: function() {
                    router.history.push(`/dashboard/invoices/${row.id}`);
                  }
                };
              }}
              columns={this.state.selectedColumns}
              columnPresentation={columnPresentation}
              initialSort={{
                columnIdentifier: 'issuedOn',
                direction: 'desc'
              }}
            />
          </div>
        }
      />;
    }
  }
});


export var InvoicesPageWrapper = reportsPageWrapper(invoicesStore, InvoicesPage);
