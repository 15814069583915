import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from '../organisation.js';
import { dispatcher, StoreBase, registerActions, handleAction } from '../coincraftFlux.js';
import { ReportStore } from '../reports/flux.js';
import { sum, groupBy, areSameDbObjects } from '../utils.js';
import { getEntries } from '../timesheets/ReportQuery.js';
import { router } from '../router.js';
import { Report } from '../reports/Report.js';
import { Link } from 'react-router';
import { Column } from '../table.js';
import { mixinAccountingSettingsMethods } from './mixinAccountingSettingsMethods.js';
import * as modalTypes from '../modalTypes.js';
export { Invoice } from "../models/Invoice.js";


const invoicesActionDefinitions = [
  {action: 'loadPage', args: []},
  {action: 'initiateCreateNewInvoice', args: []},
  {action: 'initiateEditTemplates', args: []},
  {action: 'createNewInvoice', args: ['projectId', 'startDate', 'endDate']},
  {action: 'cancelModal', args: ['modalType']},
  {action: 'saveAccountingSystemSettings', args: ['accountingSystemId', 'settings', 'generalSettings', 'then']},
  {action: 'saveAccountingSystemSettingsSuccess', args: ['accountingSystemId', 'settings', 'generalSettings', 'then']},
  {action: 'error', args: ['errorCode']},
  {action: 'dismissError', args: ['errorCode']},
];
export const invoicesActions = registerActions("invoices-page", invoicesActionDefinitions, dispatcher);


class InvoiceStore extends StoreBase {
  constructor() {
    super();

    let self = this;
    this.columns = [
      new Column({
        identifier: 'description',
        header: "Invoice #",
        width: '25%',
        data: inv => inv.description,
        type: 'string',
        isMandatory: true,
        content: function(inv) {
          return <Link to={`/dashboard/invoices/${inv.id}`}>
            {inv.description || "(No description)"}
          </Link>;
        }
      }),
      new Column({
        identifier: 'issuedOn',
        header: "Issue date",
        width: '25%',
        data: inv => inv.issuedOn,
        type: 'moment',
        content: function (inv, i, stack, data) {
          return data.format("ddd DD MMMM, YYYY");
        },
        exportText: function (inv, i, stack, data) {
          return data.format('YYYY-MM-DD');
        }
      }),
      new Column({
        identifier: 'dueDate',
        header: "Due date",
        width: '25%',
        data: inv => inv.dueDate,
        type: 'moment',
        content: function(inv, i, stack, data) {
          return data.format("ddd DD MMMM, YYYY");
        },
        exportText: function (inv, i, stack, data) {
          return data.format('YYYY-MM-DD');
        }
      }),
      new Column({
        identifier: 'project',
        header: "Project",
        width: '20%',
        data: inv => inv.project,
        type: 'project'
      }),
      new Column({
        identifier: 'contact',
        header: "Contact",
        width: '20%',
        data: inv => inv.contact,
        type: 'contact'
      }),
      new Column({
        identifier: 'amountExTax',
        header: "Amount ex tax",
        width: '20%',
        type: 'number',
        data: inv => inv.totalExTax,
        content: function(inv) {
          return <span>{self.currencyFormatter.formatWithCents(inv.totalExTax)}</span>;
        }
      }),
      new Column({
        identifier: 'amountIncTax',
        header: "Amount inc tax",
        width: '20%',
        type: 'number',
        data: inv => inv.totalIncTax,
        content: function(inv) {
          return <span>{self.currencyFormatter.formatWithCents(inv.totalIncTax)}</span>;
        }
      }),
      new Column({
        identifier: 'amountTax',
        header: "Tax amount",
        width: '20%',
        type: 'number',
        data: inv => inv.totalTax,
        content: function(inv) {
          return <span>{self.currencyFormatter.formatWithCents(inv.totalTax)}</span>;
        }
      }),
      new Column({
        identifier: 'synced',
        header: "Synced",
        width: '10%',
        type: 'bool',
        props: {style: {textAlign: 'center'}},
        data: inv => inv.accountingSystemId != null,
        content: (inv, i, stack, data) => (data ? 'Yes' : 'No')
      })
    ];

    this.reportStore = new ReportStore({
      path: "invoices-page/report",
      columns: this.columns
    });

    mixinAccountingSettingsMethods({
      store: this,
      actions: invoicesActions,
      onSave: (function(accountingSystemId, settings, generalSettings, then) {
        if (then === 'closePopup') {
          organisationStore.closeModalByType(modalTypes.accountingSystemSettings);
        }
        else {
          throw new Error("then");
        }
      }).bind(this)
    });

    this.timesheetEntries = null;

    this.modals = [];
    this.isReady = false;

    this.actionDefinitions = invoicesActionDefinitions;
  }

  handle(action) {
    if (action.path === "invoices-page") {
      handleAction(action, this);
    }
    else if (action.path === "invoices-page/report") {
      this.reportStore.handle(action);
      this.emitChanged();
    }
  }

  get currencyFormatter() {
    return organisationStore.organisation.currencyFormatter;
  }

  loadPage() {
    this.modals = [];
    this.emitChanged();
  }

  loadReport(report) {
    if (report == null) {
      report = new Report({
        name: "New report",
        reportType: "invoice",
        columns: ['description', 'issuedOn', 'project', 'amountIncTax', 'synced']
      });
    }

    this.reportStore.report = report;
    this.isReady = true;
    this.emitChanged();
  }

  initiateCreateNewInvoice() {
    organisationStore.openModal({type: modalTypes.newInvoice});
  }

  initiateEditTemplates() {
    organisationStore.openModal({ type: modalTypes.descriptionTemplates });
  }

  createNewInvoice(projectId, startDate, endDate) {
    organisationStore.modals = [];
    organisationStore.emitChanged();
    router.history.push(`/dashboard/invoices/new/project/${projectId}?`
      + `startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`);
  }

  toDefaultPage() {
    router.history.push("/dashboard/invoices");
  }

  getMatchingInvoices() {
    return this.reportStore.getMatchingItems(organisationStore.objects.Invoice.list);
  }

  newInvoicePopupSelectProject(project) {
    this.newInvoicePopupSelectedProject = project;
    this.emitChanged();
  }

  cancelModal(modalType) {
    if (modalTypes[modalType] != null) {
      organisationStore.closeModalByType(modalType);
    }
    else {
      this.modals = this.modals.filter(m => m.type !== modalType);
      this.emitChanged();
    }
  }

  error(errorCode) {
    this.modals.push({type: 'error', errorCode: errorCode});
    this.emitChanged();
  }

  dismissError(errorCode) {
    this.modals = this.modals.filter(m => !(m.type === 'error' && m.errorCode === errorCode));
    this.emitChanged();
  }
}


export function retrieveTimesheetEntries(invoice) {
  /**
   * Returns a promise that resolves to a list of `TimesheetEntry`s.
   */
  return new Promise(function(resolve, reject) {
    getEntries(new Report(Report.transformArgs({
      dateRange: {
        id: 'custom',
        start: invoice.startDate.format("YYYY-MM-DD"),
        end: invoice.endDate.format("YYYY-MM-DD")
      },
      filters: [
        {
          columnId: 'project',
          matcher: {
            type: 'project',
            value: [invoice.project.id]
          }
        }
      ]
    }))).then(function({entries}) {
      resolve(entries);
    }, function(error) {
      reject(error);
    });
  });
}


export function getMatchingEntries(
    entries, // List of TimesheetEntry objects
    phase,
    task,
    {
      isBillable = null,    // If true or false, include only entries with that value
      isVariation = null,   // If true or false, include only entries with that value
      staffMemberIds = null // If a list of ids, include only entries matching staff
    } = {}) {
  /**
   * Returns a list of TimesheetEntry objects.
   */
  const phaseToCheck = (phase.id !== -1) ? phase : null;

  return entries.filter(function(te) {
    return areSameDbObjects(te.projectPhase, phaseToCheck)
      && (te.numMinutes > 0 || (te.notes != null && te.notes !== ''))
      && (staffMemberIds == null || _.include(staffMemberIds, te.staffMember.id))
      && (
        task === 'all'
        || (task == null && te.task == null)
        || (task != null && te.task != null && te.task.uuid === task.uuid)
      )
      && (isBillable == null || te.isBillable === isBillable)
      && (isVariation == null || te.isVariation === isVariation);
  });
}


export function getStaffDataLookup(
    entries,
    phase,
    task,
    {
      isBillable = null,    // If true or false, include only entries with that value
      isVariation = null,   // If true or false, include only entries with that value
      staffMemberIds = null // If a list of staff ids, include exactly that set of ids.
    } = {}) {
  /**
   * `entries`: list of `TimesheetEntry`s.

     Returns:

      {
        staffId: {
          staffId: int,
          staffName: string,
          numHours: number,
          chargeOutRate: number
        }
      }
   */

  const matchingEntries = getMatchingEntries(entries, phase, task, {
    isBillable: isBillable,
    isVariation: isVariation,
    staffMemberIds: staffMemberIds
  });
  const staffGroups = groupBy(matchingEntries, te => te.staffMember, sm => sm.id);

  let lookup = {};
  for (let {grouper: staffMember, items: entries} of staffGroups) {
    if (staffMemberIds == null || _.include(staffMemberIds, staffMember.id)) {
      const totalHours = sum(entries.map(te => te.numMinutes)) / 60;
      const totalChargeOut = sum(entries.map(te => te.chargeOut));
      lookup[staffMember.id] = {
        staffId: staffMember.id,
        staffName: organisationStore.getStaffMemberById(staffMember.id).getFullName(),
        numHours: totalHours,
        chargeOutRate: totalChargeOut / totalHours,
        timeEntryIds: entries.map(te => te.id)
      };
    }
  }

  if (staffMemberIds != null) {
    for (let id of staffMemberIds) {
      // If we have included staff members who have no timesheets satisfying the query,
      // add them with zero hour entries.
      if (lookup[id] == null) {
        const sm = organisationStore.getStaffMemberById(id);
        lookup[id] = {
          staffId: id,
          staffName: sm.getFullName(),
          numHours: 0,
          chargeOutRate: sm.chargeOutRate,
          timeEntryIds: []
        };
      }
    }
  }

  return lookup;
}


export var invoicesStore = new InvoiceStore();



