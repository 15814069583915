import _ from 'underscore';
import moment from 'moment';
import { StoreBase, registerActions, handleAction, dispatcher } from '../coincraftFlux.js';
import { Enum } from '../enum.js';
import { ProjectTaskStore } from './tasks.js';
import { organisationStore } from '../organisation.js';
import { ContactSelectorStore } from '../widgets/ContactSelectorStore.js';
import { areIntersectingKeyValuesEqual, isNumber, generateUUID } from '../utils.js';
import { router } from '../router.js';
import { ProjectPhase } from '../models.js';
import { ProjectNote } from '../models/projectnote.js';
import { permissions } from '../models/permissions.js';
import { userStore } from '../user/flux.js';
import { phaseRevenueDateMap, phaseStaffDateMap, phaseRoleDateMap } from './PhaseDates.js' 
import { dateConverter } from '../models/dateconverter.js';
import { ChangeLogItem } from '../models/changelogitem.js';


export const MilestoneSyncState = Enum(['synced']);

const feeProps = ['fee', 'startDate', 'endDate']
const budgetProps = [
  'manualBudget',
  'manualHoursBudget',
  'manualExpensesBudget',
  'startDate',
  'endDate'
]


const projectPageActionDefinitions = [
  {action: 'setProjectProp', args: ['name', 'value']},
  {action: 'setProjectPhaseProp', args: ['phase', 'name', 'value']},
  {action: 'setChangeLogItemProp', args: ['project', 'index', 'propName', 'value']},
  {action: 'addChangeLogItem', args: ['project']},
  {action: 'deleteChangeLogItem', args: ['project', 'index']},
  {action: 'deletePhase', args: ['phase']},
  {action: 'resetMilestones', args: []},
  {action: 'resetMilestonesSuccessTimeout', args: []},
  {action: 'clearCantDeletePopup', args: ['phase']},
  {action: 'syncAllocations', args: []},
  {action: 'save', args: []},
  {action: 'confirmSave', args: ['data']},
  {action: 'cancelSave', args: []},
  {action: 'saveSuccess', args: ['data'] },
  {action: 'saveSuccessTimeout', args: [] },
  {action: 'saveFailure', args: [] },

  {action: 'addPhase', args: ['project']},
  {action: 'toggleStaffBudgets', args: ['phase']},
  {action: 'setPhaseBudgetedHoursHours', args: ['phase', 'item', 'hours']},
  {action: 'deletePhaseBudgetedHours', args: ['phase', 'item']},
  {action: 'setPhaseDurationUnit', args: ['phase', 'durationUnit']},
  {action: 'setPhaseDuration', args: ['phase', 'duration']},
  {action: 'setProjectDurationUnit', args: ['project', 'durationUnit']},
  {action: 'setProjectDuration', args: ['project', 'duration']},
  {action: 'linkUpPhaseFee', args: ['phase']},
  {action: 'linkDownPhaseFee', args: ['phase']},
  {action: 'unlinkPhaseFee', args: ['phase']},
  {action: 'linkUpPhaseExpenseBudget', args: ['phase']},
  {action: 'linkDownPhaseExpenseBudget', args: ['phase']},
  {action: 'unlinkPhaseExpenseBudget', args: ['phase']},
  {action: 'linkUpPhaseHoursBudget', args: ['phase']},
  {action: 'linkDownPhaseHoursBudget', args: ['phase']},
  {action: 'unlinkPhaseHoursBudget', args: ['phase']},
  {action: 'changePhaseBudgetedHoursItem', args: ['phase', 'item', 'budgetedHours']},
  {action: 'addNewPhaseBudgetedHours', args: ['phase']},
  {action: 'clickCopyProject', args: [] },
  {action: 'addError', args: ['error'] },
  {action: 'removeError', args: ['error'] },
  {action: 'copyProject', args: ['data'] },
  {action: 'updateMilestones', args: [] },
  {action: 'updateAllocations', args: [] },
  {action: 'setMilestonePercent', args: ['phase', 'milestone', 'percent'] },
  {action: 'setMilestoneRevenue', args: ['phase', 'milestone', 'revenue'] },
  {action: 'setMilestoneDate', args: ['phase', 'milestone', 'dateMoment'] },
  {action: 'createMilestone', args: ['phase'] },
  {action: 'deleteMilestone', args: ['phase','milestone'] },
  {action: 'createNote', args: [] },
  {action: 'deleteNote', args: ['note'] },
  {action: 'setNoteDate', args: ['note', 'date'] },
  {action: 'setNoteDescription', args: ['note', 'description'] },

  {action: 'addRate', args: []},
  {action: 'setRateField', args: ['rateIndex', 'field', 'value']},
  {action: 'setRatePhase', args: ['rateIndex', 'phase']},
  {action: 'setRateItem', args: ['rateIndex', 'item']},
  {action: 'deleteRate', args: ['rateIndex']},

  { action: 'setDirty', args: ['dirty'] },
  { action: 'toggleMenu', args: ['showMenu'] },

  { action: 'changeForecastType', args: ['forecastType']},
  { action: 'changeDisplayedProjectPhase', args: ['displayedProjectPhase']},

  { action: 'changeUpdateForecastSelection', args: ['updateForecastSelection'] },
  { action: 'changeUpdateBudgetValue', args: ['updateBudgetValue'] },
  { action: 'changeUpdateForecastStartDate', args: ['startDate'] },
  { action: 'changeUpdateForecastEndDate', args: ['endDate'] },
  { action: 'changeUpdateForecastCustomStartDate', args: ['customStartDate'] },
  { action: 'changeUpdateForecastCustomEndDate', args: ['customEndDate'] },
  { action: 'changeSelectedTab', args: ['selectedTab'] }
];

export const actions = registerActions("project-page", projectPageActionDefinitions, dispatcher);


export const ProjectStore = class extends StoreBase {
  constructor() {
    super();
    this.path = "project-page";
    this.project = null;
    this.setDirty(false);
    this.selectedTab = "projectDetails"

    // If the user tried to delete a phase they couldn't delete, this variable
    // points to that phase.
    this.cantDeletePopup = false;

    this.confirmOverwriteBillabilityPopup = false;
    this.confirmSyncPopup = false;
    this.copyProjectPopup = false;

    this.saveState = null;
    this.modifiedFee = false;
    this.modifiedBudget = false; 
    this.datesChanged = false;

    this.updateForecastSelection = null;
    this.updateBudgetValue = "remainingBudget"
    this.updateForecastStartDate = "now"
    this.updateForecastEndDate = "endDate"
    this.updateForecastCustomStartDate = null;
    this.updateForecastCustomEndDate = null;

    this.modifiedPhases = new Set()
    this.modifiedStaffBudgets = new Set()
    this.modifiedRoleBudgets = new Set()
    this.deletedStaffBudgets = new Set()
    this.deletedRoleBudgets = new Set()

    this.expandedPhases = [];
    this.errors = [];

    this.showMenu = false;

    this.stores = {
      'contact-selector': new ContactSelectorStore('project-page/contact-selector')
    };

    this.projectTaskStore = new ProjectTaskStore({
      path: "project-page/tasks"
    });

    this.actionDefinitions = projectPageActionDefinitions;
  }

  handle(action) {
    if (action.path.startsWith("project-page/contact-selector")) {
      if (action.type === 'contact/saveSuccess') {
        let contact = this.stores['contact-selector'].handle(action);
        this.setProjectProp('contact', contact);
      }
      else {
        this.stores['contact-selector'].handle(action);
      }
    }
    else if (action.path === "project-page/tasks") {
      this.projectTaskStore.handle(action);
      this.setDirty(true);
    }
    else {
      handleAction(action, this);
    }
  }

  _storeInitialProjectValues(project) {
    this.oldIsBillable = project.costCentre.isBillable;
    this.oldTaskBillabilityLookup = project.getTaskBillabilityLookup();
  }

  loadProject(project) {
    const finPerm = permissions.financialVisibilityRevenue.ok(userStore.user)
    const expPerm = permissions.financialVisibilityExpenses.ok(userStore.user)
    const adminPerm = permissions.projectAdmin(project).ok(userStore.user)
    const pmPerm = permissions.projectManager(project).ok(userStore.user);
    this._storeInitialProjectValues(project);
    this.project = project;
    this.projectTaskStore.project = project;
    this.isDirty = project.isDirty;
    this.saveState = null;
    this.modifiedFee = false;
    this.modifiedPhases = new Set();
    this.modifiedStaffBudgets = new Set()
    this.modifiedRoleBudgets = new Set()
    this.deletedStaffBudgets = new Set()
    this.deletedRoleBudgets = new Set()
    this.modifiedBudget = false; 
    this.milestoneSyncState = null; // MilestoneSyncState
    this.forecastType =
		pmPerm && finPerm
			? "revenueVsExpenses"
			: expPerm
			? "expenseBudget"
			: "hoursBudget";
    this.displayedProjectPhase = project
    this.selectedTab = "projectDetails"
    this.updateBudgetValue = project.milestoneType === "manual" ? "totalBudget" : "remainingBudget"
    this.updateForecastStartDate = project.milestoneType === "manual" ? "endDate" : "now"
    this.updateForecastEndDate = "endDate"
    this.emitChanged();
  }

  setDirty(dirty) {
    this.isDirty = dirty;
    if (this.project) {
      this.project.isDirty = dirty;
    }
  }

  getProjectDates(project) {
    let dates = project.phases.map(phase => [phase.startDate, phase.endDate]);
    return dates;
  }

  setProjectProp(name, value) {
    if (!_.include(['name', 'jobCode', 'manualBudget', 'manualHoursBudget', 'manualExpensesBudget', 'contact',
          'costCentre', 'status', 'phases', 'expenses', 'fee', 'startDate', 'endDate', 'milestoneType', 'likelihood'], name)) {
      throw new Error("Unknown prop name");
    }

    if (feeProps.includes(name) && this.project.hasDates) {
      this.modifiedFee = true
      this.project.getVisiblePhases().forEach(ph => this.modifiedPhases.add(ph))
    }

    if (budgetProps.includes(name) && this.project.hasDates) {
      this.modifiedBudget = true
      this.project.getVisiblePhases().forEach(ph => {
        ph.staffMemberBudgetedHours.forEach(b => this.modifiedStaffBudgets.add(b))
        ph.staffRoleBudgetedHours.forEach(b => this.modifiedRoleBudgets.add(b))
      })
    }

    if (name === 'manualBudget') {
      this.project.setManualBudget(value);
    }
    else if (name === 'manualHoursBudget') {
      this.project.setManualHoursBudget(parseFloat(value));
    }
    else if (name === 'expenses') {
      this.project.setExpenses(value);
    } else if (name === 'fee') {
      this.project.setFee(parseFloat(value));
    } else if (name === 'startDate') {
      if (value != null) {
        this.setProjectDates(this.project, dateConverter.momentToInt(value), dateConverter.momentToInt(this.project.getEndDate()));
      }
    } else if (name === 'endDate') {
      if (value != null) {
        this.setProjectDates(this.project, dateConverter.momentToInt(this.project.getStartDate()), dateConverter.momentToInt(value));
      }
    }
    else {
      this.project[name] = value;
    }
    this.setDirty(true);
    this.emitChanged();
  }

  setProjectDates(project, startDate, endDate) {
    let self = this;
    let factor = (endDate - startDate) / (dateConverter.momentToInt(project.getEndDate()) - dateConverter.momentToInt(project.getStartDate()));

    project.getVisiblePhases().forEach(function(phase) {
      if (isNumber(factor)) {
        self.setPhaseDates(phase,
          startDate + (phase.startDate - dateConverter.momentToInt(project.getStartDate())) * factor,
          startDate + (phase.endDate - dateConverter.momentToInt(project.getStartDate())) * factor
        );
      }
      else {
        // If the project was zero size when we started.
        self.setPhaseDates(phase, startDate, endDate);
      }
    });
  }

  setPhaseDates(phase, startDate, endDate) {
    phase.setDates(startDate, endDate);
    if(phase.hasDates) {
      this.modifiedFee = true;
      this.modifiedPhases.add(phase)
      this.modifiedBudget = true;
      phase.staffMemberBudgetedHours.forEach(b => this.modifiedStaffBudgets.add(b))
      phase.staffRoleBudgetedHours.forEach(b => this.modifiedRoleBudgets.add(b))
    }
  }

  setProjectPhaseProp(phase, name, value) {
    if (!_.include(['name', 'jobCode', 'startDate', 'endDate', 'fee', 'manualBudget', 'manualHoursBudget', 'status', 'likelihood'], name)) {
      throw new Error("Unknown prop name");
    }

    if (feeProps.includes(name) && phase.hasDates) {
      this.modifiedFee = true
      this.modifiedPhases.add(phase)
    }

    if (budgetProps.includes(name) && phase.hasDates) {
      this.modifiedBudget = true
      phase.staffMemberBudgetedHours.forEach(b => this.modifiedStaffBudgets.add(b))
      phase.staffRoleBudgetedHours.forEach(b => this.modifiedRoleBudgets.add(b))
    }

    if (name === 'startDate') {
      this.setPhaseDates(phase, value ? dateConverter.momentToInt(value) : null, phase.endDate);
    } else if (name === 'endDate') {
      this.setPhaseDates(phase, phase.startDate, value ? dateConverter.momentToInt(value) : null);
    }
    else if (name === 'fee') {
      phase.setFee(parseFloat(value));
    }
    else if (name === 'manualBudget') {
      phase.setExpenseBudget(parseFloat(value));
    }
    else if (name === 'manualHoursBudget') {
      phase.setHours(parseFloat(value));
    }
    else {
      phase[name] = value;
    }
    this.setDirty(true);
    this.emitChanged();
  }

  setPhaseDurationUnit(phase, durationUnit) {
    phase.durationUnit = durationUnit;
    this.setDirty(true);
    this.emitChanged();
  }

  setPhaseDuration(phase, duration) {
    let startMoment = phase.getStartDate() || moment();
    let startDateInt = dateConverter.momentToInt(startMoment);
    if(phase.durationUnit === "months") {
      let endMoment =  startMoment.clone().add(duration, 'months').subtract(1, 'day');
      this.setPhaseDates(phase, startDateInt, dateConverter.momentToInt(endMoment));
    } else if(phase.durationUnit === "weeks") {
      this.setPhaseDates(phase, startDateInt, startDateInt + duration*7 -1);
    } else {
      this.setPhaseDates(phase, startDateInt, startDateInt + duration -1);
    }
    if (phase.hasDates) {
      this.modifiedFee = true;
      this.modifiedPhases.add(phase)
      this.modifiedBudget = true;
      phase.staffMemberBudgetedHours.forEach(b => this.modifiedStaffBudgets.add(b))
      phase.staffRoleBudgetedHours.forEach(b => this.modifiedRoleBudgets.add(b))
    }
    this.setDirty(true);
    this.emitChanged();
  }

  setProjectDurationUnit(project, durationUnit) {
    project.durationUnit = durationUnit;
    this.setDirty(true);
    this.emitChanged();
  }

  setProjectDuration(project, duration) {
    let startMoment = project.getStartDate() || moment();
    let startDateInt = dateConverter.momentToInt(startMoment);
    if(project.durationUnit === "months") {
      let endMoment = startMoment.clone().add(duration, 'months').subtract(1, 'day');
      this.setProjectDates(project, startDateInt, dateConverter.momentToInt(endMoment));
    } else if(project.durationUnit === "weeks") {
      this.setProjectDates(project, startDateInt, startDateInt + duration*7 -1);
    } else {
      this.setProjectDates(project, startDateInt, startDateInt + duration -1);
    }
    this.setDirty(true);
    this.emitChanged();
  }

  addChangeLogItem(project) {
    project.changeLog = project.changeLog.push(new ChangeLogItem({
      project: project,
      date: moment().startOf('day')
    }));
    this.setDirty(true);
    this.emitChanged();
  }

  deleteChangeLogItem(project, index) {
    project.changeLog = project.changeLog.remove(index);
    this.setDirty(true);
    this.emitChanged();
  }

  setChangeLogItemProp(project, index, propName, value) {
    project.changeLog = project.changeLog.updateIn([index], item => item.set({[propName]: value}));
    this.setDirty(true);
    this.emitChanged();
  }

  deletePhase(phase) {
    if (!phase.hasTimesheets) {
      this.project.removePhase(phase);
    }
    else {
      this.cantDeletePopup = phase;
    }
    this.setDirty(true);
    this.emitChanged();
  }

  addPhase(project) {
    let pp = new ProjectPhase({project: project});
    pp.createDefaultTask();
    project.phases.push(pp);
    this.setDirty(true);
    this.emitChanged();
  }

  toggleStaffBudgets(phase) {
    if(this.expandedPhases.includes(phase)) {
      this.expandedPhases = _.without(this.expandedPhases, phase)
    } else {
      this.expandedPhases.push(phase);
    }
    this.emitChanged();
  }

  setPhaseBudgetedHoursHours(phase, item, hours) {
    let itemType = item.constructor.getClassName();
    if (itemType === "StaffRole") {
      phase.setBudgetedHoursForStaffRole(item, hours);
      if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(item))
    } else {
      phase.setBudgetedHoursForStaffMember(item, hours);
      if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(item))
    }
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  deletePhaseBudgetedHours(phase, item) {
    let itemType = item.constructor.getClassName();
    if (itemType === "StaffRole") {
      const budgetedHours = phase.getBudgetedHoursObjectForStaffRole(item)
      phase.deleteBudgetedHoursForStaffRole(item);
      if (phase.hasDates) this.deletedRoleBudgets.add({ ...budgetedHours, item: item })
    } else {
      const budgetedHours = phase.getBudgetedHoursObjectForStaffMember(item)
      phase.deleteBudgetedHoursForStaffMember(item);
      if (phase.hasDates) this.deletedStaffBudgets.add({ ...budgetedHours, item: item})
    }
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  resetMilestones() {
    // https://docs.google.com/document/d/19vjqPLSbXnE0sXfcNj_B2slvMqhjjhy07zbMW_wfaFg/edit
    for (let p of this.project.phases) {
      if (p.startDate != null && p.endDate != null) {
        p.milestones = [];
        p.adjustMilestones(p.startDate, p.endDate);
      }
    }
    this.setDirty(true);
    this.milestoneSyncState = MilestoneSyncState.synced;
    this.emitChanged();
    setTimeout(actions.resetMilestonesSuccessTimeout, 1500);
  }

  resetMilestonesSuccessTimeout() {
    this.milestoneSyncState = null;
    this.emitChanged();
  }

  syncAllocations() {
    this.project.syncAllocationDates();
    this.setDirty(true);
    this.allocationSyncState = 'synced';
    this.emitChanged();
  }

  clearCantDeletePopup() {
    this.cantDeletePopup = null;
    this.emitChanged();
  }

  handleTasksChanged() {
    this.setDirty(true);
    this.emitChanged();
  }

  linkUpPhaseFee(phase) {
    if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
      let ratio = phase.fee / phase.getTotalChargeOutFromStaffBudgets();
      phase.getCombinedBudgetedHours().forEach((x) => {
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, x.hours*ratio);
          if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, x.hours*ratio);
          if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    } else {
      let feePortion = phase.fee / phase.getCombinedBudgetedHours().length;
      phase.getCombinedBudgetedHours().forEach((x) => {
        let hours = feePortion / x.item.chargeOutRate;
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, hours);
          if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, hours);
          if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    }
    phase.feeLinked = true;
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  linkDownPhaseFee(phase) {
    phase.feeLinked = true;
    phase.updateFeeFromStaffBudgets();
    this.setDirty(true);
    this.emitChanged();
  }

  unlinkPhaseFee(phase) {
    phase.feeLinked = false;
    this.setDirty(true);
    this.emitChanged();
  }

  linkUpPhaseExpenseBudget(phase) {
    if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
      let ratio = phase.manualBudget / phase.getTotalExpenseFromStaffBudgets();
      phase.getCombinedBudgetedHours().forEach((x) => {
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, x.hours*ratio);
      if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, x.hours*ratio);
      if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    } else {
      let budgetPortion = phase.manualBudget / phase.getCombinedBudgetedHours().length;
      phase.getCombinedBudgetedHours().forEach((x) => {
        let hours = budgetPortion / x.item.costRate;
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, hours);
      if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, hours);
      if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    }
    phase.expenseBudgetLinked = true;
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  linkDownPhaseExpenseBudget(phase) {
    phase.expenseBudgetLinked = true;
    phase.updateExpenseBudgetFromStaffBudgets();
    this.setDirty(true);
    this.emitChanged();
  }

  unlinkPhaseExpenseBudget(phase) {
    phase.expenseBudgetLinked = false;
    this.setDirty(true);
    this.emitChanged();
  }

  linkUpPhaseHoursBudget(phase) {
    if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
      let ratio = phase.manualHoursBudget / phase.getTotalHoursBudgetFromStaffBudgets();
      phase.getCombinedBudgetedHours().forEach((x) => {
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, x.hours*ratio);
          if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, x.hours*ratio);
          if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    } else {
      let budgetPortion = phase.manualHoursBudget / phase.getCombinedBudgetedHours().length;
      phase.getCombinedBudgetedHours().forEach((x) => {
        let itemType = x.item.constructor.getClassName();
        if (itemType === "StaffRole") {
          phase.setBudgetedHoursForStaffRole(x.item, budgetPortion);
          if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(x.item))
        } else {
          phase.setBudgetedHoursForStaffMember(x.item, budgetPortion);
          if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(x.item))
        }
      });
    }
    phase.hoursBudgetLinked = true;
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  linkDownPhaseHoursBudget(phase) {
    phase.hoursBudgetLinked = true;
    phase.updateHoursBudgetFromStaffBudgets();
    this.setDirty(true);
    this.emitChanged();
  }

  unlinkPhaseHoursBudget(phase) {
    phase.hoursBudgetLinked = false;
    this.setDirty(true);
    this.emitChanged();
  }

  changePhaseBudgetedHoursItem(phase, item, budgetedHours) {
    let oldType = budgetedHours.item.constructor.getClassName();
    let newType = item.constructor.getClassName();
    if (newType ==="StaffMember") {
      phase.setBudgetedHoursForStaffMember(item, budgetedHours.hours);
      if (phase.hasDates) this.modifiedStaffBudgets.add(phase.getBudgetedHoursObjectForStaffMember(item))
    } else if(newType ==="StaffRole") {
      phase.setBudgetedHoursForStaffRole(item, budgetedHours.hours);
      if (phase.hasDates) this.modifiedRoleBudgets.add(phase.getBudgetedHoursObjectForStaffRole(item))
    }
    if (oldType ==="StaffMember") {
      phase.deleteBudgetedHoursForStaffMember(budgetedHours.item);
      if (phase.hasDates) this.deletedStaffBudgets.add(budgetedHours)
    } else if(oldType ==="StaffRole") {
      phase.deleteBudgetedHoursForStaffRole(budgetedHours.item);
      if (phase.hasDates) this.deletedRoleBudgets.add(budgetedHours)
    }
    if (phase.hasDates) this.modifiedBudget = true
    this.setDirty(true);
    this.emitChanged();
  }

  addNewPhaseBudgetedHours(phase) {
    let allItems = [...organisationStore.staffRoles, ...organisationStore.getVisibleStaff()];
    let selectedItems = phase.getCombinedBudgetedHours().map(sbh => sbh.item);
    let availableItems = allItems.filter(s => {
      return !(selectedItems.map(s => s.id).includes(s.id));
    })
    let itemType = availableItems[0].constructor.getClassName();
    if (itemType === "StaffRole") {
      phase.setBudgetedHoursForStaffRole(availableItems[0], 0);
    } else {
      phase.setBudgetedHoursForStaffMember(availableItems[0], 0);
    }
    this.setDirty(true);
    this.emitChanged();
  }

  save() {
    this._validate();
    this.saveState = null; // hack - sometimes this is 'saved' state (cause TBD)
    if (this.isValid) {
      if (this.project.costCentre.isBillable !== this.oldIsBillable) {
        this.confirmOverwriteBillabilityPopup = true;
        this.emitChanged();
      }
      else if (!areIntersectingKeyValuesEqual(this.project.getTaskBillabilityLookup(), this.oldTaskBillabilityLookup)) {
        this.confirmOverwriteTimesheetPopup = true;
        this.emitChanged();
      } else if ((this.modifiedFee || this.modifiedBudget) && this.project.id != null && this.selectedTab === "projectDetails") {
        if (this.modifiedFee && this.modifiedBudget) {
          this.updateForecastSelection = "revenueResource"
        } else if (this.modifiedFee) {
          this.updateForecastSelection = "revenue"
        } else if (this.modifiedBudget) {
          this.updateForecastSelection = "resource"
        } else {
          this.updateForecastSelection = "nothing"
        }
        this.confirmSyncPopup = true;
        this.emitChanged();
      }
      else {
        this._save();
      }
    }
    else {
      this.saveInvalid();
    }
  }

  updateMilestones() {
    this.modifiedPhases.forEach(p => {
      if (!p.hasDates) return null
      let fee = this.updateBudgetValue === "remainingBudget" ? p.remainingRevenue : this.updateBudgetValue === "totalBudget" ? p.fee : 0;
      let startDate = phaseRevenueDateMap[this.updateForecastStartDate](p);
      let endDate = phaseRevenueDateMap[this.updateForecastEndDate](p);
      if (startDate && endDate) {
        if(endDate < startDate) fee = 0;
        p.syncMilestones(fee, startDate, endDate)
      }
    });
  }

  updateAllocations() {
    Array.from(this.modifiedStaffBudgets).filter(sb => sb).forEach(sb => {
      let p = sb.phase
      if (!p.hasDates) return null
      let hours = this.updateBudgetValue === "remainingBudget" ? p.getRemainingStaffHours(sb.staffMember) : this.updateBudgetValue === "totalBudget" ? sb.hours : 0;
      let startDate = phaseStaffDateMap[this.updateForecastStartDate](p, sb.staffMember);
      let endDate = phaseStaffDateMap[this.updateForecastEndDate](p, sb.staffMember);
      if (endDate < startDate) hours = 0;
      p.syncStaffMemberAllocations(sb.staffMember, hours, startDate, endDate)
    });
    Array.from(this.modifiedRoleBudgets).filter(rb => rb).forEach(rb => {
      let p = rb.phase
      if (!p.hasDates) return null
      let hours = this.updateBudgetValue === "remainingBudget" ? p.getRemainingRoleHours(rb.staffRole) : this.updateBudgetValue === "totalBudget" ? rb.hours : 0;
      let startDate = phaseRoleDateMap[this.updateForecastStartDate](p, rb.staffRole);
      let endDate = phaseRoleDateMap[this.updateForecastEndDate](p, rb.staffRole);
      if (endDate < startDate) hours = 0;
      p.syncStaffRoleAllocations(rb.staffRole, hours, startDate, endDate)
    });
    Array.from(this.deletedStaffBudgets).forEach(sb => {
      let p = sb.phase
      if (!p.hasDates) return null
      if (Array.from(this.modifiedStaffBudgets).map(sb2 => sb2.staffMember.uuid).includes(sb.item.uuid)) return null
      p.setStaffAllocation(sb.item, 0)
    })
    Array.from(this.deletedRoleBudgets).forEach(rb => {
      let p = rb.phase
      if (!p.hasDates) return null
      if (Array.from(this.modifiedRoleBudgets).map(rb2 => rb2.staffRole.uuid).includes(rb.item.uuid)) return null
      p.setRoleAllocation(rb.item, 0)
    })
  }

  clickCopyProject() {
    this.copyProjectPopup = true;
    this.emitChanged();
  }

  confirmSave(data = {}) {
    this._save(data);
  }

  cancelSave() {
    this._closeConfirmSavePopups();
    this.emitChanged();
  }

  get updateRevenueForecast() {
    return this.updateForecastSelection && this.updateForecastSelection.toLowerCase().includes('revenue')
  }

  get updateResourceSchedule() {
    return this.updateForecastSelection && this.updateForecastSelection.toLowerCase().includes('resource')
  }

  _save() {
    if (this.project.id == null) {
      this.project.getVisiblePhases().forEach(p => p.createMilestones());
      this.updateAllocations();
    }
    if (this.updateRevenueForecast) {
      this.updateMilestones();
    }
    if (this.updateResourceSchedule) {
      this.updateAllocations();
    }
    this.saveState = 'saving';
    this.emitChanged();

    organisationStore.saveProject(this.project, this.project.id == null).then(
		function(data) {
			actions.saveSuccess(data);
		},
		function(err) {
			actions.saveFailure();
		}
	);
  }

  _closeConfirmSavePopups() {
    this.confirmOverwriteBillabilityPopup = false;
    this.confirmOverwriteTimesheetPopup = false;
    this.confirmSyncPopup = false;
    this.copyProjectPopup = false;
  }

  saveSuccess(data) {
    this._closeConfirmSavePopups();

    if (this.project.id != null) {
      // If we saved and updated the cost centre billability then the server will
      // have updated the billability of our tasks, so we need to update the
      // values on the form to reflect this.
      for (let p of data.project.phases) {
        let thisPhase = _.find(this.project.phases, pp => pp.uuid === p.uuid);
        if (thisPhase != null) {
          for (let t of p.tasks) {
            let thisTaskIndex = thisPhase.tasks.findIndex(tt => tt.uuid === t.uuid);
            if (thisTaskIndex !== -1) {
              thisPhase.tasks = thisPhase.tasks.setIn([thisTaskIndex, 'isBillable'], t.isBillable);
            }
          }
        }
      }
      this._storeInitialProjectValues(this.project);
      this.project.initRates();
      this.modifiedFee = false;
      this.modifiedPhases = new Set();
      this.modifiedStaffBudgets = new Set()
      this.modifiedRoleBudgets = new Set()
      this.deletedStaffBudgets = new Set()
      this.deletedRoleBudgets = new Set()
      this.modifiedBudget = false; 

      this.setDirty(false);
      this.saveState = 'saved';
      this.emitChanged();
      setTimeout(function() {
        actions.saveSuccessTimeout();
      }, 1500);
    }
    else {
      this.setDirty(false);
      router.history.replace(`/dashboard/project/${data.project.id}`);
    }
  }

  saveSuccessTimeout() {
    this.saveState = null;
    this.emitChanged();
  }

  saveFailure() {
    this.saveState = 'failed';
    this.emitChanged();
  }

  saveInvalid() {
    this.saveState = this.project.getErrors().length > 0 ? 'invalid' : 'failed';
    this.emitChanged();
  }

  addError(error) {
    this.errors.push(error);
  }

  removeError(error) {
    this.errors = _.reject(this.errors, error);
  }

  copyProject({project, name, jobCode, startDate}) {
    project.name = name;
    project.jobCode = jobCode;
    if (startDate) {
      project.moveBy(dateConverter.momentToInt(startDate) - dateConverter.momentToInt(project.getStartDate()));
    }
    project.uuid = generateUUID();

    this.project = null;
    this.loadProject(project);
    this.save();
  }

  _validate() {
    this.isValid = this.project.isValid();
  }

  setMilestonePercent(phase, milestone, percent) {
    if((Math.round(percent * 100) / 100) !== (Math.round(milestone.percent * 100) / 100)) {
      milestone.setPercent(percent);
      phase.setupMilestones();
      phase.updateMilestoneRevenuesBasedOnPercent();
      this.setDirty(true);
      this.emitChanged();
    }
  }

  setMilestoneRevenue(phase, milestone, revenue) {
    if((Math.round(revenue * 100) / 100) !== (Math.round(milestone.revenue * 100) / 100)) {
      milestone.setRevenue(revenue);
      phase.updateMilestonePercentsBasedOnRevenue();
      phase.setupMilestones();
      this.setDirty(true);
      this.emitChanged();
    }
  }

  setMilestoneDate(phase, milestone, dateMoment) {
    const dateInt = dateConverter.momentToInt(dateMoment);
    milestone.setEndDate(dateInt);
    phase.setupMilestones();
    phase.updateMilestonePercentsBasedOnRevenue();
    this.setDirty(true);
    this.emitChanged();
  }

  createMilestone(phase) {
    phase.addMilestone();
    phase.setupMilestones();
    phase.updateMilestoneRevenuesBasedOnPercent();
    this.setDirty(true);
    this.emitChanged();
  }

  deleteMilestone(phase, milestone){
    phase.milestones = _.without(phase.milestones, milestone);
    phase.setupMilestones();
    phase.updateMilestonePercentsBasedOnRevenue();
    this.setDirty(true);
    this.emitChanged();
  }

  createNote(){
    let newNote = new ProjectNote();
    this.project.notes.unshift(newNote);
    this.setDirty(true);
    this.emitChanged();
  }

  deleteNote(note){
    this.project.notes = _.without(this.project.notes, note);
    this.setDirty(true);
    this.emitChanged();
  }

  setNoteDate(note, date){
    note.date = date;
    this.setDirty(true);
    this.emitChanged();
  }

  setNoteDescription(note, description){
    note.description = description;
    this.setDirty(true);
    this.emitChanged();
  }

  addRate() {
    let newRate = {
      date: '2000-01-01',
      phaseUuid: null,
      costRate: null,
      chargeOutRate: null,
      itemType: "StaffMember",
      itemUuid: organisationStore.staffMembers[0].uuid,
    };
    this.project.rates.push(newRate);
    this.project.initRates();
    this.isDirty = true;
  }

  setRateField(rateIndex, field, value) {
    if (field != 'date') {
      value = value == '' ? null : value;
    }
    this.project.rates[rateIndex][field] = value;
    let phase = this.project.rates[rateIndex].phase();
    if(phase) {
      phase.updateExpenseBudgetFromStaffBudgets();
      phase.updateFeeFromStaffBudgets();
    } else {
      this.project.phases.forEach(ph => {
        ph.updateExpenseBudgetFromStaffBudgets();
        ph.updateFeeFromStaffBudgets();
      })
    }
    this.isDirty = true;
  }

  setRatePhase(rateIndex, phase) {
    if (phase) {
      this.project.rates[rateIndex]["phaseUuid"] = phase.uuid;
      phase.updateExpenseBudgetFromStaffBudgets();
      phase.updateFeeFromStaffBudgets();
    } else {
      this.project.rates[rateIndex]["phaseUuid"] = undefined;
      this.project.phases.forEach(ph => {
        ph.updateExpenseBudgetFromStaffBudgets();
        ph.updateFeeFromStaffBudgets();
      })
    }
    this.isDirty = true;
  }

  setRateItem(rateIndex, item) {
    this.project.rates[rateIndex]["itemType"] = item.constructor.getClassName();
    this.project.rates[rateIndex]["itemUuid"] = item.uuid;
    let phase = this.project.rates[rateIndex].phase();
    if(phase) {
      phase.updateExpenseBudgetFromStaffBudgets();
      phase.updateFeeFromStaffBudgets();
    } else {
      this.project.phases.forEach(ph => {
        ph.updateExpenseBudgetFromStaffBudgets();
        ph.updateFeeFromStaffBudgets();
      })
    }
    this.isDirty = true;
  }

  deleteRate(rateIndex) {
    let phase = this.project.rates[rateIndex].phase();
    this.project.rates.splice(rateIndex, 1)
    this.isDirty = true;
    if(phase) {
      phase.updateExpenseBudgetFromStaffBudgets();
      phase.updateFeeFromStaffBudgets();
    } else {
      this.project.phases.forEach(ph => {
        ph.updateExpenseBudgetFromStaffBudgets();
        ph.updateFeeFromStaffBudgets();
      })
    }
  }

  toggleMenu(showMenu) {
    this.showMenu = showMenu !== undefined ? showMenu : !this.showMenu
  }

  changeForecastType(forecastType) {
    this.forecastType = forecastType;
    this.emitChanged();
  }

  changeDisplayedProjectPhase(displayedProjectPhase) {
    this.displayedProjectPhase = displayedProjectPhase;
    this.emitChanged();
  }

  changeUpdateForecastSelection(updateForecastSelection) {
    this.updateForecastSelection = updateForecastSelection;
    this.emitChanged();
  }
  changeUpdateBudgetValue(updateBudgetValue) {
    this.updateBudgetValue = updateBudgetValue;
    this.emitChanged();
  }
  changeUpdateForecastStartDate(startDate) {
    this.updateForecastStartDate = startDate;
    this.emitChanged();
  }
  changeUpdateForecastEndDate(endDate) {
    this.updateForecastEndDate = endDate;
    this.emitChanged();
  }
  changeUpdateForecastCustomStartDate(customStartDate) {
    this.updateForecastCustomStartDate = customStartDate;
    this.emitChanged();
  }
  changeUpdateForecastCustomEndDate(customEndDate) {
    this.updateForecastCustomEndDate = customEndDate;
    this.emitChanged();
  }

  changeSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
    this.showMenu = false;
    this.emitChanged();
  }
}


export let projectStore = new ProjectStore();
