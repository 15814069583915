import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from "underscore";
import { rootStore } from '../../RootStore';
import { organisationStore } from '../../organisation';
import { projectStore } from '../flux';
import { makeMultipleStoreMixin } from '../../coincraftFlux';
import { Spreadsheet } from '../../newSpreadsheet/Spreadsheet';
import { RevenueCell } from './cells/RevenueCell';
import Immutable from 'immutable';
import { TimesheetCell } from './cells/TimesheetCell';
import { LinearScale } from '../../utils.js';
import { CashFlowChart, SuperimposedDataRenderer } from "../../cashFlowChart.js";
import { projectForecastsStore, actions } from './ProjectForecastsStore';

import elementResizeDetectorMaker from "element-resize-detector";
import { BasicMySelect2 } from '../../widgets/generic';
import { ExpenseCell } from './cells/ExpenseCell';
import { AddPhaseModal } from './modals/AddPhaseModal';
import { AddExpenseModal } from './modals/AddExpenseModal';
import { AddAllocationModal } from './modals/AddAllocationModal';
import { userStore } from '../../user/flux';
import { dateConverter } from '../../models/dateconverter';
import PropTypes from "prop-types";
const erd = elementResizeDetectorMaker();

export var ProjectForecasts = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
  },

  mixins: [
    makeMultipleStoreMixin([rootStore, organisationStore, projectStore, projectForecastsStore], function () {
      if (!projectForecastsStore.isReady) {
        return { isReady: false };
      }

      return {
        isReady: projectForecastsStore.isReady,
        currentMonthIndex: projectForecastsStore.currentMonthIndex,
        startMonth: projectForecastsStore.startMonth,
        endMonth: projectForecastsStore.endMonth,

        columns: projectForecastsStore.getColumns(),
        profitRows: projectForecastsStore.profitSpreadsheet,
        revenueRows: projectForecastsStore.revenueSpreadsheet,
        timeRows: projectForecastsStore.timeSpreadsheet,
        expenseRows: projectForecastsStore.expenseSpreadsheet,
        graphData: projectForecastsStore.graphData,

        displayedProjectPhase: projectForecastsStore.displayedProjectPhase,
        forecastType: projectForecastsStore.forecastType,

        displayRevenue: projectForecastsStore.displayRevenue,
        displayExpenses: projectForecastsStore.displayExpenses,

        modal: projectForecastsStore.modal,
        
        user: userStore.user,
        organisationStore: organisationStore,
      };
    })
  ],

  componentWillMount: function () {
    projectForecastsStore.initialize(this.props.project);
  },

  componentWillUnmount: function () {
    actions.setIsReady(false)
  },

  shiftDate: function (direction) {
    if (direction === 'right') {
      actions.moveRight();
    } else if (direction === 'left') {
      actions.moveLeft();
    }
  },

  componentDidUpdate: function () {
    const { setupResizeListener, isReady } = this.state
    if (!setupResizeListener && isReady) {
      this.setupResizeHandler();
    }
  },

  setupResizeHandler() {
    let el = this.refs.forecast;
    erd.listenTo(el, this.handleResize);
    this.handleResize(el);
    this.setState({
      setupResizeListener: true,
    });
  },

  handleResize: function (element) {
    this.setState({
      hasSize: true,
      offsetWidth: element.offsetWidth,
      contentOffsetWidth: element.offsetWidth * 72 / 100,
      offsetHeight: element.offsetHeight
    });
  },

  render: function() {
	let self = this;
	let isAdmin = this.state.user?.permissions.isAdmin;
    if (!this.state.isReady) {
      return <div ref="forecast">Loading...</div>
    } else {
      return (
			<div ref="forecast">
				{this.renderModals()[this.state.modal]}
				<CashFlowChartContainer
					contentOffsetWidth={this.state.contentOffsetWidth}
					graphData={this.state.graphData}
					startMonth={this.state.graphData[0].monthIndex}
					yAxisWidth={"28%"}
					contentWidth={"72%"}
					yValueType={
						!this.state.forecastType.includes("hours")
							? "dollars"
							: "hours"
					}
				/>
				<div>
					<Spreadsheet
						style={{ margin: "2em 0" }}
						columns={this.state.columns}
						rows={this.state.profitRows}
						cellComponent={RevenueCell}
						edgeKeyPress={this.shiftDate}
					/>
				</div>
				{this.state.displayRevenue && (
					<div>
						<h2 style={{ marginTop: "1.5em" }}>Revenue</h2>
						<Spreadsheet
							style={{ margin: "1em 0" }}
							columns={this.state.columns}
							rows={this.state.revenueRows}
							cellComponent={RevenueCell}
							onCommitCell={function(cell, inputText) {
								if (!isAdmin) return false;
								return new Promise(function(resolve, reject) {
									actions.commitRevenueInputText(
										cell,
										inputText
									);
									self.props.onChange();
									resolve();
								});
							}}
							onRollbackCell={function(cell) {
								if (!isAdmin) return false;
								return new Promise(function(resolve, reject) {
									actions.rollbackRevenueInputText(cell);
									resolve();
								});
							}}
							onSetInputText={function(cell, inputText) {
								if (!isAdmin) return false;
								return new Promise(function(resolve, reject) {
									actions.setRevenueInputText(
										cell,
										inputText
									);
									resolve();
								});
							}}
							edgeKeyPress={this.shiftDate}
							selectCell={cell => {
								if (!isAdmin) return false;
								actions.selectRevenueCell(cell);
								this.props.toggleMenu(true);
							}}
						/>
					</div>
				)}
				<div>
					{this.state.forecastType.includes("hours") ? (
						<h2 style={{ marginTop: "1.5em" }}>Staff Hours</h2>
					) : (
						<h2 style={{ marginTop: "1.5em" }}>Staff Expenses</h2>
					)}
					<Spreadsheet
						style={{ margin: "1em 0" }}
						columns={this.state.columns}
						rows={this.state.timeRows}
						cellComponent={TimesheetCell}
						onCommitCell={function(cell, inputText) {
							if (!self.props.isEditable) return false;
							return new Promise(function(resolve, reject) {
								actions.commitHoursInputText(cell, inputText);
								self.props.onChange();
								resolve();
							});
						}}
						onRollbackCell={function(cell) {
							if (!self.props.isEditable) return false;
							return new Promise(function(resolve, reject) {
								actions.rollbackHoursInputText(cell);
								resolve();
							});
						}}
						onSetInputText={function(cell, inputText) {
							if (!self.props.isEditable) return false;
							return new Promise(function(resolve, reject) {
								actions.setHoursInputText(cell, inputText);
								resolve();
							});
						}}
						edgeKeyPress={this.shiftDate}
						selectCell={cell => {
							actions.selectHoursCell(cell);
							this.props.toggleMenu(true);
						}}
					/>
				</div>
				{this.state.displayExpenses && (
					<div>
						<h2 style={{ marginTop: "1.5em" }}>Expenses</h2>
						<Spreadsheet
							style={{ margin: "1em 0" }}
							columns={this.state.columns}
							rows={this.state.expenseRows}
							cellComponent={ExpenseCell}
							onCommitCell={function(cell, inputText) {
								if (!self.props.isEditable) return false;
								return new Promise(function(resolve, reject) {
									actions.commitExpenseInputText(
										cell,
										inputText
									);
									self.props.onChange();
									resolve();
								});
							}}
							onRollbackCell={function(cell) {
								if (!self.props.isEditable) return false;
								return new Promise(function(resolve, reject) {
									actions.rollbackExpenseInputText(cell);
									resolve();
								});
							}}
							onSetInputText={function(cell, inputText) {
								if (!self.props.isEditable) return false;
								return new Promise(function(resolve, reject) {
									actions.setExpenseInputText(
										cell,
										inputText
									);
									resolve();
								});
							}}
							edgeKeyPress={this.shiftDate}
							selectCell={cell => {
								actions.selectExpenseCell(cell);
								this.props.toggleMenu(true);
							}}
						/>
					</div>
				)}
			</div>
		);
    }
  },
  renderModals: function () {
    return {
      addPhase: <AddPhaseModal 
        onClose={() => actions.closeModal()} 
        onSubmit={(name) => {
          actions.addPhase(name)
          this.props.onChange()
        }} 
      />,
      addExpense: <AddExpenseModal 
        onClose={() => actions.closeModal()} 
        onSubmit={(name) => {
          actions.addExpense(name)
          this.props.onChange()
        }} 
      />,
      addAllocation: <AddAllocationModal 
        onClose={() => actions.closeModal()} 
        onSubmit={(options) => {
          actions.addAllocations(options)
          this.props.onChange()
        }} 
      />,
      null: null,
      undefined: null
    }
  }
});

var CashFlowChartContainer = CreateReactClass({
  propTypes: {
    contentOffsetWidth: PropTypes.number.isRequired,
    graphData: PropTypes.array.isRequired,
    startMonth: PropTypes.number.isRequired,
    yAxisWidth: PropTypes.string.isRequired, // percent-string
    contentWidth: PropTypes.string.isRequired, // percent-string
    yValueType: PropTypes.oneOf(['dollars', 'hours']).isRequired
  },

  // shouldComponentUpdate: function(nextProps) {
  //   //TODO needs to actually be aware of cell values for when visibilty changes
  //   return (this.props.contentOffsetWidth !== nextProps.contentOffsetWidth
  //           || this.props.startMonth !== nextProps.startMonth);
  // },

  render: function () {
    const data = this.props.graphData;
    const minX = data[0].date;
    const maxX = _.last(data).date + 31; //TODO-next_scheduler_fiddling

    const xScale = new LinearScale({
      minX: data[0].date,
      maxX: data[0].date + 365,
      minY: 0,
      maxY: this.props.contentOffsetWidth
    });

    return <CashFlowChart
      data={this.props.graphData}
      paddingBottom="20%"
      showLegend={false}
      renderer={new SuperimposedDataRenderer()}
      enableHoverTooltips={false}
      min={minX}
      max={maxX}
      viewportScrollLeft={dateConverter.monthIndexToOffset(this.props.startMonth)}
      manageOwnScrolling={false}
      showXAxis={false}
      showScrollbar={false}
      onScroll={this.handleCashFlowGraphScroll}
      xScale={xScale}
      yValueType={this.props.yValueType}
      padding={{
        top: '0%',
        left: this.props.yAxisWidth,
        bottom: '0%',
        right: '0%'
      }}
    />;
  }
});

