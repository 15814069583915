import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { PermissionLevel } from '../models/permissions.js';
import { projectStatusOptions } from '../models/project.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { StaffMemberSelector, Checkbox, BasicMySelect2, ProjectPhaseSelector, ErrorPanel } from '../widgets.js';
import { Modal, ModalContent } from '../modal.js';
import { getProjectOptions } from '../widgets/ProjectSelector.js';
import { getProjectPhases } from '../widgets/ProjectPhaseSelector.js';
import { rootStore } from '../RootStore.js';
import { timesheetReportStore } from './TimesheetReportStore.js';
import { organisationStore } from '../organisation.js';
import { TriStateSaveButton } from '../widgets/TriStateSaveButton.js';
import { TimesheetEntryStore, NONE } from './TimesheetEntryStore.js';
import { camelCaseToSentence } from '../utils.js';
import { userStore } from '../user.js';
import PropTypes from "prop-types";



var Row = CreateReactClass({
  propTypes: {
    isDisabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isDisabled: false
    };
  },

  render: function() {
    return <div>
      <div
          className="flexbox-container flex-align-items-center"
          style={{
            padding: '0.25em 0.5em',
            opacity: this.props.disabled ? 0.8 : null,
            height: '3.2em'
          }}>
        <div style={{display: 'inline-block', width: '10em'}}>
          {this.props.label}
        </div>
        <div style={{display: 'inline-block'}}>
          {this.props.content}
        </div>
      </div>
    </div>;
  }
});


export var ProjectRow = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.array,
  },

  render: function() {
    return <Row
      label="Project"
      content={
        <BasicMySelect2
          className="project-selector"
          options={_.sortBy(this.props.options
            || getProjectOptions(PermissionLevel.projectManager).map(function(project) {
              return {
                label: project.getTitle(),
                value: project
              };
            }), o => projectStatusOptions.indexOf(o.value ? o.value.status : "active").toString() + (o.value != null ? o.value.getTitle() || "No title" : "No title").toString())}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder="(Please select)"
          groupBy={o => {
            // Batch timesheet editing sometimes has a null value which borks up the grouping
            return o.value != null ? camelCaseToSentence((o.value.status + 'Projects')) : 'Active Projects';
          }}
          filter={function(option, searchText) {
            return option.label.toUpperCase().match(searchText.toUpperCase());
          }}
        />
      }
    />;
  }
});


export var StaffMemberRow = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func,
  },

  render: function() {
    return <Row
      label="Staff member"
      content={
        <StaffMemberSelector
          value={this.props.value}
          onChange={this.props.onChange}
          allowNull={false}
          nullText="(Please select)"
        />
      }
    />;
  }
});



export var ProjectPhaseRow = CreateReactClass({
  propTypes: {
    project: PropTypes.object,
    value: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
  },

  render: function() {
    const options = _.sortBy(this.props.options || (
      this.props.project != null ?
        getProjectPhases({
          project: this.props.project,
          selectedPhase: this.props.value,
          organisationStore: organisationStore
        })
      : []
    ), o => projectStatusOptions.indexOf(o.value ? o.value.status : "active").toString() + (o.value != null ? o.value.startDate || Infinity : Infinity).toString());

    return <Row
      disabled={options.length < 1}
      label="Project phase"
      content={
        <BasicMySelect2
          className="project-phase-selector"
          options={options}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder="(Please select)"
          groupBy={o => {
            // Batch timesheet editing sometimes has a null value which borks up the grouping
            return o.value != null ? camelCaseToSentence((o.value.status + 'Phases')) : 'Active Phases';
          }}
          filter={function(option, searchText) {
            return option.label.toUpperCase().match(searchText.toUpperCase());
          }}
        />
      }
    />;
  }
});



export var EditEntryModal = CreateReactClass({
  propTypes: {
    modal: PropTypes.object.isRequired,

    // Either these two
    timesheetEntryPath: PropTypes.object,
    row: PropTypes.object,

    // or this (if this exists it's assumed to be nonempty)
    entries: PropTypes.array,

    // or these two.
    project: PropTypes.object,
    projectPhase: PropTypes.object,

    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      return timesheetReportStore.batchEdit;
    })
  ],

  getInitialState: function() {
    let args = {
      projects: getProjectOptions(PermissionLevel.timesheet),
      organisationStore: organisationStore
    };

    if (this.props.row != null) { // Edit entry
      args.row = this.props.row;
    }
    else if (this.props.entries != null) { // Batch edit
      args.entries = this.props.entries;
    }
    else {
      args.projectPhase = this.props.projectPhase;
    }

    this.store = new TimesheetEntryStore(args);
    return this.store.getState();
  },

  render: function() {
    let headerText, buttonText;
    let settings = organisationStore.organisation.settings;
    if (this.store.isNewEntry) {
      headerText = "New Timesheet Entry";
      buttonText = "Add entry";
    }
    else if (this.store.isNewEntry) {
      headerText = "Edit Timesheet Entry";
      buttonText = "Save entry";
    }
    else {
      headerText = "Batch Edit";
      buttonText = `Save ${this.props.entries.length} entries`;
    }
    return (
		<Modal>
			<ModalContent
				header={headerText}
				className="timesheet-edit-entry-modal"
				width="45em"
				includeFooter={true}
				onClose={this.handleCancelClick}
				acceptButton={
					<TriStateSaveButton
						isSuccessButton={true}
						text={buttonText}
						disabled={
							!(
								(this.store.isNewEntry ||
									this.store.isNewEntry) &&
								this.state.project &&
								(settings.allowNoPhase ||
									(this.state.projectPhase &&
										this.state.projectPhase !== -1))
							)
						}
						state={this.state.isSaving ? "saving" : null}
						onClick={this.handleSubmitClick}
					/>
				}
			>
				<div style={{ padding: "1em" }}>
					<div style={{ padding: "0.5em" }}>
						<ProjectRow
							value={this.state.project}
							onChange={this.handleProjectChange}
							options={this.state.projectOptions}
						/>
						<ProjectPhaseRow
							project={this.state.project}
							value={this.state.projectPhase}
							options={this.state.phaseOptions}
							onChange={this.handleProjectPhaseChange}
						/>
						{settings.useTasks && (
							<Row
								disabled={this.state.taskOptions.length < 2}
								label="Task"
								content={
									<BasicMySelect2
										className="task-selector"
										options={this.state.taskOptions}
										value={this.state.task}
										onChange={this.handleTaskChange}
										placeholder="(Please select)"
										filter={function(option, searchText) {
											return option.label
												.toUpperCase()
												.match(
													searchText.toUpperCase()
												);
										}}
										disabled={
											this.state.taskOptions.length < 2
										}
									/>
								}
							/>
						)}
					</div>
					{settings.timeEntryFlags.length ? (
						<div>
							<div
								className="flexbox-container flex-space-around"
								style={{
									marginTop: "1em",
									paddingTop: "1em",
									borderTop: "solid 1px #d9d9d9"
								}}
							>
								{this.store.isBatchEdit ||
									(settings.timeEntryFlags.includes(
										"billable"
									) && (
										<Checkbox
											label="Billable"
											className="is-billable-checkbox"
											indeterminate={
												this.state.isBillable === null
											}
											value={this.state.isBillable}
											onChange={isBillable => {
												if (this.store.isBatchEdit) {
													const batchIsBillable = this
														.state.isBillable
														? false
														: this.state
																.isBillable ===
														  null
														? true
														: null;
													this.setIsBillable(
														batchIsBillable
													);
												} else {
													this.setIsBillable(
														isBillable
													);
												}
											}}
										/>
									))}
								{this.store.isBatchEdit ||
									(settings.timeEntryFlags.includes(
										"variation"
									) && (
										<Checkbox
											label="Variation"
											className="is-variation-checkbox"
											indeterminate={
												this.state.isVariation === null
											}
											value={this.state.isVariation}
											onChange={isVariation => {
												if (this.store.isBatchEdit) {
													const batchIsVariation = this
														.state.isVariation
														? false
														: this.state
																.isVariation ===
														  null
														? true
														: null;
													this.setIsVariation(
														batchIsVariation
													);
												} else {
													this.setIsVariation(
														isVariation
													);
												}
											}}
										/>
									))}
								{this.store.isBatchEdit ||
									(settings.timeEntryFlags.includes(
										"overtime"
									) && (
										<Checkbox
											label="Overtime"
											className="is-overtime-checkbox"
											indeterminate={
												this.state.isOvertime === null
											}
											value={this.state.isOvertime}
											onChange={isOvertime => {
												if (this.store.isBatchEdit) {
													const batchIsOvertime = this
														.state.isOvertime
														? false
														: this.state
																.isOvertime ===
														  null
														? true
														: null;
													this.setIsOvertime(
														batchIsOvertime
													);
												} else {
													this.setIsOvertime(
														isOvertime
													);
												}
											}}
										/>
									))}
								{this.store.isBatchEdit &&
								userStore.isAdmin() ? (
									<Checkbox
										label="Locked"
										className="is-locked-checkbox"
										indeterminate={
											this.state.isLocked === null
										}
										value={this.state.isLocked}
										onChange={isLocked => {
											if (this.store.isBatchEdit) {
												const batchIsLocked = this.state
													.isLocked
													? false
													: this.state.isLocked ===
													  null
													? true
													: null;
												this.setIsLocked(batchIsLocked);
											} else {
												this.setIsLocked(isLocked);
											}
										}}
									/>
								) : null}
								{this.store.isBatchEdit &&
								userStore.isAdmin() ? (
									<Checkbox
										label="Invoiced"
										className="been-invoiced-checkbox"
										indeterminate={
											this.state.beenInvoiced === null
										}
										value={this.state.beenInvoiced}
										onChange={beenInvoiced => {
											if (this.store.isBatchEdit) {
												const batchBeenInvoiced = this
													.state.beenInvoiced
													? false
													: this.state
															.beenInvoiced ===
													  null
													? true
													: null;
												this.setBeenInvoiced(
													batchBeenInvoiced
												);
											} else {
												this.setBeenInvoiced(
													beenInvoiced
												);
											}
										}}
									/>
								) : null}
							</div>
						</div>
					) : null}
				</div>

				{this.state.isError ? (
					<ErrorPanel message="There was a problem updating these timesheet entries." />
				) : null}
			</ModalContent>
		</Modal>
	);
  },

  handleProjectChange: function(project) {
    this.store.selectProject(project);
    this.setState(this.store.getState());
  },

  handleProjectPhaseChange: function(phase) {
    this.store.selectPhase(phase);
    this.setState(this.store.getState());
  },

  handleTaskChange: function(task) {
    this.store.selectTask(task);
    this.setState(this.store.getState());
  },

  setIsBillable: function(isBillable) {
    this.store.setIsBillable(isBillable);
    this.setState(this.store.getState());
  },

  setIsVariation: function(isVariation) {
    this.store.setIsVariation(isVariation);
    this.setState(this.store.getState());
  },

  setIsOvertime: function (isOvertime) {
    this.store.setIsOvertime(isOvertime);
    this.setState(this.store.getState());
  },

  setIsLocked: function (isLocked) {
    this.store.setIsLocked(isLocked);
    this.setState(this.store.getState());
  },

  setBeenInvoiced: function (beenInvoiced) {
    this.store.setBeenInvoiced(beenInvoiced);
    this.setState(this.store.getState());
  },

  handleSubmitClick: function() {
    function convert(val) {
      return val === NONE ? null : val;
    };

    let args;
    if (this.store.isBatchEdit) {
      args = [
        this.props.entries,
        this.state.project,
        this.state.projectPhase,
        this.state.task,
        this.state.isBillable,
        this.state.isVariation,
        this.state.isOvertime,
        this.state.isLocked,
        this.state.beenInvoiced,
      ];
    }
    else {
      args = [
        this.props.row,
        this.props.timesheetEntryPath,
        convert(this.state.project),
        convert(this.state.projectPhase),
        convert(this.state.task),
        convert(this.state.isBillable),
        convert(this.state.isVariation),
        convert(this.state.isOvertime),
        convert(this.state.isLocked),
        convert(this.state.beenInvoiced)
      ];
    }

    this.props.onSubmit(this.props.modal, ...args);
  },

  handleCancelClick: function() {
    this.props.onClose();
  }
});
