import _ from 'underscore';
import moment from 'moment';
import { dispatcher, StoreBase, registerActions, handleAction } from '../coincraftFlux.js';
import { retrieveTimesheetEntries } from './flux.js';
import { jsonHttp2 } from '../jsonHttp.js';
import { Invoice } from '../models/Invoice.js';
import { organisationStore } from '../organisation.js';
import { AjaxOperation } from '../AjaxOperation.js';
import { router } from '../router.js';
import { mixinAccountingSettingsMethods } from './mixinAccountingSettingsMethods.js';
import { ContactSelectorStore } from '../widgets/ContactSelectorStore.js';
import { ConnectionState } from './ConnectionState.js';
import * as modalTypes from '../modalTypes.js';
import { bulkUpdateEntries } from '../timesheets/flux'
import { Report } from '../reports/Report.js';
import { dateConverter } from '../models/dateconverter.js';
import apiRequest from '../apiRequest.js';


export const invoiceErrorCodes = {
  loadInvoice: 'loadInvoice',
  accountingSystemAuthorise: 'accountingSystemAuthorise',
  retrieveTimesheetEntries: 'retrieveTimesheetEntries',
  saveTimesheetEntries: 'saveTimesheetEntries',
  saveInvoice: 'saveInvoice',
  deleteInvoice: 'deleteInvoice',
  syncInvoice: 'syncInvoice',
  getAccountingSystemData: 'getAccountingSystemData',
  saveAccountingSystemSettings: 'saveAccountingSystemSettings',
  disconnect: 'disconnect'
};


const actionDefinitions = [
  {action: 'setInvoiceField', args: ['field', 'value']},
  {action: 'setNumDaysTillDueDate', args: ['n']},
  {action: 'addNewLineItem', args: ['phaseId', 'lineItemType']},
  {action: 'submitAddTimesheetLineItem', args: ['phaseId', 'combineType', 'staffMemberIds', 'task', 'isBillable', 'isVariation']},
  {action: 'setLineItemField', args: ['lineItemUuid', 'field', 'description']},
  {action: 'deleteLineItem', args: ['phaseId', 'lineItemUuid']},
  { action: 'save', args: [] },
  { action: 'initSave', args: [] },
  {action: 'saveSuccess', args: ['data', 'then']},

  {action: 'updateLockTimesheetsOnSave', args: ['lockTimesheetsOnSave']},
  { action: 'updateMarkTimesheetsAsInvoicedOnSave', args: ['markTimesheetsAsInvoicedOnSave'] },
  { action: 'updateUnlockTimesheetsOnDelete', args: ['unlockTimesheetsOnDelete'] },
  { action: 'updateUnmarkTimesheetsAsInvoicedOnDelete', args: ['unmarkTimesheetsAsInvoicedOnDelete'] },

  {action: 'initiateSync', args: []},
  {action: 'syncInvoice', args: ['accountingSystemId']},
  {action: 'syncInvoiceSuccess', args: ['data']},
  {action: 'syncInvoiceError', args: ['error']},

  {action: 'initiateDeleteInvoice', args: []},
  {action: 'confirmDeleteInvoice', args: []},
  {action: 'deleteInvoiceSuccess', args: ['invoiceId']},

  {action: 'openExpenseSyncPopup', args: []},
  {action: 'submitExpenseSyncForm', args: ['expensesToAdd', 'expensesToRemove', 'modal']},

  {action: 'openEditInvoiceDatesPopup', args: []},
  {action: 'submitEditInvoiceDatesPopup', args: ['startDate', 'endDate']},

  {action: 'cancelModal', args: ['modalType']},

  // Accounting system auth stuff
  {action: 'beginAuthSuccess', args: []},
  {action: 'openAuthPopup', args: ['accountingSystemId']},
  {action: 'authSuccess', args: ['accountingSystemId', 'connectionData']},
  {action: 'getDataSuccess', args: ['accountingSystemId', 'data']},
  {action: 'disconnect', args: ['accountingSystemId']},
  {action: 'disconnectSuccess', args: ['accountingSystemId']},
  {action: 'saveAccountingSystemSettings', args: ['accountingSystemId', 'settings', 'generalSettings', 'then']},
  {action: 'saveAccountingSystemSettingsSuccess', args: ['accountingSystemId', 'settings', 'generalSettings', 'then']},

  {action: 'openMyobAccountRightAuthPopup', args: ['then']},
  {action: 'setMyobAccountRightAuth', args: ['modal', 'username', 'password']},

  {action: 'error', args: ['operation', 'errorCode']},
  {action: 'dismissError', args: ['operation']},
];
export const invoiceActions = registerActions("invoice-page", actionDefinitions, dispatcher);


class InvoiceStore extends StoreBase {
  constructor() {
    super();

    this.isReady = false;
    this.isDirty = false;
    this.lockTimesheetsOnSave = false;
    this.markTimesheetsAsInvoicedOnSave = true;
    this.unlockTimesheetsOnDelete = true;
    this.unmarkTimesheetsAsInvoicedOnDelete = true;
    this.isValidationFailed = false;
    this.invoiceErrors = {};
    this.startingTimesheets = [];

    this.saveInvoiceOperation = new AjaxOperation(this);

    // null, 'sync_started', 'sync_finished'
    this.syncState = null;

    // null or {invoiceId: <invoice sync state>:str}.
    this.invoiceSyncStatus = null;

    mixinAccountingSettingsMethods({
      store: this,
      actions: invoiceActions,
      onSave: (function(accountingSystemId, settings, generalSettings, then) {
        if (then === 'closePopup') {
          organisationStore.closeModalByType(modalTypes.accountingSystemSettings);
        }
        else if (then === 'copyInvoice') {
          organisationStore.closeModalByType(modalTypes.accountingSystemSettings);
          this._proceedToCopyInvoice(accountingSystemId);
        }

        if (this.invoice != null) {
          let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);
          this.invoice = this.invoice.setAccountingSettings(accountingSystemId, settings.settings, ac.getDefaultTaxRate());
        }
      }).bind(this)
    });

    this.stores = {
      'contact-selector': new ContactSelectorStore('invoice-page/contact-selector')
    };

    this.actionDefinitions = actionDefinitions;
  }

  handle(action) {
    if (action.path.startsWith('invoice-page/contact-selector')) {
      this.stores['contact-selector'].handle(action);
      if (action.type === 'contact/saveSuccess') {
        this.setInvoiceField('contactId', action.contactData != null ? action.contactData.id : null);
      }
    }
    else {
      handleAction(action, this);
    }
  }

  _getNewInvoiceData(projectId, startDate = null, endDate = null) {
    return apiRequest({
		path: `/organisation/${organisationStore.organisation.id}/invoices/project/${projectId}/new`,
		method: "post",
		data:
			startDate.format("YYYY-MM-DD") && endDate.format("YYYY-MM-DD")
				? {
						startDate: startDate.format("YYYY-MM-DD"),
						endDate: endDate.format("YYYY-MM-DD")
				  }
				: {}
	});
  }

  startNewInvoice(projectId, startDate, endDate) {
    let self = this;
    apiRequest({
		path: `/organisation/${organisationStore.organisation.id}/invoices/project/${projectId}/new`,
		method: "post",
		data:
			startDate.format("YYYY-MM-DD") && endDate.format("YYYY-MM-DD")
				? {
						startDate: startDate.format("YYYY-MM-DD"),
						endDate: endDate.format("YYYY-MM-DD")
				  }
				: {},
		success: data => self.loadInvoice(Invoice.fromJson(data.invoice)),
		error: data => invoiceActions.error(invoiceErrorCodes.loadInvoice)
	});
  }

  _loadInvoice(invoice) {
    /**
     * This is not a production method, it's used by QUnit tests to set up the
     * invoice store without making a server request to get timesheet entries.
     */
    this.invoice = invoice;
    this.isReady = false;
    this.isValidationFailed = false;
    this.isDirty = false;
    this.syncState = null;
    this.invoiceSyncStatus = null;
    this.modals = [];
    this.timesheetEntries = [];
    this.isReady = true;
  }

  loadInvoice(invoice) {
    let self = this;
    this.invoice = invoice;
    this.isReady = false;
    this.isValidationFailed = false;
    this.isDirty = false;
    this.syncState = null;
    this.invoiceSyncStatus = null;
    this.modals = [];

    const getTimesheets = (inv) => retrieveTimesheetEntries(inv).then(function(entries) {
      self.timesheetEntries = entries;
      self.startingTimesheets = self.getNormalLineItemTimesheets() || [];
      const startingTimesheetEntryIds = self.startingTimesheets.map(te => te.id) || [];
      self.timesheetEntries = self.timesheetEntries.filter(
        t => (!t.beenInvoiced || startingTimesheetEntryIds.includes(t.id))
      );
      self.isReady = true;
      self.emitChanged();
    }, function() {
      invoiceActions.error(invoiceErrorCodes.retrieveTimesheetEntries);
    });
    if (invoice?.id) {
      apiRequest({ url: `/api/v1/org/invoice/${invoice.id}` }).then(data => {
        const inv = Invoice.fromJson(data.invoice);
        organisationStore.setObjectById("Invoice", inv, {
          emitChanged: false
        });
        this.invoice = inv;
        getTimesheets(inv);
      });
    } else {
      getTimesheets(invoice);
    }
    
  }

  setInvoiceField(field, value) {
    if (!_.include(['description', 'contactId', 'issuedOn', 'dueDate', 'taxRatePercent'], field)) {
      throw new Error("Not implemented");
    }
    if (field === 'taxRatePercent') {
      this.invoice = this.invoice.setTaxRatePercent(value);
    }
    else if (field === 'issuedOn') {
      this.invoice = this.invoice.setIssuedOn(value);
    }
    else {
      this.invoice = this.invoice.set(field, value);
    }
    this._setDirty();
    this.emitChanged();
  }

  setNumDaysTillDueDate(n) {
    this.invoice = this.invoice.set('dueDate', moment().add(n, 'days'));
    this._setDirty();
    this.emitChanged();
  }

  addNewLineItem(phaseId, lineItemType) {
    if (_.include(['progress', 'fixed', 'expense', 'note'], lineItemType)) {
      this.invoice = this.invoice.addNewLineItem(phaseId, lineItemType);
    }
    else if (lineItemType === 'timesheets') {
      this.modals.push({type: 'timesheetLineItem', phaseId: phaseId});
    }
    else {
      throw new Error("Not implemented");
    }
    this.emitChanged();
  }

  submitAddTimesheetLineItem(phaseId, combineType, staffMemberIds, task, isBillable, isVariation) {
    this.invoice = this.invoice.addNewTimesheetItem({
      combineType: combineType,
      phaseId: phaseId,
      task: task,
      isBillable: isBillable,
      isVariation: isVariation,
      staffMemberIds: staffMemberIds,
      timesheetEntries: this.timesheetEntries
    });
    this.modals = this.modals.filter(m => m.type !== 'timesheetLineItem');
    this._setDirty();
    this.emitChanged();
  }

  setLineItemField(lineItemUuid, field, value) {
    this.invoice = this.invoice.setLineItemField(lineItemUuid, field, value);
    this._setDirty();
    this.emitChanged();
  }

  deleteLineItem(phaseId, lineItemUuid) {
    this.invoice = this.invoice.deleteLineItem(phaseId, lineItemUuid);
    this._setDirty();
    this.emitChanged();
  }

  _setDirty() {
    this.isDirty = true;
    this.isValidationFailed = false;
  }

  updateLockTimesheetsOnSave(lockTimesheetsOnSave){
    this.lockTimesheetsOnSave = lockTimesheetsOnSave;
    this.emitChanged();
  }
  updateMarkTimesheetsAsInvoicedOnSave(markTimesheetsAsInvoicedOnSave){
    this.markTimesheetsAsInvoicedOnSave = markTimesheetsAsInvoicedOnSave;
    this.emitChanged();
  }

  updateUnlockTimesheetsOnDelete(unlockTimesheetsOnDelete) {
    this.unlockTimesheetsOnDelete = unlockTimesheetsOnDelete;
    this.emitChanged();
  }
  updateUnmarkTimesheetsAsInvoicedOnDelete(unmarkTimesheetsAsInvoicedOnDelete) {
    this.unmarkTimesheetsAsInvoicedOnDelete = unmarkTimesheetsAsInvoicedOnDelete;
    this.emitChanged();
  }

  initSave() {
    this.modals.push({ type: 'updateTimesheets' });
    this.emitChanged();
  }

  save(then = null) {
    let self = this;

    const {isValid, errors} = this.invoice.validate();
    if (isValid) {
      this.isValidationFailed = false;
      this.invoiceErrors = {};
      this.emitChanged();

      let request = apiRequest({
				url: `/organisation/${
					organisationStore.organisation.id
				}/invoices/${self.invoice.id || ""}`,
				method: "post",
				data: { invoice: self.invoice.serialize() },
				success: data => invoiceActions.saveSuccess(data, then),
				error: data =>
					invoiceActions.error(invoiceErrorCodes.saveInvoice)
			});

      this.saveInvoiceOperation.execute(request);
    }
    else {
      this.isValidationFailed = true;
      this.invoiceErrors = errors;
      this.cancelModal('updateTimesheets');
      this.emitChanged();
    }
  }

  getNonLineItemTimesheets() {
    return this.timesheetEntries.filter(te => !this.invoice.getAddedTimesheets().includes(te.id))
  }

  getNormalTimesheets() {
    return this.timesheetEntries.filter(te => {
      return te.isBillable && !te.isVariation && (te.projectPhase && this.invoice.getPhase(te.projectPhase.id) ? this.invoice.getPhase(te.projectPhase.id).phaseTotalIncTax > 0 : true)
    })
  }

  getLineItemTimesheets() {
    return this.timesheetEntries.filter(te => this.invoice.getAddedTimesheets().includes(te.id))
  }

  getNormalLineItemTimesheets() {
    return this.timesheetEntries.filter(te => {
      return (
        (
          te.isBillable 
          && !te.isVariation 
          && (te.projectPhase && this.invoice.getPhase(te.projectPhase.id) ? this.invoice.getPhase(te.projectPhase.id).phaseTotalIncTax > 0 : true)
        ) 
        || this.invoice.getAddedTimesheets().includes(te.id)
      )
    })
  }

  getRemovedTimesheets() {
    const normalLineItemTimesheetIds = this.getNormalLineItemTimesheets().map(te => te.id)
    return this.startingTimesheets.filter(te => !normalLineItemTimesheetIds.includes(te.id))
  }

  allTimesheetsLocked() {
    return _.every(this.getNormalLineItemTimesheets(), te => te.isLocked)
  }

  allTimesheetsMarkedAsInvoiced() {
    return _.every(this.getNormalLineItemTimesheets(), te => te.beenInvoiced)
  }

  allTimesheetsUnlocked() {
    return _.every(this.getNormalLineItemTimesheets(), te => !te.isLocked)
  }

  allTimesheetsUnmarkedAsInvoiced() {
    return _.every(this.getNormalLineItemTimesheets(), te => !te.beenInvoiced)
  }

  unlockRemovedTimesheets(removedTimesheets) {
    let self = this;
    if (removedTimesheets.length) {
      const projectInvoicesInRange = organisationStore.objects.Invoice.list.filter(inv => {
        return (
          inv.projectId === this.invoice.projectId
          && inv.id !== this.invoice.id
          && dateConverter.momentToInt(inv.startDate) <= dateConverter.momentToInt(this.invoice.endDate)
          && dateConverter.momentToInt(inv.endDate) >= dateConverter.momentToInt(this.invoice.startDate)
        )
      })
      const timesheetsNotInOtherInvoices = removedTimesheets.filter(te => {
        if (
          (!te.isBillable || te.isVariation) 
          && _.some(projectInvoicesInRange, inv => inv.getAddedTimesheets().includes(te.id))
        ) return false;
        if (
          te.isBillable 
          && !te.isVariation 
          && _.some(projectInvoicesInRange, inv => (
            (te.projectPhase && inv.getPhase(te.projectPhase.id) ? inv.getPhase(te.projectPhase.id).phaseTotalIncTax > 0 : true)
            && dateConverter.momentToInt(te.date) >= dateConverter.momentToInt(inv.startDate)
            && dateConverter.momentToInt(te.date) <= dateConverter.momentToInt(inv.endDate)
          ))
        ) return false;
        return true;
      })
      bulkUpdateEntries({
        report: new Report(Report.transformArgs({
          dateRange: {
            id: 'custom',
            start: this.invoice.startDate.format("YYYY-MM-DD"),
            end: this.invoice.endDate.format("YYYY-MM-DD")
          },
          filters: [
            {
              columnId: 'project',
              matcher: {
                type: 'project',
                value: [this.invoice.project.id]
              }
            }
          ]
        })),
        timesheetEntries: timesheetsNotInOtherInvoices,
        isLocked: false,
        beenInvoiced: false,
      }).then(function () {
        retrieveTimesheetEntries(self.invoice).then(function (entries) {
          self.timesheetEntries = entries;
          self.emitChanged();
        }, function () {
          invoiceActions.error(invoiceErrorCodes.retrieveTimesheetEntries);
        });
      }, function (err) {
        invoiceActions.error(invoiceErrorCodes.saveTimesheetEntries);
      });
    }
  }

  saveSuccess(data, then) {
    const self = this;
    const inv = Invoice.fromJson(data.objects.Invoice[0]);
    organisationStore.setObjectById('Invoice', inv);
    this.invoice = this.invoice.set('id', inv.id);
    this.invoice.project.mergeChangeLog();
    this.isDirty = false;
    const lockTimesheets = this.lockTimesheetsOnSave && !this.allTimesheetsLocked();
    const markTimesheetsAsInvoiced = this.markTimesheetsAsInvoicedOnSave && !this.allTimesheetsMarkedAsInvoiced();

      this.unlockRemovedTimesheets(this.getRemovedTimesheets())

    if (lockTimesheets || markTimesheetsAsInvoiced) {
      bulkUpdateEntries({
        report: new Report(Report.transformArgs({
          dateRange: {
            id: 'custom',
            start: this.invoice.startDate.format("YYYY-MM-DD"),
            end: this.invoice.endDate.format("YYYY-MM-DD")
          },
          filters: [
            {
              columnId: 'project',
              matcher: {
                type: 'project',
                value: [this.invoice.project.id]
              }
            }
          ]
        })),
        timesheetEntries: this.getNormalLineItemTimesheets(),
        isLocked: lockTimesheets || null,
        beenInvoiced: markTimesheetsAsInvoiced || null,
      }).then(function () {
        retrieveTimesheetEntries(self.invoice).then(function (entries) {
          self.timesheetEntries = entries;
          self.emitChanged();
        }, function () {
          invoiceActions.error(invoiceErrorCodes.retrieveTimesheetEntries);
        });
      }, function (err) {
        invoiceActions.error(invoiceErrorCodes.saveTimesheetEntries);
      });
    }
    

    if (then === 'copyInvoice') {
      const ac = organisationStore.getCurrentAccountingSystem();
      if (ac != null && ac.identifier !== 'none' && ac.canSaveSettings(ac.settings)) {
        this._checkAuth(ac.identifier);
        this._proceedToCopyInvoice(ac.identifier);
      }
      else {
        organisationStore.openAccountingSystemSettingsPopup({then: 'copyInvoice'});
      }
    }
    this.cancelModal('updateTimesheets');
    this.emitChanged();
  }

  initiateDeleteInvoice() {
    this.modals.push({type: 'confirmDeleteInvoice'});
    this.emitChanged();
  }

  confirmDeleteInvoice() {
    let self = this;
    apiRequest({
      url: `/organisation/${organisationStore.organisation.id}/invoices/${this.invoice.id}`,
      method: "delete",
      success: data => invoiceActions.deleteInvoiceSuccess(self.invoice.id),
      error: data => invoiceActions.error(invoiceErrorCodes.deleteInvoice)
    });
  }

  deleteInvoiceSuccess(invoiceId) {
    const self = this;
    organisationStore._deleteObject('Invoice', invoiceId);
    this.invoice.project.changeLog = this.invoice.project.changeLog.filter(function(cli) {
      return cli.invoice == null || cli.invoice.id !== invoiceId;
    });
    const unlockTimesheets = this.unlockTimesheetsOnDelete && !this.allTimesheetsUnlocked()
    const unmarkTimesheetsAsInvoiced = this.unmarkTimesheetsAsInvoicedOnDelete && !this.allTimesheetsUnmarkedAsInvoiced()

    if (unlockTimesheets || unmarkTimesheetsAsInvoiced) {
      bulkUpdateEntries({
        report: new Report(Report.transformArgs({
          dateRange: {
            id: 'custom',
            start: this.invoice.startDate.format("YYYY-MM-DD"),
            end: this.invoice.endDate.format("YYYY-MM-DD")
          },
          filters: [
            {
              columnId: 'project',
              matcher: {
                type: 'project',
                value: [this.invoice.project.id]
              }
            }
          ]
        })),
        timesheetEntries: this.getNormalLineItemTimesheets(),
        isLocked: unlockTimesheets ? false : null,
        beenInvoiced: unmarkTimesheetsAsInvoiced ? false : null,
      }).then(function () {
        retrieveTimesheetEntries(self.invoice).then(function (entries) {
          self.timesheetEntries = entries;
          self.emitChanged();
        }, function () {
          invoiceActions.error(invoiceErrorCodes.retrieveTimesheetEntries);
        });
      }, function (err) {
        invoiceActions.error(invoiceErrorCodes.saveTimesheetEntries);
      });
    }
    router.history.replace({pathname: "/dashboard/invoice-deleted"});
  }

  initiateSync() {
    this.save('copyInvoice');
  }

  syncInvoice(accountingSystemId) {
    let accounting = require('./AccountingSystemStore.js');

    this.syncState = 'sync_started';
    this.emitChanged();

    accounting.syncInvoice(accountingSystemId, this.invoice).then(function(data) {
      if (data.status === "ok") {
        invoiceActions.syncInvoiceSuccess(data);
      } else {
        invoiceActions.syncInvoiceError(data.error);
      }
    }, function(error) {
      invoiceActions.syncInvoiceError(error);  
    });
  }

  syncInvoiceSuccess({invoiceStatus}) {
    let self = this;
    this.syncState = 'sync_finished';
    this.invoiceSyncStatus = invoiceStatus;

    _.each(invoiceStatus, function(status, invoiceId) {
      invoiceId = parseInt(invoiceId);
      if (status.data != null) {
        let invoice = organisationStore.getInvoiceById(invoiceId);
        if (invoice != null) {
          organisationStore.setObjectById('Invoice', invoice.merge(status.data));
        }
        if (invoiceId === self.invoice.id) {
          self.invoice = self.invoice.merge(status.data);
        }
      }
    });

    this.emitChanged();
  }

  syncInvoiceError(error) {
    this.syncState = null;
    this.error(invoiceErrorCodes.syncInvoice, {error: error});
  }

  openEditInvoiceDatesPopup() {
    this.modals.push({type: 'editInvoiceDates'});
    this.emitChanged();
  }

  openExpenseSyncPopup() {
    this.modals.push({type: 'expenseSync'});
    this.emitChanged();
  }

  submitExpenseSyncForm(expensesToAdd, expensesToRemove, modal) {
    this.invoice = this.invoice.syncExpenses(expensesToAdd, expensesToRemove);
    this.modals = this.modals.filter(m => m.type !== 'expenseSync');
    this.emitChanged();
  }

  _checkAuth(accountingSystemId) {
    if (accountingSystemId != null && accountingSystemId !== 'none') {
      let self = this;
      let accounting = require('./AccountingSystemStore.js');
      let connection = organisationStore.getAccountingSystemByIdentifier(accountingSystemId).connection;
      if (
			connection.state !== ConnectionState.connected ||
			connection.expiresAt.unix() < moment().unix()
		) {
			connection.state = ConnectionState.checking;
			connection.url = "loading";
			self.emitChanged();
			accounting.beginAuth(accountingSystemId).then(
				function(data) {
					connection.state = ConnectionState.notConnected;
					connection.url = data.url;
					invoiceActions.beginAuthSuccess();
				},
				function() {
					connection.state = ConnectionState.error;
					connection.url = null;
					self.emitChanged();
				}
			);
		}
    }
  }

  beginAuthSuccess() {
    this.emitChanged();
  }

  openAuthPopup(accountingSystemId) {
    let self = this;
    let accounting = require('./AccountingSystemStore.js');
    let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);

    if (accountingSystemId === 'myob-accountright'
        && (ac.settings == null || (ac.settings.localUsername == null || ac.settings.localPassword == null))) {
      this.openMyobAccountRightAuthPopup({then: 'connect'});
      this.emitChanged();
    }
    else {
      let connection = organisationStore.getAccountingSystemByIdentifier(accountingSystemId).connection;
      connection.state = ConnectionState.connecting;
      this.emitChanged();

      accounting.openAuthPopup(accountingSystemId).then(function(connectionData) {
        invoiceActions.authSuccess(accountingSystemId, connectionData);
        self.emitChanged();
      }, function() {
        invoiceActions.error(invoiceErrorCodes.accountingSystemAuthorise);
        self.emitChanged();
      });
    }
  }

  openMyobAccountRightAuthPopup({then = null} = {}) {
    let ac = organisationStore.getAccountingSystemByIdentifier('myob-accountright');

    organisationStore.openModal({
      type: 'myobAccountRightAuthForm',
      then: then,
      initialData: (ac.settings != null) ?
        {
          username: ac.settings.localUsername || '',
          password: ac.settings.localPassword || ''
        }
      : null
    });
  }

  setMyobAccountRightAuth(modal, username, password) {
    let self = this;

    this.cancelModal(modal.type);

    let accounting = require('./AccountingSystemStore.js');
    let accountRight = organisationStore.getAccountingSystemByIdentifier('myob-accountright');
    if (accountRight.settings == null) {
      accountRight.settings = accountRight.getDefaultSettings();
    }
    accountRight.settings.localUsername = username;
    accountRight.settings.localPassword = password;
    accounting.saveSettings('myob-accountright', accountRight.settings, {}).then(function({settings, generalSettings}) {
      if (modal.then === 'connect') {
        self.openAuthPopup('myob-accountright');
      } else if (accountRight.data == null) {
        accounting.getAccountingSystemData(accountRight).then(function(data) {
          invoiceActions.getDataSuccess('myob-accountright', data);
        }, function(error) {
          invoiceActions.error(invoiceErrorCodes.getAccountingSystemData, {errorCode: error});
          accountRight.data = null;
          organisationStore._updateInvoiceSettingsState(accountRight);
        });
      }
    });
  }

  authSuccess(accountingSystemId, connectionData) {
    let self = this;
    let accounting = require('./AccountingSystemStore.js');
    let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);
    ac.connection.setCredentials(connectionData);
    if (ac.settings == null) {
      ac.settings = ac.getDefaultSettings();
    }

    if (ac.data == null) {
      this.emitChanged();
      accounting.getAccountingSystemData(ac).then(function(data) {
        invoiceActions.getDataSuccess(accountingSystemId, data);
        self.emitChanged();
      }, function(error) {
        invoiceActions.error(invoiceErrorCodes.getAccountingSystemData, {errorCode: error});
        ac.data = null;
        organisationStore._updateInvoiceSettingsState(ac);
        self.emitChanged();
      });
    };
    organisationStore._updateInvoiceSettingsState(ac);
    this.emitChanged();
  }

  getDataSuccess(accountingSystemId, data) {
    let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);
    ac.data = data;
    organisationStore._updateInvoiceSettingsState(ac);
    this.emitChanged();
    organisationStore.emitChanged(); // TODO-better-invoices lel
  }

  disconnect(accountingSystemId) {
    let self = this;
    let accounting = require('./AccountingSystemStore.js');

    let connection = organisationStore.getAccountingSystemByIdentifier(accountingSystemId).connection;
    connection.state = ConnectionState.disconnecting;
    this.emitChanged();

    accounting.disconnect(accountingSystemId).then(
		function() {
			invoiceActions.disconnectSuccess(accountingSystemId);
		},
		function() {
			connection.state = ConnectionState.connected;
			self.emitChanged();

			invoiceActions.error(invoiceErrorCodes.disconnect);
		}
	);
  }

  disconnectSuccess(accountingSystemId) {
    let connection = organisationStore.getAccountingSystemByIdentifier(accountingSystemId).connection;
    connection.url = null;
    this._checkAuth(accountingSystemId);
  }

  _proceedToCopyInvoice(accountingSystemId) {
    let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);

    this.syncState = null;
    this.invoiceSyncStatus = null;

    let errors = {};
    if (ac.requiresContact() && this.invoice.contactId == null) {
      errors.contactMissing = true;
    }
    if (ac.invoiceNumberMaxLength != null && this.invoice.description.length > ac.invoiceNumberMaxLength) {
      errors.invoiceNumberMaxLength = ac.invoiceNumberMaxLength;
    }
    if (this.invoice.contact != null
        && ac.requiresLinkedContact
        && this.invoice.contact.accountingSystemId !== accountingSystemId) {
      errors.wrongContactAccountingSystem = true;
    }

    if (_.isEmpty(errors)) {
      this.modals.push({type: 'copyInvoice'});
    }
    else {
      this.isValidationFailed = true;
      this.invoiceErrors = {...this.invoiceErrors, ...errors};
    }
  }

  submitEditInvoiceDatesPopup(startDate, endDate) {
    let self = this;
    this.invoice = this.invoice.setDates(startDate, endDate);
    this.cancelModal('editInvoiceDates');

    retrieveTimesheetEntries(this.invoice).then(function(entries) {
      self.timesheetEntries = entries;
    }, function() {
      invoiceActions.error(invoiceErrorCodes.retrieveTimesheetEntries);
    });
  }

  cancelModal(modalType) {
    if (modalTypes[modalType] != null) {
      organisationStore.closeModalByType(modalType);
    }
    else {
      this.modals = this.modals.filter(m => m.type !== modalType);
      this.emitChanged();
    }
  }

  error(operation, {errorCode = null, error = null} = {}) {
    if (errorCode === invoiceErrorCodes.syncInvoice) {
      this.syncState = null;
    }
    this.modals.push({type: 'error', operation: operation, errorCode: errorCode, error: error});
    this.emitChanged();
  }

  dismissError(operation) {
    this.modals = this.modals.filter(m => !(m.type === 'error' && m.operation === operation));
    this.emitChanged();
  }
}


export let invoicePageStore = new InvoiceStore();
