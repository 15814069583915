import _ from "underscore";
import { sum, generateUUID, formatCurrency, compareMultiple } from "../../../utils.js";
import { organisationStore } from '../../../organisation.js';
import { dateConverter } from "../../../models/dateconverter.js";
import moment from "moment";

const displayTypes = {
    0: "sub-total",
    1: "sub-total",
    2: "",
    3: "child",
    4: "childchild"
}

const expandable = {
    0: false,
    1: false,
    2: true,
    3: true,
    4: true,
}

const hideable = {
    0: false,
    1: false,
    2: true,
    3: true,
    4: true,
}

const defaultExpanded = {
    0: true,
    1: true,
    2: false,
    3: false,
    4: false,
}

const currrentDateInt = dateConverter.momentToInt(moment())

export const TimesheetRow = class {
    constructor(spreadsheetStore, rowData) {
        this.uuid = rowData.uuid;
        this.timesheetItems = [];
        this.parentId = rowData.parentId;
        this.childrenIds = new Set(rowData.childrenIds);
        this.visible = true;
        this.level = rowData.level;
        this.title = rowData.title || "";
        this.spreadsheetStore = spreadsheetStore;
        this.groupType = rowData.groupType;
        this.rowType = rowData.rowType;
        this.editable = rowData.editable === false ? false : true; 
        this.project = rowData.project || organisationStore.getProjectById(rowData.projectId);
        this.staffMember = rowData.staff || organisationStore.getStaffMemberById(rowData.staffId);
        this.staffRole = rowData.role || organisationStore.getStaffRoleById(rowData.roleId);
        this.phase = rowData.phase || organisationStore.getProjectPhaseById(rowData.phaseId);

        // cached vals
        this._projectedHoursCache = rowData.projectedHours || {};
        this._actualHoursCache = rowData.actualHours || {};
        this._combinedHoursCache = rowData.combinedHours || {};
        this._projectedHoursToDateCache = rowData.projectedHoursToDate || {};
        this._actualHoursToDateCache = rowData.actualHoursToDate || {};
        this._combinedHoursToDateCache = rowData.combinedHoursToDate || {};
        this._projectedCostCache = rowData.projectedCost || {};
        this._actualCostCache = rowData.actualCost || {};
        this._combinedCostCache = rowData.combinedCost || {};
        this._projectedCostToDateCache = {};
        this._actualCostToDateCache = rowData.actualCostToDate || {};
        this._combinedCostToDateCache = {};
        this._projectedChargeOutCache = rowData.projectedChargeOut || {};
        this._actualChargeOutCache = rowData.actualChargeOut || {};
        this._combinedChargeOutCache = rowData.combinedChargeOut || {};
        this._projectedChargeOutToDateCache = {};
        this._actualChargeOutToDateCache = rowData.actualChargeOutToDate || {};
        this._combinedChargeOutToDateCache = {};
        this._actualMinMonthIndex = rowData.actualMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._actualMaxMonthIndex = rowData.actualMaxMonthIndex || spreadsheetStore.currentMonthIndex;
        this._projectedMinMonthIndex = rowData.projectedMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._projectedMaxMonthIndex = rowData.projectedMaxMonthIndex || spreadsheetStore.currentMonthIndex;
        this._combinedMinMonthIndex = rowData.combinedMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._combinedMaxMonthIndex = rowData.combinedMaxMonthIndex || spreadsheetStore.currentMonthIndex;
        this._budget = undefined;
        this._availableHoursInMonth = {};
        this._startMonthIndex = undefined;
        this._endMonthIndex = undefined;
    }

    get addAllocationButton() {
        return this.rowType === "phase"
    }

    get rowDisplayType() {
        return displayTypes[this.level]
    }

    get expandable() {
        return expandable[this.level]
    }

    get hideable() {
        return hideable[this.level]
    }

    get startMonthIndex() {
        if (this._startMonthIndex) return this._startMonthIndex
        this._startMonthIndex = this.phase && this.phase.id > 0 ? this.phase.startMonthIndex : this.project ? this.project.startMonthIndex : undefined;
        return this._startMonthIndex;
    }

    get endMonthIndex() {
        if (this._endMonthIndex) return this._endMonthIndex
        this._endMonthIndex = this.phase && this.phase.id > 0 ? this.phase.endMonthIndex : this.project ? this.project.endMonthIndex : undefined;
        return this._endMonthIndex;
    }

    get expanded() {
        return defaultExpanded[this.level] || this.spreadsheetStore.expandedStaff.includes(this.uuid)
    }

    get minMonthIndex() {
        return this._combinedMinMonthIndex;
    }

    get maxMonthIndex() {
        return this._combinedMaxMonthIndex;
    }

    get parent() {
        return this.spreadsheetStore.hoursRows.get(this.parentId) // rename in future
    }

    get children() {
        return [...this.childrenIds]
            .map(cId => this.spreadsheetStore.hoursRows.get(cId))
            .sort(compareMultiple(
                (a, b) => (a.rowType === "phase" && a.phase && a.phase.startDate ? a.phase.startDate : Infinity) - (b.rowType === "phase" && b.phase && b.phase.startDate ? b.phase.startDate : Infinity),
                (a, b) => a.title.localeCompare(b.title)
            ));
    }

    get visibleChildren() {
        // if you turn off entire row but van see children
        // you want to the totals to add up correctl and not be 0
        return this.children.filter(c => this.visible ? c.visible : true)
    }

    get editableChildren() {
        // if you turn off entire row but van see children
        // you want to the totals to add up correctl and not be 0
        return this.children.filter(c => (this.visible ? c.visible : true) && c.editable && (c.isLeaf ?
            (
                c.project 
                && c.phase 
                && (c.staffMember || c.staffRole)
            )
            : c.editableChildren.length > 0))
    }

    get ancestors() {
        if (!this.parent) return []
        let ancestors = this.parent.ancestors
        ancestors.push(this.parent)
        return ancestors
    }

    get selected() {
        return this.spreadsheetStore.selectedHoursRowId === this.uuid
    }

    get isEntireRole() {
        return this.groupType == "staff" && this.rowType == "role"
    }

    get isEntireStaffMember() {
        return this.groupType == "staff" && this.rowType == "staff"
    }

    get displayBudget() {
        let groups = this.ancestors.map(a => a.rowType)
        return (
            this.rowType === "project"
            || groups.includes("project")
        )
    }

    get isLeaf() {
        return this.childrenIds.size === 0
    }

    get isArchived() {
        return ((this.project ? this.project.status === "archived" : false)
            || (this.staffMember ? this.staffMember.isArchived : false))
            && (this.children.length > 0 ? _.every(this.children, child => child.isArchived) : true)
    }

    get isDisplayed() {
        return (!this.isArchived || this.hoursInDisplayedMonths) 
            && (this.children.length > 0 ? _.some(this.children, child => child.isDisplayed) : true)
    }

    get hoursInDisplayedMonths() {
        return sum(this.spreadsheetStore.dateColumns.map(dc => {
            return this.getDisplayedHoursMonthIndex(dc.monthIndex)
        }))
    }

    toggleVisibility() {
        this.visible = !this.visible
        this.children.forEach(child => child.visible = this.visible)
        _.range(this.minMonthIndex, this.maxMonthIndex + 1).forEach( mi => {
            let multiplyer = this.visible ? 1 : -1;
            this.parent.addActualHours(this.getActualHoursMonthIndex(mi)*multiplyer, mi)
            this.parent.addProjectedHours(this.getProjectedHoursMonthIndex(mi) * multiplyer, mi)
            this.parent.addCombinedHours(this.getCombinedHoursMonthIndex(mi) * multiplyer, mi)
        })
        this.parent.clearCalculatedVals()
    }

    addTimesheetItem(tsItem) {
        this.timesheetItems.push(tsItem)
        const actual = tsItem.type === "timesheet"
        const { hours, monthIndex } = tsItem
        this.addHours(hours, monthIndex, actual ? "actual" : "projected")
    }

    addHours(hours, monthIndex, type) {
        if (type === "actual") {
            this.addActualHours(hours, monthIndex)
        } else if (type === "projected") {
            this.addProjectedHours(hours, monthIndex)
        }
    }

    addActualHours(hours, monthIndex) {
        if (hours == undefined) return true
        const currentMonthIndex = this.spreadsheetStore.currentMonthIndex
        this._actualHoursCache[monthIndex] = this._actualHoursCache[monthIndex] || 0
        this._actualHoursCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._actualMinMonthIndex = Math.min(monthIndex, this._actualMinMonthIndex) || monthIndex;
        this._actualMaxMonthIndex = Math.max(monthIndex, this._actualMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._actualHoursToDateCache[mi] != undefined) {
                this._actualHoursToDateCache[mi] = this.getActualHoursToDateMonthIndex(mi)
                this._actualHoursToDateCache[mi] += hours
            } else {
                this._actualHoursToDateCache[mi] = this.getActualHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addActualHours(hours, monthIndex)
        if (this.childrenIds.size === 0) {
            if ((monthIndex < currentMonthIndex) || (
                (monthIndex == currentMonthIndex) &&
                ((this._actualHoursCache[monthIndex] || 0) >= (this._projectedHoursCache[monthIndex] || 0))
            )) {
                const changeInHours = (this._actualHoursCache[monthIndex] || 0) - (this._combinedHoursCache[monthIndex] || 0)
                this.addCombinedHours(changeInHours, monthIndex)
            }
        }
    }

    addProjectedHours(hours, monthIndex) {
        if (hours == undefined) return true
        const currentMonthIndex = this.spreadsheetStore.currentMonthIndex
        this._projectedHoursCache[monthIndex] = this._projectedHoursCache[monthIndex] || 0
        this._projectedHoursCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._projectedMinMonthIndex = Math.min(monthIndex, this._projectedMinMonthIndex) || monthIndex;
        this._projectedMaxMonthIndex = Math.max(monthIndex, this._projectedMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._projectedHoursToDateCache[mi] != undefined) {
                this._projectedHoursToDateCache[mi] = this.getProjectedHoursToDateMonthIndex(mi)
                this._projectedHoursToDateCache[mi] += hours
            } else {
                this._projectedHoursToDateCache[mi] = this.getProjectedHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addProjectedHours(hours, monthIndex)
        if (this.childrenIds.size === 0) {
            if ((monthIndex > currentMonthIndex) || (
                (monthIndex == currentMonthIndex)
            )) {
                if ((this._projectedHoursCache[monthIndex] || 0) >= (this._actualHoursCache[monthIndex] || 0)) {
                    const changeInHours =
						(this._projectedHoursCache[monthIndex] || 0) -
						(this._combinedHoursCache[monthIndex] || 0);
					this.addCombinedHours(changeInHours, monthIndex);
                } else {
                    const changeInHours =
						(this._actualHoursCache[monthIndex] || 0) -
						(this._combinedHoursCache[monthIndex] || 0);
					this.addCombinedHours(changeInHours, monthIndex);
                }
            }
        }
    }

    addCombinedHours(hours, monthIndex) {
        if (hours == undefined) return true
        this._combinedHoursCache[monthIndex] = this._combinedHoursCache[monthIndex] || 0
        this._combinedHoursCache[monthIndex] += hours

        this._minMonthIndex = Math.min(monthIndex, this._minMonthIndex) || monthIndex;
        this._maxMonthIndex = Math.max(monthIndex, this._maxMonthIndex) || monthIndex;
        this._combinedMinMonthIndex = Math.min(monthIndex, this._combinedMinMonthIndex) || monthIndex;
        this._combinedMaxMonthIndex = Math.max(monthIndex, this._combinedMaxMonthIndex) || monthIndex;

        _.range(monthIndex, this.maxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._combinedHoursToDateCache[mi] != undefined) {
                this._combinedHoursToDateCache[mi] = this.getCombinedHoursToDateMonthIndex(mi)
                this._combinedHoursToDateCache[mi] += hours
            } else {
                this._combinedHoursToDateCache[mi] = this.getCombinedHoursToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addCombinedHours(hours, monthIndex)
    }

    getTimesheetItemsMonthIndex(monthIndex) {
        this.timesheetItems.filter(tsi => tsi.monthIndex === monthIndex)
    }

    getProjectedHoursMonthIndex(monthIndex) {
        return this._projectedHoursCache[monthIndex] || 0
    }

    getActualHoursMonthIndex(monthIndex) {
        return this._actualHoursCache[monthIndex] || 0
    }

    getCombinedHoursMonthIndex(monthIndex) {
        return this._combinedHoursCache[monthIndex] || 0
    }

    getDisplayedHoursMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedHoursMonthIndex(monthIndex);
            case "actuals":
                return this.getActualHoursMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedHoursMonthIndex(monthIndex)
        }
    }

    getProjectedCostMonthIndex(monthIndex) {
        if (this._projectedCostCache[monthIndex]) return this._projectedCostCache[monthIndex]
        if (this.visibleChildren.length > 0) {
            this._projectedCostCache[monthIndex] = sum(this.visibleChildren.map(c => c.getProjectedCostMonthIndex(monthIndex)))
            return this._projectedCostCache[monthIndex]
        }
        if (!this.project) return 0
        this._projectedCostCache[monthIndex] = (
            this.getProjectedHoursMonthIndex(monthIndex) 
            * this.project.getRateInMonth(this.staffMember || this.staffRole, this.phase, "costRate", monthIndex)
        )
        return this._projectedCostCache[monthIndex]
    }

    getActualCostMonthIndex(monthIndex) {
        return this._actualCostCache[monthIndex] || 0
    }

    getCombinedCostMonthIndex(monthIndex) {
        const { currentMonthIndex } = this.spreadsheetStore
        if (monthIndex < currentMonthIndex) {
            return this.getActualCostMonthIndex(monthIndex)
        } else if (monthIndex > currentMonthIndex) {
            return this.getProjectedCostMonthIndex(monthIndex)
        } else if (monthIndex === currentMonthIndex) {
            return Math.max(this.getActualCostMonthIndex(monthIndex), this.getProjectedCostMonthIndex(monthIndex))
        }
    }

    getDisplayedCostMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedCostMonthIndex(monthIndex);
            case "actuals":
                return this.getActualCostMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedCostMonthIndex(monthIndex)
        }
    }

    getProjectedChargeOutMonthIndex(monthIndex) {
        if (this._projectedChargeOutCache[monthIndex]) return this._projectedChargeOutCache[monthIndex]
        if (this.visibleChildren.length > 0) {
            this._projectedChargeOutCache[monthIndex] = sum(this.visibleChildren.map(c => c.getProjectedChargeOutMonthIndex(monthIndex)))
            return this._projectedChargeOutCache[monthIndex]
        }
        if (!this.project) return 0
        this._projectedChargeOutCache[monthIndex] = (
            this.getProjectedHoursMonthIndex(monthIndex)
            * this.project.getRateInMonth(this.staffMember || this.staffRole, this.phase, "chargeOutRate", monthIndex)
        )
        return this._projectedChargeOutCache[monthIndex]
    }

    getActualChargeOutMonthIndex(monthIndex) {
        return this._actualChargeOutCache[monthIndex] || 0
    }

    getCombinedChargeOutMonthIndex(monthIndex) {
        const { currentMonthIndex } = this.spreadsheetStore
        if (monthIndex < currentMonthIndex) {
            return this.getActualChargeOutMonthIndex(monthIndex)
        } else if (monthIndex > currentMonthIndex) {
            return this.getProjectedChargeOutMonthIndex(monthIndex)
        } else if (monthIndex === currentMonthIndex) {
            return Math.max(this.getActualChargeOutMonthIndex(monthIndex), this.getProjectedChargeOutMonthIndex(monthIndex))
        }
    }

    getDisplayedChargeOutMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedChargeOutMonthIndex(monthIndex);
            case "actuals":
                return this.getActualChargeOutMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedChargeOutMonthIndex(monthIndex)
        }
    }

    getTotalAvailableHoursInMonth(monthIndex) {
        if (this._availableHoursInMonth[monthIndex]) return this._availableHoursInMonth[monthIndex]
        let groups = this.ancestors.map(a => a.rowType)
        groups.push(this.rowType)
        const staffGroup = groups.includes("staff")
        const roleGroup = groups.includes("role")
        if (staffGroup) {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getStaffAvailabilityInMonth(this.staffMember || this.staffRole, monthIndex);
        } else if (roleGroup) {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getRoleAvailabilityInMonth(this.staffRole, monthIndex);
        } else {
            this._availableHoursInMonth[monthIndex] = this.spreadsheetStore.getAllStaffAvailabilityInMonth(monthIndex);
        }
        return this._availableHoursInMonth[monthIndex]
    }

    getProjectedPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getProjectedHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getActualPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getActualHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getDisplayedPercentUtilisationMonthIndex(monthIndex) {
        const hours = this.getDisplayedHoursMonthIndex(monthIndex)
        if (!hours) return 0
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        if (!availabileHours) return 0
        return (hours / availabileHours) * 100
    }

    getProjectedHoursToDateMonthIndex(monthIndex) {
        if (!this._projectedMinMonthIndex || monthIndex < this._projectedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._projectedMaxMonthIndex) {
            return this.getProjectedHoursToDateMonthIndex(this._projectedMaxMonthIndex)
        } else {
            return this._projectedHoursToDateCache[monthIndex] || this.getProjectedHoursToDateMonthIndex(monthIndex-1)
        }
    }

    getActualHoursToDateMonthIndex(monthIndex) {
        if (!this._actualMinMonthIndex || monthIndex < this._actualMinMonthIndex) {
            return 0
        } else if(monthIndex > this._actualMaxMonthIndex) {
            return this.getActualHoursToDateMonthIndex(this._actualMaxMonthIndex)
        } else {
            return this._actualHoursToDateCache[monthIndex] || this.getActualHoursToDateMonthIndex(monthIndex-1)
        }
        
    }

    getCombinedHoursToDateMonthIndex(monthIndex) {
        if (!this._combinedMinMonthIndex || monthIndex < this._combinedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._combinedMaxMonthIndex) {
            return this.getCombinedHoursToDateMonthIndex(this._combinedMaxMonthIndex)
        } else {
            return this._combinedHoursToDateCache[monthIndex] || this.getCombinedHoursToDateMonthIndex(monthIndex-1)
        }

    }

    getDisplayedHoursToDateMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedHoursToDateMonthIndex(monthIndex);
            case "actuals":
                return this.getActualHoursToDateMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedHoursToDateMonthIndex(monthIndex);
        }
    }

    getProjectedCostToDateMonthIndex(monthIndex) {
        if (!this._projectedMinMonthIndex || monthIndex < this._projectedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._projectedMaxMonthIndex) {
            return this.getProjectedCostToDateMonthIndex(this._projectedMaxMonthIndex)
        } else if (this._projectedCostToDateCache[monthIndex]) {
            return this._projectedCostToDateCache[monthIndex]
        } else {
            this._projectedCostToDateCache[monthIndex] = this.getProjectedCostToDateMonthIndex(monthIndex - 1) + this.getProjectedCostMonthIndex(monthIndex)
            return this._projectedCostToDateCache[monthIndex]
        }
    }

    getActualCostToDateMonthIndex(monthIndex) {
        if (!this._actualMinMonthIndex || monthIndex < this._actualMinMonthIndex) {
            return 0
        } else if (monthIndex > this._actualMaxMonthIndex) {
            return this.getActualCostToDateMonthIndex(this._actualMaxMonthIndex)
        } else {
            return this._actualCostToDateCache[monthIndex] || this.getActualCostToDateMonthIndex(monthIndex - 1)
        }

    }

    getCombinedCostToDateMonthIndex(monthIndex) {
        if (!this._combinedMinMonthIndex || monthIndex < this._combinedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._combinedMaxMonthIndex) {
            return this.getCombinedCostToDateMonthIndex(this._combinedMaxMonthIndex)
        } else if (this._combinedCostToDateCache[monthIndex]) {
            return this._combinedCostToDateCache[monthIndex]
        } else {
            this._combinedCostToDateCache[monthIndex] = this.getCombinedCostToDateMonthIndex(monthIndex - 1) + this.getCombinedCostMonthIndex(monthIndex)
            return this._combinedCostToDateCache[monthIndex]
        }

    }

    getDisplayedCostToDateMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedCostToDateMonthIndex(monthIndex);
            case "actuals":
                return this.getActualCostToDateMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedCostToDateMonthIndex(monthIndex);
        }
    }

    getProjectedChargeOutToDateMonthIndex(monthIndex) {
        if (!this._projectedMinMonthIndex || monthIndex < this._projectedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._projectedMaxMonthIndex) {
            return this.getProjectedChargeOutToDateMonthIndex(this._projectedMaxMonthIndex)
        } else if (this._projectedChargeOutToDateCache[monthIndex]) {
            return this._projectedChargeOutToDateCache[monthIndex]
        } else {
            this._projectedChargeOutToDateCache[monthIndex] = this.getProjectedChargeOutToDateMonthIndex(monthIndex - 1) + this.getProjectedChargeOutMonthIndex(monthIndex)
            return this._projectedChargeOutToDateCache[monthIndex]
        }
    }

    getActualChargeOutToDateMonthIndex(monthIndex) {
        if (!this._actualMinMonthIndex || monthIndex < this._actualMinMonthIndex) {
            return 0
        } else if (monthIndex > this._actualMaxMonthIndex) {
            return this.getActualChargeOutToDateMonthIndex(this._actualMaxMonthIndex)
        } else {
            return this._actualChargeOutToDateCache[monthIndex] || this.getActualChargeOutToDateMonthIndex(monthIndex - 1)
        }

    }

    getCombinedChargeOutToDateMonthIndex(monthIndex) {
        if (!this._combinedMinMonthIndex || monthIndex < this._combinedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._combinedMaxMonthIndex) {
            return this.getCombinedChargeOutToDateMonthIndex(this._combinedMaxMonthIndex)
        } else if (this._combinedChargeOutToDateCache[monthIndex]) {
            return this._combinedChargeOutToDateCache[monthIndex]
        } else {
            this._combinedChargeOutToDateCache[monthIndex] = this.getCombinedChargeOutToDateMonthIndex(monthIndex - 1) + this.getCombinedChargeOutMonthIndex(monthIndex)
            return this._combinedChargeOutToDateCache[monthIndex]
        }

    }

    getDisplayedChargeOutToDateMonthIndex(monthIndex) {
        const { dataType, currentMonthIndex } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedChargeOutToDateMonthIndex(monthIndex);
            case "actuals":
                return this.getActualChargeOutToDateMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedChargeOutToDateMonthIndex(monthIndex);
        }
    }


    get monthIndexArray() {
        return _.range(
            Math.min(this.minMonthIndex, this.spreadsheetStore.selectedHoursMonthIndex), 
            Math.max(this.maxMonthIndex, this.spreadsheetStore.selectedHoursMonthIndex) + 1
        )
    }

    get totalProjectedHours() {
        return this.getDisplayedHoursToDateMonthIndex(this.maxMonthIndex)
    }

    get totalProjectedCost() {
        return this.getDisplayedCostToDateMonthIndex(this.maxMonthIndex)
    }

    get totalBudgetUse() {
        if (!this.budget) return 0
        return (this.totalProjectedHours / this.budget) * 100
    }

    get budget() {
        if (this._budget !== undefined) return this._budget
        this._budget = this.calcBudget()
        return this._budget
    }

    get costBudget() {
        if (this._costBudget !== undefined) return this._costBudget
        this._costBudget = this.calcCostBudget()
        return this._costBudget
    }

    calcBudget() {
        let groups = this.ancestors.map(a => a.rowType)
        groups.push(this.rowType)
        const projectGroup = groups.includes("project") && this.project
        const phaseGroup = groups.includes("phase") && this.phase
        const staffGroup = groups.includes("staff")
        const roleGroup = groups.includes("role")
        if (projectGroup && phaseGroup && staffGroup) {
            if (this.staffMember) return this.phase.getStaffMemberHoursBudget(this.staffMember)
            return this.phase.getStaffRoleHoursBudget(this.staffRole)
        } else if (projectGroup && phaseGroup && roleGroup && !staffGroup) {
            return this.phase.getTotalStaffRoleHoursBudget(this.staffRole)
        } else if (projectGroup && staffGroup && !phaseGroup && !roleGroup) {
            if (this.staffMember) return this.project.getStaffMemberHoursBudget(this.staffMember)
            return this.project.getStaffRoleHoursBudget(this.staffRole)
        } else if (projectGroup && phaseGroup && !roleGroup && !staffGroup) {
            return this.phase ? this.phase.manualHoursBudget : 0
        } else if (projectGroup && !phaseGroup && !roleGroup && !staffGroup) {
            return this.project ? this.project.manualHoursBudget : 0
        } else {
            return sum(this.visibleChildren.map(child => child.budget))
        }
    }

    calcCostBudget() {
        let groups = this.ancestors.map(a => a.rowType)
        groups.push(this.rowType)
        const projectGroup = groups.includes("project") && this.project
        const phaseGroup = groups.includes("phase") && this.phase
        const staffGroup = groups.includes("staff") && this.staffMember
        const roleGroup = groups.includes("role") && this.staffRole
        if (projectGroup && phaseGroup && staffGroup) {
            if (this.staffMember) return this.phase.getStaffMemberHoursBudget(this.staffMember) * this.project.getRateInRange(this.staffMember, this.phase, "costRate", this.phase.startDate || currrentDateInt, this.phase.endDate || currrentDateInt)
            return this.phase.getStaffRoleHoursBudget(this.staffRole) * this.project.getRateInRange(this.staffRole, this.phase, "costRate", this.phase.startDate || currrentDateInt || currrentDateInt, this.phase.endDate || currrentDateInt)
        } else if (projectGroup && phaseGroup && roleGroup && !staffGroup) {
            return this.phase.getTotalStaffRoleHoursBudget(this.staffRole) * this.project.getRateInRange(this.staffRole, this.phase, "costRate", this.phase.startDate || currrentDateInt, this.phase.endDate || currrentDateInt)
        } else if (projectGroup && staffGroup && !phaseGroup && !roleGroup) {
            if (this.staffMember) return this.project.getStaffMemberHoursBudget(this.staffMember) * this.project.getRateInRange(this.staffMember, this.phase, "costRate", this.phase.startDate || currrentDateInt, this.phase.endDate || currrentDateInt)
            return this.project.getStaffRoleHoursBudget(this.staffRole) * this.project.getRateInRange(this.staffRole, this.phase, "costRate", this.phase.startDate || currrentDateInt, this.phase.endDate || currrentDateInt)
        } else if (projectGroup && phaseGroup && !roleGroup && !staffGroup) {
            return this.phase ? this.phase.manualBudget : 0
        } else if (projectGroup && !phaseGroup && !roleGroup && !staffGroup) {
            return this.project ? this.project.manualBudget : 0
        } else {
            return sum(this.visibleChildren.map(child => child.budget))
        }
    }

    getProjectedBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getProjectedHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    getActualBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getActualHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    getDisplayedBudgetUseMonthIndex(monthIndex) {
        if (!this.budget) return 0
        return (this.getDisplayedHoursToDateMonthIndex(monthIndex) / this.budget) * 100
    }

    getProjectedCostBudgetUseMonthIndex(monthIndex) {
        if (!this.costBudget) return 0
        return (this.getProjectedCostToDateMonthIndex(monthIndex) / this.costBudget) * 100
    }

    getActualCostBudgetUseMonthIndex(monthIndex) {
        if (!this.costBudget) return 0
        return (this.getActualCostToDateMonthIndex(monthIndex) / this.costBudget) * 100
    }

    getDisplayedCostBudgetUseMonthIndex(monthIndex) {
        if (!this.costBudget) return 0
        return (this.getDisplayedCostToDateMonthIndex(monthIndex) / this.costBudget) * 100
    }

    clearBudgets() {
        this._budget = undefined;
        this.ancestors.forEach( a => a._budget = undefined )
        this.children.forEach( c => c.clearBudgets() )
    }

    setCostMonthIndex(monthIndex, newCost) {
        const oldProjectedCost = this.getProjectedCostMonthIndex(monthIndex)
        const oldDisplayedCost = this.getDisplayedCostMonthIndex(monthIndex)
        if (!this.editable || newCost === oldProjectedCost) return true
        if (this.editableChildren.length > 0) {
            const costRatio = oldDisplayedCost ? newCost / oldDisplayedCost : newCost / this.editableChildren.length
            this.editableChildren.forEach(childRow => {
                const oldChildCost = childRow.getDisplayedCostMonthIndex(monthIndex)
                childRow.setCostMonthIndex(monthIndex, oldDisplayedCost ? oldChildCost * costRatio : costRatio)
            })
        } else {
            const costRate = this.project.getRateInMonth(this.staffMember || this.staffRole, this.phase, "costRate", monthIndex)
            const oldProjectedHours = this.getProjectedHoursMonthIndex(monthIndex)
            const newHours = newCost / costRate
            const hours = (newHours - oldProjectedHours)
            this.addHours(hours, monthIndex, "projected");
            const startDate = dateConverter.monthIndexToOffset(monthIndex);
            const endDate = dateConverter.endOfMonthOffset(startDate);
            if (this.staffMember && this.phase) {
                this.phase.setStaffMemberHours(this.staffMember, startDate, endDate, newHours);
            } else if (this.staffRole && this.phase) {
                this.phase.setStaffRoleHours(this.staffRole, startDate, endDate, newHours);
            }
            this.clearCalculatedVals()
        }
    }

    setHoursMonthIndex(monthIndex, newHours) {
        const oldProjectedHours = this.getProjectedHoursMonthIndex(monthIndex)
        const oldDisplayedHours = this.getDisplayedHoursMonthIndex(monthIndex)
        if (!this.editable || newHours === oldProjectedHours) return true
        if (this.editableChildren.length > 0) {
            const hoursRatio = oldDisplayedHours ? newHours / oldDisplayedHours : newHours / this.editableChildren.length
            this.editableChildren.forEach(childRow => {
                const oldChildHours = childRow.getDisplayedHoursMonthIndex(monthIndex)
                childRow.setHoursMonthIndex(monthIndex, oldDisplayedHours ? oldChildHours * hoursRatio : hoursRatio)
            })
        } else {
            const hours = (newHours - oldProjectedHours)
            this.addHours(hours, monthIndex, "projected");
            const startDate = dateConverter.monthIndexToOffset(monthIndex);
            const endDate = dateConverter.endOfMonthOffset(startDate);
            if (this.staffMember && this.phase) {
                this.phase.setStaffMemberHours(this.staffMember, startDate, endDate, newHours);
            } else if (this.staffRole && this.phase){
                this.phase.setStaffRoleHours(this.staffRole, startDate, endDate, newHours);
            }
            this.clearCalculatedVals()
        }
    }

    setCostBudgetUseMonthIndex(monthIndex, budgetPercent) {
        const budget = this.costBudget
        const costLastMonthToDate = this.getDisplayedCostToDateMonthIndex(monthIndex - 1)
        const newMonthCost = budget * (budgetPercent / 100) - costLastMonthToDate
        this.setCostMonthIndex(monthIndex, newMonthCost);
    }

    setBudgetUseMonthIndex(monthIndex, budgetPercent) {
        const budget = this.budget
        const hoursLastMonthToDate = this.getDisplayedHoursToDateMonthIndex(monthIndex-1)
        const hours = budget * (budgetPercent / 100) - hoursLastMonthToDate
        this.setHoursMonthIndex(monthIndex, hours);
    }

    setUtilisationMonthIndex(monthIndex, percentUtilisation) {
        const availabileHours = this.getTotalAvailableHoursInMonth(monthIndex)
        const hours = availabileHours * (percentUtilisation / 100)
        this.setHoursMonthIndex(monthIndex, hours);
    }

    get averageDisplayedHours() {
        const totalHours = sum(this.spreadsheetStore.dateColumns.map(dc => this.getDisplayedHoursMonthIndex(dc.monthIndex)))
        return totalHours / 12
    }

    get averageAvailableHours() {
        const totalHours = sum(this.spreadsheetStore.dateColumns.map(dc => this.getTotalAvailableHoursInMonth(dc.monthIndex)))
        return totalHours / 12
    }

    get averageUtilisation() {
        if (!this.averageAvailableHours) return 0
        return (this.averageDisplayedHours / this.averageAvailableHours) * 100 
    }

    addChildStaff(roleStaffArray) {
        if (this.rowType == "phase") {
            roleStaffArray.forEach(rs => {
                const roleRow = this.addBlankChildRow(getBlankRowData({
                    uuid: this.uuid + itemUuids["role"](rs.role),
                    title: itemTitles["role"](rs.role),
                    level: this.level + 1,
                    groupType: this.groupType,
                    rowType: "role",
                    project: this.project,
                    phase: this.phase,
                    staff: null,
                    role: rs.role,
                    parentId: this.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                roleRow.addBlankChildRow(getBlankRowData({
                    uuid: roleRow.uuid + itemUuids["staff"](rs.staff),
                    title: itemTitles["staff"](rs.staff),
                    level: roleRow.level + 1,
                    groupType: this.groupType,
                    rowType: "staff",
                    project: this.project,
                    phase: this.phase,
                    staff: rs.staff,
                    role: rs.role,
                    parentId: roleRow.uuid,
                    childrenIds: [],
                    currentMonthIndex: this.spreadsheetStore.currentMonthIndex
                }))
                if (rs.staff) {
                    this.phase.addBlankStaffAllocations(rs.staff)
                } else if (rs.role) {
                    this.phase.addBlankRoleAllocations(rs.role)
                }
            })
        }
    }

    addBlankChildRow(data) {
        const store = this.spreadsheetStore
        if (!this.childrenIds.has(data.uuid)) {
            this.childrenIds.add(data.uuid)
            const newRow = new TimesheetRow(store, data)
            store.hoursRows = store.hoursRows.set(data.uuid, newRow)
            return newRow
        } else {
            return store.hoursRows.get(data.uuid)
        }
    }

    generateSpreasheetRow(dateColumns, shadow = false) {
        const totalHours = Math.round(this.projectedTotal);
        const budget = Math.round(this.displayedBudget);
        const percent = Math.round(budget ? (totalHours / budget) * 100 : 0)
        const percentString = isFinite(percent) && Math.abs(percent) < 1000 ? ` (${percent}%)`  : ``
        const totalHoursString = (this.displayCost ? `$` : ``) + `${formatCurrency(totalHours)}`
        const budgetString = (this.displayCost ? `$` : ``) + `${formatCurrency(budget)}`
        const totalString = `${totalHoursString} / ${budgetString}` + percentString;
        const error = budget && percent > 100
        return {
            rowType: this.rowDisplayType + (shadow ? " shadow" : ""),
            cells: [
                {
                    value: this.title,
                    uuid: this.uuid,
                    cellType: "title",
                    isRowHeader: true,
                    isEditable: false,
                    expandable: this.expandable && this.childrenIds.size > 0,
                    hideable: this.hideable,
                    expanded: this.expanded,
                    visible: this.visible,
                    addAllocationButton: this.addAllocationButton,
                    row: this,
                },
                {
                    value: totalString,
                    isRowHeader: true,
                    isEditable: false,
                    error: error,
                    visible: this.visible,
                    row: this,
                },
                ...dateColumns.map(d => this.getMonthIndexCell(this, d.monthIndex))
            ]
        }
    }

    get displayCost() {
        return !this.spreadsheetStore.forecastType.includes("hours");
    }

    get displayedBudget() {
        return this.displayCost ? this.costBudget : this.budget;
    }

    get projectedTotal() {
        return this.displayCost ? this.totalProjectedCost : this.totalProjectedHours;
    }

    getDisplayedMonthTotal(monthIndex) {
        return this.displayCost ? this.getDisplayedCostMonthIndex(monthIndex) : this.getDisplayedHoursMonthIndex(monthIndex);
    }

    setDisplayedMonthTotal(monthIndex, newTotal) {
        if (this.displayCost) {
            this.setCostMonthIndex(monthIndex, newTotal)
        } else {
            this.setHoursMonthIndex(monthIndex, newTotal)
        }
    }

    clearCalculatedVals() {
        this._projectedCostCache = {};
        this._combinedCostCache = {};
        this._projectedCostToDateCache = {};
        this._combinedCostToDateCache = {};
        this._projectedChargeOutCache = {};
        this._projectedChargeOutToDateCache = {};
        this._combinedChargeOutToDateCache = {};
        if (this.parent) this.parent.clearCalculatedVals()
    }

    getMonthIndexCell(row, monthIndex) {
        if (!monthIndex) return null
        const selectedCell = this.selected && this.spreadsheetStore.selectedHoursMonthIndex === monthIndex
        const monthInput = selectedCell ? this.spreadsheetStore.selectedHoursCellInputText : ""
        const monthTotal = Math.round(this.getDisplayedMonthTotal(monthIndex));
        const valueString = (this.displayCost ? `$` : ``) + `${monthTotal}`
        return {
            value: valueString,
            numValue: monthTotal,
            uuid: this.uuid,
            inputText: monthInput,
            monthIndex: monthIndex,
            row: row,
            visible: this.visible,
            inRange: this.startMonthIndex && this.endMonthIndex && (monthIndex >= this.startMonthIndex && monthIndex <= this.endMonthIndex),
            isProject: true,
            isEditable: this.editable,
            error: false,
        }
    }
}

const itemUuids = {
    "role": item => item ? item.id : "--no-role",
    "staff": item => item ? item.id : "--no-staff",
    "project": item => item ? item.id : "--no-project",
    "phase": item => item ? item.id : "--no-phase",
    "status": item => item ? item.status : "--no-status",
}

const itemTitles = {
    "total": item => "Total",
    "role": item => item ? item.name : "No Role",
    "staff": item => item ? item.getFullName() : "Generic",
    "project": item => item ? item.getTitle() : "No Project",
    "phase": item => item ? item.getTitle() : "No Phase",
    "status": item => item ? item.status : "No Status",
}

const getBlankRowData = ({
    uuid,
    title,
    level,
    groupType,
    rowType,
    projectId,
    phaseId,
    staffId,
    roleId,
    parentId,
    project,
    phase,
    staff,
    role,
    childrenIds,
    currentMonthIndex
}) => ({
    'uuid': uuid,
    'title': title,
    'level': level,
    'groupType': groupType,
    'rowType': rowType,
    'projectId': projectId,
    'phaseId': phaseId,
    'staffId': staffId,
    'roleId': roleId,
    'project': project,
    'phase': phase,
    'staff': staff,
    'role': role,
    'parentId': parentId,
    'childrenIds': childrenIds,
    'projectedHours': {},
    'actualHours': {},
    'combinedHours': {},
    'projectedHoursToDate': {},
    'actualHoursToDate': {},
    'combinedHoursToDate': {},
    'actualMinMonthIndex': currentMonthIndex,
    'actualMaxMonthIndex': currentMonthIndex,
    'projectedMinMonthIndex': currentMonthIndex,
    'projectedMaxMonthIndex': currentMonthIndex,
    'combinedMinMonthIndex': currentMonthIndex,
    'combinedMaxMonthIndex': currentMonthIndex,
})