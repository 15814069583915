import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';

import PropTypes from "prop-types";

export var VerticalLink = CreateReactClass({
  propTypes: {
    upLinkFunction: PropTypes.func,
    downLinkFunction: PropTypes.func,
    unlinkFunction: PropTypes.func,
    paddingEms: PropTypes.number,
    linked: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      paddingEms: 0,
      upLinkFunction: () => null,
      downLinkFunction: () => null,
      unlinkFunction: () => null,
      linked: false
    };
  },

  getInitialState: function() {
    return {
      hoverUp: false,
      hoverDown: false
    };
  },

  render: function() {
    let hovering = (this.state.hoverUp || this.state.hoverDown);
    let linked = this.props.linked;
    return (
      <div style={{width: `calc(100% - ${this.props.paddingEms * 2}em)`, height: `calc(100% - ${this.props.paddingEms * 2}em)`, position: 'absolute'}}>
        <div style={{width: '100%', height: '100%', zIndex: 2, position: 'absolute'}}>
          <div style={{width: '100%', height: '50%', textAlign: 'center'}}>
            <div
              style={{width: '3em', height: '100%', display: 'inline-block', cursor: 'pointer'}}
              onMouseEnter={(e) => this.handleUpMouseIn(e)}
              onMouseLeave={(e) => this.handleUpMouseOut(e)}
              onClick={(e) => this.handleUpClick(e)}
            />
          </div>
          <div style={{width: '100%', height: '50%', textAlign: 'center'}}>
            <div
              style={{width: '3em', height: '100%', display: 'inline-block', cursor: 'pointer'}}
              onMouseEnter={(e) => this.handleDownMouseIn(e)}
              onMouseLeave={(e) => this.handleDownMouseOut(e)}
              onClick={(e) => this.handleDownClick(e)}
            />
          </div>
        </div>
        <div style={{display: 'inline-block', position: 'relative', zIndex: 1, color: this.props.linked ? '#333' : '#666'}}>
          {!linked && this.state.hoverUp ?
            <div style={{position: 'absolute', right: '100%', top: 0, textAlign: 'center', height: '50%', paddingRight: '0.5em', fontSize: '1em'}}>
              <i className="fa fa-chevron-up" style={{margin: 0, display: 'block'}}/>
            </div>
          : null}
          {!linked && this.state.hoverDown ?
            <div style={{position: 'absolute', right: '100%', bottom: 0, textAlign: 'center', height: '50%', paddingRight: '0.5em', fontSize: '1em'}}>
              <i className="fa fa-chevron-down" style={{margin: 0, display: 'block'}}/>
            </div>
          : null}
          <div style={{
              display: 'inline-block',
              border: linked ? 'solid 1px #999' : 'solid 1px #ccc',
              backgroundColor: ((linked && !hovering) || (!linked && hovering)) ? '#e2e2e2' : 'white',
              boxShadow: ((linked) || (!linked && hovering)) ? 'rgba(0, 0, 0, 0.2) 1px 1px 1px 0px inset' : 'inset rgba(0, 0, 0, 0.2) -0.5px -0.5px 1px 0px',
            }}>
            <i className="fa fa-link fa-rotate-45" style={{margin: 0}}/>
          </div>
          {hovering ?
            <div style={{position: 'absolute', left: '100%', paddingLeft: '0.5em', top: 0, color: '#666', fontWeight: 600, lineHeight: "1em", width: "5em"}}>
              {linked ? 'Unlink' : this.state.hoverUp ? 'Link to Phase' : 'Link to Resources'}
            </div>
          : null}
        </div>
      </div>
    );
  },

  handleUpClick: function(event) {
    if (this.props.linked) {
      this.props.unlinkFunction();
    } else {
      this.props.upLinkFunction();
    }
  },

  handleDownClick: function(event) {
    if (this.props.linked) {
      this.props.unlinkFunction();
    } else {
      this.props.downLinkFunction();
    }
  },

  handleUpMouseIn: function(event) {
    this.setState({hoverUp: true});
  },

  handleUpMouseOut: function(event) {
    this.setState({hoverUp: false});
  },

  handleDownMouseIn: function(event) {
    this.setState({hoverDown: true});
  },

  handleDownMouseOut: function(event) {
    this.setState({hoverDown: false});
  }
});
