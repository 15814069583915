import _ from 'underscore';
import moment from 'moment';
import { organisationStore } from "../organisation.js";
import { StaffMember } from '../models.js';
import { PayRate } from '../models/staffmember.js';
import { PermissionItem, PermissionObject, FinancialsVisibility, getProjectPermissionLevel } from '../models/permissions.js';
import { isNotBlank, isEmail, isNumericOrBlank } from '../utils.js';
import { StoreBase, dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import { TimesheetStore } from '../timesheets/flux.js';
import { jsonHttp } from '../jsonHttp.js';
import { ReportStore, applyTimesheetCacheRequirement, isDataChangedAction } from '../reports/flux.js';
import { Column } from '../table.js';
import { router } from '../router.js';
import { Report } from '../reports/Report.js';
import { TimesheetDataCache } from '../reports/TimesheetDataCache.js';
import { AjaxOperation2 } from '../AjaxOperation.js';
import Immutable from 'immutable';
import apiRequest from '../apiRequest.js';
import { userStore } from '../user/flux.js';


const staffActionDefinitions = [
  {action: 'loadPage', args: ['staffMemberId']},

  {action: 'selectTab', args: ['tabName']},
  {action: 'editPayRates', args: []},

  {action: 'addPayRate', args: []},
  {action: 'setPayRateField', args: ['uuid', 'field', 'value']},
  {action: 'deletePayRate', args: ['uuid']},

  {action: 'editPermissions', args: []},
  {action: 'save', args: ['staffMember']},
  {action: 'setFieldValue', args: ['fieldName', 'value']},

  {action: 'timesheetSaveSuccessTimeout', args: []},
  {action: 'addPermission', args: []},
  {action: 'setFinancialsVisibility', args: ['value']},
  {action: 'setOverallLevel', args: ['level']},
  {action: 'setPermissionItem', args: ['rowIndex', 'item']},
  {action: 'setPermissionLevel', args: ['rowIndex', 'level']},
  {action: 'deletePermission', args: ['rowIndex']},

  {action: 'resendInvite', args: []},
  {action: 'resendInviteConfirm', args: []},
  {action: 'resendInviteSuccess', args: []},
  {action: 'resendInviteFailure', args: ['error']},
  {action: 'resendInviteClose', args: ['modal']}
];

export const actions = registerActions("staff-page", staffActionDefinitions, dispatcher);


export const StaffColumns = class {
			constructor(dataSource) {
				let self = this;

				this.dataSource = dataSource;

				this.columns = [
					new Column({
						identifier: "staff",
						header: "Staff",
						width: "15%",
						content: sm => sm,
						type: "staffMember",
						canShow: false
					}),
					new Column({
						identifier: "lastName",
						header: "Last name",
						width: "15%",
						content: sm => sm.lastName,
						type: "string"
					}),
					new Column({
						header: "First name",
						identifier: "firstName",
						width: "15%",
						content: sm => sm.firstName,
						type: "string"
					}),
					new Column({
						identifier: "email",
						header: "Email",
						width: "30%",
						content: sm => sm.email,
						type: "string"
					}),
					new Column({
						identifier: "role",
						header: "Role",
						width: "15%",
						data: sm => sm.role,
						content: (row, i, stack, data) =>
							data != null ? data.name : "",
						type: "staffRole"
					}),
					new Column({
						identifier: "costCentre",
						header: "Cost Centre",
						width: "15%",
						data: sm => sm.costCentre,
						content: (row, i, stack, data) =>
							data != null ? data.name : "",
						type: "costCentre"
					}),
					new Column({
						identifier: "isArchived",
						header: "Is Archived",
						width: "10%",
						data: sm => sm.isArchived,
						type: "bool"
					}),
					new Column({
						identifier: "payRate",
						header: "Pay Rate",
						width: "10%",
						data: sm => sm.payRate,
						type: "number"
					}),
					new Column({
						identifier: "overtimeRate",
						header: "Overtime Rate",
						width: "10%",
						data: sm => sm.overtimeRate,
						type: "number"
					}),
					new Column({
						identifier: "costRate",
						header: "Cost Rate",
						width: "10%",
						data: sm => sm.costRate,
						type: "number"
					}),
					new Column({
						identifier: "chargeOutRate",
						header: "Charge Out Rate",
						width: "10%",
						data: sm => sm.chargeOutRate,
						type: "number"
					}),
					new Column({
						identifier: "weeklyAvailability",
						header: "Weekly Availability",
						width: "10%",
						data: sm => sm.weeklyAvailability,
						type: "number"
					}),
					new Column({
						identifier: "allocatedHours",
						header: "Allocated hours",
						width: "10%",
						data: sm => this.staffMemberAllocatedHours(sm),
						type: "number"
					}),
					new Column({
						identifier: "availableHours",
						header: "Available hours",
						width: "10%",
						data: sm => this.staffMemberAvailableHours(sm),
						type: "number"
					}),
					new Column({
						identifier: "recordedHours",
						header: "Recorded hours",
						width: "10%",
						requires: ["timesheetCache"],
						data: function(sm) {
							const staffMemberData = self.timesheetData.staffMemberMap.get(
								sm.id
							);
							return staffMemberData != null
								? staffMemberData.numHours
								: 0;
						},
						type: "number"
					}),
					new Column({
						/**
						 * Ratio of the user's billable hours in the time period vs. their total hours
						 * for the time period.
						 */
						identifier: "percentBillableHours",
						header: "Billable hours %",
						width: "10%",
						requires: ["timesheetCache"],
						data: function(sm) {
							const staffMemberData = self.timesheetData.staffMemberMap.get(
								sm.id
							);
							const staffMemberBillableData = self.timesheetData.staffMemberBillableMap.get(
								sm.id
							);
							return staffMemberData != null &&
								staffMemberBillableData != null &&
								staffMemberData.numHours > 0
								? (staffMemberBillableData.numHours /
										staffMemberData.numHours) *
										100
								: 0;
						},
						type: "number"
					}),
					new Column({
						identifier: "projects",
						header: "Projects",
						width: "30%",
						data: sm => this.staffMemberProjects(sm),
						type: "projects"
					})
				];
				applyTimesheetCacheRequirement(this.columns, this.dataSource);

				this.columnLookup = {};
				for (let c of this.columns) {
					this.columnLookup[c.identifier] = c;
				}
			}

			getColumnById(id) {
				return this.columnLookup[id];
			}

			get dateRange() {
				return this.dataSource.dateRange;
			}

			get timesheetData() {
				return this.dataSource.timesheetDataCache.getDateRange(
					this.dataSource.dateRange
				);
			}

			staffMemberAllocatedHours(staffMember) {
				return staffMember.getNumHoursAllocatedInRange(
					...this.dateRange.getDates(moment())
				);
			}

			staffMemberAvailableHours(staffMember) {
				//TODO-new_reports we have `Infinity` hours available if the date range is infinite.
				return staffMember.getNumHoursAvailableInRange(
					...this.dateRange.getDates(moment()),
					organisationStore.getHolidaysXspans().data
				);
			}

			staffMemberProjects(staffMember) {
				return staffMember.getAllocatedProjectsInRange(
					...this.dateRange.getDates(moment())
				);
			}
		};


class StaffMembersPageStore extends StoreBase {
  constructor() {
    super();

    let columns = new StaffColumns(this).columns;

    this.reportStore = new ReportStore({
      path: "staff-members-page/report",
      columns: columns,
    });

    this.timesheetDataCache = new TimesheetDataCache(() => this.emitChanged());
    this.isReady = false;
  }

  handle(action) {
    if (action.path === "staff-members-page/report") {
      this.reportStore.handle(action);
      this.emitChanged();
      if (isDataChangedAction(action)) {
        this.updateTimesheetDataCache();
      }
    }
  }

  loadReport(report) {
    if (report == null) {
      report = Report.fromJson({
        name: "New report",
        reportType: "staffMember",
        columns: ['lastName', 'firstName', 'email'],
        dateRange: {
          id: 'all_time'
        },
        filters: [
          {
            columnId: "isArchived",
            matcher: {
              type: "bool",
              operation: "is_false"
            }
          }
        ],
      });
    }
    this.reportStore.report = report;
    this.updateTimesheetDataCache();
    this.isReady = true;
    this.emitChanged();
  }

  get dateRange() {
    return this.reportStore.report.dateRange;
  }

  get timesheetData() {
    return this.timesheetDataCache.getDateRange(this.dateRange);
  }

  updateTimesheetDataCache() {
    if (this.reportStore.hasSelectedColumnWithRequirement('timesheetCache')) {
      this.timesheetDataCache.populateDateRange(this.report.dateRange);
    }
  }

  toDefaultPage() {
    router.history.push("/dashboard/staff");
  }

  getMatchingStaff() {
    return this.reportStore.getMatchingItems(
      organisationStore.staffMembers.filter(
        sm => getProjectPermissionLevel(sm, userStore.user) === "admin"
      )
    );
  }

  get report() {
    return this.reportStore.report;
  }

  newStaffMember() {
    router.history.push('/dashboard/staff/new');
  }

  openStaffMember(staffMember) {
    router.history.push(`/dashboard/staff/${staffMember.id}`);
  }

  staffMemberAllocatedHours(staffMember) {
    return staffMember.getNumHoursAllocatedInRange(...this.dateRange.getDates(moment()));
  }

  staffMemberAvailableHours(staffMember) {
    //TODO-new_reports we have `Infinity` hours available if the date range is infinite.
    let holidaysArray = organisationStore.getHolidaysXspans().data
    return staffMember.getNumHoursAvailableInRange(...this.dateRange.getDates(moment()), holidaysArray);
  }

  staffMemberProjects(staffMember) {
    return staffMember.getAllocatedProjectsInRange(...this.dateRange.getDates(moment()));
  }
}


export let staffMembersPageStore = new StaffMembersPageStore();


class StaffStore {
  constructor() {
    this.path = "staff-page";

    this.staffMember = null;
    this.submitted = false;
    this.errors = {};
    this.isValid = null;
    this.isDirty = false;

    this.saveOperation = new AjaxOperation2(this.path + '/save');

    this.stores = {
      'save': this.saveOperation
    };

    this.selectedTabName = 'details';
    this.saveError = null;

    this.timesheetStore = null;
    this.timesheetSaveState = null;

    this.modals = [];
    this.resendInviteState = null; // `null`, 'sending', 'sent', 'error'

    this.actionDefinitions = staffActionDefinitions;
  }

  handle(action) {
    if (action.path === this.path + '/save') {
      this.saveOperation.handle(action);
      if (action.type === "ajax/success") {
        this.saveSuccess(action.data.objects.StaffMember[0]);
      }
    }
    else {
      handleAction(action, this);
    }
  }

  _setStaffMember(staffMember) {
    this.staffMember = staffMember.updateIn(['permissions', 'items'], function(items) {
      return items.filter(function(item) {
        return (
          item.object.item === 'everything'
          || organisationStore.getProjectFromPermissionObject(item.object) != null
          || organisationStore.getCostCentreFromPermissionObject(item.object) != null
        );
      });
    });
    this.payRate = staffMember.payRate;
    this.overtimeRate = staffMember.overtimeRate;
    this.costRate = staffMember.costRate;
    this.chargeOutRate = staffMember.chargeOutRate;
    this.weeklyAvailability = staffMember.weeklyAvailability;
  }

  loadPage(staffMemberId) {
    if (staffMemberId === 'new') {
      this._setStaffMember(new StaffMember());
    }
    else {
      this._setStaffMember(organisationStore.getStaffMemberById(parseInt(staffMemberId)));
    }
    this.isValid = true;
    this.saveError = null;
    this.selectedTabName = 'details';
  }

  getIsDirty() {
    if (this.selectedTabName === 'timesheets') {
      return this.timesheetStore.getIsDirty();
    }
    else {
      return this.isDirty;
    }
  };

  save(staffMember) {
    let self = this;

    if (this.selectedTabName === 'timesheets') {
      this.timesheetSaveState = 'saving';
      this.timesheetStore.getCurrentStore().save();
    }
    else {
      this.submitted = true;

      if (this.isValid) {
        this.saveOperation.execute(
			organisationStore._save(
				`/organisation/current/staff-member/${staffMember.id || ""}`,
				"staffMember",
				{
					...staffMember.serialize(),
					payRate: self.payRate,
					overtimeRate: self.overtimeRate,
					costRate: self.costRate,
					chargeOutRate: self.chargeOutRate,
					weeklyAvailability: self.weeklyAvailability
				}
			)
		);
      }
      else {
        this.selectedTabName = 'details';
      }
    }
  }

  adjustRates() {
    let staff = this.staffMember;
    if (staff.isArchived && staff.weeklyAvailability !== 0) {
      this.staffMember = staff.update('payRates', function (payRates) {
        if (payRates.size && payRates.last().get('date').isSame(moment().startOf('day'))) {
          payRates = payRates.delete(payRates.size - 1)
        }
        if (payRates.size) {
          return payRates.push(payRates.last().merge({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        } else {
          return payRates.push(new PayRate({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        }
      });
    } else if (!staff.isArchived && staff.weeklyAvailability === 0) {
      this.staffMember = staff.update('payRates', function (payRates) {
        if (payRates.size && payRates.last().get('date').isSame(moment().startOf('day'))) {
          payRates = payRates.delete(payRates.size - 1)
        }
        if (payRates.size) {
          return payRates.push(
				payRates.last().merge({
					date: moment().startOf("day"),
					weeklyAvailability:
						staff.payRates
							.filter(p => p.get("weeklyAvailability") != 0)
							.last()
							?.get("weeklyAvailability") || 0
				})
			);
        } else {
          return payRates.push(new PayRate({
            date: moment().startOf('day'),
            weeklyAvailability: 0,
          }));
        }
      });
    }
  }

  saveSuccess(staffMemberData) {
    // this prevents race condition if new staff loaded
    // whilst current staff member is saving
    if (staffMemberData.uuid === this.staffMember.uuid) {
      this.isDirty = false;
      this.submitted = false;
      this.saveError = false;
      this.staffMember = this.staffMember.merge({
        id: staffMemberData.id,
        payRates: Immutable.List(staffMemberData.payRates.map(function(pr) {
          return PayRate.fromJson(pr);
        }))
      });
    }
    organisationStore._addObjects({StaffMember: [staffMemberData]});
  }

  saveFailure(error) {
    if (error === 'email_already_exists') {
      this.saveError = 'email_already_exists';
    }
    else {
      this.saveError = true;
    }
  }

  timesheetSaveSuccess(storeUuid) {
    if (this.timesheetStore != null && storeUuid === this.timesheetStore.uuid) {
      this.timesheetSaveState = 'saved';
      setTimeout(function() {
        actions.timesheetSaveSuccessTimeout();
      }, 2000);
    }
  }

  timesheetSaveFailed(storeUuid) {
    if (this.timesheetStore != null && storeUuid === this.timesheetStore.uuid) {
      this.timesheetSaveState = 'saveFailed';
    }
  }

  timesheetSaveSuccessTimeout() {
    this.timesheetSaveState = null;
  }

  setFieldValue(fieldName, value) {
    if (_.include(['payRate', 'overtimeRate', 'costRate', 'chargeOutRate', 'weeklyAvailability'], fieldName)) {
      this[fieldName] = value;
    } else if(fieldName === "roleId" && !value) {
      this.staffMember = this.staffMember.set('inheritPayRate', false);
      this.staffMember = this.staffMember.set('inheritOvertimeRate', false);
      this.staffMember = this.staffMember.set('inheritCostRate', false);
      this.staffMember = this.staffMember.set('inheritChargeOutRate', false);
      this.staffMember = this.staffMember.set(fieldName, value);
    }
    else {
      this.staffMember = this.staffMember.set(fieldName, value);
      if (fieldName === "isArchived") {
        this.adjustRates();
      }
    }
    this.isDirty = true;
    this._validate();
  }

  selectTab(tabName) {
    this.selectedTabName = tabName;

    if (tabName === 'timesheets') {
      this.timesheetStore = TimesheetStore.makeStore({
        user: this.staffMember,
        autosave: false
      });
    }
  }

  editPayRates() {
    this.selectTab('rates');
  }

  editPermissions() {
    this.selectTab('permissions');
  }

  addPayRate() {
    this.staffMember = this.staffMember.update('payRates', function(payRates) {
      let lastRate = payRates.last();
      return payRates.push(new PayRate({
        date: lastRate ? lastRate.date : moment("2000-01-01"),
        payRate: lastRate ? lastRate.payRate : 0,
        overtimeRate: lastRate ? lastRate.overtimeRate : 0,
        costRate: lastRate ? lastRate.costRate : 0,
        chargeOutRate: lastRate ? lastRate.chargeOutRate : 0,
        weeklyAvailability: lastRate ? lastRate.weeklyAvailability : 0,
        roleRate: true
      }));
    });
    this.isDirty = true;
  }

  setPayRateField(uuid, field, value) {
    const val = _.isString(value) ? parseFloat(value) : value;
    const payRateIndex = this.staffMember.payRates.findIndex(pr => pr.uuid === uuid);
    this.staffMember = this.staffMember.setIn(['payRates', payRateIndex, field], val);
    this.isDirty = true;
  }

  deletePayRate(uuid) {
    const payRateIndex = this.staffMember.payRates.findIndex(pr => pr.uuid === uuid);
    this.staffMember = this.staffMember.deleteIn(['payRates', payRateIndex]);
    this.isDirty = true;
  }

  addPermission() {
    this.staffMember = this.staffMember.updateIn(['permissions', 'items'], addPermission);
    this._update();
    this.isDirty = true;
  }

  setFinancialsVisibility(financialsVisibility) {
    this.staffMember = this.staffMember.setIn(['permissions', 'financialsVisibility'], financialsVisibility);
    this.isDirty = true;
  }

  setOverallLevel(level) {
    if (this.staffMember.permissions.items.some(item => item.item !== 'everything')) {
      if (!window.confirm("This will reset this staff member's permissions. Are you sure you want to proceed?")) {
        return false;
      }
    }
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => setOverallLevel(items, level)
    );
    this._update();
    this.isDirty = true;
  }

  setPermissionItem(rowIndex, item) {
    this.staffMember = this.staffMember.setIn(['permissions', 'items', rowIndex, 'object'], item);
    this._update();
    this.isDirty = true;
  }

  setPermissionLevel(rowIndex, level) {
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => setPermissionLevel(items, rowIndex, level)
    );
    this._update();
    this.isDirty = true;
  }

  deletePermission(rowIndex) {
    this.staffMember = this.staffMember.updateIn(
      ['permissions', 'items'],
      items => deletePermission(items, rowIndex)
    );
    this.isDirty = true;
  }

  _update() {
    if (this.staffMember.permissions.isAdmin) {
      this.staffMember = this.staffMember.setIn(['permissions', 'financialsVisibility'], FinancialsVisibility.all);
    }
  }

  resendInvite() {
    this.resendInviteState = null;
    this.modals.push({type: 'resendInvite'});
  }

  resendInviteConfirm() {
    this.resendInviteState = 'sending';
    apiRequest({
      url: `/organisation/current/staff-member/${this.staffMember.id}/resend-invite`,
      method: "post",
      data: { email: this.staffMember.email },
      success: data => actions.resendInviteSuccess(),
      error: data => actions.resendInviteFailure(data)
    });
  }

  resendInviteSuccess() {
    this.resendInviteState = 'sent';
  }

  resendInviteFailure() {
    this.resendInviteState = 'error';
  }

  resendInviteClose(modal) {
    this.modals = _.without(this.modals, modal);
    this.resendInviteState = null;
  }

  _validate() {
    let self = this;

    const validators = {
      firstName: isNotBlank,
      lastName: isNotBlank,
      email: self.staffMember.hasLogin ? isEmail : () => true,
      payRate: isNumericOrBlank,
      overtimeRate: isNumericOrBlank,
      chargeOutRate: isNumericOrBlank,
      weeklyAvailability: isNumericOrBlank,
      minutesPerWeek: isNumericOrBlank
    };

    this.errors = {};
    this.isValid = true;
    _.each(validators, function(func, prop) {
      var isFieldValid = func(self.staffMember[prop]);
      if (!isFieldValid) {
        self.errors[prop] = true;
        self.isValid = false;
      }
    });
  }
}


function addPermission(permissions) {
  return permissions.push(new PermissionItem({
    object: new PermissionObject({item: 'everything'}),
    level: 'view'
  }));
}

function setOverallLevel(_permissions, level) {
  return Immutable.List([
    new PermissionItem({
      object: new PermissionObject({item: 'everything'}),
      level: level
    })
  ]);
}

function setPermissionLevel(permissions, rowIndex, level) {
  return permissions.setIn([rowIndex, 'level'], level);
}

function deletePermission(permissions, rowIndex) {
  return permissions.remove(rowIndex);
}


export var staffStore = new StaffStore();


export const permissionLevels = [
  {
    value: 'admin',
    label: 'Admin',
    description: "This staff member has full access to all Coincraft functionality for your organisation."
  },
  {
    value: 'viewer',
    label: 'Organisation-wide read-only access',
    description: "This staff member can view all your organisation's project data " +
      "and timesheet reports, including financials, but cannot edit any data except their " +
      "own timesheets."
  },
  {
    value: 'project',
    label: 'Project-specific permissions',
    description: "You can assign this staff member to be able to view or edit specific projects " +
      "and/or cost centres."
  },
  {
    value: 'timesheet',
    label: "Timesheet user",
    description: "This staff member can only view and enter their own timesheets."
  }
];
