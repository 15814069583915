import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { rootStore } from '../RootStore.js';
import { ProjectPhase } from '../models.js';
import { BlockTabs } from '../widgets/tabs.js';
import { FormInput } from '../widgets/generic.js';
import { VerticalLink } from '../widgets/VerticalLink.js';
import { projectStore } from './flux.js';
import { organisationStore } from '../organisation.js';
import { permissions, requiresPermission, OperationLevel, UserLevel } from '../models/permissions.js';
import { ProjectStatus } from '../models/project.js';
import { ConfirmChangeCostCentreModal } from './ConfirmChangeCostCentreModal';
import { ProjectTasks } from './tasks.js';
import { ProjectForecasts } from './projectForecasts/ProjectForecasts';
import { MyTable } from "../myTable.js";
import { TextValue, NumberValue, DateValue, CurrencyValue, CostCentreSelector, ContactSelector,
  EditItemControls, ConfirmableDeleteButton, NumberInputContainer, SmallDeleteButton,
  TriStateSaveButton, IntercomMessageLink, SaveBar, StaffMemberRoleSelector,
  BasicMySelect2, ErrorAlert, InfoAlert, SuccessAlert, CurrencySymbol, ProjectPhaseSelector
} from '../widgets.js';
import { formatCurrencyWithCents, formatPercentage0, ErrorPopover, formatNumber2, sum } from "../utils.js";
import { actions, MilestoneSyncState } from './flux.js';
import { ConfirmUpdateTimesheetEntriesModal } from './ConfirmUpdateTimesheetEntriesModal.js';
import { ConfirmSyncModal } from './ConfirmSyncModal.js';
import { CopyProjectModal } from './CopyProjectModal.js';
import { ProjectChangeLog } from './ProjectChangeLog.js';
import { ProjectExpenses } from './ProjectExpenses.js';
import { PhaseMilestonesList } from './PhaseMilestonesList.js';
import { ProjectNoteList } from './ProjectNoteList.js';
import { ProjectHistoryList } from './ProjectHistoryList.js';
import { ProjectStatusList } from './ProjectStatusList.js';
import { labelStyle, indentStyle, quietInfoPanel } from './styles.js';
import { Table, Column } from '../table.js';
import { ProjectForecastsMenu } from './projectForecasts/ProjectForecastsMenu.js';
import moment from 'moment';
import PropTypes from "prop-types";


const elevioIds = {
  resetRevenue: "89334",
};


export var ProjectForm = requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: ({project}) => permissions.canEditProject(project),
    financialsPermission: ({ project }) => permissions.projectFinancialsBudgets(project),
    expensesPermission: ({ project }) => permissions.projectFinancialsBudgets(project),
    hoursPermission: ({project}) => permissions.editProjectBudgets(project),
    deletePermission: ({project}) => permissions.projectAdmin(project),
    isAtLeastAdmin: ({project}) => permissions.projectAdmin(project),
    isAtLeastPM: ({ project }) => permissions.projectManager(project),
    canSeeFinancials: () => permissions.financialVisibilityRevenue,
    canSeeExpenses: () => permissions.financialVisibilityExpenses
  },
  CreateReactClass({
    propTypes: {
      showRevenueHint: PropTypes.bool
    },

    getDefaultProps: function() {
      return {
        showRevenueHint: false
      };
    },

    mixins: [
      makeMultipleStoreMixin([rootStore, organisationStore, projectStore], function() {
        return {
          contacts: organisationStore.contacts,
          costCentres: organisationStore.costCentres,
          project: projectStore.project,
          isDirty: projectStore.isDirty,
          isValid: projectStore.isValid,
          errors: projectStore.project.getErrors(),
          saveState: projectStore.saveState,
          allocationSyncState: projectStore.allocationSyncState,
          cantDeletePopup: projectStore.cantDeletePopup,
          confirmOverwriteBillabilityPopup: projectStore.confirmOverwriteBillabilityPopup,
          confirmOverwriteTimesheetPopup: projectStore.confirmOverwriteTimesheetPopup,
          confirmSyncPopup: projectStore.confirmSyncPopup,
          copyProjectPopup: projectStore.copyProjectPopup,
          projectTaskStore: projectStore.projectTaskStore,
          currencyFormatter: organisationStore.organisation.currencyFormatter,
          milestoneSyncState: projectStore.milestoneSyncState,
          expandedPhases: projectStore.expandedPhases,
          showMenu: projectStore.showMenu,
          displayedProjectPhase: projectStore.displayedProjectPhase,
          forecastType: projectStore.forecastType,
          selectedTab: projectStore.selectedTab
        };
      }),
    ],

    getElements: function() {
      const hasIncome = this.state.project.hasIncome();
      const { canSeeFinancials, canSeeExpenses, isAtLeastAdmin, isAtLeastPM} = this.props
      return {
			className: "project-page",
			heading: (
				<div>
					<div>{this.state.project.getTitle() || "New project"}</div>
				</div>
			),
			extraContent: this.state.selectedTab === "forecasts" && (
				<div className="report-selector">
					<BasicMySelect2
						style={{ marginRight: "1em", maxWidth: "15em" }}
						value={this.state.displayedProjectPhase}
						onChange={displayedProjectPhase =>
							actions.changeDisplayedProjectPhase(
								displayedProjectPhase
							)
						}
						options={[
							{
								label: this.props.project.getTitle(),
								value: this.props.project
							},
							...this.props.project.getVisiblePhases().map(ph => {
								return { label: ph.getTitle(), value: ph };
							})
						]}
					/>
					<BasicMySelect2
						value={this.state.forecastType}
						onChange={forecastType =>
							actions.changeForecastType(forecastType)
						}
						options={[
							...(canSeeFinancials && isAtLeastPM
								? [
										{
											label:
												"Revenue vs. Expenses (cumulative)",
											value: "revenueVsExpenses"
										}
								  ]
								: []),
							...(canSeeExpenses
								? [
										{
											label:
												"Expense Budget (cumulative)",
											value: "expenseBudget"
										}
								  ]
								: []),
							{
								label: "Hours Budget (cumulative)",
								value: "hoursBudget"
							},
							...(canSeeFinancials && isAtLeastPM
								? [
										{
											label:
												"Revenue vs. Expenses (monthly)",
											value: "revenueVsExpensesMonthly"
										}
								  ]
								: []),
							...(canSeeExpenses
								? [
										{
											label: "Expense Budget (monthly)",
											value: "expenseBudgetMonthly"
										}
								  ]
								: []),
							{
								label: "Hours Budget (monthly)",
								value: "hoursBudgetMonthly"
							}
						]}
					/>
				</div>
			),
			rightButtons: this.renderEditControls(),
			tabs:
				this.state.project.id != null ? (
					<BlockTabs
						initialValue="projectDetails"
						onChange={value => actions.changeSelectedTab(value)}
						tabs={[
							{
								label: "Project details",
								value: "projectDetails",
								props: { className: "project-details-tab" }
							},
							{
								label: "Forecasts",
								value: "forecasts",
								props: { className: "forecasts-tab" }
							},
							{
								label: "Tasks",
								value: "tasks",
								props: { className: "project-tasks-tab" }
							},
							this.props.financialsPermission
								? {
										label: "Change log",
										value: "changeLog",
										props: {
											className: "project-change-log-tab"
										}
								  }
								: null,
							this.props.financialsPermission &&
							this.props.isAtLeastPM
								? {
										label: "Rates",
										value: "rates",
										props: {
											className: "project-rates-tab"
										}
								  }
								: null,
							{
								label: "Notes",
								value: "notes",
								props: { className: "project-notes-tab" }
							},
							this.props.isAtLeastPM &&
							this.state.project.eventHistory.length > 0
								? {
										label: "History",
										value: "history",
										props: {
											className: "project-history-tab"
										}
								  }
								: null,
							this.props.isAtLeastPM
								? {
										label: "Status",
										value: "status",
										props: {
											className: "project-status-tab"
										}
								  }
								: null
						]}
					/>
				) : null,
			body: (
				<div className="project-form">
					{this.props.showRevenueHint ? (
						<InfoAlert>
							{hasIncome ? (
								<div>
									<i className="fa fa-lg fa-check-square" />
									Ready for revenue forecasting!
								</div>
							) : (
								<div>
									<div style={{ marginBottom: "1em" }}>
										<strong>
											Not yet ready for revenue
											forecasting
										</strong>
									</div>
									<div>
										To appear in the Revenue Forecast or
										Dashboard, at least one of the{" "}
										<strong>phases</strong> in this project
										should have a{" "}
										<strong>start date</strong>, an{" "}
										<strong>end date</strong>, and a{" "}
										<strong>fee</strong>.
									</div>
								</div>
							)}
						</InfoAlert>
					) : null}

					{this.renderPopups()}

					{this.state.selectedTab === "projectDetails"
						? this.renderProjectDetailsTab()
						: this.state.selectedTab === "forecasts"
						? this.renderForecastsTab()
						: this.state.selectedTab === "tasks"
						? this.renderTasksTab()
						: this.state.selectedTab === "milestones"
						? this.renderMilestonesTab()
						: this.state.selectedTab === "changeLog"
						? this.renderChangeLogTab()
						: this.state.selectedTab === "rates"
						? this.renderRatesTab()
						: this.state.selectedTab === "notes"
						? this.renderNotesTab()
						: this.state.selectedTab === "history"
						? this.renderHistoryTab()
						: this.state.selectedTab === "status"
						? this.renderStatusTab()
						: null}
				</div>
			),

			saveBar:
				this.state.saveState === "invalid" &&
				this.state.errors.length > 0 ? (
					<SaveBar
						unsavedChangesMessage={this.state.errors[0]}
						color={"#dc2d11"}
						showButton={false}
						isDirty={this.state.isDirty}
					/>
				) : this.state.saveState === "failed" ? (
					<SaveBar
						isDirty={this.state.isDirty}
						saveState={this.state.saveState}
						onSaveClick={this.handleSaveButtonClick}
						unsavedChangesMessage={
							"There was an issue saving your project. Please contact us if the problem persists."
						}
						buttonText={"Try Again"}
						color={"#dc2d11"}
					/>
				) : this.state.isDirty || this.state.saveState != null ? (
					<SaveBar
						isDirty={this.state.isDirty}
						saveState={this.state.saveState}
						onSaveClick={this.handleSaveButtonClick}
						unsavedChangesMessage={
							"Your project has unsaved changes."
						}
					/>
				) : null,
			sidebar: this.state.showMenu && (
				<ProjectForecastsMenu
					displayedTab={this.state.selectedTab}
					isEditable={isAtLeastPM}
					viewFinancials={canSeeFinancials}
					viewExpenses={canSeeFinancials}
					onChange={() => actions.setDirty(true)}
				/>
			)
		};
    },

    render: function() {
      return React.createElement(this.props.template, this.getElements());
    },

    renderPopups: function() {
      if (this.state.confirmOverwriteBillabilityPopup) {
        return <ConfirmChangeCostCentreModal
          project={this.state.project}
          onConfirm={() => actions.confirmSave()}
          onCancel={() => actions.cancelSave()}
          saveState={this.state.saveState}
        />;
      }
      else if (this.state.confirmOverwriteTimesheetPopup) {
        return <ConfirmUpdateTimesheetEntriesModal
          itemTypeName='project'
          onConfirm={() => actions.confirmSave()}
          onCancel={() => actions.cancelSave()}
          saveState={this.state.saveState}
        />;
      }
      else if (this.state.confirmSyncPopup) {
        return <ConfirmSyncModal
          itemTypeName='project'
          project={this.state.project}
          onConfirm={(data) => {actions.confirmSave(data)}}
          onCancel={() => actions.cancelSave()}
          saveState={this.state.saveState}
        />;
      }
      else if (this.state.copyProjectPopup) {
        return <CopyProjectModal
          itemTypeName='project'
          project={this.state.project.templateCopy()}
          onConfirm={(data) => actions.copyProject(data)}
          onCancel={() => actions.cancelSave()}
          saveState={this.state.saveState}
        />;
      }
      else {
        return null;
      }
    },

    renderEditControls: function() {
      if (this.props.isAtLeastAdmin) {
        return <div>
          <ConfirmableDeleteButton
            object={this.state.project}
            objectTypeName={"project"}
            text={"Delete"}
            popupBelow={true}
          />
          <button className="page-header__button" onClick={() => actions.clickCopyProject()}>Copy</button>
        </div>;
      }
      else {
        return null;
      }
    },

    renderProjectDetailsTab: function() {
      let self = this;
      return <div>
        <div>
          <div className="flexbox-container flex-space-between flex-align-items-flex-end">
            <h3 style={{fontSize: '2.2em', display: 'inline-block'}}>
              Project Details
            </h3>
            <div>
              <div className="flexbox-container flex-space-between flex-align-items-center">
                <div style={{display: 'inline-block', margin: '0 1em'}}>
                  <div>Client</div>
                  {self.props.isAtLeastPM ?
                    <ContactSelector
                      path="project-page/contact-selector"
                      className="project-form__contact"
                      value={this.state.project.contact}
                      context="project"
                    />
                  :
                    <span>
                      {this.state.project.contact != null ?
                        this.state.project.contact.display()
                      :
                        '(No contact)'
                      }
                    </span>
                  }
                </div>
                <div style={{display: 'inline-block', margin: '0 1em'}} className="project-form__cost-centre-select">
                  <div>Cost Centre</div>
                  <CostCentreSelector
                    value={this.state.project.costCentre}
                    onChange={(costCentre) => actions.setProjectProp('costCentre', costCentre)}
                    isEditable={self.props.isAtLeastAdmin}
                    dropLeft={true}
                  />
                </div>
                <div style={{ display: 'inline-block', margin: '0 1em' }}>
                  <div>Bill on</div>
                  <BasicMySelect2
                    isEditable={self.props.isAtLeastAdmin}
                    options={[
                      { label: 'End of Each Month', value: 'monthly' },
                      { label: 'End of Phase', value: 'manual' }
                    ]}
                    value={self.state.project.milestoneType}
                    onChange={(milestoneType) => actions.setProjectProp('milestoneType', milestoneType)}
                    dropLeft={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{margin: '1.2em 0 2.5em'}} className="coincraft-table">
            <div className="flexbox-container coincraft-table-row header">
              <div className="coincraft-table-cell" style={{width:'6%'}}>Project Code</div>
              <div className="coincraft-table-cell" style={{width:'18%'}}>Project Name</div>
              <div className="coincraft-table-cell" style={{width:'11%'}}>Start Date</div>
              <div className="coincraft-table-cell" style={{width:'11%'}}>End Date</div>
              <div className="coincraft-table-cell" style={{width:'12%'}}>Duration</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Fee</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Expense Budget</div>
              <div className="coincraft-table-cell" style={{width:'8%'}}>Hours Budget</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Profit</div>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
            </div>
            <div className="flexbox-container coincraft-table-row">
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'6%'}}>
                <TextValue
                  className="project-form__project_job_number_input coincraft-table-cell__input"
                  value={this.state.project.jobCode}
                  isEditable={self.props.isAtLeastAdmin}
                  maxLength={20}
                  onChange={(event) => actions.setProjectProp('jobCode', event.target.value)}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'18%'}}>
                <TextValue
                  className="project-form__project_name_input coincraft-table-cell__input"
                  isEditable={self.props.isAtLeastAdmin}
                  value={this.state.project.name}
                  onChange={(event) => actions.setProjectProp('name', event.target.value)}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--date"  style={{width:'11%'}}>
                <DateValue
                  value={this.state.project.getStartDate()}
                  isEditable={self.props.isAtLeastPM}
                  onChange={(startDate) => actions.setProjectProp('startDate', startDate)}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--date"  style={{width:'11%'}}>
                <DateValue
                  value={this.state.project.getEndDate()}
                  isEditable={self.props.isAtLeastPM}
                  onChange={(endDate) => actions.setProjectProp('endDate', endDate)}
                />
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'4%'}}>
                  <NumberInputContainer
                    className="coincraft-table-cell__input"
                    value={this.state.project.getDuration()}
                    isEditable={self.props.isAtLeastPM}
                    formatFunc={n => n != null ? formatNumber2(n) : ''}
                    onChange={(duration) => actions.setProjectDuration(this.state.project, duration)}
                  />
              </div>
              <div
                  className="coincraft-table-cell coincraft-table-cell--dropdown"
                  style={{width:'8%'}}>
                {self.props.isAtLeastPM ?
                  <BasicMySelect2
                    value={this.state.project.durationUnit}
                    onChange={(durationUnit) => actions.setProjectDurationUnit(this.state.project, durationUnit)}
                    options={[
                      {label: 'Days', value: 'days'},
                      {label: 'Weeks', value: 'weeks'},
                      {label: 'Months', value: 'months'}
                    ]}
                  />
                : <span>{this.state.project.durationUnit}</span>}
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'10%'}}>
                {this.props.financialsPermission ?
                  <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                    <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                      <NumberInputContainer
                        className="coincraft-table-cell__input"
                        value={this.state.project.getFee()}
                        isEditable={this.props.isAtLeastAdmin && this.props.financialsPermission === OperationLevel.edit}
                        onChange={(fee) => actions.setProjectProp('fee', fee)}
                        formatFunc={n => n != null ? formatNumber2(n) : ''}
                        nullReadOnlyValue="(Not set)"
                      />
                  </div>
                : null}
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'10%'}}>
                {this.props.expensesPermission ?
                  <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                    <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                      <NumberInputContainer
                        className="coincraft-table-cell__input"
                        value={this.state.project.getBudget()}
                        isEditable={this.props.isAtLeastAdmin && this.props.expensesPermission === OperationLevel.edit}
                        onChange={(budget) => actions.setProjectProp('manualBudget', budget)}
                        formatFunc={n => n != null ? formatNumber2(n) : ''}
                        nullReadOnlyValue="(Not set)"
                      />
                  </div>
                : null}
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'8%'}}>
                <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                  <NumberInputContainer
                    className="project-form__budgeted-hours coincraft-table-cell__input"
                    value={this.state.project.getHours()}
                    isEditable={this.props.isAtLeastAdmin}
                    formatFunc={n => n != null ? formatNumber2(n) : ''}
                    onChange={(hours) => actions.setProjectProp('manualHoursBudget', hours)}
                  />
                </div>
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--currency" style={{width:'10%', fontWeight: 'bold'}}>
                {this.props.financialsPermission ?
                  <div className="coincraft-table-cell--currency__container">
                    <span className="coincraft-table-cell__value invoice__line-item__line-total">
                      ${formatNumber2(this.state.project.getFee() - this.state.project.getBudget())} ({formatNumber2(((this.state.project.getFee() - this.state.project.getBudget())/this.state.project.getFee())*100)}%)
                    </span>
                  </div>
                : null}
              </div>
              <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'4%'}} />
            </div>
          </div>

          {this.renderPhases()}

          {this.props.expensesPermission ? this.renderExpenses() : null}

        </div>
      </div>;
    },

    renderChangeLogTab: function() {
      return <div>
        <div>
          <ProjectChangeLog />
        </div>
      </div>;
    },

    renderForecastsTab: function () {
      return <div>
        <div className="new-dashboard__panel new-dashboard__panel__inner" style={{ padding: '0 2em' }}>
          <ProjectForecasts
            path="project-page/forecasts"
            project={this.state.project}
            isEditable={this.props.isAtLeastPM}
            onChange={() => actions.setDirty(true)}
            toggleMenu={(showMenu) => actions.toggleMenu(showMenu)}
          />
        </div>
      </div>;
    },

    renderTasksTab: function() {
      return <div>
        <div className="new-dashboard__panel new-dashboard__panel__inner" style={{padding: '0 2em'}}>
          <ProjectTasks
            path="project-page/tasks"
            project={this.state.project}
            isEditable={this.props.isEditable}
            actions={this.state.projectTaskStore.actions}
          />
        </div>
      </div>;
    },

    renderMilestonesTab: function() {
      return this.renderMilestones();
    },

    renderNotesTab: function() {
      return this.renderNotes();
    },

    renderHistoryTab: function () {
      return this.renderHistory();
    },

    renderStatusTab: function () {
      return this.renderStatus();
    },

    renderRatesTab: function() {
      return this.renderRatesTable();
    },

    renderRatesTable: function() {
      let self = this;

      return (
        <div className="rates-table">
          <h4 style={{marginTop: 15, marginBottom: 20}}>
            Rates
          </h4>

          <Table
            rows={this.state.project.rates}
            tableProps={{
              style: {
                maxWidth: '65em'
              }
            }}
            columns={[
              new Column({
                header: "From date",
                width: '31%',
                content: function(pr, i) {
                  return <DateValue
                    value={moment(pr.date, "YYYY-MM-DD")}
                    isEditable={this.props.expensesPermission}
                    onChange={(date) => actions.setRateField(i, 'date', date.format("YYYY-MM-DD"))}
                  />;
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: "Staff / Role",
                width: '16%',
                content: function(pr, i) {
                  return <StaffMemberRoleSelector
                    className="timesheet__staff-selector"
                    value={pr.item()}
                    allowNull={false}
                    style={{ width: '100%' }}
                    onChange={(item) => actions.setRateItem(i, item)}
                    nullText={'(Please Select a Staff Member / Role)'}
                  />
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: "Phase",
                width: '16%',
                content: function(pr, i) {
                  return <ProjectPhaseSelector
                    project={self.state.project}
                    phase={pr.phase()}
                    style={{ width: '100%' }}
                    onChange={(phase) => actions.setRatePhase(i, phase)}
                  />
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Cost rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.costRate} />
                </span>,
                width: '16%',
                content: function(pr, i) {
                  return <NumberInputContainer
                    value={pr.costRate}
                    isEditable={this.props.expensesPermission}
                    allowNull={true}
                    onChange={(costRate) => actions.setRateField(i, 'costRate', costRate)}
                  />;
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: <span>
                  {'Charge-out rate '}
                  <i className="fa fa-question-circle" data-elevio-inline={elevioIds.chargeOutRate} />
                </span>,
                width: '16%',
                content: function(pr, i) {
                  return <NumberInputContainer
                    value={pr.chargeOutRate}
                    isEditable={this.props.financialsPermission}
                    allowNull={true}
                    onChange={(chargeOutRate) => actions.setRateField(i, 'chargeOutRate', chargeOutRate)}
                  />;
                },
                // type: null means "not sortable"
                type: null,
              }),
              new Column({
                header: null,
                width: '5%',
                getCellProps: function() {
                  return {
                    style: {
                      padding: '0.6em 0.1em',
                      verticalAlign: 'middle'
                    }
                  };
                },
                content: function(pr, i) {
                  if (self.props.financialsPermission) {
                    return <SmallDeleteButton onClick={() => actions.deleteRate(i)} />;
                  }
                  else {
                    return null;
                  }
                },
                // type: null means "not sortable"
                type: null
              }),
            ]}
          />

          <div style={{marginTop: '1em'}}>
            <button
                className="btn btn-default add-pay-rate-button"
                onClick={() => actions.addRate()}>
              + Add Rates
            </button>
          </div>
        </div>
      );
    },

    renderPhases: function() {
      let self = this;
      return (
        <div className="project-form__phases-table">
          <h4 style={{fontSize: '1.8em', margin: '1em 0 0.5em 0.5em', display: 'inline-block'}}>
            Phases
          </h4>
          <div className="coincraft-table">
            <div className="flexbox-container coincraft-table-row header">
              <div className="coincraft-table-cell" style={{width:'2%'}}></div>
              <div className="coincraft-table-cell" style={{width:'8%'}}>Phase Code</div>
              <div className="coincraft-table-cell" style={{width:'14%'}}>Phase Name</div>
              <div className="coincraft-table-cell" style={{width:'11%'}}>Start Date</div>
              <div className="coincraft-table-cell" style={{width:'11%'}}>End Date</div>
              <div className="coincraft-table-cell" style={{width:'12%'}}>Duration</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Fee</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Expense Budget</div>
              <div className="coincraft-table-cell" style={{width:'8%'}}>Hours Budget</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Profit</div>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
            </div>
            {this.state.project.getVisiblePhases().map(function(phase) {
              return <div>
                <div className="flexbox-container coincraft-table-row project-form__phases-table__row" key={phase.uuid} data-phase-id={phase.id}>
                  <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'4%'}}>
                    <button onClick={() => actions.toggleStaffBudgets(phase)}>
                      <i
                        className={self.state.expandedPhases.includes(phase) ? "fa fa-chevron-down" : "fa fa-chevron-right" }
                        style={{margin: 0}} />
                    </button>
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input project-form__phases-table__job-code-cell"  style={{width:'6%'}}>
                    <TextValue
                      value={phase.jobCode}
                      isEditable={self.props.isAtLeastAdmin}
                      className="coincraft-table-cell__input"
                      onChange={(event) => actions.setProjectPhaseProp(phase, 'jobCode', event.target.value)}
                    />
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input project-form__phases-table__phase-name-cell"  style={{width:'14%'}}>
                    <TextValue
                      value={phase.name}
                      isEditable={self.props.isAtLeastAdmin}
                      className="coincraft-table-cell__input"
                      onChange={(event) => actions.setProjectPhaseProp(phase, 'name', event.target.value)}
                      isValid={!(phase.name === '' && (self.state.saveState === 'invalid' || self.state.saveState === 'failed'))}
                    />
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--date project-form__phases-table__start-date-cell"  style={{width:'11%'}}>
                    <DateValue
                      isValid={(phase.getStartDate()== null && phase.getEndDate() == null) || ((phase.getStartDate() != null && phase.getEndDate() != null) && (phase.getStartDate() <= phase.getEndDate()))}
                      value={phase.getStartDate()}
                      isEditable={self.props.isAtLeastPM}
                      onChange={(startDate) => actions.setProjectPhaseProp(phase, 'startDate', startDate)}
                    />
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--date project-form__phases-table__end-date-cell"  style={{width:'11%'}}>
                    <DateValue
                      isValid={(phase.getStartDate() == null && phase.getEndDate() == null) || ((phase.getStartDate() != null && phase.getEndDate() != null) && (phase.getStartDate() <= phase.getEndDate()))}
                      value={phase.getEndDate()}
                      isEditable={self.props.isAtLeastPM}
                      onChange={(endDate) => actions.setProjectPhaseProp(phase, 'endDate', endDate)}
                    />
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'4%'}}>
                      <NumberInputContainer
                        className="coincraft-table-cell__input"
                        value={phase.getDuration()}
                        isEditable={self.props.isAtLeastPM}
                        formatFunc={n => n != null ? formatNumber2(n) : ''}
                        onChange={(duration) => actions.setPhaseDuration(phase, duration)}
                      />
                  </div>
                  <div
                      className="coincraft-table-cell coincraft-table-cell--dropdown"
                      style={{width:'8%'}}>
                    {self.props.isAtLeastPM ?
                      <BasicMySelect2
                        value={phase.durationUnit}
                        onChange={(durationUnit) => actions.setPhaseDurationUnit(phase, durationUnit)}
                        options={[
                          {label: 'Days', value: 'days'},
                          {label: 'Weeks', value: 'weeks'},
                          {label: 'Months', value: 'months'}
                        ]}
                      />
                    : <span>{phase.durationUnit}</span>}
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input project-form__phases-table__income-cell"  style={{width:'10%'}}>
                    <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                      <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                        <NumberInputContainer
                          className="coincraft-table-cell__input"
                          value={phase.fee}
                          isEditable={self.props.isAtLeastAdmin && self.props.financialsPermission === OperationLevel.edit}
                          formatFunc={n => n != null ? formatNumber2(n) : ''}
                          onChange={(fee) => actions.setProjectPhaseProp(phase, 'fee', fee)}
                        />
                    </div>
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input project-form__phases-table__budget-cell"  style={{width:'10%'}}>
                    <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                      <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                        <NumberInputContainer
                          className="coincraft-table-cell__input"
                          value={phase.getBudget()}
                          isEditable={self.props.isAtLeastAdmin && self.props.expensesPermission === OperationLevel.edit}
                          formatFunc={n => n != null ? formatNumber2(n) : ''}
                          onChange={(budget) => actions.setProjectPhaseProp(phase, 'manualBudget', budget)}
                        />
                    </div>
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'8%'}}>
                    <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
                        <NumberInputContainer
                          className="coincraft-table-cell__input"
                          value={phase.manualHoursBudget}
                          isEditable={self.props.isAtLeastAdmin && self.props.hoursPermission === OperationLevel.edit}
                          formatFunc={n => n != null ? formatNumber2(n) : ''}
                          onChange={(budget) => actions.setProjectPhaseProp(phase, 'manualHoursBudget', budget)}
                        />
                    </div>
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--currency" style={{width:'10%', fontWeight: 'bold'}}>
                    {self.props.isAtLeastAdmin ?
                      <div className="coincraft-table-cell--currency__container">
                        <span className="coincraft-table-cell__value invoice__line-item__line-total">
                          ${formatNumber2(phase.fee - phase.getBudget())} ({formatNumber2(((phase.fee - phase.getBudget())/phase.fee)*100)}%)
                        </span>
                      </div>
                    : null}
                  </div>
                  <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'4%', position: 'relative'}}>
                    {self.props.isAtLeastAdmin ?
                      <SmallDeleteButton onClick={() => actions.deletePhase(phase)} className="phase-delete-button"/>
                    : null}
                    {self.state.cantDeletePopup != null && self.state.cantDeletePopup.id === phase.id ?
                      <ErrorPopover
                        orientation="right"
                        className="project-form__cant-delete-phase-because-dependencies"
                        style={{width: '18em', display: 'inline-block', position: 'absolute', right: '0', top: '100%', zIndex: 1, padding: '1em'}}
                        arrowProps={{style: {top: '1em'}}}
                        message={<div>
                          <p>
                            {`You can't delete this phase because there is already time
                            allocated to it.`}
                          </p>
                          <p>
                            If this is a problem or you have other feedback or questions,
                            you can {' '} <IntercomMessageLink label="chat with us" />.
                          </p>
                          <div>
                            <button className="btn btn-default" onClick={() => actions.clearCantDeletePopup()} style={{backgroundColor: 'white', marginTop: '1em'}}>
                              Okay
                            </button>
                          </div>
                        </div>}
                      />
                    : null}
                  </div>
                </div>
                {self.state.expandedPhases.includes(phase) ?
                  self.renderAllocations(phase)
                : null }
              </div>;
            })}
          </div>
          {self.props.isAtLeastAdmin ?
            <button
                className="btn btn-default add-phase-button"
                style={{margin: '0.5em'}}
                onClick={() => actions.addPhase(this.state.project)}>
            + Add Phase
            </button>
          : null}
        </div>
      );
    },

    renderAllocations: function(phase) {
      let self = this;
      let project = phase.project;
      let allStaff = organisationStore.getVisibleStaff();
      let selectedStaff = phase.staffMemberBudgetedHours.map(sbh => sbh.staffMember);
      let selectedRoles = phase.staffRoleBudgetedHours.map(sbh => sbh.staffRole);
      let availableStaff = allStaff.filter(s => {
        return !(selectedStaff.map(s => s.id).includes(s.id));
      })
      return (
        <div>
          <div className="coincraft-table-sub-section">
            {self.props.isAtLeastAdmin ?
              <div className="flexbox-container coincraft-table-row mini-header" style={{fontSize: '0.8em', fontWeight: 'bold'}}>
                <div className="coincraft-table-cell" style={{width:'4%'}}></div>
                <div className="coincraft-table-cell" style={{width:'20%'}}></div>
                <div className="coincraft-table-cell" style={{width:'11%'}}></div>
                <div className="coincraft-table-cell" style={{width:'11%'}}></div>
                <div className="coincraft-table-cell" style={{width:'12%'}}></div>
                <div className="coincraft-table-cell" style={{width:'10%', textAlign: 'center', position: 'relative', height: '3em'}}>
                  <VerticalLink
                    linked={phase.feeLinked}
                    paddingEms={0.5}
                    upLinkFunction={() => actions.linkUpPhaseFee(phase)}
                    downLinkFunction={() => actions.linkDownPhaseFee(phase)}
                    unlinkFunction={() => actions.unlinkPhaseFee(phase)} />
                </div>
                <div className="coincraft-table-cell" style={{width:'10%', textAlign: 'center', position: 'relative', height: '3em'}}>
                  <VerticalLink
                    linked={phase.expenseBudgetLinked}
                    paddingEms={0.5}
                    upLinkFunction={() => actions.linkUpPhaseExpenseBudget(phase)}
                    downLinkFunction={() => actions.linkDownPhaseExpenseBudget(phase)}
                    unlinkFunction={() => actions.unlinkPhaseExpenseBudget(phase)} />
                </div>
                <div className="coincraft-table-cell" style={{width:'8%', textAlign: 'center', position: 'relative', height: '3em'}}>
                  <VerticalLink
                    linked={phase.hoursBudgetLinked}
                    paddingEms={0.5}
                    upLinkFunction={() => actions.linkUpPhaseHoursBudget(phase)}
                    downLinkFunction={() => actions.linkDownPhaseHoursBudget(phase)}
                    unlinkFunction={() => actions.unlinkPhaseHoursBudget(phase)} />
                </div>
                <div className="coincraft-table-cell" style={{width:'10%'}}></div>
                <div className="coincraft-table-cell" style={{width:'4%'}}></div>
              </div>
            : null}
            <div className="flexbox-container coincraft-table-row mini-header" style={{fontSize: '0.85em', fontWeight: 'bold'}}>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
              <div className="coincraft-table-cell" style={{width:'20%'}}>Staff Member / Role</div>
              <div className="coincraft-table-cell" style={{width:'11%'}}></div>
              <div className="coincraft-table-cell" style={{width:'11%'}}></div>
              <div className="coincraft-table-cell" style={{width:'12%'}}></div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Charge Out</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}>Expense</div>
              <div className="coincraft-table-cell" style={{width:'8%'}}>Hours Budget</div>
              <div className="coincraft-table-cell" style={{width:'10%'}}></div>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
            </div>
            <div className="flexbox-container coincraft-table-row mini-header" style={{fontSize: '0.8em', fontWeight: 'bold'}}>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
              <div className="coincraft-table-cell" style={{width:'20%'}}></div>
              <div className="coincraft-table-cell" style={{width:'11%'}}></div>
              <div className="coincraft-table-cell" style={{width:'11%'}}></div>
              <div className="coincraft-table-cell" style={{width:'12%', textAlign: 'right', fontStyle: 'italic'}}>Total</div>
              <div className="coincraft-table-cell" style={{width:'10%', textAlign: 'right', fontStyle: 'italic'}}>
                ${formatNumber2(sum(phase.getCombinedBudgetedHours().map(budgetedHours => {
                  return budgetedHours.hours * project.getRateForPhase(budgetedHours.item, phase, 'chargeOutRate')
                })))}
              </div>
              <div className="coincraft-table-cell" style={{width:'10%', textAlign: 'right', fontStyle: 'italic'}}>
                ${formatNumber2(sum(phase.getCombinedBudgetedHours().map(budgetedHours => {
                  return budgetedHours.hours * project.getRateForPhase(budgetedHours.item, phase, 'costRate')
                })))}
              </div>
              <div className="coincraft-table-cell" style={{width:'8%', textAlign: 'right', fontStyle: 'italic'}}>
                {formatNumber2(sum(phase.getCombinedBudgetedHours().map(budgetedHours => {
                  return budgetedHours.hours
                })))}
              </div>
              <div className="coincraft-table-cell" style={{width:'10%'}}></div>
              <div className="coincraft-table-cell" style={{width:'4%'}}></div>
            </div>
            {phase.getCombinedBudgetedHours().map(function(budgetedHours) {
              return <div className="flexbox-container coincraft-table-row sub-row" key={budgetedHours.uuid}>
                <div className="coincraft-table-cell" style={{width:'4%'}} />
                <div className="coincraft-table-cell coincraft-table-cell--dropdown"  style={{width:'20%'}}>
                  {self.props.isAtLeastAdmin ?
                    <StaffMemberRoleSelector
                      className="timesheet__staff-selector"
                      value={budgetedHours.item}
                      allowNull={false}
                      onChange={(item) => actions.changePhaseBudgetedHoursItem(phase, item, budgetedHours)}
                      nullText={'(Please Select a Staff Member / Role)'}
                      disabledStaff={selectedStaff}
                      disabledRoles={selectedRoles}
                    />
                  : <span>{budgetedHours.label}</span>}
                </div>
                <div className="coincraft-table-cell"  style={{width:'11%'}}>
                  {/*<DateValue
                    style={phase.startDate != null && phase.endDate != null && phase.startDate > phase.endDate ?
                      {backgroundColor: '#d9534f'}
                    : null}
                    value={phase.getStartDate()}
                    isEditable={self.props.isEditable}
                    onChange={(startDate) => actions.setProjectPhaseProp(phase, 'startDate', startDate)}
                  />*/}
                </div>
                <div className="coincraft-table-cell"  style={{width:'11%'}}>
                  {/*<DateValue
                    style={phase.startDate != null && phase.endDate != null && phase.startDate > phase.endDate ?
                      {backgroundColor: '#d9534f'}
                    : null}
                    value={phase.getEndDate()}
                    isEditable={self.props.isEditable}
                    onChange={(endDate) => actions.setProjectPhaseProp(phase, 'endDate', endDate)}
                  />*/}
                </div>
                <div className="coincraft-table-cell"  style={{width:'4%'}}>
                    {/*<NumberInputContainer
                      className="coincraft-table-cell__input"
                      value={phase.getDuration()}
                      isEditable={self.props.isEditable}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={(duration) => actions.setPhaseDuration(phase, duration)}
                    />*/}
                </div>
                <div
                    className="coincraft-table-cell"
                    style={{width:'8%'}}>
                  {/*<BasicMySelect2
                    value={phase.durationUnit}
                    onChange={(durationUnit) => actions.setPhaseDurationUnit(phase, durationUnit)}
                    options={[
                      {label: 'Days', value: 'days'},
                      {label: 'Weeks', value: 'weeks'},
                      {label: 'Months', value: 'months'}
                    ]}
                  />*/}
              </div>
                <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'10%', textAlign: 'right'}}>
                  <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                    <NumberInputContainer
                      style={{width: '8em'}}
                      className="coincraft-table-cell__input"
                      value={budgetedHours.hours * project.getRateForPhase(budgetedHours.item, phase, 'chargeOutRate')}
                      isEditable={self.props.isAtLeastAdmin && self.props.financialsPermission === OperationLevel.edit}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={(chargeOut) => actions.setPhaseBudgetedHoursHours(phase, budgetedHours.item, (chargeOut/project.getRateForPhase(budgetedHours.item, phase, 'chargeOutRate')))}
                    />
                </div>
                <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'10%', textAlign: 'right'}}>
                  <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
                    <NumberInputContainer
                      style={{width: '8em'}}
                      className="coincraft-table-cell__input"
                      value={budgetedHours.hours * project.getRateForPhase(budgetedHours.item, phase, 'costRate')}
                      isEditable={self.props.isAtLeastAdmin && self.props.expensesPermission === OperationLevel.edit}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={(expense) => actions.setPhaseBudgetedHoursHours(phase, budgetedHours.item, (expense/project.getRateForPhase(budgetedHours.item, phase, 'costRate')))}
                    />
                </div>
                <div className="coincraft-table-cell coincraft-table-cell--input"  style={{width:'8%', textAlign: 'right'}}>
                    <NumberInputContainer
                      style={{width: '8em'}}
                      className="coincraft-table-cell__input"
                      value={budgetedHours.hours}
                      isEditable={self.props.isAtLeastAdmin && self.props.hoursPermission === OperationLevel.edit}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={(hours) => actions.setPhaseBudgetedHoursHours(phase, budgetedHours.item, hours)}
                    />
                </div>
                <div className="coincraft-table-cell coincraft-table-cell--currency" style={{width:'10%', fontWeight: 'bold'}}>
                    <div className="coincraft-table-cell--currency__container">
                      <span className="coincraft-table-cell__value">
                      </span>
                    </div>
                </div>
                <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'4%'}}>
                  {self.props.isAtLeastAdmin ?
                    <SmallDeleteButton onClick={() => actions.deletePhaseBudgetedHours(phase, budgetedHours.item)} />
                  : null}
                </div>
              </div>;
            })}
            {self.props.isAtLeastAdmin ?
              <div className="flexbox-container coincraft-table-row sub-row">
                <div className="coincraft-table-cell" style={{width:'24%'}}>
                  <button
                      className="btn btn-default"
                      style={{margin: '0 2em', fontSize: '0.9em', padding: '0.3em 3em', backgroundColor: '#fcfcfc'}}
                      onClick={() => actions.addNewPhaseBudgetedHours(phase)}
                      disabled={availableStaff.length === 0}>
                  + Add Resource
                  </button>
                </div>
                <div className="coincraft-table-cell" style={{width:'11%'}} />
                <div className="coincraft-table-cell" style={{width:'11%'}} />
                <div className="coincraft-table-cell" style={{width:'12%'}} />
                <div className="coincraft-table-cell" style={{width:'10%'}} />
                <div className="coincraft-table-cell" style={{width:'10%'}} />
                <div className="coincraft-table-cell" style={{width:'8%'}} />
                <div className="coincraft-table-cell" style={{width:'10%'}} />
                <div className="coincraft-table-cell" style={{width:'4%'}} />
              </div>
            : null}
          </div>
        </div>
      );
    },

    renderExpenses: function() {
      let self = this;
      return <ProjectExpenses
        expenses={this.state.project.expenses}
        project={this.state.project}
        isEditable={this.props.isEditable && this.props.expensesPermission === OperationLevel.edit}
        onChange={(expenses) => actions.setProjectProp('expenses', expenses)}
      />;
    },

    renderMilestones: function() {

      return (
        <div>
          <h4 style={{fontSize: '1.8em', margin: '1em 0 0.5em 0.5em', display: 'inline-block'}}>
            Billing Milestones
          </h4>
          {this.state.project.getVisiblePhases().map(phase =>
            <PhaseMilestonesList phase={phase} />
          )}
        </div>
      );
    },

    renderNotes: function() {
      return (
        <div>
          <ProjectNoteList
            project={this.state.project}
            editable={this.props.isAtLeastPM}
          />
        </div>
      );
    },

    renderHistory: function () {
      return (
        <div>
          <ProjectHistoryList
            project={this.state.project}
          />
        </div>
      );
    },



    renderStatus: function () {
      return (
        <div>
          <ProjectStatusList
            project={this.state.project}
          />
        </div>
      );
    },

    renderAllocationSync() {
      if (this.props.isEditable && _.include(['outOfSync', 'synced'], this.state.allocationSyncState)) {
        return <div className="form-group">
          <div style={labelStyle} />
          <div>
            {this.state.allocationSyncState === 'outOfSync' ?
              <InfoAlert
                  // Make it a bit quieter than the regular alert-info
                  style={{backgroundColor: '#e7f6fd'}}>
                <p>
                  Your staff allocation dates don't match your phase dates. That's fine; you can leave
                  everything as it is, but if you like we can stretch your allocations to match
                  your phase dates if you like. The number of hours allocated will stay the same.
                </p>
                <p>
                  <button
                      className="btn btn-default btn-sm"
                      onClick={this.handleSyncButtonClick}>
                    Sync allocation dates with phase dates
                  </button>
                </p>
              </InfoAlert>
            : this.state.allocationSyncState === 'synced' ?
              <SuccessAlert>
                <i className="fa fa-check" />
                Allocations have been synced to phase dates.
              </SuccessAlert>
            : null}
          </div>
        </div>;
      }
      else {
        return null;
      }
    },

    handleSyncButtonClick: function() {
      actions.syncAllocations();
    },

    handleSaveButtonClick: function() {
      actions.save();
    },

    handleCancel: function() {
      actions.cancel();
    },
  })
);
