import _ from 'underscore';
import { mergeColumnDefinitions } from '../table.js';
import { jsonHttp } from '../jsonHttp.js';
import { generateUUID } from '../utils.js';
import { fieldTypeToMatcherTypeLookup } from '../widgets/filterwidget.js';
import { dispatcher, registerActions, handleAction } from "../coincraftFlux.js";
import { setColumnSelection } from './ColumnSelector.js';
import { router } from '../router.js';
import { organisationStore } from "../organisation.js";
import { iterRowDescendants, isRowChecked } from "../groupRows.js";
import { AjaxOperation } from '../AjaxOperation.js';
import apiRequest from '../apiRequest.js';


export const reportActionDefinitions = [
  {action: 'toggleExpanded', args: []},
  {action: 'setSortBy', args: ['columnIdentifier', 'direction']},
  {action: 'selectReport', args: ['report']},
  {action: 'selectDateRange', args: ['dateRange']},
  {action: 'selectGroupBy', args: ['groupBy']},
  {action: 'toggleGroupExpanded', args: ['stack', 'item']},
  {action: 'toggleRowCheck', args: ['row']},
  {action: 'addFilter', args: []},
  {action: 'deleteFilter', args: ['filterIndex']},
  {action: 'selectColumnById', args: ['filterIndex', 'prop']},
  {action: 'setFilterValue', args: ['filterIndex', 'value']},
  {action: 'setFilterOperation', args: ['filterIndex', 'operation']},
  {action: 'setFilterCustomRange', args: ['filterIndex', 'customRange']},
  {action: 'setColumnSelected', args: ['columnId', 'isSelected']},
  {action: 'clickSaveReportButton', args: [], callback: 'default'},
  {action: 'saveReport', args: ['reportName', 'modal']},
  {action: 'saveReportSuccess', args: ['modal', 'data']},
  {action: 'saveReportSuccessTimeout', args: []},
  {action: 'saveReportFailure', args: ['modal']},
  {action: 'renameCurrentReport', args: []},
  {action: 'setActionsMenuExpanded', args: ['isExpanded']},
  {action: 'setSaveMenuExpanded', args: ['isExpanded']},
  {action: 'duplicateCurrentReport', args: []},
  {action: 'createDuplicateReportSubmit', args: ['reportName', 'modal']},
  {action: 'deleteCurrentReport', args: []},
  {action: 'deleteCurrentReportSubmit', args: ['modal']},
  {action: 'deleteCurrentReportSuccess', args: ['modal']},
  {action: 'clickSelectColumns', args: []},
  {action: 'saveColumnSelection', args: ['selectedColumns', 'modal']},
  {action: 'setFilterText', args: ['text']},
  {action: 'toggleMenu', args: []},
  {action: 'openExportModal', args: []},
  {action: 'closeModal', args: ['modal']},

  // Refresh from the dropdown menu
  {action: 'refresh', args: []},

  // "Refresh table" button
  {action: 'refreshTable', args: []},
];

export function isDataChangedAction(action) {
  return _.include([
    'report/setReport',
    'report/selectDateRange',
    'report/setSelectedColumnIds',
    'report/saveColumnSelection',
  ], action.type);
}


export const ReportStore = class {
			constructor({ path, columns, isColumnVisible }) {
				/**
				 * If specified, `isColumnVisible` is a function that takes a column id and returns true
				 * if the user can view that column, or false otherwise.
				 */
				let self = this;

				this.path = path;
				this.columns = columns;
				this.isColumnVisible = isColumnVisible || (id => true);

				this.save = new AjaxOperation({
					emitChanged: function() {
						let rootStore = require("../RootStore").rootStore;
						rootStore.emitChanged();
					}
				});

				this.columnLookup = {};
				this.columns.forEach(function(c) {
					self.columnLookup[c.identifier] = c;
				});

				this.expandedGroups = [];
				this.selectedItems = [];

				this.isActionsMenuExpanded = false;
				this.isSaveMenuExpanded = false;
				this.modals = [];
				this.isExpanded = false;
				this.isMenuExpanded = false;
				this.isDirty = false;

				this.filterText = "";

				this.sortBy = null;

				this.actionDefinitions = reportActionDefinitions;
				this.actions = registerActions(
					"report",
					reportActionDefinitions,
					dispatcher,
					this.path
				);
			}

			handle(action) {
				handleAction(action, this);
			}

			get saveState() {
				return this.save.state;
			}

			getColumnById(id) {
				return this.columnLookup[id];
			}

			toggleExpanded() {
				this.isExpanded = !this.isExpanded;
			}

			setSortBy(columnIdentifier, direction) {
				this.sortBy = {
					columnIdentifier: columnIdentifier,
					direction: direction
				};
			}

			setReport(report) {
				this.report = report;
			}

			selectReport(report) {
				router.history.push(report.getPath());
			}

			selectDateRange(dateRange) {
				this.report = this.report.setDateRange(dateRange);
				this.isDirty = true;
			}

			selectGroupBy(groupBy) {
				this.report = this.report.setGroupBy(groupBy);
				this.isDirty = true;
			}

			toggleGroupExpanded(stack, item) {
				const groupStack = this.report.groupBy
					.slice(0, stack.length + 1)
					.map(fieldName => item[fieldName]);
				const index = _.findIndex(this.expandedGroups, ei =>
					_.isEqual(ei, groupStack)
				);

				if (index !== -1) {
					this.expandedGroups = this.expandedGroups.filter(
						(_, i) => i !== index
					);
				} else {
					this.expandedGroups = [...this.expandedGroups, groupStack];
				}
			}

			toggleRowCheck(row) {
				this.setRowChecked(row, !isRowChecked(row, this.selectedItems));
			}

			setRowChecked(row, value) {
				const descendants = Array.from(iterRowDescendants(row));
				if (value) {
					this.selectedItems = _.union(
						this.selectedItems,
						descendants
					);
				} else {
					this.selectedItems = _.difference(
						this.selectedItems,
						descendants
					);
				}
			}

			clickSaveReportButton() {
				if (this.report.id != null) {
					this.saveReport();
				} else {
					this.openSaveReportModal();
				}
			}

			saveReport(reportName = null, modal = null) {
				let self = this;

				if (reportName != null) {
					this.report = this.report.set("name", reportName);
				}

				this.save.execute(
					apiRequest({
						url: `/organisation/current/reports/${this.report.uuid}`,
						method: "post",
						data: {report: self.report.serialize()},
						success: data => self.actions.saveReportSuccess(modal, data),
						error: data => self.actions.saveReportFailure(modal)
					})
				);
			}

			saveReportSuccess(modal = null, data) {
				this.report = this.report.set("id", data.reportId);
				this.isDirty = false;
				if (modal != null) {
					this.closeModal(modal);
				}

				organisationStore.updateReport(this.report);
				router.history.replace({ pathname: this.report.getPath() });
			}

			saveReportFailure(modal = null) {
				alert(
					"There was a problem saving this report. Try again, or let us know if the problem persists."
				);
				this.reportSaveState = null;
			}

			openSaveReportModal() {
				this.modals.push({ type: "saveReport" });
			}

			duplicateCurrentReport() {
				this.isActionsMenuExpanded = false;
				this.isSaveMenuExpanded = false;
				this.modals.push({ type: "duplicateReport" });
			}

			createDuplicateReportSubmit(reportName, modal) {
				this.report = this.report.merge({
					id: null,
					uuid: generateUUID(),
					name: reportName,
					isDefault: false
				});
				this.saveReport(undefined, modal);
			}

			getItemMatcher(report) {
				return report.getItemMatcher(this.columns);
			}

			setSelectedColumnIds(selectedColumns) {
				this.report = this.report.set("columns", selectedColumns);
				this.isDirty = true;
			}

			setColumnSelected(columnId, isSelected) {
				this.setSelectedColumnIds(
					setColumnSelection(
						this.report.columns,
						columnId,
						isSelected
					)
				);
			}

			selectColumnById(filterIndex, id) {
				let column = _.find(this.columns, f => f.identifier === id);
				let matcherCreator = fieldTypeToMatcherTypeLookup[column.type];
				if (matcherCreator == null) {
					throw new Error(`Unknown type: ${column.type}`);
				}

				this.report = this.report.setFilterColumn(
					filterIndex,
					column,
					matcherCreator()
				);
			}

			addFilter() {
				this.report = this.report.addFilter();
				this.isDirty = true;
			}

			deleteFilter(filterIndex) {
				this.report = this.report.deleteFilter(filterIndex);
				this.isDirty = true;
			}

			setFilterValue(filterIndex, value) {
				this.report = this.report.setFilterValue(filterIndex, value);
				this.isDirty = true;
			}

			setFilterOperation(filterIndex, operation) {
				this.report = this.report.setFilterOperation(
					filterIndex,
					operation
				);
				this.isDirty = true;
			}

			setFilterCustomRange(filterIndex, customRange) {
				this.report = this.report.setFilterCustomRange(
					filterIndex,
					customRange
				);
				this.isDirty = true;
			}

			renameCurrentReport() {
				this.isActionsMenuExpanded = false;
				this.isSaveMenuExpanded = false;
				this.modals.push({ type: "renameReport" });
			}

			setActionsMenuExpanded(isExpanded) {
				this.isActionsMenuExpanded = isExpanded;
			}

			setSaveMenuExpanded(isExpanded) {
				this.isSaveMenuExpanded = isExpanded;
			}

			deleteCurrentReport() {
				this.isActionsMenuExpanded = false;
				this.isSaveMenuExpanded = false;
				this.modals.push({ type: "deleteReport" });
			}

			deleteCurrentReportSubmit(modal) {
				let self = this;
				apiRequest({
					url: `/organisation/current/reports/${this.report.uuid}`,
					method: "delete",
					success: data =>
						self.actions.deleteCurrentReportSuccess(modal),
					error: data => alert(
							"There was a problem deleting this report. Try again, or let us know if the problem persists."
						)
				});
			}

			deleteCurrentReportSuccess(modal) {
				organisationStore.deleteReport(this.report);
				this.closeModal(modal);
				router.history.replace({ pathname: "/dashboard/projects" });
			}

			clickSelectColumns() {
				this.modals.push({ type: "columnSelection" });
			}

			saveColumnSelection(selectedColumnIds, modal) {
				this.report = this.report.set("columns", selectedColumnIds);
				this.closeModal(modal);
				this.isDirty = true;
			}

			setFilterText(text) {
				this.filterText = text;
			}

			toggleMenu() {
				this.isMenuExpanded = !this.isMenuExpanded;
			}

			openExportModal() {
				this.modals.push({ type: "export" });
			}

			refresh() {
				organisationStore.refreshOrganisation();
				this.toggleMenu();
			}

			refreshTable() {
				// Assume parent has refreshed the data.
				this.isDirty = false;
			}

			getCsvString(
				matchingRows,
				columnPresentation,
				exportChildData = false
			) {
				const rowData = exportChildData
					? _.flatten(
							matchingRows.map(r => r.getPhasesForProjectTable())
					  )
					: matchingRows;
				const selectedColumns = mergeColumnDefinitions(
					this.report.columns.map(c => this.getColumnById(c)),
					columnPresentation
				);
				const sharedData = selectedColumns.map(c => c.sharedData());

				const rows = [
					selectedColumns.map(c => c.name).join(","),
					...rowData.map(function(row, i) {
						return selectedColumns
							.map(function(column, j) {
								let val = column.exportCSVText(
									row,
									i,
									[],
									column.dataOnly(row, sharedData[j]),
									null
								);
								if (
									exportChildData &&
									column.identifier === "name"
								) {
									val = `${row.project.getTitle()}: ${row.getTitle()}`;
								}
								return '"' + val + '"';
							})
							.join(",");
					})
				];
				return rows.join("\n");
			}

			getMatchingItems(items) {
				let self = this;
				const matcher = this.getItemMatcher(this.report);

				return items.filter(function(item) {
					return (
						(self.filterText == null ||
							self.filterText === "" ||
							item.matchesSearch(self.filterText)) &&
						matcher(item)
					);
				});
			}

			hasSelectedColumnWithRequirement(requirement) {
				function hasRequirement(column) {
					return _.include(column.requires, requirement);
				}

				return (
					this.report.columns.some(c =>
						hasRequirement(this.getColumnById(c))
					) ||
					this.report.filters.some(f =>
						hasRequirement(this.getColumnById(f.get("columnId")))
					)
				);
			}

			closeModal(modal) {
				this.modals = _.without(this.modals, modal);
			}
		};


ReportStore.refreshTableEvent = 'refreshTable';


export function applyTimesheetCacheRequirement(columns, store) {
  function timesheetData() {
    return store.timesheetDataCache.getDateRange(store.dateRange);
  }

  function requiresTimesheetCache(func) {
    return function() {
      if (timesheetData() == null) {
        return '?';
      }
      else if (timesheetData() === 'loading') {
        return 'loading';
      }
      else {
        return func(...arguments);
      }
    };
  }

  for (let c of columns) {
    if (_.include(c.requires, 'timesheetCache')) {
      if (c.content != null) {
        c.content = requiresTimesheetCache(c.content);
      }
      if (c._data != null) {
        c._data = requiresTimesheetCache(c._data);
      }
      if (c.sharedData != null) {
        c.sharedData = requiresTimesheetCache(c.sharedData);
      }
    }
  }
}
