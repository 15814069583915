import React from 'react';
import CreateReactClass from 'create-react-class';
import { AccountingSystem } from './base.js';
import { MyobSettings } from './MyobSettings.js';
import { FormSection, FormRow, FormLabel } from '../../forms.js';
import { invoiceActions } from '../InvoicePageStore.js';
import { ModalFooter } from '../../modal.js';
import PropTypes from "prop-types";


export const MyobAccountRightAccountingSystem = class extends AccountingSystem {
  constructor(options) {
    super(options);
    this.identifier = "myob-accountright";
    this.name = "MYOB AccountRight";
    this.shortName = "MYOB";
    this.invoiceNumberMaxLength = 8; // Limit enforced by MYOB
  }

  getDefaultSettings() {
    return {
      salesAccount: null,
      taxOnTaxType: null,
      taxOffTaxType: null
    };
  }

  requiresContact() {
    return true;
  }

  canSaveSettings(settings) {
    return (settings != null
      && settings.salesAccount != null
      && settings.taxOnTaxType != null
      && settings.taxOffTaxType != null
    );
  }

  getSettingsComponent() {
    return MyobAccountRightSettings;
  }

  getLoginComponent() {
    return <div style={{padding: '0 2em'}}>
      <button onClick={() => invoiceActions.openMyobAccountRightAuthPopup()} className="btn btn-default btn-sm">
        Set username / password for company file...
      </button>
    </div>;
  }
}


var MyobAccountRightSettings = CreateReactClass({
  render: function() {
    return <div>
      <MyobSettings {...this.props} />
    </div>;
  }
});


export var MyobAccountRightAuthForm = CreateReactClass({
  propTypes: {
    initialUsername: PropTypes.string,
    initialPassword: PropTypes.string
  },

  getInitialState: function() {
    return {
      username: this.props.initialUsername || '',
      password: this.props.initialPassword || ''
    };
  },

  render: function() {
    return <div>
      <div style={{padding: '1em'}}>
        <p>
          Please enter your AccountRight company file user ID and password.
        </p>
        <FormSection>
          <FormRow>
            <FormLabel style={{width: '7em'}}>
              Username
            </FormLabel>
            <input
              type="text"
              value={this.state.username}
              onChange={(event) => this.setState({username: event.target.value})}
            />
          </FormRow>
          <FormRow>
            <FormLabel style={{width: '7em'}}>
              Password
            </FormLabel>
            <input
              type="password"
              value={this.state.password}
              onChange={(event) => this.setState({password: event.target.value})}
            />
          </FormRow>
        </FormSection>
      </div>
      <ModalFooter
        acceptButtonText="Save"
        onSubmit={() => this.props.onSubmit(this.state.username, this.state.password)}
        onCancel={this.props.onDismiss}
      />
    </div>;
  }
});
