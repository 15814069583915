import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { ModalFooter } from "../modal.js";
import { ProjectSelector, DateValue } from "../widgets.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { rootStore } from "../RootStore.js";
import {
	descriptionTemplatesStore,
	actions
} from "./descriptionTemplatesStore.js";
import { BasicMySelect2 } from "../widgets/generic.js";

export var DecriptionTemplatesModal = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      return {
			templates: descriptionTemplatesStore.templates,
			selectedTemplate: descriptionTemplatesStore.selectedTemplate,
			state: descriptionTemplatesStore.state,
			saveState: descriptionTemplatesStore.saveState,
			deleteState: descriptionTemplatesStore.deleteState
		};
    })
  ],

	render: function() {
		return (
			<div className="description-template-modal">
				<div style={{ padding: "2em" }}>
					<div className="flexbox-container">
						{this.state.state === "display" && (
							<BasicMySelect2
								// style={{marginLeft: '0.5em'}}
								outerClassName="flex-1-1-auto"
								value={this.state.selectedTemplate}
								onChange={template =>
									actions.selectTemplate(template)
								}
								options={this.state.templates.map(t => ({
									label: `${t.name}`,
									value: t
								}))}
								placeholder={"Select Template..."}
							/>
						)}
						{this.state.state === "edit" && (
							<input
								style={{
									padding: "0.5em 1em",
									fontSize: "1em",
									borderRadius: "0.5em",
									border: "none",
									border: "1px solid #ccc"
								}}
								className="flex-1-1-auto"
								value={this.state.selectedTemplate.name}
								onChange={event =>
									actions.changeTemplateName(
										event.target.value
									)
								}
							/>
						)}
						{this.state.selectedTemplate &&
							this.state.state === "display" && (
								<button
									className="flex-0-0-auto btn btn-primary"
									style={{ margin: "0 0 0 0.5em" }}
									onClick={e => actions.editTemplate()}
								>
									Edit
								</button>
							)}
						{this.state.state === "edit" && (
							<button
								className="flex-0-0-auto btn btn-primary"
								style={{ margin: "0 0 0 0.5em" }}
								onClick={e => actions.saveTemplate()}
							>
								{this.state.saveState ? "Saving..." : "Save"}
							</button>
						)}
						{this.state.state === "display" && (
							<button
								className="flex-0-0-auto btn btn-default"
								style={{ margin: "0 0 0 0.5em" }}
								onClick={e => actions.newTemplate()}
							>
								+ New
							</button>
						)}
						{this.state.state === "edit" && (
							<button
								className="flex-0-0-auto btn btn-default"
								style={{ margin: "0 0 0 0.5em" }}
								onClick={e => actions.cancelEditTemplate()}
							>
								Cancel
							</button>
						)}
					</div>
					{this.state.selectedTemplate &&
						this.state.state === "display" && (
							<p
								style={{
									width: "100%",
									marginTop: "1em",
									padding: "1em"
								}}
							>
								{`"  ${this.state.selectedTemplate.description}  "`}
							</p>
						)}
					{this.state.selectedTemplate &&
						this.state.state === "edit" && (
							<textarea
								rows={8}
								style={{
									width: "100%",
									marginTop: "1em",
									padding: "1em",
									borderRadius: "0.5em"
								}}
								value={this.state.selectedTemplate.description}
								disabled={this.state.state === "display"}
								onChange={event =>
									actions.changeTemplateDescription(
										event.target.value
									)
								}
							/>
						)}
				</div>
				<div
					className="modal-footer flexbox-container flex-space-between"
					style={{
						padding: "1em",
						textAlign: "right",
						borderTop: "solid 1px #ccc"
					}}
				>
					<div className="flex-0-0-auto">
						{this.state.selectedTemplate?.id &&
							this.state.state === "edit" && (
								<button
									className="flex-0-0-auto btn btn-danger"
									onClick={e => actions.deleteTemplate()}
								>
									{this.state.deleteState ? "Deleting..." : "Delete"}
								</button>
							)}
					</div>
					<div className="flex-0-0-auto">
						<button
							className="flex-0-0-auto btn btn-default"
							onClick={this.props.onCancel}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		);
	}
});
