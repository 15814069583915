import _ from 'underscore';
import { organisationStore } from "../organisation.js";
import { StoreBase, dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import Immutable from 'immutable';
import { StaffRole } from '../models/staffRole.js';
import { router } from '../router.js';



const staffRoleListActionDefinitions = [
  {action: 'newStaffRole', args: []},
  {action: 'openStaffRole', args: ['staffRole']},
];

export const actions = registerActions("staff-role-list", staffRoleListActionDefinitions, dispatcher);

class StaffRoleListStore {
  constructor() {
    this.path = "staff-role-list";
    this.staffRoles = organisationStore ? organisationStore.staffRoles : [];
    this.actionDefinitions = staffRoleListActionDefinitions;
  }

  handle(action) {
    handleAction(action, this);
  }

  newStaffRole() {
    router.history.push('/dashboard/staff-roles/new');
  }

  openStaffRole(staffRole) {
    router.history.push(`/dashboard/staff-roles/${staffRole.id}`);
  }

}

export var staffRoleListStore = new StaffRoleListStore();
