import { dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import { Enum } from '../enum.js';
import { jsonHttp, jsonHttp2 } from '../jsonHttp.js';
import { organisationStore } from '../organisation.js';
import { AjaxOperation2 } from '../AjaxOperation.js';
import { ConnectionState } from '../invoices/ConnectionState.js';
import { Contact } from '../models/Contact.js';
import apiRequest from '../apiRequest.js';
export { Contact } from "../models/Contact.js";


const contactsPageActionDefinitions = [
  {action: 'newContact', args: []},
  {action: 'editContact', args: ['contact']},
  {action: 'openImportContacts', args: []},
  {action: 'closeImportContacts', args: []},
];

export const contactsPageActions = registerActions("contacts-page", contactsPageActionDefinitions, dispatcher);

const contactFormActionDefinitions = [
  {action: 'setStage', args: ['contactType']},
  {action: 'back', args: []},
  {action: 'cancel', args: []},
  {action: 'save', args: []},
  {action: 'saveSuccess', args: ['contactData', 'isNew']},
  {action: 'saveFailure', args: []},

  {action: 'setContactFirstName', args: ['firstName']},
  {action: 'setContactLastName', args: ['lastName']},
  {action: 'setContactOrganisationName', args: ['organisationName']},
  {action: 'setContactNotes', args: ['notes']},

  {action: 'selectAccountingSystemContact', args: ['contact']},
  {action: 'retrieveContacts', args: []},
  {action: 'selectContactForImport', args: ['contact', 'value']},
  {action: 'importSelectedContacts', args: []},

  {action: 'deleteContact', args: []},
  {action: 'deleteContactSuccess', args: []},
  {action: 'deleteContactFailure', args: ['error']},
];


export const Stage = Enum([
  'selectOperation',
  'importContacts',
  'selectContact',
  'editContact'
]);


export const ContactFormStore = class {
  constructor(path, contact = null) {
    this.path = path;

    this.stage = Stage.selectOperation;

    // Contact for the 'Coincraft contact' page
    this.contact = null;

    // Selected contact for the 'Accounting system contact' page
    this.accountingSystemContact = null;

    this.saveState = null;

    this.importContacts = new AjaxOperation2(this.path + '/import-contacts', {resetOnSuccess: false});
    this._retrieveContacts = new ContactRetrievalOperation(this.path + '/retrieve-contacts', {resetOnSuccess: false});

    this.stores = {
      'import-contacts': this.importContacts,
      'retrieve-contacts': this._retrieveContacts
    };

    this.retrievedContacts = null;
    this.selectedRetrievedContacts = null;

    this.isOpen = false;

    this.actionDefinitions = contactFormActionDefinitions;
  }

  reset() {
    this._retrieveContacts.reset();
    this.importContacts.reset();
    this.retrievedContacts = null;
    this.selectedRetrievedContacts = null;
  }

  handle(action) {
    if (action.path === this.path + '/import-contacts') {
      this.importContacts.handle(action);
      if (action.type === 'ajax/success') {
        if (this.context === 'contacts') {
          this.close();
        }
        else {
          this.setStage(Stage.selectContact);
        }
      }
    }
    else if (action.path === this.path + '/retrieve-contacts') {
      this._retrieveContacts.handle(action);
      if (action.type === 'ajax/success') {
        this.retrievedContacts = action.data.objects.Contact.map(d => new Contact(d));
        this.selectedRetrievedContacts = new Set();
      }
    }
    else {
      return handleAction(action, this);
    }
  }

  newContact(context = null) {
    this.reset();
    this.contact = new Contact();
    this.saveState = null;
    this.stage = Stage.selectOperation;
    this.isOpen = true;
    contactsPageStore._reset();
    this.context = context;
  }

  editContact(contact) {
    this.reset();
    this.contact = contact.copy();
    this.stage = Stage.editContact;
    this.saveState = null;
    this.isOpen = true;
  }

  openToImportContacts() {
    this.reset();
    this.context = 'contacts';
    this.stage = Stage.importContacts;
    this.isOpen = true;
  }

  selectAccountingSystemContact(contact) {
    this.accountingSystemContact = contact;
  }

  setStage(stage) {
    this.stage = stage;
    if (stage === Stage.selectContact && this.selectedRetrievedContacts.size === 1) {
      this.accountingSystemContact = Array.from(this.selectedRetrievedContacts)[0];
    }
  }

  get buttons() {
    let buttons = new Set();
    buttons.add('cancel');
    if (this.stage === Stage.importContacts) {
      buttons.add('back');
      if (this.retrievedContacts != null && this.retrievedContacts.length > 0) {
        buttons.add('import');
      }
    }
    else if (this.stage === Stage.editContact) {
      if (this.contact == null || this.contact.id == null) {
        buttons.add('back');
      }
      buttons.add('save');
      if (this.contact != null && this.contact.id > 0) {
        buttons.add('delete');
      }
    }
    else if (this.stage === Stage.selectContact) {
      buttons.add('save');
    }
    return buttons;
  }

  retrieveContacts() {
    const ac = organisationStore.invoiceSettingsState.accountingSystem;
    this._retrieveContacts.execute(
    apiRequest({
      url: `/organisation/current/${ac.identifier}/contacts`,
      method: "get"
    })
	);
  }

  back() {
    if (this.stage === Stage.selectContact) {
      this.stage = Stage.importContacts;
    }
    else {
      this.stage = Stage.selectOperation;
    }
  }

  _setFieldValue(fieldName, value) {
    this.contact[fieldName] = value;
  }

  setContactFirstName(firstName) {
    this._setFieldValue('firstName', firstName);
  }

  setContactLastName(lastName) {
    this._setFieldValue('lastName', lastName);
  }

  setContactOrganisationName(organisationName) {
    this._setFieldValue('contactOrganisationName', organisationName);
  }

  setContactNotes(notes) {
    this._setFieldValue('notes', notes);
  }

  canSave() {
    if (this.stage === Stage.selectContact) {
      return this.accountingSystemContact != null;
    }
    else if (this.stage === Stage.editContact) {
      return (this.contact != null
        && this.contact.accountingSystemId == null
        && (this.contact.firstName !== ''
          || this.contact.lastName !== ''
          || this.contact.contactOrganisationName !== ''
        )
      );
    }
    else {
      return false;
    }
  }

  save() {
    let self = this;
    let contact;
    if (this.stage === Stage.selectContact) {
      contact = this.accountingSystemContact;
    }
    else {
      contact = this.contact;
    }
    let isNew = (contact.id == null);

    this.saveState = 'saving';
    apiRequest({
      path: `/organisation/${organisationStore.id}/contact/${contact.id ||
        ""}`,
      method: "post",
      data: {
        contact: contact.serialize()
      },
      success: data => {
        dispatcher.dispatch({
          type: "contact/saveSuccess",
          path: self.path,
          contactData: data.objects.Contact[0],
          isNew: isNew
        });;
      },
      error: data => {
        dispatcher.dispatch({
        type: "contact/saveFailure",
        path: self.path
      });
      }
    });
  }

  saveSuccess(contactData) {
    this.isOpen = false;
    let contact = new Contact(contactData);
    organisationStore.contactSaveSuccess(contact);
    return contact;
  }

  saveFailure() {
    this.saveState = 'error';
  }

  selectContactForImport(contact, value) {
    if (value) {
      this.selectedRetrievedContacts.add(contact);
    }
    else {
      this.selectedRetrievedContacts.delete(contact);
    }
  }

  importSelectedContacts() {
    const request = apiRequest({
		url: `/organisation/current/contacts/import`,
		method: "post",
		data: { contacts: [...this.selectedRetrievedContacts] },
		success: data => {
			organisationStore._addObjects(data.objects);
			organisationStore.emitChanged();
		}
	});
    this.importContacts.execute(request);
  }

  deleteContact() {
    let self = this;
    organisationStore._deleteObjectFromServer(this.contact).then(function() {
      dispatcher.dispatch({
        type: 'contact/deleteContactSuccess',
        path: self.path
      });
    }, function(error) {
      dispatcher.dispatch({
        type: 'contact/deleteContactFailure',
        path: self.path,
        error: error
      });
    });
  }

  deleteContactSuccess() {
    organisationStore._deleteObject('Contact', this.contact.id);
    this.close();
  }

  deleteContactFailure(error) {
    organisationStore.deleteObjectFailure(this.contact, error);
  }

  close() {
    this.isOpen = false;
  }

  cancel() {
    this.isOpen = false;
  }
};


class ContactRetrievalOperation extends AjaxOperation2 {
  error(error) {
    if (error != null && error.error === 'not_verified') {
      this.setAuthorising();

      //TODO-techdebt would be nice to fluxify this rather than have a chain
      //of promises but we'll call this ok for now.
      let accounting = require("../invoices/AccountingSystemStore.js");
      let ac = organisationStore.getCurrentAccountingSystem();
      let connection = ac.connection;
      connection.state = ConnectionState.notConnected;
      connection.url = null;
      connection.oauthToken = null;
      accounting.beginAuth(ac.identifier).then(function(data) {
        connection.state = ConnectionState.notConnected;
        connection.url = data.url;
        accounting.openAuthPopup(ac.identifier).then(function() {
          contactsPageStore.retrieveContacts(ac.identifier);
        });
      });
    }
    else {
      this.setError();
    }
  }

  setAuthorising() {
    this.state = 'authorising';
  }

  get isAuthorising() {
    return this.state === 'authorising';
  }

  get isRetrieving() {
    return this.state === 'executing';
  }

  get isExecuting() {
    return this.state === 'executing' || this.state === 'authorising';
  }
}




class ContactsPageStore {
  constructor() {
    this.actionDefinitions = contactsPageActionDefinitions;

    this.contactFormStore = new ContactFormStore('contacts-page/contact-form');

    this.stores = {
      'contact-form': this.contactFormStore
    };
  }

  handle(action) {
    if (action.path.startsWith('contacts-page/contact-form')) {
      this.contactFormStore.handle(action);
    }
    else {
      handleAction(action, this);
    }
  }

  _reset() {
    this.retrievedContacts = [];
    this.selectedRetrievedContacts = new Set();
    this.contactFormStore.reset();
  }

  newContact() {
    organisationStore._checkCurrentAccountingSystemAuth();
    this.contactFormStore.newContact('contacts');
  }

  editContact(contact) {
    this.contactFormStore.editContact(contact);
  }

  openImportContacts() {
    organisationStore._checkCurrentAccountingSystemAuth();
    this.contactFormStore.openToImportContacts();
  }
}


export var contactsPageStore = new ContactsPageStore();
