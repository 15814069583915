import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from "moment";
import _ from 'underscore';
import { jsonHttp} from "./jsonHttp.js";
import { makeRecordClass, BooleanType, NumberType, DateType, StringType, ListType } from './models/record.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Immutable from 'immutable';
import { sum } from './utils.js';
import { DateValue, BooleanValue } from './widgets/generic.js';
import { ErrorAlert } from './widgets/alerts.js';

import PropTypes from "prop-types"; 
import apiRequest from './apiRequest.js';


class TrialOrganisation extends makeRecordClass({
  organisationId: null,
  organisationName: null,
  firstName: null,
  lastName: null,
  email: null,
  adminUsers: NumberType,
  timesheetUsers: NumberType,
  stripeAdminUsers: NumberType,
  stripeTimesheetUsers: NumberType,
  adminUsers30: NumberType,
  timesheetUsers30: NumberType,
  hasSubscription: BooleanType,
  nextPaymentDate: DateType,
  trialStart: DateType,
  trialEnd: DateType,
  subscriptionStart: DateType,
  useStripe: BooleanType,
  stripeId: StringType,
  monthlyAdminPlanId: StringType,
  monthlyStandardPlanId: StringType,
  yearlyAdminPlanId: StringType,
  yearlyStandardPlanId: StringType,
  yearlyCouponId: StringType,
  monthlyCouponId: StringType,
  subscriptionPeriod: StringType,
  unpaidInvoices: ListType,
}) {
}



export var TrialPage = CreateReactClass({
  mixins: [
    PureRenderMixin
  ],

  componentWillMount: function() {
    let self = this;
    apiRequest({
		url: `/api/v1/trials`,
		method: "get",
		success: data => {
			let users = data.users;
			users.sort((a, b) =>
				(a.organisationName || "").localeCompare(
					b.organisationName || ""
				)
			);
			self.setState({
				users: Immutable.List(
					users.map(u => TrialOrganisation.fromJson(u))
				),
				plans: data.plans,
				coupons: data.coupons
			});
		},
		error: data => self.setState({ users: "error" })
	});
  },

  getInitialState: function() {
    return {
      users: [], // `null`, [<user>], 'error'
      onlySubscribers: true,
      noStripe: false,
      trialUsers: false,
      plans: {},
      coupons: {}
    };
  },

  render: function() {
    let self = this;
    let users = Immutable.List([]);
    if (Immutable.List.isList(this.state.users)) {
      users = this.state.users.filter(u => !self.state.onlySubscribers || u.hasSubscription);
      users = users.filter(u => !self.state.noStripe || !u.useStripe);
      users = users.filter(u => !self.state.trialUsers || !u.hasSubscription && u.trialEnd && u.trialEnd.isAfter(moment()));
    }
    let mrrList = users.toJS().map(u => {
      let adminPlanId = u.subscriptionPeriod == "yearly" ? u.yearlyAdminPlanId : u.monthlyAdminPlanId;
      let standardPlanId = u.subscriptionPeriod == "yearly" ? u.yearlyStandardPlanId : u.monthlyStandardPlanId;
      let couponId = u.subscriptionPeriod == "yearly" ? u.yearlyCouponId : u.monthlyCouponId;
      let adminPlan = this.state.plans[adminPlanId];
      let standardPlan = this.state.plans[standardPlanId];
      let coupon = this.state.coupons[couponId];
      if (adminPlan && standardPlan) {
        let baseAmount = (u.adminUsers * adminPlan.amount + u.timesheetUsers * standardPlan.amount) / 100;
        let division = u.subscriptionPeriod == "yearly" ? 12 : adminPlan.interval_count;
        let discount = coupon ? coupon.percent_discount : 0;
        return (baseAmount / division) * (1 - discount/100)
      } else {
        return 0;
      }
    })
    mrrList = mrrList.filter(mrr => mrr)
    let mrrTotal = sum(mrrList)
    let numSubs = mrrList.length
    return <div style={{ padding: '1em' }}>
      <h1>Trials - Subs: {numSubs} - MRR: {mrrTotal} </h1>
      Subscriptions: <BooleanValue
        value={this.state.onlySubscribers}
        onChange={() => this.setState({onlySubscribers: !this.state.onlySubscribers})}
        isEditable={true}
      />
      Show No Stripe: <BooleanValue
        value={this.state.noStripe}
        onChange={() => this.setState({ noStripe: !this.state.noStripe })}
        isEditable={true}
      />
      Trialing: <BooleanValue
        value={this.state.trialUsers}
        onChange={() => this.setState({ trialUsers: !this.state.trialUsers })}
        isEditable={true}
      />

      {Immutable.List.isList(this.state.users) ?
        <div>
          <div style={{width: '255em'}} className="coincraft-table trial-table">
            <div className="flexbox-container coincraft-table-row header">
              <div style={{ width: '20em' }} className="coincraft-table-cell">Organisation name</div>
              <div style={{ width: '15em' }}  className="coincraft-table-cell">Name</div>
              <div style={{ width: '25em' }}  className="coincraft-table-cell">Email</div>
              <div style={{ width: '15em' }}  className="coincraft-table-cell">Admin Users</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Timesheet Users</div>
              <div style={{ width: '10em' }} className="coincraft-table-cell">Use Stripe</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Next Payment Date</div>
              <div style={{ width: '10em' }} className="coincraft-table-cell">Unpaid Invoices</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Stripe ID</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Admin Plan</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Standard Plan</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Coupon Code</div>
              <div style={{ width: '5em' }} className="coincraft-table-cell">MRR</div>
              <div style={{ width: '10em' }} className="coincraft-table-cell">Save</div>
              <div style={{ width: '15em' }}  className="coincraft-table-cell">Trial Start</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Trial End</div>
              <div style={{ width: '15em' }} className="coincraft-table-cell">Subscription Start</div>
              <div style={{ width: '10em' }} className="coincraft-table-cell">Save</div>
            </div>
            <div style={{overflowY: 'auto', height: '50em'}} className="trial-table-body">
              {users.map(function(u, i) {
                return <TrialPageUserRow key={u.email} user={u} plans={self.state.plans}  coupons={self.state.coupons} />;
              })}
            </div>
          </div>
        </div>
      : this.state.users === null ?
        <div>
          Loading...
        </div>
      : this.state.users === 'error' ?
        <ErrorAlert className="trial-widget__couldnt-load-data">
          {`Couldn't load trial data`}
        </ErrorAlert>
      : null}
    </div>;
  }
});



var TrialPageUserRow = CreateReactClass({
  propTypes: {
    user: PropTypes.object.isRequired,
    plans: PropTypes.object.isRequired,
    coupons: PropTypes.object.isRequired
  },

  mixins: [
    PureRenderMixin
  ],

  getInitialState: function() {
    return {
      user: this.props.user,
      saveState: null, // `null`, 'saved'
    };
  },

  componentWillMount: function () {
    let self = this;
    let user = this.state.user
    if (user.stripeId) {
      apiRequest({
        url: `/api/v1/trial-user-stripe-data/`,
        method: "get",
        success: data => {
          let userObj = self.state.user;
          userObj = userObj.set(
            "stripeAdminUsers",
            data.stripeData["stripeAdminUsers"]
          );
          userObj = userObj.set(
            "stripeTimesheetUsers",
            data.stripeData["stripeTimesheetUsers"]
          );
          self.setState({ user: userObj });
        }
      });
    }
  },

  render: function() {
    let u = this.state.user;
    let adminPlanId = u.subscriptionPeriod == "yearly" ? u.yearlyAdminPlanId : u.monthlyAdminPlanId;
    let standardPlanId = u.subscriptionPeriod == "yearly" ? u.yearlyStandardPlanId : u.monthlyStandardPlanId;
    let couponId = u.subscriptionPeriod == "yearly" ? u.yearlyCouponId : u.monthlyCouponId;
    let adminPlan = this.props.plans[adminPlanId];
    let standardPlan = this.props.plans[standardPlanId];
    let coupon = this.props.coupons[couponId];
    let adminPlanString = u.subscriptionPeriod == "yearly" ? "yearlyAdminPlanId" : "monthlyAdminPlanId";
    let standardPlanString = u.subscriptionPeriod == "yearly" ? "yearlyStandardPlanId" : "monthlyStandardPlanId";
    let couponString = u.subscriptionPeriod == "yearly" ? "yearlyCouponId" : "monthlyCouponId";


    return <div className="flexbox-container coincraft-table-row">
      <div style={{ width: '20em' }}  className="coincraft-table-cell">{u.organisationName}</div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">{`${u.firstName || ""} ${u.lastName || ""}`}</div>
      <div style={{ width: '25em' }} className="coincraft-table-cell"><a href={'/user/impersonate/' + u.email}>{u.email}</a></div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">
        <div className="flexbox-container" style={{borderBottom: 'solid 1px #eee', textAlign: 'center', width: '10em'}}>
          <div className="flex-1-1-auto" style={{borderRight: 'solid 1px #eee'}}>C</div>
          <div className="flex-1-1-auto" style={{borderRight: 'solid 1px #eee'}}>A</div>
          <div className="flex-1-1-auto">S</div>
        </div>
        <div className="flexbox-container" style={{textAlign: 'center', width: '10em' }}>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee', color: u.adminUsers == u.stripeAdminUsers ? '#00d000' : 'inherit' }}>{u.adminUsers}</div>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee', color: u.adminUsers30 == u.stripeAdminUsers ? '#00d000' : 'inherit'}}>{u.adminUsers30}</div>
          <div className="flex-1-1-auto">{u.stripeAdminUsers}</div>
        </div>
      </div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">
        <div className="flexbox-container" style={{ borderBottom: 'solid 1px #eee', textAlign: 'center', width: '10em'  }}>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee' }}>C</div>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee' }}>A</div>
          <div className="flex-1-1-auto">S</div>
        </div>
        <div className="flexbox-container" style={{ textAlign: 'center', width: '10em'  }}>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee', color: u.timesheetUsers == u.stripeTimesheetUsers ? '#00d000' : 'inherit' }}>{u.timesheetUsers}</div>
          <div className="flex-1-1-auto" style={{ borderRight: 'solid 1px #eee', color: u.timesheetUsers30 == u.stripeTimesheetUsers ? '#00d000' : 'inherit' }}>{u.timesheetUsers30}</div>
          <div className="flex-1-1-auto">{u.stripeTimesheetUsers}</div>
        </div>
      </div>
      <div style={{width:'10em'}}  className="coincraft-table-cell">
        <BooleanValue
          value={u.useStripe || false}
          onChange={this.handleUseStripeChange}
          isEditable={true}
          className="trial-widget__row__use-stripe-checkbox"
        />
        <button
          className="btn btn-default trial-widget__row__save-button"
          style={{marginLeft: '1em'}}
          onClick={this.handleSaveClick}>
          Save
        </button>

        {this.state.saveState === 'saved' ?
          <div
            className="trial-widget__save-success-indicator"
            style={{
              display: 'inline-block',
              height: '100%',
              marginLeft: 8,
              color: '#3c763d',
              backgroundColor: '#dff0d8'
            }}>
            <i className="fa fa-check" />
          </div>
          : null}
      </div>
      <div style={{ width: '15em' }} className="coincraft-table-cell">
        {u.nextPaymentDate ? u.nextPaymentDate.format("DD MMMM YYYY") : '-'}
      </div>
      <div style={{ width: '10em' }} className="coincraft-table-cell">
        {sum(u.unpaidInvoices.map(inv => inv.amount))}
      </div>
      <div style={{ width: '15em' }} className="coincraft-table-cell">
        {u.stripeId}
      </div>
      <div style={{ width: '15em' }} className="coincraft-table-cell">
        <input
          type="text"
          value={adminPlanId}
          onChange={(e) => this._setField(adminPlanString, e.target.value)}
        />
      </div>
      <div style={{ width: '15em' }} className="coincraft-table-cell">
        <input
          type="text"
          value={standardPlanId}
          onChange={(e) => this._setField(standardPlanString, e.target.value)}
        />
      </div>
      <div style={{ width: '15em' }} className="coincraft-table-cell">
        <input
          type="text"
          value={couponId}
          onChange={(e) => this._setField(couponString, e.target.value)}
        />
      </div>
      <div style={{ width: '5em' }} className="coincraft-table-cell">
        {adminPlan && standardPlan ? (((u.adminUsers * adminPlan.amount + u.timesheetUsers * standardPlan.amount) / 100) / (u.subscriptionPeriod == "yearly" ? 12 : adminPlan.interval_count))*(1-(coupon ? coupon.percent_discount/100 : 0)) : 0}
      </div>
      <div style={{ width: '10em' }} className="coincraft-table-cell">
        <button
          className="btn btn-default trial-widget__row__save-button"
          onClick={this.handleSaveClick}>
          Save
        </button>

        {this.state.saveState === 'saved' ?
          <div
            className="trial-widget__save-success-indicator"
            style={{
              display: 'inline-block',
              height: '100%',
              marginLeft: 8,
              color: '#3c763d',
              backgroundColor: '#dff0d8'
            }}>
            <i className="fa fa-check" />
          </div>
          : null}
      </div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">
        <DateValue
          value={u.trialStart}
          onChange={(date) => this._setField('trialStart', date)}
          isEditable={true}
          className="trial-widget__row__started-trial-on-box"
        />
      </div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">
        <DateValue
          value={u.trialEnd}
          onChange={(date) => this._setField('trialEnd', date)}
          isEditable={true}
        />
      </div>
      <div style={{width:'15em'}}  className="coincraft-table-cell">
        <DateValue
          value={u.subscriptionStart}
          onChange={(date) => this._setField('subscriptionStart', date)}
          isEditable={true}
        />
      </div>
      <div style={{width:'10em'}}  className="coincraft-table-cell">
        <button
            className="btn btn-default trial-widget__row__save-button"
            onClick={this.handleSaveClick}>
          Save
        </button>

        {this.state.saveState === 'saved' ?
          <div
              className="trial-widget__save-success-indicator"
              style={{
                display: 'inline-block',
                height: '100%',
                marginLeft: 8,
                color: '#3c763d',
                backgroundColor: '#dff0d8'
              }}>
              <i className="fa fa-check" />
          </div>
        : null}
      </div>
    </div>;
  },

  _setField: function(fieldName, value) {
    this.setState({user: this.state.user.set(fieldName, value)});
  },

  _setNumberField: function(fieldName, value) {
    this._setField(fieldName, value !== '' ? parseInt(value) : null);
  },

  handleUseStripeChange: function(s) {
    this.setState({user: this.state.user.set('useStripe', s)});
  },

  handleSaveClick: function() {
    let self = this;
    let user = this.state.user;

    apiRequest({
      url: `/api/v1/trial/${user.organisationId}`,
      method: "post",
      data: user.serialize(),
      success: data => {
        self.setState({ saveState: "saved" });
        setTimeout(function() {
          self.setState({ saveState: null });
        }, 2000);
      },
      error: data => alert("fail")
    });
  }
});
