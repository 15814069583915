import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import classNames from 'classnames';
import { Project, ProjectPhase } from '../models.js';
import { organisationStore } from "../organisation.js";
import { Table } from '../table.js';
import { getOnboardingData } from '../organisationStore.js';
import { projectsPageStore, actions as projectTableActions } from './ProjectsPageStore.js';
import { userStore } from '../user.js';
import { ReportStoreWidget, ReportStoreSaveBar } from '../reports/ReportWidget.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import { columnPresentation } from './columnPresentation.js';
import { rootStore } from '../RootStore.js';
import { wrapUserStore } from '../user/utils.js';
import { reportsPageWrapper } from '../reports/reportsPageWrapper.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { ReportType } from '../reports/Report.js';


export var ProjectsPage = wrapUserStore(requiresPermission(
  permissions.noRestrictions,
  {
    canCreateProject: permissions.canCreateProject
  },
  CreateReactClass({
    mixins: [
      makeMultipleStoreMixin([rootStore, projectsPageStore, organisationStore, userStore], function() {
        const reportStore = projectsPageStore.reportStore;

        return {
          user: userStore.user,
          onboardingData: getOnboardingData(organisationStore, permissions, userStore),
          matchingProjects: (reportStore
            .getMatchingItems(organisationStore.projects)
            .filter(p => permissions.canViewProject(p).ok(userStore.user))
            .sort((a, b) => a.getTitle().localeCompare(b.getTitle()))
          ),
          expandedProjects: projectsPageStore.expandedProjects,
          selectedVisibleColumns: projectsPageStore.selectedVisibleColumns,
          reportStore: reportStore,
          reports: organisationStore.reports.filter(r => r.reportType === ReportType.project),
          isDirty: rootStore.isDirty,
        };
      })
    ],

    render: function() {
      let self = this;

      if (!this.state.onboardingData.hasProjects) {
        return <div>
          <IncompleteSetupPage
            header={
              <strong>
                No projects yet
              </strong>
            }
            onboardingData={this.state.onboardingData}
          />
        </div>;
      }
      else {
        return <CoincraftPage
          className="projects-page"
          header={
            <ReportStoreWidget
              heading="Projects"
              user={this.state.user}
              items={this.state.matchingProjects}
              reports={this.state.reports}
              reportStore={this.state.reportStore}
              columnPresentation={columnPresentation}
              filterInputPlaceholder="Filter by project name or job code"
              canRefresh={!this.state.isDirty}
              buttons={[
                this.props.canCreateProject ?
                  <button
                      key={0}
                      className="project-list__add-new-project-button page-header__button--primary"
                      onClick={() => projectTableActions.newProject()}>
                    + New Project
                  </button>
                : null
              ]}
            />
          }
          body={
            <div style={{ height: '100%'}}>
              <div className="project-table new-dashboard__project-list" style={{ textAlign: 'left', height: '100%', minHeight: '30em'}}>
                <Table
                  scroll={true}
                  rows={this.state.matchingProjects}
                  columns={this.state.selectedVisibleColumns}
                  rowProps={function(item, i, stack) {
                    return {
                      className: classNames(
                        "new-dashboard__project-list__row",
                        "new-dashboard__project-list__item",
                        "project-table__row",
                        self.props.className
                      ),
                      style: (item.constructor !== Project) ? //TODO - don't apply the following to last phase item
                        {borderBottom: '1px solid #efefef'}
                      : {},
                      onClick: function(event) {
                        // Only care about clicking on projects.. clicking on phases doesn't do anything.
                        if (item.constructor === Project) {
                          projectTableActions.toggleProjectExpanded(item);
                        }
                      }
                    };
                  }}
                  tableData={function() {
                    return {
                      expandedProjects: self.state.expandedProjects
                    };
                  }}
                  getRowChildren={function(item) {
                    if (_.include(self.state.expandedProjects, item)) {
                      return item.getPhasesForProjectTable();
                    }
                    else {
                      return [];
                    }
                  }}
                  getCellProps={function(item) {
                    return {
                      style: {
                        padding: item.constructor === ProjectPhase ? '0.4em 0.6em 0' : '0.6em 0.6em 0',
                        fontSize: item.constructor === ProjectPhase ? '.95em' : '1em',
                      }
                    };
                  }}
                  columnPresentation={columnPresentation}
                  initialSort={{
                    columnIdentifier: 'name',
                    direction: 'asc',
                    isAlreadySorted: true
                  }}
                />
              </div>
            </div>
          }
          saveBar={
            <ReportStoreSaveBar
              reportStore={this.state.reportStore}
              user={this.state.user}
            />
          }
        />;
      }
    }
  })
));


export var ProjectsPageWrapper = reportsPageWrapper(projectsPageStore, ProjectsPage);
