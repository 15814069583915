/* @flow */
import moment from 'moment';
import _ from 'underscore';
import { generateUUID, sum, compareMoments, isDecimal, divideTotalAmongItems, imap, ifilter } from '../utils.js';
import { Enum } from '../enum.js';
import { organisationStore } from '../organisation.js';
import { DataObject } from './dataobject.js';
import { dateConverter } from './dateconverter.js';
import { ProjectPhase } from './projectphase.js';
import { Allocation, getStaffMembers, getStaffMemberHours } from './allocation.js';
import { ChangeLogItem } from './changelogitem.js';
import { ProjectExpense } from './projectexpense.js';
import { ProjectNote } from './projectnote.js';
import { ProjectEvent } from './projectevent.js';
import { Task } from './task.js';
import { NoPhasePhase } from './nophasephase.js';
import { CashFlowItem, makeCashFlowItemsFromMilestones } from './cashflowitem.js';
import Immutable from 'immutable';
import { Milestone } from './milestone.js';
import xspans from 'xspans';
import { ProjectExpenseAllocation } from './projectexpenseallocation.js';


export const ProjectStatus = Enum(['prospective', 'active', 'onHold', 'archived']);
export const projectStatusOptions = ['active', 'onHold', 'prospective', 'archived', undefined]


export const Project = class extends DataObject {
  /**
   * this.staffExpenses: projected staff pay (set by scheduler)
   * this.staffChargeOut: projected staff charge out (set by scheduler)
   */

  constructor(options) {
    super(options);

    var self = this;

    // `startDate` and `endDate` aren't persisted fields of the `Project` and
    // they're currently just used by the milestones widget.
    if (options == null) { options = {}; }
    this.startDate = options.startDate;
    this.endDate = options.endDate;

    this._status = options.status

    this.phases.forEach(function(p, i) {
      p.project = self;
      p.phaseIndex = i;
    });

    this.expenses.forEach(function(e, i) {
      e.projectId = self.id;
    });

    if (this.uuid == null) {
      this.uuid = generateUUID();
    }

    this.initRates();

    this.errors = [];
    this.isDirty = false;
  }

  static getClassName() {
    return "Project";
  }

  static compareByTitle(a, b) {
    return a.getTitle().localeCompare(b.getTitle());
  }

  initRates() {
    this.rates.forEach(pr => {
      pr.phase = () => this.phases.filter(ph => ph.uuid === pr.phaseUuid)[0];
      pr.item = () => {
        if (pr.itemType === "StaffMember") {
          return organisationStore.staffMembers.filter(sm => sm.uuid === pr.itemUuid)[0];
        } else {
          return organisationStore.staffRoles.filter(sr => sr.uuid === pr.itemUuid)[0];
        }
      }
    })
  }

  get hasDates() {
    return this.startDate && this.endDate
  }

  getProject() {
    // So a caller can get to a project from a project or phase object without
    // caring which.
    return this;
  }

  setFee(fee, updateMilestones = false) {
    if (this.getFee() > 0) {
      let factor = fee / this.getFee();
      this.getVisiblePhases().forEach(function(phase) {
        phase.setFee(phase.fee * factor, updateMilestones);
      });
    }
    else {
      let phaseFee = fee / this.phases.length;
      this.getVisiblePhases().forEach(function(phase) {
        phase.setFee(phaseFee, updateMilestones);
      });
    }
  }

  setHours(hours) {
    var self = this;
    var factor = (this.hours > 0) ? hours / this.hours : null;
    this.getVisiblePhases().forEach(function(phase) {
      phase.setHours(factor != null ? (phase.hours * factor) : hours / self.phases.length);
    });
  }

  setTotalAllocatedHours(hours) {
    let phases = this.getVisiblePhases();
    let currentTotal = sum(phases.map(ph => ph.getTotalAllocatedHours()));
    let ratio = hours / currentTotal;
    phases.forEach(ph => ph.setTotalAllocatedHours(ph.getTotalAllocatedHours() * ratio));
  }

  setStaffMembers(staffMembers) {
    this.phases.forEach(function(phase) {
      phase.setStaffMembers(staffMembers);
    });
  }

  get status() {
    if (this.getVisiblePhases().length === 0) return this._status
    const statusIndex = Math.min(...this.getVisiblePhases().map(p => projectStatusOptions.indexOf(p.status)))
    return projectStatusOptions[statusIndex] || "active"
  }

  set status(status) {
    this._status = status
    this.phases.forEach(ph => ph.status = status)
  }

  getActuals(now = moment()) {
    /**
      Returns [CashFlowItem].

      Spec: https://docs.google.com/document/d/1hfQuhzkDg0dXoKBeUgtn3hvIk0Xiyv6VUucNju21mtA/edit

      Include:
      - Invoices / change log items for months before the current month
      - Milestones for months after the current month
      - For the current month, use either:
        - All the milestones in the month, or
        - All the invoices / change log items in the month
        Whichever is greater.
    */
    const startOfMonth = now.clone().startOf('month');
    const startOfMonthIndex = dateConverter.momentToInt(startOfMonth);
    const endOfMonth = now.clone().endOf('month');
    const endOfMonthIndex = dateConverter.momentToInt(endOfMonth);

    const milestones = _.flatten(
      this.getVisiblePhases().map(function(phase) {
        return makeCashFlowItemsFromMilestones(phase.getVisibleMilestones().filter(m => {
            return m.endDate && m.getEndDate().isAfter(startOfMonth)
          }));
      })
    );

    const changeLogItems = (this.changeLog
      .filter(cli => cli.date != null && cli.billingType !== "agreedFee")
      .map(cli => cli.toCashFlowItem())
      .toArray()
    );

    return [
        ...milestones,
        ...changeLogItems
      ];
  }

  getTotalProjectedRevenue() {
    return Math.round(sum(this.getActuals().filter(cfi => cfi.billingType === "agreedFee").map(cfi => cfi.fee)));
  }


  getActualsInRange(startDateInt, endDateInt) {
    /**
      Returns [CashFlowItem].

      Spec: https://docs.google.com/document/d/1hfQuhzkDg0dXoKBeUgtn3hvIk0Xiyv6VUucNju21mtA/edit

      Include:
      - Invoices / change log items for months before the current month
      - Milestones for months after the current month
      - For the current month, use either:
        - All the milestones in the month, or
        - All the invoices / change log items in the month
        Whichever is greater.
    */
    const now = moment();
    const startMoment = dateConverter.intToMoment(startDateInt);
    const endMoment = dateConverter.intToMoment(endDateInt);
    const startOfMonth = now.clone().startOf('month');
    const startOfMonthIndex = dateConverter.momentToInt(startOfMonth);

    const milestones = _.flatten(
      this.getVisiblePhases().map(function(phase) {
        return makeCashFlowItemsFromMilestones(phase.getVisibleMilestones().filter(m => {
            return m.endDate >= startDateInt && m.endDate <= endDateInt && m.endDate >= startOfMonthIndex;
          }));
      })
    );

    const changeLogItems = (this.changeLog
      .filter(cli => cli.date != null && cli.date.isSameOrBefore(endMoment) && cli.date.isSameOrAfter(startMoment) && cli.billingType === "agreedFee")
      .map(cli => cli.toCashFlowItem())
      .toArray()
    );

    return [
        ...milestones,
        ...changeLogItems
      ];
  }

  getActualsInMonth(monthIndex) {
    const startMoment = dateConverter.monthIndexToMoment(monthIndex);
    const startDateInt = dateConverter.momentToInt(startMoment);
    const endDateInt = dateConverter.momentToInt(startMoment.clone().endOf('month'));
    return this.getActualsInRange(startDateInt, endDateInt);
  }

  getMilestonesInRange(startDateInt, endDateInt) {
    let milestones = [];
    this.getVisiblePhases().forEach(ph => {
      milestones = milestones.concat(ph.getMilestonesInRange(startDateInt, endDateInt));
    })
    return milestones;
  }

  getMilestonesInMonth(monthIndex) {
    const startMoment = dateConverter.monthIndexToMoment(monthIndex);
    const startDateInt = dateConverter.momentToInt(startMoment);
    const endDateInt = dateConverter.momentToInt(startMoment.clone().endOf('month'));
    return this.getMilestonesInRange(startDateInt, endDateInt);
  }

  setTotalMilestoneRevenueInMonth(totalRevenue, monthIndex) {
    let milestones = this.getMilestonesInMonth(monthIndex);
    if (milestones.length === 0) {
      const phases = this.getVisiblePhases();
      const startMoment = dateConverter.monthIndexToMoment(monthIndex);
      const endDateInt = dateConverter.momentToInt(startMoment.clone().endOf('month'));
      phases.forEach(ph => {
        let newMilestone = new Milestone({
          endDate: endDateInt,
          revenue: totalRevenue / phases.length,
          phase: ph,
          allocation: ph.allocation.mapHours(h => 0)
        })
        ph.milestones.push(newMilestone);
        ph.updateMilestonePercentsBasedOnRevenue();
        ph.setupMilestones();
      });
    } else {
      const ratio = totalRevenue / sum(milestones.map(m => m.revenue));
      const phases = [...new Set(milestones.map(m => m.phase))]; //set makes values unique - I think...
      milestones.forEach(m => {
        m.setRevenue(m.revenue * ratio);
      })
      phases.forEach(ph => {
        ph.updateMilestonePercentsBasedOnRevenue();
        ph.setupMilestones();
      })
    }
  }

  getNoPhaseCashFlowItems() {
    return (this.changeLog
      .filter(cli => cli.date != null && (cli.phase == null || cli.phase.id === -1) && cli.billingType !== "reimbursement")
      .map(cli => cli.toCashFlowItem())
      .toArray()
    );
  }


  //TODO-project_changelog why are there cash flow items in milestonesStore.cashFlow with
  //string endDates?
  //
  getCashFlowItemsByMode(graphMode, now) {
    if (graphMode === 'actuals') {
      return this.getActuals(now);
    }
    else {
      return _.flatten(this.getVisiblePhases().map(function(phase) {
        return makeCashFlowItemsFromMilestones(phase.milestones);
      }));
    }
  }

  getCashFlowItems() {
    const milestones = _.flatten(
      this.getVisiblePhases().map(function(phase) {
        return makeCashFlowItemsFromMilestones(phase.getVisibleMilestones());
      })
    );
    const changeLogItems = (this.changeLog.filter(cli => cli.billingType !== "reimbursement")
      .map(cli => cli.toCashFlowItem())
      .toArray()
    );

    return [
        ...milestones,
        ...changeLogItems
      ];
  }

  getCashflowItemsInRange(startDateInt, endDateInt) {
    const startMoment = dateConverter.intToMoment(startDateInt);
    const endMoment = dateConverter.intToMoment(endDateInt);

    const milestones = _.flatten(
      this.getVisiblePhases().map(function(phase) {
        return makeCashFlowItemsFromMilestones(phase.getVisibleMilestones().filter(m => {
            return m.endDate >= startDateInt && m.endDate <= endDateInt;
          }));
      })
    );


    const changeLogItems = (this.changeLog
      .filter(cli => cli.date != null && cli.date.isSameOrBefore(endMoment) && cli.date.isSameOrAfter(startMoment) && cli.billingType !== "reimbursement")
      .map(cli => cli.toCashFlowItem())
      .toArray()
    );

    return [
        ...milestones,
        ...changeLogItems
      ];
  }

  getCashflowItemsInMonth(monthIndex) {
    const startMoment = dateConverter.monthIndexToMoment(monthIndex);
    const startDateInt = dateConverter.momentToInt(startMoment);
    const endDateInt = dateConverter.momentToInt(startMoment.clone().endOf('month'));
    return this.getCashflowItemsInRange(startDateInt, endDateInt);
  }

  setStaffAllocation(staffMember, hours) {
    const currentTotalHours = this.allocation.getStaffMemberAllocation(staffMember) || 0;
    const factor = (currentTotalHours > 0) ? hours / currentTotalHours : null;

    for (let phase of this.getVisiblePhases()) {
      const phaseHours = phase.allocation.getStaffMemberAllocation(staffMember) || 0;
      //TODO-new_project_creator: have a more intelligent default allocation
      //than `hours / this.phases.length`;
      phase.setStaffAllocation(staffMember, currentTotalHours > 0 ? (phaseHours * factor) : hours / this.getVisiblePhases().length);
    }
  }

  getTotalExpenses() {
    return sum(this.expenses.map(e => e.totalCost()));
  }

  getProfitPercent() {
    /**
     * Projected from scheduler (I think).
     */
    return (this.fee - this.staffExpenses - this.getTotalExpenses()) / this.fee * 100;
  }

  getProfit() {
    /**
     * Projected from scheduler (I think).
     */
    return this.fee - this.staffExpenses - this.getTotalExpenses();
  }

  appendNewPhase(phaseData) {
    /**
     * Creates a phase and appends it to this project's phases list.
     */
    var phase = this.createNewPhase(phaseData);
    this.phases.push(phase);
    return phase;
  }

  createNewPhase(phaseData) {
    /**
     * Creates a phase but doesn't add it to this project's phases list.
     */
    var phase = new ProjectPhase({
      project: this,
      name: phaseData.name,
      jobCode: phaseData.jobCode,
      startDate: this.startDate,
      endDate: this.startDate != null ? (this.startDate + 60) : null,
      fee: 0,
      milestones: [] // We'll set the milestones after this loop.
    });
    phase.createDefaultTask();
    phase.project = this;
    phase.allocation = new Allocation();
    phase.adjustMilestones(phase.startDate, phase.endDate, phase.fee);
    return phase;
  }

  apiTypeName() {
    return "project";
  }

  defaults() {
    return {
      id: null,
      name: '',
      budget: null,
      jobCode: null,

      // We set a value for `createdAt` on object creation on the client, but
      // we don't send it back to the server - the server is responsible for
      // managing it (that way we don't need to worry about time zones or
      // client datetimes being wrong).
      createdAt: moment(),

      startDate: null,
      endDate: null,

      milestoneType: 'monthly',

      costCentreId: this.getDefaultCostCentre(),

      contactId: null,
      contact: null,

      phases: [],
      expenses: [],
      changeLog: Immutable.List([]),
      allocation: new Allocation(),

      manualBudget: null,
      manualExpensesBudget: 0,
      manualHoursBudget: null,

      unbilledChargeOut: null,
      durationUnit: 'months',

      noPhase: {
        unbilledChargeOut: 0,
        staffMinutesLoggedToDate: 0
      },

      percentLikelihood: 100,
      rates: [],
      notes: [],
      eventHistory: [],
    };
  }

  getDefaultCostCentre() {
    let costCentres = organisationStore.costCentres;
    if (costCentres.length > 0) {
      let projectCostCentres = costCentres.filter(cc => cc.name === "Projects");
      if(projectCostCentres.length > 0) {
        return projectCostCentres[0].id;
      } else {
        return costCentres[0].id
      }
    } else {
      return null;
    }
  }

  removePhase(phase) {
    phase.isDeleted = true;
  }

  static fieldTypes() {
    return {
      id: 'int',
      name: 'string',
      jobCode: 'string',
      startDate: 'date',
      endDate: 'date',
      createdAt: 'datetime',
      milestoneType: 'string',
      budget: 'number',
      costCentreId: 'int',
      contactId: 'int',
      unbilledChargeOut: 'number',
      noPhase: 'dict',
      phases: [ProjectPhase],
      expenses: [ProjectExpense],
      manualBudget: 'number',
      manualExpensesBudget: 'number',
      manualHoursBudget: 'number',
      changeLog: 'array',
      durationUnit: 'string',
      percentLikelihood: 'number',
      rates: 'array',
      notes: [ProjectNote],
      eventHistory: [ProjectEvent],
    };
  }

  static transformArgs(objectData, organisation) {
    let args = super.transformArgs(objectData, organisation);
    args.changeLog = Immutable.List((args.changeLog || []).map(function(cliData) {
      return ChangeLogItem.fromJSON(cliData);
    }));

    return args;
  }

  copy({cloneIdentity = true} = {}) {
    var project = new Project({
      id: cloneIdentity ? this.id : null,
      name: _.clone(this.name),
      jobCode: this.jobCode,
      budget: this.budget,
      costCentreId: this.costCentreId,
      contact: this.contact,
      unbilledChargeOut: this.unbilledChargeOut,
      noPhase: _.clone(this.noPhase),
      expenses: this.expenses,
      startDate: this.startDate,
      endDate: this.endDate,
      createdAt: this.createdAt != null ? this.createdAt.clone() : null,
      milestoneType: this.milestoneType,
      manualBudget: this.manualBudget,
      manualExpensesBudget: this.manualExpensesBudget,
      manualHoursBudget: this.manualHoursBudget,
      durationUnit: this.durationUnit,
      percentLikelihood: this.percentLikelihood,
      rates: this.rates,
      notes: this.notes,
      eventHistory: this.eventHistory,
    });
    project.changeLog = this.changeLog.map(cli => cli.copy({cloneIdentity: cloneIdentity}));
    project.staffExpenses = this.staffExpenses;
    project.phases = this.getVisiblePhases().map(p => p.copy({cloneIdentity: cloneIdentity}));
    project.phases.forEach(function(p) { p.project = project; });

    return project;
  }

  clone() {
    return this.copy({cloneIdentity: false});
  }

  templateCopy() {
    /**
     * Make a new project using this project as a template.
     *
     * Currently keeps all data except change log.
     */
    let newProject = this.copy({cloneIdentity: false});
    newProject.changeLog = Immutable.List([]);
    newProject.expenses = [];
    newProject.notes = [];
    newProject.eventHistory = [];

    newProject.phases.forEach(function(phase) {
      phase.staffMinutesLoggedToDate = 0;
      phase.unbilledChargeOut = 0;
    });

    if (newProject.noPhase != null) {
      newProject.noPhase.staffMinutesLoggedToDate = 0;
      newProject.noPhase.unbilledChargeOut = 0;
    }

    return newProject;
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      budget: this.budget,
      costCentreId: this.costCentre.id,
      contactId: this.contact != null ? this.contact.id : undefined,
      jobCode: this.jobCode,
      status: this.status,
      milestoneType: this.milestoneType,
      expenses: this.expenses.map(e => e.serialize()),
      changeLog: this.changeLog.filter(cli => cli.isEditable).map(cli => cli.serialize()).toArray(),
      manualBudget: this.manualBudget,
      manualHoursBudget: this.manualHoursBudget,
      manualExpensesBudget: this.manualExpensesBudget,
      durationUnit: this.durationUnit,
      percentLikelihood: this.percentLikelihood,
      rates: this.rates,
      notes: this.notes,
      eventHistory: this.eventHistory,
      phases: this.phases.map(function(phase) {
        return {
          id: phase.id,
          uuid: phase.uuid,
          name: phase.name,
          status: phase.status,
          percentLikelihood: phase.percentLikelihood,
          startDate: phase.startDate != null ?
                     dateConverter.intToMoment(phase.startDate).format("YYYY-MM-DD") :
                     null,
          endDate: phase.endDate != null ?
                   dateConverter.intToMoment(phase.endDate).format("YYYY-MM-DD") :
                   null,
          isDeleted: phase.isDeleted,
          fee: phase.fee,
          totalRevenue: phase.totalRevenue,

          // Legacy field, just keeping for technical correctness for the time being.
          budget: phase.budget,

          hours: phase.hours,
          jobCode: phase.jobCode,
          milestones: phase.milestones.map(m => m.serialize()),
          allocations: phase.allocations.map(a => a.serialize()),
          tasks: phase.tasks.toJS(),
          manualBudget: phase.manualBudget,
          manualHoursBudget: phase.manualHoursBudget,
          staffMemberBudgetedHours: phase.staffMemberBudgetedHours.filter(b => b.staffMember).map(b => ({
            staffMember: b.staffMember.id,
            hours: b.hours
          })),
          staffRoleBudgetedHours: phase.staffRoleBudgetedHours.map(b => ({
            staffRole: b.staffRole.id,
            hours: b.hours
          })),
          isRevenueSpreadsheetModified: phase.isRevenueSpreadsheetModified,
          durationUnit: phase.durationUnit,
          hoursBudgetLinked: phase.hoursBudgetLinked,
          expenseBudgetLinked: phase.expenseBudgetLinked,
          feeLinked: phase.feeLinked,
        };
      })
    };
  }

  get isSaved() {
    return this.id != null;
  }

  get isSelectable() {
    return this.isSaved;
  }

  get isActive() {
    return this.status === ProjectStatus.active;
  }

  get showProjections() {
    return (
      this.status === ProjectStatus.active
      || this.status === ProjectStatus.prospective
    );
  }

  get isArchived() {
    return this.status === ProjectStatus.archived;
  }

  get costCentre() {
    return organisationStore.getCostCentreById(this.costCentreId);
  }

  set costCentre(costCentre) {
    this.costCentreId = (costCentre != null) ? costCentre.id : null;
  }


  //just do this until we remove the properties from the project model
  get manualBudget() {
    return this.getBudget();
  }

  set manualBudget(budget) {
    this.setManualBudget(budget);
  }

  get manualExpensesBudget() {
    return this.getTotalExpenses();
  }

  set manualExpensesBudget(budget) {
    //fix to scale expenses?
  }

  get manualHoursBudget() {
    return this.getHours();
  }

  set manualHoursBudget(budget) {
    this.setManualHoursBudget(budget);
  }

  get fee() {
    return this.getFee();
  }

  set fee(fee) {
    this.setFee(fee);
  }

  get startDate() {
    let startDate = this.getStartDate();
    if (startDate)
      return dateConverter.momentToInt(startDate);
    else {
      return null;
    }
  }

  set startDate(dateInt) {
    // console.log('Set Start Date');
  }

  get endDate() {
    let endDate = this.getEndDate();
    if (endDate)
      return dateConverter.momentToInt(endDate);
    else {
      return null;
    }
  }

  set endDate(dateInt) {
    // console.log('Set End Date');
  }

  get startMonthIndex() {
    let startDate = this.getStartDate();
    if (startDate)
      return dateConverter.momentToMonthIndex(startDate);
    else {
      return null;
    }
  }

  get endMonthIndex() {
    let endDate = this.getEndDate();
    if (endDate)
      return dateConverter.momentToMonthIndex(endDate);
    else {
      return null;
    }
  }

  get likelihood() {
    if ((this.percentLikelihood !== undefined && this.percentLikelihood !== null) && (this.status === "prospective" || this.status === "onHold")) {
      const visiblePhases = this.getVisiblePhases()
      return Math.round(sum(visiblePhases.map(p => p.likelihood))/visiblePhases.length);
    } else {
      return 100;
    }
  }

  set likelihood(val) {
    this.percentLikelihood = val;
    this.phases.forEach(ph => ph.percentLikelihood = val)
  }

  get latestEventDate() {
    if (this.eventHistory.length > 0) {
      return _.max(this.eventHistory.map(e => e.dateInt)) || null
    } else {
      return null
    }
  }


  mergeChangeLog(dateType="endDate") {
    let project = this;

    let invoiceItems = [];
    for (let inv of this.getInvoices()) {
      if (inv.isDeleted) {
        continue;
      }
      for (let invoicePhase of inv.phases) {
        ['agreedFee', 'variation'].forEach(billingType => {
          let phaseTotal = invoicePhase.getPhaseTotal({billingType: billingType, amount: true, tax: false});
          if (phaseTotal !== 0) {
            invoiceItems.push(new ChangeLogItem({
              project: project,
              phase: organisationStore.getProjectPhaseById(invoicePhase.phaseId),
              date: inv[dateType].clone(),
              invoice: inv,
              revenue: phaseTotal,
              expenses: 0,
              progress: invoicePhase.phasePercent,
              billingType: billingType
            }));
          }
        });
        invoicePhase.lineItems.filter(
          li => li.billingType === "reimbursement"
        ).forEach(li => {
          invoiceItems.push(
            new ChangeLogItem({
              project: project,
              phase: organisationStore.getProjectPhaseById(
                invoicePhase.phaseId
              ),
              date: inv[dateType].clone(),
              invoice: inv,
              revenue: li.unitCost * li.unitQuantity,
              expenses: li.unitCost * li.unitQuantity,
              expense: li.expenseUuid && project.getExpenseFromUuid(
                li.expenseUuid
              ),
              billingType: "reimbursement"
            })
          );
        });
      }
    };

    project.changeLog = Immutable.List(
      [
        ...(project.changeLog
          // Filter out the `ChangeLogItem`s that are generated from invoices and expenses
          // and re-add them.
          .filter(cli => cli.invoice == null && cli.expense == null)
          .map(function(cliData) {
            if (cliData instanceof ChangeLogItem) {
              return cliData;
            }
            else {
              return ChangeLogItem.fromJSON({project: project.id, ...cliData});
            }
          })),
        ...invoiceItems,
        // TODO some people don't want expenses in revenue forecast
        ...project.expenses/*.filter(e => !e.isInvoiceable)*/.flatMap(function(expense) {
          return expense.allocated_expenses.map(a => new ChangeLogItem({
            project: project,
            phase: expense.phase,
            phaseId: expense.phaseId,
            date: a.date,
            expense: a,
            revenue: 0,
            expenses: a.amount,
            isChangeLogItem: false,
            billingType: 'expense'
          }));
        })
      ].sort(function(cli1, cli2) {
        return compareMoments(cli1.date, cli2.date);
      })
    );
  }

  getExpenseFromUuid(uuid) {
    return this.expenses.find(e => (uuid === e.uuid))
  }

  updateFromMilestones() {
    let self = this;
    this.fee = sum(this.getVisiblePhases().map(p => p.fee));

    let projectAllocation = new Allocation();
    this.staffExpenses = this.staffChargeOut = this.hours = 0;

    this.getVisiblePhases().forEach(function(phase) {
      phase.updateFromMilestones();
      self.hours += phase.hours;
      self.staffExpenses += phase.staffExpenses;
      self.staffChargeOut += phase.staffChargeOut;
      projectAllocation.addAllocation(phase.allocation);
    });

    this.allocation = projectAllocation;
  }

  getStaffMemberHoursBudget(staffMember) {
    return sum(this.getVisiblePhases().map(p => p.getStaffMemberHoursBudget(staffMember)))
  }

  getStaffRoleHoursBudget(staffRole) {
    return sum(this.getVisiblePhases().map(p => p.getStaffRoleHoursBudget(staffRole)))
  }

  getDatesFromPhases() {
    /**
     * Returns [startDate::date-int|null, endDate::date-int|null]
     *
     * Set the start date to the earliest date in any of the phases, and the
     * end date to the latest date in any of the phases. Note that we don't
     * assume that the earliest date is a start date or that the latest date is
     * an end date, not just because of existing erroneous phases whose start
     * dates are after their end dates, but because it's possible for a phase
     * to have just one date (ie. just a start date or just an end date). So a
     * phase may have a start date which is later than the end date of any
     * other phase, and no end date. In this case that phase's start date
     * should be viewed as the end date of the project for these purposes.
     */
    let startDate = null, endDate = null;
    for (let p of this.getVisiblePhases()) {
      for (let d of [p.startDate, p.endDate]) {
        if (d != null) {
          if (startDate == null || d < startDate) {
            startDate = d;
          }
          if (endDate == null || d > endDate) {
            endDate = d;
          }
        }
      }
    }
    return [startDate, endDate];
  }

  updateDatesFromPhases() {
    [this.startDate, this.endDate] = this.getDatesFromPhases();
  }

  matchesSearch(searchStr) {
    if (searchStr == null || searchStr === '') {
      return true;
    }
    var searchLower = searchStr.toLowerCase();
    return this.name.toLowerCase().indexOf(searchLower) !== -1 ||
      (this.jobCode != null && this.jobCode.toLowerCase().indexOf(searchLower) !== -1);
  }

  isValid() {
    return this.getErrors().length === 0;
  }

  getErrors() {
    this.validate();
    let errors = this.errors;
    this.getVisiblePhases().forEach(p => {
      p.validate();
      errors = _.union(errors, p.errors);
    });
    return _.uniq(errors);
  }

  validate() {
    this.validateName();
    this.validateCostCentre();
    this.validatePhaseNumbers();
  }

  validateName() {
    let isValid = this.name != '' && this.name != null;
    if (!isValid) {
      this.errors.push("Please enter a project name");
    } else {
      this.errors = _.without(this.errors, "Please enter a project name");
    }
  }

  validatePhaseNumbers() {
    let isValid = this.getVisiblePhases().length > 0;
    if (!isValid) {
      this.errors.push("Currently a project requires at least one phase.");
    } else {
      this.errors = _.without(this.errors, "Currently a project requires at least one phase.");
    }
  }

  validateCostCentre() {
    let isValid = this.costCentre != null;
    if (!isValid) {
      this.errors.push("Please select a cost centre");
    } else {
      this.errors = _.without(this.errors, "Please select a cost centre");
    }
  }

  getTitle() {
    if (this.jobCode != null && this.jobCode !== '') {
      return `${this.jobCode}: ${this.name}`;
    }
    else {
      return this.name;
    }
  }

  hasIncome() {
    return _.some(this.phases, p => !p.isDeleted && p.hasIncome());
  } 
  
  hasHours() {
    return _.some(this.phases, p => !p.isDeleted && p.hasHours());
  }

  getVisiblePhases() {
    return this.phases.filter(p => !p.isDeleted);
  }

  getActivePhases() {
    return this.phases.filter(p => !p.isDeleted && p.status === "active");
  }

  getPhasesForProjectTable() {
    // Pretend the `noPhase` data is a real phase.
    var realPhases = this.getVisiblePhases();
    if ((this.noPhase != null && this.noPhase.unbilledChargeOut !== 0)
        || this.changeLog.some(cli => cli.phase == null || cli.phase.id === -1)) {
      realPhases.push(this.getProjectNoPhase());
    }
    return realPhases;
  }

  getProjectNoPhase() {
    if (!this.noPhasePhase) {
      this.noPhasePhase = new NoPhasePhase({
        project: this,
        ...this.noPhase
      });
    }
    return this.noPhasePhase;
  }

  getExpensesInRange(startDate, endDate) {
    return (this.expenses || []).filter(function(expense) {
      return expense.startDate && expense.endDate && !(expense.startDate.isSameOrBefore(endDate) || expense.endDate.isSameOrAfter(startDate));
    });
  }

  getStartDate() {
    var dates = _.union(
      this.getVisiblePhases().map(p => p.startDate),
      this.getVisiblePhases().map(p => p.endDate)
    ).filter(d => d != null);
    if (dates.length > 0) {
      return dateConverter.intToMoment(_.min(dates));
    }
    else {
      return null;
    }
  }

  getEndDate() {
    var dates = _.union(
      this.getVisiblePhases().map(p => p.startDate),
      this.getVisiblePhases().map(p => p.endDate)
    ).filter(d => d != null);
    if (dates.length > 0) {
      return dateConverter.intToMoment(_.max(dates));
    }
    else {
      return null;
    }
  }

  getDuration() {
    if (this.getStartDate() == null || this.getEndDate() == null) {
      return null;
    } else if (this.durationUnit === "months") {
      return this.getEndDate().clone().add(1, 'day').diff(this.getStartDate(), 'months', true);
    } else if(this.durationUnit === "weeks") {
      return (dateConverter.momentToInt(this.getEndDate()) - dateConverter.momentToInt(this.getStartDate()) +1)/7;
    } else {
      return dateConverter.momentToInt(this.getEndDate()) - dateConverter.momentToInt(this.getStartDate()) +1;
    }
  }

  getFee() {
    return sum(this.getVisiblePhases().map(p => p.fee));
  }

  getHours() {
    return sum(this.getVisiblePhases().map(p => p.manualHoursBudget));
  }

  getStaffCost(store) {
    return sum(this.getVisiblePhases().map(p => p.getStaffCost(store)));
  }

  getRateInRange(item, phase, rateType, startDateInt, endDateInt) {
    // item == role/staff
    if (!item) return 0
    let rates = []
    if (startDateInt && endDateInt) {
      rates = this.rates.filter(r => {
        return (
          r.itemType === item.constructor.getClassName() &&
          r.itemUuid === item.uuid &&
          (r.phaseUuid && phase ? r.phaseUuid === phase.uuid : true) &&
          dateConverter.stringToInt(r.date) <= endDateInt
        );
      });
    } else {
      if (this.rates.length > 0) {
        rates = [_.last(
          this.rates.filter(r => {
            return (
              r.itemType === item.constructor.getClassName() &&
              r.itemUuid === item.uuid &&
              (r.phaseUuid ? r.phaseUuid === phase.uuid : true)
            );
          })
        )];
      }
    }
    rates = rates.filter(r => r)
    if (rates.length == 1) {
      return rates[0][rateType] || item.getRateInRange(rateType, startDateInt, endDateInt);
    } else if(rates.length > 1) {
      let rateInRange = 0;
      let totalDuration = endDateInt - startDateInt;
      let intersectingRates = []
      rates.forEach((a,i) => {
        let rateStartDate = dateConverter.stringToInt(a.date);
        let rateEndDate = rates[i+1] ? dateConverter.stringToInt(rates[i+1].date) : rateStartDate + 100000;
        let intersectingDates = xspans.and([rateStartDate, rateEndDate], [startDateInt, endDateInt]).data;
        if (intersectingDates.length === 2) {
          intersectingRates.push({
            startDate: intersectingDates[0],
            endDate: intersectingDates[1],
            duration: intersectingDates[1] - intersectingDates[0],
            rate: a[rateType] || item.getRateInRange(rateType, intersectingDates[0], intersectingDates[1]),
          })
        }
      });
      intersectingRates.forEach((a) => rateInRange += a.rate * (a.duration / totalDuration));
      return rateInRange;
    } else {
      return item.getRateInRange(rateType, startDateInt, endDateInt);
    }
  }

  getRateInMonth(item, phase, rateType, monthIndex) {
    let monthMoment = dateConverter.monthIndexToMoment(monthIndex);
    let startDate = dateConverter.momentToInt(monthMoment);
    let endDate = dateConverter.momentToInt(monthMoment.endOf('month'));
    return this.getRateInRange(item, phase, rateType, startDate, endDate);
  }

  getRateForPhase(item, phase, rateType) {
    return this.getRateInRange(item, phase, rateType, phase.startDate, phase.endDate);
  }


  setupDefaultTasks() {
    const isBillable = this.costCentre.isBillable;
    for (let p of this.phases) {
      let defaultTaskIndex = p.tasks.findIndex(t => t.isDefault);
      if (defaultTaskIndex === -1) {
        p.tasks = p.tasks.push(new Task({
          name: "(No task)",
          isDefault: true,
          isBillable: isBillable
        }));
      }
      else {
        if (this.id == null) {
          p.tasks = p.tasks.setIn([defaultTaskIndex, 'isBillable'], isBillable);
        }
      }
    }
  }

  getAllMilestones() {
    var milestones = [];
    this.getVisiblePhases().forEach(function(p) {
      if (p.getStartDate() != null && p.getEndDate() != null && p.milestones != null) {
        milestones = milestones.concat(p.getVisibleMilestones().filter(m => m.endDate && m.revenue));
      }
    });
    return milestones;
  }

  getBudget() {
    return sum(this.getNonDeletedPhases().map(p => p.manualBudget)) + this.manualExpensesBudget;
  }

  setManualBudget(budget) {
    //TODO-scheduling_tweaks better handle non-numeric input
    let self = this;

    divideTotalAmongItems({
      items: this.getVisiblePhases(),
      newTotal: budget - this.manualExpensesBudget,
      get: item => item.manualBudget,
      set: function(item, val) {
        self.setPhaseManualBudget(item, val);
      }
    });
  }

  getNonDeletedPhases() {
    return this.phases.filter(p => !p.isDeleted);
  }

  setPhaseManualBudget(phase, budget) {
    /**
     * `phase`: ProjectPhase | 'expenses'
     */
    if (phase === 'expenses') {
      this.manualExpensesBudget = budget;
    }
    else {
      phase.setExpenseBudget(budget);
    }
  }

  setExpenses(expenses) {
    if (this.expenses === expenses) return;
    let expenseBudget = sum(expenses.map(e => e.totalCost()));
    this.expenses = expenses;
    this.manualExpensesBudget = expenseBudget;
    this.mergeChangeLog()
  }

  setManualHoursBudget(hoursBudget) {
    divideTotalAmongItems({
      items: this.getVisiblePhases(),
      newTotal: hoursBudget,
      get: item => item.manualHoursBudget,
      set: function(item, val) {
        item.setHours(val);
      }
    });
  }

  setPhaseManualHoursBudget(phase, hoursBudget) {
    phase.setHours(hoursBudget);
  }

  getTotalNonStaffExpenses() {
    return sum(this.expenses.map(e => e.totalCost()));
  }

  getExpensesSpent() {
    let today = moment().startOf('day');
    return this.changeLog.filter(e => e.date == null || !e.date.isAfter(today))
                         .map(cli => cli.expenses || 0)
                         .reduce((a, b) => a + b, 0);
  }

  getExpensesSpentInDateRange(dateRange) {
    return this.changeLog
      .filter(function(e) {
        return e.date != null
          && (dateRange.start == null || !e.date.isBefore(dateRange.start))
          && (dateRange.end == null || !e.date.isAfter(dateRange.end));
      })
      .map(cli => cli.expenses || 0)
      .reduce((a, b) => a + b, 0);
  }

  getTotalInvoicedToDate() {
    return this.changeLog.map(cli => cli.revenue || 0).reduce((a, b) => a + b, 0);
  }

  getChangeLogItemsInDateRange(dateRange) {
    /**
     * dateRange: {start: moment|null, end: moment|null}
     */
    if (dateRange != null) {
      return this.changeLog.filter(function(c) {
        return !(
          (dateRange.start != null && c.date != null && c.date.isBefore(dateRange.start))
          || (dateRange.end != null && c.date != null && c.date.isAfter(dateRange.end))
        );
      });
    }
    else {
      return this.changeLog;
    }
  }

  * iterExpenseCashFlowItemsInRange(startDate, endDate) {
    for (let expense of this.expenses) {
      for (let allocation of expense.allocated_expenses) {
        if (allocation.date != null && !allocation.date.isBefore(startDate) && !allocation.date.isAfter(endDate)) {
          yield new CashFlowItem({
            project: this,
            phase: expense.phase,
            phaseId: expense.phaseId,
            invoice: null,
            endDate: dateConverter.momentToInt(allocation.date),
            expense: allocation,
            fee: expense.isInvoiceable ? allocation.amount : 0,
            spend: allocation.amount,
            title: expense.name
          });
        }
      }
    }
  }

  getRevenueInDateRange(dateRange, ...billingTypes) {
    /**
     * dateRange: {start: moment|null, end: moment|null}
     */
    return this.getChangeLogItemsInDateRange(dateRange).filter(c => billingTypes.length ? billingTypes.includes(c.billingType) : true).map(c => c.revenue || 0).reduce((a, b) => a + b, 0);
  }

  getStaffMinutesLoggedToDate() {
    return sum([...this.phases, this.noPhase].map(p => p.staffMinutesLoggedToDate));
  }

  /**
   * <Feed> interface.
   */
  getInvoices() {
    return organisationStore.objects.Invoice.list.filter(inv => inv.project === this);
  }

  moveBy(diff) {
    this.startDate += diff;
    this.endDate += diff;
    this.getVisiblePhases().forEach(function(phase) {
      phase.moveBy(diff);
    });
  }

  getCurrentProgress() {
    let cli = this.changeLog.filter(c => c.phase == null && c.progress != null).maxBy(c => c.progress);
    return (cli != null) ? cli.progress : 0;
  }

  getProgressAtEndOfDateRange(dateRange) {
    /**
     * dateRange: {start: moment|null, end: moment|null}
     */
    let cli = (this.getChangeLogItemsInDateRange(dateRange)
      .filter(c => c.phase == null && c.progress != null)
      .maxBy(c => c.progress)
    );
    return (cli != null) ? cli.progress : 0;
  }

  getMostRecentRevenueDate() {
    let invoices = this.changeLog.filter(cli => cli.revenue > 0);
    if (invoices.isEmpty()) {
      return null;
    }
    return invoices.max(function(cli1, cli2) {
      return compareMoments(cli1.date, cli2.date);
    }).date;
  }

  getCompletionDate() {
    let cli = this.changeLog.find(c => c.phase == null && c.progress == 100);
    return (cli != null) ? cli.date : null;
  }

  getStaffMembers() {
    return getStaffMembers(this.iterAllocations());
  }

  getStaffMemberAllocations() {
    return getStaffMemberHours(this.iterAllocations());
  }

  * iterAllocations() {
    for (let p of this.phases) {
      yield * p.iterAllocations();
    }
  }

  getAllocatedStaffMembersInDateRange(dateRange) {
    /**
     * Returns a list of `StaffMember`s.
     */
    if (dateRange == null) {
      return this.allocation.getStaffMembers();
    }
    else {
      let staffMembers = new Immutable.Set();
      for (let p of this.getVisiblePhases()) {
        staffMembers = staffMembers.union(p.getAllocatedStaffMembersInDateRangeSet(dateRange));
      }
      return staffMembers.toJS();
    }
  }

  getAllocatedHours() {
    return this.allocation.getTotalAllocatedHours();
  }

  getAllocatedHoursInDateRange(dateRange) {
    if (dateRange == null) {
      return this.allocation.getTotalAllocatedHours();
    }
    else {
      return sum(this.getVisiblePhases().map(p => p.getAllocatedHoursInDateRange(dateRange)));
    }
  }

  getAllocatedRateInDateRange(dateRange, rateType) {
    dateRange = dateRange || [null, null]
    return sum(this.getVisiblePhases().map(p => p.getAllocatedRateInDateRange(dateRange, rateType)));
  }

  getStaffExpensesFromAllocations(staffMember = null, payField = 'payRate') {
    let expenses = 0, chargeOut = 0;
    for (let p of this.getVisiblePhases()) {
      const data = p.getStaffExpensesFromAllocations(staffMember, payField);
      expenses += data.expenses;
      chargeOut += data.chargeOut;
    }

    expenses += this.getTotalNonStaffExpenses();
    return {
      expenses: expenses,
      chargeOut: chargeOut
    };
  }

  syncAllocationDates() {
    for (let p of this.phases) {
      p.syncAllocationDates();
    }
  }

  areAllocationsOutOfSync() {
    return Array.some(this.getVisiblePhases(), p => p.areAllocationsOutOfSync());
  }

  areMilestonesOutOfSync() {
    return Array.some(this.getVisiblePhases(), p => p.areMilestonesOutOfSync());
  }

  getTaskBillabilityLookup() {
    /**
     * Returns Immutable.Map([uuid, [isBillable::bool, isVariation::bool]])
     */
    let lookup = {};
    for (let p of this.phases) {
      for (let t of p.tasks) {
        lookup[t.uuid] = [t.isBillable, t.isVariation];
      }
    }
    return Immutable.fromJS(lookup);
  }

  getBudgetedHoursInMonthIndex(monthIndex) {
    return sum(this.getVisiblePhases().map(p => p.getBudgetedHoursInMonthIndex(monthIndex)))
  }

  getBudgetedExpenseInMonthIndex(monthIndex) {
    return sum(this.getVisiblePhases().map(p => p.getBudgetedExpenseInMonthIndex(monthIndex)))
  }
}
